import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> }
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Date: string
  DateTime: string
  DateTimeOffset: string
  Decimal: number
  Guid: string
  Long: number
  UInt: number
  /** The `Upload` scalar type represents a file upload. */
  Upload: unknown
  Uri: string
}

export interface AccountDateChanges {
  readonly __typename?: 'AccountDateChanges'
  readonly changeDate: Scalars['DateTime']
  readonly dateNew: Scalars['DateTime']
  readonly dateOld: Scalars['DateTime']
}

export type AcquaintWithNotificationsError = UnknownServerError

export interface AcquaintWithNotificationsOutput {
  readonly __typename?: 'AcquaintWithNotificationsOutput'
  readonly errors: Maybe<ReadonlyArray<AcquaintWithNotificationsError>>
  readonly notifications: Maybe<ReadonlyArray<Notification>>
}

export interface ActionAttach {
  readonly __typename?: 'ActionAttach'
  readonly addDate: Maybe<Scalars['DateTime']>
  readonly awsFileName: Maybe<Scalars['String']>
  readonly crmActionId: Maybe<Scalars['ID']>
  readonly fileName: Maybe<Scalars['String']>
  readonly id: Maybe<Scalars['ID']>
  readonly managerId: Maybe<Scalars['ID']>
  readonly url: Maybe<Scalars['String']>
}

export interface ActionCounter {
  readonly __typename?: 'ActionCounter'
  readonly count: Scalars['Int']
  readonly state: CrmActionState
}

export interface ActionFilters {
  readonly __typename?: 'ActionFilters'
  readonly assigners: Maybe<ReadonlyArray<Maybe<CrmActionCreatorEnum>>>
  readonly offset: Maybe<Scalars['String']>
  readonly query: Maybe<Scalars['String']>
  readonly types: Maybe<ReadonlyArray<Maybe<CrmActionTypeEnum>>>
  readonly users: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>
}

export interface ActionStateCounter {
  readonly __typename?: 'ActionStateCounter'
  readonly actionCount: Scalars['Int']
  readonly state: CrmActionState
}

export interface ActionTeamCounter {
  readonly __typename?: 'ActionTeamCounter'
  readonly counters: ReadonlyArray<ActionStateCounter>
  readonly isSalesSupport: Scalars['Boolean']
  readonly manager: CrmManager
}

export interface ActivatedAdditionalService {
  readonly __typename?: 'ActivatedAdditionalService'
  readonly activatedAt: Maybe<Scalars['Date']>
  readonly endedAt: Maybe<Scalars['Date']>
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly serviceId: Scalars['ID']
  readonly state: Maybe<ServiceState>
  readonly type: Maybe<ServiceType>
}

export interface ActivatedCvDbService {
  readonly __typename?: 'ActivatedCvDbService'
  readonly activatedAt: Maybe<Scalars['Date']>
  readonly activator: Maybe<ServiceActivator>
  readonly city: City
  readonly contactsAccrualPeriod: Maybe<ContactsAccrualPeriod>
  readonly endedAt: Maybe<Scalars['Date']>
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly rubric: Rubric
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly serviceId: Scalars['ID']
  readonly state: Maybe<ServiceState>
  readonly totalContacts: Maybe<Scalars['Int']>
  readonly type: Maybe<ServiceType>
  readonly usagePeriod: Maybe<ServiceUsagePeriod>
}

export interface ActivatedHotService {
  readonly __typename?: 'ActivatedHotService'
  readonly activatedAt: Maybe<Scalars['Date']>
  readonly availableCount: Maybe<Scalars['Int']>
  readonly endedAt: Maybe<Scalars['Date']>
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly parentServiceId: Maybe<Scalars['ID']>
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly serviceId: Scalars['ID']
  readonly state: Maybe<ServiceState>
  readonly type: Maybe<ServiceType>
}

export interface ActivatedLogoInPortalService {
  readonly __typename?: 'ActivatedLogoInPortalService'
  readonly activatedAt: Maybe<Scalars['Date']>
  readonly branch: LogoPortalBranch
  readonly endedAt: Maybe<Scalars['Date']>
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly portal: LogoPortal
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly serviceId: Scalars['ID']
  readonly state: Maybe<ServiceState>
  readonly type: Maybe<ServiceType>
}

export interface ActivatedLogoInRubricService {
  readonly __typename?: 'ActivatedLogoInRubricService'
  readonly activatedAt: Maybe<Scalars['Date']>
  readonly endedAt: Maybe<Scalars['Date']>
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly rubric: Rubric
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly serviceId: Scalars['ID']
  readonly state: Maybe<ServiceState>
  readonly type: Maybe<ServiceType>
}

export interface ActivatedOrderByTurbotaServiceEmployerEvent {
  readonly __typename?: 'ActivatedOrderByTurbotaServiceEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly order: Maybe<Order>
  readonly performer: PerformerEmployerEventUnion
}

export interface ActivatedRisingUpService {
  readonly __typename?: 'ActivatedRisingUpService'
  readonly activatedAt: Maybe<Scalars['Date']>
  readonly availableCount: Maybe<Scalars['Int']>
  readonly endedAt: Maybe<Scalars['Date']>
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly serviceId: Scalars['ID']
  readonly state: Maybe<ServiceState>
  readonly type: Maybe<ServiceType>
}

export interface ActivatedServiceEmployerEvent {
  readonly __typename?: 'ActivatedServiceEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly performer: PerformerEmployerEventUnion
  readonly service: ServiceEmployerEventSnapshotUnion
}

export interface ActivatedVacancyPackageService {
  readonly __typename?: 'ActivatedVacancyPackageService'
  readonly activatedAt: Maybe<Scalars['Date']>
  readonly activator: Maybe<ServiceActivator>
  readonly additionalServices: ReadonlyArray<Maybe<ServiceUnited>>
  readonly availableCount: Maybe<Scalars['Int']>
  readonly contactsAccrualPeriod: Maybe<ContactsAccrualPeriod>
  readonly contactsCount: Maybe<Scalars['Int']>
  readonly endedAt: Maybe<Scalars['Date']>
  readonly id: Scalars['ID']
  readonly isPackage: Maybe<Scalars['Boolean']>
  readonly isUnlimitedContacts: Maybe<Scalars['Boolean']>
  readonly name: Scalars['String']
  readonly publicationType: Maybe<VacancyPublicationType>
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly serviceId: Scalars['ID']
  readonly state: Maybe<ServiceState>
  readonly supportedRegions: ReadonlyArray<City>
  readonly type: Maybe<ServiceType>
  readonly usedContactCount: Maybe<Scalars['Int']>
  readonly vacanciesPerPackage: Maybe<Scalars['Int']>
  readonly vacancyMailingCount: Maybe<Scalars['Int']>
  readonly vacancyPublishDays: Maybe<Scalars['Int']>
  readonly vacancyRisingCount: Maybe<Scalars['Int']>
}

export interface ActivatedVacancyPublicationService {
  readonly __typename?: 'ActivatedVacancyPublicationService'
  readonly activatedAt: Maybe<Scalars['Date']>
  readonly availableCount: Maybe<Scalars['Int']>
  readonly endedAt: Maybe<Scalars['Date']>
  readonly id: Scalars['ID']
  readonly isPackage: Maybe<Scalars['Boolean']>
  readonly name: Scalars['String']
  readonly parentServiceId: Maybe<Scalars['ID']>
  readonly publicationType: Maybe<VacancyPublicationType>
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly serviceId: Scalars['ID']
  readonly state: Maybe<ServiceState>
  readonly supportedRegions: ReadonlyArray<City>
  readonly type: Maybe<ServiceType>
  readonly vacancyMailingCount: Maybe<Scalars['Int']>
  readonly vacancyPublishDays: Maybe<Scalars['Int']>
  readonly vacancyRisingCount: Maybe<Scalars['Int']>
}

export interface AdamLabel {
  readonly __typename?: 'AdamLabel'
  readonly color: Maybe<Scalars['String']>
  readonly id: Scalars['ID']
  readonly shortCut: Maybe<Scalars['String']>
}

export interface AddAdamLabelInput {
  readonly color: Scalars['String']
  readonly id: Scalars['String']
  readonly shortCut?: InputMaybe<Scalars['String']>
}

export interface AddClubPostInput {
  readonly body: Scalars['String']
  readonly title: Scalars['String']
}

export interface AddClubPostOutput {
  readonly __typename?: 'AddClubPostOutput'
  readonly post: Maybe<ClubPost>
}

export interface AddContactInput {
  readonly beginDate?: InputMaybe<Scalars['Date']>
  readonly notebookId: Scalars['ID']
  readonly payerId: Scalars['ID']
}

export interface AddGamificationLimitInput {
  readonly limits: ReadonlyArray<GiftDetailInput>
  readonly notebookId: Scalars['ID']
  readonly occasionWaveId: Scalars['ID']
}

export interface AddGamificationLimitsOutput {
  readonly __typename?: 'AddGamificationLimitsOutput'
  readonly id: Scalars['ID']
  readonly isSuccess: Scalars['Boolean']
  readonly messageText: Maybe<Scalars['String']>
}

export interface AddGiftDetailInput {
  readonly count: Scalars['Int']
  readonly id?: InputMaybe<Scalars['ID']>
  readonly name: Scalars['String']
  readonly price: Scalars['Decimal']
  readonly weight: Scalars['Int']
}

export interface AddGiftInput {
  readonly comment?: InputMaybe<Scalars['String']>
  readonly companyId: Scalars['ID']
  readonly deliveryChannelId: GiftDeliveryChannelEnum
  readonly deliverySlots?: InputMaybe<ReadonlyArray<GiftDeliverySlotInput>>
  readonly details: ReadonlyArray<GiftDetailInput>
  readonly novaPoshtaDeliveryOptions?: InputMaybe<AddGiftRequestDeliveryOptionsInput>
  readonly requestOccasionId?: InputMaybe<Scalars['ID']>
  readonly userCardId?: InputMaybe<Scalars['ID']>
}

export interface AddGiftOccasionInput {
  readonly details?: InputMaybe<ReadonlyArray<AddGiftDetailInput>>
  readonly occasionId?: InputMaybe<Scalars['ID']>
  readonly occasionName: Scalars['String']
  readonly startDate: Scalars['DateTime']
}

export interface AddGiftRequestDeliveryOptionsInput {
  readonly addressRef: Scalars['String']
  readonly contactPersonRef: Scalars['String']
  readonly cost: Scalars['Int']
  readonly counterpartyRef: Scalars['String']
  readonly deliveryDate?: InputMaybe<Scalars['DateTime']>
  readonly deliveryTypeId: GiftDeliveryTypeEnum
  readonly description?: InputMaybe<Scalars['String']>
  readonly edrpou?: InputMaybe<Scalars['String']>
  readonly isExactDeliveryDate?: InputMaybe<Scalars['Boolean']>
  readonly isNeedToAttachDocuments?: InputMaybe<Scalars['Boolean']>
  readonly novaPoshtaComment?: InputMaybe<Scalars['String']>
  readonly recipientCityName: Scalars['String']
  readonly recipientFirstName: Scalars['String']
  readonly recipientFlat?: InputMaybe<Scalars['String']>
  readonly recipientHouse?: InputMaybe<Scalars['String']>
  readonly recipientLastName: Scalars['String']
  readonly recipientPhone: Scalars['String']
  readonly recipientStreetName: Scalars['String']
  readonly recipientType: NovaPosthaCounterpartyTypesGraphEnum
  readonly senderFullName: Scalars['String']
  readonly senderPhone: Scalars['String']
  readonly senderRef: Scalars['String']
  readonly settlementRef: Scalars['String']
}

export interface AddGiftRequestOutput {
  readonly __typename?: 'AddGiftRequestOutput'
  readonly id: Scalars['ID']
  readonly isSuccess: Scalars['Boolean']
  readonly message: Maybe<Scalars['String']>
}

export interface AddGiftWaveInput {
  readonly details?: InputMaybe<ReadonlyArray<AddGiftDetailInput>>
  readonly startDate: Scalars['DateTime']
  readonly waveId?: InputMaybe<Scalars['ID']>
  readonly waveName: Scalars['String']
}

export interface AddJobAlertSubscriptionInput {
  readonly cityId: Scalars['ID']
  readonly clusterTags?: InputMaybe<ReadonlyArray<Scalars['String']>>
  readonly districtIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
  readonly email?: InputMaybe<Scalars['String']>
  readonly employmentIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
  readonly isDontShowVacancyFromAgency?: InputMaybe<Scalars['Boolean']>
  readonly isForStudent?: InputMaybe<Scalars['Boolean']>
  readonly isOnlyNoCvApply?: InputMaybe<Scalars['Boolean']>
  readonly isSpecialNeeds?: InputMaybe<Scalars['Boolean']>
  readonly keywords?: InputMaybe<ReadonlyArray<Scalars['String']>>
  readonly metroBranchIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
  readonly metroIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
  readonly rubricIds1?: InputMaybe<ReadonlyArray<Scalars['ID']>>
  readonly rubricIds2?: InputMaybe<ReadonlyArray<Scalars['ID']>>
  readonly salary?: InputMaybe<Scalars['Int']>
  readonly workIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
}

export interface AddManagerAlarmInput {
  readonly alarmTypeId: Scalars['ID']
  readonly email: Scalars['String']
  readonly managerId: Scalars['ID']
  readonly notebookId: Scalars['ID']
  readonly phones?: InputMaybe<ReadonlyArray<Scalars['String']>>
}

export interface AddMarketingGiftRequestInput {
  readonly details: ReadonlyArray<GiftDetailInput>
  readonly eventName: Scalars['String']
}

export interface AddOtherSiteLinkInput {
  readonly companyId: Scalars['Int']
  readonly source: OtherSiteEnum
  readonly url: Scalars['String']
}

export interface AddOtherSiteLinkOutput {
  readonly __typename?: 'AddOtherSiteLinkOutput'
  readonly isSuccess: Scalars['Boolean']
  readonly messageText: Maybe<Scalars['String']>
  readonly vacancyCount: Maybe<Scalars['Int']>
}

export interface AddPromoCodeResponse {
  readonly __typename?: 'AddPromoCodeResponse'
  readonly error: Maybe<AddPublicPromoCodeErrorEnum>
  readonly isSuccess: Scalars['Boolean']
  readonly promoCode: Maybe<PromoCode>
}

export enum AddPublicPromoCodeErrorEnum {
  AlreadyHasActivated = 'ALREADY_HAS_ACTIVATED',
  NotAvailable = 'NOT_AVAILABLE',
  NotFound = 'NOT_FOUND',
  ServerError = 'SERVER_ERROR'
}

export interface AddRegularGiftInput {
  readonly cost: Scalars['Decimal']
  readonly id?: InputMaybe<Scalars['ID']>
  readonly isSouvenir: Scalars['Boolean']
  readonly name: Scalars['String']
  readonly standCount: Scalars['Int']
  readonly storageCount: Scalars['Int']
  readonly weight: Scalars['Int']
}

export interface AddSeekerFavoriteVacanciesInput {
  readonly vacancyIds: ReadonlyArray<Scalars['ID']>
}

export interface AddSeekerFavoriteVacanciesOutput {
  readonly __typename?: 'AddSeekerFavoriteVacanciesOutput'
  readonly query: Query
  readonly vacancies: ReadonlyArray<Vacancy>
}

export type AddSeekerProfResumeAdditionalEducationsError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type AddSeekerProfResumeAdditionalInfoError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type AddSeekerProfResumeEducationsError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type AddSeekerProfResumeExperiencesError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export interface AddSeekerSubscriptionMutations {
  readonly __typename?: 'AddSeekerSubscriptionMutations'
  readonly jobAlert: SubscriptionOutputType
}

export interface AddSeekerSubscriptionMutationsJobAlertArgs {
  input: AddJobAlertSubscriptionInput
}

export interface AddedNewUserManagementEmployerEvent {
  readonly __typename?: 'AddedNewUserManagementEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly employee: EmployerEventSnapshot
  readonly id: Scalars['ID']
  readonly performer: PerformerEmployerEventUnion
}

export interface AddedProfResumeAdditionalEducationsOutput {
  readonly __typename?: 'AddedProfResumeAdditionalEducationsOutput'
  readonly errors: Maybe<ReadonlyArray<AddSeekerProfResumeAdditionalEducationsError>>
  readonly profResume: Maybe<ProfResume>
}

export interface AddedProfResumeAdditionalInfoOutput {
  readonly __typename?: 'AddedProfResumeAdditionalInfoOutput'
  readonly errors: Maybe<ReadonlyArray<AddSeekerProfResumeAdditionalInfoError>>
  readonly profResume: Maybe<ProfResume>
}

export interface AddedProfResumeEducationsOutput {
  readonly __typename?: 'AddedProfResumeEducationsOutput'
  readonly errors: Maybe<ReadonlyArray<AddSeekerProfResumeEducationsError>>
  readonly profResume: Maybe<ProfResume>
}

export interface AddedProfResumeExperiencesOutput {
  readonly __typename?: 'AddedProfResumeExperiencesOutput'
  readonly errors: Maybe<ReadonlyArray<AddSeekerProfResumeExperiencesError>>
  readonly profResume: Maybe<ProfResume>
}

export interface AdditionalCatalogService {
  readonly __typename?: 'AdditionalCatalogService'
  readonly calculatePrice: ServicePrice
  readonly groupWrapper: ServiceGroupWrapper
  readonly id: Scalars['ID']
  readonly name: Maybe<Scalars['String']>
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly typeWrapper: ServiceTypeWrapper
}

export interface AdditionalCatalogServiceCalculatePriceArgs {
  input: InputMaybe<CalculateServicePriceInput>
}

export interface AdditionalServiceEmployerEventSnapshot {
  readonly __typename?: 'AdditionalServiceEmployerEventSnapshot'
  readonly addedAt: Scalars['DateTime']
  readonly catalogService: CatalogService
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export interface AdvertisingBanner extends Banner {
  readonly __typename?: 'AdvertisingBanner'
  readonly background: Scalars['String']
  readonly buttons: ReadonlyArray<BannerButton>
  readonly dueTo: Scalars['DateTime']
  readonly dynamicElement: Maybe<UnionBannerDynamicElement>
  readonly hasCloseButton: Scalars['Boolean']
  readonly id: Scalars['ID']
  readonly image: Maybe<BannerImageElement>
  readonly mainText: BannerTextElement
  readonly title: BannerTextElement
  readonly type: BannerTypeEnum
}

export enum AllowedVacancyActions {
  Approve = 'APPROVE',
  ChangeResponsible = 'CHANGE_RESPONSIBLE',
  Complete = 'COMPLETE',
  Delete = 'DELETE',
  Deny = 'DENY',
  Prolong = 'PROLONG',
  Publish = 'PUBLISH',
  RePublish = 'RE_PUBLISH',
  Suspend = 'SUSPEND'
}

export interface AlreadyReviewedVacancyProlongationError {
  readonly __typename?: 'AlreadyReviewedVacancyProlongationError'
  readonly reason: Scalars['String']
}

export interface AnotherSourceVacancyOutput {
  readonly __typename?: 'AnotherSourceVacancyOutput'
  readonly vacancy: VacancyFromAnotherSourceGraphType
}

export interface AnswerOption {
  readonly __typename?: 'AnswerOption'
  readonly id: Scalars['ID']
  readonly isCorrect: Scalars['Boolean']
  readonly text: Scalars['String']
}

export interface AnswerOptionInput {
  readonly id?: InputMaybe<Scalars['ID']>
  readonly isCorrect: Scalars['Boolean']
  readonly text: Scalars['String']
}

export interface AnswerOptionSuggest {
  readonly __typename?: 'AnswerOptionSuggest'
  readonly id: Maybe<Scalars['ID']>
  readonly isCorrect: Scalars['Boolean']
  readonly text: Scalars['String']
}

export enum ApplicableAnswerTypeEnum {
  MultiChoice = 'MULTI_CHOICE',
  SingleChoice = 'SINGLE_CHOICE',
  Text = 'TEXT'
}

export enum ApplicableAnswerTypeSuggestEnum {
  MultiChoice = 'MULTI_CHOICE',
  SingleChoice = 'SINGLE_CHOICE'
}

export interface ApplicationInvitedNotificationDetails {
  readonly __typename?: 'ApplicationInvitedNotificationDetails'
  readonly application: Maybe<Apply>
  readonly detailType: SeekerNotificationDetailTypeEnum
}

export interface ApplicationRefusedNotificationDetails {
  readonly __typename?: 'ApplicationRefusedNotificationDetails'
  readonly application: Maybe<Apply>
  readonly detailType: SeekerNotificationDetailTypeEnum
}

export interface ApplicationResumeFilling {
  readonly __typename?: 'ApplicationResumeFilling'
  readonly percent: Scalars['UInt']
  readonly type: ResumeFillingType
}

export interface ApplicationSentNotificationDetails {
  readonly __typename?: 'ApplicationSentNotificationDetails'
  readonly application: Maybe<Apply>
  readonly detailType: SeekerNotificationDetailTypeEnum
}

export interface ApplicationViewedNotificationDetails {
  readonly __typename?: 'ApplicationViewedNotificationDetails'
  readonly application: Maybe<Apply>
  readonly detailType: SeekerNotificationDetailTypeEnum
}

export enum ApplicationsDestinationType {
  ApplyPage = 'APPLY_PAGE',
  EmailAndApplyPage = 'EMAIL_AND_APPLY_PAGE',
  Questionnaire = 'QUESTIONNAIRE'
}

export interface ApplicationsViewedEmployerAchievement extends EmployerAchievement {
  readonly __typename?: 'ApplicationsViewedEmployerAchievement'
  readonly currentCount: Scalars['Int']
  readonly isDone: Scalars['Boolean']
  readonly targetCount: Scalars['Int']
  readonly type: EmployerAchievementType
}

export interface Apply {
  readonly __typename?: 'Apply'
  readonly chat: Maybe<Chat>
  readonly coveringLetter: Maybe<ApplyCoveringLetter>
  readonly id: Scalars['ID']
  readonly interactions: ReadonlyArray<CandidateInteraction>
  readonly isRepeatedToVacancy: Scalars['Boolean']
  readonly jobSeeker: Maybe<ApplyJobSeeker>
  readonly lastTimeDeniedAt: Maybe<Scalars['DateTime']>
  readonly lastTimeInvitedAt: Maybe<Scalars['DateTime']>
  readonly madeAt: Scalars['DateTime']
  readonly matchedKeywords: ReadonlyArray<Maybe<CandidateMatchedKeyword>>
  readonly notes: ReadonlyArray<Maybe<ApplyNote>>
  readonly previousApplies: ReadonlyArray<Maybe<Apply>>
  readonly questionnaireAnswersRate: Maybe<Scalars['Int']>
  readonly questionnaireAnswersSummary: Maybe<QuestionnaireAnswersSummary>
  readonly resume: ApplyResumeInterface
  readonly source: CandidateSource
  readonly status: ApplyStatus
  readonly surveyResult: Maybe<ApplySurveyResult>
  readonly user: Maybe<User>
  readonly vacancy: Maybe<Vacancy>
  readonly viewDate: Maybe<Scalars['DateTime']>
}

export interface ApplyInteractionsArgs {
  input: InputMaybe<CandidateInteractionsFilterInput>
}

export interface ApplyAddCompanyAddressInput {
  readonly value: Scalars['String']
}

export interface ApplyAddCompanyAddressOutput {
  readonly __typename?: 'ApplyAddCompanyAddressOutput'
  readonly address: ApplyCompanyAddress
}

export interface ApplyAddNoteInput {
  readonly applyId: Scalars['ID']
  readonly onlyShowToMe: Scalars['Boolean']
  readonly text: Scalars['String']
}

export interface ApplyAddNoteOutput {
  readonly __typename?: 'ApplyAddNoteOutput'
  readonly note: Maybe<ApplyNote>
}

export interface ApplyAggregation {
  readonly __typename?: 'ApplyAggregation'
  readonly items: ReadonlyArray<Facet>
}

export interface ApplyAtsConnectionsFilterInput {
  readonly keyword?: InputMaybe<Scalars['String']>
}

export interface ApplyAtsProject {
  readonly __typename?: 'ApplyAtsProject'
  readonly candidateUrl: Maybe<Scalars['String']>
  readonly cityName: Scalars['String']
  readonly id: Scalars['ID']
  readonly isOwnedByCurrentUser: Scalars['Boolean']
  readonly name: Scalars['String']
  readonly ownerName: Scalars['String']
}

export interface ApplyAtsProjectEdge {
  readonly __typename?: 'ApplyAtsProjectEdge'
  readonly cursor: Scalars['String']
  readonly node: ApplyAtsProject
}

export interface ApplyAttachParsedFileContent {
  readonly __typename?: 'ApplyAttachParsedFileContent'
  readonly experience: Maybe<Scalars['String']>
  readonly phones: ReadonlyArray<Maybe<Scalars['String']>>
  readonly skills: ReadonlyArray<Maybe<Scalars['String']>>
}

export interface ApplyAttachResume extends ApplyResumeInterface {
  readonly __typename?: 'ApplyAttachResume'
  readonly contacts: ApplyResumeContacts
  readonly downloadFileName: Scalars['String']
  readonly downloadFileUrl: Scalars['String']
  readonly fullName: Scalars['String']
  readonly parsedFileContent: Maybe<ApplyAttachParsedFileContent>
  readonly parsedResume: Maybe<ApplyProfResume>
  readonly photoUrl: Scalars['String']
  readonly previewFileUrl: Scalars['String']
}

export interface ApplyChangeStatusInput {
  readonly applyId: Scalars['ID']
  readonly statusId: Scalars['ID']
}

export interface ApplyChangeStatusOutput {
  readonly __typename?: 'ApplyChangeStatusOutput'
  readonly apply: Maybe<Apply>
}

export interface ApplyChangeStatusesInput {
  readonly applyIds: ReadonlyArray<InputMaybe<Scalars['ID']>>
  readonly statusId: Scalars['ID']
}

export interface ApplyChangeStatusesOutput {
  readonly __typename?: 'ApplyChangeStatusesOutput'
  readonly applies: Maybe<ReadonlyArray<Maybe<Apply>>>
}

export interface ApplyCompanyAddress {
  readonly __typename?: 'ApplyCompanyAddress'
  readonly id: Scalars['ID']
  readonly value: Scalars['String']
}

export interface ApplyConnection {
  readonly __typename?: 'ApplyConnection'
  readonly aggregations: ReadonlyArray<ApplyAggregation>
  readonly edges: Maybe<ReadonlyArray<Maybe<ApplyEdge>>>
  readonly items: Maybe<ReadonlyArray<Maybe<Apply>>>
  readonly pageInfo: PageInfo
  readonly totalCount: Maybe<Scalars['Int']>
}

export interface ApplyConnectionAggregationsArgs {
  filter: InputMaybe<ApplyFilterInput>
}

export interface ApplyContactLink {
  readonly __typename?: 'ApplyContactLink'
  readonly type: ApplyContactLinkType
  readonly value: Scalars['String']
}

export enum ApplyContactLinkType {
  ArtStation = 'ART_STATION',
  Behance = 'BEHANCE',
  DeviantArt = 'DEVIANT_ART',
  Dribbble = 'DRIBBBLE',
  Facebook = 'FACEBOOK',
  GitHub = 'GIT_HUB',
  GooglePlus = 'GOOGLE_PLUS',
  Instagram = 'INSTAGRAM',
  LinkedIn = 'LINKED_IN',
  Ok = 'OK',
  Portfolio = 'PORTFOLIO',
  Skype = 'SKYPE',
  Telegram = 'TELEGRAM',
  Twitter = 'TWITTER',
  Viber = 'VIBER',
  Vk = 'VK'
}

export interface ApplyCountFilterInput {
  readonly candidateTypes?: InputMaybe<ReadonlyArray<InputMaybe<CandidateType>>>
  readonly candidateTypesMode?: InputMaybe<CandidatesFilterMode>
  readonly statusIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  readonly statusIdsMode?: InputMaybe<CandidatesFilterMode>
}

export interface ApplyCoveringLetter {
  readonly __typename?: 'ApplyCoveringLetter'
  readonly text: Scalars['String']
}

export interface ApplyDeleteCompanyAddressInput {
  readonly addressId: Scalars['ID']
}

export interface ApplyDeleteCompanyAddressOutput {
  readonly __typename?: 'ApplyDeleteCompanyAddressOutput'
  readonly addressId: Scalars['ID']
}

export interface ApplyDeleteNoteInput {
  readonly noteId: Scalars['ID']
  readonly relatedApplyId?: InputMaybe<Scalars['ID']>
}

export interface ApplyDeleteNoteOutput {
  readonly __typename?: 'ApplyDeleteNoteOutput'
  readonly noteId: Maybe<Scalars['ID']>
}

export interface ApplyEdge {
  readonly __typename?: 'ApplyEdge'
  readonly cursor: Scalars['String']
  readonly node: Maybe<Apply>
}

export interface ApplyEditNoteInput {
  readonly noteId: Scalars['ID']
  readonly onlyShowToMe: Scalars['Boolean']
  readonly relatedApplyId?: InputMaybe<Scalars['ID']>
  readonly text: Scalars['String']
}

export interface ApplyEditNoteOutput {
  readonly __typename?: 'ApplyEditNoteOutput'
  readonly note: Maybe<ApplyNote>
}

export interface ApplyEmail {
  readonly __typename?: 'ApplyEmail'
  readonly confirmed: Scalars['Boolean']
  readonly value: Scalars['String']
}

export interface ApplyEmployer {
  readonly __typename?: 'ApplyEmployer'
  readonly fullName: Scalars['String']
  readonly id: Scalars['ID']
}

export interface ApplyEmptyResume extends ApplyResumeInterface {
  readonly __typename?: 'ApplyEmptyResume'
  readonly contacts: ApplyResumeContacts
  readonly fullName: Scalars['String']
  readonly photoUrl: Scalars['String']
}

export type ApplyFacetUnion = ApplyStatus | CandidateInteractionWrapper | CandidateSourceType | ResumeFillingType

export interface ApplyFilterInput {
  readonly candidateTypes?: InputMaybe<ReadonlyArray<InputMaybe<CandidateType>>>
  readonly candidateTypesMode?: InputMaybe<CandidatesFilterMode>
  readonly interactionTypes?: InputMaybe<ReadonlyArray<CandidateInteractionEnum>>
  readonly keywords?: InputMaybe<Scalars['String']>
  readonly onlyForPublishedVacancies?: InputMaybe<Scalars['Boolean']>
  readonly onlyFromUniqueJobseekers?: InputMaybe<Scalars['Boolean']>
  readonly onlyWithNotes?: InputMaybe<Scalars['Boolean']>
  readonly onlyWithOpenContacts?: InputMaybe<Scalars['Boolean']>
  readonly onlyWithPreviousApplies?: InputMaybe<Scalars['Boolean']>
  readonly resumeFillingTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
  readonly statusIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  readonly statusIdsMode?: InputMaybe<CandidatesFilterMode>
  readonly vacancyId?: InputMaybe<Scalars['ID']>
}

export interface ApplyInvitationDetails {
  readonly __typename?: 'ApplyInvitationDetails'
  readonly addresses: ReadonlyArray<Maybe<ApplyCompanyAddress>>
  readonly customText: Maybe<Scalars['String']>
  readonly defaultText: Scalars['String']
}

export interface ApplyInviteInput {
  readonly addressId?: InputMaybe<Scalars['ID']>
  readonly applyId: Scalars['ID']
  readonly message: Scalars['String']
  readonly sendMeCopyToEmail: Scalars['Boolean']
}

export interface ApplyInviteOutput {
  readonly __typename?: 'ApplyInviteOutput'
  readonly apply: Maybe<Apply>
}

export interface ApplyJobSeeker {
  readonly __typename?: 'ApplyJobSeeker'
  readonly fullName: Scalars['String']
  readonly id: Scalars['ID']
  readonly isOnline: Scalars['Boolean']
  readonly lastActivityAt: Scalars['DateTime']
}

export interface ApplyMassRefuseInput {
  readonly applyIds: ReadonlyArray<InputMaybe<Scalars['ID']>>
  readonly message: Scalars['String']
}

export interface ApplyNote {
  readonly __typename?: 'ApplyNote'
  readonly id: Scalars['ID']
  readonly madeAt: Scalars['DateTime']
  readonly madeBy: ApplyEmployer
  readonly text: Scalars['String']
}

export interface ApplyPhone {
  readonly __typename?: 'ApplyPhone'
  readonly confirmed: Scalars['Boolean']
  readonly value: Scalars['String']
}

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export interface ApplyProfResume extends ApplyResumeInterface {
  readonly __typename?: 'ApplyProfResume'
  readonly additionalEducations: ReadonlyArray<Maybe<ApplyResumeAdditionalEducation>>
  readonly age: Maybe<Scalars['Int']>
  readonly areContactsOpened: Scalars['Boolean']
  readonly birthdate: Maybe<Scalars['DateTime']>
  readonly city: City
  readonly contacts: ApplyResumeContacts
  readonly desiredPosition: ApplyResumeDesiredPosition
  readonly downloadFileName: Scalars['String']
  readonly downloadFileUrl: Scalars['String']
  readonly experiences: ReadonlyArray<Maybe<ApplyResumeExperience>>
  readonly filling: Maybe<ApplicationResumeFilling>
  readonly fullName: Scalars['String']
  readonly gender: ApplyResumeGender
  readonly generalEducations: ReadonlyArray<Maybe<ApplyResumeGeneralEducation>>
  readonly id: Scalars['ID']
  readonly infos: ReadonlyArray<Maybe<ApplyResumeAdditional>>
  readonly isAnonymous: Scalars['Boolean']
  readonly languageSkills: ReadonlyArray<Maybe<ApplyResumeLanguageSkill>>
  readonly name: ApplyProfResumeFullName
  readonly photoUrl: Scalars['String']
  readonly relevanceScore: Maybe<RelevanceScore>
  readonly relocations: ReadonlyArray<Maybe<City>>
  readonly skillsSummary: Maybe<Scalars['String']>
  readonly subRubrics: Maybe<ReadonlyArray<Maybe<Subrubric>>>
}

export interface ApplyProfResumeFullName {
  readonly __typename?: 'ApplyProfResumeFullName'
  readonly fatherName: Maybe<Scalars['String']>
  readonly firstName: Maybe<Scalars['String']>
  readonly lastName: Maybe<Scalars['String']>
}

export interface ApplyProjectAtsConnection {
  readonly __typename?: 'ApplyProjectAtsConnection'
  readonly candidateInDatabaseUrl: Maybe<Scalars['String']>
  readonly edges: Maybe<ReadonlyArray<Maybe<ApplyAtsProjectEdge>>>
  readonly items: Maybe<ReadonlyArray<ApplyAtsProject>>
  readonly pageInfo: PageInfo
  readonly presentInOtherUsersProjectsCount: Scalars['Int']
  readonly totalCount: Maybe<Scalars['Int']>
}

export interface ApplyRefusalDetails {
  readonly __typename?: 'ApplyRefusalDetails'
  readonly customText: Maybe<Scalars['String']>
  readonly defaultText: Scalars['String']
}

export interface ApplyRefuseInput {
  readonly applyId: Scalars['ID']
  readonly message: Scalars['String']
}

export interface ApplyRefuseOutput {
  readonly __typename?: 'ApplyRefuseOutput'
  readonly apply: Maybe<Apply>
}

export interface ApplyResumeAdditional {
  readonly __typename?: 'ApplyResumeAdditional'
  readonly description: Scalars['String']
  readonly title: Scalars['String']
}

export interface ApplyResumeAdditionalEducation {
  readonly __typename?: 'ApplyResumeAdditionalEducation'
  readonly description: Maybe<Scalars['String']>
  readonly institutionTitle: Scalars['String']
  readonly location: Maybe<Scalars['String']>
  readonly yearOfGraduation: Scalars['Int']
}

export interface ApplyResumeContacts {
  readonly __typename?: 'ApplyResumeContacts'
  readonly emails: ReadonlyArray<Maybe<ApplyEmail>>
  readonly phones: ReadonlyArray<Maybe<ApplyPhone>>
  readonly socials: ReadonlyArray<Maybe<ApplyContactLink>>
}

export interface ApplyResumeDesiredPosition {
  readonly __typename?: 'ApplyResumeDesiredPosition'
  readonly salary: Maybe<ApplySalary>
  readonly schedule: Maybe<Schedule>
  readonly title: Scalars['String']
}

export interface ApplyResumeExperience {
  readonly __typename?: 'ApplyResumeExperience'
  readonly begin: Scalars['DateTime']
  readonly companyBranch: Maybe<Branch>
  readonly companyTitle: Scalars['String']
  readonly description: Maybe<Scalars['String']>
  readonly end: Maybe<Scalars['DateTime']>
  readonly position: Scalars['String']
  readonly recommendations: ReadonlyArray<Maybe<ApplyResumeRecommendation>>
}

export interface ApplyResumeFillingTypeCount {
  readonly __typename?: 'ApplyResumeFillingTypeCount'
  readonly count: Scalars['Int']
  readonly type: ResumeFillingType
}

export interface ApplyResumeFillingTypeCountFilterInput {
  readonly vacancyId?: InputMaybe<Scalars['ID']>
}

export enum ApplyResumeGender {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER'
}

export interface ApplyResumeGeneralEducation {
  readonly __typename?: 'ApplyResumeGeneralEducation'
  readonly institutionTitle: Scalars['String']
  readonly level: EducationLevel
  readonly location: Scalars['String']
  readonly speciality: Maybe<Scalars['String']>
  readonly yearOfGraduation: Scalars['Int']
}

export interface ApplyResumeInterface {
  readonly contacts: ApplyResumeContacts
  readonly fullName: Scalars['String']
  readonly photoUrl: Scalars['String']
}

export interface ApplyResumeLanguageSkill {
  readonly __typename?: 'ApplyResumeLanguageSkill'
  readonly canPassInterview: Scalars['Boolean']
  readonly certificate: Maybe<Scalars['String']>
  readonly language: Lang
  readonly level: LanguageSkillLevel
}

export interface ApplyResumeRecommendation {
  readonly __typename?: 'ApplyResumeRecommendation'
  readonly canShowOnDemand: Scalars['Boolean']
  readonly companyTitle: Scalars['String']
  readonly email: Maybe<Scalars['String']>
  readonly fullName: Scalars['String']
  readonly phone: Maybe<Scalars['String']>
  readonly position: Scalars['String']
}

export interface ApplySalary {
  readonly __typename?: 'ApplySalary'
  readonly amount: Scalars['Int']
  readonly currency: Currency
}

export enum ApplySortType {
  FirstNew = 'FIRST_NEW',
  FirstNotViewed = 'FIRST_NOT_VIEWED',
  FirstOld = 'FIRST_OLD',
  RelevanceScore = 'RELEVANCE_SCORE',
  ScreeningRelevance = 'SCREENING_RELEVANCE'
}

export interface ApplyStatus {
  readonly __typename?: 'ApplyStatus'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export interface ApplyStatusCount {
  readonly __typename?: 'ApplyStatusCount'
  readonly count: Scalars['Int']
  readonly status: ApplyStatus
}

export interface ApplyStatusCountFilterInput {
  readonly onlyForPublishedVacancies?: InputMaybe<Scalars['Boolean']>
  readonly vacancyId?: InputMaybe<Scalars['ID']>
}

export interface ApplySurverQuestionImportance {
  readonly __typename?: 'ApplySurverQuestionImportance'
  readonly id: Scalars['ID']
  readonly value: Scalars['String']
}

export interface ApplySurveyAnswer {
  readonly __typename?: 'ApplySurveyAnswer'
  readonly correctAnswer: Scalars['String']
  readonly importance: ApplySurverQuestionImportance
  readonly question: Scalars['String']
  readonly userAnswer: Scalars['String']
}

export interface ApplySurveyResult {
  readonly __typename?: 'ApplySurveyResult'
  readonly answers: ReadonlyArray<Maybe<ApplySurveyAnswer>>
  readonly percentage: Scalars['Int']
}

export type ApplyToAtsExportError = InvalidError | NotAvailableError | ServerError

export interface ApplyToAtsExportInput {
  readonly applyId: Scalars['ID']
  readonly projectId?: InputMaybe<Scalars['ID']>
}

export interface ApplyToAtsExportOutput {
  readonly __typename?: 'ApplyToAtsExportOutput'
  readonly error: Maybe<ApplyToAtsExportError>
  readonly result: Maybe<ApplyToAtsExportResult>
}

export interface ApplyToAtsExportResult {
  readonly __typename?: 'ApplyToAtsExportResult'
  readonly candidateDatabaseUrl: Scalars['String']
  readonly candidateProjectUrl: Maybe<Scalars['String']>
}

export interface ApplyUpdateCompanyAddressInput {
  readonly addressId: Scalars['ID']
  readonly value: Scalars['ID']
}

export interface ApplyUpdateCompanyAddressOutput {
  readonly __typename?: 'ApplyUpdateCompanyAddressOutput'
  readonly address: ApplyCompanyAddress
}

export interface ApplyVacancyKeyword {
  readonly __typename?: 'ApplyVacancyKeyword'
  readonly text: Scalars['String']
}

export enum ApplyWalletBonusCodeErrorEnum {
  CannotBeAppliedWrongBonusAmount = 'CANNOT_BE_APPLIED_WRONG_BONUS_AMOUNT',
  EmptyCart = 'EMPTY_CART',
  NotFound = 'NOT_FOUND',
  ServerError = 'SERVER_ERROR'
}

export interface ApplyWalletBonusResponse {
  readonly __typename?: 'ApplyWalletBonusResponse'
  readonly cart: CartQuery
  readonly errors: ReadonlyArray<ApplyWalletBonusCodeErrorEnum>
  readonly isSuccess: Scalars['Boolean']
}

export interface ApprovedProlongationRequestVacancyEmployerEvent {
  readonly __typename?: 'ApprovedProlongationRequestVacancyEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly performer: PerformerEmployerEventUnion
  readonly vacancyCity: City
  readonly vacancyId: Scalars['ID']
  readonly vacancyName: Scalars['String']
  readonly vacancyOwner: EmployerEventSnapshot
}

export interface ApprovedReviewVacancyEmployerEvent {
  readonly __typename?: 'ApprovedReviewVacancyEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly performer: PerformerEmployerEventUnion
  readonly vacancyCity: City
  readonly vacancyId: Scalars['ID']
  readonly vacancyName: Scalars['String']
  readonly vacancyOwner: EmployerEventSnapshot
}

export interface Assignment {
  readonly __typename?: 'Assignment'
  readonly attaches: ReadonlyArray<FileAttach>
  readonly company: Maybe<CrmCompany>
  readonly completeAt: Maybe<Scalars['DateTimeOffset']>
  readonly contactType: Maybe<TaskContactTypeEnum>
  readonly contactWarmth: Maybe<ContactWarmthTypeEnum>
  readonly description: Scalars['String']
  readonly employee: Maybe<CrmEmployee>
  readonly events: ReadonlyArray<EventInterface>
  readonly id: Scalars['ID']
  readonly initiator: Maybe<CrmManager>
  readonly isArchive: Scalars['Boolean']
  readonly isInvokedByEvent: Maybe<Scalars['Boolean']>
  readonly isWholeDay: Scalars['Boolean']
  readonly madeAt: Maybe<Scalars['DateTimeOffset']>
  readonly nextReschedule: Maybe<Scalars['DateTimeOffset']>
  readonly planAt: Maybe<Scalars['DateTimeOffset']>
  readonly plannedTask: Maybe<Assignment>
  readonly points: Scalars['Int']
  readonly priority: Maybe<TaskPriorityEnum>
  readonly readAt: Maybe<Scalars['DateTimeOffset']>
  readonly rescheduleStepHours: Maybe<Scalars['Float']>
  readonly rescheduledCalls: Scalars['Int']
  readonly responsible: Maybe<CrmManager>
  readonly result: Maybe<Scalars['String']>
  readonly sentAt: Maybe<Scalars['DateTimeOffset']>
  readonly sortAt: Maybe<Scalars['DateTimeOffset']>
  readonly status: Maybe<TaskStatusEnum>
  readonly title: Scalars['String']
}

export interface AssignmentCompleteInput {
  readonly completedTopics?: InputMaybe<ReadonlyArray<InputMaybe<EventTopicCompleteInput>>>
  readonly contactType?: InputMaybe<TaskContactTypeEnum>
  readonly contactWarmth?: InputMaybe<ContactWarmthTypeEnum>
  readonly employeeId?: InputMaybe<Scalars['ID']>
  readonly eventsIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  readonly id?: InputMaybe<Scalars['ID']>
  readonly result?: InputMaybe<Scalars['String']>
  readonly taskStatus?: InputMaybe<TaskStatusEnum>
}

export interface AssignmentConnection {
  readonly __typename?: 'AssignmentConnection'
  readonly edges: Maybe<ReadonlyArray<Maybe<AssignmentEdge>>>
  readonly items: Maybe<ReadonlyArray<Assignment>>
  readonly pageInfo: PageInfo
  readonly totalCount: Maybe<Scalars['Int']>
}

export interface AssignmentCreateInput {
  readonly callId?: InputMaybe<Scalars['ID']>
  readonly companyId?: InputMaybe<Scalars['ID']>
  readonly completeDate?: InputMaybe<Scalars['DateTimeOffset']>
  readonly contactType?: InputMaybe<TaskContactTypeEnum>
  readonly contactWarmth?: InputMaybe<ContactWarmthTypeEnum>
  readonly description?: InputMaybe<Scalars['String']>
  readonly employeeId?: InputMaybe<Scalars['ID']>
  readonly eventsIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  readonly initiatorId?: InputMaybe<Scalars['ID']>
  readonly isWholeDay?: InputMaybe<Scalars['String']>
  readonly multiUserId?: InputMaybe<Scalars['ID']>
  readonly name?: InputMaybe<Scalars['String']>
  readonly planDate?: InputMaybe<Scalars['DateTimeOffset']>
  readonly priority?: InputMaybe<TaskPriorityEnum>
  readonly programId?: InputMaybe<Scalars['ID']>
  readonly responsibleManagerId?: InputMaybe<Scalars['ID']>
  readonly result?: InputMaybe<Scalars['String']>
  readonly sentDate?: InputMaybe<Scalars['DateTimeOffset']>
  readonly status?: InputMaybe<TaskStatusEnum>
}

export interface AssignmentEdge {
  readonly __typename?: 'AssignmentEdge'
  readonly cursor: Scalars['String']
  readonly node: Assignment
}

export interface AssignmentOperationOutput {
  readonly __typename?: 'AssignmentOperationOutput'
  readonly assignment: Maybe<Assignment>
  readonly isSuccess: Scalars['Boolean']
}

export interface AssignmentUpdateInput {
  readonly callId?: InputMaybe<Scalars['ID']>
  readonly companyId?: InputMaybe<Scalars['ID']>
  readonly completeDate?: InputMaybe<Scalars['DateTimeOffset']>
  readonly completedTopics?: InputMaybe<ReadonlyArray<InputMaybe<EventTopicCompleteInput>>>
  readonly contactType?: InputMaybe<TaskContactTypeEnum>
  readonly contactWarmth?: InputMaybe<ContactWarmthTypeEnum>
  readonly description?: InputMaybe<Scalars['String']>
  readonly employeeId?: InputMaybe<Scalars['ID']>
  readonly eventsIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  readonly id?: InputMaybe<Scalars['ID']>
  readonly initiatorId?: InputMaybe<Scalars['ID']>
  readonly isWholeDay?: InputMaybe<Scalars['String']>
  readonly multiUserId?: InputMaybe<Scalars['ID']>
  readonly name?: InputMaybe<Scalars['String']>
  readonly planDate?: InputMaybe<Scalars['DateTimeOffset']>
  readonly priority?: InputMaybe<TaskPriorityEnum>
  readonly programId?: InputMaybe<Scalars['ID']>
  readonly rescheduledCalls?: InputMaybe<Scalars['Int']>
  readonly responsibleManagerId?: InputMaybe<Scalars['ID']>
  readonly result?: InputMaybe<Scalars['String']>
  readonly sentDate?: InputMaybe<Scalars['DateTimeOffset']>
  readonly status?: InputMaybe<TaskStatusEnum>
}

export interface AtsProjectCreationError extends Error {
  readonly __typename?: 'AtsProjectCreationError'
  readonly reason: Scalars['String']
}

export interface AttachNotFound extends ResumeError {
  readonly __typename?: 'AttachNotFound'
  readonly message: Scalars['String']
}

export interface AttachParsingError extends ResumeError {
  readonly __typename?: 'AttachParsingError'
  readonly message: Scalars['String']
}

export interface AutoProlongationDisabledVacancyEmployerEvent {
  readonly __typename?: 'AutoProlongationDisabledVacancyEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly performer: PerformerEmployerEventUnion
  readonly vacancyCity: City
  readonly vacancyId: Scalars['ID']
  readonly vacancyName: Scalars['String']
}

export interface AutoProlongationEnabledVacancyEmployerEvent {
  readonly __typename?: 'AutoProlongationEnabledVacancyEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly autoActivateService: Maybe<CatalogService>
  readonly duration: AutoProlongationVacancyDurationEmployerEventEnum
  readonly hasServiceAutoActivation: Scalars['Boolean']
  readonly id: Scalars['ID']
  readonly performer: PerformerEmployerEventUnion
  readonly vacancyCity: City
  readonly vacancyId: Scalars['ID']
  readonly vacancyName: Scalars['String']
  readonly vacancyPublicationType: VacancyPublicationTypeWrapper
}

export enum AutoProlongationVacancyDurationEmployerEventEnum {
  RepublishEveryNextMonth = 'REPUBLISH_EVERY_NEXT_MONTH',
  RepublishForOneMonth = 'REPUBLISH_FOR_ONE_MONTH'
}

export interface AutoProlongedVacancyEmployerEvent {
  readonly __typename?: 'AutoProlongedVacancyEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly performer: SystemEventPerformer
  readonly vacancyCity: City
  readonly vacancyId: Scalars['ID']
  readonly vacancyName: Scalars['String']
}

export interface BanInfo {
  readonly __typename?: 'BanInfo'
  readonly banReason: Maybe<ReadonlyArray<BanReasonInfo>>
  readonly isBannedByModerator: Scalars['Boolean']
}

export interface BanReasonInfo {
  readonly __typename?: 'BanReasonInfo'
  readonly id: Scalars['ID']
  readonly text: Scalars['String']
  readonly text2: Scalars['String']
  readonly text2Ukr: Scalars['String']
  readonly text3: Scalars['String']
  readonly text3Ukr: Scalars['String']
}

export interface Banner {
  readonly background: Scalars['String']
  readonly buttons: ReadonlyArray<BannerButton>
  readonly dueTo: Scalars['DateTime']
  readonly hasCloseButton: Scalars['Boolean']
  readonly id: Scalars['ID']
  readonly image: Maybe<BannerImageElement>
  readonly mainText: BannerTextElement
  readonly type: BannerTypeEnum
}

export interface BannerButton {
  readonly __typename?: 'BannerButton'
  readonly message: Scalars['String']
  readonly type: BannerButtonTypeEnum
  readonly url: Scalars['String']
  readonly urlType: BannerButtonLinkTypeEnum
}

export enum BannerButtonLinkTypeEnum {
  Anchor = 'Anchor',
  Redirect = 'Redirect',
  Request = 'Request'
}

export enum BannerButtonTypeEnum {
  Additional = 'Additional',
  Link = 'Link',
  Main = 'Main'
}

export interface BannerClock extends BannerDynamicElement {
  readonly __typename?: 'BannerClock'
  readonly end: Scalars['DateTime']
  readonly message: Scalars['String']
  readonly type: BannerDynamicElementTypeEnum
}

export enum BannerDisplayPagesEnum {
  Candidates = 'Candidates',
  Home = 'HOME',
  Vacancies = 'Vacancies'
}

export interface BannerDynamicElement {
  readonly message: Scalars['String']
  readonly type: BannerDynamicElementTypeEnum
}

export enum BannerDynamicElementTypeEnum {
  Clock = 'Clock',
  ProgressBar = 'ProgressBar'
}

export interface BannerImageElement {
  readonly __typename?: 'BannerImageElement'
  readonly sizes: ReadonlyArray<BannerImageSize>
}

export interface BannerImageSize {
  readonly __typename?: 'BannerImageSize'
  readonly size: BannerImageSizeEnum
  readonly url: Scalars['String']
}

export enum BannerImageSizeEnum {
  Large = 'Large',
  Medium = 'Medium',
  Small = 'Small'
}

export interface BannerProgressBar extends BannerDynamicElement {
  readonly __typename?: 'BannerProgressBar'
  readonly current: Scalars['Int']
  readonly max: Scalars['Int']
  readonly message: Scalars['String']
  readonly type: BannerDynamicElementTypeEnum
}

export interface BannerQueries {
  readonly __typename?: 'BannerQueries'
  readonly myAdvertising: Maybe<AdvertisingBanner>
  readonly myInformational: ReadonlyArray<InformationBanner>
}

export interface BannerTextElement {
  readonly __typename?: 'BannerTextElement'
  readonly color: Scalars['String']
  readonly text: Scalars['String']
}

export enum BannerTypeEnum {
  Advertising = 'Advertising',
  Information = 'Information'
}

export type BasicQuestion = ExperienceBasicQuestion | LanguageBasicQuestion

export interface BasicQuestionItem {
  readonly __typename?: 'BasicQuestionItem'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export enum BasicQuestionTypeEnum {
  Experience = 'EXPERIENCE',
  Language = 'LANGUAGE'
}

export interface BillFilters {
  readonly __typename?: 'BillFilters'
  readonly hasActs: Maybe<Scalars['Boolean']>
  readonly offset: Maybe<Scalars['String']>
  readonly quarters: Maybe<ReadonlyArray<Maybe<Quarter>>>
  readonly states: Maybe<ReadonlyArray<Maybe<CrmAccountState>>>
}

export interface BlockedUserManagementEmployerEvent {
  readonly __typename?: 'BlockedUserManagementEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly employee: EmployerEventSnapshot
  readonly id: Scalars['ID']
  readonly performer: PerformerEmployerEventUnion
}

export interface Branch {
  readonly __typename?: 'Branch'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly popularProfessionsTags: Maybe<ReadonlyArray<Maybe<TagGroup>>>
}

export interface BriefParsedCv {
  readonly __typename?: 'BriefParsedCv'
  readonly added: Scalars['DateTimeOffset']
  readonly assinged: Maybe<Scalars['String']>
  readonly assingedName: Maybe<Scalars['String']>
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly state: ParsedCvStateEnum
  readonly text: Scalars['String']
}

export interface BriefParsedCvEdge {
  readonly __typename?: 'BriefParsedCvEdge'
  readonly cursor: Scalars['String']
  readonly node: BriefParsedCv
}

export interface Bucket {
  readonly __typename?: 'Bucket'
  readonly docCount: Maybe<Scalars['Int']>
  readonly salaryFrom: Maybe<Scalars['Int']>
  readonly salaryTo: Maybe<Scalars['Int']>
}

export interface CabinetEventsDateRange {
  readonly __typename?: 'CabinetEventsDateRange'
  readonly begin: Maybe<Scalars['DateTime']>
  readonly end: Maybe<Scalars['DateTime']>
}

export interface CabinetEventsFilter {
  readonly __typename?: 'CabinetEventsFilter'
  readonly dateRange: CabinetEventsDateRange
  readonly employeeIds: Maybe<ReadonlyArray<Scalars['ID']>>
  readonly types: Maybe<ReadonlyArray<EmployerEventTypeEnum>>
}

export interface CabinetEventsQueryFilter {
  readonly __typename?: 'CabinetEventsQueryFilter'
  readonly filter: CabinetEventsFilter
  readonly page: Scalars['Int']
}

export interface CalculatePriceItem {
  readonly __typename?: 'CalculatePriceItem'
  readonly price: Maybe<ServicePrice>
  readonly requestFilter: CalculatePricesFilter
}

export interface CalculatePricesFilter {
  readonly __typename?: 'CalculatePricesFilter'
  readonly city: Maybe<City>
  readonly count: Scalars['Int']
  readonly id: Scalars['ID']
  readonly rubric: Maybe<Rubric>
  readonly serviceType: ServiceTypeWrapper
  readonly usagePeriod: Maybe<UsagePeriodTypeWrapper>
}

export interface CalculatePricesInput {
  readonly appliedPromoCodeIds?: InputMaybe<ReadonlyArray<Scalars['Guid']>>
  readonly appliedTotalBonusAmount: Scalars['Float']
  readonly cityId?: InputMaybe<Scalars['ID']>
  readonly count: Scalars['Int']
  readonly id: Scalars['ID']
  readonly rubricId?: InputMaybe<Scalars['ID']>
  readonly serviceType: Scalars['ID']
  readonly usagePeriodId?: InputMaybe<Scalars['ID']>
}

export interface CalculateServicePriceInput {
  readonly count: Scalars['Int']
}

export interface CalculateServicePriceMultipleInput {
  readonly counts: ReadonlyArray<Scalars['Int']>
}

export interface CallFilters {
  readonly __typename?: 'CallFilters'
  readonly companyId: Maybe<Scalars['ID']>
  readonly cursor: Maybe<Scalars['String']>
  readonly date: Maybe<DateInterval>
  readonly query: Maybe<Scalars['String']>
  readonly types: Maybe<ReadonlyArray<Maybe<CrmCallTypeRequest>>>
}

export interface CandidateInteraction {
  readonly __typename?: 'CandidateInteraction'
  readonly accuredAt: Scalars['DateTime']
  readonly type: CandidateInteractionWrapper
  readonly vacancy: Maybe<Vacancy>
}

export enum CandidateInteractionEnum {
  ContactOpened = 'CONTACT_OPENED',
  Saved = 'SAVED',
  Viewed = 'VIEWED'
}

export interface CandidateInteractionWrapper {
  readonly __typename?: 'CandidateInteractionWrapper'
  readonly id: Scalars['ID']
  readonly type: CandidateInteractionEnum
}

export interface CandidateInteractionsFilterInput {
  readonly vacancyIds: ReadonlyArray<Scalars['ID']>
}

export interface CandidateMatchedKeyword {
  readonly __typename?: 'CandidateMatchedKeyword'
  readonly text: Scalars['String']
}

export interface CandidateResumeEmployerEventSnapshot {
  readonly __typename?: 'CandidateResumeEmployerEventSnapshot'
  readonly fullName: Scalars['String']
  readonly id: Scalars['ID']
  readonly speciality: Scalars['String']
}

export enum CandidateSource {
  Application = 'APPLICATION',
  Recommended = 'RECOMMENDED',
  Selected = 'SELECTED',
  VacancyInteraction = 'VACANCY_INTERACTION',
  VacancyOffered = 'VACANCY_OFFERED'
}

export interface CandidateSourceType {
  readonly __typename?: 'CandidateSourceType'
  readonly id: Scalars['ID']
  readonly type: CandidateType
}

export enum CandidateType {
  Application = 'APPLICATION',
  ApplicationWithFile = 'APPLICATION_WITH_FILE',
  ApplicationWithResume = 'APPLICATION_WITH_RESUME',
  Recommended = 'RECOMMENDED',
  SelectedResume = 'SELECTED_RESUME',
  VacancyInteraction = 'VACANCY_INTERACTION',
  VacancyOffered = 'VACANCY_OFFERED'
}

export enum CandidatesFilterMode {
  Exclusion = 'EXCLUSION',
  Inclusion = 'INCLUSION'
}

export interface CandidatesVacancyCounters {
  readonly __typename?: 'CandidatesVacancyCounters'
  readonly new: Scalars['Int']
  readonly total: Scalars['Int']
  readonly type: CandidateSourceType
}

export interface CartItem {
  readonly __typename?: 'CartItem'
  readonly appliedPromoCodes: ReadonlyArray<PromoCode>
  readonly appliedTotalBonusAmount: Scalars['Float']
  readonly city: Maybe<City>
  readonly count: Scalars['Int']
  readonly id: Scalars['ID']
  readonly nearPrices: ReadonlyArray<ServicePrice>
  readonly price: ServicePrice
  readonly rubric: Maybe<Rubric>
  readonly service: CatalogUnitedServices
  readonly serviceId: Scalars['ID']
  readonly type: ServiceTypeWrapper
  readonly usagePeriod: Maybe<UsagePeriodTypeWrapper>
}

export interface CartItemInput {
  readonly cityId?: InputMaybe<Scalars['Int']>
  readonly count: Scalars['Int']
  readonly rubricId?: InputMaybe<Scalars['Int']>
  readonly serviceId: Scalars['ID']
  readonly serviceTypeId: Scalars['ID']
  readonly usagePeriodId?: InputMaybe<Scalars['ID']>
}

export interface CartMutation {
  readonly __typename?: 'CartMutation'
  readonly add: CartQuery
  readonly orders: OrderCartMutation
  readonly promoCodes: PromoCodeCartMutation
  readonly remove: CartQuery
  readonly update: CartQuery
  readonly walletBonuses: WalletCartMutation
}

export interface CartMutationAddArgs {
  input: ReadonlyArray<InputMaybe<CartItemInput>>
}

export interface CartMutationRemoveArgs {
  input: CartRemoveItemsInput
}

export interface CartMutationUpdateArgs {
  input: ReadonlyArray<InputMaybe<CartItemInput>>
}

export interface CartQuery {
  readonly __typename?: 'CartQuery'
  readonly id: Scalars['ID']
  readonly items: ReadonlyArray<CartItem>
  readonly promoCodes: Maybe<PromoCodeConnection>
  readonly total: Scalars['Int']
}

export interface CartQueryPromoCodesArgs {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  sort: InputMaybe<PromoCodeSortEnum>
}

export interface CartRemoveItemsInput {
  readonly items: ReadonlyArray<Scalars['Guid']>
}

export interface CatalogChildService {
  readonly __typename?: 'CatalogChildService'
  readonly count: Scalars['Int']
  readonly service: CatalogUnitedServices
}

export interface CatalogService {
  readonly __typename?: 'CatalogService'
  readonly detailsUnion: CatalogUnitedServices
  readonly id: Scalars['ID']
}

export enum CatalogServiceCategory {
  All = 'ALL',
  CrmSpecific = 'CRM_SPECIFIC',
  EmployerSpecific = 'EMPLOYER_SPECIFIC'
}

export enum CatalogServiceDiscountEnum {
  EverySecond = 'EVERY_SECOND',
  Percent = 'PERCENT',
  PercentForSomeCount = 'PERCENT_FOR_SOME_COUNT',
  Wholesale = 'WHOLESALE'
}

export interface CatalogServicesFilterInput {
  readonly cityIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
  readonly serviceCategory: CatalogServiceCategory
  readonly serviceGroup?: InputMaybe<ServiceGroupEnum>
  readonly serviceType?: InputMaybe<ServiceType>
}

export type CatalogUnitedServices =
  | AdditionalCatalogService
  | CvDbCatalogService
  | HotCatalogService
  | LogoInPortalCatalogService
  | LogoInRubricCatalogService
  | LogoOnMainPageCatalogService
  | MarketingCatalogService
  | RisingUpCatalogService
  | VacancyPackageCatalogService
  | VacancyPublicationCatalogService

export interface CategorizationAttachesMutations {
  readonly __typename?: 'CategorizationAttachesMutations'
  readonly addComment: CategorizationResultOutput
  readonly setPositionTags: CategorizationResultOutput
  readonly setRubrics: CategorizationResultOutput
  readonly setState: CategorizationResultOutput
  readonly setTextTags: CategorizationResultOutput
  readonly updateAdamLabel: CategorizationResultOutput
  readonly updateCvName: CategorizationResultOutput
  readonly updateLabel: CategorizationResultOutput
}

export interface CategorizationAttachesMutationsAddCommentArgs {
  cvId: Scalars['ID']
  note: Scalars['String']
}

export interface CategorizationAttachesMutationsSetPositionTagsArgs {
  input: EditCvTagInput
}

export interface CategorizationAttachesMutationsSetRubricsArgs {
  input: SetCvRubricInput
}

export interface CategorizationAttachesMutationsSetStateArgs {
  input: SetCvStateInput
}

export interface CategorizationAttachesMutationsSetTextTagsArgs {
  input: EditCvTagInput
}

export interface CategorizationAttachesMutationsUpdateAdamLabelArgs {
  input: AddAdamLabelInput
}

export interface CategorizationAttachesMutationsUpdateCvNameArgs {
  cvId: Scalars['ID']
  name: Scalars['String']
}

export interface CategorizationAttachesMutationsUpdateLabelArgs {
  input: EditLabelInput
}

export interface CategorizationAttachesQueries {
  readonly __typename?: 'CategorizationAttachesQueries'
  readonly adamLabels: ReadonlyArray<AdamLabel>
  readonly categorizators: ReadonlyArray<Categorizator>
  readonly parsedCv: Maybe<ParsedCv>
  readonly parsedCvList: Maybe<ParsedCvConnection>
  readonly positionTags: ReadonlyArray<SynonymTag>
  readonly textTags: ReadonlyArray<SynonymTag>
}

export interface CategorizationAttachesQueriesParsedCvArgs {
  cvId: Scalars['ID']
}

export interface CategorizationAttachesQueriesParsedCvListArgs {
  after: InputMaybe<Scalars['String']>
  filter: InputMaybe<FilteredCvListInput>
  first: InputMaybe<Scalars['Int']>
}

export interface CategorizationAttachesQueriesPositionTagsArgs {
  term: Scalars['String']
}

export interface CategorizationAttachesQueriesTextTagsArgs {
  term: Scalars['String']
}

export interface CategorizationResultOutput {
  readonly __typename?: 'CategorizationResultOutput'
  readonly isSuccess: Scalars['Boolean']
  readonly message: Maybe<Scalars['String']>
}

export interface Categorizator {
  readonly __typename?: 'Categorizator'
  readonly email: Scalars['String']
  readonly name: Scalars['String']
}

export type ChangeSavedCardsErrorUnion = HasNoScopesForActionError | InvalidError | ServerError | UserIsNotAuthenticatedError

export interface ChangeSavedCardsResponse {
  readonly __typename?: 'ChangeSavedCardsResponse'
  readonly errors: ReadonlyArray<ChangeSavedCardsErrorUnion>
  readonly isSuccess: Scalars['Boolean']
  readonly savedCards: ReadonlyArray<SavedCard>
}

export interface ChangeSeekerEmailInput {
  readonly newEmail: Scalars['String']
  readonly password: Scalars['String']
}

export interface ChangeSeekerEmailMutation {
  readonly __typename?: 'ChangeSeekerEmailMutation'
  readonly addPasswordAndSendConfirmationEmail: SendChangeSeekerEmailOutput
  readonly confirm: ChangeSeekerEmailOutput
  readonly sendConfirmationEmail: SendChangeSeekerEmailOutput
}

export interface ChangeSeekerEmailMutationAddPasswordAndSendConfirmationEmailArgs {
  input: ChangeSeekerEmailInput
}

export interface ChangeSeekerEmailMutationConfirmArgs {
  key: Scalars['String']
}

export interface ChangeSeekerEmailMutationSendConfirmationEmailArgs {
  input: ChangeSeekerEmailInput
}

export interface ChangeSeekerEmailOutput {
  readonly __typename?: 'ChangeSeekerEmailOutput'
  readonly isSuccess: Scalars['Boolean']
  readonly token: Maybe<Scalars['String']>
}

export interface ChangeSeekerNameMutationInput {
  readonly name: Scalars['String']
  readonly surname: Scalars['String']
}

export interface ChangeSeekerNameMutationOutput {
  readonly __typename?: 'ChangeSeekerNameMutationOutput'
  readonly seeker: User
}

export interface ChangeSeekerPasswordByEmailInput {
  readonly code: Scalars['String']
  readonly newPassword: Scalars['String']
}

export interface ChangeSeekerPasswordByEmailMutations {
  readonly __typename?: 'ChangeSeekerPasswordByEmailMutations'
  readonly confirmAndChange: ChangeSeekerPasswordOutput
  readonly sendConfirmation: SendEmailOutput
}

export interface ChangeSeekerPasswordByEmailMutationsConfirmAndChangeArgs {
  input: ChangeSeekerPasswordByEmailInput
}

export interface ChangeSeekerPasswordByPhoneInput {
  readonly code: Scalars['String']
  readonly newPassword: Scalars['String']
}

export interface ChangeSeekerPasswordByPhoneMutations {
  readonly __typename?: 'ChangeSeekerPasswordByPhoneMutations'
  readonly confirmAndChange: ChangeSeekerPasswordOutput
  readonly confirmCode: PhoneConfirmationOutput
  readonly sendConfirmation: SendSmsOutput
}

export interface ChangeSeekerPasswordByPhoneMutationsConfirmAndChangeArgs {
  input: ChangeSeekerPasswordByPhoneInput
}

export interface ChangeSeekerPasswordByPhoneMutationsConfirmCodeArgs {
  code: Scalars['String']
}

export interface ChangeSeekerPasswordMutations {
  readonly __typename?: 'ChangeSeekerPasswordMutations'
  readonly byEmail: ChangeSeekerPasswordByEmailMutations
  readonly byPhone: ChangeSeekerPasswordByPhoneMutations
}

export interface ChangeSeekerPasswordOutput {
  readonly __typename?: 'ChangeSeekerPasswordOutput'
  readonly status: ChangeSeekerPasswordStatusEnum
}

export enum ChangeSeekerPasswordStatusEnum {
  CodeInvalid = 'CODE_INVALID',
  Failed = 'FAILED',
  Success = 'SUCCESS'
}

export interface ChangedFullNameUserManagementEmployerEvent {
  readonly __typename?: 'ChangedFullNameUserManagementEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly employee: Employee
  readonly fullNameAfter: Scalars['String']
  readonly fullNameBefore: Scalars['String']
  readonly id: Scalars['ID']
  readonly performer: PerformerEmployerEventUnion
}

export interface ChangedScopesUserManagementEmployerEvent {
  readonly __typename?: 'ChangedScopesUserManagementEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly performer: PerformerEmployerEventUnion
  readonly scopes: ReadonlyArray<EmployeeScopeChangeUserManagementEmployerEvent>
  readonly targetEmployee: EmployerEventSnapshot
}

export interface Chat {
  readonly __typename?: 'Chat'
  readonly contextName: Scalars['String']
  readonly id: Scalars['ID']
  readonly messages: Maybe<MessagesConnection>
  readonly name: Scalars['String']
  readonly unreadMessagesCount: Scalars['Int']
}

export interface ChatMessagesArgs {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
}

export interface ChatMessage {
  readonly __typename?: 'ChatMessage'
  readonly content: Maybe<Scalars['String']>
  readonly createdAt: Scalars['DateTime']
  readonly id: Scalars['ID']
}

export interface CheckPayer {
  readonly __typename?: 'CheckPayer'
  readonly company: CrmCompany
  readonly payer: Scalars['ID']
}

export interface CheckPayerResultOutput {
  readonly __typename?: 'CheckPayerResultOutput'
  readonly found: ReadonlyArray<CheckPayer>
  readonly isValidCode: Scalars['Boolean']
}

export interface CheckVacancyContentCorrectnessForPublishingOutput {
  readonly __typename?: 'CheckVacancyContentCorrectnessForPublishingOutput'
  readonly isCorrect: Scalars['Boolean']
}

export interface CitiesConnection {
  readonly __typename?: 'CitiesConnection'
  readonly edges: Maybe<ReadonlyArray<CityEdge>>
  readonly nodes: Maybe<ReadonlyArray<City>>
  readonly pageInfo: PageInfo
  readonly totalCount: Scalars['Int']
}

export interface CitiesConnectionInput {
  readonly keyword?: InputMaybe<Scalars['String']>
}

export interface City {
  readonly __typename?: 'City'
  readonly en: Maybe<Scalars['String']>
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly regionName: Maybe<Scalars['String']>
  readonly ru: Maybe<Scalars['String']>
  readonly statistic: Maybe<StatisticData>
  readonly ua: Maybe<Scalars['String']>
}

export interface CityStatisticArgs {
  input: StatisticDataKeywordRubricInput
}

export interface CityDistrict {
  readonly __typename?: 'CityDistrict'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export interface CityEdge {
  readonly __typename?: 'CityEdge'
  readonly cursor: Scalars['String']
  readonly node: City
}

export type CloseBannerError = InvalidError | NotFoundError | ServerError | UserIsNotAuthenticatedError

export interface CloseBannerResponse {
  readonly __typename?: 'CloseBannerResponse'
  readonly error: Maybe<CloseBannerError>
  readonly isSuccess: Scalars['Boolean']
}

export interface ClosedAdditionalService {
  readonly __typename?: 'ClosedAdditionalService'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly serviceId: Scalars['ID']
  readonly spentAt: Maybe<Scalars['Date']>
  readonly state: Maybe<ServiceState>
  readonly type: Maybe<ServiceType>
}

export interface ClosedCvDbService {
  readonly __typename?: 'ClosedCvDbService'
  readonly activatedAt: Maybe<Scalars['Date']>
  readonly activator: Maybe<ServiceActivator>
  readonly city: City
  readonly contactsAccrualPeriod: Maybe<ContactsAccrualPeriod>
  readonly endedAt: Maybe<Scalars['Date']>
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly rubric: Rubric
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly serviceId: Scalars['ID']
  readonly state: Maybe<ServiceState>
  readonly totalContacts: Maybe<Scalars['Int']>
  readonly type: Maybe<ServiceType>
  readonly usagePeriod: Maybe<ServiceUsagePeriod>
}

export interface ClosedHotService {
  readonly __typename?: 'ClosedHotService'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly parentServiceId: Maybe<Scalars['ID']>
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly serviceId: Scalars['ID']
  readonly spentAt: Maybe<Scalars['Date']>
  readonly state: Maybe<ServiceState>
  readonly type: Maybe<ServiceType>
}

export interface ClosedLogoInPortalService {
  readonly __typename?: 'ClosedLogoInPortalService'
  readonly branch: LogoPortalBranch
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly portal: LogoPortal
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly serviceId: Scalars['ID']
  readonly spentAt: Maybe<Scalars['Date']>
  readonly state: Maybe<ServiceState>
  readonly type: Maybe<ServiceType>
}

export interface ClosedLogoInRubricService {
  readonly __typename?: 'ClosedLogoInRubricService'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly rubric: Rubric
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly serviceId: Scalars['ID']
  readonly spentAt: Maybe<Scalars['Date']>
  readonly state: Maybe<ServiceState>
  readonly type: Maybe<ServiceType>
}

export interface ClosedMarketingService {
  readonly __typename?: 'ClosedMarketingService'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly serviceId: Scalars['ID']
  readonly spentAt: Maybe<Scalars['Date']>
  readonly state: Maybe<ServiceState>
  readonly type: Maybe<ServiceType>
}

export interface ClosedRisingUpService {
  readonly __typename?: 'ClosedRisingUpService'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly serviceId: Scalars['ID']
  readonly spentAt: Maybe<Scalars['Date']>
  readonly state: Maybe<ServiceState>
  readonly type: Maybe<ServiceType>
}

export interface ClosedVacancyEmployerEvent {
  readonly __typename?: 'ClosedVacancyEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly performer: PerformerEmployerEventUnion
  readonly vacancyCity: City
  readonly vacancyId: Scalars['ID']
  readonly vacancyName: Scalars['String']
}

export interface ClosedVacancyPackageService {
  readonly __typename?: 'ClosedVacancyPackageService'
  readonly activatedAt: Maybe<Scalars['Date']>
  readonly activator: Maybe<ServiceActivator>
  readonly additionalServices: ReadonlyArray<Maybe<ServiceUnited>>
  readonly contactsAccrualPeriod: Maybe<ContactsAccrualPeriod>
  readonly contactsCount: Maybe<Scalars['Int']>
  readonly endedAt: Maybe<Scalars['Date']>
  readonly id: Scalars['ID']
  readonly isPackage: Maybe<Scalars['Boolean']>
  readonly isUnlimitedContacts: Maybe<Scalars['Boolean']>
  readonly name: Scalars['String']
  readonly publicationType: Maybe<VacancyPublicationType>
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly serviceId: Scalars['ID']
  readonly spentCount: Maybe<Scalars['Int']>
  readonly state: Maybe<ServiceState>
  readonly supportedRegions: ReadonlyArray<City>
  readonly totalVacancyCount: Maybe<Scalars['Int']>
  readonly type: Maybe<ServiceType>
  readonly usedContactCount: Maybe<Scalars['Int']>
  readonly vacanciesPerPackage: Maybe<Scalars['Int']>
  readonly vacancyMailingCount: Maybe<Scalars['Int']>
  readonly vacancyPublishDays: Maybe<Scalars['Int']>
  readonly vacancyRisingCount: Maybe<Scalars['Int']>
}

export interface ClosedVacancyPublicationService {
  readonly __typename?: 'ClosedVacancyPublicationService'
  readonly id: Scalars['ID']
  readonly isPackage: Maybe<Scalars['Boolean']>
  readonly name: Scalars['String']
  readonly parentServiceId: Maybe<Scalars['ID']>
  readonly publicationType: Maybe<VacancyPublicationType>
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly serviceId: Scalars['ID']
  readonly spentAt: Maybe<Scalars['Date']>
  readonly state: Maybe<ServiceState>
  readonly supportedRegions: ReadonlyArray<City>
  readonly type: Maybe<ServiceType>
  readonly vacancyMailingCount: Maybe<Scalars['Int']>
  readonly vacancyPublishDays: Maybe<Scalars['Int']>
  readonly vacancyRisingCount: Maybe<Scalars['Int']>
}

export interface ClubMutations {
  readonly __typename?: 'ClubMutations'
  readonly addPost: AddClubPostOutput
  readonly addPostToHot: ClubPost
  readonly addPostToTop: ClubPost
  readonly deletePostFromHot: DeletePostFromHotOutput
  readonly deletePostFromTop: DeletePostFromTopOutput
}

export interface ClubMutationsAddPostArgs {
  input: AddClubPostInput
}

export interface ClubMutationsAddPostToHotArgs {
  id: Scalars['ID']
}

export interface ClubMutationsAddPostToTopArgs {
  id: Scalars['ID']
}

export interface ClubMutationsDeletePostFromHotArgs {
  id: Scalars['ID']
}

export interface ClubMutationsDeletePostFromTopArgs {
  id: Scalars['ID']
}

export interface ClubPost {
  readonly __typename?: 'ClubPost'
  readonly body: Scalars['String']
  readonly createdAt: Scalars['DateTime']
  readonly description: Maybe<Scalars['String']>
  readonly id: Scalars['ID']
  readonly publishedAt: Maybe<Scalars['DateTime']>
  readonly relatedPosts: Maybe<PublicClubPostsOutput>
  readonly slug: Scalars['String']
  readonly tags: ReadonlyArray<ClubTag>
  readonly thumbnail: Scalars['String']
  readonly title: Scalars['String']
  readonly topics: ReadonlyArray<ClubTopic>
  readonly userProfile: Maybe<ClubUserProfile>
  readonly viewsCount: Scalars['UInt']
}

export interface ClubPostRelatedPostsArgs {
  cursorPagination: InputMaybe<PublicClubPostsCursorPaginationInput>
}

export interface ClubQueries {
  readonly __typename?: 'ClubQueries'
  readonly post: Maybe<ClubPost>
  readonly publicPosts: PublicClubPostsOutput
  readonly tag: ClubTag
  readonly tags: ReadonlyArray<ClubTag>
  readonly topPost: ClubPost
  readonly topic: ClubTopic
  readonly topicEnums: Maybe<PublicClubTopicEnameEnum>
  readonly topics: ReadonlyArray<ClubTopic>
}

export interface ClubQueriesPostArgs {
  id: InputMaybe<Scalars['ID']>
  slug: InputMaybe<Scalars['String']>
}

export interface ClubQueriesPublicPostsArgs {
  cursorPagination: InputMaybe<PublicClubPostsCursorPaginationInput>
  filter: InputMaybe<PublicClubPostsFilterInput>
  sorting: InputMaybe<PublicClubPostsSortingEnum>
}

export interface ClubQueriesTagArgs {
  id: InputMaybe<Scalars['ID']>
  name: InputMaybe<Scalars['String']>
}

export interface ClubQueriesTopicArgs {
  id: InputMaybe<Scalars['ID']>
  name: InputMaybe<Scalars['String']>
}

export interface ClubTag {
  readonly __typename?: 'ClubTag'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export interface ClubTopic {
  readonly __typename?: 'ClubTopic'
  readonly banner: Scalars['String']
  readonly color: Scalars['String']
  readonly description: Scalars['String']
  readonly engname: Scalars['String']
  readonly id: Scalars['ID']
  readonly logo: Scalars['String']
  readonly meta: Scalars['String']
  readonly name: Scalars['String']
  readonly sideBarIcon: Scalars['String']
}

export interface ClubUserProfile {
  readonly __typename?: 'ClubUserProfile'
  readonly avatarImageName: Scalars['String']
  readonly fullName: Scalars['String']
  readonly id: Scalars['ID']
  readonly position: Scalars['String']
}

export interface Company {
  readonly __typename?: 'Company'
  readonly addDate: Scalars['DateTime']
  readonly adminEmployee: Employee
  readonly blockingReasons: Maybe<CompanyBlockingReasons>
  readonly branch: Branch
  readonly brendProfileDateTo: Maybe<Scalars['DateTime']>
  readonly city: City
  readonly companyState: Maybe<CompanyStateEnum>
  readonly companyUrl: Maybe<Scalars['Uri']>
  readonly contactPhone: Scalars['String']
  readonly description: Scalars['String']
  readonly edrpou: Maybe<Scalars['String']>
  readonly edrpouFile: Maybe<Scalars['String']>
  readonly employees: Maybe<EmployeeConnection>
  readonly hasAtsAccess: Scalars['Boolean']
  readonly hasConfirmedContactEmail: Scalars['Boolean']
  readonly hasNonUkrainianVacancies: Scalars['Boolean']
  readonly hasServicesLimitAccess: Maybe<Scalars['Boolean']>
  readonly honors: ReadonlyArray<CompanyHonors>
  readonly id: Scalars['ID']
  readonly isAllowedToPersonalizeAutoReplyApplyTemplate: Scalars['Boolean']
  readonly isBrendProfile: Scalars['Boolean']
  readonly isConfirmByPhoneAndSite: Scalars['Boolean']
  readonly isDuplicate: Scalars['Boolean']
  readonly isEdrpouApproved: Scalars['Boolean']
  readonly isVerified: Scalars['Boolean']
  readonly jiraServiceDeskOrganisation: JiraServiceDeskOrganisation
  readonly logoUrl: Scalars['String']
  readonly manager: Manager
  readonly miniProfile: CompanyMiniProfile
  readonly myOrders: Maybe<OrderConnection>
  readonly myServices: Maybe<MyServicesConnection>
  readonly name: Scalars['String']
  readonly onlyMainUserCanActivateService: Maybe<Scalars['Boolean']>
  readonly popularProfessions: Maybe<ReadonlyArray<Maybe<TagGroup>>>
  readonly promoCodes: Maybe<PromoCodeConnection>
  readonly socialMedia: Maybe<CompanySocialMedia>
  readonly summaryServices: Maybe<ReadonlyArray<Maybe<SummaryService>>>
  readonly turbotaStatus: CompanyTurbotaStatus
  readonly wallets: Maybe<WalletsConnection>
}

export interface CompanyEmployeesArgs {
  after: InputMaybe<Scalars['String']>
  filter: InputMaybe<EmployeesFilterInput>
  first: InputMaybe<Scalars['Int']>
  orderBy: InputMaybe<EmployeesOrderBy>
}

export interface CompanyMyOrdersArgs {
  after: InputMaybe<Scalars['String']>
  filter: InputMaybe<FilterCompanyOrdersRequest>
  first: InputMaybe<Scalars['Int']>
}

export interface CompanyMyServicesArgs {
  after: InputMaybe<Scalars['String']>
  filter: InputMaybe<FilterMyServicesInput>
  first: InputMaybe<Scalars['Int']>
}

export interface CompanyPromoCodesArgs {
  after: InputMaybe<Scalars['String']>
  filter: InputMaybe<PromoCodeFilterInput>
  first: InputMaybe<Scalars['Int']>
  sort: InputMaybe<PromoCodeSortEnum>
}

export interface CompanySummaryServicesArgs {
  orderBy: InputMaybe<SummaryServiceOrderBy>
}

export interface CompanyWalletsArgs {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
}

export interface CompanyApplyAutoReplyTemplateConfigurationUpdateInput {
  readonly isAllowedToPersonalize: Scalars['Boolean']
}

export interface CompanyApplyAutoReplyTemplateConfigurationUpdateOutput {
  readonly __typename?: 'CompanyApplyAutoReplyTemplateConfigurationUpdateOutput'
  readonly isAllowedToPersonalize: Scalars['Boolean']
  readonly isSuccessful: Scalars['Boolean']
}

export interface CompanyAssignmentOutput {
  readonly __typename?: 'CompanyAssignmentOutput'
  readonly id: Maybe<Scalars['ID']>
  readonly isSuccess: Scalars['Boolean']
  readonly message: Maybe<Scalars['String']>
}

export interface CompanyAssignmentRefuseInput {
  readonly comment: Scalars['String']
  readonly companyId: Scalars['ID']
  readonly proposedManagerId: Scalars['ID']
}

export interface CompanyAssignmentWantInput {
  readonly comment: Scalars['String']
  readonly companyId: Scalars['ID']
}

export interface CompanyAssignmentsMutations {
  readonly __typename?: 'CompanyAssignmentsMutations'
  readonly forbidAutoUnlink: CompanyAssignmentOutput
  readonly refuse: CompanyAssignmentOutput
  readonly want: CompanyAssignmentOutput
}

export interface CompanyAssignmentsMutationsForbidAutoUnlinkArgs {
  companyId: Scalars['ID']
  state: Scalars['Boolean']
}

export interface CompanyAssignmentsMutationsRefuseArgs {
  input: CompanyAssignmentRefuseInput
}

export interface CompanyAssignmentsMutationsWantArgs {
  input: CompanyAssignmentWantInput
}

export interface CompanyBenefit {
  readonly __typename?: 'CompanyBenefit'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export interface CompanyBlockingReasons {
  readonly __typename?: 'CompanyBlockingReasons'
  readonly reasons: ReadonlyArray<CompanyReason>
}

export interface CompanyBufferEvent extends EventInterface {
  readonly __typename?: 'CompanyBufferEvent'
  readonly addDate: Scalars['DateTimeOffset']
  readonly assignment: Maybe<Assignment>
  readonly company: Maybe<CrmCompany>
  readonly daysLeft: Maybe<Scalars['Int']>
  readonly employee: Maybe<CrmEmployee>
  readonly eventType: EventTypeEnum
  readonly expirationDate: Maybe<Scalars['DateTimeOffset']>
  readonly id: Scalars['ID']
  readonly isArchieved: Scalars['Boolean']
  readonly isRead: Scalars['Boolean']
  readonly lifetimeDays: Scalars['Int']
  readonly state: EventStateEnum
  readonly topics: ReadonlyArray<Topic>
}

export interface CompanyCard {
  readonly __typename?: 'CompanyCard'
  readonly action: Maybe<CompanyCardAction>
  readonly anonymousRemain: Scalars['Int']
  readonly biSegmentName: Scalars['String']
  readonly businessRemain: Scalars['Int']
  readonly cityName: Scalars['String']
  readonly finishedVacancyCount: Scalars['Int']
  readonly hotRemain: Scalars['Int']
  readonly hotVacancyCount: Scalars['Int']
  readonly hotWorkVacancyCount: Scalars['Int']
  readonly id: Scalars['ID']
  readonly lastPayment: Maybe<CompanyCardPayment>
  readonly name: Scalars['String']
  readonly optimumRemain: Scalars['Int']
  readonly payments1Year: Scalars['Decimal']
  readonly previousLoyalDate: Maybe<Scalars['DateTime']>
  readonly problemsCount: Scalars['Int']
  readonly professionalRemain: Scalars['Int']
  readonly registrationDate: Scalars['DateTime']
  readonly ticketAwayCount: Scalars['Int']
  readonly totalRemain: Scalars['Int']
  readonly uniqueVacancyCount: Scalars['Int']
  readonly uniqueVacancyCountDiff: Scalars['Int']
  readonly vacancyCount: Scalars['Int']
  readonly willBurnTicketCount: Scalars['Int']
  readonly willFinishVacancyCount: Scalars['Int']
  readonly workVacancyCount: Scalars['Int']
}

export interface CompanyCardAction {
  readonly __typename?: 'CompanyCardAction'
  readonly actionComment: Scalars['String']
  readonly actionCompleteDate: Scalars['DateTime']
  readonly actionType: CrmActionTypeEnum
  readonly id: Scalars['ID']
}

export interface CompanyCardConnection {
  readonly __typename?: 'CompanyCardConnection'
  readonly displayedCount: Scalars['Int']
  readonly edges: Maybe<ReadonlyArray<Maybe<CompanyCardEdge>>>
  readonly items: Maybe<ReadonlyArray<CompanyCard>>
  readonly pageInfo: PageInfo
  readonly totalCount: Maybe<Scalars['Int']>
}

export interface CompanyCardEdge {
  readonly __typename?: 'CompanyCardEdge'
  readonly cursor: Scalars['String']
  readonly node: CompanyCard
}

export interface CompanyCardFilterInput {
  readonly biSegmentIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
  readonly cityIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>
  readonly endDate?: InputMaybe<Scalars['Date']>
  readonly hotVacancyCount?: InputMaybe<IntIntervalInput>
  readonly hotWorkVacancyCount?: InputMaybe<IntIntervalInput>
  readonly isCoveredByAction?: InputMaybe<Scalars['Boolean']>
  readonly isPaidCompany?: InputMaybe<Scalars['Boolean']>
  readonly isShowOnlyActiveCompanies?: InputMaybe<Scalars['Boolean']>
  readonly isShowOnlyNewCompanies?: InputMaybe<Scalars['Boolean']>
  readonly loyaltyStateIds?: InputMaybe<ReadonlyArray<CompanyLoyaltyEnum>>
  readonly managerId?: InputMaybe<Scalars['ID']>
  readonly managerIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
  readonly notebookId?: InputMaybe<Scalars['ID']>
  readonly startDate?: InputMaybe<Scalars['Date']>
  readonly ticketBurn?: InputMaybe<CrmDateInterval>
  readonly uniqueVacancyCount?: InputMaybe<IntIntervalInput>
  readonly uniqueVacancyCountDiff?: InputMaybe<IntIntervalInput>
  readonly vacancyCount?: InputMaybe<IntIntervalInput>
  readonly vacancyFinish?: InputMaybe<CrmDateInterval>
  readonly workVacancyCount?: InputMaybe<IntIntervalInput>
}

export interface CompanyCardPayment {
  readonly __typename?: 'CompanyCardPayment'
  readonly date: Scalars['DateTime']
  readonly paySum: Scalars['Decimal']
  readonly spentDate: Scalars['DateTime']
}

export enum CompanyCardServiceTypeEnum {
  Anonymous = 'ANONYMOUS',
  Business = 'BUSINESS',
  Hot = 'HOT',
  Optimum = 'OPTIMUM',
  Professional = 'PROFESSIONAL'
}

export interface CompanyCardTicket {
  readonly __typename?: 'CompanyCardTicket'
  readonly count: Scalars['Int']
  readonly date: Scalars['DateTime']
  readonly type: CompanyCardServiceTypeEnum
  readonly typeName: Scalars['String']
}

export interface CompanyEditInput {
  readonly branchId?: InputMaybe<Scalars['ID']>
  readonly cityId?: InputMaybe<Scalars['ID']>
  readonly companyUrl?: InputMaybe<Scalars['String']>
  readonly description: Scalars['String']
  readonly edrpou?: InputMaybe<Scalars['String']>
  readonly edrpouFile?: InputMaybe<Scalars['String']>
  readonly id: Scalars['ID']
  readonly logoUrl?: InputMaybe<Scalars['String']>
  readonly miniProfile?: InputMaybe<CompanyMiniProfileInput>
  readonly name: Scalars['String']
  readonly socialMedia?: InputMaybe<CompanySocialMediaInput>
}

export interface CompanyEditOutput {
  readonly __typename?: 'CompanyEditOutput'
  readonly company: Maybe<Company>
  readonly error: Maybe<Scalars['String']>
}

export interface CompanyEmailResendConfirmationInput {
  readonly companyId: Scalars['ID']
}

export interface CompanyEmailResendConfirmationOutput {
  readonly __typename?: 'CompanyEmailResendConfirmationOutput'
  readonly isSuccessful: Scalars['Boolean']
}

export enum CompanyEventEnum {
  AssignedToManager = 'ASSIGNED_TO_MANAGER',
  DealCreated = 'DEAL_CREATED',
  EmailAlert = 'EMAIL_ALERT',
  InvoiceCreatedByManager = 'INVOICE_CREATED_BY_MANAGER',
  InvoiceCreatedEcommerce = 'INVOICE_CREATED_ECOMMERCE',
  Payment = 'PAYMENT',
  PromocodeAdded = 'PROMOCODE_ADDED',
  Registred = 'REGISTRED',
  ServiceActivation = 'SERVICE_ACTIVATION',
  ServiceBurntOut = 'SERVICE_BURNT_OUT',
  VacancyFinished = 'VACANCY_FINISHED',
  VacancyPublished = 'VACANCY_PUBLISHED',
  ViberAlert = 'VIBER_ALERT',
  Wallet = 'WALLET'
}

export interface CompanyEventInput {
  readonly companyId: Scalars['ID']
  readonly dates?: InputMaybe<CrmDateInterval>
  readonly types?: InputMaybe<ReadonlyArray<CompanyEventEnum>>
}

export interface CompanyEventOutput {
  readonly __typename?: 'CompanyEventOutput'
  readonly accountNumber: Maybe<Scalars['Int']>
  readonly accountYear: Maybe<Scalars['Int']>
  readonly date: Scalars['DateTime']
  readonly dealNumber: Maybe<Scalars['Int']>
  readonly description: Scalars['String']
  readonly eventType: CompanyEventEnum
  readonly name: Scalars['String']
  readonly promocode: Maybe<PromoCode>
}

export interface CompanyFreeServiceAddedEvent {
  readonly __typename?: 'CompanyFreeServiceAddedEvent'
  readonly addedAt: Scalars['DateTime']
  readonly catalogService: CatalogService
  readonly id: Scalars['ID']
  readonly performer: PerformerEmployerEventUnion
}

export interface CompanyHonors {
  readonly __typename?: 'CompanyHonors'
  readonly badge: Maybe<CompanyHonorsBadge>
  readonly description: Scalars['String']
  readonly detailsUrl: Maybe<Scalars['String']>
  readonly id: Scalars['ID']
  readonly logoUrl: Maybe<Scalars['String']>
}

export interface CompanyHonorsBadge {
  readonly __typename?: 'CompanyHonorsBadge'
  readonly iconUrl: Scalars['String']
  readonly id: Scalars['ID']
  readonly isFavorite: Scalars['Boolean']
  readonly locations: ReadonlyArray<CompanyHonorsBadgeLocationEnum>
  readonly tooltipDescription: Scalars['String']
}

export enum CompanyHonorsBadgeLocationEnum {
  Company = 'COMPANY',
  Vacancy = 'VACANCY'
}

export interface CompanyJoinRequest {
  readonly __typename?: 'CompanyJoinRequest'
  readonly company: Company
  readonly status: CompanyJoinRequestStatusEnum
}

export enum CompanyJoinRequestStatusEnum {
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export enum CompanyLoyaltyEnum {
  Disloyal = 'DISLOYAL',
  Loyal = 'LOYAL',
  Neutral = 'NEUTRAL'
}

export interface CompanyMiniProfile {
  readonly __typename?: 'CompanyMiniProfile'
  readonly benefits: ReadonlyArray<CompanyBenefit>
  readonly description: Maybe<Scalars['String']>
  readonly images: ReadonlyArray<Scalars['String']>
  readonly isEnabled: Scalars['Boolean']
  readonly staffSize: CompanyStaffSize
  readonly years: Scalars['Int']
}

export interface CompanyMiniProfileInput {
  readonly benefits?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  readonly description?: InputMaybe<Scalars['String']>
  readonly images?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
  readonly isEnabled?: InputMaybe<Scalars['Boolean']>
  readonly staffSize?: InputMaybe<Scalars['ID']>
  readonly years?: InputMaybe<Scalars['Int']>
}

export interface CompanyNotificationSnapshot {
  readonly __typename?: 'CompanyNotificationSnapshot'
  readonly id: Scalars['ID']
  readonly state: CompanyStateEnum
}

export interface CompanyPublishedVacanciesFilter {
  readonly __typename?: 'CompanyPublishedVacanciesFilter'
  readonly cityId: Scalars['ID']
  readonly rubricId: Scalars['ID']
}

export interface CompanyPublishedVacanciesFilterInput {
  readonly cityId?: InputMaybe<Scalars['ID']>
  readonly rubricId?: InputMaybe<Scalars['ID']>
}

export interface CompanyPublishedVacanciesQueryFilter {
  readonly __typename?: 'CompanyPublishedVacanciesQueryFilter'
  readonly filter: CompanyPublishedVacanciesFilter
  readonly page: Scalars['Int']
}

export interface CompanyReason {
  readonly __typename?: 'CompanyReason'
  readonly description: Scalars['String']
  readonly id: Scalars['ID']
  readonly title: Maybe<Scalars['String']>
}

export interface CompanyRegisteredEvent extends EventInterface {
  readonly __typename?: 'CompanyRegisteredEvent'
  readonly addDate: Scalars['DateTimeOffset']
  readonly assignment: Maybe<Assignment>
  readonly company: Maybe<CrmCompany>
  readonly daysLeft: Maybe<Scalars['Int']>
  readonly employee: Maybe<CrmEmployee>
  readonly eventType: EventTypeEnum
  readonly expirationDate: Maybe<Scalars['DateTimeOffset']>
  readonly id: Scalars['ID']
  readonly isArchieved: Scalars['Boolean']
  readonly isRead: Scalars['Boolean']
  readonly lifetimeDays: Scalars['Int']
  readonly state: EventStateEnum
  readonly topics: ReadonlyArray<Topic>
}

export interface CompanySavedCardAddedEvent {
  readonly __typename?: 'CompanySavedCardAddedEvent'
  readonly addedAt: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly performer: PerformerEmployerEventUnion
  readonly savedCard: SavedCard
}

export interface CompanySavedCardRemovedEvent {
  readonly __typename?: 'CompanySavedCardRemovedEvent'
  readonly addedAt: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly performer: PerformerEmployerEventUnion
  readonly savedCard: SavedCard
}

export enum CompanySegmentEnum {
  A = 'A',
  Aa = 'AA',
  Ab = 'AB',
  B = 'B',
  C = 'C',
  D = 'D',
  None = 'NONE'
}

export enum CompanySendJoinRequestErrorEnum {
  CompanyIsAlreadyVerified = 'COMPANY_IS_ALREADY_VERIFIED',
  CompanyIsNotInTest = 'COMPANY_IS_NOT_IN_TEST',
  JoiningCompanyIsNotFound = 'JOINING_COMPANY_IS_NOT_FOUND',
  OriginalCompanyIsNotFound = 'ORIGINAL_COMPANY_IS_NOT_FOUND',
  ServerError = 'SERVER_ERROR'
}

export interface CompanySendJoinRequestOutput {
  readonly __typename?: 'CompanySendJoinRequestOutput'
  readonly errors: ReadonlyArray<CompanySendJoinRequestErrorEnum>
  readonly isSuccess: Scalars['Boolean']
}

export interface CompanySocialMedia {
  readonly __typename?: 'CompanySocialMedia'
  readonly facebookUrl: Scalars['String']
  readonly instagramUrl: Scalars['String']
  readonly linkedInUrl: Scalars['String']
}

export interface CompanySocialMediaInput {
  readonly facebookUrl?: InputMaybe<Scalars['String']>
  readonly instagramUrl?: InputMaybe<Scalars['String']>
  readonly linkedInUrl?: InputMaybe<Scalars['String']>
}

export interface CompanyStaffSize {
  readonly __typename?: 'CompanyStaffSize'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export interface CompanyStateChangedDetails {
  readonly __typename?: 'CompanyStateChangedDetails'
  readonly companySnapshot: CompanyNotificationSnapshot
  readonly detailType: SeekerNotificationDetailTypeEnum
}

export enum CompanyStateEnum {
  BlackList = 'BLACK_LIST',
  ExpectationOfActivation = 'EXPECTATION_OF_ACTIVATION',
  Mega = 'MEGA',
  New = 'NEW',
  NotActivated = 'NOT_ACTIVATED',
  Undefined = 'UNDEFINED',
  Validated = 'VALIDATED'
}

export interface CompanyStateWrapper {
  readonly __typename?: 'CompanyStateWrapper'
  readonly id: Scalars['ID']
  readonly state: CompanyStateEnum
}

export interface CompanyTurbotaStatus {
  readonly __typename?: 'CompanyTurbotaStatus'
  readonly activatedAt: Maybe<Scalars['DateTime']>
  readonly endsAt: Maybe<Scalars['DateTime']>
  readonly hasTurbotaAccess: Scalars['Boolean']
  readonly isActiveNow: Scalars['Boolean']
  readonly order: Maybe<Order>
}

export interface CompanyVerificationInput {
  readonly companyUrl?: InputMaybe<Scalars['String']>
  readonly edrpou?: InputMaybe<Scalars['String']>
  readonly edrpouFile?: InputMaybe<Scalars['String']>
  readonly id: Scalars['ID']
}

export interface CompanyVerificationOutput {
  readonly __typename?: 'CompanyVerificationOutput'
  readonly error: Maybe<Scalars['String']>
  readonly isSuccessful: Maybe<Scalars['Boolean']>
}

export interface CompanyWasMergedError extends Error {
  readonly __typename?: 'CompanyWasMergedError'
  readonly reason: Scalars['String']
}

export interface CompanyWorkRegisteredEvent extends EventInterface {
  readonly __typename?: 'CompanyWorkRegisteredEvent'
  readonly addDate: Scalars['DateTimeOffset']
  readonly assignment: Maybe<Assignment>
  readonly company: Maybe<CrmCompany>
  readonly daysLeft: Maybe<Scalars['Int']>
  readonly employee: Maybe<CrmEmployee>
  readonly eventType: EventTypeEnum
  readonly expirationDate: Maybe<Scalars['DateTimeOffset']>
  readonly id: Scalars['ID']
  readonly isArchieved: Scalars['Boolean']
  readonly isRead: Scalars['Boolean']
  readonly lifetimeDays: Scalars['Int']
  readonly state: EventStateEnum
  readonly topics: ReadonlyArray<Topic>
}

export interface ConfirmEmailByCodeInput {
  readonly code: Scalars['String']
  readonly email: Scalars['String']
}

export interface ConfirmEmailCodeOutput {
  readonly __typename?: 'ConfirmEmailCodeOutput'
  readonly status: ConfirmEmailCodeStatusEnum
  readonly token: Maybe<Scalars['String']>
}

export enum ConfirmEmailCodeStatusEnum {
  AttemptsLimitReached = 'ATTEMPTS_LIMIT_REACHED',
  Failed = 'FAILED',
  Success = 'SUCCESS',
  WrongCode = 'WRONG_CODE'
}

export interface ConfirmProfileDeletionInput {
  readonly token: Scalars['String']
}

export interface ConfirmProfileDeletionOutput {
  readonly __typename?: 'ConfirmProfileDeletionOutput'
  readonly status: DeleteProfileStatus
}

export interface ConfirmedPhoneUsersOutput {
  readonly __typename?: 'ConfirmedPhoneUsersOutput'
  readonly phoneConfirmationResult: PhoneConfirmationOutput
  readonly userAccounts: ReadonlyArray<UserAccountLogin>
}

export enum ContactInfoSubTypeEnum {
  ArtStation = 'ART_STATION',
  Behance = 'BEHANCE',
  DeviantArt = 'DEVIANT_ART',
  Dribbble = 'DRIBBBLE',
  Facebook = 'FACEBOOK',
  GitHub = 'GIT_HUB',
  GooglePlus = 'GOOGLE_PLUS',
  Instagram = 'INSTAGRAM',
  LinkedIn = 'LINKED_IN',
  None = 'NONE',
  Ok = 'OK',
  Skype = 'SKYPE',
  Telegram = 'TELEGRAM',
  Twitter = 'TWITTER',
  Viber = 'VIBER',
  Vk = 'VK'
}

export enum ContactWarmthTypeEnum {
  Cold = 'COLD',
  Neutral = 'NEUTRAL',
  Warm = 'WARM'
}

export enum ContactsAccrualPeriod {
  Day = 'DAY',
  Month = 'MONTH'
}

export interface Contract {
  readonly __typename?: 'Contract'
  readonly additionals: ReadonlyArray<Contract>
  readonly endDate: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly isActive: Scalars['Boolean']
  readonly isAllowDelete: Scalars['Boolean']
  readonly isClientApproved: Scalars['Boolean']
  readonly isCreatedByJurist: Scalars['Boolean']
  readonly isSigned: Scalars['Boolean']
  readonly number: Scalars['String']
  readonly startDate: Scalars['DateTime']
  readonly url: Scalars['String']
}

export interface ContractCreationOutput {
  readonly __typename?: 'ContractCreationOutput'
  readonly errorMessage: Maybe<Scalars['String']>
  readonly id: Scalars['ID']
  readonly number: Scalars['String']
  readonly url: Maybe<Scalars['String']>
}

export interface CountStatistics {
  readonly __typename?: 'CountStatistics'
  readonly futurePeriodsCounts: Maybe<ReadonlyArray<Maybe<PeriodAmount>>>
  readonly lastYearCounts: Maybe<ReadonlyArray<Maybe<PeriodAmount>>>
}

export interface CreateAsCopyProfResumeInput {
  readonly resumeId: Scalars['ID']
}

export interface CreateEmployerDuplicateProfileInput {
  readonly contactEmail: Scalars['String']
  readonly fullname: Scalars['String']
  readonly loginEmail: Scalars['String']
  readonly originUrl?: InputMaybe<Scalars['String']>
  readonly originalCompanyId: Scalars['ID']
  readonly password?: InputMaybe<Scalars['String']>
  readonly phone: Scalars['String']
  readonly position: Scalars['String']
}

export interface CreateEmployerProfileInput {
  readonly branchId?: InputMaybe<Scalars['ID']>
  readonly cityId?: InputMaybe<Scalars['ID']>
  readonly companyName: Scalars['String']
  readonly contactEmail: Scalars['String']
  readonly countEmployeeId?: InputMaybe<Scalars['ID']>
  readonly fullname: Scalars['String']
  readonly loginEmail: Scalars['String']
  readonly originUrl?: InputMaybe<Scalars['String']>
  readonly password?: InputMaybe<Scalars['String']>
  readonly phone: Scalars['String']
  readonly position: Scalars['String']
  readonly shortDescription?: InputMaybe<Scalars['String']>
}

export type CreateEmptyProfResumeError = ProfResumeLimit

export interface CreateGroupNotificationInput {
  readonly elements: ReadonlyArray<NotificationElementInput>
  readonly group: NotificationGroupEnum
}

export interface CreateGroupNotificationOutput {
  readonly __typename?: 'CreateGroupNotificationOutput'
  readonly isSuccessful: Scalars['Boolean']
}

export interface CreateInvoiceOutput {
  readonly __typename?: 'CreateInvoiceOutput'
  readonly invoice: Maybe<Invoice>
}

export interface CreateOrderCvDbInput {
  readonly cityId: Scalars['ID']
  readonly period: ServiceUsagePeriod
  readonly rubricId: Scalars['ID']
}

export enum CreateOrderFromCartErrorEnum {
  EmptyCart = 'EMPTY_CART',
  InvalidPromocodesSelected = 'INVALID_PROMOCODES_SELECTED',
  ServerError = 'SERVER_ERROR'
}

export interface CreateOrderFromCartResponse {
  readonly __typename?: 'CreateOrderFromCartResponse'
  readonly errors: ReadonlyArray<CreateOrderFromCartErrorEnum>
  readonly isSuccess: Scalars['Boolean']
  readonly order: Maybe<Order>
}

export interface CreateOrderFromServicesInput {
  readonly hasToBeConfiguredInDb?: InputMaybe<Scalars['Boolean']>
  readonly services: ReadonlyArray<InputMaybe<CreateOrderServiceInput>>
}

export interface CreateOrderFromServicesOutput {
  readonly __typename?: 'CreateOrderFromServicesOutput'
  readonly order: Maybe<Order>
}

export interface CreateOrderServiceInput {
  readonly appliedTotalBonusAmount: Scalars['Float']
  readonly count: Scalars['Int']
  readonly cvDbData?: InputMaybe<CreateOrderCvDbInput>
  readonly promocodeIds?: InputMaybe<ReadonlyArray<Scalars['Guid']>>
  readonly serviceId: Scalars['ID']
}

export type CreateProfResumeAsCopyError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist | ProfResumeLimit

export type CreateProfResumeError = ProfResumeDuplicated | ProfResumeLimit

export type CreateProfResumeFromAttachError = AttachNotFound | AttachParsingError | ProfResumeDuplicated | ProfResumeLimit

export interface CreateProfResumeFromAttachInput {
  readonly attachId: Scalars['ID']
}

export interface CreateProfResumeFromAttachOutput {
  readonly __typename?: 'CreateProfResumeFromAttachOutput'
  readonly errors: Maybe<ReadonlyArray<CreateProfResumeFromAttachError>>
  readonly profResume: Maybe<ProfResume>
}

export interface CreateProfResumeInput {
  readonly resume: ProfResumeInput
}

export type CreateRecruitmentOrderError = InvalidError | NotFoundError

export interface CreateRecruitmentOrderInput {
  readonly cityId: Scalars['ID']
  readonly email?: InputMaybe<Scalars['String']>
  readonly name: Scalars['String']
  readonly phone: Scalars['String']
  readonly vacancyId: Scalars['ID']
}

export interface CreateRecruitmentOrderOutput {
  readonly __typename?: 'CreateRecruitmentOrderOutput'
  readonly errors: Maybe<ReadonlyArray<CreateRecruitmentOrderError>>
  readonly recruitmentOrderOutput: Maybe<RecruitmentOrderOutput>
}

export interface CreateResumeDraftCreatedNotificationInput {
  readonly resumeId: Scalars['ID']
  readonly userId: Scalars['ID']
}

export interface CreateResumeDraftCreatedNotificationOutput {
  readonly __typename?: 'CreateResumeDraftCreatedNotificationOutput'
  readonly notification: Maybe<Notification>
}

export interface CreateResumePublishedNotificationInput {
  readonly resumeId: Scalars['ID']
  readonly userId: Scalars['ID']
}

export interface CreateResumePublishedNotificationOutput {
  readonly __typename?: 'CreateResumePublishedNotificationOutput'
  readonly notification: Maybe<Notification>
}

export interface CreateSeekerProfileInput {
  readonly fullname: Scalars['String']
  readonly loginEmail?: InputMaybe<Scalars['String']>
  readonly loginPhone?: InputMaybe<Scalars['String']>
  readonly originUrl?: InputMaybe<Scalars['String']>
  readonly password?: InputMaybe<Scalars['String']>
}

export interface CreateSeekerProfileResponse {
  readonly __typename?: 'CreateSeekerProfileResponse'
  readonly authLink: Scalars['String']
  readonly errors: ReadonlyArray<UserRegistrationError>
  readonly isSuccess: Scalars['Boolean']
  readonly oneTimeLoginToken: Maybe<Scalars['String']>
  readonly token: Maybe<Scalars['String']>
}

export interface CreateSharingDocumentsDeepLinkInput {
  readonly cityId: Scalars['ID']
  readonly id: Scalars['ID']
  readonly position: Scalars['String']
}

export interface CreateSharingDocumentsDeepLinkOutput {
  readonly __typename?: 'CreateSharingDocumentsDeepLinkOutput'
  readonly deepLink: Scalars['String']
}

export interface CreateTargetedMailingInput {
  readonly additionalServicesCount?: InputMaybe<Scalars['Int']>
  readonly candidatesSamplingType?: InputMaybe<TargetedMailingCandidatesSamplingTypeEnum>
  readonly companyId: Scalars['ID']
  readonly mailContent?: InputMaybe<TargetedMailingMailContentInput>
  readonly mobilePushContent?: InputMaybe<TargetedMailingMobilePushContentInput>
  readonly plannedAt?: InputMaybe<Scalars['DateTime']>
  readonly readyToSpendServicesCount?: InputMaybe<Scalars['Int']>
  readonly samplingSettings?: InputMaybe<TargetedMailingCandidatesSamplingInput>
  readonly status?: InputMaybe<TargetedMailingStatusEnum>
  readonly title: Scalars['String']
}

export interface CreateTargetedMailingOutput {
  readonly __typename?: 'CreateTargetedMailingOutput'
  readonly error: Maybe<TargetedMailingSaveErrorUnion>
  readonly mailing: Maybe<TargetedMailing>
}

export interface CreateUserNotificationInput {
  readonly elements: ReadonlyArray<NotificationElementInput>
  readonly userIds: ReadonlyArray<Scalars['ID']>
}

export interface CreateUserNotificationOutput {
  readonly __typename?: 'CreateUserNotificationOutput'
  readonly isSuccessful: Scalars['Boolean']
}

export type CreateWalletError = UserIsNotAuthenticatedError | WalletExistsError

export interface CreateWalletInput {
  readonly notebookId: Scalars['Int']
}

export interface CreateWalletOutput {
  readonly __typename?: 'CreateWalletOutput'
  readonly errors: Maybe<ReadonlyArray<CreateWalletError>>
  readonly wallet: Maybe<Wallet>
}

export type CreateWalletTransactionError = InvalidError | TransactionExistsError | UserIsNotAuthenticatedError | WalletDoesNotExistError

export interface CreateWalletTransactionInput {
  readonly walletTransaction: WalletTransactionInput
}

export interface CreateWalletTransactionOutput {
  readonly __typename?: 'CreateWalletTransactionOutput'
  readonly errors: Maybe<ReadonlyArray<CreateWalletTransactionError>>
  readonly walletTransaction: Maybe<WalletTransaction>
}

export interface CreatedAsCopyProfResumeOutput {
  readonly __typename?: 'CreatedAsCopyProfResumeOutput'
  readonly errors: Maybe<ReadonlyArray<CreateProfResumeAsCopyError>>
  readonly profResume: Maybe<ProfResume>
}

export interface CreatedDraftVacancyEmployerEvent {
  readonly __typename?: 'CreatedDraftVacancyEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly performer: PerformerEmployerEventUnion
  readonly vacancyCity: City
  readonly vacancyId: Scalars['ID']
  readonly vacancyName: Scalars['String']
}

export interface CreatedEmptyProfResumeOutput {
  readonly __typename?: 'CreatedEmptyProfResumeOutput'
  readonly errors: Maybe<ReadonlyArray<CreateEmptyProfResumeError>>
  readonly profResume: Maybe<ProfResume>
}

export interface CreatedOrderServiceEmployerEvent {
  readonly __typename?: 'CreatedOrderServiceEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly order: Maybe<Order>
  readonly performer: PerformerEmployerEventUnion
}

export interface CreatedProfResumeOutput {
  readonly __typename?: 'CreatedProfResumeOutput'
  readonly errors: Maybe<ReadonlyArray<CreateProfResumeError>>
  readonly profResume: Maybe<ProfResume>
}

export interface CrmAccount {
  readonly __typename?: 'CrmAccount'
  readonly acts: Maybe<ReadonlyArray<Maybe<CrmCompletionAct>>>
  readonly dealNumber: Maybe<Scalars['Int']>
  readonly id: Maybe<Scalars['ID']>
  readonly number: Maybe<Scalars['String']>
  readonly orderId: Maybe<Scalars['ID']>
  readonly payDate: Maybe<Scalars['DateTime']>
  readonly payTotal: Maybe<Scalars['Float']>
  readonly state: Maybe<CrmAccountState>
  readonly stateName: Maybe<Scalars['String']>
  readonly total: Maybe<Scalars['Float']>
  readonly year: Maybe<Scalars['Int']>
}

export interface CrmAccountConnection {
  readonly __typename?: 'CrmAccountConnection'
  readonly edges: Maybe<ReadonlyArray<Maybe<CrmAccountEdge>>>
  readonly items: Maybe<ReadonlyArray<Maybe<CrmAccount>>>
  readonly pageInfo: PageInfo
  readonly totalCount: Maybe<Scalars['Int']>
}

export interface CrmAccountEdge {
  readonly __typename?: 'CrmAccountEdge'
  readonly cursor: Scalars['String']
  readonly node: Maybe<CrmAccount>
}

export enum CrmAccountState {
  All = 'ALL',
  NotPaid = 'NOT_PAID',
  Paid = 'PAID',
  PartlyPaid = 'PARTLY_PAID'
}

export interface CrmAction {
  readonly __typename?: 'CrmAction'
  readonly actionComment: Maybe<Scalars['String']>
  readonly assignedManagerId: Maybe<Scalars['ID']>
  readonly assignedName: Maybe<Scalars['String']>
  readonly companyName: Maybe<Scalars['String']>
  readonly dealId: Maybe<Scalars['ID']>
  readonly executionDate: Maybe<Scalars['DateTime']>
  readonly filesAttachedCount: Scalars['Int']
  readonly id: Maybe<Scalars['ID']>
  readonly isAssignedByStm: Scalars['Boolean']
  readonly isGamificationParticipant: Scalars['Boolean']
  readonly isWholeDay: Maybe<Scalars['Boolean']>
  readonly metaNotebook: Scalars['ID']
  readonly notebookId: Maybe<Scalars['ID']>
  readonly responsibleName: Scalars['String']
  readonly stateId: Maybe<CrmActionState>
  readonly subject: Maybe<Scalars['String']>
  readonly typeId: Maybe<CrmActionTypeEnum>
  readonly userCard: Maybe<CrmUserCard>
}

export interface CrmActionAddInput {
  readonly actionComment?: InputMaybe<Scalars['String']>
  readonly alarmId?: InputMaybe<Scalars['ID']>
  readonly dealId?: InputMaybe<Scalars['ID']>
  readonly executionDate?: InputMaybe<Scalars['DateTime']>
  readonly isByPersonalCar?: InputMaybe<Scalars['Boolean']>
  readonly isFromCompanyPage?: InputMaybe<Scalars['Boolean']>
  readonly isRemind?: InputMaybe<Scalars['Boolean']>
  readonly isWholeDay?: InputMaybe<Scalars['Boolean']>
  readonly meetingReasonId?: InputMaybe<Scalars['ID']>
  readonly metaNotebookId?: InputMaybe<Scalars['ID']>
  readonly notebookId?: InputMaybe<Scalars['ID']>
  readonly parentId?: InputMaybe<Scalars['ID']>
  readonly responsible: Scalars['String']
  readonly state: CrmActionState
  readonly subject?: InputMaybe<Scalars['String']>
  readonly typeId: CrmActionTypeEnum
  readonly userCardId?: InputMaybe<Scalars['ID']>
  readonly userCardIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
}

export interface CrmActionAddResult {
  readonly __typename?: 'CrmActionAddResult'
  readonly id: Scalars['ID']
  readonly ids: ReadonlyArray<Scalars['ID']>
  readonly messageText: Scalars['String']
  readonly userCardId: Scalars['ID']
}

export interface CrmActionBrief {
  readonly __typename?: 'CrmActionBrief'
  readonly actionComment: Maybe<Scalars['String']>
  readonly executionDate: Maybe<Scalars['DateTime']>
  readonly id: Maybe<Scalars['ID']>
  readonly stateId: Maybe<CrmActionState>
  readonly subject: Maybe<Scalars['String']>
  readonly typeId: Maybe<CrmActionTypeEnum>
}

export interface CrmActionConnection {
  readonly __typename?: 'CrmActionConnection'
  readonly edges: Maybe<ReadonlyArray<Maybe<CrmActionEdge>>>
  readonly items: Maybe<ReadonlyArray<CrmAction>>
  readonly pageInfo: PageInfo
  readonly totalCount: Maybe<Scalars['Int']>
}

export enum CrmActionCreatorEnum {
  FieldSales = 'FIELD_SALES',
  SupportSales = 'SUPPORT_SALES'
}

export interface CrmActionDraftRequest {
  readonly alarmId?: InputMaybe<Scalars['ID']>
  readonly metanotebookId?: InputMaybe<Scalars['ID']>
  readonly notebookId?: InputMaybe<Scalars['ID']>
  readonly prevActionId?: InputMaybe<Scalars['ID']>
}

export interface CrmActionEdge {
  readonly __typename?: 'CrmActionEdge'
  readonly cursor: Scalars['String']
  readonly node: CrmAction
}

export enum CrmActionOperation {
  CallNotAnswered = 'CALL_NOT_ANSWERED',
  Complete = 'COMPLETE',
  Delete = 'DELETE',
  Reopen = 'REOPEN'
}

export interface CrmActionOperationInput {
  readonly actionId?: InputMaybe<Scalars['ID']>
  readonly operation?: InputMaybe<CrmActionOperation>
}

export interface CrmActionType {
  readonly __typename?: 'CrmActionType'
  readonly id: Maybe<CrmActionTypeEnum>
  readonly name: Maybe<Scalars['String']>
  readonly nameUkr: Maybe<Scalars['String']>
}

export enum CrmActionTypeEnum {
  Education = 'EDUCATION',
  IncomeCall = 'INCOME_CALL',
  IncomeEmail = 'INCOME_EMAIL',
  Meet = 'MEET',
  MessangerConversation = 'MESSANGER_CONVERSATION',
  OutcomeCall = 'OUTCOME_CALL',
  OutcomeEmail = 'OUTCOME_EMAIL',
  Report = 'REPORT',
  SystemAction = 'SYSTEM_ACTION',
  Task = 'TASK',
  VideoCall = 'VIDEO_CALL'
}

export interface CrmActionUpdateInput {
  readonly actionComment?: InputMaybe<Scalars['String']>
  readonly completeDate?: InputMaybe<Scalars['DateTime']>
  readonly executionDate?: InputMaybe<Scalars['DateTime']>
  readonly id?: InputMaybe<Scalars['ID']>
  readonly isByPersonalCar?: InputMaybe<Scalars['Boolean']>
  readonly isRemind?: InputMaybe<Scalars['Boolean']>
  readonly isWholeDay?: InputMaybe<Scalars['Boolean']>
  readonly meetingReasonId?: InputMaybe<Scalars['ID']>
  readonly responsible: Scalars['String']
  readonly state: CrmActionState
  readonly subject?: InputMaybe<Scalars['String']>
  readonly typeId: CrmActionTypeEnum
  readonly userCardId?: InputMaybe<Scalars['ID']>
}

export interface CrmCall {
  readonly __typename?: 'CrmCall'
  readonly actions: Maybe<ReadonlyArray<Maybe<CrmAction>>>
  readonly answeredAt: Maybe<Scalars['Int']>
  readonly billSec: Maybe<Scalars['Int']>
  readonly callOrder: Maybe<CrmCallOrder>
  readonly callType: Maybe<CrmCallType>
  readonly clientEmployeeId: Maybe<Scalars['ID']>
  readonly clientNotebookId: Maybe<Scalars['ID']>
  readonly clientPhone: Maybe<Scalars['String']>
  readonly dayCount: Maybe<Scalars['Int']>
  readonly disposition: Maybe<CrmCallDisposition>
  readonly employee: Maybe<CrmUserCard>
  readonly generalCallId: Maybe<Scalars['String']>
  readonly history: Maybe<ReadonlyArray<Maybe<CrmCall>>>
  readonly id: Maybe<Scalars['String']>
  readonly manager: Maybe<CrmManager>
  readonly managerId: Maybe<Scalars['ID']>
  readonly managerInternalPhone: Maybe<Scalars['String']>
  readonly managerPhone: Maybe<Scalars['String']>
  readonly startDate: Maybe<Scalars['DateTime']>
  readonly startTime: Maybe<Scalars['Int']>
  readonly stopTime: Maybe<Scalars['Int']>
  readonly transferManager: Maybe<CrmManager>
  readonly transferManagerId: Maybe<Scalars['ID']>
  readonly transferPhone: Maybe<Scalars['String']>
  readonly waitSec: Maybe<Scalars['Int']>
}

export interface CrmCallConnection {
  readonly __typename?: 'CrmCallConnection'
  readonly edges: Maybe<ReadonlyArray<Maybe<CrmCallEdge>>>
  readonly items: Maybe<ReadonlyArray<CrmCall>>
  readonly pageInfo: PageInfo
  readonly totalCount: Maybe<Scalars['Int']>
}

export enum CrmCallDisposition {
  Answer = 'ANSWER',
  Busy = 'BUSY',
  Calling = 'CALLING',
  Cancel = 'CANCEL',
  ChanUnavail = 'CHAN_UNAVAIL',
  Congestion = 'CONGESTION',
  NoAnswer = 'NO_ANSWER',
  Online = 'ONLINE',
  Transfer = 'TRANSFER',
  Vm = 'VM',
  VmSuccess = 'VM_SUCCESS'
}

export interface CrmCallEdge {
  readonly __typename?: 'CrmCallEdge'
  readonly cursor: Scalars['String']
  readonly node: CrmCall
}

export enum CrmCallOrder {
  First = 'FIRST',
  Intermediate = 'INTERMEDIATE',
  LastOrSingle = 'LAST_OR_SINGLE'
}

export enum CrmCallType {
  Incoming = 'INCOMING',
  Outgoing = 'OUTGOING'
}

export enum CrmCallTypeRequest {
  IncomingNoAnswer = 'INCOMING_NO_ANSWER',
  IncomingSuccess = 'INCOMING_SUCCESS',
  OutgoingNoAnswer = 'OUTGOING_NO_ANSWER',
  OutgoingSuccess = 'OUTGOING_SUCCESS',
  Transfered = 'TRANSFERED'
}

export interface CrmCompany {
  readonly __typename?: 'CrmCompany'
  readonly addDate: Scalars['DateTime']
  readonly biSegmentName: Maybe<Scalars['String']>
  readonly branchName: Maybe<Scalars['String']>
  readonly cityId: Maybe<Scalars['Int']>
  readonly cityName: Maybe<Scalars['String']>
  readonly comissionPercent: Scalars['Decimal']
  readonly contactURL: Maybe<Scalars['String']>
  readonly contracts: ReadonlyArray<Contract>
  readonly edrpou: Maybe<Scalars['String']>
  readonly edrpouFile: Maybe<Scalars['String']>
  readonly employeeCount: Maybe<Scalars['String']>
  readonly employees: Maybe<ReadonlyArray<Maybe<CrmEmployee>>>
  readonly exactEmployeeCount: Maybe<Scalars['Int']>
  readonly hasEmailConfirmed: Scalars['Boolean']
  readonly hasPhoneConfirmed: Scalars['Boolean']
  readonly id: Maybe<Scalars['ID']>
  readonly isForbiddenAutoUnlink: Scalars['Boolean']
  readonly isLegalPerson: Maybe<Scalars['Boolean']>
  readonly isPremium: Maybe<Scalars['Boolean']>
  readonly logo: Maybe<Scalars['String']>
  readonly manager: Maybe<CrmManager>
  readonly managerId: Maybe<Scalars['Int']>
  readonly name: Maybe<Scalars['String']>
  readonly notebookId: Maybe<Scalars['Int']>
  readonly payer: Maybe<Scalars['String']>
  readonly payers: ReadonlyArray<Payer>
  readonly publications: VacancyCounterGraphType
  readonly rating: Maybe<Scalars['Int']>
  readonly scaleName: Maybe<Scalars['String']>
  readonly segmentCategory: Maybe<CompanySegmentEnum>
  readonly segmentationMechanics: Maybe<SegmentationMechanicsEnum>
  readonly shortDescription: Maybe<Scalars['String']>
  readonly state: CompanyStateEnum
  readonly stateId: Maybe<Scalars['Int']>
  readonly wallets: Maybe<WalletsConnection>
}

export interface CrmCompanyWalletsArgs {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
}

export interface CrmCompanyConnection {
  readonly __typename?: 'CrmCompanyConnection'
  readonly edges: Maybe<ReadonlyArray<Maybe<CrmCompanyEdge>>>
  readonly items: Maybe<ReadonlyArray<Maybe<CrmCompany>>>
  readonly pageInfo: PageInfo
  readonly totalCount: Maybe<Scalars['Int']>
}

export interface CrmCompanyEdge {
  readonly __typename?: 'CrmCompanyEdge'
  readonly cursor: Scalars['String']
  readonly node: Maybe<CrmCompany>
}

export interface CrmCompletionAct {
  readonly __typename?: 'CrmCompletionAct'
  readonly number1C: Maybe<Scalars['String']>
  readonly sendTypeName: Maybe<Scalars['String']>
  readonly sentDate: Maybe<Scalars['DateTime']>
}

export interface CrmContact {
  readonly __typename?: 'CrmContact'
  readonly email: Maybe<Scalars['String']>
  readonly name: Maybe<Scalars['String']>
}

export interface CrmDateInterval {
  readonly begin?: InputMaybe<Scalars['DateTimeOffset']>
  readonly end?: InputMaybe<Scalars['DateTimeOffset']>
}

export enum CrmDayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export interface CrmEditAction {
  readonly __typename?: 'CrmEditAction'
  readonly actionComment: Maybe<Scalars['String']>
  readonly addDate: Maybe<Scalars['DateTime']>
  readonly assigned: Maybe<Scalars['String']>
  readonly assignedName: Maybe<Scalars['String']>
  readonly companyName: Maybe<Scalars['String']>
  readonly completeDate: Maybe<Scalars['DateTime']>
  readonly dashboardEndDate: Maybe<Scalars['DateTime']>
  readonly dashboardStartDate: Maybe<Scalars['DateTime']>
  readonly dealId: Maybe<Scalars['ID']>
  readonly executionDate: Maybe<Scalars['DateTime']>
  readonly executionDisplayDate: Maybe<Scalars['String']>
  readonly giftCount: Maybe<Scalars['Int']>
  readonly id: Maybe<Scalars['ID']>
  readonly isByPersonalCar: Maybe<Scalars['Boolean']>
  readonly isDashboardLinkActive: Scalars['Boolean']
  readonly isInaccurateDate: Maybe<Scalars['Boolean']>
  readonly isNotInterested: Maybe<Scalars['Boolean']>
  readonly isPinned: Maybe<Scalars['Boolean']>
  readonly isWholeDay: Maybe<Scalars['Boolean']>
  readonly loyaltyId: Maybe<Scalars['ID']>
  readonly meetingReasonName: Maybe<Scalars['String']>
  readonly metaNotebookId: Scalars['ID']
  readonly multiUserId: Maybe<Scalars['ID']>
  readonly multiUserName: Maybe<Scalars['String']>
  readonly notebookId: Maybe<Scalars['ID']>
  readonly parentAlarmId: Maybe<Scalars['ID']>
  readonly parentId: Maybe<Scalars['ID']>
  readonly priority: Maybe<Scalars['ID']>
  readonly responsible: Maybe<Scalars['String']>
  readonly responsibleName: Maybe<Scalars['String']>
  readonly stateId: Maybe<CrmActionState>
  readonly subject: Maybe<Scalars['String']>
  readonly typeId: Maybe<CrmActionTypeEnum>
  readonly userCardId: Maybe<Scalars['ID']>
}

export interface CrmEmployee {
  readonly __typename?: 'CrmEmployee'
  readonly fullName: Maybe<Scalars['String']>
  readonly id: Maybe<Scalars['ID']>
  readonly multiUserId: Maybe<Scalars['ID']>
  readonly name: Maybe<Scalars['String']>
  readonly surname: Maybe<Scalars['String']>
}

export interface CrmFilterCallInput {
  readonly callDate?: InputMaybe<CrmDateInterval>
  readonly companyId?: InputMaybe<Scalars['ID']>
  readonly includeCounters?: InputMaybe<Scalars['Boolean']>
  readonly query?: InputMaybe<Scalars['String']>
  readonly types?: InputMaybe<ReadonlyArray<InputMaybe<CrmCallTypeRequest>>>
}

export interface CrmLinkPhoneToUserCardInput {
  readonly cardId?: InputMaybe<Scalars['ID']>
  readonly phone?: InputMaybe<Scalars['String']>
}

export interface CrmManager {
  readonly __typename?: 'CrmManager'
  readonly departmentId: Maybe<Scalars['ID']>
  readonly email: Maybe<Scalars['String']>
  readonly fullName: Maybe<Scalars['String']>
  readonly id: Maybe<Scalars['ID']>
  readonly internalPhone: Maybe<Scalars['String']>
  readonly mobilePhone: Maybe<Scalars['String']>
  readonly nameRu: Maybe<Scalars['String']>
  readonly nameUa: Maybe<Scalars['String']>
  readonly parentManagerId: Maybe<Scalars['ID']>
  readonly role: Maybe<CrmManagerRole>
  readonly smId: Maybe<Scalars['ID']>
  readonly stmId: Maybe<Scalars['ID']>
}

export interface CrmManagerCalendarRequest {
  readonly month?: InputMaybe<Scalars['Int']>
  readonly year?: InputMaybe<Scalars['Int']>
}

export enum CrmManagerRole {
  Manager = 'MANAGER',
  SalesManager = 'SALES_MANAGER',
  SalesTeamManager = 'SALES_TEAM_MANAGER'
}

export interface CrmMarketingService {
  readonly __typename?: 'CrmMarketingService'
  readonly bundle: Maybe<Scalars['Int']>
  readonly clientPriceSumWithVAT: Maybe<Scalars['Decimal']>
  readonly companyName: Maybe<Scalars['String']>
  readonly firstPaymentDate: Maybe<Scalars['DateTime']>
  readonly managerId: Maybe<Scalars['ID']>
  readonly managerName: Maybe<Scalars['String']>
  readonly notebookId: Maybe<Scalars['ID']>
  readonly orderDetailId: Maybe<Scalars['ID']>
  readonly orderDetailState: Maybe<CrmPositionState>
  readonly orderId: Maybe<Scalars['ID']>
  readonly orderPayStatus: Maybe<Scalars['String']>
  readonly orderPayStatusId: Maybe<CrmPayState>
  readonly orderType: Maybe<CrmOrderType>
  readonly serviceDate: Maybe<Scalars['DateTime']>
  readonly serviceId: Maybe<Scalars['ID']>
  readonly serviceName: Maybe<Scalars['String']>
}

export interface CrmMarketingServiceConnection {
  readonly __typename?: 'CrmMarketingServiceConnection'
  readonly edges: Maybe<ReadonlyArray<Maybe<CrmMarketingServiceEdge>>>
  readonly items: Maybe<ReadonlyArray<Maybe<CrmMarketingService>>>
  readonly pageInfo: PageInfo
  readonly totalCount: Maybe<Scalars['Int']>
}

export interface CrmMarketingServiceEdge {
  readonly __typename?: 'CrmMarketingServiceEdge'
  readonly cursor: Scalars['String']
  readonly node: Maybe<CrmMarketingService>
}

export interface CrmMarketingServiceFilter {
  readonly __typename?: 'CrmMarketingServiceFilter'
  readonly filter: Maybe<CrmMarketingServiceFilterType>
  readonly id: Maybe<Scalars['ID']>
  readonly name: Maybe<Scalars['String']>
}

export enum CrmMarketingServiceFilterType {
  Manager = 'MANAGER',
  Service = 'SERVICE'
}

export interface CrmMeetingReason {
  readonly __typename?: 'CrmMeetingReason'
  readonly id: Maybe<CrmActionTypeEnum>
  readonly name: Maybe<Scalars['String']>
  readonly nameUkr: Maybe<Scalars['String']>
}

export interface CrmMetaCompany {
  readonly __typename?: 'CrmMetaCompany'
  readonly companyName: Maybe<Scalars['String']>
  readonly email: Maybe<Scalars['String']>
  readonly id: Maybe<Scalars['ID']>
}

export interface CrmMultiUser {
  readonly __typename?: 'CrmMultiUser'
  readonly activeVacancyCount: Maybe<Scalars['Int']>
  readonly addDate: Maybe<Scalars['DateTime']>
  readonly email: Maybe<Scalars['String']>
  readonly fax: Maybe<Scalars['String']>
  readonly id: Maybe<Scalars['ID']>
  readonly internalPhone: Maybe<Scalars['String']>
  readonly isApproved: Scalars['Boolean']
  readonly lastVisit: Maybe<Scalars['DateTime']>
  readonly loginLink: Maybe<Scalars['String']>
  readonly mobilePhone: Maybe<Scalars['String']>
  readonly name: Maybe<Scalars['String']>
  readonly phone: Maybe<Scalars['String']>
  readonly position: Maybe<Scalars['String']>
  readonly publishedVacancyCount: Maybe<Scalars['Int']>
  readonly region: Maybe<Scalars['String']>
  readonly rights: ReadonlyArray<Scalars['String']>
  readonly role: Maybe<Scalars['String']>
  readonly roleId: Maybe<CrmMultiUserRole>
  readonly user: Maybe<User>
  readonly userCard: Scalars['ID']
  readonly viewedResumeCount: Maybe<Scalars['Int']>
}

export enum CrmMultiUserRole {
  Admin = 'ADMIN',
  Guest = 'GUEST',
  None = 'NONE',
  Recruiter = 'RECRUITER'
}

export enum CrmOrderType {
  Bonus = 'BONUS',
  Conversion = 'CONVERSION',
  ECommerce = 'E_COMMERCE',
  Normal = 'NORMAL',
  Test = 'TEST'
}

export enum CrmPayState {
  Bonus = 'BONUS',
  NotPaid = 'NOT_PAID',
  Paid = 'PAID',
  PartlyPaid = 'PARTLY_PAID'
}

export interface CrmPhone {
  readonly __typename?: 'CrmPhone'
  readonly isUserPhone: Maybe<Scalars['Boolean']>
  readonly lastCall: Maybe<Scalars['DateTime']>
  readonly phone: Maybe<Scalars['String']>
  readonly phoneId: Maybe<Scalars['ID']>
}

export enum CrmPhoneUpdateAction {
  Add = 'ADD',
  Delete = 'DELETE',
  Edit = 'EDIT'
}

export interface CrmPinActionInput {
  readonly actionId?: InputMaybe<Scalars['ID']>
  readonly isPinned?: InputMaybe<Scalars['Boolean']>
}

export interface CrmPlannedActionByDay {
  readonly __typename?: 'CrmPlannedActionByDay'
  readonly actionsCount: Maybe<Scalars['Int']>
  readonly dayOfMonth: Maybe<Scalars['Int']>
  readonly dayOfWeek: Maybe<CrmDayOfWeek>
}

export enum CrmPositionState {
  Activated = 'ACTIVATED',
  Done = 'DONE',
  Editing = 'EDITING',
  None = 'NONE'
}

export interface CrmQuarter {
  readonly __typename?: 'CrmQuarter'
  readonly number: Maybe<Scalars['Int']>
  readonly year: Maybe<Scalars['Int']>
}

export interface CrmQuarterInput {
  readonly number?: InputMaybe<Scalars['Int']>
  readonly year?: InputMaybe<Scalars['Int']>
}

export interface CrmUserCard {
  readonly __typename?: 'CrmUserCard'
  readonly comment: Maybe<Scalars['String']>
  readonly company: Maybe<CrmCompany>
  readonly id: Maybe<Scalars['ID']>
  readonly isAdmin: Maybe<Scalars['Boolean']>
  readonly isElected: Maybe<Scalars['Boolean']>
  readonly isOwn: Maybe<Scalars['Boolean']>
  readonly isUserCard: Maybe<Scalars['Boolean']>
  readonly messengers: ReadonlyArray<MessengerContact>
  readonly multiUserId: Maybe<Scalars['ID']>
  readonly name: Maybe<Scalars['String']>
  readonly phones: Maybe<ReadonlyArray<Maybe<CrmPhone>>>
  readonly postName: Maybe<Scalars['String']>
  readonly stateId: Maybe<CrmUserCardState>
  readonly userEmail: Maybe<Scalars['String']>
  readonly userEmails: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>
}

export interface CrmUserCardAddInput {
  readonly addressCity?: InputMaybe<Scalars['String']>
  readonly addressHouseNumber?: InputMaybe<Scalars['String']>
  readonly addressStreet?: InputMaybe<Scalars['String']>
  readonly birthday?: InputMaybe<Scalars['Date']>
  readonly comment?: InputMaybe<Scalars['String']>
  readonly emails?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
  readonly messengers?: InputMaybe<ReadonlyArray<InputMaybe<MessengerContactInput>>>
  readonly multiUserId?: InputMaybe<Scalars['ID']>
  readonly name: Scalars['String']
  readonly notebookId: Scalars['ID']
  readonly phones?: InputMaybe<ReadonlyArray<InputMaybe<CrmUserPhoneUpdateInput>>>
  readonly postName?: InputMaybe<Scalars['String']>
  readonly roleId?: InputMaybe<CrmUserCardRoleEnum>
  readonly surname?: InputMaybe<Scalars['String']>
}

export interface CrmUserCardEdit {
  readonly __typename?: 'CrmUserCardEdit'
  readonly addDate: Maybe<Scalars['DateTime']>
  readonly addressCity: Maybe<Scalars['String']>
  readonly addressHouseNumber: Maybe<Scalars['String']>
  readonly addressStreet: Maybe<Scalars['String']>
  readonly birthday: Maybe<Scalars['DateTime']>
  readonly comment: Maybe<Scalars['String']>
  readonly emails: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>
  readonly id: Maybe<Scalars['ID']>
  readonly isElected: Maybe<Scalars['Boolean']>
  readonly messengers: ReadonlyArray<MessengerContact>
  readonly multiUserId: Maybe<Scalars['ID']>
  readonly name: Maybe<Scalars['String']>
  readonly phones: Maybe<ReadonlyArray<Maybe<CrmPhone>>>
  readonly postName: Maybe<Scalars['String']>
  readonly roleId: Maybe<CrmUserCardRoleEnum>
  readonly stateId: Maybe<CrmUserCardState>
  readonly surname: Maybe<Scalars['String']>
}

export interface CrmUserCardElectInput {
  readonly id: Scalars['ID']
  readonly isUserCard: Scalars['Boolean']
  readonly selected: Scalars['Boolean']
}

export interface CrmUserCardRole {
  readonly __typename?: 'CrmUserCardRole'
  readonly id: Maybe<CrmUserCardRoleEnum>
  readonly name: Maybe<Scalars['String']>
  readonly nameUkr: Maybe<Scalars['String']>
}

export enum CrmUserCardRoleEnum {
  MakesDecision = 'MAKES_DECISION',
  MinorInfluence = 'MINOR_INFLUENCE',
  NotDefined = 'NOT_DEFINED',
  NoInfluence = 'NO_INFLUENCE',
  StrongInfluence = 'STRONG_INFLUENCE'
}

export enum CrmUserCardState {
  Actual = 'ACTUAL',
  NotActual = 'NOT_ACTUAL',
  NotDefined = 'NOT_DEFINED'
}

export interface CrmUserCardUpdateInput {
  readonly addressCity?: InputMaybe<Scalars['String']>
  readonly addressHouseNumber?: InputMaybe<Scalars['String']>
  readonly addressStreet?: InputMaybe<Scalars['String']>
  readonly birthday?: InputMaybe<Scalars['Date']>
  readonly comment?: InputMaybe<Scalars['String']>
  readonly emails?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
  readonly id: Scalars['ID']
  readonly messengers?: InputMaybe<ReadonlyArray<InputMaybe<MessengerContactInput>>>
  readonly name: Scalars['String']
  readonly phones?: InputMaybe<ReadonlyArray<InputMaybe<CrmUserPhoneUpdateInput>>>
  readonly postName?: InputMaybe<Scalars['String']>
  readonly roleId?: InputMaybe<CrmUserCardRoleEnum>
  readonly surname?: InputMaybe<Scalars['String']>
}

export interface CrmUserPhoneUpdateInput {
  readonly action: CrmPhoneUpdateAction
  readonly phone: Scalars['String']
  readonly phoneId: Scalars['ID']
}

export enum Currency {
  Uah = 'UAH',
  Usd = 'USD'
}

export enum CustomNotificationButtonTypeEnum {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY'
}

export interface CustomNotificationDetails {
  readonly __typename?: 'CustomNotificationDetails'
  readonly detailType: SeekerNotificationDetailTypeEnum
  readonly elements: ReadonlyArray<CustomNotificationElement>
}

export interface CustomNotificationElement {
  readonly __typename?: 'CustomNotificationElement'
  readonly buttons: ReadonlyArray<CustomNotificationElementButton>
  readonly iconUrl: Scalars['String']
  readonly text: ReadonlyArray<CustomNotificationElementText>
}

export interface CustomNotificationElementButton {
  readonly __typename?: 'CustomNotificationElementButton'
  readonly label: Scalars['String']
  readonly type: CustomNotificationButtonTypeEnum
  readonly url: Scalars['String']
}

export interface CustomNotificationElementText {
  readonly __typename?: 'CustomNotificationElementText'
  readonly isBold: Scalars['Boolean']
  readonly text: Scalars['String']
  readonly url: Maybe<Scalars['String']>
}

export interface CvDbCatalogService {
  readonly __typename?: 'CvDbCatalogService'
  readonly calculatePrice: ServicePrice
  readonly city: City
  readonly contactsAccrualPeriod: Maybe<ContactsAccrualPeriod>
  readonly groupWrapper: ServiceGroupWrapper
  readonly id: Scalars['ID']
  readonly name: Maybe<Scalars['String']>
  readonly rubric: Rubric
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly totalContacts: Maybe<Scalars['Int']>
  readonly typeWrapper: ServiceTypeWrapper
  readonly usagePeriod: UsagePeriodTypeWrapper
}

export interface CvDbCatalogServiceCalculatePriceArgs {
  input: InputMaybe<CvdbPriceInput>
}

export interface CvDbServiceEmployerEventSnapshot {
  readonly __typename?: 'CvDbServiceEmployerEventSnapshot'
  readonly activeTo: Scalars['DateTime']
  readonly addedAt: Scalars['DateTime']
  readonly catalogService: CatalogService
  readonly city: City
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly period: Maybe<CvDbServiceUsagePeriodEmployerEventEnum>
  readonly rubric: Rubric
}

export enum CvDbServiceUsagePeriodEmployerEventEnum {
  HalfYear = 'HALF_YEAR',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Week = 'WEEK',
  Year = 'YEAR'
}

export interface CvRubricInput {
  readonly experience: Scalars['ID']
  readonly rubric: Scalars['ID']
  readonly subrubric: Scalars['ID']
}

export interface CvTagInput {
  readonly group: Scalars['ID']
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export interface CvdbPriceInput {
  readonly cityId: Scalars['ID']
  readonly count: Scalars['Int']
  readonly rubricId: Scalars['ID']
  readonly usagePeriodId: Scalars['ID']
}

export interface DailyResumeViewsReportCalculated {
  readonly __typename?: 'DailyResumeViewsReportCalculated'
  readonly companiesCount: Scalars['Int']
  readonly detailType: SeekerNotificationDetailTypeEnum
  readonly resume: Maybe<ProfResume>
  readonly viewsCount: Scalars['Int']
}

export interface DashboardCompany {
  readonly __typename?: 'DashboardCompany'
  readonly cityId: Maybe<Scalars['Int']>
  readonly id: Maybe<Scalars['ID']>
  readonly logo: Maybe<Scalars['String']>
  readonly managerId: Maybe<Scalars['Int']>
  readonly name: Maybe<Scalars['String']>
  readonly notebookId: Maybe<Scalars['Int']>
  readonly stateId: Maybe<Scalars['Int']>
}

export interface DashboardCompanyConnection {
  readonly __typename?: 'DashboardCompanyConnection'
  readonly edges: Maybe<ReadonlyArray<Maybe<DashboardCompanyEdge>>>
  readonly items: Maybe<ReadonlyArray<Maybe<DashboardCompany>>>
  readonly pageInfo: PageInfo
  readonly totalCount: Maybe<Scalars['Int']>
}

export interface DashboardCompanyEdge {
  readonly __typename?: 'DashboardCompanyEdge'
  readonly cursor: Scalars['String']
  readonly node: Maybe<DashboardCompany>
}

export interface DashboardCompanyInput {
  readonly endCalendarDate?: InputMaybe<Scalars['DateTime']>
  readonly notebookId?: InputMaybe<Scalars['ID']>
  readonly section?: InputMaybe<DashboardSection>
  readonly startCalendarDate?: InputMaybe<Scalars['DateTime']>
}

export interface DashboardMultiUserInput {
  readonly endCalendarDate?: InputMaybe<Scalars['DateTime']>
  readonly notebookId?: InputMaybe<Scalars['ID']>
  readonly section?: InputMaybe<DashboardMultiUserSection>
  readonly startCalendarDate?: InputMaybe<Scalars['DateTime']>
  readonly values?: InputMaybe<DashboardValuesEnum>
}

export interface DashboardMultiUserResponse {
  readonly __typename?: 'DashboardMultiUserResponse'
  readonly chartData: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>
  readonly currentValue: Maybe<Scalars['Float']>
  readonly diffAbs: Maybe<Scalars['Int']>
  readonly diffPerc: Maybe<Scalars['Float']>
  readonly isStared: Maybe<Scalars['Boolean']>
  readonly multiUserId: Maybe<Scalars['Int']>
  readonly multiUserName: Maybe<Scalars['String']>
  readonly position: Maybe<Scalars['String']>
  readonly region: Maybe<Scalars['String']>
  readonly section: Maybe<DashboardMultiUserSection>
}

export enum DashboardMultiUserSection {
  ResumeInvitations = 'RESUME_INVITATIONS',
  ResumeNotesRate = 'RESUME_NOTES_RATE',
  ResumeViewsPerVacancy = 'RESUME_VIEWS_PER_VACANCY',
  VacancyActiveLocationRate = 'VACANCY_ACTIVE_LOCATION_RATE',
  VacancyApplyFeedbackRate = 'VACANCY_APPLY_FEEDBACK_RATE',
  VacancyApplySpecialFoldersRate = 'VACANCY_APPLY_SPECIAL_FOLDERS_RATE',
  VacancyApplyViewsRate = 'VACANCY_APPLY_VIEWS_RATE',
  VacancyApplyViewsTime = 'VACANCY_APPLY_VIEWS_TIME',
  VacancyMedia = 'VACANCY_MEDIA',
  VacancyPublication = 'VACANCY_PUBLICATION'
}

export interface DashboardRatingInput {
  readonly endCalendarDate?: InputMaybe<Scalars['DateTime']>
  readonly giftCount?: InputMaybe<Scalars['Int']>
  readonly notebookId?: InputMaybe<Scalars['ID']>
  readonly section?: InputMaybe<DashboardRatingSection>
  readonly startCalendarDate?: InputMaybe<Scalars['DateTime']>
  readonly values?: InputMaybe<DashboardValuesEnum>
}

export interface DashboardRatingResponse {
  readonly __typename?: 'DashboardRatingResponse'
  readonly currentRank: Maybe<Scalars['Int']>
  readonly currentValue: Maybe<Scalars['Float']>
  readonly diffAbs: Maybe<Scalars['Float']>
  readonly diffPerc: Maybe<Scalars['Float']>
  readonly multiUserId: Maybe<Scalars['Int']>
  readonly multiUserName: Maybe<Scalars['String']>
  readonly position: Maybe<Scalars['String']>
  readonly rating: Maybe<Scalars['Int']>
  readonly region: Maybe<Scalars['String']>
  readonly section: Maybe<DashboardRatingSection>
}

export enum DashboardRatingSection {
  Nominees = 'NOMINEES',
  ResumeViewsPerVacancy = 'RESUME_VIEWS_PER_VACANCY',
  VacancyApplyViewsRate = 'VACANCY_APPLY_VIEWS_RATE',
  VacancyPublication = 'VACANCY_PUBLICATION'
}

export interface DashboardResponse {
  readonly __typename?: 'DashboardResponse'
  readonly chartData: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>
  readonly percents: Maybe<Scalars['Float']>
  readonly points: Maybe<Scalars['Int']>
  readonly section: Maybe<DashboardSection>
  readonly value: Maybe<Scalars['Int']>
}

export enum DashboardSection {
  ContactOpenings = 'CONTACT_OPENINGS',
  ResumeInvitations = 'RESUME_INVITATIONS',
  ResumeNotes = 'RESUME_NOTES',
  ResumeViews = 'RESUME_VIEWS',
  VacancyActive = 'VACANCY_ACTIVE',
  VacancyActiveLocation = 'VACANCY_ACTIVE_LOCATION',
  VacancyApply = 'VACANCY_APPLY',
  VacancyApplyFeedback = 'VACANCY_APPLY_FEEDBACK',
  VacancyApplySpecialFolders = 'VACANCY_APPLY_SPECIAL_FOLDERS',
  VacancyApplyViews = 'VACANCY_APPLY_VIEWS',
  VacancyApplyViewsTime = 'VACANCY_APPLY_VIEWS_TIME',
  VacancyPublication = 'VACANCY_PUBLICATION'
}

export enum DashboardValuesEnum {
  Average = 'AVERAGE',
  Total = 'TOTAL'
}

export interface DateCounter {
  readonly __typename?: 'DateCounter'
  readonly count: Scalars['Long']
  readonly date: Scalars['DateTimeOffset']
}

export interface DateInterval {
  readonly __typename?: 'DateInterval'
  readonly begin: Maybe<Scalars['DateTimeOffset']>
  readonly end: Maybe<Scalars['DateTimeOffset']>
}

export interface DateIntervalInput {
  readonly begin?: InputMaybe<Scalars['DateTime']>
  readonly end?: InputMaybe<Scalars['DateTime']>
}

export interface DateRange {
  readonly begin?: InputMaybe<Scalars['DateTime']>
  readonly end?: InputMaybe<Scalars['DateTime']>
}

export interface DateRangeInput {
  readonly begin?: InputMaybe<Scalars['DateTime']>
  readonly end?: InputMaybe<Scalars['DateTime']>
}

export interface DealCard {
  readonly __typename?: 'DealCard'
  readonly accounts: ReadonlyArray<DealCardAccount>
  readonly bonusDate: Maybe<Scalars['DateTime']>
  readonly bonusSum: Maybe<Scalars['Decimal']>
  readonly closePossibility: Scalars['Int']
  readonly column: DealTableFolderEnum
  readonly company: CrmCompany
  readonly dealState: DealStateEnum
  readonly dealStateName: Scalars['String']
  readonly id: Scalars['ID']
  readonly isArchive: Scalars['Boolean']
  readonly isEcommerce: Scalars['Boolean']
  readonly isTurbota: Scalars['Boolean']
  readonly kanbanCardDate: Maybe<Scalars['DateTime']>
  readonly orderClientPriceSumVat: Scalars['Decimal']
  readonly orderDebt: Scalars['Decimal']
  readonly orderNumber: Maybe<Scalars['Int']>
  readonly orderPaymentSum: Scalars['Decimal']
  readonly orderRecognizedRevenue: Scalars['Decimal']
}

export interface DealCardAccount {
  readonly __typename?: 'DealCardAccount'
  readonly accYear: Scalars['Int']
  readonly accountDate: Scalars['DateTime']
  readonly addDate: Scalars['DateTime']
  readonly dateChanges: ReadonlyArray<AccountDateChanges>
  readonly dateFirst: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly payment: Maybe<DealCardPayment>
  readonly possibility: Scalars['Int']
  readonly sumVat: Scalars['Decimal']
}

export interface DealCardPayment {
  readonly __typename?: 'DealCardPayment'
  readonly date: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly sumVat: Scalars['Decimal']
}

export interface DealDetailsOutput {
  readonly __typename?: 'DealDetailsOutput'
  readonly accounts: ReadonlyArray<DealCardAccount>
  readonly company: CrmCompany
  readonly dealAddDate: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly isEcommerce: Scalars['Boolean']
  readonly isTurbota: Scalars['Boolean']
  readonly order: DealOrderCard
}

export interface DealForecast {
  readonly __typename?: 'DealForecast'
  readonly column: DealTableFolderEnum
  readonly expectedIncome: Scalars['Decimal']
  readonly maxPossibility: Scalars['Int']
  readonly minPossibility: Scalars['Int']
}

export interface DealOrderCard {
  readonly __typename?: 'DealOrderCard'
  readonly clientPriceSumVat: Scalars['Decimal']
  readonly debt: Scalars['Decimal']
  readonly id: Scalars['ID']
  readonly paymentSum: Scalars['Decimal']
  readonly recognizedRevenue: Scalars['Decimal']
}

export enum DealStateEnum {
  Done = 'DONE',
  InProcess = 'IN_PROCESS',
  NotDone = 'NOT_DONE',
  Unknown = 'UNKNOWN'
}

export enum DealTableFolderEnum {
  AccountPaid = 'ACCOUNT_PAID',
  Activated = 'ACTIVATED',
  Created = 'CREATED',
  CreatedAccount = 'CREATED_ACCOUNT',
  Done = 'DONE'
}

export interface DealTableOutput {
  readonly __typename?: 'DealTableOutput'
  readonly deals: ReadonlyArray<DealCard>
  readonly forecasts: ReadonlyArray<DealForecast>
}

export interface DealTableRequestInput {
  readonly accountId?: InputMaybe<Scalars['Int']>
  readonly accountIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>
  readonly accountYear?: InputMaybe<Scalars['Int']>
  readonly endDate?: InputMaybe<Scalars['DateTime']>
  readonly isChangedAccDate?: InputMaybe<Scalars['Boolean']>
  readonly isHasDebt?: InputMaybe<Scalars['Boolean']>
  readonly isShowArchive?: InputMaybe<Scalars['Boolean']>
  readonly isShowEcommerce?: InputMaybe<Scalars['Boolean']>
  readonly isTurbota?: InputMaybe<Scalars['Boolean']>
  readonly managerIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>
  readonly notebookIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>
  readonly paymentStateIds?: InputMaybe<ReadonlyArray<CrmPayState>>
  readonly startDate?: InputMaybe<Scalars['DateTime']>
}

export type DeleteAllNotificationsError = NotificationNotFoundError | UnknownServerError

export interface DeleteAllNotificationsOutput {
  readonly __typename?: 'DeleteAllNotificationsOutput'
  readonly errors: Maybe<ReadonlyArray<DeleteAllNotificationsError>>
  readonly notifications: Maybe<ReadonlyArray<Notification>>
}

export type DeleteNotificationsError = NotificationNotFoundError | UnknownServerError

export interface DeleteNotificationsInput {
  readonly notificationIds: ReadonlyArray<Scalars['ID']>
}

export interface DeleteNotificationsOutput {
  readonly __typename?: 'DeleteNotificationsOutput'
  readonly errors: Maybe<ReadonlyArray<DeleteNotificationsError>>
  readonly notifications: Maybe<ReadonlyArray<Notification>>
}

export interface DeletePostFromHotOutput {
  readonly __typename?: 'DeletePostFromHotOutput'
  readonly post: Maybe<ClubPost>
  readonly postId: Scalars['ID']
}

export interface DeletePostFromTopOutput {
  readonly __typename?: 'DeletePostFromTopOutput'
  readonly post: Maybe<ClubPost>
  readonly postId: Scalars['ID']
}

export interface DeleteProfResumeInput {
  readonly resumeId: Scalars['ID']
}

export interface DeleteProfResumePortfolioOutput {
  readonly __typename?: 'DeleteProfResumePortfolioOutput'
  readonly errors: Maybe<ReadonlyArray<DeleteSeekerProfResumePortfoliosError>>
  readonly profResume: Maybe<ProfResume>
}

export interface DeleteProfResumeSocialNetworksOutput {
  readonly __typename?: 'DeleteProfResumeSocialNetworksOutput'
  readonly errors: Maybe<ReadonlyArray<DeleteSeekerProfResumeSocialNetworksError>>
  readonly profResume: Maybe<ProfResume>
}

export interface DeleteProfileOutput {
  readonly status: DeleteProfileStatus
}

export enum DeleteProfileStatus {
  ConfirmationFailed = 'CONFIRMATION_FAILED',
  Deleted = 'DELETED',
  Failed = 'FAILED',
  SentEmailForConfirm = 'SENT_EMAIL_FOR_CONFIRM',
  SentSmsForConfirm = 'SENT_SMS_FOR_CONFIRM'
}

export interface DeleteProfileWithEmailOutput extends DeleteProfileOutput {
  readonly __typename?: 'DeleteProfileWithEmailOutput'
  readonly sendEmailStatus: SendEmailOutput
  readonly status: DeleteProfileStatus
}

export interface DeleteProfileWithSmsOutput extends DeleteProfileOutput {
  readonly __typename?: 'DeleteProfileWithSmsOutput'
  readonly sendSmsStatus: SendSmsOutput
  readonly status: DeleteProfileStatus
}

export type DeleteSeekerProfResumeAdditionalEducationsError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type DeleteSeekerProfResumeAdditionalInfoError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type DeleteSeekerProfResumeAdditionalPhonesError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export interface DeleteSeekerProfResumeAdditionalPhonesOutput {
  readonly __typename?: 'DeleteSeekerProfResumeAdditionalPhonesOutput'
  readonly errors: Maybe<ReadonlyArray<DeleteSeekerProfResumeAdditionalPhonesError>>
  readonly profResume: Maybe<ProfResume>
}

export type DeleteSeekerProfResumeDiiaCertificateError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type DeleteSeekerProfResumeEducationsError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type DeleteSeekerProfResumeError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type DeleteSeekerProfResumeExperiencesError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type DeleteSeekerProfResumeLanguageSkillsError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type DeleteSeekerProfResumePortfoliosError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type DeleteSeekerProfResumeSocialNetworksError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export interface DeleteTargetedMailingOutput {
  readonly __typename?: 'DeleteTargetedMailingOutput'
  readonly isSuccessful: Scalars['Boolean']
}

export interface DeleteVacancyInput {
  readonly vacanciesIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  readonly vacancyId: Scalars['ID']
}

export interface DeleteVacancyOutput {
  readonly __typename?: 'DeleteVacancyOutput'
  readonly vacanciesIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>
  readonly vacancyId: Maybe<Scalars['ID']>
}

export interface DeletedProfResumeAdditionalEducationsOutput {
  readonly __typename?: 'DeletedProfResumeAdditionalEducationsOutput'
  readonly errors: Maybe<ReadonlyArray<DeleteSeekerProfResumeAdditionalEducationsError>>
  readonly profResume: Maybe<ProfResume>
}

export interface DeletedProfResumeAdditionalInfoOutput {
  readonly __typename?: 'DeletedProfResumeAdditionalInfoOutput'
  readonly errors: Maybe<ReadonlyArray<DeleteSeekerProfResumeAdditionalInfoError>>
  readonly profResume: Maybe<ProfResume>
}

export interface DeletedProfResumeEducationsOutput {
  readonly __typename?: 'DeletedProfResumeEducationsOutput'
  readonly errors: Maybe<ReadonlyArray<DeleteSeekerProfResumeEducationsError>>
  readonly profResume: Maybe<ProfResume>
}

export interface DeletedProfResumeExperiencesOutput {
  readonly __typename?: 'DeletedProfResumeExperiencesOutput'
  readonly errors: Maybe<ReadonlyArray<DeleteSeekerProfResumeExperiencesError>>
  readonly profResume: Maybe<ProfResume>
}

export interface DeletedProfResumeLanguageSkillsOutput {
  readonly __typename?: 'DeletedProfResumeLanguageSkillsOutput'
  readonly errors: Maybe<ReadonlyArray<DeleteSeekerProfResumeLanguageSkillsError>>
  readonly profResume: Maybe<ProfResume>
}

export interface DeletedProfResumeOutput {
  readonly __typename?: 'DeletedProfResumeOutput'
  readonly errors: Maybe<ReadonlyArray<DeleteSeekerProfResumeError>>
  readonly profResume: Maybe<ReadonlyArray<ProfResume>>
}

export interface DeletedUserManagementEmployerEvent {
  readonly __typename?: 'DeletedUserManagementEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly deleted: EmployerEventSnapshot
  readonly id: Scalars['ID']
  readonly performer: PerformerEmployerEventUnion
  readonly responsible: EmployerEventSnapshot
}

export interface DeletedVacancyEmployerEvent {
  readonly __typename?: 'DeletedVacancyEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly performer: PerformerEmployerEventUnion
  readonly vacancyCity: City
  readonly vacancyId: Scalars['ID']
  readonly vacancyName: Scalars['String']
}

export enum DepartmentEnum {
  Fs = 'FS',
  Is = 'IS',
  Ms = 'MS',
  Ps = 'PS',
  Ss = 'SS'
}

export interface DirectoryItem {
  readonly __typename?: 'DirectoryItem'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export type Discount = ManagerDiscount | PromoCodeDiscount

export interface DislikeSeekerVacanciesInput {
  readonly vacancyIds: ReadonlyArray<Scalars['ID']>
}

export interface DislikeSeekerVacanciesOutput {
  readonly __typename?: 'DislikeSeekerVacanciesOutput'
  readonly query: Query
  readonly vacancies: ReadonlyArray<Vacancy>
}

export interface DistrictFacet {
  readonly __typename?: 'DistrictFacet'
  readonly cityDistrict: CityDistrict
  readonly count: Scalars['Long']
}

export interface DownloadActInput {
  readonly number: Scalars['ID']
  readonly year: Scalars['Int']
}

export interface DownloadActOutput {
  readonly __typename?: 'DownloadActOutput'
  readonly url: Maybe<Scalars['String']>
}

export interface DownloadInvoiceInput {
  readonly items: ReadonlyArray<InputMaybe<DownloadInvoiceInputItem>>
}

export interface DownloadInvoiceInputItem {
  readonly id: Scalars['ID']
  readonly year: Scalars['Int']
}

export interface DownloadInvoiceOutput {
  readonly __typename?: 'DownloadInvoiceOutput'
  readonly url: Maybe<Scalars['String']>
}

export interface DropAppliedWalletBonusResponse {
  readonly __typename?: 'DropAppliedWalletBonusResponse'
  readonly cart: CartQuery
  readonly isSuccess: Scalars['Boolean']
}

export interface DropSelectedPromoCodeResponse {
  readonly __typename?: 'DropSelectedPromoCodeResponse'
  readonly cart: CartQuery
  readonly isSuccess: Scalars['Boolean']
}

export interface DuplicateCompany {
  readonly __typename?: 'DuplicateCompany'
  readonly duplicateType: DuplicateReasonEnum
  readonly id: Scalars['ID']
  readonly managerName: Scalars['String']
  readonly name: Scalars['String']
}

export enum DuplicateReasonEnum {
  Cookie = 'COOKIE',
  Edrpou = 'EDRPOU',
  Ip = 'IP',
  Name = 'NAME',
  Phone = 'PHONE',
  Url = 'URL'
}

export interface EditCvTagInput {
  readonly cvId: Scalars['ID']
  readonly tags?: InputMaybe<ReadonlyArray<CvTagInput>>
}

export interface EditGiftLimitInput {
  readonly comment?: InputMaybe<Scalars['String']>
  readonly details: ReadonlyArray<GiftDetailInput>
  readonly managerId: Scalars['ID']
  readonly occasionId: Scalars['ID']
  readonly occasionWaveId?: InputMaybe<Scalars['ID']>
}

export enum EditLabelActionEnum {
  Add = 'ADD',
  Modify = 'MODIFY',
  Remove = 'REMOVE'
}

export interface EditLabelInput {
  readonly action: EditLabelActionEnum
  readonly cvId: Scalars['ID']
  readonly modified?: InputMaybe<ParsedCvLabelInput>
  readonly original?: InputMaybe<ParsedCvLabelInput>
}

export interface EditedVacancyCityEmployerEvent {
  readonly __typename?: 'EditedVacancyCityEmployerEvent'
  readonly from: City
  readonly to: City
}

export interface EditedVacancyEmployerEvent {
  readonly __typename?: 'EditedVacancyEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly editedFields: ReadonlyArray<EditedVacancyFieldEmployerEventUnion>
  readonly id: Scalars['ID']
  readonly performer: PerformerEmployerEventUnion
  readonly vacancyCity: City
  readonly vacancyId: Scalars['ID']
  readonly vacancyName: Scalars['String']
}

export interface EditedVacancyFieldEmployerEvent {
  readonly __typename?: 'EditedVacancyFieldEmployerEvent'
  readonly name: Scalars['String']
}

export type EditedVacancyFieldEmployerEventUnion = EditedVacancyCityEmployerEvent | EditedVacancyFieldEmployerEvent | EditedVacancyTitleEmployerEvent

export interface EditedVacancyTitleEmployerEvent {
  readonly __typename?: 'EditedVacancyTitleEmployerEvent'
  readonly from: Scalars['String']
  readonly to: Scalars['String']
}

export interface EducationLevel {
  readonly __typename?: 'EducationLevel'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export enum EducationLevelEnum {
  Higher = 'HIGHER',
  Mba = 'MBA',
  Secondary = 'SECONDARY',
  SecondarySpecial = 'SECONDARY_SPECIAL',
  UnfinishedHigher = 'UNFINISHED_HIGHER'
}

export interface EmailAlreadyExistsError {
  readonly __typename?: 'EmailAlreadyExistsError'
  readonly isEmployer: Scalars['Boolean']
  readonly isSeeker: Scalars['Boolean']
  readonly message: Scalars['String']
}

export interface EmailSenderResult {
  readonly __typename?: 'EmailSenderResult'
  readonly isSuccess: Maybe<Scalars['Boolean']>
  readonly message: Maybe<Scalars['String']>
}

export interface EmailStatistics {
  readonly __typename?: 'EmailStatistics'
  readonly appliesCount: Maybe<Scalars['Int']>
  readonly clicks: Scalars['Int']
  readonly deliveries: Scalars['Int']
  readonly links: ReadonlyArray<TargetedMailingLinkUnion>
  readonly openings: Scalars['Int']
}

export interface Employee {
  readonly __typename?: 'Employee'
  readonly addedAt: Scalars['DateTime']
  readonly applyAutoReplyTemplate: EmployeeSubscriptionAnswerTemplate
  readonly ats: EmployeeAtsRelation
  readonly company: Maybe<Company>
  readonly contactPhoto: Maybe<Scalars['String']>
  readonly department: Maybe<Scalars['String']>
  readonly email: Maybe<Scalars['String']>
  readonly fullName: Maybe<Scalars['String']>
  readonly hasEverPostedVacancies: Scalars['Boolean']
  readonly id: Scalars['ID']
  readonly isActiveUser: Maybe<Scalars['Boolean']>
  readonly isCurrentUser: Scalars['Boolean']
  readonly isOnline: Scalars['Boolean']
  readonly lastPasswordChangedDate: Scalars['DateTime']
  readonly lastSeenAt: Maybe<Scalars['DateTime']>
  readonly lastStatusChanged: Maybe<EmployeeStatusChanged>
  readonly mainPhone: Maybe<Scalars['String']>
  readonly phones: ReadonlyArray<Scalars['String']>
  readonly position: Maybe<Scalars['String']>
  readonly post: Maybe<EmployeePostEnum>
  readonly role: Maybe<EmployeeRole>
  readonly scopes: ReadonlyArray<EmployeeRoleBasedScope>
  readonly serviceLimitCounters: Maybe<ReadonlyArray<Maybe<EmployeeLimitCounter>>>
  readonly serviceLimits: Maybe<ReadonlyArray<Maybe<EmployeeSummaryServiceLimit>>>
  readonly status: EmployeeStatusEnum
  readonly subscriptions: ReadonlyArray<EmployeeSubscription>
  readonly user: User
}

export interface EmployeeServiceLimitCountersArgs {
  orderBy: InputMaybe<SummaryServiceOrderBy>
}

export interface EmployeeServiceLimitsArgs {
  orderBy: InputMaybe<SummaryServiceOrderBy>
}

export enum EmployeeActiveStatus {
  Active = 'ACTIVE',
  All = 'ALL',
  Inactive = 'INACTIVE'
}

export interface EmployeeAtsRelation {
  readonly __typename?: 'EmployeeAtsRelation'
  readonly hasCandidatesDatabaseAccess: Scalars['Boolean']
  readonly isBlocked: Scalars['Boolean']
  readonly isRegistered: Scalars['Boolean']
}

export interface EmployeeChangeEmailInput {
  readonly key: Scalars['String']
  readonly newEmail: Scalars['String']
}

export interface EmployeeChangeEmailOutput {
  readonly __typename?: 'EmployeeChangeEmailOutput'
  readonly error: Maybe<EmployeeEditError>
  readonly isSuccessful: Scalars['Boolean']
  readonly token: Maybe<Scalars['String']>
}

export interface EmployeeChangeOutput {
  readonly __typename?: 'EmployeeChangeOutput'
  readonly error: Maybe<EmployeeEditError>
  readonly isSuccessful: Scalars['Boolean']
}

export interface EmployeeChangePasswordInput {
  readonly key: Scalars['String']
  readonly newPassword: Scalars['String']
}

export interface EmployeeConnection {
  readonly __typename?: 'EmployeeConnection'
  readonly edges: Maybe<ReadonlyArray<Maybe<EmployeeEdge>>>
  readonly items: Maybe<ReadonlyArray<Employee>>
  readonly pageInfo: PageInfo
  readonly totalCount: Maybe<Scalars['Int']>
}

export type EmployeeCreateError = EmailAlreadyExistsError | ForbiddenToMutateUsersError

export interface EmployeeCreateInput {
  readonly contactPhoto?: InputMaybe<Scalars['String']>
  readonly department?: InputMaybe<Scalars['String']>
  readonly email?: InputMaybe<Scalars['String']>
  readonly employeeRoleBasedScopeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
  readonly fullName: Scalars['String']
  readonly mainPhone?: InputMaybe<Scalars['String']>
  readonly phones?: InputMaybe<ReadonlyArray<Scalars['String']>>
  readonly position?: InputMaybe<Scalars['String']>
  readonly post?: InputMaybe<EmployeePostEnum>
}

export interface EmployeeCreateOutput {
  readonly __typename?: 'EmployeeCreateOutput'
  readonly employee: Maybe<Employee>
  readonly error: Maybe<EmployeeCreateError>
}

export interface EmployeeCvDbSavedSearchDeleteInput {
  readonly id: Scalars['ID']
}

export interface EmployeeCvDbSavedSearchDeleteOutput {
  readonly __typename?: 'EmployeeCvDbSavedSearchDeleteOutput'
  readonly isSuccessful: Scalars['Boolean']
}

export interface EmployeeEdge {
  readonly __typename?: 'EmployeeEdge'
  readonly cursor: Scalars['String']
  readonly node: Employee
}

export type EmployeeEditError = EmailAlreadyExistsError | ForbiddenToMutateUsersError | PasswordConfirmationFailedError | TokenAlreadyUsedError

export interface EmployeeEditInput {
  readonly contactPhoto?: InputMaybe<Scalars['String']>
  readonly department?: InputMaybe<Scalars['String']>
  readonly email?: InputMaybe<Scalars['String']>
  readonly employeeRoleBasedScopeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
  readonly fullName: Scalars['String']
  readonly id: Scalars['ID']
  readonly mainPhone?: InputMaybe<Scalars['String']>
  readonly password?: InputMaybe<Scalars['String']>
  readonly phones?: InputMaybe<ReadonlyArray<Scalars['String']>>
  readonly position?: InputMaybe<Scalars['String']>
  readonly post?: InputMaybe<EmployeePostEnum>
}

export interface EmployeeEditOutput {
  readonly __typename?: 'EmployeeEditOutput'
  readonly employee: Maybe<Employee>
  readonly error: Maybe<EmployeeEditError>
}

export interface EmployeeHasScopesInput {
  readonly employeeId: Scalars['ID']
  readonly employeeRoleBasedScopeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
}

export interface EmployeeHasScopesOutput {
  readonly __typename?: 'EmployeeHasScopesOutput'
  readonly existing: ReadonlyArray<EmployeeRoleBasedScope>
  readonly hasRequestedScopes: Scalars['Boolean']
  readonly requested: ReadonlyArray<EmployeeRoleBasedScope>
}

export interface EmployeeJoiningRequestAlreadyReviewedError {
  readonly __typename?: 'EmployeeJoiningRequestAlreadyReviewedError'
  readonly message: Scalars['String']
}

export type EmployeeJoiningReviewErrorUnion = EmployeeJoiningRequestAlreadyReviewedError

export interface EmployeeJoiningReviewInput {
  readonly employeeId: Scalars['ID']
  readonly isApproved: Scalars['Boolean']
}

export interface EmployeeJoiningReviewOutput {
  readonly __typename?: 'EmployeeJoiningReviewOutput'
  readonly employee: Maybe<Employee>
  readonly error: Maybe<EmployeeJoiningReviewErrorUnion>
  readonly isSuccessful: Scalars['Boolean']
}

export interface EmployeeLimitCounter {
  readonly __typename?: 'EmployeeLimitCounter'
  readonly has: Maybe<Scalars['Boolean']>
  readonly remaining: Maybe<Scalars['Int']>
  readonly serviceType: Maybe<ServiceType>
  readonly total: Maybe<Scalars['Int']>
  readonly usesCount: Maybe<Scalars['Int']>
}

export interface EmployeeMakeMainOutput {
  readonly __typename?: 'EmployeeMakeMainOutput'
  readonly isSuccessful: Scalars['Boolean']
}

export enum EmployeePostEnum {
  Director = 'DIRECTOR',
  DirectorsAssistant = 'DIRECTORS_ASSISTANT',
  RecruitingManager = 'RECRUITING_MANAGER',
  RecruitingSpecialist = 'RECRUITING_SPECIALIST',
  RegionalManager = 'REGIONAL_MANAGER'
}

export interface EmployeeResendInvitationInput {
  readonly employeeId: Scalars['ID']
}

export interface EmployeeResendInvitationOutput {
  readonly __typename?: 'EmployeeResendInvitationOutput'
  readonly isSuccessful: Scalars['Boolean']
}

export enum EmployeeRole {
  Main = 'MAIN',
  None = 'NONE',
  Subordinate = 'SUBORDINATE'
}

export interface EmployeeRoleBasedScope {
  readonly __typename?: 'EmployeeRoleBasedScope'
  readonly id: Scalars['ID']
  readonly role: EmployeeRoleBasedScopeEnum
}

export enum EmployeeRoleBasedScopeEnum {
  AtsAccess = 'ATS_ACCESS',
  CompanyInfoEditing = 'COMPANY_INFO_EDITING',
  CvdbContactsOpening = 'CVDB_CONTACTS_OPENING',
  LimitsManagement = 'LIMITS_MANAGEMENT',
  SavedCardManagement = 'SAVED_CARD_MANAGEMENT',
  SavedCardUsage = 'SAVED_CARD_USAGE',
  SelfDependentPublication = 'SELF_DEPENDENT_PUBLICATION',
  ServiceManagement = 'SERVICE_MANAGEMENT',
  UsersManagement = 'USERS_MANAGEMENT',
  VacancyManagementOfOthers = 'VACANCY_MANAGEMENT_OF_OTHERS',
  ViewingVacanciesAppliesOfOthers = 'VIEWING_VACANCIES_APPLIES_OF_OTHERS'
}

export interface EmployeeScopeChangeUserManagementEmployerEvent {
  readonly __typename?: 'EmployeeScopeChangeUserManagementEmployerEvent'
  readonly isEnabledAfter: Scalars['Boolean']
  readonly isEnabledBefore: Scalars['Boolean']
  readonly scope: EmployeeRoleBasedScope
}

export interface EmployeeSendChangeEmailInput {
  readonly newEmail: Scalars['String']
  readonly password: Scalars['String']
}

export interface EmployeeSetAsAdminInput {
  readonly employeeId: Scalars['ID']
  readonly mainPhone?: InputMaybe<Scalars['String']>
  readonly position?: InputMaybe<Scalars['String']>
}

export interface EmployeeStatusChanged {
  readonly __typename?: 'EmployeeStatusChanged'
  readonly at: Scalars['DateTime']
  readonly by: Employee
  readonly from: Maybe<EmployeeStatusEnum>
  readonly to: EmployeeStatusEnum
}

export enum EmployeeStatusEnum {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  Invited = 'INVITED'
}

export interface EmployeeSubscription {
  readonly __typename?: 'EmployeeSubscription'
  readonly id: Scalars['ID']
  readonly isActive: Scalars['Boolean']
  readonly options: Maybe<ReadonlyArray<EmployeeSubscriptionSubOptionUnion>>
  readonly type: EmployeeSubscriptionType
}

export interface EmployeeSubscriptionAnswerTemplate {
  readonly __typename?: 'EmployeeSubscriptionAnswerTemplate'
  readonly isSendForAllApplies: Scalars['Boolean']
  readonly text: Scalars['String']
}

export interface EmployeeSubscriptionAnswerTemplateInput {
  readonly isSendForAllApplies?: InputMaybe<Scalars['Boolean']>
  readonly text?: InputMaybe<Scalars['String']>
}

export interface EmployeeSubscriptionAnswerTemplateOutput {
  readonly __typename?: 'EmployeeSubscriptionAnswerTemplateOutput'
  readonly applyAutoReplyTemplate: EmployeeSubscriptionAnswerTemplate
  readonly isSuccessful: Scalars['Boolean']
}

export interface EmployeeSubscriptionIntRange {
  readonly __typename?: 'EmployeeSubscriptionIntRange'
  readonly from: Maybe<Scalars['Int']>
  readonly to: Maybe<Scalars['Int']>
}

export enum EmployeeSubscriptionSavedCvDbSearchContextEnum {
  Filters = 'FILTERS',
  History = 'HISTORY',
  Main = 'MAIN',
  None = 'NONE'
}

export enum EmployeeSubscriptionSavedCvDbSearchCvLanguageEnum {
  All = 'ALL',
  English = 'ENGLISH',
  Russian = 'RUSSIAN',
  Ukrainian = 'UKRAINIAN'
}

export enum EmployeeSubscriptionSavedCvDbSearchCvSortEnum {
  LastActivity = 'LAST_ACTIVITY',
  Score = 'SCORE',
  UpdateDate = 'UPDATE_DATE'
}

export enum EmployeeSubscriptionSavedCvDbSearchPeriodEnum {
  All = 'ALL',
  Month = 'MONTH',
  ThreeDays = 'THREE_DAYS',
  ThreeMonths = 'THREE_MONTHS',
  Today = 'TODAY',
  Week = 'WEEK',
  Year = 'YEAR'
}

export interface EmployeeSubscriptionSavedCvDbSearchRequest {
  readonly __typename?: 'EmployeeSubscriptionSavedCvDbSearchRequest'
  readonly age: Maybe<EmployeeSubscriptionIntRange>
  readonly branchIds: Maybe<ReadonlyArray<Scalars['ID']>>
  readonly city: Maybe<City>
  readonly count: Maybe<Scalars['Int']>
  readonly cvLanguage: Maybe<EmployeeSubscriptionSavedCvDbSearchCvLanguageEnum>
  readonly districtIds: Maybe<ReadonlyArray<Scalars['ID']>>
  readonly educationIds: Maybe<ReadonlyArray<Scalars['ID']>>
  readonly experienceIds: Maybe<ReadonlyArray<Scalars['ID']>>
  readonly hasMoveability: Maybe<Scalars['Boolean']>
  readonly hasOnlyMoveability: Maybe<Scalars['Boolean']>
  readonly hasPhoto: Maybe<Scalars['Boolean']>
  readonly isIncludeDisliked: Maybe<Scalars['Boolean']>
  readonly isInside: Maybe<Scalars['Boolean']>
  readonly isOnlyDisabled: Maybe<Scalars['Boolean']>
  readonly isOnlyFavorite: Maybe<Scalars['Boolean']>
  readonly isOnlyNew: Maybe<Scalars['Boolean']>
  readonly isOnlyStudents: Maybe<Scalars['Boolean']>
  readonly isOnlyViewed: Maybe<Scalars['Boolean']>
  readonly isOnlyWithCurrentNotebookNotes: Maybe<Scalars['Boolean']>
  readonly isOnlyWithOpenedContacts: Maybe<Scalars['Boolean']>
  readonly isOnlyWithOwnCar: Maybe<Scalars['Boolean']>
  readonly isReverse: Maybe<Scalars['Boolean']>
  readonly isShowCvWithoutSalary: Maybe<Scalars['Boolean']>
  readonly isSynonym: Maybe<Scalars['Boolean']>
  readonly isUkrainian: Maybe<Scalars['Boolean']>
  readonly keyWords: Maybe<Scalars['String']>
  readonly languages: Maybe<ReadonlyArray<Scalars['ID']>>
  readonly lastSort: Maybe<Scalars['String']>
  readonly page: Maybe<Scalars['Int']>
  readonly period: Maybe<EmployeeSubscriptionSavedCvDbSearchPeriodEnum>
  readonly profLevelIds: Maybe<ReadonlyArray<Scalars['ID']>>
  readonly resumeFillingTypeIds: Maybe<ReadonlyArray<Scalars['ID']>>
  readonly rubrics: Maybe<ReadonlyArray<Scalars['ID']>>
  readonly salary: Maybe<EmployeeSubscriptionIntRange>
  readonly scheduleIds: Maybe<ReadonlyArray<Scalars['ID']>>
  readonly searchContext: Maybe<EmployeeSubscriptionSavedCvDbSearchContextEnum>
  readonly searchType: Maybe<Scalars['String']>
  readonly sex: Maybe<Gender>
  readonly sort: Maybe<EmployeeSubscriptionSavedCvDbSearchCvSortEnum>
}

export interface EmployeeSubscriptionSavedCvDbSearchSubOption {
  readonly __typename?: 'EmployeeSubscriptionSavedCvDbSearchSubOption'
  readonly id: Scalars['ID']
  readonly isActive: Scalars['Boolean']
  readonly searchRequest: EmployeeSubscriptionSavedCvDbSearchRequest
}

export type EmployeeSubscriptionSubOptionUnion = EmployeeSubscriptionSavedCvDbSearchSubOption

export interface EmployeeSubscriptionType {
  readonly __typename?: 'EmployeeSubscriptionType'
  readonly id: Scalars['ID']
  readonly value: EmployeeSubscriptionTypeEnum
}

export enum EmployeeSubscriptionTypeEnum {
  AllVacanciesWeeklyStatistics = 'ALL_VACANCIES_WEEKLY_STATISTICS',
  Budni = 'BUDNI',
  CvDbRecommendedCandidatesBySavedSearches = 'CV_DB_RECOMMENDED_CANDIDATES_BY_SAVED_SEARCHES',
  CvDbRecommendedCandidatesByVacancies = 'CV_DB_RECOMMENDED_CANDIDATES_BY_VACANCIES',
  EventInvitations = 'EVENT_INVITATIONS',
  MyVacanciesAutoRepublish = 'MY_VACANCIES_AUTO_REPUBLISH',
  MyVacanciesDailyStatistics = 'MY_VACANCIES_DAILY_STATISTICS',
  MyVacanciesHotPeriodExpiration = 'MY_VACANCIES_HOT_PERIOD_EXPIRATION',
  MyVacanciesPublishPeriodExpiration = 'MY_VACANCIES_PUBLISH_PERIOD_EXPIRATION',
  MyVacanciesWeeklyStatistics = 'MY_VACANCIES_WEEKLY_STATISTICS',
  Promo = 'PROMO',
  ServiceVacancyPackageExpiration = 'SERVICE_VACANCY_PACKAGE_EXPIRATION'
}

export interface EmployeeSubscriptionsMutations {
  readonly __typename?: 'EmployeeSubscriptionsMutations'
  readonly update: EmployeeSubscriptionsUpdateOutput
  readonly updateAnswerTemplate: EmployeeSubscriptionAnswerTemplateOutput
}

export interface EmployeeSubscriptionsMutationsUpdateArgs {
  items: ReadonlyArray<EmployeeSubscriptionsUpdateItemInput>
}

export interface EmployeeSubscriptionsMutationsUpdateAnswerTemplateArgs {
  input: EmployeeSubscriptionAnswerTemplateInput
}

export interface EmployeeSubscriptionsQueries {
  readonly __typename?: 'EmployeeSubscriptionsQueries'
  readonly applyAutoReplyTemplate: EmployeeSubscriptionAnswerTemplate
  readonly glossary: ReadonlyArray<EmployeeSubscriptionType>
  readonly list: ReadonlyArray<EmployeeSubscription>
  readonly single: Maybe<EmployeeSubscription>
}

export interface EmployeeSubscriptionsQueriesApplyAutoReplyTemplateArgs {
  employeeId: Scalars['ID']
}

export interface EmployeeSubscriptionsQueriesSingleArgs {
  id: Scalars['ID']
}

export interface EmployeeSubscriptionsUpdateItemInput {
  readonly id: Scalars['ID']
  readonly isActive: Scalars['Boolean']
  readonly subOptions?: InputMaybe<ReadonlyArray<EmployeeSubscriptionsUpdateSubItemInput>>
}

export interface EmployeeSubscriptionsUpdateOutput {
  readonly __typename?: 'EmployeeSubscriptionsUpdateOutput'
  readonly isSuccessful: Scalars['Boolean']
}

export interface EmployeeSubscriptionsUpdateSubItemInput {
  readonly id: Scalars['ID']
  readonly isActive: Scalars['Boolean']
}

export interface EmployeeSummaryServiceLimit {
  readonly __typename?: 'EmployeeSummaryServiceLimit'
  readonly employee: Maybe<Employee>
  readonly has: Maybe<Scalars['Boolean']>
  readonly id: Maybe<Scalars['ID']>
  readonly month: Maybe<ServiceLimit>
  readonly permanent: Maybe<ServiceLimit>
  readonly remaining: Maybe<Scalars['Int']>
  readonly service: Maybe<SummaryService>
  readonly serviceType: Maybe<ServiceType>
  readonly total: Maybe<Scalars['Int']>
  readonly usedInLastMonth: Maybe<Scalars['Int']>
  readonly usesCount: Maybe<Scalars['Int']>
}

export interface EmployeesBlockInput {
  readonly employeeIds: ReadonlyArray<Scalars['ID']>
}

export interface EmployeesBlockOutput {
  readonly __typename?: 'EmployeesBlockOutput'
  readonly isSuccessful: Scalars['Boolean']
}

export interface EmployeesDeleteInput {
  readonly employeeIds: ReadonlyArray<Scalars['ID']>
  readonly responsibleEmployeeId: Scalars['ID']
}

export interface EmployeesDeleteOutput {
  readonly __typename?: 'EmployeesDeleteOutput'
  readonly isSuccessful: Scalars['Boolean']
}

export interface EmployeesFilterInput {
  readonly activeStatus?: InputMaybe<EmployeeActiveStatus>
  readonly statuses?: InputMaybe<ReadonlyArray<InputMaybe<EmployeeStatusEnum>>>
  readonly term?: InputMaybe<Scalars['String']>
}

export interface EmployeesMultiScopesUpdateOutput {
  readonly __typename?: 'EmployeesMultiScopesUpdateOutput'
  readonly isSuccessful: Scalars['Boolean']
}

export enum EmployeesOrderBy {
  AddedAtAsc = 'ADDED_AT_ASC',
  AddedAtDesc = 'ADDED_AT_DESC',
  EmployeeRoleAsc = 'EMPLOYEE_ROLE_ASC',
  FullNameAsc = 'FULL_NAME_ASC',
  FullNameDesc = 'FULL_NAME_DESC',
  LastSeenAtAsc = 'LAST_SEEN_AT_ASC',
  LastSeenAtDesc = 'LAST_SEEN_AT_DESC'
}

export interface EmployeesScopesMultiUpdateInput {
  readonly employees: ReadonlyArray<EmployeesScopesMultiUpdateItemInput>
}

export interface EmployeesScopesMultiUpdateItemInput {
  readonly id: Scalars['ID']
  readonly roleBasedScopeIds: ReadonlyArray<Scalars['ID']>
}

export interface EmployeesUnblockInput {
  readonly employeeIds: ReadonlyArray<Scalars['ID']>
}

export interface EmployeesUnblockOutput {
  readonly __typename?: 'EmployeesUnblockOutput'
  readonly isSuccessful: Scalars['Boolean']
}

export interface EmployerAchievement {
  readonly isDone: Scalars['Boolean']
  readonly type: EmployerAchievementType
}

export enum EmployerAchievementType {
  OnBoardingApplicationsViewed = 'ON_BOARDING_APPLICATIONS_VIEWED',
  OnBoardingFirstVacancyPublished = 'ON_BOARDING_FIRST_VACANCY_PUBLISHED',
  OnBoardingOpenedContactOrVacancyOffered = 'ON_BOARDING_OPENED_CONTACT_OR_VACANCY_OFFERED',
  OnBoardingPhoneNumberConfirmed = 'ON_BOARDING_PHONE_NUMBER_CONFIRMED',
  OnBoardingVacancyPublishedWithFilledAddressAndSalary = 'ON_BOARDING_VACANCY_PUBLISHED_WITH_FILLED_ADDRESS_AND_SALARY'
}

export interface EmployerEventLogConnection {
  readonly __typename?: 'EmployerEventLogConnection'
  readonly edges: Maybe<ReadonlyArray<Maybe<EmployersEventUnionEdge>>>
  readonly items: Maybe<ReadonlyArray<EmployerEventUnion>>
  readonly pageInfo: PageInfo
  readonly totalCount: Maybe<Scalars['Int']>
}

export interface EmployerEventPerformer {
  readonly __typename?: 'EmployerEventPerformer'
  readonly employeeSnapshot: EmployerEventSnapshot
}

export interface EmployerEventSnapshot {
  readonly __typename?: 'EmployerEventSnapshot'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export enum EmployerEventTypeEnum {
  CvDb = 'CV_DB',
  Service = 'SERVICE',
  User = 'USER',
  Vacancy = 'VACANCY'
}

export type EmployerEventUnion =
  | ActivatedOrderByTurbotaServiceEmployerEvent
  | ActivatedServiceEmployerEvent
  | AddedNewUserManagementEmployerEvent
  | ApprovedProlongationRequestVacancyEmployerEvent
  | ApprovedReviewVacancyEmployerEvent
  | AutoProlongationDisabledVacancyEmployerEvent
  | AutoProlongationEnabledVacancyEmployerEvent
  | AutoProlongedVacancyEmployerEvent
  | BlockedUserManagementEmployerEvent
  | ChangedFullNameUserManagementEmployerEvent
  | ChangedScopesUserManagementEmployerEvent
  | ClosedVacancyEmployerEvent
  | CompanyFreeServiceAddedEvent
  | CompanySavedCardAddedEvent
  | CompanySavedCardRemovedEvent
  | CreatedDraftVacancyEmployerEvent
  | CreatedOrderServiceEmployerEvent
  | DeletedUserManagementEmployerEvent
  | DeletedVacancyEmployerEvent
  | EditedVacancyEmployerEvent
  | EndedHotPeriodVacancyEmployerEvent
  | EndedServiceEmployerEvent
  | MadeHotVacancyEmployerEvent
  | OfferedVacancyCvDbEmployerEvent
  | OpenedContactsCvDbEmployerEvent
  | OrderFreemiumServicesAccruedServiceEmployerEvent
  | OrderServicesAccruedServiceEmployerEvent
  | OwnerChangedVacancyEmployerEvent
  | PaymentReceivedServiceEmployerEvent
  | ProlongationRequestVacancyEmployerEvent
  | PublishedVacancyEmployerEvent
  | RePublishedVacancyEmployerEvent
  | RejectedByModeratorVacancyEmployerEvent
  | RejectedProlongationRequestVacancyEmployerEvent
  | RejectedReviewVacancyEmployerEvent
  | SentToReviewVacancyEmployerEvent
  | UsedHotServiceEmployerEvent
  | UsedVacancyServiceEmployerEvent
  | WalletTransactionEvent

export interface EmployerEventsLogFilterInput {
  readonly dateRange?: InputMaybe<DateRangeInput>
  readonly employeeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
  readonly types?: InputMaybe<ReadonlyArray<EmployerEventTypeEnum>>
}

export interface EmployerOnBoardingCheckListOutput {
  readonly __typename?: 'EmployerOnBoardingCheckListOutput'
  readonly applicationsViewed: ApplicationsViewedEmployerAchievement
  readonly companyPhoneNumberConfirmed: Maybe<PhoneNumberConfirmedEmployerAchievement>
  readonly firstVacancyPublished: VacancyPublishedEmployerAchievement
  readonly isOnBoardingCheckListRead: Scalars['Boolean']
  readonly openedContactOrVacancyOffered: OpenedContactOrVacancyOfferedEmployerAchievement
  readonly phoneNumberConfirmed: PhoneNumberConfirmedEmployerAchievement
  readonly vacancyPublishedWithFilledAddressAndSalary: VacancyPublishedWithFilledAddressAndSalaryEmployerAchievement
}

export interface EmployerRegistration {
  readonly __typename?: 'EmployerRegistration'
  readonly createDuplicateProfile: EmployerRegistrationResponse
  readonly createProfile: EmployerRegistrationResponse
  readonly validate: UserRegistrationValidationResponse
}

export interface EmployerRegistrationCreateDuplicateProfileArgs {
  input: CreateEmployerDuplicateProfileInput
}

export interface EmployerRegistrationCreateProfileArgs {
  input: CreateEmployerProfileInput
}

export interface EmployerRegistrationValidateArgs {
  input: EmployerRegistrationValidationInput
}

export interface EmployerRegistrationResponse {
  readonly __typename?: 'EmployerRegistrationResponse'
  readonly authLink: Scalars['String']
  readonly employer: Maybe<Employee>
  readonly errors: ReadonlyArray<UserRegistrationError>
  readonly isSuccess: Scalars['Boolean']
}

export interface EmployerRegistrationValidationInput {
  readonly login: Scalars['String']
}

export interface EmployerServiceLimitRequestInput {
  readonly servicesLimitRequest?: InputMaybe<ReadonlyArray<ServiceLimitRequestInput>>
}

export interface EmployerSummaryServiceLimitInput {
  readonly employeeId: Scalars['ID']
  readonly notifyUser?: InputMaybe<Scalars['Boolean']>
  readonly servicesLimit?: InputMaybe<ReadonlyArray<ServiceLimitInput>>
}

export interface EmployerWalletUser {
  readonly __typename?: 'EmployerWalletUser'
  readonly employer: User
}

export interface EmployersEventUnionEdge {
  readonly __typename?: 'EmployersEventUnionEdge'
  readonly cursor: Scalars['String']
  readonly node: EmployerEventUnion
}

export interface EndedHotPeriodVacancyEmployerEvent {
  readonly __typename?: 'EndedHotPeriodVacancyEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly performer: SystemEventPerformer
  readonly vacancyCity: City
  readonly vacancyId: Scalars['ID']
  readonly vacancyName: Scalars['String']
}

export interface EndedServiceEmployerEvent {
  readonly __typename?: 'EndedServiceEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly performer: SystemEventPerformer
  readonly service: ServiceEmployerEventSnapshotUnion
}

export interface Error {
  readonly reason: Scalars['String']
}

export interface EventInterface {
  readonly addDate: Scalars['DateTimeOffset']
  readonly assignment: Maybe<Assignment>
  readonly company: Maybe<CrmCompany>
  readonly daysLeft: Maybe<Scalars['Int']>
  readonly employee: Maybe<CrmEmployee>
  readonly eventType: EventTypeEnum
  readonly expirationDate: Maybe<Scalars['DateTimeOffset']>
  readonly id: Scalars['ID']
  readonly isArchieved: Scalars['Boolean']
  readonly isRead: Scalars['Boolean']
  readonly lifetimeDays: Scalars['Int']
  readonly state: EventStateEnum
  readonly topics: ReadonlyArray<Topic>
}

export enum EventStateEnum {
  All = 'ALL',
  Done = 'DONE',
  Planned = 'PLANNED'
}

export interface EventTopicCompleteInput {
  readonly eventId?: InputMaybe<Scalars['ID']>
  readonly topicIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
}

export enum EventTypeEnum {
  CompanyBuffer = 'COMPANY_BUFFER',
  CompanyOnWorkRegistered = 'COMPANY_ON_WORK_REGISTERED',
  CompanyRegistered = 'COMPANY_REGISTERED',
  InvoiceExpired = 'INVOICE_EXPIRED',
  InvoicePaid = 'INVOICE_PAID',
  InvoicePayment_1C = 'INVOICE_PAYMENT_1_C',
  InvoiceRequested = 'INVOICE_REQUESTED',
  MetanotebookAssigned = 'METANOTEBOOK_ASSIGNED',
  None = 'NONE',
  PersonalDiscount = 'PERSONAL_DISCOUNT',
  ProfileRegistered = 'PROFILE_REGISTERED',
  ServiceIsExpiring = 'SERVICE_IS_EXPIRING',
  Turbota = 'TURBOTA',
  UncompletedPayment = 'UNCOMPLETED_PAYMENT',
  UserLeftBasket = 'USER_LEFT_BASKET',
  VacancyBanned = 'VACANCY_BANNED',
  VacancyOnWorkWillExpireIn_5Days = 'VACANCY_ON_WORK_WILL_EXPIRE_IN_5_DAYS'
}

export interface Experience {
  readonly __typename?: 'Experience'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export interface ExperienceBasicQuestion {
  readonly __typename?: 'ExperienceBasicQuestion'
  readonly defaultText: Scalars['String']
  readonly experiences: ReadonlyArray<BasicQuestionItem>
}

export type ExternalCourse = PrometheusExternalCourse

export interface ExternalUrlPaymentMethod {
  readonly __typename?: 'ExternalUrlPaymentMethod'
  readonly generate: GenerateExternalPaymentUrlOutput
}

export interface ExternalUrlPaymentMethodGenerateArgs {
  errorUrl: InputMaybe<Scalars['String']>
  successUrl: InputMaybe<Scalars['String']>
}

export enum ExternalWalletEnum {
  ApplePay = 'APPLE_PAY',
  GooglePay = 'GOOGLE_PAY'
}

export interface Facet {
  readonly __typename?: 'Facet'
  readonly count: Scalars['Int']
  readonly item: ApplyFacetUnion
}

export interface FavoriteVacanciesRemindedDetails {
  readonly __typename?: 'FavoriteVacanciesRemindedDetails'
  readonly detailType: SeekerNotificationDetailTypeEnum
}

export interface FeedbackInput {
  readonly cameFromUrl?: InputMaybe<Scalars['String']>
  readonly description: Scalars['String']
  readonly isCookiesEnabled: Scalars['Boolean']
  readonly reporter: FeedbackReporterInput
  readonly type?: InputMaybe<FeedbackTypeEnum>
}

export interface FeedbackMutations {
  readonly __typename?: 'FeedbackMutations'
  readonly give: FeedbackOutput
  readonly reportResume: ResumeReportOutput
  readonly reportVacancy: VacancyReportOutput
}

export interface FeedbackMutationsGiveArgs {
  input: FeedbackInput
}

export interface FeedbackMutationsReportResumeArgs {
  input: ResumeReportInput
}

export interface FeedbackMutationsReportVacancyArgs {
  input: VacancyReportInput
}

export interface FeedbackOutput {
  readonly __typename?: 'FeedbackOutput'
  readonly isSuccessful: Scalars['Boolean']
  readonly key: Scalars['String']
}

export interface FeedbackQueries {
  readonly __typename?: 'FeedbackQueries'
  readonly ping: Scalars['String']
}

export interface FeedbackReporterInput {
  readonly email: Scalars['String']
  readonly name: Scalars['String']
  readonly phone?: InputMaybe<Scalars['String']>
  readonly type?: InputMaybe<FeedbackReporterTypeEnum>
}

export enum FeedbackReporterTypeEnum {
  Employer = 'EMPLOYER',
  Seeker = 'SEEKER',
  System = 'SYSTEM'
}

export enum FeedbackTypeEnum {
  DuplicateRegistration = 'DUPLICATE_REGISTRATION',
  Form = 'FORM',
  MissingCities = 'MISSING_CITIES'
}

export interface FileAttach {
  readonly __typename?: 'FileAttach'
  readonly addDate: Scalars['DateTimeOffset']
  readonly assignmentId: Maybe<Scalars['ID']>
  readonly fileName: Scalars['String']
  readonly id: Maybe<Scalars['ID']>
  readonly managerId: Maybe<Scalars['ID']>
  readonly url: Scalars['String']
}

export interface FilterAccountsInput {
  readonly hasActs?: InputMaybe<Scalars['Boolean']>
  readonly notebookId?: InputMaybe<Scalars['ID']>
  readonly quarters?: InputMaybe<ReadonlyArray<InputMaybe<CrmQuarterInput>>>
  readonly query?: InputMaybe<Scalars['String']>
  readonly states?: InputMaybe<ReadonlyArray<InputMaybe<CrmAccountState>>>
}

export interface FilterActionsInput {
  readonly assigned?: InputMaybe<Scalars['String']>
  readonly assignedManagerIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
  readonly assigners?: InputMaybe<ReadonlyArray<InputMaybe<CrmActionCreatorEnum>>>
  readonly dateFrom?: InputMaybe<Scalars['Date']>
  readonly dateTimeFrom?: InputMaybe<Scalars['DateTime']>
  readonly dateTimeTo?: InputMaybe<Scalars['DateTime']>
  readonly dateTo?: InputMaybe<Scalars['Date']>
  readonly isShowGamificationActionOnly?: InputMaybe<Scalars['Boolean']>
  readonly metaNotebookId?: InputMaybe<Scalars['ID']>
  readonly notebookId?: InputMaybe<Scalars['ID']>
  readonly query?: InputMaybe<Scalars['String']>
  readonly responsible?: InputMaybe<Scalars['String']>
  readonly responsibleManagerIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
  readonly states?: InputMaybe<ReadonlyArray<InputMaybe<CrmActionState>>>
  readonly stmManagerId?: InputMaybe<Scalars['ID']>
  readonly types?: InputMaybe<ReadonlyArray<InputMaybe<CrmActionTypeEnum>>>
  readonly users?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
}

export interface FilterAssignmentsInput {
  readonly companyIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  readonly contactTypes?: InputMaybe<ReadonlyArray<InputMaybe<TaskContactTypeEnum>>>
  readonly ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  readonly initiatorIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  readonly managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  readonly planDate?: InputMaybe<DateIntervalInput>
  readonly query?: InputMaybe<Scalars['String']>
  readonly status?: InputMaybe<TaskStatusEnum>
}

export interface FilterCompaniesInput {
  readonly ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  readonly query?: InputMaybe<Scalars['String']>
}

export interface FilterCompanyOrdersRequest {
  readonly onlyRealizedDeals?: InputMaybe<Scalars['Boolean']>
  readonly onlyWithNonZeroSum?: InputMaybe<Scalars['Boolean']>
  readonly paymentStates?: InputMaybe<ReadonlyArray<OrderPaymentState>>
  readonly types?: InputMaybe<ReadonlyArray<InputMaybe<OrderType>>>
}

export interface FilterGiftRequestInput {
  readonly deliveryChannel?: InputMaybe<ReadonlyArray<InputMaybe<GiftDeliveryChannelEnum>>>
  readonly giftIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Int']>>>
  readonly managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  readonly notebookIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  readonly novaPoshtaStateIds?: InputMaybe<ReadonlyArray<InputMaybe<NovaPoshtaStateEnum>>>
  readonly occasionIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Int']>>>
  readonly period?: InputMaybe<GiftPeriodEnum>
  readonly sortBy?: InputMaybe<GiftSortingEnum>
  readonly stateIds?: InputMaybe<ReadonlyArray<InputMaybe<GiftRequestStateEnum>>>
  readonly userCardIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Int']>>>
}

export interface FilterHelperCompaniesInput {
  readonly activityFilterStateIds?: InputMaybe<ReadonlyArray<HelperActivityFilterStateEnum>>
  readonly managerIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
  readonly notebookIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
  readonly sort?: InputMaybe<HelperCompanySortEnum>
}

export interface FilterMarketingServicesInput {
  readonly dateFrom?: InputMaybe<Scalars['DateTime']>
  readonly dateTo?: InputMaybe<Scalars['DateTime']>
  readonly firstPaymentDateFrom?: InputMaybe<Scalars['DateTime']>
  readonly firstPaymentDateTo?: InputMaybe<Scalars['DateTime']>
  readonly managerId?: InputMaybe<Scalars['ID']>
  readonly payStatusIds?: InputMaybe<ReadonlyArray<InputMaybe<CrmPayState>>>
  readonly positionStates?: InputMaybe<ReadonlyArray<CrmPositionState>>
  readonly query?: InputMaybe<Scalars['String']>
  readonly serviceId?: InputMaybe<Scalars['ID']>
}

export interface FilterMyServicesInput {
  readonly states?: InputMaybe<ReadonlyArray<InputMaybe<ServiceState>>>
  readonly types?: InputMaybe<ReadonlyArray<InputMaybe<ServiceType>>>
}

export interface FilterNovaPoshtaRegisterListInput {
  readonly query?: InputMaybe<Scalars['String']>
}

export interface FilterTeamActionsInput {
  readonly assignedManagerId?: InputMaybe<Scalars['ID']>
  readonly assignedManagerIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
  readonly dateFrom?: InputMaybe<Scalars['DateTime']>
  readonly dateTo?: InputMaybe<Scalars['DateTime']>
  readonly isPinned?: InputMaybe<Scalars['Boolean']>
  readonly isShowGamificationActionOnly?: InputMaybe<Scalars['Boolean']>
  readonly managerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Int']>>>
  readonly notebookId?: InputMaybe<Scalars['ID']>
  readonly states?: InputMaybe<ReadonlyArray<CrmActionState>>
  readonly stmManagerId?: InputMaybe<Scalars['ID']>
  readonly types?: InputMaybe<ReadonlyArray<CrmActionTypeEnum>>
}

export interface FilteredCvListInput {
  readonly assigned?: InputMaybe<ReadonlyArray<Scalars['String']>>
  readonly dates?: InputMaybe<CrmDateInterval>
  readonly states?: InputMaybe<ReadonlyArray<ParsedCvStateEnum>>
  readonly tags?: InputMaybe<ReadonlyArray<Scalars['String']>>
  readonly term?: InputMaybe<Scalars['String']>
}

export interface ForbiddenToMutateUsersError {
  readonly __typename?: 'ForbiddenToMutateUsersError'
  readonly message: Scalars['String']
}

export interface GamificationDates {
  readonly __typename?: 'GamificationDates'
  readonly end: Scalars['DateTime']
  readonly start: Scalars['DateTime']
}

export interface GamificationWave {
  readonly __typename?: 'GamificationWave'
  readonly endDate: Scalars['DateTime']
  readonly giftCount: Scalars['Int']
  readonly id: Scalars['ID']
  readonly isActive: Scalars['Boolean']
  readonly isGiftSent: Scalars['Boolean']
  readonly multiUsers: ReadonlyArray<Scalars['ID']>
  readonly occasionWaveName: Scalars['String']
  readonly startDate: Scalars['DateTime']
  readonly wave: Scalars['Int']
}

export interface GamificationWaveList {
  readonly __typename?: 'GamificationWaveList'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly occasion: Scalars['Int']
}

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER'
}

export interface GenerateExternalPaymentUrlByInvoiceInput {
  readonly errorUrl?: InputMaybe<Scalars['String']>
  readonly id: Scalars['ID']
  readonly successUrl?: InputMaybe<Scalars['String']>
  readonly year: Scalars['Int']
}

export interface GenerateExternalPaymentUrlByInvoiceOutput {
  readonly __typename?: 'GenerateExternalPaymentUrlByInvoiceOutput'
  readonly url: Maybe<Scalars['String']>
}

export interface GenerateExternalPaymentUrlInput {
  readonly errorUrl?: InputMaybe<Scalars['String']>
  readonly orderId: Scalars['ID']
  readonly successUrl?: InputMaybe<Scalars['String']>
}

export interface GenerateExternalPaymentUrlOutput {
  readonly __typename?: 'GenerateExternalPaymentUrlOutput'
  readonly url: Maybe<Scalars['String']>
}

export type GetCurrentEmployerErrorUnion = InvalidError | NotFoundError | UserIsNotAuthenticatedError

export interface GetCurrentEmployerOutput {
  readonly __typename?: 'GetCurrentEmployerOutput'
  readonly employer: Maybe<Employee>
  readonly errors: ReadonlyArray<GetCurrentEmployerErrorUnion>
  readonly isSuccess: Scalars['Boolean']
}

export interface GetSeekerDislikedVacanciesTotalCountOutput {
  readonly __typename?: 'GetSeekerDislikedVacanciesTotalCountOutput'
  readonly value: Scalars['UInt']
}

export interface GetSeekerFavoriteVacanciesTotalCountOutput {
  readonly __typename?: 'GetSeekerFavoriteVacanciesTotalCountOutput'
  readonly value: Scalars['UInt']
}

export interface GetVacancyByIdResponse {
  readonly __typename?: 'GetVacancyByIdResponse'
  readonly error: Maybe<GetVacancyError>
  readonly isSuccess: Scalars['Boolean']
  readonly vacancy: Maybe<Vacancy>
}

export type GetVacancyError = HasNoAccessToRequestedFieldsError | NotAvailableError | NotFoundError

export interface GetVacancySearchSuggestsInput {
  readonly cityId?: InputMaybe<Scalars['Int']>
  readonly query: Scalars['String']
  readonly size?: InputMaybe<Scalars['Int']>
  readonly suggestTypes: ReadonlyArray<VacancySearchSuggestTypeEnum>
}

export interface GetVacancySearchSuggestsOutput {
  readonly __typename?: 'GetVacancySearchSuggestsOutput'
  readonly list: ReadonlyArray<VacancySearchSuggestList>
}

export interface Gift {
  readonly __typename?: 'Gift'
  readonly giftType: Scalars['ID']
  readonly id: Scalars['ID']
  readonly isDeleted: Scalars['Boolean']
  readonly name: Scalars['String']
  readonly typeName: Scalars['String']
}

export enum GiftCompanyWaveCounterEnum {
  GiftRequestCount = 'GIFT_REQUEST_COUNT',
  ManagerWaveLimit = 'MANAGER_WAVE_LIMIT',
  NotebookNeedToGiveLimit = 'NOTEBOOK_NEED_TO_GIVE_LIMIT'
}

export interface GiftCompanyWaveLimit {
  readonly __typename?: 'GiftCompanyWaveLimit'
  readonly counter: GiftCompanyWaveCounterEnum
  readonly counterValue: Scalars['Int']
  readonly gift: Gift
  readonly occasionWave: GiftOccasion
}

export enum GiftDeliveryChannelEnum {
  Byself = 'BYSELF',
  NovaPoshta = 'NOVA_POSHTA'
}

export interface GiftDeliverySlot {
  readonly __typename?: 'GiftDeliverySlot'
  readonly height: Scalars['Int']
  readonly id: Scalars['ID']
  readonly length: Scalars['Int']
  readonly weight: Scalars['Decimal']
  readonly width: Scalars['Int']
}

export interface GiftDeliverySlotInput {
  readonly height: Scalars['Int']
  readonly id?: InputMaybe<Scalars['ID']>
  readonly length: Scalars['Int']
  readonly modification: ModificationTypeEnum
  readonly weight: Scalars['Decimal']
  readonly width: Scalars['Int']
}

export enum GiftDeliveryTypeEnum {
  Address = 'ADDRESS',
  Warehouse = 'WAREHOUSE'
}

export interface GiftDetailInput {
  readonly giftId: Scalars['ID']
  readonly requestedAmount: Scalars['Int']
}

export interface GiftLimit {
  readonly __typename?: 'GiftLimit'
  readonly allowedCount: Scalars['Int']
  readonly giftType: Scalars['Int']
  readonly giftTypeName: Scalars['String']
  readonly id: Scalars['ID']
  readonly isAllowedPaymentRange: Maybe<Scalars['Boolean']>
  readonly name: Scalars['String']
  readonly occasion: Scalars['Int']
  readonly occasionName: Scalars['String']
  readonly occasionWave: Maybe<Scalars['Int']>
  readonly occasionWaveName: Maybe<Scalars['String']>
}

export interface GiftLimitsTransferHistoryByTeamLeadRequestInput {
  readonly giftIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
  readonly managerIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
}

export interface GiftOccasion {
  readonly __typename?: 'GiftOccasion'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export enum GiftPeriodEnum {
  All = 'ALL',
  HalfOfYear = 'HALF_OF_YEAR',
  Week = 'WEEK',
  Year = 'YEAR'
}

export interface GiftRequest {
  readonly __typename?: 'GiftRequest'
  readonly addDate: Scalars['DateTime']
  readonly comment: Maybe<Scalars['String']>
  readonly company: CrmCompany
  readonly deliveryChannel: GiftDeliveryChannelEnum
  readonly deliveryOption: Maybe<GiftRequestDeliveryOptions>
  readonly deliverySlots: ReadonlyArray<GiftDeliverySlot>
  readonly details: ReadonlyArray<GiftRequestDetail>
  readonly id: Scalars['ID']
  readonly manager: CrmManager
  readonly occasion: Scalars['ID']
  readonly occasionName: Scalars['String']
  readonly state: GiftRequestStateEnum
  readonly userCard: Maybe<CrmUserCard>
}

export interface GiftRequestConnection {
  readonly __typename?: 'GiftRequestConnection'
  readonly edges: Maybe<ReadonlyArray<Maybe<GiftRequestEdge>>>
  readonly items: Maybe<ReadonlyArray<GiftRequest>>
  readonly pageInfo: PageInfo
  readonly totalCount: Maybe<Scalars['Int']>
}

export interface GiftRequestDeliveryOptions {
  readonly __typename?: 'GiftRequestDeliveryOptions'
  readonly addressRef: Scalars['String']
  readonly contactPersonRef: Scalars['String']
  readonly cost: Scalars['Int']
  readonly counterpartyRef: Scalars['String']
  readonly deliveryCost: Maybe<Scalars['Int']>
  readonly deliveryDate: Maybe<Scalars['DateTime']>
  readonly deliveryType: GiftDeliveryTypeEnum
  readonly description: Maybe<Scalars['String']>
  readonly documentNumber: Maybe<Scalars['String']>
  readonly documentRef: Maybe<Scalars['String']>
  readonly edrpou: Maybe<Scalars['String']>
  readonly isExactDeliveryDate: Maybe<Scalars['Boolean']>
  readonly isNeedToAttachDocuments: Maybe<Scalars['Boolean']>
  readonly novaPoshtaComment: Maybe<Scalars['String']>
  readonly novaPoshtaState: NovaPoshtaStateEnum
  readonly recipientCityName: Scalars['String']
  readonly recipientFirstName: Scalars['String']
  readonly recipientFlat: Maybe<Scalars['String']>
  readonly recipientHouse: Maybe<Scalars['String']>
  readonly recipientLastName: Scalars['String']
  readonly recipientPhone: Scalars['String']
  readonly recipientStreetName: Scalars['String']
  readonly recipientType: NovaPosthaCounterpartyTypesGraphEnum
  readonly senderFullName: Scalars['String']
  readonly senderPhone: Scalars['String']
  readonly senderRef: Scalars['String']
  readonly settlementRef: Scalars['String']
}

export interface GiftRequestDetail {
  readonly __typename?: 'GiftRequestDetail'
  readonly giftName: Scalars['String']
  readonly giftType: Scalars['ID']
  readonly quantity: Scalars['Int']
}

export interface GiftRequestEdge {
  readonly __typename?: 'GiftRequestEdge'
  readonly cursor: Scalars['String']
  readonly node: GiftRequest
}

export interface GiftRequestOccasion {
  readonly __typename?: 'GiftRequestOccasion'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export enum GiftRequestStateEnum {
  DeclinedByStm = 'DECLINED_BY_STM',
  Deleted = 'DELETED',
  Delivered = 'DELIVERED',
  Marketing = 'MARKETING',
  New = 'NEW',
  NovaPoshta = 'NOVA_POSHTA',
  WaitingApprove = 'WAITING_APPROVE'
}

export enum GiftSortingEnum {
  RequestDateAsc = 'REQUEST_DATE_ASC',
  RequestDateDesc = 'REQUEST_DATE_DESC',
  RequestStateAsc = 'REQUEST_STATE_ASC',
  RequestStateDesc = 'REQUEST_STATE_DESC'
}

export interface GiftTeamLimit {
  readonly __typename?: 'GiftTeamLimit'
  readonly gift: Gift
  readonly limitCount: Scalars['Int']
  readonly limitTransferedCount: Scalars['Int']
  readonly occasion: GiftOccasion
  readonly occasionWave: Maybe<GiftOccasion>
  readonly startDate: Maybe<Scalars['DateTime']>
}

export interface GiftTeamLimitByManager {
  readonly __typename?: 'GiftTeamLimitByManager'
  readonly gift: Gift
  readonly gifts: ReadonlyArray<GiftTeamLimitByManagerDetail>
  readonly limitCount: Scalars['Int']
  readonly limitTransferedCount: Scalars['Int']
  readonly manager: CrmManager
  readonly occasion: GiftOccasion
  readonly occasionWave: Maybe<GiftOccasion>
  readonly startDate: Maybe<Scalars['DateTime']>
}

export interface GiftTeamLimitByManagerConnection {
  readonly __typename?: 'GiftTeamLimitByManagerConnection'
  readonly edges: Maybe<ReadonlyArray<Maybe<GiftTeamLimitByManagerEdge>>>
  readonly items: Maybe<ReadonlyArray<GiftTeamLimitByManager>>
  readonly pageInfo: PageInfo
  readonly totalCount: Maybe<Scalars['Int']>
}

export interface GiftTeamLimitByManagerDetail {
  readonly __typename?: 'GiftTeamLimitByManagerDetail'
  readonly id: Scalars['ID']
  readonly limitCount: Scalars['Int']
  readonly limitTransferedCount: Scalars['Int']
  readonly name: Scalars['String']
}

export interface GiftTeamLimitByManagerEdge {
  readonly __typename?: 'GiftTeamLimitByManagerEdge'
  readonly cursor: Scalars['String']
  readonly node: GiftTeamLimitByManager
}

export interface GiftTeamLimitTransferHistory {
  readonly __typename?: 'GiftTeamLimitTransferHistory'
  readonly addDate: Maybe<Scalars['DateTime']>
  readonly comment: Maybe<Scalars['String']>
  readonly gift: Gift
  readonly limitCount: Scalars['Int']
  readonly limitTransferedCount: Scalars['Int']
  readonly manager: CrmManager
  readonly occasion: GiftOccasion
  readonly occasionWave: Maybe<GiftOccasion>
}

export interface GiftTeamLimitTransferHistoryConnection {
  readonly __typename?: 'GiftTeamLimitTransferHistoryConnection'
  readonly edges: Maybe<ReadonlyArray<Maybe<GiftTeamLimitTransferHistoryEdge>>>
  readonly items: Maybe<ReadonlyArray<GiftTeamLimitTransferHistory>>
  readonly pageInfo: PageInfo
  readonly totalCount: Maybe<Scalars['Int']>
}

export interface GiftTeamLimitTransferHistoryEdge {
  readonly __typename?: 'GiftTeamLimitTransferHistoryEdge'
  readonly cursor: Scalars['String']
  readonly node: GiftTeamLimitTransferHistory
}

export enum GiftTypeEnum {
  Limited = 'LIMITED',
  Regular = 'REGULAR',
  Souvenir = 'SOUVENIR'
}

export interface GiftWaveLimit {
  readonly __typename?: 'GiftWaveLimit'
  readonly gift: Scalars['ID']
  readonly giftName: Scalars['String']
  readonly limitCount: Scalars['Int']
  readonly occasion: GiftOccasion
  readonly occasionWave: GiftOccasion
}

export interface GiftsFilters {
  readonly __typename?: 'GiftsFilters'
  readonly deliveryChannel: Maybe<ReadonlyArray<Maybe<GiftDeliveryChannelEnum>>>
  readonly giftIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>
  readonly managerIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>
  readonly notebookId: Maybe<Scalars['ID']>
  readonly novaPoshtaState: Maybe<ReadonlyArray<Maybe<NovaPoshtaStateEnum>>>
  readonly occasions: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>
  readonly period: Maybe<GiftPeriodEnum>
  readonly stateIds: Maybe<ReadonlyArray<Maybe<GiftRequestStateEnum>>>
}

export interface GiftsMutations {
  readonly __typename?: 'GiftsMutations'
  readonly addGift: MovingGiftLimitsOutput
  readonly addMarketingGiftRequest: MovingGiftLimitsOutput
  readonly addOccasion: MovingGiftLimitsOutput
  readonly addWave: MovingGiftLimitsOutput
  readonly finishOccasion: MovingGiftLimitsOutput
  readonly finishWave: MovingGiftLimitsOutput
  readonly setDeleted: MovingGiftLimitsOutput
  readonly setRange: MovingGiftLimitsOutput
  readonly updateGiftLimitByStm: MovingGiftLimitsOutput
}

export interface GiftsMutationsAddGiftArgs {
  input: AddRegularGiftInput
}

export interface GiftsMutationsAddMarketingGiftRequestArgs {
  input: AddMarketingGiftRequestInput
}

export interface GiftsMutationsAddOccasionArgs {
  input: AddGiftOccasionInput
}

export interface GiftsMutationsAddWaveArgs {
  input: AddGiftWaveInput
}

export interface GiftsMutationsFinishOccasionArgs {
  id: Scalars['ID']
}

export interface GiftsMutationsFinishWaveArgs {
  waveId: Scalars['ID']
}

export interface GiftsMutationsSetDeletedArgs {
  giftId: Scalars['ID']
}

export interface GiftsMutationsSetRangeArgs {
  input: SetGiftRangeInput
}

export interface GiftsMutationsUpdateGiftLimitByStmArgs {
  input: EditGiftLimitInput
}

export interface GiveAnswerInput {
  readonly options: ReadonlyArray<InputMaybe<Scalars['String']>>
  readonly questionId: Scalars['ID']
}

export interface GroupTag {
  readonly __typename?: 'GroupTag'
  readonly childTags: Maybe<ReadonlyArray<Maybe<GroupTag>>>
  readonly childTagsProf: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>
  readonly clusterId: Maybe<Scalars['Int']>
  readonly id: Maybe<Scalars['ID']>
  readonly isProf: Maybe<Scalars['Boolean']>
  readonly name: Maybe<Scalars['String']>
  readonly parentTags: Maybe<ReadonlyArray<Maybe<GroupTag>>>
  readonly parentTagsProf: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>
  readonly synonymIds: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>
  readonly synonymNames: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>
}

export interface HasNoAccessToRequestedFieldsError extends Error {
  readonly __typename?: 'HasNoAccessToRequestedFieldsError'
  readonly reason: Scalars['String']
}

export interface HasNoScopesForActionError extends Error {
  readonly __typename?: 'HasNoScopesForActionError'
  readonly reason: Scalars['String']
}

export enum HelperActivityFilterStateEnum {
  ActiveThisWeek = 'ACTIVE_THIS_WEEK',
  NeverActive = 'NEVER_ACTIVE',
  WasActive = 'WAS_ACTIVE'
}

export enum HelperActivityStateEnum {
  Active = 'ACTIVE',
  NotActive = 'NOT_ACTIVE',
  NotUsed = 'NOT_USED'
}

export interface HelperCompany {
  readonly __typename?: 'HelperCompany'
  readonly activityState: HelperActivityStateEnum
  readonly details: ReadonlyArray<HelperCompanyDetail>
  readonly id: Scalars['ID']
  readonly isActive: Scalars['Boolean']
  readonly isRegistered: Scalars['Boolean']
  readonly managerName: Scalars['String']
  readonly name: Scalars['String']
}

export interface HelperCompanyConnection {
  readonly __typename?: 'HelperCompanyConnection'
  readonly activeCompaniesCount: Scalars['Int']
  readonly attractedCompaniesCount: Scalars['Int']
  readonly edges: Maybe<ReadonlyArray<Maybe<HelperCompanyEdge>>>
  readonly items: Maybe<ReadonlyArray<Maybe<HelperCompany>>>
  readonly notAttractedCompaniesCount: Scalars['Int']
  readonly pageInfo: PageInfo
  readonly totalCount: Maybe<Scalars['Int']>
}

export interface HelperCompanyDetail {
  readonly __typename?: 'HelperCompanyDetail'
  readonly calendarWeek: Scalars['Int']
  readonly endDate: Scalars['DateTime']
  readonly eventCount: Scalars['Int']
  readonly startDate: Scalars['DateTime']
}

export interface HelperCompanyEdge {
  readonly __typename?: 'HelperCompanyEdge'
  readonly cursor: Scalars['String']
  readonly node: Maybe<HelperCompany>
}

export enum HelperCompanySortEnum {
  ActiveFirst = 'ACTIVE_FIRST',
  NotActiveFirst = 'NOT_ACTIVE_FIRST',
  NotUsedFirst = 'NOT_USED_FIRST'
}

export interface HotCatalogService {
  readonly __typename?: 'HotCatalogService'
  readonly calculatePrice: ServicePrice
  readonly calculatePrices: ReadonlyArray<ServicePrice>
  readonly groupWrapper: ServiceGroupWrapper
  readonly id: Scalars['ID']
  readonly name: Maybe<Scalars['String']>
  readonly packagesCount: Maybe<Scalars['Int']>
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly typeWrapper: ServiceTypeWrapper
}

export interface HotCatalogServiceCalculatePriceArgs {
  input: InputMaybe<CalculateServicePriceInput>
}

export interface HotCatalogServiceCalculatePricesArgs {
  input: InputMaybe<CalculateServicePriceMultipleInput>
}

export interface HotServiceEmployerEventSnapshot {
  readonly __typename?: 'HotServiceEmployerEventSnapshot'
  readonly addedAt: Scalars['DateTime']
  readonly catalogService: CatalogService
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export interface InformationBanner extends Banner {
  readonly __typename?: 'InformationBanner'
  readonly background: Scalars['String']
  readonly buttons: ReadonlyArray<BannerButton>
  readonly displayPages: ReadonlyArray<BannerDisplayPagesEnum>
  readonly dueTo: Scalars['DateTime']
  readonly hasCloseButton: Scalars['Boolean']
  readonly id: Scalars['ID']
  readonly image: Maybe<BannerImageElement>
  readonly mainText: BannerTextElement
  readonly type: BannerTypeEnum
}

export interface IntIntervalInput {
  readonly from?: InputMaybe<Scalars['Int']>
  readonly to?: InputMaybe<Scalars['Int']>
}

export interface IntRange {
  readonly __typename?: 'IntRange'
  readonly from: Maybe<Scalars['Int']>
  readonly to: Maybe<Scalars['Int']>
}

export interface IntRangeInput {
  readonly from?: InputMaybe<Scalars['Int']>
  readonly to?: InputMaybe<Scalars['Int']>
}

export interface InvalidError extends Error {
  readonly __typename?: 'InvalidError'
  readonly reason: Scalars['String']
}

export interface Invoice {
  readonly __typename?: 'Invoice'
  readonly acts: Maybe<ReadonlyArray<Maybe<InvoiceAct>>>
  readonly addedAt: Scalars['DateTime']
  readonly generateExternalPaymentUrl: Maybe<GenerateExternalPaymentUrlOutput>
  readonly id: Scalars['ID']
  readonly isPaid: Scalars['Boolean']
  readonly isValid: Scalars['Boolean']
  readonly lastPaidAt: Maybe<Scalars['DateTime']>
  readonly orderId: Scalars['ID']
  readonly payWithCard: PayWithCardResponse
  readonly payWithExternalWallet: PayWithExternalWalletResponse
  readonly payer: Scalars['String']
  readonly paymentMethod: PaymentMethods
  readonly state: InvoiceState
  readonly totalPrice: Scalars['Float']
  readonly validTo: Maybe<Scalars['DateTime']>
  readonly year: Scalars['Int']
}

export interface InvoiceGenerateExternalPaymentUrlArgs {
  errorUrl: InputMaybe<Scalars['String']>
  successUrl: InputMaybe<Scalars['String']>
}

export interface InvoicePayWithCardArgs {
  cardId: Scalars['Guid']
  errorUrl: InputMaybe<Scalars['String']>
  successUrl: InputMaybe<Scalars['String']>
}

export interface InvoicePayWithExternalWalletArgs {
  errorUrl: InputMaybe<Scalars['String']>
  paymentData: Scalars['String']
  successUrl: InputMaybe<Scalars['String']>
  walletType: ExternalWalletEnum
}

export interface InvoiceAct {
  readonly __typename?: 'InvoiceAct'
  readonly addedAt: Scalars['DateTime']
  readonly number: Scalars['String']
  readonly year: Scalars['Int']
}

export interface InvoiceCreateInput {
  readonly orderId: Scalars['ID']
}

export interface InvoiceExpiredEvent extends EventInterface {
  readonly __typename?: 'InvoiceExpiredEvent'
  readonly accountNumber: Scalars['Int']
  readonly accountYear: Scalars['Int']
  readonly addDate: Scalars['DateTimeOffset']
  readonly assignment: Maybe<Assignment>
  readonly company: Maybe<CrmCompany>
  readonly daysLeft: Maybe<Scalars['Int']>
  readonly dealNumber: Scalars['Int']
  readonly employee: Maybe<CrmEmployee>
  readonly eventType: EventTypeEnum
  readonly expirationDate: Maybe<Scalars['DateTimeOffset']>
  readonly id: Scalars['ID']
  readonly isArchieved: Scalars['Boolean']
  readonly isRead: Scalars['Boolean']
  readonly lifetimeDays: Scalars['Int']
  readonly orderNumber: Scalars['Int']
  readonly state: EventStateEnum
  readonly topics: ReadonlyArray<Topic>
}

export interface InvoicePaidEvent extends EventInterface {
  readonly __typename?: 'InvoicePaidEvent'
  readonly accountNumber: Scalars['Int']
  readonly accountYear: Scalars['Int']
  readonly addDate: Scalars['DateTimeOffset']
  readonly assignment: Maybe<Assignment>
  readonly company: Maybe<CrmCompany>
  readonly daysLeft: Maybe<Scalars['Int']>
  readonly dealNumber: Scalars['Int']
  readonly employee: Maybe<CrmEmployee>
  readonly eventType: EventTypeEnum
  readonly expirationDate: Maybe<Scalars['DateTimeOffset']>
  readonly id: Scalars['ID']
  readonly isArchieved: Scalars['Boolean']
  readonly isRead: Scalars['Boolean']
  readonly lifetimeDays: Scalars['Int']
  readonly orderNumber: Scalars['Int']
  readonly state: EventStateEnum
  readonly topics: ReadonlyArray<Topic>
  readonly total: Maybe<Scalars['Decimal']>
}

export interface InvoicePaymentBankEvent extends EventInterface {
  readonly __typename?: 'InvoicePaymentBankEvent'
  readonly accountNumber: Scalars['Int']
  readonly accountYear: Scalars['Int']
  readonly addDate: Scalars['DateTimeOffset']
  readonly assignment: Maybe<Assignment>
  readonly company: Maybe<CrmCompany>
  readonly daysLeft: Maybe<Scalars['Int']>
  readonly dealNumber: Scalars['Int']
  readonly employee: Maybe<CrmEmployee>
  readonly eventType: EventTypeEnum
  readonly expirationDate: Maybe<Scalars['DateTimeOffset']>
  readonly id: Scalars['ID']
  readonly isArchieved: Scalars['Boolean']
  readonly isRead: Scalars['Boolean']
  readonly lifetimeDays: Scalars['Int']
  readonly orderNumber: Scalars['Int']
  readonly state: EventStateEnum
  readonly topics: ReadonlyArray<Topic>
  readonly total: Maybe<Scalars['Decimal']>
}

export interface InvoiceRequestedEvent extends EventInterface {
  readonly __typename?: 'InvoiceRequestedEvent'
  readonly accountNumber: Scalars['Int']
  readonly accountYear: Scalars['Int']
  readonly addDate: Scalars['DateTimeOffset']
  readonly assignment: Maybe<Assignment>
  readonly company: Maybe<CrmCompany>
  readonly daysLeft: Maybe<Scalars['Int']>
  readonly dealNumber: Scalars['Int']
  readonly employee: Maybe<CrmEmployee>
  readonly eventType: EventTypeEnum
  readonly expirationDate: Maybe<Scalars['DateTimeOffset']>
  readonly id: Scalars['ID']
  readonly isArchieved: Scalars['Boolean']
  readonly isRead: Scalars['Boolean']
  readonly lifetimeDays: Scalars['Int']
  readonly orderNumber: Scalars['Int']
  readonly state: EventStateEnum
  readonly topics: ReadonlyArray<Topic>
  readonly total: Maybe<Scalars['Decimal']>
}

export enum InvoiceState {
  Disabled = 'DISABLED',
  Fully = 'FULLY',
  Partially = 'PARTIALLY',
  Unpaid = 'UNPAID'
}

export interface JiraServiceDeskOrganisation {
  readonly __typename?: 'JiraServiceDeskOrganisation'
  readonly id: Scalars['ID']
}

export interface JobInstantReceivedNotificationDetails {
  readonly __typename?: 'JobInstantReceivedNotificationDetails'
  readonly detailType: SeekerNotificationDetailTypeEnum
  readonly vacancy: Maybe<Vacancy>
}

export interface JobRecommendationReceivedDetails {
  readonly __typename?: 'JobRecommendationReceivedDetails'
  readonly detailType: SeekerNotificationDetailTypeEnum
  readonly vacancyCount: Maybe<Scalars['Int']>
}

export interface Keyword {
  readonly __typename?: 'Keyword'
  readonly id: Scalars['ID']
  readonly isProfession: Maybe<Scalars['Boolean']>
  readonly name: Scalars['String']
  readonly statistic: Maybe<StatisticData>
}

export interface KeywordStatisticArgs {
  input: StatisticDataCityRubricInput
  withoutChildKeywords?: InputMaybe<Scalars['Boolean']>
}

export interface Lang {
  readonly __typename?: 'Lang'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export interface LangSuggest {
  readonly __typename?: 'LangSuggest'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export enum Language {
  Ru = 'RU',
  Ukr = 'UKR'
}

export interface LanguageBasicQuestion {
  readonly __typename?: 'LanguageBasicQuestion'
  readonly defaultText: Scalars['String']
  readonly languages: ReadonlyArray<BasicQuestionItem>
  readonly skills: ReadonlyArray<BasicQuestionItem>
}

export enum LanguageLevelEnum {
  Advanced = 'ADVANCED',
  Elementary = 'ELEMENTARY',
  Fluent = 'FLUENT',
  Intermediate = 'INTERMEDIATE',
  LowerIntermediate = 'LOWER_INTERMEDIATE',
  Native = 'NATIVE',
  UpperIntermediate = 'UPPER_INTERMEDIATE'
}

export interface LanguageSkillLevel {
  readonly __typename?: 'LanguageSkillLevel'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export interface LegalPayerInput {
  readonly accountNum?: InputMaybe<Scalars['Long']>
  readonly certificate?: InputMaybe<Scalars['String']>
  readonly edrpou: Scalars['String']
  readonly iban?: InputMaybe<Scalars['String']>
  readonly mfo?: InputMaybe<Scalars['Int']>
  readonly name: Scalars['String']
  readonly payerResponsible: PayerResponsibleEnum
  readonly payerResponsibleOther?: InputMaybe<Scalars['String']>
  readonly proxyDate?: InputMaybe<Scalars['DateTime']>
  readonly proxyNumber?: InputMaybe<Scalars['String']>
  readonly responsibleBasisId?: InputMaybe<Scalars['ID']>
}

export interface LetterFilters {
  readonly __typename?: 'LetterFilters'
  readonly showButtons: Scalars['Boolean']
  readonly showDates: Scalars['Boolean']
  readonly showPerson: Scalars['Boolean']
}

export interface LimitsChangeRequestedDetails {
  readonly __typename?: 'LimitsChangeRequestedDetails'
  readonly detailType: SeekerNotificationDetailTypeEnum
  readonly employeeSnapshot: NotificationEmployeeSnapshot
}

export interface LimitsFilters {
  readonly __typename?: 'LimitsFilters'
  readonly giftIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>
  readonly managerIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>
}

export interface ListSeekerDislikedVacanciesInput {
  readonly pagination: SeekerDislikedVacanciesPaginationInput
}

export interface ListSeekerDislikedVacanciesOutput {
  readonly __typename?: 'ListSeekerDislikedVacanciesOutput'
  readonly vacancies: ReadonlyArray<Vacancy>
}

export interface ListSeekerFavoriteVacanciesInput {
  readonly pagination: SeekerFavoriteVacanciesPaginationInput
}

export interface ListSeekerFavoriteVacanciesOutput {
  readonly __typename?: 'ListSeekerFavoriteVacanciesOutput'
  readonly vacancies: ReadonlyArray<Vacancy>
}

export interface LoginMutations {
  readonly __typename?: 'LoginMutations'
  readonly otpLogin: LoginWithOtpMutations
}

export interface LoginWithOtpMutations {
  readonly __typename?: 'LoginWithOtpMutations'
  readonly confirmCode: ConfirmedPhoneUsersOutput
  readonly sendConfirmation: SendSmsOutput
}

export interface LoginWithOtpMutationsConfirmCodeArgs {
  code: Scalars['String']
  phone: Scalars['String']
}

export interface LoginWithOtpMutationsSendConfirmationArgs {
  phone: Scalars['String']
}

export interface LogoInPortalCatalogService {
  readonly __typename?: 'LogoInPortalCatalogService'
  readonly calculatePrice: ServicePrice
  readonly groupWrapper: ServiceGroupWrapper
  readonly id: Scalars['ID']
  readonly name: Maybe<Scalars['String']>
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly typeWrapper: ServiceTypeWrapper
}

export interface LogoInPortalCatalogServiceCalculatePriceArgs {
  input: InputMaybe<CalculateServicePriceInput>
}

export interface LogoInPortalServiceEmployerEventSnapshot {
  readonly __typename?: 'LogoInPortalServiceEmployerEventSnapshot'
  readonly addedAt: Scalars['DateTime']
  readonly catalogService: CatalogService
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export interface LogoInRubricCatalogService {
  readonly __typename?: 'LogoInRubricCatalogService'
  readonly calculatePrice: ServicePrice
  readonly groupWrapper: ServiceGroupWrapper
  readonly id: Scalars['ID']
  readonly name: Maybe<Scalars['String']>
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly typeWrapper: ServiceTypeWrapper
}

export interface LogoInRubricCatalogServiceCalculatePriceArgs {
  input: InputMaybe<CalculateServicePriceInput>
}

export interface LogoInRubricServiceEmployerEventSnapshot {
  readonly __typename?: 'LogoInRubricServiceEmployerEventSnapshot'
  readonly addedAt: Scalars['DateTime']
  readonly catalogService: CatalogService
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly rubric: Rubric
}

export interface LogoOnMainPageCatalogService {
  readonly __typename?: 'LogoOnMainPageCatalogService'
  readonly calculatePrice: ServicePrice
  readonly groupWrapper: ServiceGroupWrapper
  readonly id: Scalars['ID']
  readonly name: Maybe<Scalars['String']>
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly typeWrapper: ServiceTypeWrapper
}

export interface LogoOnMainPageCatalogServiceCalculatePriceArgs {
  input: InputMaybe<CalculateServicePriceInput>
}

export interface LogoOnMainPageServiceEmployerEventSnapshot {
  readonly __typename?: 'LogoOnMainPageServiceEmployerEventSnapshot'
  readonly addedAt: Scalars['DateTime']
  readonly catalogService: CatalogService
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export interface LogoPortal {
  readonly __typename?: 'LogoPortal'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export interface LogoPortalBranch {
  readonly __typename?: 'LogoPortalBranch'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export interface MadeHotVacancyEmployerEvent {
  readonly __typename?: 'MadeHotVacancyEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly durationInDays: Scalars['Int']
  readonly endsAt: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly performer: PerformerEmployerEventUnion
  readonly vacancyCity: City
  readonly vacancyId: Scalars['ID']
  readonly vacancyName: Scalars['String']
}

export interface MailingStatistics {
  readonly __typename?: 'MailingStatistics'
  readonly email: Maybe<EmailStatistics>
  readonly mobilePush: Maybe<MobilePushStatistics>
  readonly updatedAt: Scalars['DateTime']
}

export interface Manager {
  readonly __typename?: 'Manager'
  readonly contacts: Maybe<ManagerContacts>
  readonly department: ManagerDepartment
  readonly id: Scalars['ID']
  readonly imageUrl: Scalars['String']
  readonly name: Scalars['String']
}

export interface ManagerContacts {
  readonly __typename?: 'ManagerContacts'
  readonly additionalPhone: Maybe<Scalars['String']>
  readonly email: Maybe<Scalars['String']>
  readonly internalPhone: Maybe<Scalars['String']>
  readonly mobilePhone: Maybe<Scalars['String']>
  readonly publicPhone: Maybe<Scalars['String']>
  readonly skype: Maybe<Scalars['String']>
}

export interface ManagerDeals {
  readonly __typename?: 'ManagerDeals'
  readonly accountFind: ReadonlyArray<CrmAccount>
  readonly deal: DealDetailsOutput
  readonly dealTable: DealTableOutput
}

export interface ManagerDealsAccountFindArgs {
  query: Scalars['String']
}

export interface ManagerDealsDealArgs {
  dealId: Scalars['Int']
}

export interface ManagerDealsDealTableArgs {
  input: DealTableRequestInput
}

export interface ManagerDepartment {
  readonly __typename?: 'ManagerDepartment'
  readonly id: Scalars['ID']
}

export interface ManagerDiscount {
  readonly __typename?: 'ManagerDiscount'
  readonly manager: Manager
  readonly sum: Scalars['Float']
}

export interface ManagerEventPerformer {
  readonly __typename?: 'ManagerEventPerformer'
  readonly manager: Maybe<Manager>
}

export interface ManagerGiftLimit {
  readonly __typename?: 'ManagerGiftLimit'
  readonly giftName: Scalars['String']
  readonly id: Scalars['ID']
  readonly limitCount: Scalars['Int']
  readonly occasion: GiftOccasion
  readonly wave: Maybe<GiftOccasion>
}

export interface ManagerWalletUser {
  readonly __typename?: 'ManagerWalletUser'
  readonly manager: Manager
}

export interface MarketingCatalogService {
  readonly __typename?: 'MarketingCatalogService'
  readonly calculatePrice: ServicePrice
  readonly groupWrapper: ServiceGroupWrapper
  readonly id: Scalars['ID']
  readonly name: Maybe<Scalars['String']>
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly typeWrapper: ServiceTypeWrapper
}

export interface MarketingCatalogServiceCalculatePriceArgs {
  input: InputMaybe<CalculateServicePriceInput>
}

export interface MarketingGift {
  readonly __typename?: 'MarketingGift'
  readonly allowedCount: Scalars['Int']
  readonly giftType: GiftTypeEnum
  readonly giftTypeName: Scalars['String']
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export interface MarketingGiftRequest {
  readonly __typename?: 'MarketingGiftRequest'
  readonly addDate: Scalars['DateTime']
  readonly details: ReadonlyArray<MarketingGiftRequestDetail>
  readonly id: Scalars['ID']
  readonly marketingEventName: Scalars['String']
}

export interface MarketingGiftRequestConnection {
  readonly __typename?: 'MarketingGiftRequestConnection'
  readonly edges: Maybe<ReadonlyArray<Maybe<MarketingGiftRequestEdge>>>
  readonly items: Maybe<ReadonlyArray<MarketingGiftRequest>>
  readonly pageInfo: PageInfo
  readonly totalCount: Maybe<Scalars['Int']>
}

export interface MarketingGiftRequestDetail {
  readonly __typename?: 'MarketingGiftRequestDetail'
  readonly gift: Gift
  readonly id: Scalars['ID']
  readonly quantity: Scalars['Int']
}

export interface MarketingGiftRequestEdge {
  readonly __typename?: 'MarketingGiftRequestEdge'
  readonly cursor: Scalars['String']
  readonly node: MarketingGiftRequest
}

export interface MarketingLimitTransfer {
  readonly __typename?: 'MarketingLimitTransfer'
  readonly gifts: ReadonlyArray<MarketingLimitTransferDetail>
  readonly manager: CrmManager
  readonly occasion: GiftOccasion
  readonly transferDate: Scalars['DateTime']
  readonly wave: Maybe<GiftOccasion>
}

export interface MarketingLimitTransferConnection {
  readonly __typename?: 'MarketingLimitTransferConnection'
  readonly edges: Maybe<ReadonlyArray<Maybe<MarketingLimitTransferEdge>>>
  readonly items: Maybe<ReadonlyArray<MarketingLimitTransfer>>
  readonly pageInfo: PageInfo
  readonly totalCount: Maybe<Scalars['Int']>
}

export interface MarketingLimitTransferDetail {
  readonly __typename?: 'MarketingLimitTransferDetail'
  readonly currentCount: Scalars['Int']
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly transferedCount: Scalars['Int']
}

export interface MarketingLimitTransferEdge {
  readonly __typename?: 'MarketingLimitTransferEdge'
  readonly cursor: Scalars['String']
  readonly node: MarketingLimitTransfer
}

export interface MarketingServiceEmployerEventSnapshot {
  readonly __typename?: 'MarketingServiceEmployerEventSnapshot'
  readonly addedAt: Scalars['DateTime']
  readonly catalogService: CatalogService
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export interface MassRefuseOutput {
  readonly __typename?: 'MassRefuseOutput'
  readonly applies: Maybe<ReadonlyArray<Maybe<Apply>>>
}

/** A connection to a list of items. */
export interface MessagesConnection {
  readonly __typename?: 'MessagesConnection'
  /** A list of edges. */
  readonly edges: Maybe<ReadonlyArray<MessagesEdge>>
  /** A flattened list of the nodes. */
  readonly nodes: Maybe<ReadonlyArray<ChatMessage>>
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']
}

/** An edge in a connection. */
export interface MessagesEdge {
  readonly __typename?: 'MessagesEdge'
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']
  /** The item at the end of the edge. */
  readonly node: ChatMessage
}

export interface MessengerContact {
  readonly __typename?: 'MessengerContact'
  readonly messenger: MessengerEnum
  readonly userIdentifier: Scalars['String']
}

export interface MessengerContactInput {
  readonly messenger: MessengerEnum
  readonly userIdentifier: Scalars['String']
}

export enum MessengerEnum {
  ForeignPhone = 'FOREIGN_PHONE',
  Telegram = 'TELEGRAM',
  Viber = 'VIBER'
}

export interface MetanotebookAssignedEvent extends EventInterface {
  readonly __typename?: 'MetanotebookAssignedEvent'
  readonly addDate: Scalars['DateTimeOffset']
  readonly assignment: Maybe<Assignment>
  readonly company: Maybe<CrmCompany>
  readonly daysLeft: Maybe<Scalars['Int']>
  readonly employee: Maybe<CrmEmployee>
  readonly eventType: EventTypeEnum
  readonly expirationDate: Maybe<Scalars['DateTimeOffset']>
  readonly id: Scalars['ID']
  readonly isArchieved: Scalars['Boolean']
  readonly isRead: Scalars['Boolean']
  readonly lifetimeDays: Scalars['Int']
  readonly state: EventStateEnum
  readonly topics: ReadonlyArray<Topic>
}

export interface Metro {
  readonly __typename?: 'Metro'
  readonly id: Scalars['ID']
  readonly line: MetroLine
  readonly name: Scalars['String']
}

export interface MetroFacet {
  readonly __typename?: 'MetroFacet'
  readonly count: Scalars['Long']
  readonly metro: Metro
}

export interface MetroLine {
  readonly __typename?: 'MetroLine'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export interface MetroLineFacet {
  readonly __typename?: 'MetroLineFacet'
  readonly count: Scalars['Long']
  readonly metroLine: MetroLine
  readonly metroStationsFacets: ReadonlyArray<MetroFacet>
}

export enum MilitaryStatusEnum {
  ActiveService = 'ACTIVE_SERVICE',
  NoExperience = 'NO_EXPERIENCE',
  Veteran = 'VETERAN'
}

export interface MobilePushStatistics {
  readonly __typename?: 'MobilePushStatistics'
  readonly appliesCount: Scalars['Int']
  readonly clicks: Scalars['Int']
  readonly deliveries: Scalars['Int']
}

export enum ModificationTypeEnum {
  Create = 'CREATE',
  Delete = 'DELETE',
  Update = 'UPDATE'
}

export type ModifyVacancyErrorUnion = CompanyWasMergedError | InvalidError | NoAvailableLimitsError | ServerError | TurbotaWasExpiredError | UserIsNotAuthenticatedError | UserIsNotEmployerError

export interface MovingGiftLimitsDetailInput {
  readonly id: Scalars['ID']
  readonly limitCount: Scalars['Int']
  readonly occasionId: Scalars['ID']
  readonly wave?: InputMaybe<Scalars['Int']>
}

export interface MovingGiftLimitsInput {
  readonly limits: ReadonlyArray<MovingGiftLimitsDetailInput>
  readonly managerTo: Scalars['Int']
}

export interface MovingGiftLimitsOutput {
  readonly __typename?: 'MovingGiftLimitsOutput'
  readonly id: Maybe<Scalars['ID']>
  readonly isSuccess: Scalars['Boolean']
  readonly messageText: Maybe<Scalars['String']>
}

export interface MultipleVacanciesActionItem {
  readonly publicationType: VacancyPublicationType
  readonly serviceId?: InputMaybe<Scalars['ID']>
  readonly vacancyId: Scalars['Int']
}

export interface MultipleVacanciesChangeClosing {
  readonly behavior: VacancyClosingBehavior
  readonly vacancyId: Scalars['Int']
}

export interface Mutation {
  readonly __typename?: 'Mutation'
  readonly acquaintWithNotifications: AcquaintWithNotificationsOutput
  readonly activateOrderByTurbota: OrderTurbotaActivationOutput
  readonly addGamificationGiftLimits: AddGamificationLimitsOutput
  readonly addGiftRequest: Maybe<AddGiftRequestOutput>
  readonly addSeekerProfResumeAdditionalEducations: AddedProfResumeAdditionalEducationsOutput
  readonly addSeekerProfResumeAdditionalInfo: AddedProfResumeAdditionalInfoOutput
  readonly addSeekerProfResumeEducations: AddedProfResumeEducationsOutput
  readonly addSeekerProfResumeExperiences: AddedProfResumeExperiencesOutput
  readonly applyAddCompanyAddress: Maybe<ApplyAddCompanyAddressOutput>
  readonly applyAddNote: Maybe<ApplyAddNoteOutput>
  readonly applyChangeStatus: Maybe<ApplyChangeStatusOutput>
  readonly applyChangeStatuses: Maybe<ApplyChangeStatusesOutput>
  readonly applyDeleteCompanyAddress: Maybe<ApplyDeleteCompanyAddressOutput>
  readonly applyDeleteNote: Maybe<ApplyDeleteNoteOutput>
  readonly applyEditNote: Maybe<ApplyEditNoteOutput>
  readonly applyInvite: Maybe<ApplyInviteOutput>
  readonly applyMassRefuse: Maybe<MassRefuseOutput>
  readonly applyRefuse: Maybe<ApplyRefuseOutput>
  readonly applyToAtsExport: ApplyToAtsExportOutput
  readonly applyUpdateCompanyAddress: Maybe<ApplyUpdateCompanyAddressOutput>
  readonly cart: CartMutation
  readonly categorizationAttaches: CategorizationAttachesMutations
  readonly changeUserServiceLimit: Maybe<Employee>
  readonly clearAllProzoraClientFilters: Maybe<Scalars['Boolean']>
  readonly clearCabinetEventsClientFilters: Scalars['Boolean']
  readonly clearCompanyPublishedVacanciesClientFilters: Scalars['Boolean']
  readonly clearPublishedVacanciesClientFilters: Scalars['Boolean']
  readonly clearServicesManagementFilters: Maybe<Scalars['Boolean']>
  readonly clearTargetMailingListClientFilters: Scalars['Boolean']
  readonly clearUsersManagementFilters: Maybe<Scalars['Boolean']>
  readonly clearVacanciesPageFilters: Maybe<Scalars['Boolean']>
  readonly closeBanner: CloseBannerResponse
  readonly clubs: ClubMutations
  readonly companyApplyAutoReplyTemplateConfigurationUpdate: CompanyApplyAutoReplyTemplateConfigurationUpdateOutput
  readonly companyAssignments: CompanyAssignmentsMutations
  readonly companyChangeInvoicesPayer: Scalars['Boolean']
  readonly companyEditInfo: CompanyEditOutput
  readonly companyEmailResendConfirmation: CompanyEmailResendConfirmationOutput
  readonly companySendJoinRequest: CompanySendJoinRequestOutput
  readonly companyTrackView: Maybe<TrackCompanyViewOutput>
  readonly companyVerification: CompanyVerificationOutput
  readonly completeAssignment: AssignmentOperationOutput
  readonly createAssignment: AssignmentOperationOutput
  readonly createEmptyProfResume: CreatedEmptyProfResumeOutput
  readonly createGroupNotification: CreateGroupNotificationOutput
  readonly createInvoice: CreateInvoiceOutput
  readonly createOrderFromServices: CreateOrderFromServicesOutput
  readonly createProfResume: CreatedProfResumeOutput
  readonly createProfResumeAsCopy: CreatedAsCopyProfResumeOutput
  readonly createProfResumeFromAttach: CreateProfResumeFromAttachOutput
  readonly createRecruitmentOrder: CreateRecruitmentOrderOutput
  readonly createSharingDocumentsDeepLink: CreateSharingDocumentsDeepLinkOutput
  readonly createUserNotification: CreateUserNotificationOutput
  readonly createWallet: CreateWalletOutput
  readonly createWalletTransaction: CreateWalletTransactionOutput
  readonly crmActionAdd: Maybe<CrmActionAddResult>
  readonly crmActionOperation: Maybe<Scalars['Boolean']>
  readonly crmActionUpdate: Maybe<Scalars['Boolean']>
  readonly crmAddUserCard: Maybe<Scalars['Boolean']>
  readonly crmGifts: GiftsMutations
  readonly crmLinkActionToCall: Maybe<Scalars['Boolean']>
  readonly crmLinkPhoneToUserCard: Maybe<Scalars['Boolean']>
  readonly crmLoginToNotebook: Maybe<Scalars['String']>
  readonly crmPinAction: Maybe<Scalars['Boolean']>
  readonly crmRemoveAttach: Maybe<Scalars['Boolean']>
  readonly crmSendOrderEmail: Maybe<Scalars['Boolean']>
  readonly crmSendOrderEmailExt: Maybe<EmailSenderResult>
  readonly crmSetActivationDate: Maybe<Scalars['Boolean']>
  readonly crmUpdateUserCard: Maybe<Scalars['Boolean']>
  readonly deleteAllNotifications: DeleteAllNotificationsOutput
  readonly deleteAssignment: AssignmentOperationOutput
  readonly deleteNotifications: DeleteNotificationsOutput
  readonly deleteSeekerProfResume: DeletedProfResumeOutput
  readonly deleteSeekerProfResumeAdditionalEducations: DeletedProfResumeAdditionalEducationsOutput
  readonly deleteSeekerProfResumeAdditionalInfo: DeletedProfResumeAdditionalInfoOutput
  readonly deleteSeekerProfResumeAdditionalPhones: DeleteSeekerProfResumeAdditionalPhonesOutput
  readonly deleteSeekerProfResumeDiiaCertificate: ProfResumeDeleteDiiaCertificateOutput
  readonly deleteSeekerProfResumeEducations: DeletedProfResumeEducationsOutput
  readonly deleteSeekerProfResumeExperiences: DeletedProfResumeExperiencesOutput
  readonly deleteSeekerProfResumeLanguageSkills: DeletedProfResumeLanguageSkillsOutput
  readonly deleteSeekerProfResumePortfolios: DeleteProfResumePortfolioOutput
  readonly deleteSeekerProfResumeSocialNetworks: DeleteProfResumeSocialNetworksOutput
  readonly deleteUserCard: UserCardDeleteOutput
  readonly employeeChangeEmailConfirm: EmployeeChangeEmailOutput
  readonly employeeChangePasswordConfirm: EmployeeChangeOutput
  readonly employeeCreate: EmployeeCreateOutput
  readonly employeeCvDbSavedSearchDelete: EmployeeCvDbSavedSearchDeleteOutput
  readonly employeeEdit: EmployeeEditOutput
  readonly employeeEditPersonalInfo: EmployeeEditOutput
  readonly employeeJoiningReview: EmployeeJoiningReviewOutput
  readonly employeeResendInvitation: EmployeeResendInvitationOutput
  readonly employeeSendChangeEmail: EmployeeChangeOutput
  readonly employeeSendChangePassword: EmployeeChangeOutput
  readonly employeeSetAsAdmin: EmployeeMakeMainOutput
  readonly employeesBlock: EmployeesBlockOutput
  readonly employeesDelete: EmployeesDeleteOutput
  readonly employeesScopesMultiUpdate: EmployeesMultiScopesUpdateOutput
  readonly employeesUnblock: EmployeesUnblockOutput
  readonly employerSubscriptions: EmployeeSubscriptionsMutations
  readonly feedback: FeedbackMutations
  readonly movingGiftLimits: MovingGiftLimitsOutput
  readonly novaPoshtaAddAddress: Maybe<NovaPoshtaAddGiftAddressOutput>
  readonly novaPoshtaAddCounterparty: Maybe<NovaPoshtaAddCounterpartyOutput>
  readonly novaPoshtaAddDocument: NovaPoshtaAddDocumentOutput
  readonly novaPoshtaAddDocumentsToRegister: NovaPoshtaAddDocumentToRegisterOutput
  readonly novaPoshtaDeleteDocument: NovaPoshtaDeleteDocumentOutput
  readonly novaPoshtaUpdateDocument: NovaPoshtaAddDocumentOutput
  readonly offerVacancyToApply: OfferVacancyToApplyOutput
  readonly promoCodes: PromoCodeMutation
  readonly publishSeekerProfResume: PublishedProfResumeOutput
  readonly questionnaireCopy: QuestionnaireCopyOutput
  readonly questionnaireCreate: QuestionnaireCreateOutput
  readonly questionnaireDelete: QuestionnaireDeleteOutput
  readonly questionnaireGiveAnswers: QuestionnaireGiveAnswersOutput
  readonly questionnaireUpdate: QuestionnaireUpdateOutput
  readonly removeAssignmentAttach: AssignmentOperationOutput
  readonly requestLimits: Maybe<Scalars['Boolean']>
  readonly saleManager: SaleManagerMutations
  readonly savedCards: SavedCardsMutations
  readonly seekerDislikedVacancies: SeekerDislikedVacancyMutations
  readonly seekerFavoriteVacancies: SeekerFavoriteVacancyMutations
  readonly seekerSubscriptions: SeekerSubscriptionMutations
  readonly serviceActivate: Maybe<ServiceActivationOutput>
  readonly setActionFilters: Maybe<Scalars['Boolean']>
  readonly setActionState: SetActionStateOutput
  readonly setBillFilters: Maybe<Scalars['Boolean']>
  readonly setCabinetEventsClientFilters: Scalars['Boolean']
  readonly setCabinetEventsClientPage: Scalars['Boolean']
  readonly setCallFilters: Maybe<Scalars['Boolean']>
  readonly setCompanyPublishedVacanciesClientFilters: Scalars['Boolean']
  readonly setCompanyPublishedVacanciesClientPage: Scalars['Boolean']
  readonly setElectedUserCard: Maybe<Scalars['Boolean']>
  readonly setGiftRegistersScrollInfo: Maybe<Scalars['Boolean']>
  readonly setGiftsFilters: Maybe<Scalars['Boolean']>
  readonly setGiftsManagerScrollInfo: Maybe<Scalars['Boolean']>
  readonly setGiftsMarketingBySelfScrollInfo: Maybe<Scalars['Boolean']>
  readonly setGiftsMarketingNovaPoshtaScrollInfo: Maybe<Scalars['Boolean']>
  readonly setLetterFilters: Maybe<Scalars['Boolean']>
  readonly setLimitsFilters: Maybe<Scalars['Boolean']>
  readonly setProzoraClientFilters: Maybe<Scalars['Boolean']>
  readonly setPublishedVacanciesClientFilters: Scalars['Boolean']
  readonly setPublishedVacanciesClientPage: Scalars['Boolean']
  readonly setPublishedVacanciesClientSort: Scalars['Boolean']
  readonly setServicesFilters: Maybe<Scalars['Boolean']>
  readonly setServicesManagementFilters: Maybe<Scalars['Boolean']>
  readonly setStateGiftRequest: Maybe<GiftRequest>
  readonly setTargetMailingListClientFilters: Scalars['Boolean']
  readonly setTargetMailingListClientPage: Scalars['Boolean']
  readonly setUserState: Maybe<Scalars['Boolean']>
  readonly setUsersManagementFilters: Maybe<Scalars['Boolean']>
  readonly setUsersManagementPageInfo: Maybe<Scalars['Boolean']>
  readonly setVacanciesPageFilters: Maybe<Scalars['Boolean']>
  readonly setVacancyCandidateKeywords: Vacancy
  readonly startCall: Maybe<Scalars['Boolean']>
  readonly startGamificationWave: StartGamificationWaveOutput
  readonly targetedMailing: TargetedMailingMutations
  readonly unpublishSeekerProfResume: UnpublishedProfResumeOutput
  readonly updateAssignment: AssignmentOperationOutput
  readonly updateGiftRequest: Maybe<UpdateGiftRequestOutput>
  readonly updateSeekerProfResumeAdditionalEducation: UpdatedProfResumeAdditionalEducationsOutput
  readonly updateSeekerProfResumeAdditionalInfo: UpdatedProfResumeAdditionalInfoOutput
  readonly updateSeekerProfResumeAdditionalInfos: UpdatedProfResumeAdditionalInfosOutput
  readonly updateSeekerProfResumeAdditionalPhones: UpdateProfResumeAdditionalPhonesOutput
  readonly updateSeekerProfResumeAnonymity: UpdatedProfResumeAnonymityOutput
  readonly updateSeekerProfResumeAvailabilityState: UpdatedProfResumeAvailabilityStateOutput
  readonly updateSeekerProfResumeBirthDate: UpdatedProfResumeBirthDateOutput
  readonly updateSeekerProfResumeCity: UpdatedProfResumeCityOutput
  readonly updateSeekerProfResumeDiiaCertificate: UpdatedProfResumeDiiaCertificateOutput
  readonly updateSeekerProfResumeDisability: UpdatedProfResumeDisabilityOutput
  readonly updateSeekerProfResumeEducation: UpdatedProfResumeEducationsOutput
  readonly updateSeekerProfResumeEmail: UpdateProfResumeEmailOutput
  readonly updateSeekerProfResumeExperience: UpdatedProfResumeExperiencesOutput
  readonly updateSeekerProfResumeFullName: UpdatedProfResumeFullNameOutput
  readonly updateSeekerProfResumeGender: UpdatedProfResumeGenderOutput
  readonly updateSeekerProfResumeHiddenCompanies: UpdatedProfResumeHiddenCompaniesOutput
  readonly updateSeekerProfResumeLanguageSkills: UpdatedProfResumeLanguageSkillsOutput
  readonly updateSeekerProfResumeMainPhone: UpdateProfResumePhoneOutput
  readonly updateSeekerProfResumeMilitaryStatus: UpdatedProfResumeMilitaryStatusOutput
  readonly updateSeekerProfResumePhoto: UpdatedProfResumePhotoOutput
  readonly updateSeekerProfResumePortfolios: UpdateProfResumePortfoliosOutput
  readonly updateSeekerProfResumePosition: UpdatedProfResumePositionOutput
  readonly updateSeekerProfResumeSalary: UpdatedProfResumeSalaryOutput
  readonly updateSeekerProfResumeSchedule: UpdatedProfResumeScheduleOutput
  readonly updateSeekerProfResumeSearchState: UpdatedProfResumeSearchStateOutput
  readonly updateSeekerProfResumeSkills: UpdatedProfResumeSkillsOutput
  readonly updateSeekerProfResumeSocialNetworks: UpdateProfResumeSocialNetworksOutput
  readonly updateSeekerProfResumeSortDate: UpdateSeekerProfResumeSortDateOutput
  readonly updateSeekerProfResumeState: ProfResumeUpdateStateOutput
  readonly users: Users
  readonly vacancyAppearedInViewPort: VacancyAppearedInViewPortOutput
  readonly vacancyChangeClosingBehavior: Maybe<VacancyChangeClosingBehaviorOutput>
  readonly vacancyChangeOwner: Maybe<VacancyChangeOwnerOutput>
  readonly vacancyClose: Maybe<VacancyCloseOutput>
  readonly vacancyCreate: Maybe<VacancyCreateOutput>
  readonly vacancyDelete: Maybe<DeleteVacancyOutput>
  readonly vacancyInterviewQuestions: VacancyInterviewQuestionsMutations
  readonly vacancyMakeHot: Maybe<VacancyMakeHotOutput>
  readonly vacancyProlongationReview: VacancyProlongationReviewOutput
  readonly vacancyPublish: Maybe<VacancyPublishOutput>
  readonly vacancyRejectPublication: Maybe<VacancyRejectPublicationOutput>
  readonly vacancyRepublish: Maybe<VacancyRepublishOutput>
  readonly vacancyResume: Maybe<VacancyResumeOutput>
  readonly vacancyRisingUp: Maybe<VacancyMakeHotOutput>
  readonly vacancySuspend: Maybe<VacancySuspendOutput>
  readonly vacancyToAtsProjectExport: VacancyToAtsProjectExportOutput
  readonly vacancyToDraft: Maybe<VacancyDraftOutput>
  readonly vacancyUpdate: Maybe<VacancyUpdateOutput>
  readonly viewNotifications: ViewNotificationsOutput
}

export interface MutationActivateOrderByTurbotaArgs {
  input: OrderTurbotaActivationInput
}

export interface MutationAddGamificationGiftLimitsArgs {
  input: AddGamificationLimitInput
}

export interface MutationAddGiftRequestArgs {
  input: AddGiftInput
}

export interface MutationAddSeekerProfResumeAdditionalEducationsArgs {
  input: ProfResumeAddAdditionalEducationsInput
}

export interface MutationAddSeekerProfResumeAdditionalInfoArgs {
  input: ProfResumeAddAdditionalInfoInput
}

export interface MutationAddSeekerProfResumeEducationsArgs {
  input: ProfResumeAddEducationsInput
}

export interface MutationAddSeekerProfResumeExperiencesArgs {
  input: ProfResumeAddExperiencesInput
}

export interface MutationApplyAddCompanyAddressArgs {
  input: ApplyAddCompanyAddressInput
}

export interface MutationApplyAddNoteArgs {
  input: ApplyAddNoteInput
}

export interface MutationApplyChangeStatusArgs {
  input: ApplyChangeStatusInput
}

export interface MutationApplyChangeStatusesArgs {
  input: ApplyChangeStatusesInput
}

export interface MutationApplyDeleteCompanyAddressArgs {
  input: ApplyDeleteCompanyAddressInput
}

export interface MutationApplyDeleteNoteArgs {
  input: ApplyDeleteNoteInput
}

export interface MutationApplyEditNoteArgs {
  input: ApplyEditNoteInput
}

export interface MutationApplyInviteArgs {
  input: ApplyInviteInput
}

export interface MutationApplyMassRefuseArgs {
  input: ApplyMassRefuseInput
}

export interface MutationApplyRefuseArgs {
  input: ApplyRefuseInput
}

export interface MutationApplyToAtsExportArgs {
  input: ApplyToAtsExportInput
}

export interface MutationApplyUpdateCompanyAddressArgs {
  input: ApplyUpdateCompanyAddressInput
}

export interface MutationChangeUserServiceLimitArgs {
  input: EmployerSummaryServiceLimitInput
}

export interface MutationCloseBannerArgs {
  id: Scalars['Guid']
}

export interface MutationCompanyApplyAutoReplyTemplateConfigurationUpdateArgs {
  input: CompanyApplyAutoReplyTemplateConfigurationUpdateInput
}

export interface MutationCompanyChangeInvoicesPayerArgs {
  newPayerName: Scalars['String']
}

export interface MutationCompanyEditInfoArgs {
  input: CompanyEditInput
}

export interface MutationCompanyEmailResendConfirmationArgs {
  input: CompanyEmailResendConfirmationInput
}

export interface MutationCompanySendJoinRequestArgs {
  companyId: Scalars['ID']
}

export interface MutationCompanyTrackViewArgs {
  companyId: Scalars['ID']
  rubricId: InputMaybe<Scalars['ID']>
}

export interface MutationCompanyVerificationArgs {
  input: CompanyVerificationInput
}

export interface MutationCompleteAssignmentArgs {
  input: AssignmentCompleteInput
}

export interface MutationCreateAssignmentArgs {
  input: AssignmentCreateInput
}

export interface MutationCreateGroupNotificationArgs {
  input: CreateGroupNotificationInput
}

export interface MutationCreateInvoiceArgs {
  input: InvoiceCreateInput
}

export interface MutationCreateOrderFromServicesArgs {
  input: CreateOrderFromServicesInput
}

export interface MutationCreateProfResumeArgs {
  input: CreateProfResumeInput
}

export interface MutationCreateProfResumeAsCopyArgs {
  input: CreateAsCopyProfResumeInput
}

export interface MutationCreateProfResumeFromAttachArgs {
  input: CreateProfResumeFromAttachInput
}

export interface MutationCreateRecruitmentOrderArgs {
  input: CreateRecruitmentOrderInput
}

export interface MutationCreateSharingDocumentsDeepLinkArgs {
  input: CreateSharingDocumentsDeepLinkInput
}

export interface MutationCreateUserNotificationArgs {
  input: CreateUserNotificationInput
}

export interface MutationCreateWalletArgs {
  input: CreateWalletInput
}

export interface MutationCreateWalletTransactionArgs {
  input: CreateWalletTransactionInput
}

export interface MutationCrmActionAddArgs {
  input: CrmActionAddInput
}

export interface MutationCrmActionOperationArgs {
  input: CrmActionOperationInput
}

export interface MutationCrmActionUpdateArgs {
  input: CrmActionUpdateInput
}

export interface MutationCrmAddUserCardArgs {
  input: CrmUserCardAddInput
}

export interface MutationCrmLinkActionToCallArgs {
  actionId: Scalars['ID']
  callId: Scalars['ID']
}

export interface MutationCrmLinkPhoneToUserCardArgs {
  input: CrmLinkPhoneToUserCardInput
}

export interface MutationCrmLoginToNotebookArgs {
  userCardId: Scalars['ID']
}

export interface MutationCrmPinActionArgs {
  input: CrmPinActionInput
}

export interface MutationCrmRemoveAttachArgs {
  input: Scalars['ID']
}

export interface MutationCrmSendOrderEmailArgs {
  input: SendOrderLetterInput
}

export interface MutationCrmSendOrderEmailExtArgs {
  input: SendOrderLetterInput
}

export interface MutationCrmSetActivationDateArgs {
  date: Scalars['DateTime']
  orderDetailId: Scalars['ID']
}

export interface MutationCrmUpdateUserCardArgs {
  input: CrmUserCardUpdateInput
}

export interface MutationDeleteAssignmentArgs {
  id: Scalars['ID']
}

export interface MutationDeleteNotificationsArgs {
  input: DeleteNotificationsInput
}

export interface MutationDeleteSeekerProfResumeArgs {
  input: DeleteProfResumeInput
}

export interface MutationDeleteSeekerProfResumeAdditionalEducationsArgs {
  input: ProfResumeDeleteAdditionalEducationsInput
}

export interface MutationDeleteSeekerProfResumeAdditionalInfoArgs {
  input: ProfResumeDeleteAdditionalInfoInput
}

export interface MutationDeleteSeekerProfResumeAdditionalPhonesArgs {
  input: ProfResumeDeleteAdditionalPhonesInput
}

export interface MutationDeleteSeekerProfResumeDiiaCertificateArgs {
  input: ProfResumeDeleteDiiaCertificateInput
}

export interface MutationDeleteSeekerProfResumeEducationsArgs {
  input: ProfResumeDeleteEducationsInput
}

export interface MutationDeleteSeekerProfResumeExperiencesArgs {
  input: ProfResumeExperiencesDeleteInput
}

export interface MutationDeleteSeekerProfResumeLanguageSkillsArgs {
  input: ProfResumeDeleteLanguageSkillsInput
}

export interface MutationDeleteSeekerProfResumePortfoliosArgs {
  input: ProfResumeDeletePortfolioInput
}

export interface MutationDeleteSeekerProfResumeSocialNetworksArgs {
  input: ProfResumeDeleteSocialNetworksInput
}

export interface MutationDeleteUserCardArgs {
  id: Scalars['Int']
}

export interface MutationEmployeeChangeEmailConfirmArgs {
  input: EmployeeChangeEmailInput
}

export interface MutationEmployeeChangePasswordConfirmArgs {
  input: EmployeeChangePasswordInput
}

export interface MutationEmployeeCreateArgs {
  input: EmployeeCreateInput
}

export interface MutationEmployeeCvDbSavedSearchDeleteArgs {
  input: EmployeeCvDbSavedSearchDeleteInput
}

export interface MutationEmployeeEditArgs {
  input: EmployeeEditInput
}

export interface MutationEmployeeEditPersonalInfoArgs {
  input: EmployeeEditInput
}

export interface MutationEmployeeJoiningReviewArgs {
  input: InputMaybe<EmployeeJoiningReviewInput>
}

export interface MutationEmployeeResendInvitationArgs {
  input: InputMaybe<EmployeeResendInvitationInput>
}

export interface MutationEmployeeSendChangeEmailArgs {
  input: EmployeeSendChangeEmailInput
}

export interface MutationEmployeeSetAsAdminArgs {
  input: EmployeeSetAsAdminInput
}

export interface MutationEmployeesBlockArgs {
  input: EmployeesBlockInput
}

export interface MutationEmployeesDeleteArgs {
  input: EmployeesDeleteInput
}

export interface MutationEmployeesScopesMultiUpdateArgs {
  input: EmployeesScopesMultiUpdateInput
}

export interface MutationEmployeesUnblockArgs {
  input: EmployeesUnblockInput
}

export interface MutationMovingGiftLimitsArgs {
  input: MovingGiftLimitsInput
}

export interface MutationNovaPoshtaAddAddressArgs {
  input: NovaPoshtaAddGiftAddressInput
}

export interface MutationNovaPoshtaAddCounterpartyArgs {
  input: NovaPoshtaAddCounterpartyInput
}

export interface MutationNovaPoshtaAddDocumentArgs {
  input: NovaPoshtaAddDocumentInput
}

export interface MutationNovaPoshtaAddDocumentsToRegisterArgs {
  input: NovaPoshtaAddDocumentToRegisterInput
}

export interface MutationNovaPoshtaDeleteDocumentArgs {
  id: Scalars['String']
}

export interface MutationNovaPoshtaUpdateDocumentArgs {
  input: NovaPoshtaUpdateDocumentInput
}

export interface MutationOfferVacancyToApplyArgs {
  input: OfferVacancyToApplyInput
}

export interface MutationPublishSeekerProfResumeArgs {
  input: PublishProfResumeInput
}

export interface MutationQuestionnaireCopyArgs {
  input: QuestionnaireCopyInput
}

export interface MutationQuestionnaireCreateArgs {
  input: QuestionnaireCreateInput
}

export interface MutationQuestionnaireDeleteArgs {
  id: Scalars['ID']
}

export interface MutationQuestionnaireGiveAnswersArgs {
  input: QuestionnaireGiveAnswersInput
}

export interface MutationQuestionnaireUpdateArgs {
  input: QuestionnaireUpdateInput
}

export interface MutationRemoveAssignmentAttachArgs {
  attachId: Scalars['ID']
}

export interface MutationRequestLimitsArgs {
  input: EmployerServiceLimitRequestInput
}

export interface MutationServiceActivateArgs {
  input: ServiceActivationInput
}

export interface MutationSetActionFiltersArgs {
  assigners: InputMaybe<ReadonlyArray<InputMaybe<CrmActionCreatorEnum>>>
  offset: InputMaybe<Scalars['String']>
  query: InputMaybe<Scalars['String']>
  types: InputMaybe<ReadonlyArray<InputMaybe<CrmActionTypeEnum>>>
  users: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
}

export interface MutationSetActionStateArgs {
  ids: ReadonlyArray<Scalars['ID']>
  state: CrmActionState
}

export interface MutationSetBillFiltersArgs {
  hasActs: InputMaybe<Scalars['Boolean']>
  offset: InputMaybe<Scalars['String']>
  quarters: InputMaybe<ReadonlyArray<InputMaybe<CrmQuarterInput>>>
  states: InputMaybe<ReadonlyArray<InputMaybe<CrmAccountState>>>
}

export interface MutationSetCabinetEventsClientFiltersArgs {
  input: EmployerEventsLogFilterInput
}

export interface MutationSetCabinetEventsClientPageArgs {
  page: Scalars['Int']
}

export interface MutationSetCallFiltersArgs {
  companyId: InputMaybe<Scalars['ID']>
  cursor: InputMaybe<Scalars['String']>
  date: InputMaybe<CrmDateInterval>
  query: InputMaybe<Scalars['String']>
  types: InputMaybe<ReadonlyArray<InputMaybe<CrmCallTypeRequest>>>
}

export interface MutationSetCompanyPublishedVacanciesClientFiltersArgs {
  input: CompanyPublishedVacanciesFilterInput
}

export interface MutationSetCompanyPublishedVacanciesClientPageArgs {
  page: Scalars['Int']
}

export interface MutationSetElectedUserCardArgs {
  input: CrmUserCardElectInput
}

export interface MutationSetGiftRegistersScrollInfoArgs {
  currentOffset: InputMaybe<Scalars['String']>
  hasNextOffset: Scalars['Boolean']
  nextOffset: InputMaybe<Scalars['String']>
}

export interface MutationSetGiftsFiltersArgs {
  deliveryChannel: InputMaybe<ReadonlyArray<InputMaybe<GiftDeliveryChannelEnum>>>
  giftIds: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  managerIds: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  notebookId: InputMaybe<Scalars['ID']>
  novaPoshtaState: InputMaybe<ReadonlyArray<InputMaybe<NovaPoshtaStateEnum>>>
  occasions: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  period: InputMaybe<GiftPeriodEnum>
  stateIds: InputMaybe<ReadonlyArray<InputMaybe<GiftRequestStateEnum>>>
}

export interface MutationSetGiftsManagerScrollInfoArgs {
  currentOffset: InputMaybe<Scalars['String']>
  hasNextOffset: Scalars['Boolean']
  nextOffset: InputMaybe<Scalars['String']>
}

export interface MutationSetGiftsMarketingBySelfScrollInfoArgs {
  currentOffset: InputMaybe<Scalars['String']>
  hasNextOffset: Scalars['Boolean']
  nextOffset: InputMaybe<Scalars['String']>
}

export interface MutationSetGiftsMarketingNovaPoshtaScrollInfoArgs {
  currentOffset: InputMaybe<Scalars['String']>
  hasNextOffset: Scalars['Boolean']
  nextOffset: InputMaybe<Scalars['String']>
}

export interface MutationSetLetterFiltersArgs {
  showButtons: Scalars['Boolean']
  showDates: Scalars['Boolean']
  showPerson: Scalars['Boolean']
}

export interface MutationSetLimitsFiltersArgs {
  giftIds: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  managerIds: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
}

export interface MutationSetProzoraClientFiltersArgs {
  input: ProzoraClientFiltersInput
}

export interface MutationSetPublishedVacanciesClientFiltersArgs {
  input: PublishedVacanciesClientFilterInput
}

export interface MutationSetPublishedVacanciesClientPageArgs {
  page: Scalars['Int']
}

export interface MutationSetPublishedVacanciesClientSortArgs {
  sort: PublishedVacanciesSortType
}

export interface MutationSetServicesFiltersArgs {
  dateRange: InputMaybe<DateRangeInput>
  managerId: InputMaybe<Scalars['ID']>
  offset: InputMaybe<Scalars['String']>
  payStatusIds: InputMaybe<ReadonlyArray<InputMaybe<CrmPayState>>>
  paymentDateRange: InputMaybe<DateRangeInput>
  positionStates: InputMaybe<ReadonlyArray<CrmPositionState>>
  query: InputMaybe<Scalars['String']>
  serviceId: InputMaybe<Scalars['ID']>
}

export interface MutationSetServicesManagementFiltersArgs {
  input: ServicesManagementClientFiltersInput
}

export interface MutationSetStateGiftRequestArgs {
  input: SetStateGiftRequestInput
}

export interface MutationSetTargetMailingListClientFiltersArgs {
  input: TargetedMailingFilterInput
}

export interface MutationSetTargetMailingListClientPageArgs {
  page: Scalars['Int']
}

export interface MutationSetUserStateArgs {
  department: InputMaybe<DepartmentEnum>
  id: InputMaybe<Scalars['ID']>
  isSM: InputMaybe<Scalars['Boolean']>
  isSTM: InputMaybe<Scalars['Boolean']>
  roles: InputMaybe<RolesEnum>
}

export interface MutationSetUsersManagementFiltersArgs {
  input: UsersManagementFiltersInput
}

export interface MutationSetUsersManagementPageInfoArgs {
  input: UsersManagementPageInfoInput
}

export interface MutationSetVacanciesPageFiltersArgs {
  input: VacanciesPageClientFiltersInput
}

export interface MutationSetVacancyCandidateKeywordsArgs {
  input: SetVacancyCandidateKeywordsInput
}

export interface MutationStartCallArgs {
  input: StartCallInput
}

export interface MutationStartGamificationWaveArgs {
  input: StartGamificationWaveInput
}

export interface MutationUnpublishSeekerProfResumeArgs {
  input: UnpublishProfResumeInput
}

export interface MutationUpdateAssignmentArgs {
  input: AssignmentUpdateInput
}

export interface MutationUpdateGiftRequestArgs {
  input: UpdateGiftInput
}

export interface MutationUpdateSeekerProfResumeAdditionalEducationArgs {
  input: ProfResumeUpdateAdditionalEducationInput
}

export interface MutationUpdateSeekerProfResumeAdditionalInfoArgs {
  input: ProfResumeUpdateAdditionalInfoInput
}

export interface MutationUpdateSeekerProfResumeAdditionalInfosArgs {
  input: ProfResumeAdditionalInfosInput
}

export interface MutationUpdateSeekerProfResumeAdditionalPhonesArgs {
  input: ProfResumeUpdateAdditionalPhonesInput
}

export interface MutationUpdateSeekerProfResumeAnonymityArgs {
  input: ProfResumeUpdateAnonymityInput
}

export interface MutationUpdateSeekerProfResumeAvailabilityStateArgs {
  input: ProfResumeUpdateAvailabilityStateInput
}

export interface MutationUpdateSeekerProfResumeBirthDateArgs {
  input: ProfResumeBirthDateInput
}

export interface MutationUpdateSeekerProfResumeCityArgs {
  input: ProfResumeCityInput
}

export interface MutationUpdateSeekerProfResumeDiiaCertificateArgs {
  input: ProfResumeDiiaCertificateInput
}

export interface MutationUpdateSeekerProfResumeDisabilityArgs {
  input: ProfResumeDisabilityInput
}

export interface MutationUpdateSeekerProfResumeEducationArgs {
  input: ProfResumeUpdateEducationInput
}

export interface MutationUpdateSeekerProfResumeEmailArgs {
  input: ProfResumeUpdateEmailInput
}

export interface MutationUpdateSeekerProfResumeExperienceArgs {
  input: ProfResumeUpdateExperiencesInput
}

export interface MutationUpdateSeekerProfResumeFullNameArgs {
  input: ProfResumeFullNameInput
}

export interface MutationUpdateSeekerProfResumeGenderArgs {
  input: ProfResumeGenderInput
}

export interface MutationUpdateSeekerProfResumeHiddenCompaniesArgs {
  input: ProfResumeUpdateHiddenCompaniesInput
}

export interface MutationUpdateSeekerProfResumeLanguageSkillsArgs {
  input: ProfResumeUpdateLanguageSkillsInput
}

export interface MutationUpdateSeekerProfResumeMainPhoneArgs {
  input: ProfResumeUpdatePhoneInput
}

export interface MutationUpdateSeekerProfResumeMilitaryStatusArgs {
  input: ProfResumeMilitaryStatusInput
}

export interface MutationUpdateSeekerProfResumePhotoArgs {
  input: ProfResumePhotoInput
}

export interface MutationUpdateSeekerProfResumePortfoliosArgs {
  input: ProfResumeUpdatePortfoliosInput
}

export interface MutationUpdateSeekerProfResumePositionArgs {
  input: ProfResumePositionInput
}

export interface MutationUpdateSeekerProfResumeSalaryArgs {
  input: ProfResumeUpdateSalaryInput
}

export interface MutationUpdateSeekerProfResumeScheduleArgs {
  input: ProfResumeScheduleInput
}

export interface MutationUpdateSeekerProfResumeSearchStateArgs {
  input: ProfResumeUpdateSearchStateInput
}

export interface MutationUpdateSeekerProfResumeSkillsArgs {
  input: ProfResumeSkillsInput
}

export interface MutationUpdateSeekerProfResumeSocialNetworksArgs {
  input: ProfResumeUpdateSocialNetworksInput
}

export interface MutationUpdateSeekerProfResumeSortDateArgs {
  input: UpdateSeekerProfResumeSortDateInput
}

export interface MutationUpdateSeekerProfResumeStateArgs {
  input: ProfResumeUpdateStateInput
}

export interface MutationVacancyAppearedInViewPortArgs {
  input: VacancyAppearedInViewPortInput
}

export interface MutationVacancyChangeClosingBehaviorArgs {
  input: VacancyChangeClosingBehaviorInput
}

export interface MutationVacancyChangeOwnerArgs {
  input: VacancyChangeOwnerInput
}

export interface MutationVacancyCloseArgs {
  input: VacancyCloseInput
}

export interface MutationVacancyCreateArgs {
  input: VacancyCreateInput
}

export interface MutationVacancyDeleteArgs {
  input: DeleteVacancyInput
}

export interface MutationVacancyMakeHotArgs {
  input: VacancyMakeHotInput
}

export interface MutationVacancyProlongationReviewArgs {
  input: VacancyProlongationReviewInput
}

export interface MutationVacancyPublishArgs {
  input: VacancyPublishInput
}

export interface MutationVacancyRejectPublicationArgs {
  input: VacancyRejectPublicationInput
}

export interface MutationVacancyRepublishArgs {
  input: VacancyRepublishInput
}

export interface MutationVacancyResumeArgs {
  input: VacancyResumeInput
}

export interface MutationVacancyRisingUpArgs {
  input: VacancyRisingUpInput
}

export interface MutationVacancySuspendArgs {
  input: VacancySuspendInput
}

export interface MutationVacancyToAtsProjectExportArgs {
  input: VacancyToAtsProjectExportInput
}

export interface MutationVacancyToDraftArgs {
  input: VacancyDraftInput
}

export interface MutationVacancyUpdateArgs {
  input: VacancyUpdateInput
}

export interface MutationViewNotificationsArgs {
  input: ViewNotificationsInput
}

export interface MyServicesConnection {
  readonly __typename?: 'MyServicesConnection'
  readonly edges: Maybe<ReadonlyArray<Maybe<ServiceUnitedEdge>>>
  readonly items: Maybe<ReadonlyArray<Maybe<ServiceUnited>>>
  readonly pageInfo: PageInfo
  readonly totalCount: Maybe<Scalars['Int']>
}

export interface MyVacanciesByCityFilter {
  readonly closingBehaviors?: InputMaybe<ReadonlyArray<InputMaybe<VacancyClosingBehavior>>>
  readonly employerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  readonly keywords?: InputMaybe<Scalars['String']>
  readonly statuses?: InputMaybe<ReadonlyArray<InputMaybe<VacancyStatus>>>
}

export interface MyVacanciesByEmployerFilter {
  readonly cityIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  readonly closingBehaviors?: InputMaybe<ReadonlyArray<InputMaybe<VacancyClosingBehavior>>>
  readonly keywords?: InputMaybe<Scalars['String']>
  readonly statuses?: InputMaybe<ReadonlyArray<InputMaybe<VacancyStatus>>>
}

export interface MyVacanciesByStatusFilter {
  readonly cityIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  readonly closingBehaviors?: InputMaybe<ReadonlyArray<InputMaybe<VacancyClosingBehavior>>>
  readonly employerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  readonly keywords?: InputMaybe<Scalars['String']>
}

export interface MyVacanciesConnection {
  readonly __typename?: 'MyVacanciesConnection'
  readonly edges: Maybe<ReadonlyArray<Maybe<VacancyEdge>>>
  readonly items: Maybe<ReadonlyArray<Maybe<Vacancy>>>
  readonly pageInfo: PageInfo
  readonly totalCount: Maybe<Scalars['Int']>
}

export interface MyVacanciesFilterInput {
  readonly cityIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  readonly closingBehaviors?: InputMaybe<ReadonlyArray<InputMaybe<VacancyClosingBehavior>>>
  readonly employerIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  readonly keywords?: InputMaybe<Scalars['String']>
  readonly statuses?: InputMaybe<ReadonlyArray<InputMaybe<VacancyStatus>>>
  readonly vacancyIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
}

export enum MyVacanciesSortType {
  ByAddDateAsc = 'BY_ADD_DATE_ASC',
  ByAddDateDesc = 'BY_ADD_DATE_DESC',
  ByAlphabetAsc = 'BY_ALPHABET_ASC',
  ByAlphabetDesc = 'BY_ALPHABET_DESC',
  ByCloseDateAsc = 'BY_CLOSE_DATE_ASC',
  ByCloseDateDesc = 'BY_CLOSE_DATE_DESC',
  ByStatus = 'BY_STATUS'
}

export interface NoAvailableLimitsError extends Error {
  readonly __typename?: 'NoAvailableLimitsError'
  readonly reason: Scalars['String']
}

export interface NotAvailableError extends Error {
  readonly __typename?: 'NotAvailableError'
  readonly reason: Scalars['String']
}

export interface NotFoundError extends Error {
  readonly __typename?: 'NotFoundError'
  readonly reason: Scalars['String']
}

export interface NotFoundProfResume {
  readonly __typename?: 'NotFoundProfResume'
  readonly id: Scalars['ID']
}

export interface Notification {
  readonly __typename?: 'Notification'
  readonly arrivedAt: Scalars['DateTime']
  readonly details: Maybe<NotificationDetails>
  readonly id: Scalars['ID']
  readonly isAcquainted: Scalars['Boolean']
  readonly isViewed: Scalars['Boolean']
}

export interface NotificationCommonVacancySnapshot extends NotificationCommonVacancySnapshotInterface {
  readonly __typename?: 'NotificationCommonVacancySnapshot'
  readonly id: Scalars['ID']
  readonly title: Scalars['String']
}

export interface NotificationCommonVacancySnapshotInterface {
  readonly id: Scalars['ID']
  readonly title: Scalars['String']
}

export type NotificationDetails =
  | ApplicationInvitedNotificationDetails
  | ApplicationRefusedNotificationDetails
  | ApplicationSentNotificationDetails
  | ApplicationViewedNotificationDetails
  | CompanyStateChangedDetails
  | CustomNotificationDetails
  | DailyResumeViewsReportCalculated
  | FavoriteVacanciesRemindedDetails
  | JobInstantReceivedNotificationDetails
  | JobRecommendationReceivedDetails
  | LimitsChangeRequestedDetails
  | OrderActivatedByTurbotaDetails
  | ResumeDraftCreatedNotificationDetails
  | ResumePublishedNotificationDetails
  | SendNotificationSetEmailDetails
  | UnpaidOrderDetails
  | UserEmailHasBeenSetNotificationDetails
  | VacancyAwaitingApprovalDetails
  | VacancyBannedByModeratorDetails
  | VacancyEndingCountDetails
  | VacancyEndingLeftDaysDetails
  | VacancyOwnerChangedDetails
  | VacancyPublicationApprovedDetails
  | VacancyPublicationEndedDetails
  | VacancyPublicationRejectedDetails

export interface NotificationElementButtonInput {
  readonly label: Scalars['String']
  readonly type: CustomNotificationButtonTypeEnum
  readonly url: Scalars['String']
}

export interface NotificationElementInput {
  readonly buttons: ReadonlyArray<NotificationElementButtonInput>
  readonly iconUrl: Scalars['String']
  readonly text: ReadonlyArray<NotificationElementTextInput>
}

export interface NotificationElementTextInput {
  readonly isBold: Scalars['Boolean']
  readonly text: Scalars['String']
  readonly url?: InputMaybe<Scalars['String']>
}

export interface NotificationEmployeeInitiator extends NotificationInitiatorInterface {
  readonly __typename?: 'NotificationEmployeeInitiator'
  readonly employee: Maybe<Employee>
  readonly fullName: Scalars['String']
  readonly id: Scalars['ID']
}

export interface NotificationEmployeeSnapshot {
  readonly __typename?: 'NotificationEmployeeSnapshot'
  readonly fullName: Scalars['String']
  readonly id: Scalars['ID']
}

export interface NotificationEndingDaysPublishPeriodSnapshot {
  readonly __typename?: 'NotificationEndingDaysPublishPeriodSnapshot'
  readonly daysUntilEnd: Scalars['Int']
  readonly end: Scalars['DateTime']
}

export interface NotificationEndingDaysVacancySnapshot extends NotificationCommonVacancySnapshotInterface {
  readonly __typename?: 'NotificationEndingDaysVacancySnapshot'
  readonly id: Scalars['ID']
  readonly publishPeriod: NotificationEndingDaysPublishPeriodSnapshot
  readonly title: Scalars['String']
}

export enum NotificationGroupEnum {
  Employers = 'EMPLOYERS',
  Seekers = 'SEEKERS'
}

export type NotificationInitiator = NotificationEmployeeInitiator | NotificationManagerInitiator

export interface NotificationInitiatorInterface {
  readonly fullName: Scalars['String']
  readonly id: Scalars['ID']
}

export interface NotificationManagerInitiator extends NotificationInitiatorInterface {
  readonly __typename?: 'NotificationManagerInitiator'
  readonly fullName: Scalars['String']
  readonly id: Scalars['ID']
  readonly manager: Maybe<Manager>
}

export interface NotificationNotFoundError extends NotificationsError {
  readonly __typename?: 'NotificationNotFoundError'
  readonly message: Scalars['String']
}

export interface NotificationOrderSnapshot {
  readonly __typename?: 'NotificationOrderSnapshot'
  readonly id: Scalars['ID']
  readonly orderedAt: Scalars['DateTime']
  readonly totalPrice: Scalars['Decimal']
  readonly turbotaEndsAt: Maybe<Scalars['DateTime']>
}

export interface NotificationOwnerChangedVacancySnapshot extends NotificationCommonVacancySnapshotInterface {
  readonly __typename?: 'NotificationOwnerChangedVacancySnapshot'
  readonly city: City
  readonly id: Scalars['ID']
  readonly title: Scalars['String']
}

export interface NotificationVacancyEndingCountSnapshot {
  readonly __typename?: 'NotificationVacancyEndingCountSnapshot'
  readonly count: Scalars['Int']
  readonly daysUntilEnd: Scalars['Int']
}

/** A connection to a list of items. */
export interface NotificationsConnection {
  readonly __typename?: 'NotificationsConnection'
  /** A list of edges. */
  readonly edges: Maybe<ReadonlyArray<NotificationsEdge>>
  /** A flattened list of the nodes. */
  readonly nodes: Maybe<ReadonlyArray<Notification>>
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']
}

/** An edge in a connection. */
export interface NotificationsEdge {
  readonly __typename?: 'NotificationsEdge'
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']
  /** The item at the end of the edge. */
  readonly node: Notification
}

export interface NotificationsError {
  readonly message: Scalars['String']
}

export interface NovaPoshtaAddCounterpartyOutput {
  readonly __typename?: 'NovaPoshtaAddCounterpartyOutput'
  readonly errorText: Maybe<Scalars['String']>
  readonly isSuccess: Scalars['Boolean']
  readonly result: Maybe<NovaPoshtaCounterpartyGraphType>
}

export interface NovaPoshtaAddDocumentInput {
  readonly cityRecipient: Scalars['String']
  readonly contactRecipient: Scalars['String']
  readonly cost: Scalars['Int']
  readonly deliveryType: GiftDeliveryTypeEnum
  readonly description?: InputMaybe<Scalars['String']>
  readonly giftRequest: Scalars['ID']
  readonly optionsSeat: ReadonlyArray<InputMaybe<NovaPoshtaSeatInput>>
  readonly preferredDeliveryDate?: InputMaybe<Scalars['DateTime']>
  readonly recipient: Scalars['String']
  readonly recipientAddress: Scalars['String']
  readonly recipientsPhone: Scalars['String']
  readonly senderPhone: Scalars['String']
  readonly senderRef: Scalars['String']
}

export interface NovaPoshtaAddDocumentOutput {
  readonly __typename?: 'NovaPoshtaAddDocumentOutput'
  readonly deliveryCost: Maybe<Scalars['Int']>
  readonly documentNumber: Maybe<Scalars['String']>
  readonly documentRef: Maybe<Scalars['String']>
  readonly errorText: Maybe<Scalars['String']>
  readonly isSuccess: Scalars['Boolean']
}

export interface NovaPoshtaAddDocumentToRegisterDetailInput {
  readonly documentRef: Scalars['String']
  readonly requestId: Scalars['ID']
}

export interface NovaPoshtaAddDocumentToRegisterInput {
  readonly details: ReadonlyArray<InputMaybe<NovaPoshtaAddDocumentToRegisterDetailInput>>
  readonly registerRef?: InputMaybe<Scalars['String']>
}

export interface NovaPoshtaAddDocumentToRegisterOutput {
  readonly __typename?: 'NovaPoshtaAddDocumentToRegisterOutput'
  readonly errorText: Maybe<Scalars['String']>
  readonly isSuccess: Scalars['Boolean']
  readonly registerDate: Maybe<Scalars['Date']>
  readonly registerNumber: Maybe<Scalars['String']>
  readonly registerRef: Maybe<Scalars['String']>
}

export interface NovaPoshtaAddGiftAddressOutput {
  readonly __typename?: 'NovaPoshtaAddGiftAddressOutput'
  readonly addressRef: Maybe<Scalars['String']>
  readonly contactRef: Maybe<Scalars['String']>
  readonly isSuccess: Scalars['Boolean']
  readonly message: Maybe<Scalars['String']>
}

export enum NovaPoshtaAddressTypeEnum {
  Doors = 'DOORS',
  Warehouse = 'WAREHOUSE'
}

export interface NovaPoshtaCity {
  readonly __typename?: 'NovaPoshtaCity'
  readonly area: Scalars['String']
  readonly deliveryCity: Scalars['String']
  readonly hasAddressDeliveryAllowed: Scalars['Boolean']
  readonly hasStreetsAvailability: Scalars['Boolean']
  readonly mainDescription: Scalars['String']
  readonly present: Scalars['String']
  readonly ref: Scalars['String']
  readonly region: Scalars['String']
  readonly settlementTypeCode: Scalars['String']
}

export interface NovaPoshtaCounterpartyGraphType {
  readonly __typename?: 'NovaPoshtaCounterpartyGraphType'
  readonly additionalPhone: Maybe<Scalars['String']>
  readonly addresses: Maybe<NovaPoshtaFullAddressGraphType>
  readonly contactPersonNote: Maybe<Scalars['String']>
  readonly contactPersonRef: Scalars['String']
  readonly counterpartyRef: Scalars['String']
  readonly counterpartyType: Maybe<NovaPosthaCounterpartyTypesGraphEnum>
  readonly description: Scalars['String']
  readonly edrpou: Scalars['String']
  readonly firstName: Scalars['String']
  readonly lastName: Scalars['String']
  readonly middleName: Scalars['String']
  readonly ownershipForm: Scalars['String']
  readonly phone: Scalars['String']
  readonly ref: Scalars['String']
}

export interface NovaPoshtaDeleteDocumentOutput {
  readonly __typename?: 'NovaPoshtaDeleteDocumentOutput'
  readonly isSuccess: Scalars['Boolean']
  readonly message: Maybe<Scalars['String']>
}

export interface NovaPoshtaDocument {
  readonly __typename?: 'NovaPoshtaDocument'
  readonly additionalInformation: Maybe<Scalars['String']>
  readonly cityRecipient: Maybe<Scalars['String']>
  readonly cityRecipientRef: Scalars['String']
  readonly contactRecipient: Maybe<Scalars['String']>
  readonly contactRecipientRef: Scalars['String']
  readonly cost: Scalars['Int']
  readonly costOnSite: Scalars['Int']
  readonly customerNote: Maybe<Scalars['String']>
  readonly dateTime: Scalars['DateTime']
  readonly description: Maybe<Scalars['String']>
  readonly giftRequest: Maybe<Scalars['Int']>
  readonly isPrinted: Scalars['Boolean']
  readonly note: Maybe<Scalars['String']>
  readonly number: Scalars['String']
  readonly preferredDeliveryDate: Maybe<Scalars['DateTime']>
  readonly recipientAddress: Maybe<Scalars['String']>
  readonly recipientAddressDescription: Maybe<Scalars['String']>
  readonly recipientAddressRef: Maybe<Scalars['String']>
  readonly recipientContactPerson: Maybe<Scalars['String']>
  readonly recipientContactPhone: Maybe<Scalars['String']>
  readonly recipientEDRPOU: Maybe<Scalars['String']>
  readonly recipientRef: Scalars['String']
  readonly recipientWarehouseNumber: Maybe<Scalars['String']>
  readonly recipientsPhone: Scalars['String']
  readonly ref: Scalars['String']
  readonly seats: ReadonlyArray<NovaPoshtaOptionSeat>
  readonly seatsAmount: Scalars['Int']
  readonly serviceType: NovaPoshtaServiceTypeEnum
  readonly state: Scalars['Int']
  readonly volumeWeight: Maybe<Scalars['String']>
  readonly weight: Scalars['Float']
}

export interface NovaPoshtaDoorsAddressGraphType {
  readonly __typename?: 'NovaPoshtaDoorsAddressGraphType'
  readonly addressName: Scalars['String']
  readonly areaDescription: Scalars['String']
  readonly buildingNumber: Scalars['String']
  readonly cityRef: Scalars['String']
  readonly description: Scalars['String']
  readonly flat: Maybe<Scalars['String']>
  readonly floor: Maybe<Scalars['String']>
  readonly note: Maybe<Scalars['String']>
  readonly ref: Scalars['String']
  readonly regionDescription: Scalars['String']
  readonly settlementDescription: Scalars['String']
  readonly settlementRef: Scalars['String']
  readonly streetDescription: Scalars['String']
  readonly streetRef: Scalars['String']
  readonly type: Scalars['String']
}

export interface NovaPoshtaFullAddressGraphType {
  readonly __typename?: 'NovaPoshtaFullAddressGraphType'
  readonly doorsAddresses: ReadonlyArray<NovaPoshtaDoorsAddressGraphType>
  readonly warehouseAddresses: ReadonlyArray<NovaPoshtaWarehouseAddressGraphType>
}

export interface NovaPoshtaNewAddedDocument {
  readonly __typename?: 'NovaPoshtaNewAddedDocument'
  readonly cityRecipientDescription: Maybe<Scalars['String']>
  readonly contactRecipient: Scalars['String']
  readonly costOnSite: Scalars['Int']
  readonly dateTime: Scalars['DateTime']
  readonly giftRequest: Maybe<Scalars['Int']>
  readonly intDocNumber: Scalars['String']
  readonly recipientAddressDescription: Maybe<Scalars['String']>
  readonly recipientContactPerson: Maybe<Scalars['String']>
  readonly recipientContactPhone: Maybe<Scalars['String']>
  readonly recipientsPhone: Maybe<Scalars['String']>
  readonly ref: Scalars['String']
  readonly seatsAmount: Scalars['Int']
  readonly state: Scalars['ID']
}

export interface NovaPoshtaOptionSeat {
  readonly __typename?: 'NovaPoshtaOptionSeat'
  readonly volumetricHeight: Scalars['Float']
  readonly volumetricLength: Scalars['Float']
  readonly volumetricVolume: Scalars['Float']
  readonly volumetricWeight: Scalars['Float']
  readonly volumetricWidth: Scalars['Float']
  readonly weight: Scalars['Float']
}

export interface NovaPoshtaRegisterDocument {
  readonly __typename?: 'NovaPoshtaRegisterDocument'
  readonly cityRecipientDescription: Scalars['String']
  readonly date: Scalars['DateTime']
  readonly number: Scalars['String']
  readonly recipientAddressDescription: Scalars['String']
  readonly recipientContactPersonFullName: Scalars['String']
  readonly recipientFullName: Scalars['String']
  readonly recipientPhone: Scalars['String']
  readonly ref: Scalars['String']
  readonly stateName: Scalars['String']
}

export interface NovaPoshtaRegisterList {
  readonly __typename?: 'NovaPoshtaRegisterList'
  readonly count: Scalars['Int']
  readonly date: Scalars['DateTime']
  readonly description: Maybe<Scalars['String']>
  readonly isPrinted: Scalars['Boolean']
  readonly number: Scalars['String']
  readonly ref: Scalars['String']
}

export interface NovaPoshtaRegisterListConnection {
  readonly __typename?: 'NovaPoshtaRegisterListConnection'
  readonly edges: Maybe<ReadonlyArray<Maybe<NovaPoshtaRegisterListEdge>>>
  readonly items: Maybe<ReadonlyArray<Maybe<NovaPoshtaRegisterList>>>
  readonly pageInfo: PageInfo
  readonly totalCount: Maybe<Scalars['Int']>
}

export interface NovaPoshtaRegisterListEdge {
  readonly __typename?: 'NovaPoshtaRegisterListEdge'
  readonly cursor: Scalars['String']
  readonly node: Maybe<NovaPoshtaRegisterList>
}

export interface NovaPoshtaSeatInput {
  readonly volumetricHeight: Scalars['Int']
  readonly volumetricLength: Scalars['Int']
  readonly volumetricVolume?: InputMaybe<Scalars['Float']>
  readonly volumetricWidth: Scalars['Int']
  readonly weight: Scalars['Float']
}

export enum NovaPoshtaServiceTypeEnum {
  DoorsDoors = 'DOORS_DOORS',
  DoorsWarehouse = 'DOORS_WAREHOUSE',
  WarehouseDoors = 'WAREHOUSE_DOORS',
  WarehouseWarehouse = 'WAREHOUSE_WAREHOUSE'
}

export enum NovaPoshtaStateEnum {
  AddressChanged = 'ADDRESS_CHANGED',
  BackwardDelivery = 'BACKWARD_DELIVERY',
  CannotContactRecipient = 'CANNOT_CONTACT_RECIPIENT',
  Delivered = 'DELIVERED',
  InTransit = 'IN_TRANSIT',
  New = 'NEW',
  OnWarehouse = 'ON_WAREHOUSE',
  Rejected = 'REJECTED'
}

export interface NovaPoshtaStreet {
  readonly __typename?: 'NovaPoshtaStreet'
  readonly present: Scalars['String']
  readonly settlementStreetDescription: Scalars['String']
  readonly settlementStreetRef: Scalars['String']
  readonly streetsTypeDescription: Scalars['String']
}

export interface NovaPoshtaUpdateDocumentInput {
  readonly cityRecipient: Scalars['String']
  readonly contactRecipient: Scalars['String']
  readonly cost: Scalars['Int']
  readonly deliveryType: GiftDeliveryTypeEnum
  readonly description?: InputMaybe<Scalars['String']>
  readonly giftRequest: Scalars['ID']
  readonly optionsSeat: ReadonlyArray<InputMaybe<NovaPoshtaSeatInput>>
  readonly preferredDeliveryDate?: InputMaybe<Scalars['DateTime']>
  readonly recipient: Scalars['String']
  readonly recipientAddress: Scalars['String']
  readonly recipientsPhone: Scalars['String']
  readonly ref: GiftDeliveryTypeEnum
  readonly senderPhone: Scalars['String']
  readonly senderRef: Scalars['String']
}

export interface NovaPoshtaWarehouse {
  readonly __typename?: 'NovaPoshtaWarehouse'
  readonly description: Maybe<Scalars['String']>
  readonly number: Scalars['String']
  readonly ref: Scalars['String']
  readonly typeOfWarehouse: Scalars['String']
}

export interface NovaPoshtaWarehouseAddressGraphType {
  readonly __typename?: 'NovaPoshtaWarehouseAddressGraphType'
  readonly addressDescription: Maybe<Scalars['String']>
  readonly cityDescription: Maybe<Scalars['String']>
  readonly cityRef: Scalars['String']
  readonly ref: Scalars['String']
  readonly typeOfWarehouse: Scalars['String']
  readonly warehouseNumber: Scalars['String']
}

export enum NovaPosthaCounterpartyTypesGraphEnum {
  Organization = 'ORGANIZATION',
  PrivatePerson = 'PRIVATE_PERSON'
}

export interface Objection {
  readonly __typename?: 'Objection'
  readonly addDate: Scalars['DateTimeOffset']
  readonly description: Scalars['String']
  readonly labels: ReadonlyArray<Scalars['String']>
  readonly name: Scalars['String']
}

export interface OfferVacancyToApplyInput {
  readonly applyId: Scalars['ID']
  readonly message?: InputMaybe<Scalars['String']>
  readonly vacancyId: Scalars['ID']
}

export interface OfferVacancyToApplyOutput {
  readonly __typename?: 'OfferVacancyToApplyOutput'
  readonly apply: Maybe<Apply>
  readonly isSuccess: Scalars['Boolean']
}

export interface OfferedVacancyCvDbEmployerEvent {
  readonly __typename?: 'OfferedVacancyCvDbEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly cv: CandidateResumeEmployerEventSnapshot
  readonly id: Scalars['ID']
  readonly performer: PerformerEmployerEventUnion
  readonly vacancyCity: City
  readonly vacancyId: Scalars['ID']
  readonly vacancyName: Scalars['String']
}

export interface OpenedContactOrVacancyOfferedEmployerAchievement extends EmployerAchievement {
  readonly __typename?: 'OpenedContactOrVacancyOfferedEmployerAchievement'
  readonly currentCount: Scalars['Int']
  readonly isDone: Scalars['Boolean']
  readonly targetCount: Scalars['Int']
  readonly type: EmployerAchievementType
}

export interface OpenedContactsCvDbEmployerEvent {
  readonly __typename?: 'OpenedContactsCvDbEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly cv: CandidateResumeEmployerEventSnapshot
  readonly id: Scalars['ID']
  readonly performer: PerformerEmployerEventUnion
}

export interface Order {
  readonly __typename?: 'Order'
  readonly activatedAt: Maybe<Scalars['DateTime']>
  readonly canBeActivatedByTurbota: Scalars['Boolean']
  readonly catalogPriceSum: Scalars['Float']
  readonly clientPrice: Scalars['Float']
  readonly companyId: Scalars['ID']
  readonly createInvoice: CreateInvoiceOutput
  readonly discountActionSum: Scalars['Float']
  readonly discountOptSum: Scalars['Float']
  readonly discountPointsSum: Scalars['Float']
  readonly discounts: ReadonlyArray<Discount>
  readonly employee: Maybe<Employee>
  readonly generateExternalPaymentUrl: Maybe<GenerateExternalPaymentUrlOutput>
  readonly hasIrrelevantPromotionalOffer: Scalars['Boolean']
  readonly id: Scalars['ID']
  readonly invoices: Maybe<ReadonlyArray<Maybe<Invoice>>>
  readonly isCreatedByManager: Scalars['Boolean']
  readonly isFullyConfigured: Scalars['Boolean']
  readonly isPaidByCard: Scalars['Boolean']
  readonly isValid: Scalars['Boolean']
  readonly lastPaidAt: Maybe<Scalars['DateTime']>
  readonly orderedAt: Scalars['DateTime']
  readonly paidSum: Scalars['Float']
  readonly payWithCard: PayWithCardResponse
  readonly payWithExternalWallet: PayWithExternalWalletResponse
  readonly paymentMethod: PaymentMethods
  readonly paymentState: OrderPaymentState
  readonly positions: Maybe<ReadonlyArray<Maybe<OrderPosition>>>
  readonly state: OrderState
  readonly totalPrice: Scalars['Float']
  readonly turbotaEndsAt: Maybe<Scalars['DateTime']>
  readonly type: OrderType
  readonly wasActivatedByTurbota: Scalars['Boolean']
}

export interface OrderGenerateExternalPaymentUrlArgs {
  errorUrl: InputMaybe<Scalars['String']>
  successUrl: InputMaybe<Scalars['String']>
}

export interface OrderPayWithCardArgs {
  cardId: Scalars['Guid']
  errorUrl: InputMaybe<Scalars['String']>
  successUrl: InputMaybe<Scalars['String']>
}

export interface OrderPayWithExternalWalletArgs {
  errorUrl: InputMaybe<Scalars['String']>
  paymentData: Scalars['String']
  successUrl: InputMaybe<Scalars['String']>
  walletType: ExternalWalletEnum
}

export interface OrderActivatedByTurbotaDetails {
  readonly __typename?: 'OrderActivatedByTurbotaDetails'
  readonly detailType: SeekerNotificationDetailTypeEnum
  readonly orderSnapshot: NotificationOrderSnapshot
}

export interface OrderAlreadyActivatedError {
  readonly __typename?: 'OrderAlreadyActivatedError'
  readonly message: Scalars['String']
}

export interface OrderCanNotBeActivatedByTurbotaError {
  readonly __typename?: 'OrderCanNotBeActivatedByTurbotaError'
  readonly message: Scalars['String']
}

export interface OrderCartMutation {
  readonly __typename?: 'OrderCartMutation'
  readonly create: CreateOrderFromCartResponse
}

export interface OrderCartMutationCreateArgs {
  hasToBeConfiguredInDb: InputMaybe<Scalars['Boolean']>
}

export interface OrderConnection {
  readonly __typename?: 'OrderConnection'
  readonly edges: Maybe<ReadonlyArray<Maybe<OrderEdge>>>
  readonly items: Maybe<ReadonlyArray<Maybe<Order>>>
  readonly pageInfo: PageInfo
  readonly totalCount: Maybe<Scalars['Int']>
}

export interface OrderEdge {
  readonly __typename?: 'OrderEdge'
  readonly cursor: Scalars['String']
  readonly node: Maybe<Order>
}

export interface OrderFreemiumServicesAccruedServiceEmployerEvent {
  readonly __typename?: 'OrderFreemiumServicesAccruedServiceEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly order: Maybe<Order>
  readonly performer: SystemEventPerformer
}

export enum OrderPaymentState {
  Fully = 'FULLY',
  Partially = 'PARTIALLY',
  Unpaid = 'UNPAID'
}

export interface OrderPosition {
  readonly __typename?: 'OrderPosition'
  readonly catalogPrice: Scalars['Float']
  readonly catalogPricePerItem: Scalars['Float']
  readonly clientPrice: Scalars['Float']
  readonly clientPricePerItem: Scalars['Float']
  readonly count: Maybe<Scalars['Int']>
  readonly details: OrderPositionServiceDetailsUnion
  readonly hasDiscount: Scalars['Boolean']
}

export interface OrderPositionAdditionalServiceDetails {
  readonly __typename?: 'OrderPositionAdditionalServiceDetails'
  readonly service: CatalogService
}

export interface OrderPositionCvDbServiceDetails {
  readonly __typename?: 'OrderPositionCvDbServiceDetails'
  readonly city: City
  readonly period: Maybe<ServiceUsagePeriod>
  readonly rubric: Rubric
  readonly service: CatalogService
}

export interface OrderPositionHotServiceDetails {
  readonly __typename?: 'OrderPositionHotServiceDetails'
  readonly service: CatalogService
}

export interface OrderPositionLogoInPortalServiceDetails {
  readonly __typename?: 'OrderPositionLogoInPortalServiceDetails'
  readonly service: CatalogService
}

export interface OrderPositionLogoInRubricServiceDetails {
  readonly __typename?: 'OrderPositionLogoInRubricServiceDetails'
  readonly service: CatalogService
}

export interface OrderPositionLogoOnMainServiceDetails {
  readonly __typename?: 'OrderPositionLogoOnMainServiceDetails'
  readonly service: CatalogService
}

export interface OrderPositionMarketingServiceDetails {
  readonly __typename?: 'OrderPositionMarketingServiceDetails'
  readonly service: CatalogService
}

export interface OrderPositionRisingUpServiceDetails {
  readonly __typename?: 'OrderPositionRisingUpServiceDetails'
  readonly service: CatalogService
}

export type OrderPositionServiceDetailsUnion =
  | OrderPositionAdditionalServiceDetails
  | OrderPositionCvDbServiceDetails
  | OrderPositionHotServiceDetails
  | OrderPositionLogoInPortalServiceDetails
  | OrderPositionLogoInRubricServiceDetails
  | OrderPositionLogoOnMainServiceDetails
  | OrderPositionMarketingServiceDetails
  | OrderPositionRisingUpServiceDetails
  | OrderPositionVacancyPackageServiceDetails
  | OrderPositionVacancyPublicationServiceDetails

export interface OrderPositionVacancyPackageServiceDetails {
  readonly __typename?: 'OrderPositionVacancyPackageServiceDetails'
  readonly service: CatalogService
}

export interface OrderPositionVacancyPublicationServiceDetails {
  readonly __typename?: 'OrderPositionVacancyPublicationServiceDetails'
  readonly service: CatalogService
}

export interface OrderServicesAccruedServiceEmployerEvent {
  readonly __typename?: 'OrderServicesAccruedServiceEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly order: Maybe<Order>
  readonly performer: SystemEventPerformer
}

export enum OrderState {
  Activated = 'ACTIVATED',
  ApprovedProject = 'APPROVED_PROJECT',
  Completed = 'COMPLETED',
  Project = 'PROJECT',
  WaitingForActivation = 'WAITING_FOR_ACTIVATION'
}

export type OrderTurbotaActivationError = OrderAlreadyActivatedError | OrderCanNotBeActivatedByTurbotaError | OrderWasActivatedByTurbotaError

export interface OrderTurbotaActivationInput {
  readonly orderId: Scalars['ID']
}

export interface OrderTurbotaActivationOutput {
  readonly __typename?: 'OrderTurbotaActivationOutput'
  readonly error: Maybe<OrderTurbotaActivationError>
  readonly isSuccessful: Scalars['Boolean']
  readonly order: Maybe<Order>
}

export enum OrderType {
  Bonus = 'BONUS',
  Converted = 'CONVERTED',
  ECommerce = 'E_COMMERCE',
  Regular = 'REGULAR',
  Test = 'TEST'
}

export interface OrderWasActivatedByTurbotaError {
  readonly __typename?: 'OrderWasActivatedByTurbotaError'
  readonly message: Scalars['String']
}

export interface OrderedAdditionalService {
  readonly __typename?: 'OrderedAdditionalService'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly paidAt: Maybe<Scalars['Date']>
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly serviceId: Scalars['ID']
  readonly state: Maybe<ServiceState>
  readonly total: Maybe<Scalars['Int']>
  readonly type: Maybe<ServiceType>
}

export interface OrderedCvDbService {
  readonly __typename?: 'OrderedCvDbService'
  readonly activateTo: Maybe<Scalars['Date']>
  readonly city: City
  readonly contactsAccrualPeriod: Maybe<ContactsAccrualPeriod>
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly packagesCount: Maybe<Scalars['Int']>
  readonly paidAt: Maybe<Scalars['Date']>
  readonly rubric: Rubric
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly serviceId: Scalars['ID']
  readonly state: Maybe<ServiceState>
  readonly totalContacts: Maybe<Scalars['Int']>
  readonly type: Maybe<ServiceType>
  readonly usagePeriod: Maybe<ServiceUsagePeriod>
}

export interface OrderedHotService {
  readonly __typename?: 'OrderedHotService'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly packagesCount: Maybe<Scalars['Int']>
  readonly paidAt: Maybe<Scalars['Date']>
  readonly parentServiceId: Maybe<Scalars['ID']>
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly serviceId: Scalars['ID']
  readonly servicesPerPackage: Maybe<Scalars['Int']>
  readonly state: Maybe<ServiceState>
  readonly totalTicketsCount: Maybe<Scalars['Int']>
  readonly type: Maybe<ServiceType>
}

export interface OrderedLogoInPortalService {
  readonly __typename?: 'OrderedLogoInPortalService'
  readonly branch: LogoPortalBranch
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly paidAt: Maybe<Scalars['Date']>
  readonly portal: LogoPortal
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly serviceId: Scalars['ID']
  readonly state: Maybe<ServiceState>
  readonly total: Maybe<Scalars['Int']>
  readonly type: Maybe<ServiceType>
}

export interface OrderedLogoInRubricService {
  readonly __typename?: 'OrderedLogoInRubricService'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly paidAt: Maybe<Scalars['Date']>
  readonly rubric: Rubric
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly serviceId: Scalars['ID']
  readonly state: Maybe<ServiceState>
  readonly total: Maybe<Scalars['Int']>
  readonly type: Maybe<ServiceType>
}

export interface OrderedMarketingService {
  readonly __typename?: 'OrderedMarketingService'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly paidAt: Maybe<Scalars['Date']>
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly serviceId: Scalars['ID']
  readonly state: Maybe<ServiceState>
  readonly total: Maybe<Scalars['Int']>
  readonly type: Maybe<ServiceType>
}

export interface OrderedRisingUpService {
  readonly __typename?: 'OrderedRisingUpService'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly paidAt: Maybe<Scalars['Date']>
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly serviceId: Scalars['ID']
  readonly state: Maybe<ServiceState>
  readonly totalTicketsCount: Maybe<Scalars['Int']>
  readonly type: Maybe<ServiceType>
}

export interface OrderedVacancyPackageService {
  readonly __typename?: 'OrderedVacancyPackageService'
  readonly activateTo: Maybe<Scalars['Date']>
  readonly additionalServices: ReadonlyArray<Maybe<ServiceUnited>>
  readonly contactsAccrualPeriod: Maybe<ContactsAccrualPeriod>
  readonly contactsCount: Maybe<Scalars['Int']>
  readonly id: Scalars['ID']
  readonly isPackage: Maybe<Scalars['Boolean']>
  readonly isUnlimitedContacts: Maybe<Scalars['Boolean']>
  readonly name: Scalars['String']
  readonly packagesCount: Maybe<Scalars['Int']>
  readonly paidAt: Maybe<Scalars['Date']>
  readonly publicationType: Maybe<VacancyPublicationType>
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly serviceId: Scalars['ID']
  readonly state: Maybe<ServiceState>
  readonly supportedRegions: ReadonlyArray<City>
  readonly totalVacancyCount: Maybe<Scalars['Int']>
  readonly type: Maybe<ServiceType>
  readonly vacanciesPerPackage: Maybe<Scalars['Int']>
  readonly vacancyMailingCount: Maybe<Scalars['Int']>
  readonly vacancyPublishDays: Maybe<Scalars['Int']>
  readonly vacancyRisingCount: Maybe<Scalars['Int']>
}

export interface OrderedVacancyPublicationService {
  readonly __typename?: 'OrderedVacancyPublicationService'
  readonly id: Scalars['ID']
  readonly isPackage: Maybe<Scalars['Boolean']>
  readonly name: Scalars['String']
  readonly paidAt: Maybe<Scalars['Date']>
  readonly parentServiceId: Maybe<Scalars['ID']>
  readonly publicationType: Maybe<VacancyPublicationType>
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly serviceId: Scalars['ID']
  readonly state: Maybe<ServiceState>
  readonly supportedRegions: ReadonlyArray<City>
  readonly totalVacancyCount: Maybe<Scalars['Int']>
  readonly type: Maybe<ServiceType>
  readonly vacancyMailingCount: Maybe<Scalars['Int']>
  readonly vacancyPublishDays: Maybe<Scalars['Int']>
  readonly vacancyRisingCount: Maybe<Scalars['Int']>
}

export enum OtherSiteEnum {
  Djinni = 'DJINNI',
  Dou = 'DOU',
  Grc = 'GRC',
  Work = 'WORK'
}

export interface OtherSiteLink {
  readonly __typename?: 'OtherSiteLink'
  readonly source: OtherSiteEnum
  readonly vacancyCount: Scalars['Int']
  readonly vacancyLink: Scalars['String']
}

export interface OwnerChangedVacancyEmployerEvent {
  readonly __typename?: 'OwnerChangedVacancyEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly currentOwner: EmployerEventSnapshot
  readonly id: Scalars['ID']
  readonly performer: PerformerEmployerEventUnion
  readonly previousOwner: EmployerEventSnapshot
  readonly vacancyCity: City
  readonly vacancyId: Scalars['ID']
  readonly vacancyName: Scalars['String']
}

export interface PackageServiceEmployerEventSnapshot {
  readonly __typename?: 'PackageServiceEmployerEventSnapshot'
  readonly activeTo: Scalars['DateTime']
  readonly addedAt: Scalars['DateTime']
  readonly catalogService: CatalogService
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly publicationType: VacancyPublicationTypeWrapper
  readonly restServices: Scalars['Int']
  readonly totalServices: Scalars['Int']
}

export interface PageInfo {
  readonly __typename?: 'PageInfo'
  readonly endCursor: Maybe<Scalars['String']>
  readonly hasNextPage: Scalars['Boolean']
  readonly hasPreviousPage: Scalars['Boolean']
  readonly startCursor: Maybe<Scalars['String']>
}

export interface PaidOrderService {
  readonly __typename?: 'PaidOrderService'
  readonly fixedQuantity: Scalars['Int']
  readonly id: Scalars['ID']
  readonly isAction: Scalars['Boolean']
  readonly isShow: Scalars['Boolean']
  readonly minimumQuantity: Scalars['Int']
  readonly name: Scalars['String']
  readonly nameUkr: Scalars['String']
  readonly openContactCount: Scalars['Int']
  readonly packageCount: Scalars['Int']
  readonly serviceGroup: Scalars['ID']
  readonly serviceType: Scalars['ID']
  readonly unitIds: ReadonlyArray<ServiceUnitEnum>
}

export interface ParsedCv {
  readonly __typename?: 'ParsedCv'
  readonly assinged: Maybe<Scalars['String']>
  readonly assingedName: Maybe<Scalars['String']>
  readonly comments: ReadonlyArray<ParsedCvComment>
  readonly id: Scalars['ID']
  readonly labels: ReadonlyArray<ParsedCvLabel>
  readonly name: Scalars['String']
  readonly nameTags: ReadonlyArray<SynonymTag>
  readonly rubrics: ReadonlyArray<ParsedCvRubric>
  readonly state: ParsedCvStateEnum
  readonly text: Scalars['String']
  readonly textTags: ReadonlyArray<SynonymTag>
}

export interface ParsedCvComment {
  readonly __typename?: 'ParsedCvComment'
  readonly author: Scalars['String']
  readonly authorEmail: Scalars['String']
  readonly date: Scalars['DateTime']
  readonly text: Scalars['String']
}

export interface ParsedCvConnection {
  readonly __typename?: 'ParsedCvConnection'
  readonly edges: Maybe<ReadonlyArray<Maybe<BriefParsedCvEdge>>>
  readonly items: Maybe<ReadonlyArray<BriefParsedCv>>
  readonly pageInfo: PageInfo
  readonly totalCount: Maybe<Scalars['Int']>
}

export interface ParsedCvLabel {
  readonly __typename?: 'ParsedCvLabel'
  readonly end: Scalars['Int']
  readonly labelType: Scalars['String']
  readonly start: Scalars['Int']
  readonly text: Scalars['String']
}

export interface ParsedCvLabelInput {
  readonly end: Scalars['Int']
  readonly labelType: Scalars['String']
  readonly start: Scalars['Int']
  readonly text?: InputMaybe<Scalars['String']>
}

export interface ParsedCvRubric {
  readonly __typename?: 'ParsedCvRubric'
  readonly experience: Experience
  readonly rubric: Rubric
  readonly subrubric: Subrubric
}

export enum ParsedCvStateEnum {
  Approved = 'APPROVED',
  New = 'NEW',
  Processing = 'PROCESSING',
  Rejected = 'REJECTED'
}

export interface PasswordConfirmationFailedError {
  readonly __typename?: 'PasswordConfirmationFailedError'
  readonly message: Scalars['String']
}

export type PayWithCardErrorUnion = InvalidError | ServerError | UserIsNotAuthenticatedError

export interface PayWithCardResponse {
  readonly __typename?: 'PayWithCardResponse'
  readonly errors: ReadonlyArray<PayWithCardErrorUnion>
  readonly isSuccess: Scalars['Boolean']
  readonly validationUrl: Maybe<Scalars['String']>
}

export type PayWithExternalWalletErrorUnion = InvalidError | ServerError | UserIsNotAuthenticatedError

export interface PayWithExternalWalletResponse {
  readonly __typename?: 'PayWithExternalWalletResponse'
  readonly errors: ReadonlyArray<PayWithExternalWalletErrorUnion>
  readonly validationUrl: Maybe<Scalars['String']>
}

export interface Payer {
  readonly __typename?: 'Payer'
  readonly accountNum: Maybe<Scalars['String']>
  readonly address: Scalars['String']
  readonly certificate: Maybe<Scalars['String']>
  readonly contractDate: Maybe<Scalars['DateTime']>
  readonly edrpou: Scalars['String']
  readonly gender: Maybe<Gender>
  readonly iban: Maybe<Scalars['String']>
  readonly id: Scalars['ID']
  readonly ipn: Scalars['String']
  readonly isLegal: Maybe<Scalars['Boolean']>
  readonly legalAddress: Scalars['String']
  readonly mfo: Maybe<Scalars['Int']>
  readonly name: Scalars['String']
  readonly payerResponsible: PayerResponsibleEnum
  readonly payerResponsiblePost: Scalars['String']
  readonly physicalPassport: Maybe<PayerPassport>
  readonly proxyDate: Maybe<Scalars['Date']>
  readonly proxyNumber: Maybe<Scalars['String']>
  readonly responsibleBasis: Scalars['Int']
  readonly responsibleFullName: Scalars['String']
  readonly responsibleName: Scalars['String']
  readonly responsiblePatronymic: Scalars['String']
  readonly responsibleSurname: Scalars['String']
}

export interface PayerEditInput {
  readonly address: Scalars['String']
  readonly gender: Gender
  readonly id?: InputMaybe<Scalars['ID']>
  readonly ipn: Scalars['String']
  readonly isLegal: Scalars['Boolean']
  readonly legalAddress: Scalars['String']
  readonly legalPayer?: InputMaybe<LegalPayerInput>
  readonly notebookId: Scalars['ID']
  readonly passport?: InputMaybe<PayerPassportInput>
  readonly responsibleName: Scalars['String']
  readonly responsiblePatronymic: Scalars['String']
  readonly responsibleSurname: Scalars['String']
}

export interface PayerPassport {
  readonly __typename?: 'PayerPassport'
  readonly isNewFormat: Scalars['Boolean']
  readonly passportIssuingAuthority: Scalars['String']
  readonly passportIssuingDate: Maybe<Scalars['Date']>
  readonly passportNumber: Scalars['String']
}

export interface PayerPassportInput {
  readonly isNewFormat: Scalars['Boolean']
  readonly passportIssuingAuthority: Scalars['String']
  readonly passportIssuingDate?: InputMaybe<Scalars['Date']>
  readonly passportNumber: Scalars['String']
}

export enum PayerResponsibleEnum {
  AuthorizedPerson = 'AUTHORIZED_PERSON',
  Director = 'DIRECTOR',
  Other = 'OTHER',
  Unknown = 'UNKNOWN'
}

export type PaymentMethods = ExternalUrlPaymentMethod | WidgetPaymentMethod

export interface PaymentReceivedServiceEmployerEvent {
  readonly __typename?: 'PaymentReceivedServiceEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly hasFullyPaid: Scalars['Boolean']
  readonly id: Scalars['ID']
  readonly order: Maybe<Order>
  readonly paidInvoiceIdentifier: Scalars['String']
  readonly paidSum: Scalars['Float']
  readonly paymentType: PaymentReceivedTypeEmployerEventEnum
  readonly performer: PerformerEmployerEventUnion
}

export enum PaymentReceivedTypeEmployerEventEnum {
  Offline = 'OFFLINE',
  Online = 'ONLINE'
}

export enum PaymentSystemTypeEnum {
  Evopay = 'EVOPAY',
  Ipay = 'IPAY',
  Portmone = 'PORTMONE',
  WayForPay = 'WAY_FOR_PAY'
}

export type PerformerEmployerEventUnion = EmployerEventPerformer | ManagerEventPerformer | SystemEventPerformer

export interface Period {
  readonly __typename?: 'Period'
  readonly month: Maybe<Scalars['Int']>
  readonly year: Maybe<Scalars['Int']>
}

export interface PeriodAmount {
  readonly __typename?: 'PeriodAmount'
  readonly amount: Maybe<Scalars['Int']>
  readonly period: Maybe<Period>
}

export enum PeriodType {
  Month = 'MONTH',
  Week = 'WEEK'
}

export interface PersonalDiscountEvent extends EventInterface {
  readonly __typename?: 'PersonalDiscountEvent'
  readonly addDate: Scalars['DateTimeOffset']
  readonly assignment: Maybe<Assignment>
  readonly company: Maybe<CrmCompany>
  readonly daysLeft: Maybe<Scalars['Int']>
  readonly employee: Maybe<CrmEmployee>
  readonly eventType: EventTypeEnum
  readonly expirationDate: Maybe<Scalars['DateTimeOffset']>
  readonly id: Scalars['ID']
  readonly isArchieved: Scalars['Boolean']
  readonly isRead: Scalars['Boolean']
  readonly lifetimeDays: Scalars['Int']
  readonly state: EventStateEnum
  readonly topics: ReadonlyArray<Topic>
}

export interface PhoneConfirmationOutput {
  readonly __typename?: 'PhoneConfirmationOutput'
  readonly remainingAttempts: Scalars['Int']
  readonly status: PhoneConfirmationStatusEnum
}

export enum PhoneConfirmationStatusEnum {
  CodeExpired = 'CODE_EXPIRED',
  CodeInvalid = 'CODE_INVALID',
  Failed = 'FAILED',
  NoAttemptsLeft = 'NO_ATTEMPTS_LEFT',
  Success = 'SUCCESS'
}

export interface PhoneNumberConfirmedEmployerAchievement extends EmployerAchievement {
  readonly __typename?: 'PhoneNumberConfirmedEmployerAchievement'
  readonly isDone: Scalars['Boolean']
  readonly type: EmployerAchievementType
}

export interface PriceQueryOutput {
  readonly __typename?: 'PriceQueryOutput'
  readonly items: ReadonlyArray<CalculatePriceItem>
}

export interface ProfResume {
  readonly __typename?: 'ProfResume'
  readonly additionalEducations: ReadonlyArray<ResumeAdditionalEducation>
  readonly additionals: ReadonlyArray<ResumeAdditionalInfo>
  readonly city: City
  readonly cityDistricts: ReadonlyArray<CityDistrict>
  readonly contacts: ResumeContactInfo
  readonly diiaCertificate: Maybe<Scalars['Uri']>
  readonly educations: ReadonlyArray<ResumeEducation>
  readonly experiences: ReadonlyArray<ResumeExperience>
  readonly id: Scalars['ID']
  readonly isDisabledPerson: Scalars['Boolean']
  readonly languageSkills: ReadonlyArray<ResumeLanguageSkill>
  readonly militaryStatus: Maybe<MilitaryStatusEnum>
  readonly personal: ResumePersonalInfo
  readonly relocationCities: ReadonlyArray<City>
  readonly resumeFilling: Maybe<ResumeFilling>
  readonly rubrics: ReadonlyArray<Subrubric>
  readonly salary: Maybe<ResumeSalary>
  readonly schedules: ReadonlyArray<Schedule>
  readonly similarVacanciesCount: Maybe<Scalars['Long']>
  readonly skills: Scalars['String']
  readonly state: ResumeState
  readonly title: Maybe<Scalars['String']>
  readonly updateDate: Scalars['DateTime']
  readonly views: Maybe<ResumeViewsConnection>
}

export interface ProfResumeViewsArgs {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
}

export interface ProfResumeAddAdditionalEducationsInput {
  readonly educations: ReadonlyArray<ProfResumeAdditionalEducationInput>
  readonly resumeId: Scalars['ID']
}

export interface ProfResumeAddAdditionalInfoInput {
  readonly additionalInfo: ReadonlyArray<ProfResumeAdditionalInfoInput>
  readonly resumeId: Scalars['ID']
}

export interface ProfResumeAddEducationsInput {
  readonly educations: ReadonlyArray<ProfResumeEducationInput>
  readonly resumeId: Scalars['ID']
}

export interface ProfResumeAddExperiencesInput {
  readonly experiences: ReadonlyArray<ProfResumeExperienceInput>
  readonly resumeId: Scalars['ID']
}

export interface ProfResumeAdditionalEducationInput {
  readonly description?: InputMaybe<Scalars['String']>
  readonly location?: InputMaybe<Scalars['String']>
  readonly title: Scalars['String']
  readonly yearOfGraduation: Scalars['Int']
}

export interface ProfResumeAdditionalInfoInput {
  readonly description: Scalars['String']
  readonly id?: InputMaybe<Scalars['ID']>
  readonly name: Scalars['String']
}

export interface ProfResumeAdditionalInfosInput {
  readonly additionalInfos: ReadonlyArray<ProfResumeAdditionalInfoInput>
  readonly resumeId: Scalars['ID']
}

export interface ProfResumeBirthDateInput {
  readonly birthDate: Scalars['Date']
  readonly resumeId: Scalars['ID']
}

export interface ProfResumeCannotBePublished extends ResumeError {
  readonly __typename?: 'ProfResumeCannotBePublished'
  readonly message: Scalars['String']
}

export interface ProfResumeCityInput {
  readonly cityDistrictIds: ReadonlyArray<Scalars['Int']>
  readonly cityId: Scalars['Int']
  readonly relocationCityIds: ReadonlyArray<Scalars['Int']>
  readonly resumeId: Scalars['ID']
}

export interface ProfResumeContactInfoInput {
  readonly email?: InputMaybe<Scalars['String']>
  readonly emails?: InputMaybe<ReadonlyArray<Scalars['String']>>
  readonly phone?: InputMaybe<Scalars['String']>
  readonly phones?: InputMaybe<ReadonlyArray<Scalars['String']>>
  readonly portfolios?: InputMaybe<ReadonlyArray<Scalars['Uri']>>
  readonly socials?: InputMaybe<ReadonlyArray<ProfResumeSocialNetworkInput>>
}

export interface ProfResumeDeleteAdditionalEducationsInput {
  readonly educationIds: ReadonlyArray<Scalars['ID']>
  readonly resumeId: Scalars['ID']
}

export interface ProfResumeDeleteAdditionalInfoInput {
  readonly additionalInfoIds: ReadonlyArray<Scalars['ID']>
  readonly resumeId: Scalars['ID']
}

export interface ProfResumeDeleteAdditionalPhonesInput {
  readonly phones: ReadonlyArray<Scalars['String']>
  readonly resumeId: Scalars['ID']
}

export interface ProfResumeDeleteDiiaCertificateInput {
  readonly resumeId: Scalars['ID']
}

export interface ProfResumeDeleteDiiaCertificateOutput {
  readonly __typename?: 'ProfResumeDeleteDiiaCertificateOutput'
  readonly errors: Maybe<ReadonlyArray<DeleteSeekerProfResumeDiiaCertificateError>>
  readonly profResume: Maybe<ProfResume>
}

export interface ProfResumeDeleteEducationsInput {
  readonly educationIds: ReadonlyArray<Scalars['ID']>
  readonly resumeId: Scalars['ID']
}

export interface ProfResumeDeleteLanguageSkillsInput {
  readonly languageIds: ReadonlyArray<Scalars['ID']>
  readonly resumeId: Scalars['ID']
}

export interface ProfResumeDeletePortfolioInput {
  readonly portfolios: ReadonlyArray<Scalars['Uri']>
  readonly resumeId: Scalars['ID']
}

export interface ProfResumeDeleteSocialNetworksInput {
  readonly resumeId: Scalars['ID']
  readonly socials: ReadonlyArray<ProfResumeSocialNetworkInput>
}

export interface ProfResumeDiiaCertificateInput {
  readonly certificate?: InputMaybe<Scalars['Uri']>
  readonly resumeId: Scalars['ID']
}

export interface ProfResumeDisabilityInput {
  readonly isDisabledPerson: Scalars['Boolean']
  readonly resumeId: Scalars['ID']
}

export interface ProfResumeDoesNotBelongToSeeker extends ResumeError {
  readonly __typename?: 'ProfResumeDoesNotBelongToSeeker'
  readonly message: Scalars['String']
}

export interface ProfResumeDoesNotExist extends ResumeError {
  readonly __typename?: 'ProfResumeDoesNotExist'
  readonly message: Scalars['String']
}

export interface ProfResumeDuplicated extends ResumeError {
  readonly __typename?: 'ProfResumeDuplicated'
  readonly message: Scalars['String']
}

export interface ProfResumeEducationInput {
  readonly institutionTitle: Scalars['String']
  readonly level: EducationLevelEnum
  readonly location: Scalars['String']
  readonly speciality: Scalars['String']
  readonly yearOfGraduation: Scalars['Int']
}

export interface ProfResumeExperienceInput {
  readonly branchId?: InputMaybe<Scalars['Int']>
  readonly companyName?: InputMaybe<Scalars['String']>
  readonly description?: InputMaybe<Scalars['String']>
  readonly endWork?: InputMaybe<Scalars['Date']>
  readonly notebookCompanyId?: InputMaybe<Scalars['Int']>
  readonly position: Scalars['String']
  readonly startWork?: InputMaybe<Scalars['Date']>
}

export interface ProfResumeExperiencesDeleteInput {
  readonly experienceIds: ReadonlyArray<Scalars['ID']>
  readonly resumeId: Scalars['ID']
}

export interface ProfResumeFullNameInput {
  readonly fatherName: Scalars['String']
  readonly firstName: Scalars['String']
  readonly resumeId: Scalars['ID']
  readonly surname: Scalars['String']
}

export interface ProfResumeGenderInput {
  readonly gender: Gender
  readonly resumeId: Scalars['ID']
}

export interface ProfResumeInput {
  readonly additionalEducations?: InputMaybe<ReadonlyArray<ProfResumeAdditionalEducationInput>>
  readonly additionals?: InputMaybe<ReadonlyArray<ProfResumeAdditionalInfoInput>>
  readonly cityId?: InputMaybe<Scalars['ID']>
  readonly contacts?: InputMaybe<ProfResumeContactInfoInput>
  readonly diiaCertificate?: InputMaybe<Scalars['Uri']>
  readonly educations?: InputMaybe<ReadonlyArray<ProfResumeEducationInput>>
  readonly experiences?: InputMaybe<ReadonlyArray<ProfResumeExperienceInput>>
  readonly isDisabledPerson?: InputMaybe<Scalars['Boolean']>
  readonly languageSkills?: InputMaybe<ReadonlyArray<ProfResumeLanguageSkillInput>>
  readonly militaryStatus?: InputMaybe<MilitaryStatusEnum>
  readonly personal?: InputMaybe<ProfResumePersonalInfoInput>
  readonly position: Scalars['String']
  readonly relocationCitiesIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
  readonly rubricIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
  readonly salary?: InputMaybe<ProfResumeSalaryInput>
  readonly scheduleIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
  readonly skills?: InputMaybe<Scalars['String']>
}

export interface ProfResumeLanguageSkillInput {
  readonly canPassInterview: Scalars['Boolean']
  readonly certificate?: InputMaybe<Scalars['String']>
  readonly languageId: Scalars['ID']
  readonly level: LanguageLevelEnum
}

export interface ProfResumeLimit extends ResumeError {
  readonly __typename?: 'ProfResumeLimit'
  readonly message: Scalars['String']
}

export interface ProfResumeMilitaryStatusInput {
  readonly militaryStatus?: InputMaybe<MilitaryStatusEnum>
  readonly resumeId: Scalars['ID']
}

export interface ProfResumePersonalInfoInput {
  readonly birthDate?: InputMaybe<Scalars['Date']>
  readonly fatherName?: InputMaybe<Scalars['String']>
  readonly firstName?: InputMaybe<Scalars['String']>
  readonly gender?: InputMaybe<Gender>
  readonly photoUrl?: InputMaybe<Scalars['Uri']>
  readonly surName?: InputMaybe<Scalars['String']>
}

export interface ProfResumePhoneConfirmationFailed extends ResumeError {
  readonly __typename?: 'ProfResumePhoneConfirmationFailed'
  readonly message: Scalars['String']
}

export interface ProfResumePhotoInput {
  readonly photo: Scalars['Upload']
  readonly resumeId: Scalars['ID']
}

export interface ProfResumePositionInput {
  readonly position: Scalars['String']
  readonly resumeId: Scalars['ID']
  readonly rubricsIds?: InputMaybe<ReadonlyArray<Scalars['Int']>>
}

export type ProfResumeResponseUnion = NotFoundProfResume | ProfResume

export interface ProfResumeSalaryInput {
  readonly amount: Scalars['Int']
  readonly currency: Currency
}

export interface ProfResumeScheduleInput {
  readonly resumeId: Scalars['ID']
  readonly scheduleIds: ReadonlyArray<Scalars['Int']>
}

export interface ProfResumeSkillsInput {
  readonly resumeId: Scalars['ID']
  readonly skills: Scalars['String']
}

export interface ProfResumeSocialNetworkInput {
  readonly type: ContactInfoSubTypeEnum
  readonly value: Scalars['String']
}

export interface ProfResumeUpdateAdditionalEducationInput {
  readonly education: ProfResumeAdditionalEducationInput
  readonly educationId: Scalars['ID']
  readonly resumeId: Scalars['ID']
}

export interface ProfResumeUpdateAdditionalInfoInput {
  readonly additionalInfo: ProfResumeAdditionalInfoInput
  readonly additionalInfoId: Scalars['ID']
  readonly resumeId: Scalars['ID']
}

export interface ProfResumeUpdateAdditionalPhonesInput {
  readonly phones: ReadonlyArray<Scalars['String']>
  readonly resumeId: Scalars['ID']
}

export interface ProfResumeUpdateAnonymityInput {
  readonly isAnonymous: Scalars['Boolean']
  readonly resumeId: Scalars['ID']
}

export interface ProfResumeUpdateAvailabilityStateInput {
  readonly resumeId: Scalars['ID']
  readonly state: ResumeAvailabilityStateEnum
}

export interface ProfResumeUpdateEducationInput {
  readonly education: ProfResumeEducationInput
  readonly educationId: Scalars['ID']
  readonly resumeId: Scalars['ID']
}

export interface ProfResumeUpdateEmailInput {
  readonly email: Scalars['String']
  readonly resumeId: Scalars['ID']
}

export interface ProfResumeUpdateExperiencesInput {
  readonly experience: ProfResumeExperienceInput
  readonly experienceId: Scalars['ID']
  readonly resumeId: Scalars['ID']
}

export interface ProfResumeUpdateHiddenCompaniesInput {
  readonly hiddenCompanyIds: ReadonlyArray<Scalars['String']>
  readonly resumeId: Scalars['ID']
}

export interface ProfResumeUpdateLanguageSkillsInput {
  readonly languageSkills: ReadonlyArray<ProfResumeLanguageSkillInput>
  readonly resumeId: Scalars['ID']
}

export interface ProfResumeUpdatePhoneInput {
  readonly confirmationCode?: InputMaybe<Scalars['String']>
  readonly phone: Scalars['String']
  readonly resumeId: Scalars['ID']
}

export interface ProfResumeUpdatePortfoliosInput {
  readonly portfolios: ReadonlyArray<Scalars['Uri']>
  readonly resumeId: Scalars['ID']
}

export interface ProfResumeUpdateSalaryInput {
  readonly resumeId: Scalars['ID']
  readonly salary: ProfResumeSalaryInput
}

export interface ProfResumeUpdateSearchStateInput {
  readonly resumeId: Scalars['ID']
  readonly state: ResumeSearchStateEnum
}

export interface ProfResumeUpdateSocialNetworksInput {
  readonly resumeId: Scalars['ID']
  readonly socials: ReadonlyArray<ProfResumeSocialNetworkInput>
}

export interface ProfResumeUpdateStateInput {
  readonly activeState: ResumeActiveStateEnum
  readonly availabilityState: ResumeAvailabilityStateEnum
  readonly isAllowedToShareWithPartners?: InputMaybe<Scalars['Boolean']>
  readonly resumeId: Scalars['ID']
}

export interface ProfResumeUpdateStateOutput {
  readonly __typename?: 'ProfResumeUpdateStateOutput'
  readonly errors: Maybe<ReadonlyArray<UpdateSeekerProfResumeStateError>>
  readonly profResume: Maybe<ProfResume>
}

export interface ProfileRegisteredEvent extends EventInterface {
  readonly __typename?: 'ProfileRegisteredEvent'
  readonly addDate: Scalars['DateTimeOffset']
  readonly assignment: Maybe<Assignment>
  readonly company: Maybe<CrmCompany>
  readonly daysLeft: Maybe<Scalars['Int']>
  readonly employee: Maybe<CrmEmployee>
  readonly eventType: EventTypeEnum
  readonly expirationDate: Maybe<Scalars['DateTimeOffset']>
  readonly id: Scalars['ID']
  readonly isArchieved: Scalars['Boolean']
  readonly isRead: Scalars['Boolean']
  readonly lifetimeDays: Scalars['Int']
  readonly state: EventStateEnum
  readonly topics: ReadonlyArray<Topic>
}

export interface ProlongationRequestVacancyEmployerEvent {
  readonly __typename?: 'ProlongationRequestVacancyEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly performer: PerformerEmployerEventUnion
  readonly vacancyCity: City
  readonly vacancyId: Scalars['ID']
  readonly vacancyName: Scalars['String']
}

export interface PrometheusExternalCourse {
  readonly __typename?: 'PrometheusExternalCourse'
  readonly id: Scalars['ID']
  readonly url: Scalars['String']
}

export interface PromoCode {
  readonly __typename?: 'PromoCode'
  readonly activatorsInfo: ReadonlyArray<PromoCodeActivator>
  readonly addDate: Scalars['DateTime']
  readonly chargesLeft: Scalars['Int']
  readonly chargesTotal: Scalars['Int']
  readonly code: Scalars['String']
  readonly discount: ServiceDiscountTypeWrapper
  readonly discountSizePercent: Scalars['Int']
  readonly expirationDate: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly isAvailableInCart: Scalars['Boolean']
  readonly reason: Maybe<Scalars['String']>
  readonly status: PromoCodeStatusEnum
  readonly target: PromoCodeTragetUnion
  readonly type: PromoCodeTypeWrapper
}

export interface PromoCodeActivator {
  readonly __typename?: 'PromoCodeActivator'
  readonly activationDates: ReadonlyArray<Scalars['DateTime']>
  readonly chargesUsed: Scalars['Int']
  readonly employee: Employee
}

export interface PromoCodeCartMutation {
  readonly __typename?: 'PromoCodeCartMutation'
  readonly apply: SelectPromoCodeResponse
  readonly drop: DropSelectedPromoCodeResponse
}

export interface PromoCodeCartMutationApplyArgs {
  id: Scalars['Guid']
}

export interface PromoCodeCartMutationDropArgs {
  id: InputMaybe<Scalars['Guid']>
}

export interface PromoCodeConnection {
  readonly __typename?: 'PromoCodeConnection'
  readonly edges: Maybe<ReadonlyArray<Maybe<PromoCodeEdge>>>
  readonly items: Maybe<ReadonlyArray<PromoCode>>
  readonly pageInfo: PageInfo
  readonly totalCount: Maybe<Scalars['Int']>
}

export interface PromoCodeDateRangeInput {
  readonly from: Scalars['DateTime']
  readonly to: Scalars['DateTime']
}

export interface PromoCodeDiscount {
  readonly __typename?: 'PromoCodeDiscount'
  readonly promoCode: PromoCode
  readonly sum: Scalars['Float']
}

export interface PromoCodeEdge {
  readonly __typename?: 'PromoCodeEdge'
  readonly cursor: Scalars['String']
  readonly node: PromoCode
}

export interface PromoCodeFilterInput {
  readonly dateRange?: InputMaybe<PromoCodeDateRangeInput>
  readonly serviceTypes?: InputMaybe<ReadonlyArray<Scalars['String']>>
  readonly statuses?: InputMaybe<ReadonlyArray<PromoCodeStatusEnum>>
  readonly types?: InputMaybe<ReadonlyArray<PromoCodeTypeEnum>>
}

export interface PromoCodeMutation {
  readonly __typename?: 'PromoCodeMutation'
  readonly add: AddPromoCodeResponse
  readonly use: UsePromoCodeResponse
}

export interface PromoCodeMutationAddArgs {
  code: Scalars['String']
}

export interface PromoCodeMutationUseArgs {
  input: UsePromocodesInput
}

export enum PromoCodeSortEnum {
  ByExpirationAsc = 'BY_EXPIRATION_ASC',
  ByExpirationDesc = 'BY_EXPIRATION_DESC',
  FirstNew = 'FIRST_NEW',
  FirstOld = 'FIRST_OLD'
}

export enum PromoCodeStatusEnum {
  Active = 'ACTIVE',
  Applied = 'APPLIED',
  Expired = 'EXPIRED',
  Used = 'USED'
}

export interface PromoCodeTargetBonusSpecialServices {
  readonly __typename?: 'PromoCodeTargetBonusSpecialServices'
  readonly description: Scalars['String']
  readonly services: ReadonlyArray<CatalogService>
}

export interface PromoCodeTargetSpecialService {
  readonly __typename?: 'PromoCodeTargetSpecialService'
  readonly description: Scalars['String']
  readonly service: CatalogService
}

export interface PromoCodeTargetSpecialServiceType {
  readonly __typename?: 'PromoCodeTargetSpecialServiceType'
  readonly description: Scalars['String']
  readonly serviceType: ServiceTypeWrapper
}

export interface PromoCodeTargetWholeOrder {
  readonly __typename?: 'PromoCodeTargetWholeOrder'
  readonly description: Scalars['String']
}

export type PromoCodeTragetUnion = PromoCodeTargetBonusSpecialServices | PromoCodeTargetSpecialService | PromoCodeTargetSpecialServiceType | PromoCodeTargetWholeOrder

export enum PromoCodeTypeEnum {
  ForBonusSpecialServices = 'FOR_BONUS_SPECIAL_SERVICES',
  ForSpecialService = 'FOR_SPECIAL_SERVICE',
  ForSpecialServiceType = 'FOR_SPECIAL_SERVICE_TYPE',
  ForWholeOrder = 'FOR_WHOLE_ORDER'
}

export interface PromoCodeTypeWrapper {
  readonly __typename?: 'PromoCodeTypeWrapper'
  readonly id: Scalars['ID']
  readonly type: PromoCodeTypeEnum
}

export interface ProzoraClientDateTimeInterval {
  readonly __typename?: 'ProzoraClientDateTimeInterval'
  readonly begin: Scalars['DateTime']
  readonly end: Scalars['DateTime']
}

export interface ProzoraClientFilters {
  readonly __typename?: 'ProzoraClientFilters'
  readonly cityId: Scalars['ID']
  readonly keyword: Scalars['String']
  readonly range: ProzoraClientDateTimeInterval
  readonly rubricId: Scalars['ID']
}

export interface ProzoraClientFiltersInput {
  readonly cityId?: InputMaybe<Scalars['ID']>
  readonly keyword?: InputMaybe<Scalars['String']>
  readonly range?: InputMaybe<DateIntervalInput>
  readonly rubricId?: InputMaybe<Scalars['ID']>
}

export interface PublicClubPostsCursorPaginationInput {
  readonly after?: InputMaybe<Scalars['String']>
  readonly before?: InputMaybe<Scalars['String']>
  readonly take?: InputMaybe<Scalars['UInt']>
}

export interface PublicClubPostsFilterInput {
  readonly byTagName?: InputMaybe<Scalars['String']>
  readonly byTopicName?: InputMaybe<Scalars['String']>
  readonly isHot?: InputMaybe<Scalars['Boolean']>
  readonly isTop?: InputMaybe<Scalars['Boolean']>
  readonly searchKeyword?: InputMaybe<Scalars['String']>
  readonly tagIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
  readonly topicIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
}

export interface PublicClubPostsOutput {
  readonly __typename?: 'PublicClubPostsOutput'
  readonly count: Maybe<Scalars['Long']>
  readonly items: ReadonlyArray<ClubPost>
  readonly nextCursor: Maybe<Scalars['String']>
  readonly previousCursor: Maybe<Scalars['String']>
}

export enum PublicClubPostsSortingEnum {
  ByDateAsc = 'BY_DATE_ASC',
  ByDateDesc = 'BY_DATE_DESC',
  ByMonth = 'BY_MONTH',
  ByMostViewedAsc = 'BY_MOST_VIEWED_ASC',
  ByMostViewedDesc = 'BY_MOST_VIEWED_DESC',
  ByWeek = 'BY_WEEK',
  ByYear = 'BY_YEAR'
}

export enum PublicClubTopicEnameEnum {
  Career = 'CAREER',
  CorporateCulture = 'CORPORATE_CULTURE',
  ExpertThoughts = 'EXPERT_THOUGHTS',
  Hr = 'HR',
  News = 'NEWS',
  Recruiting = 'RECRUITING',
  ResumeJobSearch = 'RESUME_JOB_SEARCH',
  Victory = 'VICTORY'
}

export interface PublicationServiceEmployerEventSnapshot {
  readonly __typename?: 'PublicationServiceEmployerEventSnapshot'
  readonly addedAt: Scalars['DateTime']
  readonly catalogService: CatalogService
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly publicationType: VacancyPublicationTypeWrapper
}

export interface PublishProfResumeInput {
  readonly resumeId: Scalars['ID']
}

export type PublishSeekerProfResumeError = ProfResumeCannotBePublished | ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export interface PublishedProfResumeOutput {
  readonly __typename?: 'PublishedProfResumeOutput'
  readonly errors: Maybe<ReadonlyArray<PublishSeekerProfResumeError>>
  readonly profResume: Maybe<ProfResume>
}

export enum PublishedResumeCounterPeriodEnum {
  AllTime = 'ALL_TIME',
  Day = 'DAY',
  Month = 'MONTH',
  ThreeMonths = 'THREE_MONTHS'
}

export interface PublishedResumesCounter {
  readonly __typename?: 'PublishedResumesCounter'
  readonly count: Scalars['Int']
  readonly period: PublishedResumeCounterPeriodEnum
}

export interface PublishedVacancies {
  readonly __typename?: 'PublishedVacancies'
  readonly items: Maybe<ReadonlyArray<Maybe<Vacancy>>>
  readonly totalCount: Maybe<Scalars['Long']>
}

export interface PublishedVacanciesClientCluster {
  readonly __typename?: 'PublishedVacanciesClientCluster'
  readonly id: Scalars['ID']
  readonly tagIds: ReadonlyArray<Scalars['ID']>
}

export interface PublishedVacanciesClientClusterInput {
  readonly id: Scalars['ID']
  readonly tagIds: ReadonlyArray<Scalars['ID']>
}

export interface PublishedVacanciesClientFilter {
  readonly __typename?: 'PublishedVacanciesClientFilter'
  readonly cityId: Scalars['ID']
  readonly clusters: ReadonlyArray<PublishedVacanciesClientCluster>
  readonly districtIds: ReadonlyArray<Scalars['ID']>
  readonly isForVeterans: Scalars['Boolean']
  readonly isOfficeWithGenerator: Scalars['Boolean']
  readonly isOfficeWithShelter: Scalars['Boolean']
  readonly isReservation: Scalars['Boolean']
  readonly keywords: Scalars['String']
  readonly location: PublishedVacanciesLocation
  readonly metroBranches: ReadonlyArray<PublishedVacanciesClientMetroBranch>
  readonly regionId: Scalars['ID']
  readonly rubrics: ReadonlyArray<PublishedVacanciesClientRubric>
  readonly salary: Scalars['Int']
  readonly scheduleIds: ReadonlyArray<Scalars['ID']>
  readonly showAgencies: Scalars['Boolean']
  readonly showMilitary: Scalars['Boolean']
  readonly showOnlyNoCvApplyVacancies: Scalars['Boolean']
  readonly showOnlyNotViewed: Scalars['Boolean']
  readonly showOnlySpecialNeeds: Scalars['Boolean']
  readonly showOnlyWithoutExperience: Scalars['Boolean']
  readonly showWithoutSalary: Scalars['Boolean']
}

export interface PublishedVacanciesClientFilterInput {
  readonly cityId?: InputMaybe<Scalars['ID']>
  readonly clusters?: InputMaybe<ReadonlyArray<PublishedVacanciesClientClusterInput>>
  readonly districtIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  readonly isForVeterans?: InputMaybe<Scalars['Boolean']>
  readonly isOfficeWithGenerator?: InputMaybe<Scalars['Boolean']>
  readonly isOfficeWithShelter?: InputMaybe<Scalars['Boolean']>
  readonly isReservation?: InputMaybe<Scalars['Boolean']>
  readonly keywords?: InputMaybe<Scalars['String']>
  readonly location?: InputMaybe<PublishedVacanciesLocationInput>
  readonly metroBranches?: InputMaybe<ReadonlyArray<PublishedVacanciesMetroBranchInput>>
  readonly regionId?: InputMaybe<Scalars['ID']>
  readonly rubrics?: InputMaybe<ReadonlyArray<PublishedVacanciesRubricInput>>
  readonly salary?: InputMaybe<Scalars['Int']>
  readonly scheduleIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
  readonly showAgencies?: InputMaybe<Scalars['Boolean']>
  readonly showMilitary?: InputMaybe<Scalars['Boolean']>
  readonly showOnlyNoCvApplyVacancies?: InputMaybe<Scalars['Boolean']>
  readonly showOnlyNotViewed?: InputMaybe<Scalars['Boolean']>
  readonly showOnlySpecialNeeds?: InputMaybe<Scalars['Boolean']>
  readonly showOnlyWithoutExperience?: InputMaybe<Scalars['Boolean']>
  readonly showWithoutSalary?: InputMaybe<Scalars['Boolean']>
}

export interface PublishedVacanciesClientMetroBranch {
  readonly __typename?: 'PublishedVacanciesClientMetroBranch'
  readonly id: Scalars['ID']
  readonly stationIds: ReadonlyArray<Scalars['ID']>
}

export interface PublishedVacanciesClientRubric {
  readonly __typename?: 'PublishedVacanciesClientRubric'
  readonly id: Scalars['ID']
  readonly subrubricIds: ReadonlyArray<Scalars['ID']>
}

export interface PublishedVacanciesFacets {
  readonly __typename?: 'PublishedVacanciesFacets'
  readonly districtsFacets: ReadonlyArray<DistrictFacet>
  readonly metrosFacets: ReadonlyArray<MetroLineFacet>
  readonly rubricsFacets: ReadonlyArray<RubricFacet>
  readonly schedulesFacets: ReadonlyArray<ScheduleFacet>
}

export interface PublishedVacanciesFilterInput {
  readonly additionalKeywords?: InputMaybe<Scalars['String']>
  readonly cityId?: InputMaybe<Scalars['ID']>
  readonly clusterKeywords?: InputMaybe<ReadonlyArray<Scalars['String']>>
  readonly districtIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  readonly isForVeterans: Scalars['Boolean']
  readonly isOfficeWithGenerator: Scalars['Boolean']
  readonly isOfficeWithShelter: Scalars['Boolean']
  readonly isReservation: Scalars['Boolean']
  readonly keywords?: InputMaybe<Scalars['String']>
  readonly location?: InputMaybe<PublishedVacanciesLocationInput>
  readonly metroBranches?: InputMaybe<ReadonlyArray<PublishedVacanciesMetroBranchInput>>
  readonly metroIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  readonly parentRubricId?: InputMaybe<Scalars['ID']>
  readonly period?: InputMaybe<PublishedVacanciesFilterPeriod>
  readonly profLevelIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  readonly radiusFilter?: InputMaybe<UserLocationRadiusFilterInput>
  readonly regionId?: InputMaybe<Scalars['ID']>
  readonly rubrics?: InputMaybe<ReadonlyArray<PublishedVacanciesRubricInput>>
  readonly salary?: InputMaybe<Scalars['Int']>
  readonly scheduleId?: InputMaybe<PublishedVacanciesSchedule>
  readonly scheduleIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
  readonly searchedType?: InputMaybe<PublishedVacanciesSearchedTypeEnum>
  readonly showAgencies: Scalars['Boolean']
  readonly showMilitary: Scalars['Boolean']
  readonly showOnlyNoCvApplyVacancies: Scalars['Boolean']
  readonly showOnlyNotViewed: Scalars['Boolean']
  readonly showOnlySpecialNeeds: Scalars['Boolean']
  readonly showOnlyWithoutExperience: Scalars['Boolean']
  readonly showWithoutSalary: Scalars['Boolean']
  readonly subrubricIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
}

export enum PublishedVacanciesFilterPeriod {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK'
}

export interface PublishedVacanciesLocation {
  readonly __typename?: 'PublishedVacanciesLocation'
  readonly latitude: Scalars['Float']
  readonly longitude: Scalars['Float']
}

export interface PublishedVacanciesLocationInput {
  readonly latitude: Scalars['Float']
  readonly longitude: Scalars['Float']
}

export interface PublishedVacanciesMetroBranchInput {
  readonly id: Scalars['ID']
  readonly stationIds: ReadonlyArray<Scalars['ID']>
}

export interface PublishedVacanciesPaginationInput {
  readonly count: Scalars['Int']
  readonly page: Scalars['Int']
}

export interface PublishedVacanciesQueryFilter {
  readonly __typename?: 'PublishedVacanciesQueryFilter'
  readonly filter: PublishedVacanciesClientFilter
  readonly page: Scalars['Int']
  readonly sort: PublishedVacanciesSortType
}

export interface PublishedVacanciesRubricInput {
  readonly id: Scalars['ID']
  readonly subrubricIds: ReadonlyArray<Scalars['ID']>
}

export enum PublishedVacanciesSchedule {
  FullTime = 'FULL_TIME',
  Hybrid = 'HYBRID',
  Internship = 'INTERNSHIP',
  Office = 'OFFICE',
  PartTime = 'PART_TIME',
  ProjectBased = 'PROJECT_BASED',
  Remote = 'REMOTE',
  Temporary = 'TEMPORARY'
}

export enum PublishedVacanciesSearchedTypeEnum {
  Relevant = 'RELEVANT',
  Standard = 'STANDARD'
}

export enum PublishedVacanciesSortType {
  ByBusinessScore = 'BY_BUSINESS_SCORE',
  ByDate = 'BY_DATE',
  ByDistance = 'BY_DISTANCE',
  ByRelevance = 'BY_RELEVANCE',
  BySalary = 'BY_SALARY',
  ByViewed = 'BY_VIEWED'
}

export interface PublishedVacancyBadge {
  readonly __typename?: 'PublishedVacancyBadge'
  readonly id: Scalars['Int']
  readonly name: Scalars['String']
}

export interface PublishedVacancyEmployerEvent {
  readonly __typename?: 'PublishedVacancyEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly performer: PerformerEmployerEventUnion
  readonly vacancyCity: City
  readonly vacancyId: Scalars['ID']
  readonly vacancyName: Scalars['String']
}

export interface Quarter {
  readonly __typename?: 'Quarter'
  readonly number: Maybe<Scalars['Int']>
  readonly year: Maybe<Scalars['Int']>
}

export interface Query {
  readonly __typename?: 'Query'
  readonly actDownloadUrl: DownloadActOutput
  readonly actionFilters: Maybe<ActionFilters>
  readonly anotherSourceVacancy: Maybe<AnotherSourceVacancyOutput>
  readonly applies: Maybe<ApplyConnection>
  readonly apply: Maybe<Apply>
  readonly applyAtsConnection: Maybe<ApplyProjectAtsConnection>
  readonly applyInvitationDetails: Maybe<ApplyInvitationDetails>
  readonly applyRefusalDetails: Maybe<ApplyRefusalDetails>
  readonly applyResumeFillingTypeCounters: ReadonlyArray<ApplyResumeFillingTypeCount>
  readonly applyStatuses: ReadonlyArray<Maybe<ApplyStatusCount>>
  readonly assignment: Assignment
  readonly assignmentCounters: ReadonlyArray<DateCounter>
  readonly assignments: Maybe<AssignmentConnection>
  readonly banners: BannerQueries
  readonly billFilters: Maybe<BillFilters>
  readonly branch: Maybe<Branch>
  readonly branches: ReadonlyArray<Maybe<Branch>>
  readonly cabinetEventsClientFilters: CabinetEventsQueryFilter
  readonly calculatePrices: PriceQueryOutput
  readonly callFilters: Maybe<CallFilters>
  readonly cart: CartQuery
  readonly cartById: CartQuery
  readonly catalogService: Maybe<CatalogUnitedServices>
  readonly catalogServiceRegions: ReadonlyArray<City>
  readonly catalogServices: Maybe<ReadonlyArray<Maybe<CatalogUnitedServices>>>
  readonly categorizationAttaches: CategorizationAttachesQueries
  readonly checkVacancyContentCorrectnessForPublishing: CheckVacancyContentCorrectnessForPublishingOutput
  readonly cities: ReadonlyArray<Maybe<City>>
  readonly citiesConnection: Maybe<CitiesConnection>
  readonly city: Maybe<City>
  readonly cityDistrict: CityDistrict
  readonly cityDistricts: ReadonlyArray<Maybe<CityDistrict>>
  readonly cityMetro: ReadonlyArray<Maybe<Metro>>
  readonly clubs: ClubQueries
  readonly companiesSimilarToMine: ReadonlyArray<Company>
  readonly company: Maybe<Company>
  readonly companyDuplicates: ReadonlyArray<DuplicateCompany>
  readonly companyEvents: ReadonlyArray<CompanyEventOutput>
  readonly companyPublishedVacanciesClientFilters: CompanyPublishedVacanciesQueryFilter
  readonly companyStaffSize: CompanyStaffSize
  readonly companyStaffSizes: ReadonlyArray<CompanyStaffSize>
  readonly companyStates: ReadonlyArray<CompanyStateWrapper>
  readonly confirmedCompanyPhones: ReadonlyArray<CrmPhone>
  readonly crmAccounts: Maybe<CrmAccountConnection>
  readonly crmAction: Maybe<CrmEditAction>
  readonly crmActionAttaches: Maybe<ReadonlyArray<Maybe<ActionAttach>>>
  readonly crmActionDraft: Maybe<CrmEditAction>
  readonly crmActionMeetingReasons: Maybe<ReadonlyArray<Maybe<CrmMeetingReason>>>
  readonly crmActionPreviousList: Maybe<ReadonlyArray<Maybe<CrmActionBrief>>>
  readonly crmActionTypes: Maybe<ReadonlyArray<Maybe<CrmActionType>>>
  readonly crmActions: Maybe<CrmActionConnection>
  readonly crmCalls: Maybe<CrmCallConnection>
  readonly crmCompanies: Maybe<CrmCompanyConnection>
  readonly crmCompany: Maybe<CrmCompany>
  readonly crmEmailContacts: Maybe<ReadonlyArray<Maybe<CrmContact>>>
  readonly crmManagerCalendar: Maybe<ReadonlyArray<Maybe<CrmPlannedActionByDay>>>
  readonly crmMarketingServiceFilters: Maybe<ReadonlyArray<Maybe<CrmMarketingServiceFilter>>>
  readonly crmMarketingServices: Maybe<CrmMarketingServiceConnection>
  readonly crmMetaCompany: Maybe<CrmMetaCompany>
  readonly crmMultiUser: Maybe<CrmMultiUser>
  readonly crmOrderServices: ReadonlyArray<PaidOrderService>
  readonly crmPinnedActions: Maybe<ReadonlyArray<CrmAction>>
  readonly crmQuarterList: Maybe<ReadonlyArray<Maybe<CrmQuarter>>>
  readonly crmSaleManagers: Maybe<ReadonlyArray<Maybe<CrmManager>>>
  readonly crmUserCard: Maybe<CrmUserCardEdit>
  readonly crmUserCardRoles: Maybe<ReadonlyArray<Maybe<CrmUserCardRole>>>
  readonly crmUserCards: Maybe<ReadonlyArray<CrmUserCard>>
  readonly currentEmployer: Maybe<Employee>
  /** @deprecated New version of Prozora */
  readonly cvCounts: Maybe<CountStatistics>
  readonly cvdbRegions: ServiceCvdbRegionsOutput
  readonly cvdbRubrics: ServiceCvdbRubricsOutput
  readonly dashboardCompanies: Maybe<DashboardCompanyConnection>
  readonly dashboardCompanyStatistics: Maybe<DashboardResponse>
  readonly dashboardMultiUserRatings: Maybe<ReadonlyArray<Maybe<DashboardRatingResponse>>>
  readonly dashboardMultiUserStatistics: Maybe<ReadonlyArray<Maybe<DashboardMultiUserResponse>>>
  readonly employee: Maybe<Employee>
  readonly employeeHasScopes: EmployeeHasScopesOutput
  readonly employeeRoleBasedScopeEnumToIds: ReadonlyArray<Scalars['ID']>
  readonly employeeRoleBasedScopes: ReadonlyArray<EmployeeRoleBasedScope>
  readonly employeeScopes: ReadonlyArray<EmployeeRoleBasedScope>
  readonly employeeSubscriptions: EmployeeSubscriptionsQueries
  readonly employerOnBoardingCheckList: Maybe<EmployerOnBoardingCheckListOutput>
  readonly experience: Maybe<Experience>
  readonly experiences: ReadonlyArray<Maybe<Experience>>
  readonly feedback: FeedbackQueries
  readonly gamificationDashboardDates: Maybe<GamificationDates>
  readonly gamificationRecommendedWinnerCount: Scalars['Int']
  readonly gamificationWaveList: ReadonlyArray<GamificationWaveList>
  readonly gamificationWaves: ReadonlyArray<GamificationWave>
  readonly generateExternalPaymentUrl: Maybe<GenerateExternalPaymentUrlOutput>
  readonly generateExternalPaymentUrlByInvoice: Maybe<GenerateExternalPaymentUrlByInvoiceOutput>
  readonly getCounterparty: Maybe<NovaPoshtaCounterpartyGraphType>
  readonly getCurrentEmployer: GetCurrentEmployerOutput
  readonly getWorkVacancy: Maybe<SpiderVacancy>
  readonly gift: RegularGift
  readonly giftCompanyLimitsByWave: ReadonlyArray<GiftCompanyWaveLimit>
  readonly giftLimits: ReadonlyArray<GiftLimit>
  readonly giftLimitsByManager: ReadonlyArray<ManagerGiftLimit>
  readonly giftLimitsByTeamLead: ReadonlyArray<GiftTeamLimit>
  readonly giftLimitsByTeamLeadByManagers: ReadonlyArray<GiftTeamLimitByManager>
  readonly giftLimitsByTeamLeadByManagersPaging: Maybe<GiftTeamLimitByManagerConnection>
  readonly giftLimitsByWave: ReadonlyArray<GiftWaveLimit>
  readonly giftLimitsTransferHistoryByTeamLead: ReadonlyArray<GiftTeamLimitTransferHistory>
  readonly giftLimitsTransferHistoryByTeamLeadPaging: Maybe<GiftTeamLimitTransferHistoryConnection>
  readonly giftList: ReadonlyArray<Gift>
  readonly giftRegistersScrollInfo: Maybe<ScrollInfo>
  readonly giftRequest: GiftRequest
  readonly giftRequestActiveOccasions: ReadonlyArray<GiftRequestOccasion>
  readonly giftRequestByManager: Maybe<GiftRequestConnection>
  readonly giftRequestMarketing: Maybe<GiftRequestConnection>
  readonly giftRequestOccasions: ReadonlyArray<GiftRequestOccasion>
  readonly giftsFilters: Maybe<GiftsFilters>
  readonly giftsInWarehouse: ReadonlyArray<WarehouseGift>
  readonly giftsManagerScrollInfo: Maybe<ScrollInfo>
  readonly giftsMarketingBySelfScrollInfo: Maybe<ScrollInfo>
  readonly giftsMarketingNovaPoshtaScrollInfo: Maybe<ScrollInfo>
  /** @deprecated Wrong Schema Design */
  readonly groupTag: Maybe<GroupTag>
  readonly groupTagsInsideCluster: Maybe<ReadonlyArray<Maybe<GroupTag>>>
  readonly hasCompanyNewUsersManagingFeature: Scalars['Boolean']
  readonly hasLimitsFunctionality: Scalars['Boolean']
  readonly invoice: Maybe<Invoice>
  readonly invoiceDownloadUrl: DownloadInvoiceOutput
  readonly isbluecollar: Maybe<Scalars['Boolean']>
  readonly keyword: Maybe<Keyword>
  readonly keywords: Maybe<ReadonlyArray<Maybe<Keyword>>>
  readonly language: Maybe<Lang>
  readonly languageSkillLevel: Maybe<LanguageSkillLevel>
  readonly languageSkillLevels: ReadonlyArray<Maybe<LanguageSkillLevel>>
  readonly languages: ReadonlyArray<Maybe<Lang>>
  readonly letterFilters: Maybe<LetterFilters>
  readonly limitedGiftsInWarehouse: ReadonlyArray<WarehouseLimitedGift>
  readonly limitsFilters: Maybe<LimitsFilters>
  readonly logoRubrics: ReadonlyArray<Maybe<LogoPortal>>
  readonly logoSubPortals: ReadonlyArray<Maybe<LogoPortal>>
  readonly manager: Maybe<Manager>
  readonly managerActionCounter: ReadonlyArray<ActionCounter>
  readonly managerDeals: ManagerDeals
  readonly managerForAnonymousUser: Maybe<Manager>
  readonly marketingGiftLimitHistory: Maybe<MarketingLimitTransferConnection>
  readonly marketingGiftRequests: Maybe<MarketingGiftRequestConnection>
  readonly marketingGifts: ReadonlyArray<MarketingGift>
  readonly me: Maybe<User>
  readonly metro: Metro
  readonly multiUsers: ReadonlyArray<CrmMultiUser>
  readonly myCompaniesToJoin: ReadonlyArray<CompanyJoinRequest>
  readonly myEventsLog: Maybe<EmployerEventLogConnection>
  readonly myJoiningEmployees: ReadonlyArray<Employee>
  readonly myOrder: Maybe<Order>
  readonly myPromoCodes: Maybe<PromoCodeConnection>
  readonly myVacancies: Maybe<MyVacanciesConnection>
  readonly myVacanciesByCity: Maybe<ReadonlyArray<Maybe<VacancyCityCount>>>
  readonly myVacanciesByEmployer: Maybe<ReadonlyArray<Maybe<VacancyEmployerCount>>>
  readonly myVacanciesByStatus: Maybe<ReadonlyArray<Maybe<VacancyStatusCount>>>
  readonly notifications: Maybe<NotificationsConnection>
  readonly novaPoshtaDocument: Maybe<NovaPoshtaDocument>
  readonly novaPoshtaFindCity: ReadonlyArray<NovaPoshtaCity>
  readonly novaPoshtaFindCounterpartyPerson: ReadonlyArray<NovaPoshtaCounterpartyGraphType>
  readonly novaPoshtaFindStreet: ReadonlyArray<NovaPoshtaStreet>
  readonly novaPoshtaFindWarehouse: ReadonlyArray<NovaPoshtaWarehouse>
  readonly novaPoshtaNewDocuments: ReadonlyArray<NovaPoshtaNewAddedDocument>
  readonly novaPoshtaRegisterDocuments: ReadonlyArray<NovaPoshtaRegisterDocument>
  readonly novaPoshtaRegisterList: Maybe<NovaPoshtaRegisterListConnection>
  readonly novaPoshtaSenders: ReadonlyArray<NovaPoshtaCounterpartyGraphType>
  readonly objection: Objection
  readonly popularSkills: Maybe<ReadonlyArray<Maybe<TagGroup>>>
  readonly positions: ReadonlyArray<Maybe<Scalars['String']>>
  /** @deprecated New version of Prozora */
  readonly professionAutocomplete: ReadonlyArray<Maybe<Scalars['String']>>
  /** @deprecated New version of Prozora */
  readonly professionSuggestions: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>
  readonly promoCodeById: Maybe<PromoCode>
  readonly promoCodesByIds: ReadonlyArray<PromoCode>
  readonly prozoraClientFilters: ProzoraClientFilters
  readonly publishedResumesCounter: ReadonlyArray<PublishedResumesCounter>
  readonly publishedVacancies: PublishedVacancies
  readonly publishedVacanciesClientFilters: PublishedVacanciesQueryFilter
  readonly publishedVacanciesFacets: PublishedVacanciesFacets
  readonly publishedVacancy: Maybe<Vacancy>
  readonly questionTemplates: ReadonlyArray<BasicQuestion>
  readonly questionnaire: Maybe<Questionnaire>
  readonly questionnaireAnswersSummaryByApply: Maybe<QuestionnaireAnswersSummary>
  readonly questionnaireSuggest: Maybe<QuestionnaireSuggest>
  readonly questionnaires: Maybe<QuestionnaireConnection>
  readonly recommendationProfile: Maybe<SeekerRecommendationProfile>
  readonly recommendedProfResumes: Maybe<RecommendedProfResumes>
  readonly recommendedVacancies: Maybe<RecommendedVacanciesConnection>
  readonly resumeFillingType: ResumeFillingType
  readonly resumeFillingTypes: ReadonlyArray<ResumeFillingType>
  readonly resumeSearchSuggest: ReadonlyArray<ResumeSearchSuggest>
  readonly rubric: Maybe<Rubric>
  readonly rubrics: ReadonlyArray<Maybe<Rubric>>
  readonly saleManagerQueries: SaleManagerQueries
  readonly savedCards: ReadonlyArray<SavedCard>
  readonly schedule: Maybe<Schedule>
  readonly schedules: ReadonlyArray<Schedule>
  readonly seekerApplies: SeekerApplyQueries
  readonly seekerDislikedVacancies: SeekerDislikedVacancyQueries
  readonly seekerFavoriteVacancies: SeekerFavoriteVacancyQueries
  readonly seekerResume: ProfResume
  readonly seekerResumeViews: Maybe<ResumeViewsConnection>
  readonly seekerResumes: ReadonlyArray<ProfResume>
  /** @deprecated New version of Prozora */
  readonly seekerSalaryStatistics: Maybe<SalaryStatistics>
  readonly seekerSubscriptionChannels: ReadonlyArray<SeekerSubscriptionChannel>
  readonly seekerSubscriptions: ReadonlyArray<SeekerSubscription>
  readonly seekerSubscriptionsSummaries: ReadonlyArray<SeekerSubscriptionSummary>
  readonly serviceAvailableContacts: ServiceAvailableContacts
  readonly serviceCompany: Maybe<ServiceCompany>
  readonly serviceCount: Scalars['Int']
  readonly serviceEmployee: Maybe<ServiceEmployee>
  readonly serviceEmployerPurchaseHistory: ServicePurchaseHistoryOutput
  readonly servicesFilters: Maybe<ServicesFilters>
  readonly servicesManagementFilters: Maybe<ServicesManagementClientFilters>
  readonly similarSuccessfulVacancies: Maybe<ReadonlyArray<Maybe<Vacancy>>>
  readonly similarVacancies: SimilarVacanciesQueries
  readonly similarVacancyKeywords: SimilarVacancyKeywordsOutput
  readonly statistics: Maybe<Statistic>
  readonly stopWords: ReadonlyArray<Scalars['String']>
  readonly subrubric: Maybe<Subrubric>
  readonly subrubrics: ReadonlyArray<Subrubric>
  readonly suggestedVacancySearch: SuggestedVacancySearchOutput
  readonly tagGroup: Maybe<TagGroup>
  readonly tagGroupByName: Maybe<TagGroup>
  readonly targetMailingListClientFilters: TargetMailingListQueryFilter
  readonly targetedMailing: TargetedMailingQueries
  readonly teamActionCounter: TeamActions
  readonly teamManagers: ReadonlyArray<CrmManager>
  readonly topCitiesByVacancyCount: Maybe<ReadonlyArray<Maybe<City>>>
  readonly topRubricsByVacancyCount: Maybe<ReadonlyArray<Maybe<Rubric>>>
  readonly topicTemplates: ReadonlyArray<TopicTemplate>
  readonly universities: Maybe<ReadonlyArray<Maybe<University>>>
  readonly university: Maybe<University>
  readonly userState: Maybe<UserState>
  readonly usersManagementFilters: UsersManagementFilters
  readonly usersManagementPageInfo: UsersManagementPageInfo
  readonly vacanciesPageFilters: VacanciesPageClientFilters
  readonly vacancy: Maybe<Vacancy>
  readonly vacancyAddressesSuggest: VacancyAddressesSuggestOutput
  readonly vacancyBadges: Maybe<ReadonlyArray<Maybe<VacancyBadge>>>
  readonly vacancyBenefit: VacancyDictionaryBenefit
  readonly vacancyBenefits: ReadonlyArray<Maybe<VacancyDictionaryBenefit>>
  readonly vacancyById: GetVacancyByIdResponse
  readonly vacancyChangeHistory: Maybe<ReadonlyArray<Maybe<VacancyChangeHistory>>>
  /** @deprecated New version of Prozora */
  readonly vacancyCounts: Maybe<CountStatistics>
  readonly vacancyCreateSuggest: Maybe<VacancyCreateSuggestOutput>
  readonly vacancyDesign: Maybe<VacancyDesign>
  readonly vacancyProlongationRequests: VacancyProlongationRequestsOutput
  readonly vacancyPublicationTypeValues: ReadonlyArray<VacancyPublicationTypeWrapper>
  /** @deprecated New version of Prozora */
  readonly vacancySalaryStatistics: Maybe<SalaryStatistics>
  readonly vacancySearchSuggests: Maybe<ReadonlyArray<Maybe<VacancySearchSuggests>>>
  readonly vacancySearchSuggestsV2: VacancySearchSuggestsQueries
}

export interface QueryActDownloadUrlArgs {
  input: DownloadActInput
}

export interface QueryAnotherSourceVacancyArgs {
  url: Scalars['String']
}

export interface QueryAppliesArgs {
  after: InputMaybe<Scalars['String']>
  filter: InputMaybe<ApplyFilterInput>
  first: InputMaybe<Scalars['Int']>
  sortType: InputMaybe<ApplySortType>
}

export interface QueryApplyArgs {
  id: Scalars['ID']
}

export interface QueryApplyAtsConnectionArgs {
  after: InputMaybe<Scalars['String']>
  applyId: Scalars['ID']
  filter: InputMaybe<ApplyAtsConnectionsFilterInput>
  first: InputMaybe<Scalars['Int']>
}

export interface QueryApplyInvitationDetailsArgs {
  applyId: Scalars['ID']
}

export interface QueryApplyRefusalDetailsArgs {
  applyId: Scalars['ID']
}

export interface QueryApplyResumeFillingTypeCountersArgs {
  filter: InputMaybe<ApplyResumeFillingTypeCountFilterInput>
}

export interface QueryApplyStatusesArgs {
  filter: InputMaybe<ApplyStatusCountFilterInput>
}

export interface QueryAssignmentArgs {
  id: InputMaybe<Scalars['ID']>
}

export interface QueryAssignmentCountersArgs {
  filter: InputMaybe<FilterAssignmentsInput>
}

export interface QueryAssignmentsArgs {
  after: InputMaybe<Scalars['String']>
  filter: FilterAssignmentsInput
  first: InputMaybe<Scalars['Int']>
}

export interface QueryBranchArgs {
  id: Scalars['ID']
}

export interface QueryCalculatePricesArgs {
  input: ReadonlyArray<CalculatePricesInput>
}

export interface QueryCartByIdArgs {
  id: Scalars['ID']
}

export interface QueryCatalogServiceArgs {
  id: InputMaybe<Scalars['ID']>
}

export interface QueryCatalogServiceRegionsArgs {
  serviceType: ServiceType
}

export interface QueryCatalogServicesArgs {
  filter: InputMaybe<CatalogServicesFilterInput>
}

export interface QueryCheckVacancyContentCorrectnessForPublishingArgs {
  vacancyId: Scalars['ID']
}

export interface QueryCitiesConnectionArgs {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  input: InputMaybe<CitiesConnectionInput>
}

export interface QueryCityArgs {
  id: Scalars['ID']
}

export interface QueryCityDistrictArgs {
  id: Scalars['ID']
}

export interface QueryCityDistrictsArgs {
  cityId: Scalars['ID']
}

export interface QueryCityMetroArgs {
  cityId: Scalars['ID']
}

export interface QueryCompanyArgs {
  id: Scalars['ID']
}

export interface QueryCompanyDuplicatesArgs {
  companyId: Scalars['Int']
}

export interface QueryCompanyEventsArgs {
  filter: CompanyEventInput
}

export interface QueryCompanyStaffSizeArgs {
  id: Scalars['ID']
}

export interface QueryConfirmedCompanyPhonesArgs {
  companyId: Scalars['ID']
}

export interface QueryCrmAccountsArgs {
  after: InputMaybe<Scalars['String']>
  filter: InputMaybe<FilterAccountsInput>
  first: InputMaybe<Scalars['Int']>
}

export interface QueryCrmActionArgs {
  id: Scalars['ID']
}

export interface QueryCrmActionAttachesArgs {
  input: Scalars['ID']
}

export interface QueryCrmActionDraftArgs {
  input: CrmActionDraftRequest
}

export interface QueryCrmActionPreviousListArgs {
  actionId: Scalars['ID']
}

export interface QueryCrmActionsArgs {
  after: InputMaybe<Scalars['String']>
  filter: InputMaybe<FilterActionsInput>
  first: InputMaybe<Scalars['Int']>
}

export interface QueryCrmCallsArgs {
  after: InputMaybe<Scalars['String']>
  filter: InputMaybe<CrmFilterCallInput>
  first: InputMaybe<Scalars['Int']>
}

export interface QueryCrmCompaniesArgs {
  after: InputMaybe<Scalars['String']>
  filter: InputMaybe<FilterCompaniesInput>
  first: InputMaybe<Scalars['Int']>
}

export interface QueryCrmCompanyArgs {
  id: Scalars['ID']
}

export interface QueryCrmEmailContactsArgs {
  companyId: Scalars['ID']
}

export interface QueryCrmManagerCalendarArgs {
  input: CrmManagerCalendarRequest
}

export interface QueryCrmMarketingServiceFiltersArgs {
  filter: FilterMarketingServicesInput
}

export interface QueryCrmMarketingServicesArgs {
  after: InputMaybe<Scalars['String']>
  filter: InputMaybe<FilterMarketingServicesInput>
  first: InputMaybe<Scalars['Int']>
}

export interface QueryCrmMetaCompanyArgs {
  id: Scalars['ID']
}

export interface QueryCrmMultiUserArgs {
  userId: Scalars['ID']
}

export interface QueryCrmPinnedActionsArgs {
  companyId: Scalars['ID']
}

export interface QueryCrmUserCardArgs {
  cardId: Scalars['ID']
}

export interface QueryCrmUserCardsArgs {
  companyId: Scalars['ID']
}

export interface QueryCvCountsArgs {
  input: UserInput
}

export interface QueryDashboardCompaniesArgs {
  after: InputMaybe<Scalars['String']>
  filter: InputMaybe<FilterCompaniesInput>
  first: InputMaybe<Scalars['Int']>
}

export interface QueryDashboardCompanyStatisticsArgs {
  filter: InputMaybe<DashboardCompanyInput>
}

export interface QueryDashboardMultiUserRatingsArgs {
  filter: InputMaybe<DashboardRatingInput>
}

export interface QueryDashboardMultiUserStatisticsArgs {
  filter: InputMaybe<DashboardMultiUserInput>
}

export interface QueryEmployeeArgs {
  id: Scalars['ID']
}

export interface QueryEmployeeHasScopesArgs {
  input: EmployeeHasScopesInput
}

export interface QueryEmployeeRoleBasedScopeEnumToIdsArgs {
  items: ReadonlyArray<EmployeeRoleBasedScopeEnum>
}

export interface QueryEmployeeScopesArgs {
  employeeId: Scalars['ID']
}

export interface QueryExperienceArgs {
  id: Scalars['ID']
}

export interface QueryGamificationDashboardDatesArgs {
  notebookId: Scalars['ID']
}

export interface QueryGamificationRecommendedWinnerCountArgs {
  notebookId: Scalars['ID']
}

export interface QueryGamificationWavesArgs {
  companyId: Scalars['ID']
}

export interface QueryGenerateExternalPaymentUrlArgs {
  input: GenerateExternalPaymentUrlInput
}

export interface QueryGenerateExternalPaymentUrlByInvoiceArgs {
  input: GenerateExternalPaymentUrlByInvoiceInput
}

export interface QueryGetCounterpartyArgs {
  input: Scalars['ID']
}

export interface QueryGetWorkVacancyArgs {
  vacancyId: Scalars['Int']
}

export interface QueryGiftArgs {
  giftId: Scalars['ID']
}

export interface QueryGiftCompanyLimitsByWaveArgs {
  notebookId: Scalars['ID']
  waveId: Scalars['ID']
}

export interface QueryGiftLimitsArgs {
  companyId: Scalars['ID']
}

export interface QueryGiftLimitsByTeamLeadByManagersArgs {
  giftIds: InputMaybe<ReadonlyArray<Scalars['ID']>>
  managerIds: InputMaybe<ReadonlyArray<Scalars['ID']>>
}

export interface QueryGiftLimitsByTeamLeadByManagersPagingArgs {
  after: InputMaybe<Scalars['String']>
  filter: InputMaybe<GiftLimitsTransferHistoryByTeamLeadRequestInput>
  first: InputMaybe<Scalars['Int']>
}

export interface QueryGiftLimitsByWaveArgs {
  waveId: Scalars['ID']
}

export interface QueryGiftLimitsTransferHistoryByTeamLeadArgs {
  giftIds: InputMaybe<ReadonlyArray<Scalars['ID']>>
  managerIds: InputMaybe<ReadonlyArray<Scalars['ID']>>
}

export interface QueryGiftLimitsTransferHistoryByTeamLeadPagingArgs {
  after: InputMaybe<Scalars['String']>
  filter: InputMaybe<GiftLimitsTransferHistoryByTeamLeadRequestInput>
  first: InputMaybe<Scalars['Int']>
}

export interface QueryGiftRequestArgs {
  giftId: Scalars['ID']
}

export interface QueryGiftRequestByManagerArgs {
  after: InputMaybe<Scalars['String']>
  filter: InputMaybe<FilterGiftRequestInput>
  first: InputMaybe<Scalars['Int']>
}

export interface QueryGiftRequestMarketingArgs {
  after: InputMaybe<Scalars['String']>
  filter: InputMaybe<FilterGiftRequestInput>
  first: InputMaybe<Scalars['Int']>
}

export interface QueryGroupTagArgs {
  id: Scalars['ID']
}

export interface QueryGroupTagsInsideClusterArgs {
  after?: InputMaybe<Scalars['String']>
  clusterId: Scalars['ID']
  first?: InputMaybe<Scalars['Int']>
  prefix?: InputMaybe<Scalars['String']>
}

export interface QueryHasCompanyNewUsersManagingFeatureArgs {
  id: Scalars['ID']
}

export interface QueryInvoiceArgs {
  id: Scalars['ID']
  year: Scalars['Int']
}

export interface QueryInvoiceDownloadUrlArgs {
  input: DownloadInvoiceInput
}

export interface QueryIsbluecollarArgs {
  name?: InputMaybe<Scalars['String']>
}

export interface QueryKeywordArgs {
  name: Scalars['String']
}

export interface QueryKeywordsArgs {
  first: Scalars['Int']
  isProfession?: InputMaybe<Scalars['Boolean']>
  offset?: InputMaybe<Scalars['Int']>
  startsWith: InputMaybe<Scalars['String']>
}

export interface QueryLanguageArgs {
  id: Scalars['ID']
}

export interface QueryLanguageSkillLevelArgs {
  id: Scalars['ID']
}

export interface QueryManagerArgs {
  id: Scalars['ID']
}

export interface QueryManagerActionCounterArgs {
  filter: FilterActionsInput
}

export interface QueryMarketingGiftLimitHistoryArgs {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
}

export interface QueryMarketingGiftRequestsArgs {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
}

export interface QueryMetroArgs {
  id: Scalars['ID']
}

export interface QueryMultiUsersArgs {
  companyId: Scalars['ID']
}

export interface QueryMyEventsLogArgs {
  after: InputMaybe<Scalars['String']>
  filter: InputMaybe<EmployerEventsLogFilterInput>
  first: InputMaybe<Scalars['Int']>
}

export interface QueryMyOrderArgs {
  id: Scalars['ID']
}

export interface QueryMyPromoCodesArgs {
  after: InputMaybe<Scalars['String']>
  filter: InputMaybe<PromoCodeFilterInput>
  first: InputMaybe<Scalars['Int']>
  sort: InputMaybe<PromoCodeSortEnum>
}

export interface QueryMyVacanciesArgs {
  after: InputMaybe<Scalars['String']>
  filter: InputMaybe<MyVacanciesFilterInput>
  first: InputMaybe<Scalars['Int']>
  sortType: InputMaybe<MyVacanciesSortType>
}

export interface QueryMyVacanciesByCityArgs {
  filter: InputMaybe<MyVacanciesByCityFilter>
}

export interface QueryMyVacanciesByEmployerArgs {
  filter: InputMaybe<MyVacanciesByEmployerFilter>
}

export interface QueryMyVacanciesByStatusArgs {
  filter: InputMaybe<MyVacanciesByStatusFilter>
}

export interface QueryNotificationsArgs {
  after: InputMaybe<Scalars['String']>
  before: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  isAcquainted: InputMaybe<Scalars['Boolean']>
  isViewed: InputMaybe<Scalars['Boolean']>
  last: InputMaybe<Scalars['Int']>
}

export interface QueryNovaPoshtaDocumentArgs {
  docRef: Scalars['String']
}

export interface QueryNovaPoshtaFindCityArgs {
  name: Scalars['String']
}

export interface QueryNovaPoshtaFindCounterpartyPersonArgs {
  findByString: Scalars['String']
}

export interface QueryNovaPoshtaFindStreetArgs {
  cityRef: Scalars['String']
  name: Scalars['String']
}

export interface QueryNovaPoshtaFindWarehouseArgs {
  cityRef: Scalars['String']
  count: InputMaybe<Scalars['Int']>
  page: InputMaybe<Scalars['Int']>
}

export interface QueryNovaPoshtaRegisterDocumentsArgs {
  registerRef: Scalars['String']
}

export interface QueryNovaPoshtaRegisterListArgs {
  after: InputMaybe<Scalars['String']>
  filter: InputMaybe<FilterNovaPoshtaRegisterListInput>
  first: InputMaybe<Scalars['Int']>
}

export interface QueryObjectionArgs {
  id: Scalars['ID']
}

export interface QueryPopularSkillsArgs {
  keyword: Scalars['String']
  top?: InputMaybe<Scalars['Int']>
}

export interface QueryPositionsArgs {
  term: Scalars['String']
}

export interface QueryProfessionAutocompleteArgs {
  keyword: Scalars['String']
  size?: InputMaybe<Scalars['Int']>
}

export interface QueryProfessionSuggestionsArgs {
  cityId?: InputMaybe<Scalars['ID']>
  keyword: Scalars['String']
  lang: InputMaybe<Language>
}

export interface QueryPromoCodeByIdArgs {
  id: Scalars['ID']
}

export interface QueryPromoCodesByIdsArgs {
  ids: ReadonlyArray<Scalars['ID']>
}

export interface QueryPublishedResumesCounterArgs {
  input: ReadonlyArray<PublishedResumeCounterPeriodEnum>
}

export interface QueryPublishedVacanciesArgs {
  filter: PublishedVacanciesFilterInput
  pagination: InputMaybe<PublishedVacanciesPaginationInput>
  sort: InputMaybe<PublishedVacanciesSortType>
}

export interface QueryPublishedVacanciesFacetsArgs {
  filter: PublishedVacanciesFilterInput
}

export interface QueryPublishedVacancyArgs {
  id: Scalars['ID']
  trackView: InputMaybe<Scalars['Boolean']>
}

export interface QueryQuestionTemplatesArgs {
  input: InputMaybe<QuestionTemplatesFilterInput>
}

export interface QueryQuestionnaireArgs {
  id: Scalars['ID']
}

export interface QueryQuestionnaireAnswersSummaryByApplyArgs {
  applyId: Scalars['ID']
}

export interface QueryQuestionnaireSuggestArgs {
  vacancyDescription: Scalars['String']
  vacancyName: Scalars['String']
}

export interface QueryQuestionnairesArgs {
  after: InputMaybe<Scalars['String']>
  filter: InputMaybe<QuestionnairesFilterInput>
  first: InputMaybe<Scalars['Int']>
}

export interface QueryRecommendationProfileArgs {
  id: Scalars['ID']
}

export interface QueryRecommendedProfResumesArgs {
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  input: RecommendedProfResumesInput
}

export interface QueryRecommendedVacanciesArgs {
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
}

export interface QueryResumeFillingTypeArgs {
  id: Scalars['ID']
}

export interface QueryResumeSearchSuggestArgs {
  cityId: InputMaybe<Scalars['Int']>
  query: Scalars['String']
  size: InputMaybe<Scalars['Int']>
}

export interface QueryRubricArgs {
  id: Scalars['ID']
}

export interface QueryScheduleArgs {
  id: Scalars['ID']
}

export interface QuerySeekerResumeArgs {
  id: Scalars['ID']
}

export interface QuerySeekerResumeViewsArgs {
  after: InputMaybe<Scalars['String']>
  first: InputMaybe<Scalars['Int']>
  resumeId: InputMaybe<Scalars['ID']>
}

export interface QuerySeekerSalaryStatisticsArgs {
  input: UserInput
}

export interface QuerySeekerSubscriptionsArgs {
  filter: InputMaybe<SeekerSubscriptionsFilterInput>
}

export interface QueryServiceCompanyArgs {
  id: Scalars['ID']
}

export interface QueryServiceCountArgs {
  filter: InputMaybe<ServiceCount>
}

export interface QueryServiceEmployeeArgs {
  id: Scalars['ID']
}

export interface QuerySimilarSuccessfulVacanciesArgs {
  after?: InputMaybe<Scalars['String']>
  cityId: Scalars['ID']
  first?: InputMaybe<Scalars['Int']>
  vacancyTitle: Scalars['String']
}

export interface QuerySimilarVacancyKeywordsArgs {
  cityId: InputMaybe<Scalars['ID']>
  request: Scalars['String']
  size?: Scalars['Int']
}

export interface QueryStatisticsArgs {
  input: StatisticCityInput
}

export interface QuerySubrubricArgs {
  id: Scalars['ID']
}

export interface QuerySuggestedVacancySearchArgs {
  cityId: InputMaybe<Scalars['ID']>
  request: Scalars['String']
  size?: Scalars['Int']
}

export interface QueryTagGroupArgs {
  id: Scalars['ID']
}

export interface QueryTagGroupByNameArgs {
  keyword: Scalars['String']
}

export interface QueryTeamActionCounterArgs {
  filter: InputMaybe<FilterTeamActionsInput>
}

export interface QueryTopCitiesByVacancyCountArgs {
  first: Scalars['Int']
  offset?: InputMaybe<Scalars['Int']>
}

export interface QueryTopRubricsByVacancyCountArgs {
  first: Scalars['Int']
  offset?: InputMaybe<Scalars['Int']>
}

export interface QueryUniversitiesArgs {
  first: Scalars['Int']
  offset?: InputMaybe<Scalars['Int']>
  prefix?: InputMaybe<Scalars['String']>
}

export interface QueryUniversityArgs {
  id: Scalars['ID']
}

export interface QueryVacancyArgs {
  id: Scalars['ID']
}

export interface QueryVacancyAddressesSuggestArgs {
  input: VacancyAddressesSuggestInput
}

export interface QueryVacancyBadgesArgs {
  first: Scalars['Int']
  input: StatisticInput
}

export interface QueryVacancyBenefitArgs {
  id: Scalars['ID']
}

export interface QueryVacancyByIdArgs {
  id: Scalars['ID']
  removeTags: InputMaybe<Scalars['Boolean']>
}

export interface QueryVacancyChangeHistoryArgs {
  id: Scalars['ID']
}

export interface QueryVacancyCountsArgs {
  input: UserInput
}

export interface QueryVacancyDesignArgs {
  id: Scalars['ID']
}

export interface QueryVacancySalaryStatisticsArgs {
  input: UserInput
}

export interface QueryVacancySearchSuggestsArgs {
  cityId: InputMaybe<Scalars['Int']>
  query: Scalars['String']
  size: InputMaybe<Scalars['Int']>
  suggestType: InputMaybe<SuggestType>
}

export interface QuestionTemplatesFilterInput {
  readonly language?: InputMaybe<QuestionnaireLangEnum>
  readonly types?: InputMaybe<ReadonlyArray<InputMaybe<BasicQuestionTypeEnum>>>
}

export interface Questionnaire {
  readonly __typename?: 'Questionnaire'
  readonly company: Company
  readonly id: Scalars['ID']
  readonly isTemplate: Scalars['Boolean']
  readonly language: QuestionnaireLangEnum
  readonly name: Scalars['String']
  readonly questions: ReadonlyArray<QuestionnaireItem>
  readonly user: Employee
}

export interface QuestionnaireAnswer {
  readonly __typename?: 'QuestionnaireAnswer'
  readonly givenAnswers: ReadonlyArray<Scalars['String']>
  readonly isCorrect: Maybe<Scalars['Boolean']>
  readonly questionSnapshot: QuestionnaireItem
}

export interface QuestionnaireAnswersSummary {
  readonly __typename?: 'QuestionnaireAnswersSummary'
  readonly answers: ReadonlyArray<QuestionnaireAnswer>
  readonly apply: Apply
  readonly id: Scalars['ID']
  readonly questionnaire: Questionnaire
  readonly rate: Scalars['Int']
  readonly sentAt: Scalars['DateTime']
}

export interface QuestionnaireConnection {
  readonly __typename?: 'QuestionnaireConnection'
  readonly edges: Maybe<ReadonlyArray<Maybe<QuestionnaireEdge>>>
  readonly items: Maybe<ReadonlyArray<Maybe<Questionnaire>>>
  readonly pageInfo: PageInfo
  readonly totalCount: Maybe<Scalars['Int']>
}

export interface QuestionnaireCopyInput {
  readonly asTemplate: Scalars['Boolean']
  readonly name?: InputMaybe<Scalars['String']>
  readonly questionnaireId: Scalars['ID']
}

export interface QuestionnaireCopyOutput {
  readonly __typename?: 'QuestionnaireCopyOutput'
  readonly questionnaire: Maybe<Questionnaire>
}

export interface QuestionnaireCreateInput {
  readonly isTemplate: Scalars['Boolean']
  readonly items: ReadonlyArray<InputMaybe<QuestionnaireItemInput>>
  readonly language: QuestionnaireLangEnum
  readonly name: Scalars['String']
}

export interface QuestionnaireCreateOutput {
  readonly __typename?: 'QuestionnaireCreateOutput'
  readonly questionnaire: Maybe<Questionnaire>
}

export interface QuestionnaireDeleteOutput {
  readonly __typename?: 'QuestionnaireDeleteOutput'
  readonly isSuccess: Scalars['Boolean']
}

export interface QuestionnaireEdge {
  readonly __typename?: 'QuestionnaireEdge'
  readonly cursor: Scalars['String']
  readonly node: Maybe<Questionnaire>
}

export interface QuestionnaireEssayItem {
  readonly __typename?: 'QuestionnaireEssayItem'
  readonly answerType: ApplicableAnswerTypeEnum
  readonly id: Scalars['ID']
  readonly question: Scalars['String']
}

export interface QuestionnaireExperienceItem {
  readonly __typename?: 'QuestionnaireExperienceItem'
  readonly answerOptions: ReadonlyArray<AnswerOption>
  readonly answerType: ApplicableAnswerTypeEnum
  readonly id: Scalars['ID']
  readonly question: Scalars['String']
}

export interface QuestionnaireExperienceItemSuggest {
  readonly __typename?: 'QuestionnaireExperienceItemSuggest'
  readonly answerOptions: ReadonlyArray<AnswerOptionSuggest>
  readonly answerType: ApplicableAnswerTypeSuggestEnum
  readonly question: Scalars['String']
}

export interface QuestionnaireGiveAnswersInput {
  readonly answers: ReadonlyArray<GiveAnswerInput>
  readonly applyId: Scalars['ID']
  readonly questionnaireId: Scalars['ID']
  readonly vacancyId: Scalars['ID']
}

export interface QuestionnaireGiveAnswersOutput {
  readonly __typename?: 'QuestionnaireGiveAnswersOutput'
  readonly isSuccess: Scalars['Boolean']
}

export type QuestionnaireItem = QuestionnaireEssayItem | QuestionnaireExperienceItem | QuestionnaireLanguageItem | QuestionnaireSelectItem

export interface QuestionnaireItemInput {
  readonly answerType: ApplicableAnswerTypeEnum
  readonly extra?: InputMaybe<Scalars['String']>
  readonly id?: InputMaybe<Scalars['ID']>
  readonly options?: InputMaybe<ReadonlyArray<InputMaybe<AnswerOptionInput>>>
  readonly text?: InputMaybe<Scalars['String']>
  readonly type: QuestionnaireItemTypeEnum
}

export type QuestionnaireItemSuggest = QuestionnaireExperienceItemSuggest | QuestionnaireLanguageItemSuggest | QuestionnaireSelectItemSuggest

export enum QuestionnaireItemTypeEnum {
  CustomChoice = 'CUSTOM_CHOICE',
  Experience = 'EXPERIENCE',
  Language = 'LANGUAGE',
  OpenText = 'OPEN_TEXT'
}

export enum QuestionnaireLangEnum {
  Ru = 'RU',
  Ua = 'UA'
}

export enum QuestionnaireLangSuggestEnum {
  Ru = 'RU',
  Ua = 'UA'
}

export interface QuestionnaireLanguageItem {
  readonly __typename?: 'QuestionnaireLanguageItem'
  readonly answerOptions: ReadonlyArray<AnswerOption>
  readonly answerType: ApplicableAnswerTypeEnum
  readonly id: Scalars['ID']
  readonly language: Lang
  readonly question: Scalars['String']
}

export interface QuestionnaireLanguageItemSuggest {
  readonly __typename?: 'QuestionnaireLanguageItemSuggest'
  readonly answerOptions: ReadonlyArray<AnswerOptionSuggest>
  readonly answerType: ApplicableAnswerTypeSuggestEnum
  readonly language: LangSuggest
  readonly question: Scalars['String']
}

export interface QuestionnaireSelectItem {
  readonly __typename?: 'QuestionnaireSelectItem'
  readonly answerOptions: ReadonlyArray<AnswerOption>
  readonly answerType: ApplicableAnswerTypeEnum
  readonly id: Scalars['ID']
  readonly question: Scalars['String']
}

export interface QuestionnaireSelectItemSuggest {
  readonly __typename?: 'QuestionnaireSelectItemSuggest'
  readonly answerOptions: ReadonlyArray<AnswerOptionSuggest>
  readonly answerType: ApplicableAnswerTypeSuggestEnum
  readonly question: Scalars['String']
}

export interface QuestionnaireSuggest {
  readonly __typename?: 'QuestionnaireSuggest'
  readonly language: QuestionnaireLangSuggestEnum
  readonly name: Scalars['String']
  readonly questions: ReadonlyArray<QuestionnaireItemSuggest>
}

export interface QuestionnaireUpdateInput {
  readonly id: Scalars['ID']
  readonly isTemplate: Scalars['Boolean']
  readonly items: ReadonlyArray<InputMaybe<QuestionnaireItemInput>>
  readonly language: QuestionnaireLangEnum
  readonly name: Scalars['String']
}

export interface QuestionnaireUpdateOutput {
  readonly __typename?: 'QuestionnaireUpdateOutput'
  readonly questionnaire: Maybe<Questionnaire>
}

export interface QuestionnairesFilterInput {
  readonly onlyTemplates?: InputMaybe<Scalars['Boolean']>
}

export interface RePublishedVacancyEmployerEvent {
  readonly __typename?: 'RePublishedVacancyEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly performer: PerformerEmployerEventUnion
  readonly vacancyCity: City
  readonly vacancyId: Scalars['ID']
  readonly vacancyName: Scalars['String']
}

export enum RecommendationTypeEnum {
  All = 'ALL',
  Suitable = 'SUITABLE',
  Viewed = 'VIEWED'
}

export interface RecommendedCandidate {
  readonly __typename?: 'RecommendedCandidate'
  readonly lastActivityDate: Scalars['DateTime']
  readonly recommendationType: RecommendationTypeEnum
  readonly resume: ProfResumeResponseUnion
}

/** A connection to a list of items. */
export interface RecommendedCandidatesConnection {
  readonly __typename?: 'RecommendedCandidatesConnection'
  /** A list of edges. */
  readonly edges: Maybe<ReadonlyArray<RecommendedCandidatesEdge>>
  /** A flattened list of the nodes. */
  readonly nodes: Maybe<ReadonlyArray<RecommendedCandidate>>
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']
}

/** An edge in a connection. */
export interface RecommendedCandidatesEdge {
  readonly __typename?: 'RecommendedCandidatesEdge'
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']
  /** The item at the end of the edge. */
  readonly node: RecommendedCandidate
}

export interface RecommendedCandidatesFilter {
  readonly type: RecommendationTypeEnum
}

export interface RecommendedProfResume {
  readonly __typename?: 'RecommendedProfResume'
  readonly age: Maybe<Scalars['Int']>
  readonly city: Maybe<City>
  readonly displayName: Maybe<Scalars['String']>
  readonly experience: Maybe<ReadonlyArray<Maybe<RecommendedProfResumeExperience>>>
  readonly gender: Maybe<Gender>
  readonly id: Scalars['ID']
  readonly lastActivityDate: Maybe<Scalars['DateTime']>
  readonly photo: Maybe<Scalars['String']>
  readonly recommendationType: Maybe<RecommendedProfResumeType>
  readonly resumeSalary: Maybe<RecommendedProfResumeSalary>
  readonly speciality: Maybe<Scalars['String']>
  readonly updateDate: Maybe<Scalars['DateTime']>
}

export interface RecommendedProfResumeExperience {
  readonly __typename?: 'RecommendedProfResumeExperience'
  readonly branchId: Maybe<Scalars['Int']>
  readonly company: Maybe<Scalars['String']>
  readonly description: Maybe<Scalars['String']>
  readonly endWork: Maybe<Scalars['DateTime']>
  readonly period: Maybe<Scalars['String']>
  readonly position: Maybe<Scalars['String']>
  readonly startWork: Maybe<Scalars['DateTime']>
}

export interface RecommendedProfResumeSalary {
  readonly __typename?: 'RecommendedProfResumeSalary'
  readonly amount: Maybe<Scalars['Int']>
  readonly currency: Maybe<Currency>
}

export enum RecommendedProfResumeType {
  All = 'ALL',
  Suitable = 'SUITABLE',
  Viewed = 'VIEWED'
}

export interface RecommendedProfResumes {
  readonly __typename?: 'RecommendedProfResumes'
  readonly recommendedProfResumeList: Maybe<ReadonlyArray<Maybe<RecommendedProfResume>>>
  readonly total: Maybe<Scalars['Int']>
}

export interface RecommendedProfResumesInput {
  readonly cityId: Scalars['ID']
  readonly resumeType: RecommendedProfResumeType
  readonly vacancyDescription: Scalars['String']
  readonly vacancyTitle: Scalars['String']
}

/** A connection to a list of items. */
export interface RecommendedVacanciesConnection {
  readonly __typename?: 'RecommendedVacanciesConnection'
  /** A list of edges. */
  readonly edges: Maybe<ReadonlyArray<RecommendedVacanciesEdge>>
  /** A flattened list of the nodes. */
  readonly nodes: Maybe<ReadonlyArray<Vacancy>>
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']
}

/** An edge in a connection. */
export interface RecommendedVacanciesEdge {
  readonly __typename?: 'RecommendedVacanciesEdge'
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']
  /** The item at the end of the edge. */
  readonly node: Vacancy
}

export interface RecruitmentOrderOutput {
  readonly __typename?: 'RecruitmentOrderOutput'
  readonly isSuccessful: Scalars['Boolean']
}

export interface RegularGift {
  readonly __typename?: 'RegularGift'
  readonly cost: Scalars['Decimal']
  readonly giftType: GiftTypeEnum
  readonly id: Scalars['ID']
  readonly isDeleted: Scalars['Boolean']
  readonly isSouvenir: Scalars['Boolean']
  readonly name: Scalars['String']
  readonly standCount: Scalars['Int']
  readonly storageCount: Scalars['Int']
  readonly weight: Scalars['Int']
}

export interface RejectPaymentInput {
  readonly accountId: Scalars['ID']
  readonly accountYear: Scalars['Int']
  readonly reason: RejectPaymentReasonEnum
  readonly reasonText?: InputMaybe<Scalars['String']>
}

export enum RejectPaymentReasonEnum {
  ChangeServicesSet = 'CHANGE_SERVICES_SET',
  ClientChangedOwner = 'CLIENT_CHANGED_OWNER',
  ClientEconomicProblems = 'CLIENT_ECONOMIC_PROBLEMS',
  DisapprovedHrBudget = 'DISAPPROVED_HR_BUDGET',
  DissatisfiedUsingResult = 'DISSATISFIED_USING_RESULT',
  Other = 'OTHER',
  WrongActivation = 'WRONG_ACTIVATION',
  WrongOrder = 'WRONG_ORDER'
}

export interface RejectedByModeratorVacancyEmployerEvent {
  readonly __typename?: 'RejectedByModeratorVacancyEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly vacancyCity: City
  readonly vacancyId: Scalars['ID']
  readonly vacancyName: Scalars['String']
  readonly vacancyOwner: EmployerEventSnapshot
}

export interface RejectedProlongationRequestVacancyEmployerEvent {
  readonly __typename?: 'RejectedProlongationRequestVacancyEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly performer: PerformerEmployerEventUnion
  readonly vacancyCity: City
  readonly vacancyId: Scalars['ID']
  readonly vacancyName: Scalars['String']
}

export interface RejectedReviewVacancyEmployerEvent {
  readonly __typename?: 'RejectedReviewVacancyEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly performer: PerformerEmployerEventUnion
  readonly vacancyCity: City
  readonly vacancyId: Scalars['ID']
  readonly vacancyName: Scalars['String']
  readonly vacancyOwner: EmployerEventSnapshot
}

export interface RelevanceScore {
  readonly __typename?: 'RelevanceScore'
  readonly isCalculated: Scalars['Boolean']
  readonly value: Scalars['Float']
}

export interface RemoveSeekerFavoriteVacanciesInput {
  readonly vacancyIds: ReadonlyArray<Scalars['ID']>
}

export interface RemoveSeekerFavoriteVacanciesOutput {
  readonly __typename?: 'RemoveSeekerFavoriteVacanciesOutput'
  readonly query: Query
  readonly vacancies: ReadonlyArray<Vacancy>
}

export enum ResumeActiveStateEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Hide = 'HIDE',
  NotCreated = 'NOT_CREATED'
}

export interface ResumeAdditionalEducation {
  readonly __typename?: 'ResumeAdditionalEducation'
  readonly description: Scalars['String']
  readonly id: Scalars['ID']
  readonly location: Scalars['String']
  readonly title: Scalars['String']
  readonly yearOfGraduation: Scalars['Int']
}

export interface ResumeAdditionalInfo {
  readonly __typename?: 'ResumeAdditionalInfo'
  readonly description: Scalars['String']
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export enum ResumeAvailabilityStateEnum {
  ExceptEmployers = 'EXCEPT_EMPLOYERS',
  Hide = 'HIDE',
  MegaEmployer = 'MEGA_EMPLOYER',
  None = 'NONE',
  OnlyForVerifiedEmployer = 'ONLY_FOR_VERIFIED_EMPLOYER',
  Public = 'PUBLIC',
  StrongDefinedEmployer = 'STRONG_DEFINED_EMPLOYER'
}

export interface ResumeContactInfo {
  readonly __typename?: 'ResumeContactInfo'
  readonly email: Maybe<Scalars['String']>
  readonly emails: ReadonlyArray<Scalars['String']>
  readonly phone: Maybe<ResumePhone>
  readonly phones: ReadonlyArray<ResumePhone>
  readonly portfolios: ReadonlyArray<Scalars['Uri']>
  readonly socials: ReadonlyArray<ResumeSocialContactLink>
}

export interface ResumeDraftCreatedNotificationDetails {
  readonly __typename?: 'ResumeDraftCreatedNotificationDetails'
  readonly detailType: SeekerNotificationDetailTypeEnum
  readonly resume: Maybe<ProfResume>
}

export interface ResumeEducation {
  readonly __typename?: 'ResumeEducation'
  readonly id: Scalars['ID']
  readonly institutionTitle: Scalars['String']
  readonly level: Maybe<EducationLevelEnum>
  readonly location: Scalars['String']
  readonly speciality: Scalars['String']
  readonly yearOfGraduation: Scalars['Int']
}

export interface ResumeError {
  readonly message: Scalars['String']
}

export interface ResumeExperience {
  readonly __typename?: 'ResumeExperience'
  readonly branch: Maybe<Branch>
  readonly company: Maybe<Company>
  readonly companyName: Maybe<Scalars['String']>
  readonly description: Maybe<Scalars['String']>
  readonly endWork: Maybe<Scalars['Date']>
  readonly id: Scalars['ID']
  readonly position: Maybe<Scalars['String']>
  readonly startWork: Maybe<Scalars['Date']>
}

export interface ResumeFilling {
  readonly __typename?: 'ResumeFilling'
  readonly percentage: Scalars['Int']
}

export interface ResumeFillingType {
  readonly __typename?: 'ResumeFillingType'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export interface ResumeLanguageSkill {
  readonly __typename?: 'ResumeLanguageSkill'
  readonly certificate: Maybe<Scalars['String']>
  readonly isCanPassInterview: Scalars['Boolean']
  readonly language: Lang
  readonly level: LanguageSkillLevel
}

export interface ResumePersonalInfo {
  readonly __typename?: 'ResumePersonalInfo'
  readonly age: Maybe<Scalars['Int']>
  readonly birthDate: Maybe<Scalars['Date']>
  readonly fatherName: Maybe<Scalars['String']>
  readonly firstName: Maybe<Scalars['String']>
  readonly gender: Gender
  readonly photoUrl: Scalars['Uri']
  readonly surName: Maybe<Scalars['String']>
}

export interface ResumePhone {
  readonly __typename?: 'ResumePhone'
  readonly isConfirmed: Scalars['Boolean']
  readonly value: Scalars['String']
}

export interface ResumePublishedNotificationDetails {
  readonly __typename?: 'ResumePublishedNotificationDetails'
  readonly detailType: SeekerNotificationDetailTypeEnum
  readonly resume: Maybe<ProfResume>
}

export interface ResumeReportInput {
  readonly description: Scalars['String']
  readonly resumeId: Scalars['ID']
}

export interface ResumeReportOutput {
  readonly __typename?: 'ResumeReportOutput'
  readonly isSuccessful: Scalars['Boolean']
}

export interface ResumeSalary {
  readonly __typename?: 'ResumeSalary'
  readonly amount: Scalars['Float']
  readonly currency: Currency
}

export enum ResumeSearchStateEnum {
  Active = 'ACTIVE',
  None = 'NONE',
  NowWorking = 'NOW_WORKING'
}

export interface ResumeSearchSuggest {
  readonly __typename?: 'ResumeSearchSuggest'
  readonly fullOption: Scalars['String']
  readonly score: Scalars['Int']
  readonly suggest: Scalars['String']
}

export interface ResumeSocialContactLink {
  readonly __typename?: 'ResumeSocialContactLink'
  readonly type: ContactInfoSubTypeEnum
  readonly value: Scalars['String']
}

export interface ResumeState {
  readonly __typename?: 'ResumeState'
  readonly availabilityState: ResumeAvailabilityStateEnum
  readonly banInfo: BanInfo
  readonly hiddenCompanies: ReadonlyArray<Company>
  readonly isAllowedToShareWithPartners: Scalars['Boolean']
  readonly isAnonymous: Scalars['Boolean']
  readonly isBannedByModerator: Scalars['Boolean']
  readonly searchState: ResumeSearchStateEnum
  readonly state: ResumeActiveStateEnum
  /** @deprecated Use resume.views.newCount instead */
  readonly viewsCount: Scalars['Int']
}

export interface ResumeViewInfo {
  readonly __typename?: 'ResumeViewInfo'
  readonly company: Company
  readonly resume: ProfResume
  readonly viewDate: Scalars['DateTime']
}

/** A connection to a list of items. */
export interface ResumeViewsConnection {
  readonly __typename?: 'ResumeViewsConnection'
  /** A list of edges. */
  readonly edges: Maybe<ReadonlyArray<ResumeViewsEdge>>
  readonly items: Maybe<ReadonlyArray<ResumeViewInfo>>
  readonly newCount: Scalars['Int']
  /** A flattened list of the nodes. */
  readonly nodes: Maybe<ReadonlyArray<ResumeViewInfo>>
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']
}

/** An edge in a connection. */
export interface ResumeViewsEdge {
  readonly __typename?: 'ResumeViewsEdge'
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']
  /** The item at the end of the edge. */
  readonly node: ResumeViewInfo
}

export interface RisingUpCatalogService {
  readonly __typename?: 'RisingUpCatalogService'
  readonly calculatePrice: ServicePrice
  readonly groupWrapper: ServiceGroupWrapper
  readonly id: Scalars['ID']
  readonly name: Maybe<Scalars['String']>
  readonly packagesCount: Maybe<Scalars['Int']>
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly typeWrapper: ServiceTypeWrapper
}

export interface RisingUpCatalogServiceCalculatePriceArgs {
  input: InputMaybe<CalculateServicePriceInput>
}

export enum RolesEnum {
  Marketing = 'Marketing',
  Sale = 'Sale'
}

export interface Rubric {
  readonly __typename?: 'Rubric'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly statistic: Maybe<StatisticData>
  readonly subrubrics: ReadonlyArray<Subrubric>
}

export interface RubricStatisticArgs {
  input: StatisticDataCityKeywordInput
}

export interface RubricFacet {
  readonly __typename?: 'RubricFacet'
  readonly count: Scalars['Long']
  readonly rubric: Rubric
  readonly subrubricsFacets: ReadonlyArray<SubrubricsFacet>
}

export interface SalaryStatistics {
  readonly __typename?: 'SalaryStatistics'
  readonly futurePeriodsMedians: Maybe<ReadonlyArray<Maybe<PeriodAmount>>>
  readonly previousMonthCount: Maybe<PeriodAmount>
  readonly previousMonthHistogram: Maybe<ReadonlyArray<Maybe<Bucket>>>
  readonly previousMonthMax: Maybe<Scalars['Int']>
  readonly previousMonthMedian: Maybe<Scalars['Int']>
  readonly previousMonthMin: Maybe<Scalars['Int']>
  readonly previousPeriodsMedians: Maybe<ReadonlyArray<Maybe<PeriodAmount>>>
}

export interface SaleManagerMutations {
  readonly __typename?: 'SaleManagerMutations'
  readonly addManagerAlarm: SaleManagerResponseOutput
  readonly addOtherSiteLink: AddOtherSiteLinkOutput
  readonly addTypicalContact: ContractCreationOutput
  readonly deleteContract: SaleManagerResponseOutput
  readonly rejectPayment: CompanyAssignmentOutput
  readonly unbindPayer: UnlinkPayerOutput
  readonly updatePayer: SaleManagerResponseOutput
}

export interface SaleManagerMutationsAddManagerAlarmArgs {
  input: AddManagerAlarmInput
}

export interface SaleManagerMutationsAddOtherSiteLinkArgs {
  input: AddOtherSiteLinkInput
}

export interface SaleManagerMutationsAddTypicalContactArgs {
  input: AddContactInput
}

export interface SaleManagerMutationsDeleteContractArgs {
  contractId: Scalars['ID']
  typical: Scalars['Boolean']
}

export interface SaleManagerMutationsRejectPaymentArgs {
  input: RejectPaymentInput
}

export interface SaleManagerMutationsUnbindPayerArgs {
  companyId: Scalars['ID']
  payerId: Scalars['ID']
}

export interface SaleManagerMutationsUpdatePayerArgs {
  input: PayerEditInput
}

export interface SaleManagerQueries {
  readonly __typename?: 'SaleManagerQueries'
  readonly checkPayer: CheckPayerResultOutput
  readonly companyCards: Maybe<CompanyCardConnection>
  readonly helperCompanies: Maybe<HelperCompanyConnection>
  readonly payer: Payer
  readonly payerResponsibleBasis: ReadonlyArray<DirectoryItem>
  readonly ticketsAway: ReadonlyArray<CompanyCardTicket>
  readonly ticketsWillBurn: ReadonlyArray<CompanyCardTicket>
}

export interface SaleManagerQueriesCheckPayerArgs {
  code: Scalars['ID']
  companyId: Scalars['ID']
}

export interface SaleManagerQueriesCompanyCardsArgs {
  after: InputMaybe<Scalars['String']>
  filter: InputMaybe<CompanyCardFilterInput>
  first: InputMaybe<Scalars['Int']>
}

export interface SaleManagerQueriesHelperCompaniesArgs {
  after: InputMaybe<Scalars['String']>
  filter: InputMaybe<FilterHelperCompaniesInput>
  first: InputMaybe<Scalars['Int']>
}

export interface SaleManagerQueriesPayerArgs {
  payerId: Scalars['ID']
}

export interface SaleManagerQueriesTicketsAwayArgs {
  input: TicketCardFilterInput
}

export interface SaleManagerQueriesTicketsWillBurnArgs {
  input: TicketCardFilterInput
}

export interface SaleManagerResponseOutput {
  readonly __typename?: 'SaleManagerResponseOutput'
  readonly id: Maybe<Scalars['ID']>
  readonly isSuccess: Scalars['Boolean']
  readonly messageText: Maybe<Scalars['String']>
}

export type SaveVacancyInterviewQuestionsErrorUnion = InvalidError | NotFoundError

export interface SaveVacancyInterviewQuestionsInput {
  readonly questions: Scalars['String']
  readonly vacancyId: Scalars['ID']
}

export interface SaveVacancyInterviewQuestionsOutput {
  readonly __typename?: 'SaveVacancyInterviewQuestionsOutput'
  readonly errors: ReadonlyArray<SaveVacancyInterviewQuestionsErrorUnion>
  readonly isSuccess: Scalars['Boolean']
  readonly questions: Maybe<Scalars['String']>
}

export interface SavedCard {
  readonly __typename?: 'SavedCard'
  readonly addDate: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly isMain: Scalars['Boolean']
  readonly mask: Scalars['String']
  readonly paymentSystem: PaymentSystemTypeEnum
  readonly provider: Scalars['String']
}

export interface SavedCardsMutations {
  readonly __typename?: 'SavedCardsMutations'
  readonly add: ChangeSavedCardsResponse
  readonly makeMain: ChangeSavedCardsResponse
  readonly remove: ChangeSavedCardsResponse
}

export interface SavedCardsMutationsAddArgs {
  isForOnePayment: Scalars['Boolean']
  mask: Scalars['String']
  token: Scalars['String']
}

export interface SavedCardsMutationsMakeMainArgs {
  id: Scalars['Guid']
}

export interface SavedCardsMutationsRemoveArgs {
  id: Scalars['Guid']
}

export interface Schedule {
  readonly __typename?: 'Schedule'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export enum ScheduleEnum {
  FullTime = 'FULL_TIME',
  Internship = 'INTERNSHIP',
  PartTime = 'PART_TIME',
  ProjectBased = 'PROJECT_BASED',
  Remote = 'REMOTE',
  ShiftWork = 'SHIFT_WORK',
  Temporary = 'TEMPORARY'
}

export interface ScheduleFacet {
  readonly __typename?: 'ScheduleFacet'
  readonly count: Scalars['Long']
  readonly schedule: Schedule
}

export interface ScrollInfo {
  readonly __typename?: 'ScrollInfo'
  readonly currentOffset: Maybe<Scalars['String']>
  readonly hasNextOffset: Scalars['Boolean']
  readonly nextOffset: Maybe<Scalars['String']>
}

export interface SeekerApplication {
  readonly __typename?: 'SeekerApplication'
  readonly isApplied: Scalars['Boolean']
  readonly lastTimeAppliedAt: Maybe<Scalars['DateTime']>
}

export interface SeekerAppliesInput {
  readonly pagination: SeekerAppliesPaginationInput
}

export interface SeekerAppliesPaginationInput {
  readonly count: Scalars['Int']
  readonly offset: Scalars['Int']
}

export interface SeekerApplyQueries {
  readonly __typename?: 'SeekerApplyQueries'
  readonly list: ReadonlyArray<Apply>
  readonly totalCount: Scalars['Int']
}

export interface SeekerApplyQueriesListArgs {
  input: SeekerAppliesInput
}

export interface SeekerDislikedVacanciesPaginationInput {
  readonly skip: Scalars['UInt']
  readonly take: Scalars['UInt']
}

export interface SeekerDislikedVacancyDetails {
  readonly __typename?: 'SeekerDislikedVacancyDetails'
  readonly dislikedAt: Maybe<Scalars['DateTime']>
  readonly isDisliked: Scalars['Boolean']
}

export interface SeekerDislikedVacancyMutations {
  readonly __typename?: 'SeekerDislikedVacancyMutations'
  readonly dislike: DislikeSeekerVacanciesOutput
  readonly undislike: UndislikeSeekerVacanciesOutput
}

export interface SeekerDislikedVacancyMutationsDislikeArgs {
  input: DislikeSeekerVacanciesInput
}

export interface SeekerDislikedVacancyMutationsUndislikeArgs {
  input: UndislikeSeekerVacanciesInput
}

export interface SeekerDislikedVacancyQueries {
  readonly __typename?: 'SeekerDislikedVacancyQueries'
  readonly list: ListSeekerDislikedVacanciesOutput
  readonly totalCount: GetSeekerDislikedVacanciesTotalCountOutput
}

export interface SeekerDislikedVacancyQueriesListArgs {
  input: ListSeekerDislikedVacanciesInput
}

export interface SeekerFavoriteVacanciesPaginationInput {
  readonly skip: Scalars['UInt']
  readonly take: Scalars['UInt']
}

export interface SeekerFavoriteVacancyDetails {
  readonly __typename?: 'SeekerFavoriteVacancyDetails'
  readonly addedAt: Maybe<Scalars['DateTime']>
  readonly isFavorite: Scalars['Boolean']
}

export interface SeekerFavoriteVacancyMutations {
  readonly __typename?: 'SeekerFavoriteVacancyMutations'
  readonly add: AddSeekerFavoriteVacanciesOutput
  readonly remove: RemoveSeekerFavoriteVacanciesOutput
}

export interface SeekerFavoriteVacancyMutationsAddArgs {
  input: AddSeekerFavoriteVacanciesInput
}

export interface SeekerFavoriteVacancyMutationsRemoveArgs {
  input: RemoveSeekerFavoriteVacanciesInput
}

export interface SeekerFavoriteVacancyQueries {
  readonly __typename?: 'SeekerFavoriteVacancyQueries'
  readonly list: ListSeekerFavoriteVacanciesOutput
  readonly totalCount: GetSeekerFavoriteVacanciesTotalCountOutput
}

export interface SeekerFavoriteVacancyQueriesListArgs {
  input: InputMaybe<ListSeekerFavoriteVacanciesInput>
}

export interface SeekerMutations {
  readonly __typename?: 'SeekerMutations'
  readonly changeEmail: ChangeSeekerEmailMutation
  readonly changeName: ChangeSeekerNameMutationOutput
  readonly changePassword: ChangeSeekerPasswordMutations
}

export interface SeekerMutationsChangeNameArgs {
  input: ChangeSeekerNameMutationInput
}

export enum SeekerNotificationDetailTypeEnum {
  ApplicationInvited = 'APPLICATION_INVITED',
  ApplicationRefused = 'APPLICATION_REFUSED',
  ApplicationSent = 'APPLICATION_SENT',
  ApplicationViewed = 'APPLICATION_VIEWED',
  DailyResumeViewsReportCalculated = 'DAILY_RESUME_VIEWS_REPORT_CALCULATED',
  FavoriteVacanciesReminded = 'FAVORITE_VACANCIES_REMINDED',
  JobInstantReceived = 'JOB_INSTANT_RECEIVED',
  JobRecommendationReceived = 'JOB_RECOMMENDATION_RECEIVED',
  Other = 'OTHER'
}

export interface SeekerRecommendationProfile {
  readonly __typename?: 'SeekerRecommendationProfile'
  readonly cities: ReadonlyArray<City>
  readonly id: Scalars['ID']
  readonly isTop: Scalars['Boolean']
  readonly keywords: ReadonlyArray<Keyword>
  readonly name: Scalars['String']
  readonly weight: Scalars['Float']
}

export interface SeekerRecommendationProfileCitiesArgs {
  count: InputMaybe<Scalars['Int']>
}

export interface SeekerRecommendationProfileKeywordsArgs {
  count: InputMaybe<Scalars['Int']>
}

export interface SeekerRegistration {
  readonly __typename?: 'SeekerRegistration'
  readonly createProfile: CreateSeekerProfileResponse
}

export interface SeekerRegistrationCreateProfileArgs {
  input: CreateSeekerProfileInput
}

export interface SeekerSubscription {
  readonly __typename?: 'SeekerSubscription'
  readonly addDate: Scalars['DateTime']
  readonly channels: ReadonlyArray<SeekerSubscriptionChannel>
  readonly id: Scalars['ID']
  readonly isActive: Scalars['Boolean']
  readonly isActiveProfile: Maybe<Scalars['Boolean']>
  readonly language: SubscriptionLanguageEnum
  readonly profile: Maybe<SeekerSubscriptionProfile>
  readonly type: SubscriptionTypeEnum
}

export interface SeekerSubscriptionActivation {
  readonly __typename?: 'SeekerSubscriptionActivation'
  readonly all: SubscriptionOutputType
  readonly byId: SubscriptionOutputType
  readonly byProfile: SubscriptionOutputType
  /** @deprecated Use byTypes */
  readonly byType: SubscriptionOutputType
  readonly byTypes: SubscriptionOutputType
  readonly manual: SubscriptionOutputType
  readonly recommended: SubscriptionOutputType
}

export interface SeekerSubscriptionActivationByIdArgs {
  id: Scalars['ID']
}

export interface SeekerSubscriptionActivationByProfileArgs {
  profileId: Scalars['ID']
}

export interface SeekerSubscriptionActivationByTypeArgs {
  type: SubscriptionTypeEnum
}

export interface SeekerSubscriptionActivationByTypesArgs {
  types: ReadonlyArray<SubscriptionTypeEnum>
}

export interface SeekerSubscriptionChannel {
  readonly __typename?: 'SeekerSubscriptionChannel'
  readonly addDate: Maybe<Scalars['DateTime']>
  readonly id: Scalars['ID']
  readonly lastUpdateDate: Maybe<Scalars['DateTime']>
  readonly token: Scalars['String']
  readonly type: SubscriptionChannelTypeEnum
}

export interface SeekerSubscriptionManualProfile {
  readonly __typename?: 'SeekerSubscriptionManualProfile'
  readonly city: Maybe<City>
  readonly company: Maybe<Company>
  readonly id: Scalars['ID']
  readonly isActive: Scalars['Boolean']
  readonly keyword: Maybe<Scalars['String']>
  readonly keywordSynonyms: ReadonlyArray<Synonym>
  readonly keywords: ReadonlyArray<Keyword>
  /** @deprecated Use keywords + city */
  readonly name: Scalars['String']
  readonly rubric: Maybe<Rubric>
}

export interface SeekerSubscriptionManualProfileInput {
  readonly cityId: Scalars['ID']
  readonly companyId?: InputMaybe<Scalars['ID']>
  readonly rubricId?: InputMaybe<Scalars['ID']>
  readonly subscriptionId: Scalars['ID']
  readonly synonymsIds: ReadonlyArray<Scalars['ID']>
}

export interface SeekerSubscriptionMutations {
  readonly __typename?: 'SeekerSubscriptionMutations'
  readonly addSeekerSubscription: AddSeekerSubscriptionMutations
  readonly changeLanguage: SubscriptionOutputType
  readonly deleteSubscription: SubscriptionOutputType
  readonly setActive: SeekerSubscriptionActivation
  readonly updateManualProfile: SubscriptionOutputType
}

export interface SeekerSubscriptionMutationsChangeLanguageArgs {
  lang: SubscriptionLanguageEnum
}

export interface SeekerSubscriptionMutationsDeleteSubscriptionArgs {
  id: Scalars['ID']
}

export interface SeekerSubscriptionMutationsSetActiveArgs {
  isActive: Scalars['Boolean']
}

export interface SeekerSubscriptionMutationsUpdateManualProfileArgs {
  input: SeekerSubscriptionManualProfileInput
}

export type SeekerSubscriptionProfile = SeekerRecommendationProfile | SeekerSubscriptionManualProfile

export interface SeekerSubscriptionSummary {
  readonly __typename?: 'SeekerSubscriptionSummary'
  readonly channels: ReadonlyArray<SeekerSubscriptionChannel>
  readonly count: Scalars['Int']
  readonly isContainsActiveSubscription: Scalars['Boolean']
  readonly isContainsProfile: Scalars['Boolean']
  readonly type: SubscriptionTypeEnum
}

export interface SeekerSubscriptionsFilterInput {
  readonly types?: InputMaybe<ReadonlyArray<SubscriptionTypeEnum>>
}

export interface SeekerWalletUser {
  readonly __typename?: 'SeekerWalletUser'
  readonly seeker: User
}

export enum SegmentationMechanicsEnum {
  NotSet = 'NOT_SET',
  Relation = 'RELATION',
  RelationNotification = 'RELATION_NOTIFICATION',
  Transaction = 'TRANSACTION'
}

export enum SelectPromoCodeErrorEnum {
  CannotBeSelectedNoMatchingCartItem = 'CANNOT_BE_SELECTED_NO_MATCHING_CART_ITEM',
  CannotBeSelectedWrongState = 'CANNOT_BE_SELECTED_WRONG_STATE',
  EmptyCart = 'EMPTY_CART',
  NotFound = 'NOT_FOUND',
  ServerError = 'SERVER_ERROR'
}

export interface SelectPromoCodeResponse {
  readonly __typename?: 'SelectPromoCodeResponse'
  readonly cart: CartQuery
  readonly errors: ReadonlyArray<SelectPromoCodeErrorEnum>
  readonly isSuccess: Scalars['Boolean']
}

export interface SendChangeSeekerEmailOutput {
  readonly __typename?: 'SendChangeSeekerEmailOutput'
  readonly sendEmailOutput: Maybe<SendEmailOutput>
  readonly status: SendChangeSeekerEmailStatusEnum
}

export enum SendChangeSeekerEmailStatusEnum {
  EmailAlreadyExists = 'EMAIL_ALREADY_EXISTS',
  Failed = 'FAILED',
  Success = 'SUCCESS',
  WrongCredentials = 'WRONG_CREDENTIALS'
}

export interface SendConfirmCodeEmailInput {
  readonly email: Scalars['String']
}

export interface SendEmailOutput {
  readonly __typename?: 'SendEmailOutput'
  readonly remainingAttempts: Scalars['Int']
  readonly status: SendEmailStatusEnum
}

export enum SendEmailStatusEnum {
  Failed = 'FAILED',
  FailedUserDontExists = 'FAILED_USER_DONT_EXISTS',
  RejectedAttemptsLimit = 'REJECTED_ATTEMPTS_LIMIT',
  RejectedRateLimit = 'REJECTED_RATE_LIMIT',
  RejectedWrongEmail = 'REJECTED_WRONG_EMAIL',
  SelectedForDelivery = 'SELECTED_FOR_DELIVERY',
  UserBlocked = 'USER_BLOCKED'
}

export interface SendLoginEmailInput {
  readonly email: Scalars['String']
}

export interface SendNotificationSetEmailDetails {
  readonly __typename?: 'SendNotificationSetEmailDetails'
  readonly detailType: SeekerNotificationDetailTypeEnum
}

export interface SendOrderLetterInput {
  readonly accountId?: InputMaybe<Scalars['ID']>
  readonly accountYear?: InputMaybe<Scalars['Int']>
  readonly companyId?: InputMaybe<Scalars['ID']>
  readonly email?: InputMaybe<Scalars['String']>
  readonly message?: InputMaybe<Scalars['String']>
  readonly useStamp?: InputMaybe<Scalars['Boolean']>
}

export interface SendRegistrationConfirmEmailInput {
  readonly email: Scalars['String']
}

export interface SendSmsOutput {
  readonly __typename?: 'SendSmsOutput'
  readonly remainingAttempts: Scalars['Int']
  readonly status: SendSmsStatusEnum
}

export enum SendSmsStatusEnum {
  Failed = 'FAILED',
  RejectedAttemptsLimit = 'REJECTED_ATTEMPTS_LIMIT',
  SelectedForDelivery = 'SELECTED_FOR_DELIVERY'
}

export interface SentToReviewVacancyEmployerEvent {
  readonly __typename?: 'SentToReviewVacancyEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly performer: PerformerEmployerEventUnion
  readonly vacancyCity: City
  readonly vacancyId: Scalars['ID']
  readonly vacancyName: Scalars['String']
}

export interface ServerError extends Error {
  readonly __typename?: 'ServerError'
  readonly reason: Scalars['String']
}

export interface ServiceActivationInput {
  readonly id?: InputMaybe<Scalars['ID']>
}

export interface ServiceActivationOutput {
  readonly __typename?: 'ServiceActivationOutput'
  readonly id: Maybe<Scalars['ID']>
  readonly state: Maybe<ServiceState>
}

export interface ServiceActivator {
  readonly __typename?: 'ServiceActivator'
  readonly name: Maybe<Scalars['String']>
  readonly type: Maybe<ServiceActivatorType>
}

export enum ServiceActivatorType {
  Employer = 'EMPLOYER',
  Manager = 'MANAGER'
}

export interface ServiceCompany {
  readonly __typename?: 'ServiceCompany'
  readonly hasServicesLimitAccess: Maybe<Scalars['Boolean']>
  readonly id: Scalars['ID']
  readonly myOrders: Maybe<OrderConnection>
  readonly myServices: Maybe<MyServicesConnection>
  readonly name: Scalars['String']
  readonly onlyMainUserCanActivateService: Maybe<Scalars['Boolean']>
  readonly summaryServices: Maybe<ReadonlyArray<Maybe<SummaryService>>>
  readonly turbotaStatus: ServiceCompanyTurbotaStatus
}

export interface ServiceCompanyMyOrdersArgs {
  after: InputMaybe<Scalars['String']>
  filter: InputMaybe<FilterCompanyOrdersRequest>
  first: InputMaybe<Scalars['Int']>
}

export interface ServiceCompanyMyServicesArgs {
  after: InputMaybe<Scalars['String']>
  filter: InputMaybe<FilterMyServicesInput>
  first: InputMaybe<Scalars['Int']>
}

export interface ServiceCompanySummaryServicesArgs {
  orderBy: InputMaybe<SummaryServiceOrderBy>
}

export interface ServiceCompanyTurbotaStatus {
  readonly __typename?: 'ServiceCompanyTurbotaStatus'
  readonly activatedAt: Maybe<Scalars['DateTime']>
  readonly endsAt: Maybe<Scalars['DateTime']>
  readonly hasTurbotaAccess: Scalars['Boolean']
  readonly isActiveNow: Scalars['Boolean']
  readonly order: Maybe<Order>
}

export enum ServiceContactsAccrualPeriod {
  Day = 'DAY',
  Month = 'MONTH'
}

export interface ServiceCount {
  readonly serviceStates?: InputMaybe<ReadonlyArray<ServiceState>>
  readonly serviceTypes?: InputMaybe<ReadonlyArray<ServiceType>>
}

export interface ServiceCvdbRegionsOutput {
  readonly __typename?: 'ServiceCvdbRegionsOutput'
  readonly cities: Maybe<ReadonlyArray<Maybe<City>>>
}

export interface ServiceCvdbRubricsOutput {
  readonly __typename?: 'ServiceCvdbRubricsOutput'
  readonly rubrics: Maybe<ReadonlyArray<Maybe<Rubric>>>
}

export interface ServiceDiscountTypeWrapper {
  readonly __typename?: 'ServiceDiscountTypeWrapper'
  readonly id: Scalars['ID']
  readonly type: CatalogServiceDiscountEnum
}

export interface ServiceEmployee {
  readonly __typename?: 'ServiceEmployee'
  readonly company: Maybe<Company>
  readonly id: Scalars['ID']
  readonly serviceLimitCounters: Maybe<ReadonlyArray<Maybe<EmployeeLimitCounter>>>
  readonly serviceLimits: Maybe<ReadonlyArray<Maybe<EmployeeSummaryServiceLimit>>>
}

export interface ServiceEmployeeServiceLimitCountersArgs {
  orderBy: InputMaybe<SummaryServiceOrderBy>
}

export interface ServiceEmployeeServiceLimitsArgs {
  orderBy: InputMaybe<SummaryServiceOrderBy>
}

export type ServiceEmployerEventSnapshotUnion =
  | AdditionalServiceEmployerEventSnapshot
  | CvDbServiceEmployerEventSnapshot
  | HotServiceEmployerEventSnapshot
  | LogoInPortalServiceEmployerEventSnapshot
  | LogoInRubricServiceEmployerEventSnapshot
  | LogoOnMainPageServiceEmployerEventSnapshot
  | MarketingServiceEmployerEventSnapshot
  | PackageServiceEmployerEventSnapshot
  | PublicationServiceEmployerEventSnapshot

export enum ServiceGroupEnum {
  AdditionalService = 'ADDITIONAL_SERVICE',
  CvDb = 'CV_DB',
  Marketing = 'MARKETING',
  Vacancy = 'VACANCY'
}

export interface ServiceGroupWrapper {
  readonly __typename?: 'ServiceGroupWrapper'
  readonly group: ServiceGroupEnum
  readonly id: Scalars['ID']
}

export interface ServiceIsExpiringEvent extends EventInterface {
  readonly __typename?: 'ServiceIsExpiringEvent'
  readonly addDate: Scalars['DateTimeOffset']
  readonly assignment: Maybe<Assignment>
  readonly company: Maybe<CrmCompany>
  readonly daysLeft: Maybe<Scalars['Int']>
  readonly dealNumber: Scalars['Int']
  readonly employee: Maybe<CrmEmployee>
  readonly eventType: EventTypeEnum
  readonly expirationDate: Maybe<Scalars['DateTimeOffset']>
  readonly id: Scalars['ID']
  readonly isArchieved: Scalars['Boolean']
  readonly isRead: Scalars['Boolean']
  readonly lifetimeDays: Scalars['Int']
  readonly orderNumber: Scalars['Int']
  readonly serviceLeftCount: Maybe<Scalars['Int']>
  readonly serviceName: Maybe<Scalars['String']>
  readonly state: EventStateEnum
  readonly topics: ReadonlyArray<Topic>
}

export interface ServiceLimit {
  readonly __typename?: 'ServiceLimit'
  readonly count: Maybe<Scalars['Int']>
  readonly id: Maybe<Scalars['ID']>
}

export interface ServiceLimitInput {
  readonly count?: InputMaybe<Scalars['Int']>
  readonly has?: InputMaybe<Scalars['Boolean']>
  readonly id?: InputMaybe<Scalars['ID']>
}

export interface ServiceLimitRequestInput {
  readonly count?: InputMaybe<Scalars['Int']>
  readonly serviceType?: InputMaybe<ServiceType>
}

export interface ServicePrice {
  readonly __typename?: 'ServicePrice'
  readonly actionDiscountAmount: Scalars['Float']
  readonly appliedBonusAmount: Scalars['Float']
  readonly currentTotalPrice: Scalars['Float']
  readonly currentUnitPrice: Scalars['Float']
  readonly defaultTotalPrice: Scalars['Float']
  readonly defaultUnitPrice: Scalars['Float']
  readonly discountValueFixed: Scalars['Float']
  readonly discountValuePercent: Scalars['Float']
  readonly discounts: ReadonlyArray<ServiceDiscountTypeWrapper>
  readonly optDiscountsAmount: Scalars['Float']
  readonly promoCodeDiscountsAmount: Scalars['Float']
  readonly requestedCount: Scalars['Int']
}

export interface ServicePurchaseHistoryOutput {
  readonly __typename?: 'ServicePurchaseHistoryOutput'
  readonly everBoughtCVDB: Maybe<Scalars['Boolean']>
  readonly everBoughtPackages: Maybe<Scalars['Boolean']>
  readonly everBoughtPublications: Maybe<Scalars['Boolean']>
  readonly lastPurchasedServices: ReadonlyArray<Maybe<ServicePurchasedService>>
}

export interface ServicePurchaseHistoryOutputLastPurchasedServicesArgs {
  serviceTypes: InputMaybe<ReadonlyArray<InputMaybe<ServiceTypeGraphType>>>
}

export interface ServicePurchasedService {
  readonly __typename?: 'ServicePurchasedService'
  readonly city: Maybe<City>
  readonly count: Maybe<Scalars['Int']>
  readonly date: Maybe<Scalars['Date']>
  readonly id: Scalars['ID']
  readonly price: ServicePrice
  readonly rubric: Maybe<Rubric>
  readonly type: ServiceTypeWrapper
  readonly usagePeriod: Maybe<UsagePeriodTypeWrapper>
}

export enum ServiceState {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Ordered = 'ORDERED'
}

export enum ServiceType {
  AnnouncementInMailing = 'ANNOUNCEMENT_IN_MAILING',
  Anonymous = 'ANONYMOUS',
  Business = 'BUSINESS',
  BusinessSingle = 'BUSINESS_SINGLE',
  CompanyProfile = 'COMPANY_PROFILE',
  CvDb = 'CV_DB',
  FacebookPost = 'FACEBOOK_POST',
  FreeBusiness = 'FREE_BUSINESS',
  Hot = 'HOT',
  HrCamp = 'HR_CAMP',
  InstagramStories = 'INSTAGRAM_STORIES',
  Interview = 'INTERVIEW',
  LogoInPortal = 'LOGO_IN_PORTAL',
  LogoInRubric = 'LOGO_IN_RUBRIC',
  LogoOnMainPage = 'LOGO_ON_MAIN_PAGE',
  MarketingPackage_1 = 'MARKETING_PACKAGE_1',
  MarketingPackage_2 = 'MARKETING_PACKAGE_2',
  MarketingPackage_3 = 'MARKETING_PACKAGE_3',
  MarketingPackage_4 = 'MARKETING_PACKAGE_4',
  MaterialBranding = 'MATERIAL_BRANDING',
  MaterialPlacement = 'MATERIAL_PLACEMENT',
  Optimum = 'OPTIMUM',
  OptimumSingle = 'OPTIMUM_SINGLE',
  Professional = 'PROFESSIONAL',
  ProfessionalSingle = 'PROFESSIONAL_SINGLE',
  RisingUp = 'RISING_UP',
  TargetedMailing = 'TARGETED_MAILING',
  Test = 'TEST'
}

export enum ServiceTypeGraphType {
  AnnouncementInMailing = 'ANNOUNCEMENT_IN_MAILING',
  Anonymous = 'ANONYMOUS',
  Business = 'BUSINESS',
  BusinessSingle = 'BUSINESS_SINGLE',
  CompanyProfile = 'COMPANY_PROFILE',
  CvDb = 'CV_DB',
  FacebookPost = 'FACEBOOK_POST',
  FreeBusiness = 'FREE_BUSINESS',
  Hot = 'HOT',
  HrCamp = 'HR_CAMP',
  HrProAwards = 'HR_PRO_AWARDS',
  InstagramStories = 'INSTAGRAM_STORIES',
  Interview = 'INTERVIEW',
  LogoInPortal = 'LOGO_IN_PORTAL',
  LogoInRubric = 'LOGO_IN_RUBRIC',
  LogoOnMainPage = 'LOGO_ON_MAIN_PAGE',
  MarketingPackage_1 = 'MARKETING_PACKAGE_1',
  MarketingPackage_2 = 'MARKETING_PACKAGE_2',
  MarketingPackage_3 = 'MARKETING_PACKAGE_3',
  MarketingPackage_4 = 'MARKETING_PACKAGE_4',
  MaterialBranding = 'MATERIAL_BRANDING',
  MaterialPlacement = 'MATERIAL_PLACEMENT',
  Optimum = 'OPTIMUM',
  OptimumSingle = 'OPTIMUM_SINGLE',
  Professional = 'PROFESSIONAL',
  ProfessionalSingle = 'PROFESSIONAL_SINGLE',
  RisingUp = 'RISING_UP',
  TargetedMailing = 'TARGETED_MAILING',
  Test = 'TEST'
}

export interface ServiceTypeWrapper {
  readonly __typename?: 'ServiceTypeWrapper'
  readonly id: Scalars['ID']
  readonly type: ServiceType
}

export enum ServiceUnitEnum {
  HalfYear = 'HALF_YEAR',
  Month = 'MONTH',
  Piece = 'PIECE',
  Quater = 'QUATER',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type ServiceUnited =
  | ActivatedAdditionalService
  | ActivatedCvDbService
  | ActivatedHotService
  | ActivatedLogoInPortalService
  | ActivatedLogoInRubricService
  | ActivatedRisingUpService
  | ActivatedVacancyPackageService
  | ActivatedVacancyPublicationService
  | ClosedAdditionalService
  | ClosedCvDbService
  | ClosedHotService
  | ClosedLogoInPortalService
  | ClosedLogoInRubricService
  | ClosedMarketingService
  | ClosedRisingUpService
  | ClosedVacancyPackageService
  | ClosedVacancyPublicationService
  | OrderedAdditionalService
  | OrderedCvDbService
  | OrderedHotService
  | OrderedLogoInPortalService
  | OrderedLogoInRubricService
  | OrderedMarketingService
  | OrderedRisingUpService
  | OrderedVacancyPackageService
  | OrderedVacancyPublicationService

export interface ServiceUnitedEdge {
  readonly __typename?: 'ServiceUnitedEdge'
  readonly cursor: Scalars['String']
  readonly node: Maybe<ServiceUnited>
}

export enum ServiceUsagePeriod {
  HalfYear = 'HALF_YEAR',
  Month = 'MONTH',
  Piece = 'PIECE',
  Quarter = 'QUARTER',
  Week = 'WEEK',
  Year = 'YEAR'
}

export interface ServicesFilters {
  readonly __typename?: 'ServicesFilters'
  readonly dateRange: Maybe<DateInterval>
  readonly managerId: Maybe<Scalars['ID']>
  readonly offset: Maybe<Scalars['String']>
  readonly payStatusIds: Maybe<ReadonlyArray<Maybe<CrmPayState>>>
  readonly paymentDateRange: Maybe<DateInterval>
  readonly positionStates: Maybe<ReadonlyArray<CrmPositionState>>
  readonly query: Maybe<Scalars['String']>
  readonly serviceId: Maybe<Scalars['ID']>
}

export interface ServicesManagementClientFilters {
  readonly __typename?: 'ServicesManagementClientFilters'
  readonly states: Maybe<ReadonlyArray<ServiceState>>
  readonly types: Maybe<ReadonlyArray<UiServiceType>>
}

export interface ServicesManagementClientFiltersInput {
  readonly states?: InputMaybe<ReadonlyArray<ServiceState>>
  readonly types?: InputMaybe<ReadonlyArray<UiServiceType>>
}

export interface SetActionStateOutput {
  readonly __typename?: 'SetActionStateOutput'
  readonly isSuccess: Scalars['Boolean']
  readonly message: Scalars['String']
}

export interface SetCvRubricInput {
  readonly cvId: Scalars['ID']
  readonly rubrics: ReadonlyArray<CvRubricInput>
}

export interface SetCvStateInput {
  readonly assingToCurrentUser: Scalars['Boolean']
  readonly id: Scalars['ID']
  readonly state: ParsedCvStateEnum
}

export interface SetGiftRangeInput {
  readonly giftId: Scalars['ID']
  readonly isInRange: Scalars['Boolean']
  readonly rangeId: Scalars['ID']
}

export interface SetStateGiftRequestInput {
  readonly id: Scalars['ID']
  readonly state: GiftRequestStateEnum
}

export interface SetVacancyCandidateKeywordsInput {
  readonly keywords: ReadonlyArray<Scalars['String']>
  readonly vacancyId: Scalars['ID']
}

export interface SimilarVacancies {
  readonly __typename?: 'SimilarVacancies'
  readonly items: ReadonlyArray<Vacancy>
  readonly totalCount: Maybe<Scalars['Long']>
}

export interface SimilarVacanciesPaginationInput {
  readonly count: Scalars['Int']
  readonly page: Scalars['Int']
}

export interface SimilarVacanciesQueries {
  readonly __typename?: 'SimilarVacanciesQueries'
  readonly byKeyword: SimilarVacancies
  readonly byResume: SimilarVacancies
  readonly byVacancy: SimilarVacancies
}

export interface SimilarVacanciesQueriesByKeywordArgs {
  cityId: Scalars['ID']
  keyword: Scalars['String']
}

export interface SimilarVacanciesQueriesByResumeArgs {
  pagination: InputMaybe<SimilarVacanciesPaginationInput>
  resumeId: Scalars['ID']
}

export interface SimilarVacanciesQueriesByVacancyArgs {
  vacancyId: Scalars['ID']
}

export interface SimilarVacancyKeywordsOutput {
  readonly __typename?: 'SimilarVacancyKeywordsOutput'
  readonly keywords: ReadonlyArray<Scalars['String']>
}

export interface SpiderVacancy {
  readonly __typename?: 'SpiderVacancy'
  readonly address: Maybe<Scalars['String']>
  readonly business: Scalars['Boolean']
  readonly city: Scalars['String']
  readonly cityAdditional: Maybe<Scalars['String']>
  readonly cityId: Scalars['ID']
  readonly companyConfirmed: Scalars['Boolean']
  readonly companyId: Scalars['ID']
  readonly companyLink: Scalars['String']
  readonly companyName: Scalars['String']
  readonly contactPerson: Maybe<Scalars['String']>
  readonly contactPhone: Maybe<Scalars['String']>
  readonly date: Scalars['DateTime']
  readonly description: Scalars['String']
  readonly hot: Scalars['Boolean']
  readonly name: Scalars['String']
  readonly noCvApply: Scalars['Boolean']
  readonly salaryComment: Maybe<Scalars['String']>
  readonly salaryFrom: Maybe<Scalars['Int']>
  readonly salaryTo: Maybe<Scalars['Int']>
  readonly schedules: ReadonlyArray<ScheduleEnum>
  readonly source: OtherSiteEnum
  readonly specialNeeds: Scalars['Boolean']
  readonly student: Scalars['Boolean']
  readonly vacancyId: Scalars['ID']
  readonly vacancyLink: Scalars['String']
  readonly vipCompany: Scalars['Boolean']
}

export interface StartCallInput {
  readonly actionId?: InputMaybe<Scalars['ID']>
  readonly assignmentId?: InputMaybe<Scalars['String']>
  readonly clientPhone?: InputMaybe<Scalars['String']>
}

export interface StartGamificationWaveInput {
  readonly endDate: Scalars['DateTime']
  readonly giftCount: Scalars['Int']
  readonly id?: InputMaybe<Scalars['ID']>
  readonly multiUserIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
  readonly notebookId: Scalars['ID']
  readonly occasionWaveId: Scalars['Int']
  readonly startDate: Scalars['DateTime']
}

export interface StartGamificationWaveOutput {
  readonly __typename?: 'StartGamificationWaveOutput'
  readonly id: Scalars['ID']
  readonly isSuccess: Scalars['Boolean']
}

export interface Statistic {
  readonly __typename?: 'Statistic'
  readonly data: Maybe<StatisticData>
  readonly structure: Maybe<StatisticStructure>
}

export interface StatisticDataArgs {
  search: StatisticDataKeywordRubricInput
}

export interface StatisticBranch {
  readonly __typename?: 'StatisticBranch'
  readonly branch: Maybe<Branch>
  readonly percent: Maybe<Scalars['Decimal']>
}

export interface StatisticBranchTimeseries {
  readonly __typename?: 'StatisticBranchTimeseries'
  readonly begin: Maybe<Scalars['Date']>
  readonly branches: Maybe<ReadonlyArray<Maybe<StatisticBranch>>>
  readonly end: Maybe<Scalars['Date']>
  readonly others: Maybe<Scalars['Decimal']>
}

export interface StatisticCandidatesStructure {
  readonly __typename?: 'StatisticCandidatesStructure'
  readonly domain: Maybe<ReadonlyArray<Maybe<StatisticRubricTimeseries>>>
  readonly professions: Maybe<StatisticProfessionItem>
}

export interface StatisticCandidatesStructureDomainArgs {
  first: Scalars['Int']
  staticTop?: InputMaybe<Scalars['Boolean']>
}

export interface StatisticCandidatesStructureProfessionsArgs {
  keyword: InputMaybe<Scalars['String']>
  rubricId: InputMaybe<Scalars['ID']>
}

export interface StatisticCityInput {
  readonly cityId?: InputMaybe<Scalars['ID']>
  readonly range: DateIntervalInput
}

export interface StatisticData {
  readonly __typename?: 'StatisticData'
  readonly candidate: Maybe<StatisticItem>
  readonly period: Maybe<PeriodType>
  readonly vacancy: Maybe<StatisticItem>
}

export interface StatisticDataCityKeywordInput {
  readonly cityId?: InputMaybe<Scalars['ID']>
  readonly keyword?: InputMaybe<Scalars['String']>
  readonly period?: InputMaybe<PeriodType>
  readonly range: DateIntervalInput
}

export interface StatisticDataCityRubricInput {
  readonly cityId?: InputMaybe<Scalars['ID']>
  readonly keyword?: InputMaybe<Scalars['String']>
  readonly period?: InputMaybe<PeriodType>
  readonly range: DateIntervalInput
  readonly rubricId?: InputMaybe<Scalars['ID']>
}

export interface StatisticDataKeywordRubricInput {
  readonly keyword?: InputMaybe<Scalars['String']>
  readonly period?: InputMaybe<PeriodType>
  readonly range: DateIntervalInput
  readonly rubricId?: InputMaybe<Scalars['ID']>
}

export interface StatisticInput {
  readonly cityId?: InputMaybe<Scalars['ID']>
  readonly keyword?: InputMaybe<Scalars['String']>
  readonly period?: InputMaybe<PeriodType>
  readonly range: DateIntervalInput
  readonly rubricId?: InputMaybe<Scalars['ID']>
}

export interface StatisticItem {
  readonly __typename?: 'StatisticItem'
  readonly count: Maybe<ReadonlyArray<Maybe<StatisticTimeseries>>>
  readonly max: Maybe<ReadonlyArray<Maybe<StatisticTimeseries>>>
  readonly median: Maybe<ReadonlyArray<Maybe<StatisticTimeseries>>>
  readonly min: Maybe<ReadonlyArray<Maybe<StatisticTimeseries>>>
  readonly total: Maybe<StatisticTotal>
}

export interface StatisticProfession {
  readonly __typename?: 'StatisticProfession'
  readonly count: Maybe<Scalars['Int']>
  readonly profession: Maybe<Keyword>
  readonly salary: Maybe<Scalars['Int']>
}

export interface StatisticProfessionByCountTimeSeries {
  readonly __typename?: 'StatisticProfessionByCountTimeSeries'
  readonly begin: Maybe<Scalars['Date']>
  readonly end: Maybe<Scalars['Date']>
  readonly professions: Maybe<ReadonlyArray<Maybe<StatisticProfession>>>
}

export interface StatisticProfessionItem {
  readonly __typename?: 'StatisticProfessionItem'
  readonly timeseriesByCount: Maybe<ReadonlyArray<Maybe<StatisticProfessionByCountTimeSeries>>>
  readonly topByCount: Maybe<ReadonlyArray<Maybe<StatisticProfession>>>
  readonly topBySalary: Maybe<ReadonlyArray<Maybe<StatisticProfession>>>
}

export interface StatisticProfessionItemTimeseriesByCountArgs {
  first: Scalars['Int']
  keyword: InputMaybe<Scalars['String']>
  period: PeriodType
}

export interface StatisticProfessionItemTopByCountArgs {
  first: Scalars['Int']
}

export interface StatisticProfessionItemTopBySalaryArgs {
  first: Scalars['Int']
  minDocsWithSalary?: InputMaybe<Scalars['Int']>
}

export interface StatisticRubric {
  readonly __typename?: 'StatisticRubric'
  readonly percent: Maybe<Scalars['Decimal']>
  readonly rubric: Maybe<Rubric>
}

export interface StatisticRubricTimeseries {
  readonly __typename?: 'StatisticRubricTimeseries'
  readonly begin: Maybe<Scalars['Date']>
  readonly end: Maybe<Scalars['Date']>
  readonly others: Maybe<Scalars['Decimal']>
  readonly rubrics: Maybe<ReadonlyArray<Maybe<StatisticRubric>>>
}

export interface StatisticSalaryDistribution {
  readonly __typename?: 'StatisticSalaryDistribution'
  readonly begin: Maybe<Scalars['Int']>
  readonly end: Maybe<Scalars['Int']>
  readonly value: Maybe<Scalars['Int']>
}

export interface StatisticStructure {
  readonly __typename?: 'StatisticStructure'
  readonly candidate: Maybe<StatisticCandidatesStructure>
  readonly vacancy: Maybe<StatisticVacanciesStructure>
}

export interface StatisticTimeseries {
  readonly __typename?: 'StatisticTimeseries'
  readonly begin: Maybe<Scalars['Date']>
  readonly end: Maybe<Scalars['Date']>
  readonly prediction: Maybe<Scalars['Boolean']>
  readonly value: Maybe<Scalars['Int']>
}

export interface StatisticTotal {
  readonly __typename?: 'StatisticTotal'
  readonly count: Maybe<Scalars['Int']>
  readonly salary: Maybe<Scalars['Int']>
  readonly salaryDistribution: Maybe<ReadonlyArray<Maybe<StatisticSalaryDistribution>>>
  readonly salaryMax: Maybe<Scalars['Int']>
  readonly salaryMin: Maybe<Scalars['Int']>
}

export interface StatisticTotalSalaryDistributionArgs {
  bins?: InputMaybe<Scalars['Int']>
}

export interface StatisticVacanciesStructure {
  readonly __typename?: 'StatisticVacanciesStructure'
  readonly domain: Maybe<ReadonlyArray<Maybe<StatisticBranchTimeseries>>>
  readonly professions: Maybe<StatisticProfessionItem>
}

export interface StatisticVacanciesStructureDomainArgs {
  first: Scalars['Int']
  staticTop?: InputMaybe<Scalars['Boolean']>
}

export interface StatisticVacanciesStructureProfessionsArgs {
  keyword?: InputMaybe<Scalars['String']>
  rubricId: InputMaybe<Scalars['ID']>
}

export interface StatisticVacancyBadge {
  readonly __typename?: 'StatisticVacancyBadge'
  readonly count: Maybe<Scalars['Int']>
  readonly percent: Maybe<Scalars['Decimal']>
}

export interface Subrubric {
  readonly __typename?: 'Subrubric'
  readonly externalCourses: ReadonlyArray<ExternalCourse>
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly rubric: Rubric
}

export interface SubrubricsFacet {
  readonly __typename?: 'SubrubricsFacet'
  readonly count: Scalars['Long']
  readonly subrubric: Subrubric
}

export enum SubscriptionChannelTypeEnum {
  Email = 'EMAIL',
  NotificationCenter = 'NOTIFICATION_CENTER',
  Telegram = 'TELEGRAM',
  Viber = 'VIBER'
}

export enum SubscriptionLanguageEnum {
  Ru = 'RU',
  Ukr = 'UKR'
}

export interface SubscriptionOutputType {
  readonly __typename?: 'SubscriptionOutputType'
  readonly subscriptionIds: ReadonlyArray<Scalars['ID']>
  readonly subscriptions: ReadonlyArray<SeekerSubscription>
}

export enum SubscriptionTypeEnum {
  ApplyLeftUncompleted = 'APPLY_LEFT_UNCOMPLETED',
  CareerAlert = 'CAREER_ALERT',
  CvStat = 'CV_STAT',
  CvView = 'CV_VIEW',
  /** @deprecated Use JobAlert_Manual or JobAlert_Automate */
  JobAlert = 'JOB_ALERT',
  JobAlertAutomate = 'JOB_ALERT_AUTOMATE',
  JobAlertManual = 'JOB_ALERT_MANUAL',
  JobInstant = 'JOB_INSTANT',
  JobRecommendation = 'JOB_RECOMMENDATION',
  JobResumeDraft = 'JOB_RESUME_DRAFT',
  LastMinute = 'LAST_MINUTE',
  Point = 'POINT',
  SecondaryVacancyInInterest = 'SECONDARY_VACANCY_IN_INTEREST',
  UnApplyJobAlert = 'UN_APPLY_JOB_ALERT'
}

export enum SuggestType {
  All = 'ALL',
  Company = 'COMPANY',
  Profession = 'PROFESSION',
  Skill = 'SKILL'
}

export interface SuggestedVacancySearchOutput {
  readonly __typename?: 'SuggestedVacancySearchOutput'
  readonly keywords: ReadonlyArray<Scalars['String']>
}

export interface SummaryService {
  readonly __typename?: 'SummaryService'
  readonly activated: Maybe<Scalars['Int']>
  readonly activatedAccrualPeriod: Maybe<ContactsAccrualPeriod>
  readonly activatedContactsCount: Maybe<Scalars['Int']>
  readonly company: Maybe<Company>
  readonly id: Maybe<Scalars['ID']>
  readonly inactiveAccrualPeriod: Maybe<ContactsAccrualPeriod>
  readonly inactiveContactsCount: Maybe<Scalars['Int']>
  readonly limitedUserTotal: Maybe<Scalars['Int']>
  readonly remainingLimits: Maybe<Scalars['Int']>
  readonly serviceType: Maybe<ServiceType>
  readonly total: Maybe<Scalars['Int']>
  readonly usedContactsCount: Maybe<Scalars['Int']>
}

export enum SummaryServiceOrderBy {
  ServiceTypeAsc = 'SERVICE_TYPE_ASC'
}

export interface Synonym {
  readonly __typename?: 'Synonym'
  readonly group: Keyword
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export interface SynonymTag {
  readonly __typename?: 'SynonymTag'
  readonly group: Scalars['ID']
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export interface SystemEventPerformer {
  readonly __typename?: 'SystemEventPerformer'
  readonly isSystem: Scalars['Boolean']
}

export interface SystemWalletUser {
  readonly __typename?: 'SystemWalletUser'
  readonly isSystem: Scalars['Boolean']
}

export interface TagGroup {
  readonly __typename?: 'TagGroup'
  readonly childTags: Maybe<ReadonlyArray<Maybe<TagGroup>>>
  readonly childTagsProf: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>
  readonly clusterId: Maybe<Scalars['Int']>
  readonly id: Scalars['ID']
  readonly isProf: Maybe<Scalars['Boolean']>
  readonly name: Maybe<Scalars['String']>
  readonly parentTags: Maybe<ReadonlyArray<Maybe<TagGroup>>>
  readonly parentTagsProf: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>
  readonly popularBranches: Maybe<ReadonlyArray<Maybe<Branch>>>
  readonly popularCompanies: Maybe<ReadonlyArray<Maybe<Company>>>
  readonly synonymIds: Maybe<ReadonlyArray<Maybe<Scalars['Int']>>>
  readonly synonymNames: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>
}

export interface TargetMailingListFilter {
  readonly __typename?: 'TargetMailingListFilter'
  readonly statuses: Maybe<ReadonlyArray<TargetedMailingStatusEnum>>
  readonly term: Maybe<Scalars['String']>
}

export interface TargetMailingListQueryFilter {
  readonly __typename?: 'TargetMailingListQueryFilter'
  readonly filter: TargetMailingListFilter
  readonly page: Scalars['Int']
}

export interface TargetedExternalMailingLink {
  readonly __typename?: 'TargetedExternalMailingLink'
  readonly clicks: Scalars['Int']
  readonly url: Scalars['String']
}

export interface TargetedMailing {
  readonly __typename?: 'TargetedMailing'
  readonly candidatesSamplingType: Maybe<TargetedMailingCandidatesSamplingTypeEnum>
  readonly company: Company
  readonly content: Maybe<ReadonlyArray<TargetedMailingContentUnion>>
  readonly createdAt: Scalars['DateTime']
  readonly history: ReadonlyArray<TargetedMailingHistoryFactUnion>
  readonly id: Scalars['ID']
  readonly plannedAt: Maybe<Scalars['DateTime']>
  readonly readyToSpendServicesCount: Scalars['Int']
  readonly responsible: TargetedMailingResponsible
  readonly samplingSnapshot: Maybe<TargetedMailingCandidatesSampling>
  readonly sentAt: Maybe<Scalars['DateTime']>
  readonly statistics: Maybe<MailingStatistics>
  readonly status: TargetedMailingStatusEnum
  readonly title: Scalars['String']
  readonly updatedAt: Maybe<Scalars['DateTime']>
}

export interface TargetedMailingCandidatesLocation {
  readonly __typename?: 'TargetedMailingCandidatesLocation'
  readonly city: City
  readonly type: TargetedMailingCandidatesLocationEnum
}

export enum TargetedMailingCandidatesLocationEnum {
  City = 'CITY',
  Region = 'REGION'
}

export interface TargetedMailingCandidatesLocationInput {
  readonly id: Scalars['ID']
  readonly type: TargetedMailingCandidatesLocationEnum
}

export interface TargetedMailingCandidatesRubric {
  readonly __typename?: 'TargetedMailingCandidatesRubric'
  readonly rubric: Rubric
  readonly subrubrics: Maybe<ReadonlyArray<Subrubric>>
}

export interface TargetedMailingCandidatesRubricInput {
  readonly rubricId: Scalars['ID']
  readonly subrubricIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
}

export interface TargetedMailingCandidatesSampling {
  readonly __typename?: 'TargetedMailingCandidatesSampling'
  readonly candidatesLimitPerService: Scalars['Int']
  readonly details: Maybe<ReadonlyArray<TargetedMailingCandidatesSamplingDetails>>
  readonly input: TargetedMailingCandidatesSamplingInputSnapshot
  readonly requiredServices: Scalars['Int']
  readonly total: Scalars['Int']
}

export interface TargetedMailingCandidatesSamplingByCodeInput {
  readonly code: Scalars['String']
  readonly comment?: InputMaybe<Scalars['String']>
}

export interface TargetedMailingCandidatesSamplingByCodeInputSnapshot {
  readonly __typename?: 'TargetedMailingCandidatesSamplingByCodeInputSnapshot'
  readonly code: Scalars['String']
  readonly comment: Maybe<Scalars['String']>
}

export interface TargetedMailingCandidatesSamplingByFilterInput {
  readonly age?: InputMaybe<IntRangeInput>
  readonly experienceIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
  readonly genders?: InputMaybe<ReadonlyArray<Gender>>
  readonly languages?: InputMaybe<ReadonlyArray<TargetedMailingLanguageRequirementInput>>
  readonly locations?: InputMaybe<ReadonlyArray<TargetedMailingCandidatesLocationInput>>
  readonly rubrics?: InputMaybe<ReadonlyArray<TargetedMailingCandidatesRubricInput>>
}

export interface TargetedMailingCandidatesSamplingByFilterInputSnapshot {
  readonly __typename?: 'TargetedMailingCandidatesSamplingByFilterInputSnapshot'
  readonly age: Maybe<IntRange>
  readonly experiences: Maybe<ReadonlyArray<Experience>>
  readonly genders: Maybe<ReadonlyArray<Gender>>
  readonly languages: Maybe<ReadonlyArray<TargetedMailingLanguageRequirement>>
  readonly locations: Maybe<ReadonlyArray<TargetedMailingCandidatesLocation>>
  readonly rubrics: Maybe<ReadonlyArray<TargetedMailingCandidatesRubric>>
}

export interface TargetedMailingCandidatesSamplingByVacancyInput {
  readonly hasSelectedForMobileApplication: Scalars['Boolean']
  readonly id: Scalars['ID']
}

export interface TargetedMailingCandidatesSamplingDetails {
  readonly __typename?: 'TargetedMailingCandidatesSamplingDetails'
  readonly byChannels: ReadonlyArray<TargetedMailingCandidatesSamplingDetailsByChannel>
  readonly byVacancies: Maybe<ReadonlyArray<TargetedMailingCandidatesSamplingDetailsByVacancy>>
  readonly kind: TargetedMailingCandidatesSamplingDetailsKindEnum
}

export interface TargetedMailingCandidatesSamplingDetailsByChannel {
  readonly __typename?: 'TargetedMailingCandidatesSamplingDetailsByChannel'
  readonly channels: ReadonlyArray<TargetedMailingChannelEnum>
  readonly count: Scalars['Int']
}

export interface TargetedMailingCandidatesSamplingDetailsByVacancy {
  readonly __typename?: 'TargetedMailingCandidatesSamplingDetailsByVacancy'
  readonly count: Scalars['Int']
  readonly vacancySnapshot: TargetedMailingSamplingRelatedVacancy
}

export enum TargetedMailingCandidatesSamplingDetailsKindEnum {
  All = 'ALL',
  Top = 'TOP'
}

export type TargetedMailingCandidatesSamplingErrorUnion = TargetedMailingInvalidVacanciesError

export interface TargetedMailingCandidatesSamplingInput {
  readonly channels: ReadonlyArray<TargetedMailingChannelEnum>
  readonly code?: InputMaybe<TargetedMailingCandidatesSamplingByCodeInput>
  readonly filter?: InputMaybe<TargetedMailingCandidatesSamplingByFilterInput>
  readonly vacancies?: InputMaybe<ReadonlyArray<TargetedMailingCandidatesSamplingByVacancyInput>>
}

export interface TargetedMailingCandidatesSamplingInputSnapshot {
  readonly __typename?: 'TargetedMailingCandidatesSamplingInputSnapshot'
  readonly channels: ReadonlyArray<TargetedMailingChannelEnum>
  readonly code: Maybe<TargetedMailingCandidatesSamplingByCodeInputSnapshot>
  readonly filter: Maybe<TargetedMailingCandidatesSamplingByFilterInputSnapshot>
  readonly vacancies: Maybe<ReadonlyArray<TargetedMailingSamplingRelatedVacancy>>
}

export interface TargetedMailingCandidatesSamplingOutput {
  readonly __typename?: 'TargetedMailingCandidatesSamplingOutput'
  readonly candidatesSampling: Maybe<TargetedMailingCandidatesSampling>
  readonly error: Maybe<TargetedMailingCandidatesSamplingErrorUnion>
}

export enum TargetedMailingCandidatesSamplingTypeEnum {
  ByCode = 'BY_CODE',
  ByFilters = 'BY_FILTERS',
  ByVacancies = 'BY_VACANCIES'
}

export enum TargetedMailingChannelEnum {
  Email = 'EMAIL',
  MobileApplication = 'MOBILE_APPLICATION'
}

export interface TargetedMailingCompletedHistoryFact {
  readonly __typename?: 'TargetedMailingCompletedHistoryFact'
  readonly addedAt: Scalars['DateTime']
}

export interface TargetedMailingConnection {
  readonly __typename?: 'TargetedMailingConnection'
  readonly edges: Maybe<ReadonlyArray<Maybe<TargetedMailingEdge>>>
  readonly items: Maybe<ReadonlyArray<TargetedMailing>>
  readonly pageInfo: PageInfo
  readonly totalCount: Maybe<Scalars['Int']>
}

export type TargetedMailingContentUnion = TargetedMailingMailContent | TargetedMailingMobilePushContent

export interface TargetedMailingCreatedHistoryFact {
  readonly __typename?: 'TargetedMailingCreatedHistoryFact'
  readonly addedAt: Scalars['DateTime']
  readonly responsible: TargetedMailingResponsible
}

export interface TargetedMailingEdge {
  readonly __typename?: 'TargetedMailingEdge'
  readonly cursor: Scalars['String']
  readonly node: TargetedMailing
}

export interface TargetedMailingEditedHistoryFact {
  readonly __typename?: 'TargetedMailingEditedHistoryFact'
  readonly addedAt: Scalars['DateTime']
  readonly responsible: TargetedMailingResponsible
}

export interface TargetedMailingFacetsOutput {
  readonly __typename?: 'TargetedMailingFacetsOutput'
  readonly statuses: ReadonlyArray<TargetedMailingStatusFacet>
}

export interface TargetedMailingFailedHistoryFact {
  readonly __typename?: 'TargetedMailingFailedHistoryFact'
  readonly addedAt: Scalars['DateTime']
}

export interface TargetedMailingFilterInput {
  readonly companyId?: InputMaybe<Scalars['ID']>
  readonly statuses?: InputMaybe<ReadonlyArray<TargetedMailingStatusEnum>>
  readonly term?: InputMaybe<Scalars['String']>
}

export type TargetedMailingHistoryFactUnion =
  | TargetedMailingCompletedHistoryFact
  | TargetedMailingCreatedHistoryFact
  | TargetedMailingEditedHistoryFact
  | TargetedMailingFailedHistoryFact
  | TargetedMailingSendingHistoryFact

export interface TargetedMailingInvalidVacanciesError {
  readonly __typename?: 'TargetedMailingInvalidVacanciesError'
  readonly message: Scalars['String']
}

export interface TargetedMailingLanguageRequirement {
  readonly __typename?: 'TargetedMailingLanguageRequirement'
  readonly language: Lang
  readonly languageSkill: LanguageSkillLevel
}

export interface TargetedMailingLanguageRequirementInput {
  readonly languageId: Scalars['ID']
  readonly languageSkillId: Scalars['ID']
}

export type TargetedMailingLinkUnion = TargetedExternalMailingLink | TargetedVacancyMailingLink

export interface TargetedMailingMailContent {
  readonly __typename?: 'TargetedMailingMailContent'
  readonly htmlTemplateUrl: Maybe<Scalars['String']>
  readonly subject: Maybe<Scalars['String']>
}

export interface TargetedMailingMailContentInput {
  readonly htmlTemplateUrl?: InputMaybe<Scalars['String']>
  readonly subject?: InputMaybe<Scalars['String']>
}

export interface TargetedMailingMobilePushContent {
  readonly __typename?: 'TargetedMailingMobilePushContent'
  readonly text: Maybe<Scalars['String']>
  readonly title: Maybe<Scalars['String']>
  readonly vacancyId: Maybe<Scalars['ID']>
}

export interface TargetedMailingMobilePushContentInput {
  readonly text?: InputMaybe<Scalars['String']>
  readonly title?: InputMaybe<Scalars['String']>
  readonly vacancyId?: InputMaybe<Scalars['ID']>
}

export interface TargetedMailingMutations {
  readonly __typename?: 'TargetedMailingMutations'
  readonly create: CreateTargetedMailingOutput
  readonly delete: DeleteTargetedMailingOutput
  readonly testEmailTemplate: TestTargetedMailingOutput
  readonly update: UpdateTargetedMailingOutput
}

export interface TargetedMailingMutationsCreateArgs {
  input: CreateTargetedMailingInput
}

export interface TargetedMailingMutationsDeleteArgs {
  id: Scalars['ID']
}

export interface TargetedMailingMutationsTestEmailTemplateArgs {
  input: TestTargetedMailingInput
}

export interface TargetedMailingMutationsUpdateArgs {
  input: UpdateTargetedMailingInput
}

export interface TargetedMailingQueries {
  readonly __typename?: 'TargetedMailingQueries'
  readonly candidatesSuggest: TargetedMailingCandidatesSamplingOutput
  readonly facets: TargetedMailingFacetsOutput
  readonly list: TargetedMailingConnection
  readonly single: Maybe<TargetedMailing>
}

export interface TargetedMailingQueriesCandidatesSuggestArgs {
  input: TargetedMailingCandidatesSamplingInput
  type: TargetedMailingCandidatesSamplingTypeEnum
}

export interface TargetedMailingQueriesFacetsArgs {
  filter: InputMaybe<TargetedMailingFilterInput>
}

export interface TargetedMailingQueriesListArgs {
  after: InputMaybe<Scalars['String']>
  filter: InputMaybe<TargetedMailingFilterInput>
  first: InputMaybe<Scalars['Int']>
}

export interface TargetedMailingQueriesSingleArgs {
  id: Scalars['ID']
}

export interface TargetedMailingRequiredFieldsMissedError {
  readonly __typename?: 'TargetedMailingRequiredFieldsMissedError'
  readonly message: Scalars['String']
}

export interface TargetedMailingResponsible {
  readonly __typename?: 'TargetedMailingResponsible'
  readonly email: Scalars['String']
}

export interface TargetedMailingSamplingRelatedVacancy {
  readonly __typename?: 'TargetedMailingSamplingRelatedVacancy'
  readonly city: City
  readonly hasSelectedForMobileApplication: Scalars['Boolean']
  readonly id: Scalars['ID']
  readonly title: Scalars['String']
}

export type TargetedMailingSaveErrorUnion = TargetedMailingInvalidVacanciesError | TargetedMailingRequiredFieldsMissedError

export interface TargetedMailingSendingHistoryFact {
  readonly __typename?: 'TargetedMailingSendingHistoryFact'
  readonly addedAt: Scalars['DateTime']
  readonly candidatesCount: Scalars['Int']
}

export enum TargetedMailingStatusEnum {
  Draft = 'DRAFT',
  Failed = 'FAILED',
  InProcessing = 'IN_PROCESSING',
  ReadyToSend = 'READY_TO_SEND',
  Sent = 'SENT'
}

export interface TargetedMailingStatusFacet {
  readonly __typename?: 'TargetedMailingStatusFacet'
  readonly count: Scalars['Int']
  readonly status: TargetedMailingStatusEnum
}

export interface TargetedVacancyMailingLink {
  readonly __typename?: 'TargetedVacancyMailingLink'
  readonly appliesCount: Scalars['Int']
  readonly clicks: Scalars['Int']
  readonly url: Scalars['String']
  readonly vacancy: TargetedVacancyMailingLinkVacancySnapshot
}

export interface TargetedVacancyMailingLinkVacancySnapshot {
  readonly __typename?: 'TargetedVacancyMailingLinkVacancySnapshot'
  readonly company: Maybe<Company>
  readonly id: Scalars['ID']
  readonly title: Scalars['String']
}

export enum TaskContactTypeEnum {
  Call = 'CALL',
  Mail = 'MAIL',
  Meet = 'MEET',
  OutcomingCall = 'OUTCOMING_CALL',
  OutcomingMail = 'OUTCOMING_MAIL',
  WithoutContact = 'WITHOUT_CONTACT'
}

export enum TaskPriorityEnum {
  Critical = 'CRITICAL',
  Major = 'MAJOR',
  Minor = 'MINOR'
}

export enum TaskStatusEnum {
  Done = 'DONE',
  Overdue = 'OVERDUE',
  Planned = 'PLANNED'
}

export interface TeamActions {
  readonly __typename?: 'TeamActions'
  readonly counters: ReadonlyArray<ActionStateCounter>
  readonly managers: ReadonlyArray<ActionTeamCounter>
}

export interface TestTargetedMailingInput {
  readonly emails: ReadonlyArray<Scalars['String']>
  readonly htmlTemplateUrl: Scalars['String']
  readonly subject?: InputMaybe<Scalars['String']>
}

export interface TestTargetedMailingOutput {
  readonly __typename?: 'TestTargetedMailingOutput'
  readonly isSuccessful: Scalars['Boolean']
}

export interface TicketCardFilterInput {
  readonly endDate: Scalars['Date']
  readonly notebookId: Scalars['ID']
  readonly startDate: Scalars['Date']
}

export interface TokenAlreadyUsedError {
  readonly __typename?: 'TokenAlreadyUsedError'
  readonly message: Scalars['String']
}

export interface Topic {
  readonly __typename?: 'Topic'
  readonly isDone: Scalars['Boolean']
  readonly subTopics: ReadonlyArray<Topic>
  readonly template: Maybe<TopicTemplate>
}

export interface TopicTemplate {
  readonly __typename?: 'TopicTemplate'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly objections: ReadonlyArray<Objection>
  readonly subTopicTemplates: ReadonlyArray<TopicTemplate>
}

export interface TrackCompanyViewOutput {
  readonly __typename?: 'TrackCompanyViewOutput'
  readonly company: Scalars['ID']
}

export interface TransactionExistsError extends Error {
  readonly __typename?: 'TransactionExistsError'
  readonly reason: Scalars['String']
}

export enum TransactionReasonEnum {
  AutoBurning = 'AUTO_BURNING',
  Bonus = 'BONUS',
  Cashback = 'CASHBACK',
  Loyalty = 'LOYALTY',
  PartnerBonus = 'PARTNER_BONUS',
  PromoCampaign = 'PROMO_CAMPAIGN',
  SorryBonus = 'SORRY_BONUS'
}

export enum TransactionStatusEnum {
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED'
}

export enum TransactionTypeEnum {
  Credit = 'CREDIT',
  Withdraw = 'WITHDRAW'
}

/** A connection to a list of items. */
export interface TransactionsConnection {
  readonly __typename?: 'TransactionsConnection'
  /** A list of edges. */
  readonly edges: Maybe<ReadonlyArray<TransactionsEdge>>
  /** A flattened list of the nodes. */
  readonly nodes: Maybe<ReadonlyArray<WalletTransaction>>
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']
}

/** An edge in a connection. */
export interface TransactionsEdge {
  readonly __typename?: 'TransactionsEdge'
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']
  /** The item at the end of the edge. */
  readonly node: WalletTransaction
}

export interface TurbotaEvent extends EventInterface {
  readonly __typename?: 'TurbotaEvent'
  readonly addDate: Scalars['DateTimeOffset']
  readonly assignment: Maybe<Assignment>
  readonly company: Maybe<CrmCompany>
  readonly daysLeft: Maybe<Scalars['Int']>
  readonly employee: Maybe<CrmEmployee>
  readonly eventType: EventTypeEnum
  readonly expirationDate: Maybe<Scalars['DateTimeOffset']>
  readonly id: Scalars['ID']
  readonly isArchieved: Scalars['Boolean']
  readonly isRead: Scalars['Boolean']
  readonly lifetimeDays: Scalars['Int']
  readonly state: EventStateEnum
  readonly topics: ReadonlyArray<Topic>
}

export interface TurbotaWasExpiredError extends Error {
  readonly __typename?: 'TurbotaWasExpiredError'
  readonly reason: Scalars['String']
}

export enum UiServiceType {
  AnonymousPublication = 'ANONYMOUS_PUBLICATION',
  Branding = 'BRANDING',
  BusinessPackage = 'BUSINESS_PACKAGE',
  BusinessPublication = 'BUSINESS_PUBLICATION',
  CvDb = 'CV_DB',
  Hot = 'HOT',
  OptimumPackage = 'OPTIMUM_PACKAGE',
  OptimumPublication = 'OPTIMUM_PUBLICATION',
  ProfessionalPackage = 'PROFESSIONAL_PACKAGE',
  ProfessionalPublication = 'PROFESSIONAL_PUBLICATION',
  TargetedMailing = 'TARGETED_MAILING'
}

export interface UncompletedPaymentEvent extends EventInterface {
  readonly __typename?: 'UncompletedPaymentEvent'
  readonly accountNumber: Scalars['Int']
  readonly accountYear: Scalars['Int']
  readonly addDate: Scalars['DateTimeOffset']
  readonly assignment: Maybe<Assignment>
  readonly company: Maybe<CrmCompany>
  readonly daysLeft: Maybe<Scalars['Int']>
  readonly dealNumber: Scalars['Int']
  readonly employee: Maybe<CrmEmployee>
  readonly eventType: EventTypeEnum
  readonly expirationDate: Maybe<Scalars['DateTimeOffset']>
  readonly id: Scalars['ID']
  readonly isArchieved: Scalars['Boolean']
  readonly isRead: Scalars['Boolean']
  readonly lifetimeDays: Scalars['Int']
  readonly orderNumber: Scalars['Int']
  readonly state: EventStateEnum
  readonly topics: ReadonlyArray<Topic>
}

export interface UndislikeSeekerVacanciesInput {
  readonly vacancyIds: ReadonlyArray<Scalars['ID']>
}

export interface UndislikeSeekerVacanciesOutput {
  readonly __typename?: 'UndislikeSeekerVacanciesOutput'
  readonly query: Query
  readonly vacancies: ReadonlyArray<Vacancy>
}

export type UnionBannerDynamicElement = BannerClock | BannerProgressBar

export interface University {
  readonly __typename?: 'University'
  readonly EDRPOU: Maybe<Scalars['String']>
  readonly id: Maybe<Scalars['ID']>
  readonly name: Maybe<Scalars['String']>
  readonly shortName: Maybe<Scalars['String']>
}

export interface UnknownServerError extends NotificationsError {
  readonly __typename?: 'UnknownServerError'
  readonly exeptionTypeName: Maybe<Scalars['String']>
  readonly message: Scalars['String']
}

export interface UnlinkPayerOutput {
  readonly __typename?: 'UnlinkPayerOutput'
  readonly errorMessage: Maybe<Scalars['String']>
  readonly isSuccess: Scalars['Boolean']
}

export interface UnpaidOrderDetails {
  readonly __typename?: 'UnpaidOrderDetails'
  readonly detailType: SeekerNotificationDetailTypeEnum
  readonly orderSnapshot: NotificationOrderSnapshot
}

export interface UnpublishProfResumeInput {
  readonly resumeId: Scalars['ID']
}

export type UnpublishSeekerProfResumeError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export interface UnpublishedProfResumeOutput {
  readonly __typename?: 'UnpublishedProfResumeOutput'
  readonly errors: Maybe<ReadonlyArray<UnpublishSeekerProfResumeError>>
  readonly profResume: Maybe<ProfResume>
}

export interface UpdateGiftInput {
  readonly comment?: InputMaybe<Scalars['String']>
  readonly deliveryChannelId: GiftDeliveryChannelEnum
  readonly deliverySlots?: InputMaybe<ReadonlyArray<GiftDeliverySlotInput>>
  readonly details: ReadonlyArray<GiftDetailInput>
  readonly novaPoshtaDeliveryOptions?: InputMaybe<AddGiftRequestDeliveryOptionsInput>
  readonly requestId: Scalars['ID']
  readonly requestOccasionId?: InputMaybe<Scalars['ID']>
  readonly userCardId?: InputMaybe<Scalars['ID']>
}

export interface UpdateGiftRequestOutput {
  readonly __typename?: 'UpdateGiftRequestOutput'
  readonly isSuccess: Scalars['Boolean']
  readonly message: Maybe<Scalars['String']>
}

export interface UpdateProfResumeAdditionalPhonesOutput {
  readonly __typename?: 'UpdateProfResumeAdditionalPhonesOutput'
  readonly errors: Maybe<ReadonlyArray<UpdateSeekerProfResumeAdditionalPhonesError>>
  readonly profResume: Maybe<ProfResume>
}

export interface UpdateProfResumeEmailOutput {
  readonly __typename?: 'UpdateProfResumeEmailOutput'
  readonly errors: Maybe<ReadonlyArray<UpdateSeekerProfResumeEmailError>>
  readonly profResume: Maybe<ProfResume>
}

export interface UpdateProfResumePhoneOutput {
  readonly __typename?: 'UpdateProfResumePhoneOutput'
  readonly errors: Maybe<ReadonlyArray<UpdateSeekerProfResumeMainPhoneError>>
  readonly profResume: Maybe<ProfResume>
}

export interface UpdateProfResumePortfoliosOutput {
  readonly __typename?: 'UpdateProfResumePortfoliosOutput'
  readonly errors: Maybe<ReadonlyArray<UpdateSeekerProfResumePortfoliosError>>
  readonly profResume: Maybe<ProfResume>
}

export interface UpdateProfResumeSocialNetworksOutput {
  readonly __typename?: 'UpdateProfResumeSocialNetworksOutput'
  readonly errors: Maybe<ReadonlyArray<UpdateSeekerProfResumeSocialNetworksError>>
  readonly profResume: Maybe<ProfResume>
}

export type UpdateSeekerProfResumeAdditionalEducationError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type UpdateSeekerProfResumeAdditionalInfoError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type UpdateSeekerProfResumeAdditionalInfosError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type UpdateSeekerProfResumeAdditionalPhonesError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type UpdateSeekerProfResumeAnonymityError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type UpdateSeekerProfResumeAvailabilityStateError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type UpdateSeekerProfResumeBirthDateError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type UpdateSeekerProfResumeCityError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type UpdateSeekerProfResumeDiiaCertificateError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type UpdateSeekerProfResumeDisabilityError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type UpdateSeekerProfResumeEducationError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type UpdateSeekerProfResumeEmailError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type UpdateSeekerProfResumeExperienceError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type UpdateSeekerProfResumeFullNameError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type UpdateSeekerProfResumeGenderError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type UpdateSeekerProfResumeHiddenCompaniesError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type UpdateSeekerProfResumeLanguageSkillsError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type UpdateSeekerProfResumeMainPhoneError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist | ProfResumePhoneConfirmationFailed

export type UpdateSeekerProfResumeMilitaryStatusError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type UpdateSeekerProfResumePhotoError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type UpdateSeekerProfResumePortfoliosError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type UpdateSeekerProfResumePositionError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist | ProfResumeDuplicated

export type UpdateSeekerProfResumeSalaryError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type UpdateSeekerProfResumeScheduleError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type UpdateSeekerProfResumeSearchStateError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type UpdateSeekerProfResumeSkillsError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type UpdateSeekerProfResumeSocialNetworksError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export type UpdateSeekerProfResumeSortDateError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export interface UpdateSeekerProfResumeSortDateInput {
  readonly resumeId: Scalars['ID']
}

export interface UpdateSeekerProfResumeSortDateOutput {
  readonly __typename?: 'UpdateSeekerProfResumeSortDateOutput'
  readonly errors: Maybe<ReadonlyArray<UpdateSeekerProfResumeSortDateError>>
  readonly profResume: Maybe<ProfResume>
}

export type UpdateSeekerProfResumeStateError = ProfResumeDoesNotBelongToSeeker | ProfResumeDoesNotExist

export interface UpdateTargetedMailingInput {
  readonly additionalServicesCount?: InputMaybe<Scalars['Int']>
  readonly candidatesSamplingType?: InputMaybe<TargetedMailingCandidatesSamplingTypeEnum>
  readonly companyId: Scalars['ID']
  readonly id: Scalars['ID']
  readonly mailContent?: InputMaybe<TargetedMailingMailContentInput>
  readonly mobilePushContent?: InputMaybe<TargetedMailingMobilePushContentInput>
  readonly plannedAt?: InputMaybe<Scalars['DateTime']>
  readonly readyToSpendServicesCount?: InputMaybe<Scalars['Int']>
  readonly samplingSettings?: InputMaybe<TargetedMailingCandidatesSamplingInput>
  readonly status?: InputMaybe<TargetedMailingStatusEnum>
  readonly title: Scalars['String']
}

export interface UpdateTargetedMailingOutput {
  readonly __typename?: 'UpdateTargetedMailingOutput'
  readonly error: Maybe<TargetedMailingSaveErrorUnion>
  readonly mailing: Maybe<TargetedMailing>
}

export interface UpdatedProfResumeAdditionalEducationsOutput {
  readonly __typename?: 'UpdatedProfResumeAdditionalEducationsOutput'
  readonly errors: Maybe<ReadonlyArray<UpdateSeekerProfResumeAdditionalEducationError>>
  readonly profResume: Maybe<ProfResume>
}

export interface UpdatedProfResumeAdditionalInfoOutput {
  readonly __typename?: 'UpdatedProfResumeAdditionalInfoOutput'
  readonly errors: Maybe<ReadonlyArray<UpdateSeekerProfResumeAdditionalInfoError>>
  readonly profResume: Maybe<ProfResume>
}

export interface UpdatedProfResumeAdditionalInfosOutput {
  readonly __typename?: 'UpdatedProfResumeAdditionalInfosOutput'
  readonly errors: Maybe<ReadonlyArray<UpdateSeekerProfResumeAdditionalInfosError>>
  readonly profResume: Maybe<ProfResume>
}

export interface UpdatedProfResumeAnonymityOutput {
  readonly __typename?: 'UpdatedProfResumeAnonymityOutput'
  readonly errors: Maybe<ReadonlyArray<UpdateSeekerProfResumeAnonymityError>>
  readonly profResume: Maybe<ProfResume>
}

export interface UpdatedProfResumeAvailabilityStateOutput {
  readonly __typename?: 'UpdatedProfResumeAvailabilityStateOutput'
  readonly errors: Maybe<ReadonlyArray<UpdateSeekerProfResumeAvailabilityStateError>>
  readonly profResume: Maybe<ProfResume>
}

export interface UpdatedProfResumeBirthDateOutput {
  readonly __typename?: 'UpdatedProfResumeBirthDateOutput'
  readonly errors: Maybe<ReadonlyArray<UpdateSeekerProfResumeBirthDateError>>
  readonly profResume: Maybe<ProfResume>
}

export interface UpdatedProfResumeCityOutput {
  readonly __typename?: 'UpdatedProfResumeCityOutput'
  readonly errors: Maybe<ReadonlyArray<UpdateSeekerProfResumeCityError>>
  readonly profResume: Maybe<ProfResume>
}

export interface UpdatedProfResumeDiiaCertificateOutput {
  readonly __typename?: 'UpdatedProfResumeDiiaCertificateOutput'
  readonly errors: Maybe<ReadonlyArray<UpdateSeekerProfResumeDiiaCertificateError>>
  readonly profResume: Maybe<ProfResume>
}

export interface UpdatedProfResumeDisabilityOutput {
  readonly __typename?: 'UpdatedProfResumeDisabilityOutput'
  readonly errors: Maybe<ReadonlyArray<UpdateSeekerProfResumeDisabilityError>>
  readonly profResume: Maybe<ProfResume>
}

export interface UpdatedProfResumeEducationsOutput {
  readonly __typename?: 'UpdatedProfResumeEducationsOutput'
  readonly errors: Maybe<ReadonlyArray<UpdateSeekerProfResumeEducationError>>
  readonly profResume: Maybe<ProfResume>
}

export interface UpdatedProfResumeExperiencesOutput {
  readonly __typename?: 'UpdatedProfResumeExperiencesOutput'
  readonly errors: Maybe<ReadonlyArray<UpdateSeekerProfResumeExperienceError>>
  readonly profResume: Maybe<ProfResume>
}

export interface UpdatedProfResumeFullNameOutput {
  readonly __typename?: 'UpdatedProfResumeFullNameOutput'
  readonly errors: Maybe<ReadonlyArray<UpdateSeekerProfResumeFullNameError>>
  readonly profResume: Maybe<ProfResume>
}

export interface UpdatedProfResumeGenderOutput {
  readonly __typename?: 'UpdatedProfResumeGenderOutput'
  readonly errors: Maybe<ReadonlyArray<UpdateSeekerProfResumeGenderError>>
  readonly profResume: Maybe<ProfResume>
}

export interface UpdatedProfResumeHiddenCompaniesOutput {
  readonly __typename?: 'UpdatedProfResumeHiddenCompaniesOutput'
  readonly errors: Maybe<ReadonlyArray<UpdateSeekerProfResumeHiddenCompaniesError>>
  readonly profResume: Maybe<ProfResume>
}

export interface UpdatedProfResumeLanguageSkillsOutput {
  readonly __typename?: 'UpdatedProfResumeLanguageSkillsOutput'
  readonly errors: Maybe<ReadonlyArray<UpdateSeekerProfResumeLanguageSkillsError>>
  readonly profResume: Maybe<ProfResume>
}

export interface UpdatedProfResumeMilitaryStatusOutput {
  readonly __typename?: 'UpdatedProfResumeMilitaryStatusOutput'
  readonly errors: Maybe<ReadonlyArray<UpdateSeekerProfResumeMilitaryStatusError>>
  readonly profResume: Maybe<ProfResume>
}

export interface UpdatedProfResumePhotoOutput {
  readonly __typename?: 'UpdatedProfResumePhotoOutput'
  readonly errors: Maybe<ReadonlyArray<UpdateSeekerProfResumePhotoError>>
  readonly profResume: Maybe<ProfResume>
}

export interface UpdatedProfResumePositionOutput {
  readonly __typename?: 'UpdatedProfResumePositionOutput'
  readonly errors: Maybe<ReadonlyArray<UpdateSeekerProfResumePositionError>>
  readonly profResume: Maybe<ProfResume>
}

export interface UpdatedProfResumeSalaryOutput {
  readonly __typename?: 'UpdatedProfResumeSalaryOutput'
  readonly errors: Maybe<ReadonlyArray<UpdateSeekerProfResumeSalaryError>>
  readonly profResume: Maybe<ProfResume>
}

export interface UpdatedProfResumeScheduleOutput {
  readonly __typename?: 'UpdatedProfResumeScheduleOutput'
  readonly errors: Maybe<ReadonlyArray<UpdateSeekerProfResumeScheduleError>>
  readonly profResume: Maybe<ProfResume>
}

export interface UpdatedProfResumeSearchStateOutput {
  readonly __typename?: 'UpdatedProfResumeSearchStateOutput'
  readonly errors: Maybe<ReadonlyArray<UpdateSeekerProfResumeSearchStateError>>
  readonly profResume: Maybe<ProfResume>
}

export interface UpdatedProfResumeSkillsOutput {
  readonly __typename?: 'UpdatedProfResumeSkillsOutput'
  readonly errors: Maybe<ReadonlyArray<UpdateSeekerProfResumeSkillsError>>
  readonly profResume: Maybe<ProfResume>
}

export interface UsagePeriodTypeWrapper {
  readonly __typename?: 'UsagePeriodTypeWrapper'
  readonly id: Scalars['ID']
  readonly type: ServiceUsagePeriod
}

export enum UsePromoCodeErrorEnum {
  NotFound = 'NOT_FOUND',
  NoCharges = 'NO_CHARGES',
  ServerError = 'SERVER_ERROR',
  WrongState = 'WRONG_STATE'
}

export interface UsePromoCodeResponse {
  readonly __typename?: 'UsePromoCodeResponse'
  readonly error: Maybe<UsePromoCodeErrorEnum>
  readonly isSuccess: Scalars['Boolean']
  readonly promoCodes: ReadonlyArray<PromoCode>
}

export interface UsePromocodesInput {
  readonly ids: ReadonlyArray<Scalars['Guid']>
  readonly orderId: Scalars['ID']
}

export interface UsedHotServiceEmployerEvent {
  readonly __typename?: 'UsedHotServiceEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly performer: PerformerEmployerEventUnion
  readonly restSameServices: Maybe<Scalars['Int']>
  readonly usedService: ServiceEmployerEventSnapshotUnion
  readonly vacancyCity: City
  readonly vacancyId: Scalars['ID']
  readonly vacancyName: Scalars['String']
}

export interface UsedVacancyServiceEmployerEvent {
  readonly __typename?: 'UsedVacancyServiceEmployerEvent'
  readonly addedAt: Scalars['DateTime']
  readonly id: Scalars['ID']
  readonly performer: PerformerEmployerEventUnion
  readonly restSameServices: Maybe<Scalars['Int']>
  readonly usedPublicationType: VacancyPublicationTypeWrapper
  readonly usedService: ServiceEmployerEventSnapshotUnion
  readonly vacancyCity: City
  readonly vacancyId: Scalars['ID']
  readonly vacancyName: Scalars['String']
}

export interface User {
  readonly __typename?: 'User'
  readonly email: Scalars['String']
  readonly employerDetails: Maybe<Employee>
  readonly fullName: Scalars['String']
  readonly id: Scalars['ID']
  readonly isOnline: Maybe<Scalars['Boolean']>
  readonly lastActivityTime: Maybe<Scalars['DateTime']>
  readonly notebookId: Scalars['ID']
  readonly phone: Scalars['String']
  readonly role: UserRoleWrapper
}

export interface UserAccountLogin {
  readonly __typename?: 'UserAccountLogin'
  readonly oneTimeLoginToken: Scalars['String']
  readonly userInfo: User
}

export interface UserCardDeleteOutput {
  readonly __typename?: 'UserCardDeleteOutput'
  readonly id: Scalars['ID']
  readonly messageText: Maybe<Scalars['String']>
}

export interface UserEmailHasBeenSetNotificationDetails {
  readonly __typename?: 'UserEmailHasBeenSetNotificationDetails'
  readonly detailType: SeekerNotificationDetailTypeEnum
  readonly email: Scalars['String']
}

export interface UserInput {
  readonly cityId?: InputMaybe<Scalars['Int']>
  readonly experienceFrom?: InputMaybe<Scalars['Int']>
  readonly experienceTo?: InputMaybe<Scalars['Int']>
  readonly keyword?: InputMaybe<Scalars['String']>
  readonly lang?: InputMaybe<Scalars['String']>
  readonly rubricId?: InputMaybe<Scalars['Int']>
  readonly subRubrics?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Int']>>>
}

export interface UserIsNotAuthenticatedError extends Error {
  readonly __typename?: 'UserIsNotAuthenticatedError'
  readonly reason: Scalars['String']
}

export interface UserIsNotEmployerError extends Error {
  readonly __typename?: 'UserIsNotEmployerError'
  readonly reason: Scalars['String']
}

export interface UserLeftBasketEvent extends EventInterface {
  readonly __typename?: 'UserLeftBasketEvent'
  readonly addDate: Scalars['DateTimeOffset']
  readonly assignment: Maybe<Assignment>
  readonly company: Maybe<CrmCompany>
  readonly daysLeft: Maybe<Scalars['Int']>
  readonly employee: Maybe<CrmEmployee>
  readonly eventType: EventTypeEnum
  readonly expirationDate: Maybe<Scalars['DateTimeOffset']>
  readonly id: Scalars['ID']
  readonly isArchieved: Scalars['Boolean']
  readonly isRead: Scalars['Boolean']
  readonly lifetimeDays: Scalars['Int']
  readonly state: EventStateEnum
  readonly topics: ReadonlyArray<Topic>
}

export interface UserLocationRadiusFilterInput {
  readonly radius: Scalars['Int']
  readonly userLocation: PublishedVacanciesLocationInput
}

export interface UserRegistrationError {
  readonly __typename?: 'UserRegistrationError'
  readonly description: Scalars['String']
  readonly id: Scalars['ID']
  readonly status: UserRegistrationErrorEnum
}

export enum UserRegistrationErrorEnum {
  AlreadyExistsEmployer = 'ALREADY_EXISTS_EMPLOYER',
  AlreadyExistsSeeker = 'ALREADY_EXISTS_SEEKER',
  InternalError = 'INTERNAL_ERROR',
  InvalidInputCityId = 'INVALID_INPUT_CITY_ID',
  InvalidInputCompanyName = 'INVALID_INPUT_COMPANY_NAME',
  InvalidInputDescription = 'INVALID_INPUT_DESCRIPTION',
  InvalidInputEmail = 'INVALID_INPUT_EMAIL',
  InvalidInputEmployeesCount = 'INVALID_INPUT_EMPLOYEES_COUNT',
  InvalidInputFullname = 'INVALID_INPUT_FULLNAME',
  InvalidInputPassword = 'INVALID_INPUT_PASSWORD',
  InvalidInputPhone = 'INVALID_INPUT_PHONE'
}

export interface UserRegistrationMutations {
  readonly __typename?: 'UserRegistrationMutations'
  readonly employer: EmployerRegistration
  readonly seeker: SeekerRegistration
  readonly sendRegistrationConfirmationEmail: SendEmailOutput
}

export interface UserRegistrationMutationsSendRegistrationConfirmationEmailArgs {
  input: SendRegistrationConfirmEmailInput
}

export interface UserRegistrationValidationResponse {
  readonly __typename?: 'UserRegistrationValidationResponse'
  readonly errors: ReadonlyArray<UserRegistrationError>
  readonly isSuccess: Scalars['Boolean']
  readonly login: Scalars['ID']
  readonly similarCompanies: ReadonlyArray<Company>
}

export interface UserRoleWrapper {
  readonly __typename?: 'UserRoleWrapper'
  readonly id: Scalars['ID']
  readonly type: UserRolesEnum
}

export enum UserRolesEnum {
  Employer = 'EMPLOYER',
  Seeker = 'SEEKER'
}

export interface UserState {
  readonly __typename?: 'UserState'
  readonly department: Maybe<DepartmentEnum>
  readonly id: Maybe<Scalars['ID']>
  readonly isSM: Maybe<Scalars['Boolean']>
  readonly isSTM: Maybe<Scalars['Boolean']>
  readonly roles: Maybe<Scalars['String']>
}

export interface Users {
  readonly __typename?: 'Users'
  readonly confirmEmailByCode: ConfirmEmailCodeOutput
  readonly confirmProfileDeletion: ConfirmProfileDeletionOutput
  readonly deleteProfile: DeleteProfileOutput
  readonly login: LoginMutations
  readonly registration: UserRegistrationMutations
  readonly seeker: SeekerMutations
  readonly sendConfirmCodeEmail: SendEmailOutput
  readonly sendLoginMagicLinkEmail: SendEmailOutput
}

export interface UsersConfirmEmailByCodeArgs {
  input: ConfirmEmailByCodeInput
}

export interface UsersConfirmProfileDeletionArgs {
  input: InputMaybe<ConfirmProfileDeletionInput>
}

export interface UsersSendConfirmCodeEmailArgs {
  input: SendConfirmCodeEmailInput
}

export interface UsersSendLoginMagicLinkEmailArgs {
  input: SendLoginEmailInput
}

export interface UsersManagementFilters {
  readonly __typename?: 'UsersManagementFilters'
  readonly activeStatus: EmployeeActiveStatus
  readonly orderBy: EmployeesOrderBy
  readonly term: Scalars['String']
}

export interface UsersManagementFiltersInput {
  readonly activeStatus?: InputMaybe<EmployeeActiveStatus>
  readonly orderBy?: InputMaybe<EmployeesOrderBy>
  readonly term?: InputMaybe<Scalars['String']>
}

export interface UsersManagementPageInfo {
  readonly __typename?: 'UsersManagementPageInfo'
  readonly cursor: Scalars['String']
  readonly hasNextPage: Scalars['Boolean']
  readonly total: Scalars['Int']
}

export interface UsersManagementPageInfoInput {
  readonly cursor: Scalars['String']
  readonly hasNextPage: Scalars['Boolean']
  readonly total: Scalars['Int']
}

export interface UsersManagementSubFilter {
  readonly __typename?: 'UsersManagementSubFilter'
  readonly activeStatus: EmployeeActiveStatus
  readonly term: Scalars['String']
}

export interface VacanciesPageClientFilters {
  readonly __typename?: 'VacanciesPageClientFilters'
  readonly cityIds: ReadonlyArray<Scalars['ID']>
  readonly closingBehaviors: ReadonlyArray<VacancyClosingBehavior>
  readonly employerIds: ReadonlyArray<Scalars['ID']>
  readonly keyword: Scalars['String']
  readonly sortType: MyVacanciesSortType
  readonly statuses: ReadonlyArray<VacancyStatus>
}

export interface VacanciesPageClientFiltersInput {
  readonly cityIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
  readonly closingBehaviors?: InputMaybe<ReadonlyArray<VacancyClosingBehavior>>
  readonly employerIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
  readonly keyword?: InputMaybe<Scalars['String']>
  readonly sortType?: InputMaybe<MyVacanciesSortType>
  readonly statuses?: InputMaybe<ReadonlyArray<VacancyStatus>>
}

export interface Vacancy {
  readonly __typename?: 'Vacancy'
  readonly activateCvMailing: Maybe<Scalars['Boolean']>
  readonly additionalCandidatePermissions: VacancyAdditionalCandidatePermissions
  readonly address: Maybe<VacancyAddress>
  readonly adminRejectDetails: Maybe<VacancyAdminRejectDetails>
  readonly allowedStatuses: ReadonlyArray<Maybe<VacancyStatus>>
  readonly allowedVacancyActions: ReadonlyArray<Maybe<AllowedVacancyActions>>
  readonly anonymous: Scalars['Boolean']
  readonly applicationsDestination: VacancyApplicationsDestination
  readonly appliesByLink: Maybe<AppliesByUrl>
  readonly appliesByLinkCount: Maybe<Scalars['Int']>
  readonly badges: ReadonlyArray<Maybe<PublishedVacancyBadge>>
  readonly benefits: Maybe<ReadonlyArray<Maybe<VacancyBenefit>>>
  readonly branch: Maybe<Branch>
  readonly candidatesCount: Scalars['Int']
  readonly candidatesCounters: ReadonlyArray<CandidatesVacancyCounters>
  readonly candidatesScreening: VacancyCandidatesScreeningOptions
  readonly chatStatus: VacancyChatStatusEnum
  readonly city: City
  readonly closingBehavior: VacancyClosingBehavior
  readonly closingType: VacancyClosingType
  readonly code: Maybe<Scalars['String']>
  readonly company: Maybe<Company>
  readonly contacts: VacancyContacts
  readonly createdAt: Scalars['DateTime']
  readonly currentPublicationService: Maybe<CatalogService>
  readonly description: Scalars['String']
  /** @deprecated The test has ended */
  readonly descriptionUpper: Maybe<Scalars['String']>
  readonly design: Maybe<VacancyDesign>
  readonly designBannerUrl: Maybe<Scalars['String']>
  readonly designId: Maybe<Scalars['Int']>
  readonly designType: Maybe<Scalars['Int']>
  readonly distanceText: Scalars['String']
  readonly employmentTypes: ReadonlyArray<Maybe<VacancyEmploymentType>>
  readonly experienceQuestions: Maybe<ReadonlyArray<Maybe<VacancyExperienceQuestion>>>
  readonly firstPublishedAt: Maybe<Scalars['DateTime']>
  readonly formApplyCustomUrl: Maybe<Scalars['String']>
  readonly fullDescription: Scalars['String']
  /** @deprecated Wrong Schema Design */
  readonly groupKeyTags: Maybe<ReadonlyArray<Maybe<GroupTag>>>
  readonly hasDesign: Scalars['Boolean']
  readonly hasMyUnreviewedProlongationRequest: Scalars['Boolean']
  readonly hideFromCompanyProfile: Scalars['Boolean']
  readonly hot: Scalars['Boolean']
  readonly hotPeriod: Maybe<VacancyHotPeriod>
  readonly id: Scalars['ID']
  readonly interviewQuestions: Maybe<Scalars['String']>
  readonly isActive: Scalars['Boolean']
  readonly isAgency: Scalars['Boolean']
  readonly isPublicationInAllCities: Maybe<Scalars['Boolean']>
  readonly keyTagGroups: Maybe<ReadonlyArray<Maybe<TagGroup>>>
  readonly languageQuestions: Maybe<ReadonlyArray<Maybe<VacancyLanguageQuestion>>>
  readonly languageSkillsRequired: Scalars['Boolean']
  readonly linkClicks: Maybe<ClickOnLinks>
  readonly location: Maybe<PublishedVacanciesLocation>
  readonly madeAt: Scalars['DateTime']
  readonly media: Maybe<ReadonlyArray<Maybe<VacancyMedia>>>
  readonly moderatorRejectDetails: Maybe<VacancyModeratorRejectDetails>
  readonly modifyDate: Maybe<Scalars['DateTime']>
  readonly offerMessageFromEmployerToSeeker: Maybe<Scalars['String']>
  readonly owner: VacancyEmployer
  readonly positionRising: Maybe<VacancyPositionRisingInSearchList>
  readonly profLevelId: Scalars['Int']
  readonly publicationType: VacancyPublicationType
  readonly publicationTypeEnumWrapper: VacancyPublicationTypeWrapper
  readonly publishPeriod: Maybe<VacancyPublishPeriod>
  readonly recommendedCandidates: Maybe<RecommendedCandidatesConnection>
  readonly recommendedProfResumes: Maybe<RecommendedProfResumes>
  readonly regionalPackage: Maybe<Scalars['String']>
  readonly salary: Maybe<VacancySalary>
  readonly schedules: ReadonlyArray<Maybe<Schedule>>
  readonly seekerApplication: Maybe<SeekerApplication>
  readonly seekerDisliked: Maybe<SeekerDislikedVacancyDetails>
  readonly seekerFavorite: Maybe<SeekerFavoriteVacancyDetails>
  readonly serviceActivationBehavior: VacancyServiceActivationBehavior
  readonly showLogo: Scalars['Boolean']
  readonly showProfile: Scalars['Boolean']
  readonly sortDate: Scalars['DateTime']
  readonly sortDateText: Scalars['String']
  readonly statisticsTimeseries: Maybe<VacancyStatisticsTimeseries>
  readonly status: VacancyStatus
  readonly statusChangedAt: Maybe<Scalars['DateTime']>
  readonly subrubrics: ReadonlyArray<Subrubric>
  readonly supportApplicationWithoutResume: Scalars['Boolean']
  readonly title: Scalars['String']
  readonly type: VacancyType
  readonly updatedAt: Maybe<Scalars['DateTime']>
  readonly vacancyCompany: VacancyCompany
  readonly vacancyCompanyId: Maybe<Scalars['String']>
  /** @deprecated The test has ended */
  readonly vacancyCompanyName: Maybe<Scalars['String']>
  readonly vacancyCompetitors: Maybe<VacancyCompetitors>
  readonly vacancyKeywords: ReadonlyArray<Maybe<ApplyVacancyKeyword>>
  readonly vacancyPositions: Maybe<ReadonlyArray<Maybe<VacancyPosition>>>
  readonly workTypes: ReadonlyArray<Maybe<VacancyWorkType>>
}

export interface VacancyAppliesByLinkArgs {
  range: DateRange
}

export interface VacancyCandidatesCountArgs {
  filter: InputMaybe<ApplyCountFilterInput>
}

export interface VacancyLinkClicksArgs {
  range: DateRange
}

export interface VacancyRecommendedCandidatesArgs {
  after: InputMaybe<Scalars['String']>
  filter: InputMaybe<RecommendedCandidatesFilter>
  first: InputMaybe<Scalars['Int']>
}

export interface VacancyRecommendedProfResumesArgs {
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  notebookId: InputMaybe<Scalars['Int']>
  resumeType: RecommendedProfResumeType
}

export interface VacancyStatisticsTimeseriesArgs {
  range: DateRangeInput
}

export interface VacancyVacancyCompetitorsArgs {
  notebookId: InputMaybe<Scalars['ID']>
}

export interface VacancyAdditionalCandidatePermissions {
  readonly __typename?: 'VacancyAdditionalCandidatePermissions'
  readonly specialNeeds: Scalars['Boolean']
  readonly student: Scalars['Boolean']
}

export interface VacancyAdditionalCandidatePermissionsInput {
  readonly specialNeeds?: InputMaybe<Scalars['Boolean']>
  readonly student?: InputMaybe<Scalars['Boolean']>
}

export interface VacancyAddress {
  readonly __typename?: 'VacancyAddress'
  readonly district: Maybe<CityDistrict>
  readonly latitude: Maybe<Scalars['Decimal']>
  readonly longitude: Maybe<Scalars['Decimal']>
  readonly metro: Maybe<Metro>
  readonly name: Scalars['String']
}

export interface VacancyAddressInput {
  readonly districtId?: InputMaybe<Scalars['ID']>
  readonly latitude?: InputMaybe<Scalars['Decimal']>
  readonly longitude?: InputMaybe<Scalars['Decimal']>
  readonly metroId?: InputMaybe<Scalars['ID']>
  readonly name?: InputMaybe<Scalars['String']>
}

export interface VacancyAddressesSuggestInput {
  readonly cityId: Scalars['ID']
  readonly count: Scalars['Int']
}

export interface VacancyAddressesSuggestOutput {
  readonly __typename?: 'VacancyAddressesSuggestOutput'
  readonly addresses: ReadonlyArray<Scalars['String']>
}

export interface VacancyAdminRejectDetails {
  readonly __typename?: 'VacancyAdminRejectDetails'
  readonly comment: Scalars['String']
  readonly commentMadeAt: Scalars['DateTime']
  readonly madeAt: Scalars['String']
}

export interface VacancyAppearedInViewPortInput {
  readonly place: VacancyAppearedTypeEnum
  readonly vacancyIds: ReadonlyArray<Scalars['ID']>
}

export interface VacancyAppearedInViewPortOutput {
  readonly __typename?: 'VacancyAppearedInViewPortOutput'
  readonly isSuccessful: Scalars['Boolean']
}

export enum VacancyAppearedTypeEnum {
  Company = 'COMPANY',
  Recommended = 'RECOMMENDED',
  Search = 'SEARCH',
  Similar = 'SIMILAR'
}

export interface VacancyApplicationsDestination {
  readonly __typename?: 'VacancyApplicationsDestination'
  readonly location: Maybe<Scalars['String']>
  readonly type: ApplicationsDestinationType
}

export interface VacancyApplicationsDestinationInput {
  readonly location?: InputMaybe<Scalars['String']>
  readonly type: ApplicationsDestinationType
}

export interface VacancyAwaitingApprovalDetails {
  readonly __typename?: 'VacancyAwaitingApprovalDetails'
  readonly detailType: SeekerNotificationDetailTypeEnum
  readonly vacancySnapshot: NotificationCommonVacancySnapshot
}

export interface VacancyBadge {
  readonly __typename?: 'VacancyBadge'
  readonly URL: Maybe<Scalars['String']>
  readonly name: Maybe<Scalars['String']>
  readonly statistic: Maybe<StatisticVacancyBadge>
}

export interface VacancyBannedByModeratorDetails {
  readonly __typename?: 'VacancyBannedByModeratorDetails'
  readonly detailType: SeekerNotificationDetailTypeEnum
  /** @deprecated Use field vacancySnapshot instead */
  readonly vacancy: Maybe<Vacancy>
  readonly vacancySnapshot: NotificationCommonVacancySnapshot
}

export interface VacancyBannedEvent extends EventInterface {
  readonly __typename?: 'VacancyBannedEvent'
  readonly addDate: Scalars['DateTimeOffset']
  readonly assignment: Maybe<Assignment>
  readonly company: Maybe<CrmCompany>
  readonly daysLeft: Maybe<Scalars['Int']>
  readonly employee: Maybe<CrmEmployee>
  readonly eventType: EventTypeEnum
  readonly expirationDate: Maybe<Scalars['DateTimeOffset']>
  readonly id: Scalars['ID']
  readonly isArchieved: Scalars['Boolean']
  readonly isRead: Scalars['Boolean']
  readonly lifetimeDays: Scalars['Int']
  readonly moderatorLogin: Maybe<Scalars['String']>
  readonly state: EventStateEnum
  readonly topics: ReadonlyArray<Topic>
  readonly vacancy: Maybe<Vacancy>
}

export interface VacancyBenefit {
  readonly __typename?: 'VacancyBenefit'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export interface VacancyCandidatesScreeningOptions {
  readonly __typename?: 'VacancyCandidatesScreeningOptions'
  readonly isEnabled: Scalars['Boolean']
  readonly questionnaire: Maybe<Questionnaire>
}

export interface VacancyCandidatesScreeningOptionsInput {
  readonly isEnabled: Scalars['Boolean']
  readonly questionnaireId?: InputMaybe<Scalars['ID']>
}

export interface VacancyChangeClosingBehaviorInput {
  readonly behavior: VacancyClosingBehavior
  readonly serviceActivationBehavior?: InputMaybe<VacancyServiceActivationBehaviorInput>
  readonly vacancies?: InputMaybe<ReadonlyArray<InputMaybe<MultipleVacanciesChangeClosing>>>
  readonly vacancyId: Scalars['ID']
}

export interface VacancyChangeClosingBehaviorOutput {
  readonly __typename?: 'VacancyChangeClosingBehaviorOutput'
  readonly vacancies: Maybe<ReadonlyArray<Maybe<Vacancy>>>
  readonly vacancy: Maybe<Vacancy>
}

export interface VacancyChangeHistory {
  readonly __typename?: 'VacancyChangeHistory'
  readonly employerName: Scalars['String']
  readonly madeAt: Scalars['String']
  readonly type: Scalars['String']
  readonly userName: Scalars['String']
}

export interface VacancyChangeOwnerInput {
  readonly contactEmail?: InputMaybe<Scalars['String']>
  readonly contactPersonName?: InputMaybe<Scalars['String']>
  readonly contactPhone?: InputMaybe<Scalars['String']>
  readonly employerId: Scalars['ID']
  readonly vacanciesIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  readonly vacancyId: Scalars['ID']
}

export interface VacancyChangeOwnerOutput {
  readonly __typename?: 'VacancyChangeOwnerOutput'
  readonly vacancies: Maybe<ReadonlyArray<Maybe<Vacancy>>>
  readonly vacancy: Maybe<Vacancy>
}

export enum VacancyChatStatusEnum {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
  OnlyWithApply = 'ONLY_WITH_APPLY'
}

export interface VacancyCityCount {
  readonly __typename?: 'VacancyCityCount'
  readonly city: City
  readonly count: Scalars['Int']
}

export interface VacancyCloseInput {
  readonly vacanciesIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  readonly vacancyId: Scalars['ID']
}

export interface VacancyCloseOutput {
  readonly __typename?: 'VacancyCloseOutput'
  readonly vacancies: Maybe<ReadonlyArray<Maybe<Vacancy>>>
  readonly vacancy: Maybe<Vacancy>
}

export enum VacancyClosingBehavior {
  Close = 'CLOSE',
  CloseAndNotify = 'CLOSE_AND_NOTIFY',
  RepublishEveryNextMonth = 'REPUBLISH_EVERY_NEXT_MONTH',
  RepublishForOneMonth = 'REPUBLISH_FOR_ONE_MONTH'
}

export enum VacancyClosingType {
  AutoRepublish = 'AUTO_REPUBLISH',
  Close = 'CLOSE',
  CloseAndNotify = 'CLOSE_AND_NOTIFY'
}

export interface VacancyCompany {
  readonly __typename?: 'VacancyCompany'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export interface VacancyCompetitors {
  readonly __typename?: 'VacancyCompetitors'
  readonly vacancies: Maybe<ReadonlyArray<Maybe<Vacancy>>>
  readonly vacancyCompetitorsStatistics: Maybe<VacancyCompetitorsStatistics>
}

export interface VacancyCompetitorsStatistics {
  readonly __typename?: 'VacancyCompetitorsStatistics'
  readonly allCount: Maybe<Scalars['Int']>
  readonly anonymousCount: Maybe<Scalars['Int']>
  readonly businessCount: Maybe<Scalars['Int']>
  readonly businessPercent: Maybe<Scalars['Float']>
  readonly hotCount: Maybe<Scalars['Int']>
  readonly hotPercent: Maybe<Scalars['Float']>
  readonly optimumCount: Maybe<Scalars['Int']>
  readonly optimumPercent: Maybe<Scalars['Float']>
  readonly professionalCount: Maybe<Scalars['Int']>
  readonly professionalPercent: Maybe<Scalars['Float']>
  readonly successfulVacanciesSalary: Maybe<Scalars['Int']>
  readonly testCount: Maybe<Scalars['Int']>
}

export interface VacancyContacts {
  readonly __typename?: 'VacancyContacts'
  readonly name: Scalars['String']
  readonly phones: ReadonlyArray<Maybe<Scalars['String']>>
  readonly photo: Maybe<Scalars['String']>
  readonly socials: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>
}

export interface VacancyContactsInput {
  readonly name?: InputMaybe<Scalars['String']>
  readonly phones?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
  readonly photo?: InputMaybe<Scalars['String']>
  readonly socials?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
}

export interface VacancyCounterGraphType {
  readonly __typename?: 'VacancyCounterGraphType'
  readonly activeVacancyCount: Scalars['Int']
  readonly lastVisit: Scalars['DateTime']
  readonly otherSites: ReadonlyArray<OtherSiteLink>
  readonly ourPublishedCount: Scalars['Int']
  readonly resumeViewCount: Scalars['Int']
  readonly totalVacancyCount: Scalars['Int']
  readonly uniquePotential: Scalars['Int']
  readonly uniquePublishedCount: Scalars['Int']
  readonly workPublishedCount: Scalars['Int']
  readonly workVacancyCount: Scalars['Int']
}

export type VacancyCreateError = CompanyWasMergedError | InvalidError | NoAvailableLimitsError | TurbotaWasExpiredError | UserIsNotAuthenticatedError | UserIsNotEmployerError

export interface VacancyCreateInput {
  readonly activateCvMailing?: InputMaybe<Scalars['Boolean']>
  readonly additionalCandidatePermissions?: InputMaybe<VacancyAdditionalCandidatePermissionsInput>
  readonly address?: InputMaybe<VacancyAddressInput>
  readonly applicationsDestination?: InputMaybe<VacancyApplicationsDestinationInput>
  readonly benefits?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
  readonly branchId?: InputMaybe<Scalars['ID']>
  readonly candidatesScreeningOptions?: InputMaybe<VacancyCandidatesScreeningOptionsInput>
  readonly chatStatus?: InputMaybe<VacancyChatStatusEnum>
  readonly cityId: Scalars['ID']
  readonly closingBehavior?: InputMaybe<VacancyClosingBehavior>
  readonly code?: InputMaybe<Scalars['String']>
  readonly contacts?: InputMaybe<VacancyContactsInput>
  readonly deleteDraftIfErrorOccured?: InputMaybe<Scalars['Boolean']>
  readonly description?: InputMaybe<Scalars['String']>
  readonly designId?: InputMaybe<Scalars['Int']>
  readonly doesSupportApplicationWithoutResume?: InputMaybe<Scalars['Boolean']>
  readonly employmentTypes?: InputMaybe<ReadonlyArray<InputMaybe<VacancyEmploymentType>>>
  readonly experienceQuestions?: InputMaybe<ReadonlyArray<InputMaybe<VacancyExperienceQuestionInput>>>
  readonly hideFromCompanyProfile?: InputMaybe<Scalars['Boolean']>
  readonly isPublicationInAllCities?: InputMaybe<Scalars['Boolean']>
  readonly languageQuestions?: InputMaybe<ReadonlyArray<InputMaybe<VacancyLanguageQuestionInput>>>
  readonly languageSkillsRequired?: InputMaybe<Scalars['Boolean']>
  readonly media?: InputMaybe<ReadonlyArray<InputMaybe<VacancyMediaInput>>>
  readonly publicationServiceId?: InputMaybe<Scalars['Int']>
  readonly publicationType?: InputMaybe<VacancyPublicationType>
  readonly salary?: InputMaybe<VacancySalaryInput>
  readonly status?: InputMaybe<VacancyStatus>
  readonly supportApplicationWithoutResume?: InputMaybe<Scalars['Boolean']>
  readonly title: Scalars['String']
  readonly workTypes?: InputMaybe<ReadonlyArray<InputMaybe<VacancyWorkType>>>
}

export interface VacancyCreateOutput {
  readonly __typename?: 'VacancyCreateOutput'
  readonly error: Maybe<VacancyCreateError>
  readonly vacancy: Maybe<Vacancy>
}

export interface VacancyCreateSuggest {
  readonly __typename?: 'VacancyCreateSuggest'
  readonly list: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>
  readonly previous: Maybe<Scalars['String']>
}

export interface VacancyCreateSuggestOutput {
  readonly __typename?: 'VacancyCreateSuggestOutput'
  readonly activateCvMailing: Maybe<Scalars['Boolean']>
  readonly address: Maybe<VacancyCreateSuggest>
  readonly applicationDestinationLocation: Maybe<VacancyCreateSuggest>
  readonly benefits: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>
  readonly cityId: Maybe<Scalars['ID']>
  readonly contactName: Maybe<VacancyCreateSuggest>
  readonly contactPhones: Maybe<VacancyCreateSuggest>
  readonly contactPhoto: Maybe<Scalars['String']>
  readonly contactSocials: Maybe<VacancyCreateSuggest>
  readonly title: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>
}

export interface VacancyDesign {
  readonly __typename?: 'VacancyDesign'
  readonly backgroundHtml: Maybe<Scalars['String']>
  readonly footerInfo: Maybe<VacancyDesignFooter>
  readonly headerInfo: Maybe<VacancyDesignHeader>
  readonly id: Scalars['ID']
}

export interface VacancyDesignFooter {
  readonly __typename?: 'VacancyDesignFooter'
  readonly imageUrl: Maybe<Scalars['String']>
}

export interface VacancyDesignHeader {
  readonly __typename?: 'VacancyDesignHeader'
  readonly mediaItems: Maybe<ReadonlyArray<Maybe<VacancyDesignHeaderItem>>>
  readonly videoPlayButtonImageUrl: Maybe<Scalars['String']>
}

export interface VacancyDesignHeaderItem {
  readonly __typename?: 'VacancyDesignHeaderItem'
  readonly type: Maybe<VacancyDesignHeaderType>
  readonly url: Maybe<Scalars['String']>
  readonly videoCoverImageUrl: Maybe<Scalars['String']>
}

export enum VacancyDesignHeaderType {
  Image = 'IMAGE',
  YouTubeVideo = 'YOU_TUBE_VIDEO'
}

export interface VacancyDictionaryBenefit {
  readonly __typename?: 'VacancyDictionaryBenefit'
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
}

export interface VacancyDraftInput {
  readonly vacancyId: Scalars['ID']
}

export interface VacancyDraftOutput {
  readonly __typename?: 'VacancyDraftOutput'
  readonly vacancy: Maybe<Vacancy>
}

export interface VacancyEdge {
  readonly __typename?: 'VacancyEdge'
  readonly cursor: Scalars['String']
  readonly node: Maybe<Vacancy>
}

export interface VacancyEmployer {
  readonly __typename?: 'VacancyEmployer'
  readonly applyLetterTemplate: Maybe<Scalars['String']>
  readonly contactEmail: Scalars['String']
  readonly contactPhone: Scalars['String']
  readonly fullName: Scalars['String']
  readonly id: Scalars['ID']
}

export interface VacancyEmployerCount {
  readonly __typename?: 'VacancyEmployerCount'
  readonly count: Scalars['Int']
  readonly employer: VacancyEmployer
}

export enum VacancyEmploymentType {
  FullTime = 'FULL_TIME',
  PartTime = 'PART_TIME',
  ProjectBased = 'PROJECT_BASED',
  Shift = 'SHIFT'
}

export interface VacancyEndingCountDetails {
  readonly __typename?: 'VacancyEndingCountDetails'
  readonly detailType: SeekerNotificationDetailTypeEnum
  readonly vacancyEndingCountSnapshot: NotificationVacancyEndingCountSnapshot
}

export interface VacancyEndingLeftDaysDetails {
  readonly __typename?: 'VacancyEndingLeftDaysDetails'
  readonly detailType: SeekerNotificationDetailTypeEnum
  /** @deprecated Use field vacancySnapshot instead */
  readonly vacancy: Maybe<Vacancy>
  readonly vacancySnapshot: NotificationEndingDaysVacancySnapshot
}

export interface VacancyEventStatisticItem {
  readonly __typename?: 'VacancyEventStatisticItem'
  /** @deprecated Moved to datetime */
  readonly date: Maybe<Scalars['Date']>
  readonly datetime: Maybe<Scalars['DateTime']>
  readonly type: Maybe<VacancyEventType>
}

export enum VacancyEventType {
  Changed = 'CHANGED',
  Completed = 'COMPLETED',
  Hot = 'HOT',
  PublicationTypeChanged = 'PUBLICATION_TYPE_CHANGED',
  Published = 'PUBLISHED'
}

export interface VacancyExperienceQuestion {
  readonly __typename?: 'VacancyExperienceQuestion'
  readonly experience: Experience
  readonly id: Scalars['ID']
  readonly label: Scalars['String']
}

export interface VacancyExperienceQuestionInput {
  readonly experienceId: Scalars['ID']
  readonly label: Scalars['ID']
}

export interface VacancyFromAnotherSourceGraphType {
  readonly __typename?: 'VacancyFromAnotherSourceGraphType'
  readonly additionalCandidatePermissions: VacancyAdditionalCandidatePermissions
  readonly address: Maybe<VacancyAddress>
  readonly city: City
  readonly contacts: VacancyContacts
  readonly description: Scalars['String']
  readonly employmentTypes: ReadonlyArray<VacancyEmploymentType>
  readonly hasSupportApplicationWithoutResume: Scalars['Boolean']
  readonly salary: Maybe<VacancySalary>
  readonly title: Scalars['String']
  readonly workTypes: ReadonlyArray<VacancyWorkType>
}

export interface VacancyHotPeriod {
  readonly __typename?: 'VacancyHotPeriod'
  readonly begin: Scalars['DateTime']
  readonly daysUntilEnd: Scalars['Int']
  readonly end: Scalars['DateTime']
}

export interface VacancyInterviewQuestionsMutations {
  readonly __typename?: 'VacancyInterviewQuestionsMutations'
  readonly update: SaveVacancyInterviewQuestionsOutput
}

export interface VacancyInterviewQuestionsMutationsUpdateArgs {
  input: SaveVacancyInterviewQuestionsInput
}

export interface VacancyLanguageQuestion {
  readonly __typename?: 'VacancyLanguageQuestion'
  readonly language: Lang
  readonly languageSkillLevel: LanguageSkillLevel
}

export interface VacancyLanguageQuestionInput {
  readonly languageId: Scalars['ID']
  readonly languageSkillId: Scalars['ID']
}

export interface VacancyMakeHotInput {
  readonly vacancyId: Scalars['ID']
  readonly weeksNumber: Scalars['Int']
}

export interface VacancyMakeHotOutput {
  readonly __typename?: 'VacancyMakeHotOutput'
  readonly vacancy: Maybe<Vacancy>
}

export interface VacancyMedia {
  readonly __typename?: 'VacancyMedia'
  readonly description: Maybe<Scalars['String']>
  readonly type: VacancyMediaType
  readonly url: Scalars['String']
}

export interface VacancyMediaInput {
  readonly description?: InputMaybe<Scalars['String']>
  readonly type: VacancyMediaType
  readonly url: Scalars['String']
}

export enum VacancyMediaType {
  UploadedImage = 'UPLOADED_IMAGE',
  YoutubeVideo = 'YOUTUBE_VIDEO'
}

export interface VacancyModeratorRejectDetails {
  readonly __typename?: 'VacancyModeratorRejectDetails'
  readonly commentMadeAt: Scalars['DateTime']
  readonly comments: ReadonlyArray<Maybe<Scalars['String']>>
  readonly madeAt: Scalars['String']
}

export interface VacancyOnWorkWillExpireInFiveDaysEvent extends EventInterface {
  readonly __typename?: 'VacancyOnWorkWillExpireInFiveDaysEvent'
  readonly addDate: Scalars['DateTimeOffset']
  readonly assignment: Maybe<Assignment>
  readonly company: Maybe<CrmCompany>
  readonly daysLeft: Maybe<Scalars['Int']>
  readonly employee: Maybe<CrmEmployee>
  readonly eventType: EventTypeEnum
  readonly expirationDate: Maybe<Scalars['DateTimeOffset']>
  readonly id: Scalars['ID']
  readonly isArchieved: Scalars['Boolean']
  readonly isRead: Scalars['Boolean']
  readonly lifetimeDays: Scalars['Int']
  readonly spiderVacancyLink: Scalars['String']
  readonly spiderVacancyName: Scalars['String']
  readonly state: EventStateEnum
  readonly topics: ReadonlyArray<Topic>
  readonly workVacancy: Maybe<SpiderVacancy>
}

export interface VacancyOwnerChangedDetails {
  readonly __typename?: 'VacancyOwnerChangedDetails'
  readonly detailType: SeekerNotificationDetailTypeEnum
  readonly initiator: NotificationInitiator
  readonly newOwner: NotificationEmployeeSnapshot
  readonly oldOwner: NotificationEmployeeSnapshot
  readonly vacancySnapshot: NotificationOwnerChangedVacancySnapshot
}

export interface VacancyPackageCatalogService {
  readonly __typename?: 'VacancyPackageCatalogService'
  readonly additionalServices: ReadonlyArray<CatalogChildService>
  readonly calculatePrice: ServicePrice
  readonly calculatePrices: ReadonlyArray<ServicePrice>
  readonly contactsAccrualPeriod: Maybe<ContactsAccrualPeriod>
  readonly contactsCount: Maybe<Scalars['Int']>
  readonly groupWrapper: ServiceGroupWrapper
  readonly id: Scalars['ID']
  readonly isPackage: Maybe<Scalars['Boolean']>
  readonly isUnlimitedContacts: Maybe<Scalars['Boolean']>
  readonly name: Maybe<Scalars['String']>
  readonly publicationType: Maybe<VacancyPublicationType>
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly supportedRegions: ReadonlyArray<City>
  readonly totalVacancyCount: Maybe<Scalars['Int']>
  readonly typeWrapper: ServiceTypeWrapper
  readonly vacancyMailingCount: Maybe<Scalars['Int']>
  readonly vacancyPublishDays: Maybe<Scalars['Int']>
  readonly vacancyRisingCount: Maybe<Scalars['Int']>
}

export interface VacancyPackageCatalogServiceCalculatePriceArgs {
  input: InputMaybe<CalculateServicePriceInput>
}

export interface VacancyPackageCatalogServiceCalculatePricesArgs {
  input: InputMaybe<CalculateServicePriceMultipleInput>
}

export interface VacancyPosition {
  readonly __typename?: 'VacancyPosition'
  readonly groupTag: Maybe<Scalars['String']>
  readonly position: Maybe<Scalars['Int']>
  readonly totalCount: Maybe<Scalars['Int']>
}

export interface VacancyPositionRisingInSearchList {
  readonly __typename?: 'VacancyPositionRisingInSearchList'
  readonly last: Scalars['DateTime']
  readonly leftDates: ReadonlyArray<Maybe<Scalars['DateTime']>>
  readonly leftTimes: Scalars['Int']
  readonly next: Scalars['DateTime']
}

export interface VacancyProlongationRequest {
  readonly __typename?: 'VacancyProlongationRequest'
  readonly desiredClosingBehavior: VacancyClosingBehavior
  readonly desiredServiceActivationBehavior: VacancyServiceActivationBehavior
  readonly id: Scalars['ID']
  readonly vacancy: Vacancy
}

export interface VacancyProlongationRequestsOutput {
  readonly __typename?: 'VacancyProlongationRequestsOutput'
  readonly requests: ReadonlyArray<VacancyProlongationRequest>
}

export type VacancyProlongationReviewError = AlreadyReviewedVacancyProlongationError

export interface VacancyProlongationReviewInput {
  readonly isApproved: Scalars['Boolean']
  readonly requestId?: InputMaybe<Scalars['ID']>
  readonly requestIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>
}

export interface VacancyProlongationReviewOutput {
  readonly __typename?: 'VacancyProlongationReviewOutput'
  readonly error: Maybe<VacancyProlongationReviewError>
  readonly vacancies: Maybe<ReadonlyArray<Vacancy>>
  readonly vacancy: Maybe<Vacancy>
}

export interface VacancyPublicationApprovedDetails {
  readonly __typename?: 'VacancyPublicationApprovedDetails'
  readonly detailType: SeekerNotificationDetailTypeEnum
  readonly vacancySnapshot: NotificationCommonVacancySnapshot
}

export interface VacancyPublicationCatalogService {
  readonly __typename?: 'VacancyPublicationCatalogService'
  readonly calculatePrice: ServicePrice
  readonly calculatePrices: ReadonlyArray<ServicePrice>
  readonly groupWrapper: ServiceGroupWrapper
  readonly id: Scalars['ID']
  readonly isPackage: Maybe<Scalars['Boolean']>
  readonly name: Maybe<Scalars['String']>
  readonly publicationType: Maybe<VacancyPublicationType>
  readonly serviceActiveDays: Maybe<Scalars['Int']>
  readonly supportedRegions: ReadonlyArray<City>
  readonly typeWrapper: ServiceTypeWrapper
  readonly vacancyMailingCount: Maybe<Scalars['Int']>
  readonly vacancyPublishDays: Maybe<Scalars['Int']>
  readonly vacancyRisingCount: Maybe<Scalars['Int']>
}

export interface VacancyPublicationCatalogServiceCalculatePriceArgs {
  input: InputMaybe<CalculateServicePriceInput>
}

export interface VacancyPublicationCatalogServiceCalculatePricesArgs {
  input: InputMaybe<CalculateServicePriceMultipleInput>
}

export interface VacancyPublicationEndedDetails {
  readonly __typename?: 'VacancyPublicationEndedDetails'
  readonly detailType: SeekerNotificationDetailTypeEnum
  /** @deprecated Use field vacancySnapshot instead */
  readonly vacancy: Maybe<Vacancy>
  readonly vacancySnapshot: NotificationCommonVacancySnapshot
}

export interface VacancyPublicationRejectedDetails {
  readonly __typename?: 'VacancyPublicationRejectedDetails'
  readonly detailType: SeekerNotificationDetailTypeEnum
  readonly vacancySnapshot: NotificationCommonVacancySnapshot
}

export enum VacancyPublicationType {
  Anonymous = 'ANONYMOUS',
  Business = 'BUSINESS',
  Optimum = 'OPTIMUM',
  Professional = 'PROFESSIONAL',
  Test = 'TEST'
}

export interface VacancyPublicationTypeWrapper {
  readonly __typename?: 'VacancyPublicationTypeWrapper'
  readonly id: Scalars['ID']
  readonly type: VacancyPublicationType
}

export interface VacancyPublishInput {
  readonly publicationType?: InputMaybe<VacancyPublicationType>
  readonly type?: InputMaybe<VacancyType>
  readonly vacancies?: InputMaybe<ReadonlyArray<InputMaybe<MultipleVacanciesActionItem>>>
  readonly vacancyId: Scalars['ID']
}

export interface VacancyPublishOutput {
  readonly __typename?: 'VacancyPublishOutput'
  readonly errors: ReadonlyArray<ModifyVacancyErrorUnion>
  readonly vacancies: Maybe<ReadonlyArray<Maybe<Vacancy>>>
  readonly vacancy: Maybe<Vacancy>
}

export interface VacancyPublishPeriod {
  readonly __typename?: 'VacancyPublishPeriod'
  readonly autoProlongEnd: Maybe<Scalars['DateTime']>
  readonly begin: Scalars['DateTime']
  readonly daysUntilEnd: Maybe<Scalars['Int']>
  readonly end: Scalars['DateTime']
  readonly nextAutoProlongDate: Maybe<Scalars['DateTime']>
}

export interface VacancyPublishedEmployerAchievement extends EmployerAchievement {
  readonly __typename?: 'VacancyPublishedEmployerAchievement'
  readonly currentCount: Scalars['Int']
  readonly isDone: Scalars['Boolean']
  readonly targetCount: Scalars['Int']
  readonly type: EmployerAchievementType
}

export interface VacancyPublishedWithFilledAddressAndSalaryEmployerAchievement extends EmployerAchievement {
  readonly __typename?: 'VacancyPublishedWithFilledAddressAndSalaryEmployerAchievement'
  readonly isDone: Scalars['Boolean']
  readonly type: EmployerAchievementType
}

export interface VacancyRejectPublicationInput {
  readonly reason: Scalars['String']
  readonly vacancyId: Scalars['ID']
}

export interface VacancyRejectPublicationOutput {
  readonly __typename?: 'VacancyRejectPublicationOutput'
  readonly vacancy: Maybe<Vacancy>
}

export interface VacancyReportInput {
  readonly description: Scalars['String']
  readonly reporter?: InputMaybe<FeedbackReporterInput>
  readonly vacancyId: Scalars['ID']
}

export interface VacancyReportOutput {
  readonly __typename?: 'VacancyReportOutput'
  readonly isSuccessful: Scalars['Boolean']
  readonly key: Maybe<Scalars['ID']>
}

export interface VacancyRepublishInput {
  readonly publicationType: VacancyPublicationType
  readonly vacancies?: InputMaybe<ReadonlyArray<InputMaybe<MultipleVacanciesActionItem>>>
  readonly vacancyId: Scalars['ID']
}

export interface VacancyRepublishOutput {
  readonly __typename?: 'VacancyRepublishOutput'
  readonly errors: ReadonlyArray<ModifyVacancyErrorUnion>
  readonly vacancies: Maybe<ReadonlyArray<Maybe<Vacancy>>>
  readonly vacancy: Maybe<Vacancy>
}

export interface VacancyResumeInput {
  readonly vacanciesIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  readonly vacancyId: Scalars['ID']
}

export interface VacancyResumeOutput {
  readonly __typename?: 'VacancyResumeOutput'
  readonly vacancies: Maybe<ReadonlyArray<Maybe<Vacancy>>>
  readonly vacancy: Maybe<Vacancy>
}

export interface VacancyRisingUpInput {
  readonly vacancyId: Scalars['ID']
}

export interface VacancySalary {
  readonly __typename?: 'VacancySalary'
  readonly amount: Maybe<Scalars['Int']>
  readonly amountFrom: Maybe<Scalars['Int']>
  readonly amountTo: Maybe<Scalars['Int']>
  readonly comment: Maybe<Scalars['String']>
  readonly currency: Maybe<Currency>
}

export interface VacancySalaryInput {
  readonly amount?: InputMaybe<Scalars['Int']>
  readonly amountFrom?: InputMaybe<Scalars['Int']>
  readonly amountTo?: InputMaybe<Scalars['Int']>
  readonly comment?: InputMaybe<Scalars['String']>
}

export interface VacancySearchSuggest {
  readonly __typename?: 'VacancySearchSuggest'
  readonly id: Maybe<Scalars['ID']>
  readonly logo: Maybe<Scalars['String']>
  readonly name: Maybe<Scalars['String']>
  readonly searchCount: Maybe<Scalars['Int']>
  readonly synonymousId: Maybe<Scalars['String']>
  readonly vacancyCount: Maybe<Scalars['Int']>
}

export interface VacancySearchSuggestList {
  readonly __typename?: 'VacancySearchSuggestList'
  readonly suggests: ReadonlyArray<VacancySearchSuggest>
  readonly type: VacancySearchSuggestTypeEnum
}

export enum VacancySearchSuggestTypeEnum {
  Company = 'COMPANY',
  Profession = 'PROFESSION',
  Skill = 'SKILL'
}

export interface VacancySearchSuggests {
  readonly __typename?: 'VacancySearchSuggests'
  readonly type: Maybe<SuggestType>
  readonly typedSuggests: Maybe<ReadonlyArray<Maybe<VacancySearchSuggest>>>
}

export interface VacancySearchSuggestsQueries {
  readonly __typename?: 'VacancySearchSuggestsQueries'
  readonly getVacancySearchSuggests: GetVacancySearchSuggestsOutput
}

export interface VacancySearchSuggestsQueriesGetVacancySearchSuggestsArgs {
  input: InputMaybe<GetVacancySearchSuggestsInput>
}

export interface VacancyServiceActivationBehavior {
  readonly __typename?: 'VacancyServiceActivationBehavior'
  readonly catalogService: Maybe<CatalogUnitedServices>
  readonly isActivated: Scalars['Boolean']
  readonly serviceId: Maybe<Scalars['Int']>
}

export interface VacancyServiceActivationBehaviorInput {
  readonly isActivated: Scalars['Boolean']
  readonly serviceId?: InputMaybe<Scalars['Int']>
}

export interface VacancyStatisticsItem {
  readonly __typename?: 'VacancyStatisticsItem'
  readonly timeseries: Maybe<ReadonlyArray<Maybe<VacancyTimeseriesItem>>>
  readonly total: Maybe<Scalars['Int']>
}

export interface VacancyStatisticsTimeseries {
  readonly __typename?: 'VacancyStatisticsTimeseries'
  readonly applies: Maybe<VacancyStatisticsItem>
  readonly keyEvents: Maybe<ReadonlyArray<Maybe<VacancyEventStatisticItem>>>
  readonly openedContacts: Maybe<VacancyStatisticsItem>
  readonly shows: Maybe<VacancyStatisticsItem>
  readonly views: Maybe<VacancyStatisticsItem>
}

export enum VacancyStatus {
  Banned = 'BANNED',
  Closed = 'CLOSED',
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  RejectedByAdmin = 'REJECTED_BY_ADMIN',
  RejectedByModerator = 'REJECTED_BY_MODERATOR',
  Suspended = 'SUSPENDED',
  WaitingForAdminApproval = 'WAITING_FOR_ADMIN_APPROVAL',
  WaitingForModeratorApproval = 'WAITING_FOR_MODERATOR_APPROVAL'
}

export interface VacancyStatusCount {
  readonly __typename?: 'VacancyStatusCount'
  readonly count: Scalars['Int']
  readonly status: VacancyStatus
}

export interface VacancySuspendInput {
  readonly vacanciesIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>
  readonly vacancyId: Scalars['ID']
}

export interface VacancySuspendOutput {
  readonly __typename?: 'VacancySuspendOutput'
  readonly vacancies: Maybe<ReadonlyArray<Maybe<Vacancy>>>
  readonly vacancy: Maybe<Vacancy>
}

export interface VacancyTimeseriesItem {
  readonly __typename?: 'VacancyTimeseriesItem'
  readonly count: Maybe<Scalars['Int']>
  readonly date: Maybe<Scalars['Date']>
}

export type VacancyToAtsProjectExportError = AtsProjectCreationError | InvalidError | NotAvailableError | ServerError

export interface VacancyToAtsProjectExportInput {
  readonly vacancyId: Scalars['ID']
}

export interface VacancyToAtsProjectExportOutput {
  readonly __typename?: 'VacancyToAtsProjectExportOutput'
  readonly error: Maybe<VacancyToAtsProjectExportError>
  readonly result: Maybe<VacancyToAtsProjectExportResult>
}

export interface VacancyToAtsProjectExportResult {
  readonly __typename?: 'VacancyToAtsProjectExportResult'
  readonly candidatesUrl: Scalars['String']
  readonly id: Scalars['ID']
}

export enum VacancyType {
  Anonymous = 'ANONYMOUS',
  Business = 'BUSINESS',
  Optimum = 'OPTIMUM',
  Professional = 'PROFESSIONAL',
  Test = 'TEST'
}

export interface VacancyUpdateInput {
  readonly activateCvMailing?: InputMaybe<Scalars['Boolean']>
  readonly additionalCandidatePermissions?: InputMaybe<VacancyAdditionalCandidatePermissionsInput>
  readonly address?: InputMaybe<VacancyAddressInput>
  readonly applicationsDestination?: InputMaybe<VacancyApplicationsDestinationInput>
  readonly benefits?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>
  readonly branchId?: InputMaybe<Scalars['ID']>
  readonly candidatesScreeningOptions?: InputMaybe<VacancyCandidatesScreeningOptionsInput>
  readonly chatStatus?: InputMaybe<VacancyChatStatusEnum>
  readonly cityId: Scalars['ID']
  readonly closingBehavior?: InputMaybe<VacancyClosingBehavior>
  readonly code?: InputMaybe<Scalars['String']>
  readonly contacts?: InputMaybe<VacancyContactsInput>
  readonly description?: InputMaybe<Scalars['String']>
  readonly designId?: InputMaybe<Scalars['Int']>
  readonly doesSupportApplicationWithoutResume?: InputMaybe<Scalars['Boolean']>
  readonly employmentTypes?: InputMaybe<ReadonlyArray<InputMaybe<VacancyEmploymentType>>>
  readonly experienceQuestions?: InputMaybe<ReadonlyArray<InputMaybe<VacancyExperienceQuestionInput>>>
  readonly hideFromCompanyProfile?: InputMaybe<Scalars['Boolean']>
  readonly id: Scalars['ID']
  readonly isPublicationInAllCities?: InputMaybe<Scalars['Boolean']>
  readonly languageQuestions?: InputMaybe<ReadonlyArray<InputMaybe<VacancyLanguageQuestionInput>>>
  readonly languageSkillsRequired?: InputMaybe<Scalars['Boolean']>
  readonly media?: InputMaybe<ReadonlyArray<InputMaybe<VacancyMediaInput>>>
  readonly publicationServiceId?: InputMaybe<Scalars['Int']>
  readonly publicationType?: InputMaybe<VacancyPublicationType>
  readonly salary?: InputMaybe<VacancySalaryInput>
  readonly status?: InputMaybe<VacancyStatus>
  readonly supportApplicationWithoutResume?: InputMaybe<Scalars['Boolean']>
  readonly title: Scalars['String']
  readonly workTypes?: InputMaybe<ReadonlyArray<InputMaybe<VacancyWorkType>>>
}

export interface VacancyUpdateOutput {
  readonly __typename?: 'VacancyUpdateOutput'
  readonly error: Maybe<ModifyVacancyErrorUnion>
  readonly vacancy: Maybe<Vacancy>
}

export enum VacancyWorkType {
  Hybrid = 'HYBRID',
  Office = 'OFFICE',
  Remote = 'REMOTE'
}

export type ViewNotificationsError = NotificationNotFoundError | UnknownServerError

export interface ViewNotificationsInput {
  readonly notificationIds: ReadonlyArray<Scalars['ID']>
}

export interface ViewNotificationsOutput {
  readonly __typename?: 'ViewNotificationsOutput'
  readonly errors: Maybe<ReadonlyArray<ViewNotificationsError>>
  readonly notifications: Maybe<ReadonlyArray<Notification>>
}

export interface Wallet {
  readonly __typename?: 'Wallet'
  readonly balance: WalletBalance
  readonly createDate: Scalars['DateTime']
  readonly createdBy: WalletUserUnion
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly transactions: Maybe<TransactionsConnection>
  readonly users: ReadonlyArray<WalletUserUnion>
}

export interface WalletTransactionsArgs {
  after: InputMaybe<Scalars['String']>
  filter: InputMaybe<WalletTransactionsFilterInput>
  first: InputMaybe<Scalars['Int']>
  sortType: InputMaybe<WalletTransactionsSortTypeEnum>
}

export interface WalletBalance {
  readonly __typename?: 'WalletBalance'
  readonly burnAmount: Scalars['Float']
  readonly holdAmount: Scalars['Float']
  readonly totalAmount: Scalars['Float']
}

export interface WalletCartMutation {
  readonly __typename?: 'WalletCartMutation'
  readonly apply: ApplyWalletBonusResponse
  readonly drop: DropAppliedWalletBonusResponse
}

export interface WalletCartMutationApplyArgs {
  amount: Scalars['Float']
}

export interface WalletDoesNotExistError extends Error {
  readonly __typename?: 'WalletDoesNotExistError'
  readonly reason: Scalars['String']
}

export interface WalletExistsError extends Error {
  readonly __typename?: 'WalletExistsError'
  readonly reason: Scalars['String']
}

export interface WalletTransaction {
  readonly __typename?: 'WalletTransaction'
  readonly amount: Scalars['Float']
  readonly comment: Maybe<Scalars['String']>
  readonly createDate: Scalars['DateTime']
  readonly expirationDate: Maybe<Scalars['DateTime']>
  readonly id: Scalars['ID']
  readonly initiator: WalletTransactionInitiatorUnion
  readonly orderId: Scalars['ID']
  readonly reason: Maybe<TransactionReasonEnum>
  readonly reasonDescription: Maybe<Scalars['String']>
  readonly status: TransactionStatusEnum
  readonly type: TransactionTypeEnum
}

export interface WalletTransactionEvent {
  readonly __typename?: 'WalletTransactionEvent'
  readonly addedAt: Scalars['DateTime']
  readonly amount: Scalars['Float']
  readonly expirationDate: Maybe<Scalars['String']>
  readonly id: Scalars['ID']
  readonly initiator: Scalars['String']
  readonly order: Maybe<Order>
  readonly performer: PerformerEmployerEventUnion
  readonly reason: Maybe<Scalars['String']>
  readonly reasonDescriptionId: Maybe<Scalars['String']>
  readonly transactionId: Scalars['ID']
  readonly type: Scalars['String']
}

export type WalletTransactionInitiatorUnion = EmployerWalletUser | ManagerWalletUser | SeekerWalletUser | SystemWalletUser

export interface WalletTransactionInput {
  readonly amount: Scalars['Float']
  readonly comment?: InputMaybe<Scalars['String']>
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>
  readonly orderId?: InputMaybe<Scalars['ID']>
  readonly reason?: InputMaybe<TransactionReasonEnum>
  readonly reasonDescriptionId?: InputMaybe<Scalars['ID']>
  readonly transactionType: TransactionTypeEnum
  readonly walletId: Scalars['ID']
}

export interface WalletTransactionsFilterInput {
  readonly expirationDateRange?: InputMaybe<DateRangeInput>
  readonly reasons?: InputMaybe<ReadonlyArray<TransactionReasonEnum>>
  readonly statuses?: InputMaybe<ReadonlyArray<TransactionStatusEnum>>
  readonly types?: InputMaybe<ReadonlyArray<TransactionTypeEnum>>
}

export enum WalletTransactionsSortTypeEnum {
  CreateDateAsc = 'CREATE_DATE_ASC',
  CreateDateDesc = 'CREATE_DATE_DESC',
  ExpirationDateAsc = 'EXPIRATION_DATE_ASC',
  ExpirationDateDesc = 'EXPIRATION_DATE_DESC'
}

export type WalletUserUnion = EmployerWalletUser | ManagerWalletUser | SeekerWalletUser | SystemWalletUser

/** A connection to a list of items. */
export interface WalletsConnection {
  readonly __typename?: 'WalletsConnection'
  /** A list of edges. */
  readonly edges: Maybe<ReadonlyArray<WalletsEdge>>
  /** A flattened list of the nodes. */
  readonly nodes: Maybe<ReadonlyArray<Wallet>>
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo
  /** Identifies the total count of items in the connection. */
  readonly totalCount: Scalars['Int']
}

/** An edge in a connection. */
export interface WalletsEdge {
  readonly __typename?: 'WalletsEdge'
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String']
  /** The item at the end of the edge. */
  readonly node: Wallet
}

export interface WarehouseGift {
  readonly __typename?: 'WarehouseGift'
  readonly addDate: Scalars['DateTime']
  readonly cost: Scalars['Decimal']
  readonly giftType: GiftTypeEnum
  readonly id: Scalars['ID']
  readonly name: Scalars['String']
  readonly ranges: ReadonlyArray<WarehouseRange>
  readonly standCount: Scalars['Int']
  readonly storageCount: Scalars['Int']
  readonly weight: Scalars['Int']
}

export interface WarehouseLimitedGift {
  readonly __typename?: 'WarehouseLimitedGift'
  readonly addDate: Scalars['DateTime']
  readonly cost: Scalars['Decimal']
  readonly id: Scalars['ID']
  readonly limitCountTransfered: Maybe<Scalars['Int']>
  readonly name: Scalars['String']
  readonly occasion: GiftOccasion
  readonly occasionStartDate: Maybe<Scalars['DateTime']>
  readonly standCount: Scalars['Int']
  readonly wave: Maybe<GiftOccasion>
  readonly weight: Scalars['Int']
}

export interface WarehouseRange {
  readonly __typename?: 'WarehouseRange'
  readonly id: Scalars['ID']
  readonly isInRange: Scalars['Boolean']
  readonly name: Scalars['String']
}

export interface WidgetPaymentMethod {
  readonly __typename?: 'WidgetPaymentMethod'
  readonly generate: WidgetPaymentMethodOutput
}

export interface WidgetPaymentMethodOutput {
  readonly __typename?: 'WidgetPaymentMethodOutput'
  readonly data: Scalars['String']
  readonly paymentSystem: PaymentSystemTypeEnum
}

export interface AppliesByUrl {
  readonly __typename?: 'appliesByUrl'
  readonly timeseries: Maybe<ReadonlyArray<Maybe<Timeseries>>>
  readonly total: Maybe<Scalars['Int']>
}

export interface ClickOnLinks {
  readonly __typename?: 'clickOnLinks'
  readonly timeseries: Maybe<ReadonlyArray<Maybe<Timeseries>>>
  readonly total: Maybe<Scalars['Int']>
}

export enum CrmActionState {
  Cancelled = 'CANCELLED',
  Done = 'DONE',
  None = 'NONE',
  Overdue = 'OVERDUE',
  Planned = 'PLANNED'
}

export interface NovaPoshtaAddCounterpartyInput {
  readonly additionalPhone?: InputMaybe<Scalars['String']>
  readonly counterpartyType: NovaPosthaCounterpartyTypesGraphEnum
  readonly edrpou?: InputMaybe<Scalars['String']>
  readonly email?: InputMaybe<Scalars['String']>
  readonly firstName?: InputMaybe<Scalars['String']>
  readonly info?: InputMaybe<Scalars['String']>
  readonly lastName?: InputMaybe<Scalars['String']>
  readonly middleName?: InputMaybe<Scalars['String']>
  readonly phone?: InputMaybe<Scalars['String']>
}

export interface NovaPoshtaAddGiftAddressInput {
  readonly addressRef: Scalars['String']
  readonly addressType: NovaPoshtaAddressTypeEnum
  readonly buildingNumber?: InputMaybe<Scalars['String']>
  readonly counterpartyRef: Scalars['String']
  readonly firstName: Scalars['String']
  readonly flat?: InputMaybe<Scalars['String']>
  readonly lastName: Scalars['String']
  readonly note?: InputMaybe<Scalars['String']>
  readonly phone: Scalars['String']
  readonly settlementRef: Scalars['String']
}

export interface ServiceAvailableContacts {
  readonly __typename?: 'serviceAvailableContacts'
  readonly contactsAccrualPeriod: Maybe<ServiceContactsAccrualPeriod>
  readonly hasServicesWithLimitedDailyContacts: Scalars['Boolean']
  readonly hasServicesWithLimitedMonthlyContacts: Scalars['Boolean']
  readonly hasServicesWithUnlimitedContacts: Scalars['Boolean']
  readonly leftLimitedContactsCount: Maybe<Scalars['Int']>
  readonly limitedDailyContactsCount: Maybe<Scalars['Int']>
}

export interface Timeseries {
  readonly __typename?: 'timeseries'
  readonly count: Maybe<Scalars['Int']>
  readonly date: Maybe<Scalars['Date']>
}

export type ResolverTypeWrapper<T> = Promise<T> | T

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>
}
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>

export type ResolverFn<TResult, TParent, TContext, TArgs> = (parent: TParent, args: TArgs, context: TContext, info: GraphQLResolveInfo) => Promise<TResult> | TResult

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (parent: TParent, args: TArgs, context: TContext, info: GraphQLResolveInfo) => TResult | Promise<TResult>

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (parent: TParent, context: TContext, info: GraphQLResolveInfo) => Maybe<TTypes> | Promise<Maybe<TTypes>>

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>

export type NextResolverFn<T> = () => Promise<T>

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AccountDateChanges: ResolverTypeWrapper<AccountDateChanges>
  AcquaintWithNotificationsError: ResolversTypes['UnknownServerError']
  AcquaintWithNotificationsOutput: ResolverTypeWrapper<Omit<AcquaintWithNotificationsOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['AcquaintWithNotificationsError']>> }>
  ActionAttach: ResolverTypeWrapper<ActionAttach>
  ActionCounter: ResolverTypeWrapper<ActionCounter>
  ActionFilters: ResolverTypeWrapper<ActionFilters>
  ActionStateCounter: ResolverTypeWrapper<ActionStateCounter>
  ActionTeamCounter: ResolverTypeWrapper<ActionTeamCounter>
  ActivatedAdditionalService: ResolverTypeWrapper<ActivatedAdditionalService>
  ActivatedCvDbService: ResolverTypeWrapper<ActivatedCvDbService>
  ActivatedHotService: ResolverTypeWrapper<ActivatedHotService>
  ActivatedLogoInPortalService: ResolverTypeWrapper<ActivatedLogoInPortalService>
  ActivatedLogoInRubricService: ResolverTypeWrapper<ActivatedLogoInRubricService>
  ActivatedOrderByTurbotaServiceEmployerEvent: ResolverTypeWrapper<Omit<ActivatedOrderByTurbotaServiceEmployerEvent, 'performer'> & { performer: ResolversTypes['PerformerEmployerEventUnion'] }>
  ActivatedRisingUpService: ResolverTypeWrapper<ActivatedRisingUpService>
  ActivatedServiceEmployerEvent: ResolverTypeWrapper<
    Omit<ActivatedServiceEmployerEvent, 'performer' | 'service'> & { performer: ResolversTypes['PerformerEmployerEventUnion']; service: ResolversTypes['ServiceEmployerEventSnapshotUnion'] }
  >
  ActivatedVacancyPackageService: ResolverTypeWrapper<Omit<ActivatedVacancyPackageService, 'additionalServices'> & { additionalServices: ReadonlyArray<Maybe<ResolversTypes['ServiceUnited']>> }>
  ActivatedVacancyPublicationService: ResolverTypeWrapper<ActivatedVacancyPublicationService>
  AdamLabel: ResolverTypeWrapper<AdamLabel>
  AddAdamLabelInput: AddAdamLabelInput
  AddClubPostInput: AddClubPostInput
  AddClubPostOutput: ResolverTypeWrapper<AddClubPostOutput>
  AddContactInput: AddContactInput
  AddGamificationLimitInput: AddGamificationLimitInput
  AddGamificationLimitsOutput: ResolverTypeWrapper<AddGamificationLimitsOutput>
  AddGiftDetailInput: AddGiftDetailInput
  AddGiftInput: AddGiftInput
  AddGiftOccasionInput: AddGiftOccasionInput
  AddGiftRequestDeliveryOptionsInput: AddGiftRequestDeliveryOptionsInput
  AddGiftRequestOutput: ResolverTypeWrapper<AddGiftRequestOutput>
  AddGiftWaveInput: AddGiftWaveInput
  AddJobAlertSubscriptionInput: AddJobAlertSubscriptionInput
  AddManagerAlarmInput: AddManagerAlarmInput
  AddMarketingGiftRequestInput: AddMarketingGiftRequestInput
  AddOtherSiteLinkInput: AddOtherSiteLinkInput
  AddOtherSiteLinkOutput: ResolverTypeWrapper<AddOtherSiteLinkOutput>
  AddPromoCodeResponse: ResolverTypeWrapper<AddPromoCodeResponse>
  AddPublicPromoCodeErrorEnum: AddPublicPromoCodeErrorEnum
  AddRegularGiftInput: AddRegularGiftInput
  AddSeekerFavoriteVacanciesInput: AddSeekerFavoriteVacanciesInput
  AddSeekerFavoriteVacanciesOutput: ResolverTypeWrapper<AddSeekerFavoriteVacanciesOutput>
  AddSeekerProfResumeAdditionalEducationsError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  AddSeekerProfResumeAdditionalInfoError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  AddSeekerProfResumeEducationsError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  AddSeekerProfResumeExperiencesError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  AddSeekerSubscriptionMutations: ResolverTypeWrapper<AddSeekerSubscriptionMutations>
  AddedNewUserManagementEmployerEvent: ResolverTypeWrapper<Omit<AddedNewUserManagementEmployerEvent, 'performer'> & { performer: ResolversTypes['PerformerEmployerEventUnion'] }>
  AddedProfResumeAdditionalEducationsOutput: ResolverTypeWrapper<
    Omit<AddedProfResumeAdditionalEducationsOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['AddSeekerProfResumeAdditionalEducationsError']>> }
  >
  AddedProfResumeAdditionalInfoOutput: ResolverTypeWrapper<
    Omit<AddedProfResumeAdditionalInfoOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['AddSeekerProfResumeAdditionalInfoError']>> }
  >
  AddedProfResumeEducationsOutput: ResolverTypeWrapper<Omit<AddedProfResumeEducationsOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['AddSeekerProfResumeEducationsError']>> }>
  AddedProfResumeExperiencesOutput: ResolverTypeWrapper<Omit<AddedProfResumeExperiencesOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['AddSeekerProfResumeExperiencesError']>> }>
  AdditionalCatalogService: ResolverTypeWrapper<AdditionalCatalogService>
  AdditionalServiceEmployerEventSnapshot: ResolverTypeWrapper<AdditionalServiceEmployerEventSnapshot>
  AdvertisingBanner: ResolverTypeWrapper<Omit<AdvertisingBanner, 'dynamicElement'> & { dynamicElement: Maybe<ResolversTypes['UnionBannerDynamicElement']> }>
  AllowedVacancyActions: AllowedVacancyActions
  AlreadyReviewedVacancyProlongationError: ResolverTypeWrapper<AlreadyReviewedVacancyProlongationError>
  AnotherSourceVacancyOutput: ResolverTypeWrapper<AnotherSourceVacancyOutput>
  AnswerOption: ResolverTypeWrapper<AnswerOption>
  AnswerOptionInput: AnswerOptionInput
  AnswerOptionSuggest: ResolverTypeWrapper<AnswerOptionSuggest>
  ApplicableAnswerTypeEnum: ApplicableAnswerTypeEnum
  ApplicableAnswerTypeSuggestEnum: ApplicableAnswerTypeSuggestEnum
  ApplicationInvitedNotificationDetails: ResolverTypeWrapper<ApplicationInvitedNotificationDetails>
  ApplicationRefusedNotificationDetails: ResolverTypeWrapper<ApplicationRefusedNotificationDetails>
  ApplicationResumeFilling: ResolverTypeWrapper<ApplicationResumeFilling>
  ApplicationSentNotificationDetails: ResolverTypeWrapper<ApplicationSentNotificationDetails>
  ApplicationViewedNotificationDetails: ResolverTypeWrapper<ApplicationViewedNotificationDetails>
  ApplicationsDestinationType: ApplicationsDestinationType
  ApplicationsViewedEmployerAchievement: ResolverTypeWrapper<ApplicationsViewedEmployerAchievement>
  Apply: ResolverTypeWrapper<Apply>
  ApplyAddCompanyAddressInput: ApplyAddCompanyAddressInput
  ApplyAddCompanyAddressOutput: ResolverTypeWrapper<ApplyAddCompanyAddressOutput>
  ApplyAddNoteInput: ApplyAddNoteInput
  ApplyAddNoteOutput: ResolverTypeWrapper<ApplyAddNoteOutput>
  ApplyAggregation: ResolverTypeWrapper<ApplyAggregation>
  ApplyAtsConnectionsFilterInput: ApplyAtsConnectionsFilterInput
  ApplyAtsProject: ResolverTypeWrapper<ApplyAtsProject>
  ApplyAtsProjectEdge: ResolverTypeWrapper<ApplyAtsProjectEdge>
  ApplyAttachParsedFileContent: ResolverTypeWrapper<ApplyAttachParsedFileContent>
  ApplyAttachResume: ResolverTypeWrapper<ApplyAttachResume>
  ApplyChangeStatusInput: ApplyChangeStatusInput
  ApplyChangeStatusOutput: ResolverTypeWrapper<ApplyChangeStatusOutput>
  ApplyChangeStatusesInput: ApplyChangeStatusesInput
  ApplyChangeStatusesOutput: ResolverTypeWrapper<ApplyChangeStatusesOutput>
  ApplyCompanyAddress: ResolverTypeWrapper<ApplyCompanyAddress>
  ApplyConnection: ResolverTypeWrapper<ApplyConnection>
  ApplyContactLink: ResolverTypeWrapper<ApplyContactLink>
  ApplyContactLinkType: ApplyContactLinkType
  ApplyCountFilterInput: ApplyCountFilterInput
  ApplyCoveringLetter: ResolverTypeWrapper<ApplyCoveringLetter>
  ApplyDeleteCompanyAddressInput: ApplyDeleteCompanyAddressInput
  ApplyDeleteCompanyAddressOutput: ResolverTypeWrapper<ApplyDeleteCompanyAddressOutput>
  ApplyDeleteNoteInput: ApplyDeleteNoteInput
  ApplyDeleteNoteOutput: ResolverTypeWrapper<ApplyDeleteNoteOutput>
  ApplyEdge: ResolverTypeWrapper<ApplyEdge>
  ApplyEditNoteInput: ApplyEditNoteInput
  ApplyEditNoteOutput: ResolverTypeWrapper<ApplyEditNoteOutput>
  ApplyEmail: ResolverTypeWrapper<ApplyEmail>
  ApplyEmployer: ResolverTypeWrapper<ApplyEmployer>
  ApplyEmptyResume: ResolverTypeWrapper<ApplyEmptyResume>
  ApplyFacetUnion: ResolversTypes['ApplyStatus'] | ResolversTypes['CandidateInteractionWrapper'] | ResolversTypes['CandidateSourceType'] | ResolversTypes['ResumeFillingType']
  ApplyFilterInput: ApplyFilterInput
  ApplyInvitationDetails: ResolverTypeWrapper<ApplyInvitationDetails>
  ApplyInviteInput: ApplyInviteInput
  ApplyInviteOutput: ResolverTypeWrapper<ApplyInviteOutput>
  ApplyJobSeeker: ResolverTypeWrapper<ApplyJobSeeker>
  ApplyMassRefuseInput: ApplyMassRefuseInput
  ApplyNote: ResolverTypeWrapper<ApplyNote>
  ApplyPhone: ResolverTypeWrapper<ApplyPhone>
  ApplyPolicy: ApplyPolicy
  ApplyProfResume: ResolverTypeWrapper<ApplyProfResume>
  ApplyProfResumeFullName: ResolverTypeWrapper<ApplyProfResumeFullName>
  ApplyProjectAtsConnection: ResolverTypeWrapper<ApplyProjectAtsConnection>
  ApplyRefusalDetails: ResolverTypeWrapper<ApplyRefusalDetails>
  ApplyRefuseInput: ApplyRefuseInput
  ApplyRefuseOutput: ResolverTypeWrapper<ApplyRefuseOutput>
  ApplyResumeAdditional: ResolverTypeWrapper<ApplyResumeAdditional>
  ApplyResumeAdditionalEducation: ResolverTypeWrapper<ApplyResumeAdditionalEducation>
  ApplyResumeContacts: ResolverTypeWrapper<ApplyResumeContacts>
  ApplyResumeDesiredPosition: ResolverTypeWrapper<ApplyResumeDesiredPosition>
  ApplyResumeExperience: ResolverTypeWrapper<ApplyResumeExperience>
  ApplyResumeFillingTypeCount: ResolverTypeWrapper<ApplyResumeFillingTypeCount>
  ApplyResumeFillingTypeCountFilterInput: ApplyResumeFillingTypeCountFilterInput
  ApplyResumeGender: ApplyResumeGender
  ApplyResumeGeneralEducation: ResolverTypeWrapper<ApplyResumeGeneralEducation>
  ApplyResumeInterface: ResolversTypes['ApplyAttachResume'] | ResolversTypes['ApplyEmptyResume'] | ResolversTypes['ApplyProfResume']
  ApplyResumeLanguageSkill: ResolverTypeWrapper<ApplyResumeLanguageSkill>
  ApplyResumeRecommendation: ResolverTypeWrapper<ApplyResumeRecommendation>
  ApplySalary: ResolverTypeWrapper<ApplySalary>
  ApplySortType: ApplySortType
  ApplyStatus: ResolverTypeWrapper<ApplyStatus>
  ApplyStatusCount: ResolverTypeWrapper<ApplyStatusCount>
  ApplyStatusCountFilterInput: ApplyStatusCountFilterInput
  ApplySurverQuestionImportance: ResolverTypeWrapper<ApplySurverQuestionImportance>
  ApplySurveyAnswer: ResolverTypeWrapper<ApplySurveyAnswer>
  ApplySurveyResult: ResolverTypeWrapper<ApplySurveyResult>
  ApplyToAtsExportError: ResolversTypes['InvalidError'] | ResolversTypes['NotAvailableError'] | ResolversTypes['ServerError']
  ApplyToAtsExportInput: ApplyToAtsExportInput
  ApplyToAtsExportOutput: ResolverTypeWrapper<Omit<ApplyToAtsExportOutput, 'error'> & { error: Maybe<ResolversTypes['ApplyToAtsExportError']> }>
  ApplyToAtsExportResult: ResolverTypeWrapper<ApplyToAtsExportResult>
  ApplyUpdateCompanyAddressInput: ApplyUpdateCompanyAddressInput
  ApplyUpdateCompanyAddressOutput: ResolverTypeWrapper<ApplyUpdateCompanyAddressOutput>
  ApplyVacancyKeyword: ResolverTypeWrapper<ApplyVacancyKeyword>
  ApplyWalletBonusCodeErrorEnum: ApplyWalletBonusCodeErrorEnum
  ApplyWalletBonusResponse: ResolverTypeWrapper<ApplyWalletBonusResponse>
  ApprovedProlongationRequestVacancyEmployerEvent: ResolverTypeWrapper<
    Omit<ApprovedProlongationRequestVacancyEmployerEvent, 'performer'> & { performer: ResolversTypes['PerformerEmployerEventUnion'] }
  >
  ApprovedReviewVacancyEmployerEvent: ResolverTypeWrapper<Omit<ApprovedReviewVacancyEmployerEvent, 'performer'> & { performer: ResolversTypes['PerformerEmployerEventUnion'] }>
  Assignment: ResolverTypeWrapper<Assignment>
  AssignmentCompleteInput: AssignmentCompleteInput
  AssignmentConnection: ResolverTypeWrapper<AssignmentConnection>
  AssignmentCreateInput: AssignmentCreateInput
  AssignmentEdge: ResolverTypeWrapper<AssignmentEdge>
  AssignmentOperationOutput: ResolverTypeWrapper<AssignmentOperationOutput>
  AssignmentUpdateInput: AssignmentUpdateInput
  AtsProjectCreationError: ResolverTypeWrapper<AtsProjectCreationError>
  AttachNotFound: ResolverTypeWrapper<AttachNotFound>
  AttachParsingError: ResolverTypeWrapper<AttachParsingError>
  AutoProlongationDisabledVacancyEmployerEvent: ResolverTypeWrapper<Omit<AutoProlongationDisabledVacancyEmployerEvent, 'performer'> & { performer: ResolversTypes['PerformerEmployerEventUnion'] }>
  AutoProlongationEnabledVacancyEmployerEvent: ResolverTypeWrapper<Omit<AutoProlongationEnabledVacancyEmployerEvent, 'performer'> & { performer: ResolversTypes['PerformerEmployerEventUnion'] }>
  AutoProlongationVacancyDurationEmployerEventEnum: AutoProlongationVacancyDurationEmployerEventEnum
  AutoProlongedVacancyEmployerEvent: ResolverTypeWrapper<AutoProlongedVacancyEmployerEvent>
  BanInfo: ResolverTypeWrapper<BanInfo>
  BanReasonInfo: ResolverTypeWrapper<BanReasonInfo>
  Banner: ResolversTypes['AdvertisingBanner'] | ResolversTypes['InformationBanner']
  BannerButton: ResolverTypeWrapper<BannerButton>
  BannerButtonLinkTypeEnum: BannerButtonLinkTypeEnum
  BannerButtonTypeEnum: BannerButtonTypeEnum
  BannerClock: ResolverTypeWrapper<BannerClock>
  BannerDisplayPagesEnum: BannerDisplayPagesEnum
  BannerDynamicElement: ResolversTypes['BannerClock'] | ResolversTypes['BannerProgressBar']
  BannerDynamicElementTypeEnum: BannerDynamicElementTypeEnum
  BannerImageElement: ResolverTypeWrapper<BannerImageElement>
  BannerImageSize: ResolverTypeWrapper<BannerImageSize>
  BannerImageSizeEnum: BannerImageSizeEnum
  BannerProgressBar: ResolverTypeWrapper<BannerProgressBar>
  BannerQueries: ResolverTypeWrapper<BannerQueries>
  BannerTextElement: ResolverTypeWrapper<BannerTextElement>
  BannerTypeEnum: BannerTypeEnum
  BasicQuestion: ResolversTypes['ExperienceBasicQuestion'] | ResolversTypes['LanguageBasicQuestion']
  BasicQuestionItem: ResolverTypeWrapper<BasicQuestionItem>
  BasicQuestionTypeEnum: BasicQuestionTypeEnum
  BillFilters: ResolverTypeWrapper<BillFilters>
  BlockedUserManagementEmployerEvent: ResolverTypeWrapper<Omit<BlockedUserManagementEmployerEvent, 'performer'> & { performer: ResolversTypes['PerformerEmployerEventUnion'] }>
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>
  Branch: ResolverTypeWrapper<Branch>
  BriefParsedCv: ResolverTypeWrapper<BriefParsedCv>
  BriefParsedCvEdge: ResolverTypeWrapper<BriefParsedCvEdge>
  Bucket: ResolverTypeWrapper<Bucket>
  CabinetEventsDateRange: ResolverTypeWrapper<CabinetEventsDateRange>
  CabinetEventsFilter: ResolverTypeWrapper<CabinetEventsFilter>
  CabinetEventsQueryFilter: ResolverTypeWrapper<CabinetEventsQueryFilter>
  CalculatePriceItem: ResolverTypeWrapper<CalculatePriceItem>
  CalculatePricesFilter: ResolverTypeWrapper<CalculatePricesFilter>
  CalculatePricesInput: CalculatePricesInput
  CalculateServicePriceInput: CalculateServicePriceInput
  CalculateServicePriceMultipleInput: CalculateServicePriceMultipleInput
  CallFilters: ResolverTypeWrapper<CallFilters>
  CandidateInteraction: ResolverTypeWrapper<CandidateInteraction>
  CandidateInteractionEnum: CandidateInteractionEnum
  CandidateInteractionWrapper: ResolverTypeWrapper<CandidateInteractionWrapper>
  CandidateInteractionsFilterInput: CandidateInteractionsFilterInput
  CandidateMatchedKeyword: ResolverTypeWrapper<CandidateMatchedKeyword>
  CandidateResumeEmployerEventSnapshot: ResolverTypeWrapper<CandidateResumeEmployerEventSnapshot>
  CandidateSource: CandidateSource
  CandidateSourceType: ResolverTypeWrapper<CandidateSourceType>
  CandidateType: CandidateType
  CandidatesFilterMode: CandidatesFilterMode
  CandidatesVacancyCounters: ResolverTypeWrapper<CandidatesVacancyCounters>
  CartItem: ResolverTypeWrapper<Omit<CartItem, 'service'> & { service: ResolversTypes['CatalogUnitedServices'] }>
  CartItemInput: CartItemInput
  CartMutation: ResolverTypeWrapper<CartMutation>
  CartQuery: ResolverTypeWrapper<CartQuery>
  CartRemoveItemsInput: CartRemoveItemsInput
  CatalogChildService: ResolverTypeWrapper<Omit<CatalogChildService, 'service'> & { service: ResolversTypes['CatalogUnitedServices'] }>
  CatalogService: ResolverTypeWrapper<Omit<CatalogService, 'detailsUnion'> & { detailsUnion: ResolversTypes['CatalogUnitedServices'] }>
  CatalogServiceCategory: CatalogServiceCategory
  CatalogServiceDiscountEnum: CatalogServiceDiscountEnum
  CatalogServicesFilterInput: CatalogServicesFilterInput
  CatalogUnitedServices:
    | ResolversTypes['AdditionalCatalogService']
    | ResolversTypes['CvDbCatalogService']
    | ResolversTypes['HotCatalogService']
    | ResolversTypes['LogoInPortalCatalogService']
    | ResolversTypes['LogoInRubricCatalogService']
    | ResolversTypes['LogoOnMainPageCatalogService']
    | ResolversTypes['MarketingCatalogService']
    | ResolversTypes['RisingUpCatalogService']
    | ResolversTypes['VacancyPackageCatalogService']
    | ResolversTypes['VacancyPublicationCatalogService']
  CategorizationAttachesMutations: ResolverTypeWrapper<CategorizationAttachesMutations>
  CategorizationAttachesQueries: ResolverTypeWrapper<CategorizationAttachesQueries>
  CategorizationResultOutput: ResolverTypeWrapper<CategorizationResultOutput>
  Categorizator: ResolverTypeWrapper<Categorizator>
  ChangeSavedCardsErrorUnion: ResolversTypes['HasNoScopesForActionError'] | ResolversTypes['InvalidError'] | ResolversTypes['ServerError'] | ResolversTypes['UserIsNotAuthenticatedError']
  ChangeSavedCardsResponse: ResolverTypeWrapper<Omit<ChangeSavedCardsResponse, 'errors'> & { errors: ReadonlyArray<ResolversTypes['ChangeSavedCardsErrorUnion']> }>
  ChangeSeekerEmailInput: ChangeSeekerEmailInput
  ChangeSeekerEmailMutation: ResolverTypeWrapper<ChangeSeekerEmailMutation>
  ChangeSeekerEmailOutput: ResolverTypeWrapper<ChangeSeekerEmailOutput>
  ChangeSeekerNameMutationInput: ChangeSeekerNameMutationInput
  ChangeSeekerNameMutationOutput: ResolverTypeWrapper<ChangeSeekerNameMutationOutput>
  ChangeSeekerPasswordByEmailInput: ChangeSeekerPasswordByEmailInput
  ChangeSeekerPasswordByEmailMutations: ResolverTypeWrapper<ChangeSeekerPasswordByEmailMutations>
  ChangeSeekerPasswordByPhoneInput: ChangeSeekerPasswordByPhoneInput
  ChangeSeekerPasswordByPhoneMutations: ResolverTypeWrapper<ChangeSeekerPasswordByPhoneMutations>
  ChangeSeekerPasswordMutations: ResolverTypeWrapper<ChangeSeekerPasswordMutations>
  ChangeSeekerPasswordOutput: ResolverTypeWrapper<ChangeSeekerPasswordOutput>
  ChangeSeekerPasswordStatusEnum: ChangeSeekerPasswordStatusEnum
  ChangedFullNameUserManagementEmployerEvent: ResolverTypeWrapper<Omit<ChangedFullNameUserManagementEmployerEvent, 'performer'> & { performer: ResolversTypes['PerformerEmployerEventUnion'] }>
  ChangedScopesUserManagementEmployerEvent: ResolverTypeWrapper<Omit<ChangedScopesUserManagementEmployerEvent, 'performer'> & { performer: ResolversTypes['PerformerEmployerEventUnion'] }>
  Chat: ResolverTypeWrapper<Chat>
  ChatMessage: ResolverTypeWrapper<ChatMessage>
  CheckPayer: ResolverTypeWrapper<CheckPayer>
  CheckPayerResultOutput: ResolverTypeWrapper<CheckPayerResultOutput>
  CheckVacancyContentCorrectnessForPublishingOutput: ResolverTypeWrapper<CheckVacancyContentCorrectnessForPublishingOutput>
  CitiesConnection: ResolverTypeWrapper<CitiesConnection>
  CitiesConnectionInput: CitiesConnectionInput
  City: ResolverTypeWrapper<City>
  CityDistrict: ResolverTypeWrapper<CityDistrict>
  CityEdge: ResolverTypeWrapper<CityEdge>
  CloseBannerError: ResolversTypes['InvalidError'] | ResolversTypes['NotFoundError'] | ResolversTypes['ServerError'] | ResolversTypes['UserIsNotAuthenticatedError']
  CloseBannerResponse: ResolverTypeWrapper<Omit<CloseBannerResponse, 'error'> & { error: Maybe<ResolversTypes['CloseBannerError']> }>
  ClosedAdditionalService: ResolverTypeWrapper<ClosedAdditionalService>
  ClosedCvDbService: ResolverTypeWrapper<ClosedCvDbService>
  ClosedHotService: ResolverTypeWrapper<ClosedHotService>
  ClosedLogoInPortalService: ResolverTypeWrapper<ClosedLogoInPortalService>
  ClosedLogoInRubricService: ResolverTypeWrapper<ClosedLogoInRubricService>
  ClosedMarketingService: ResolverTypeWrapper<ClosedMarketingService>
  ClosedRisingUpService: ResolverTypeWrapper<ClosedRisingUpService>
  ClosedVacancyEmployerEvent: ResolverTypeWrapper<Omit<ClosedVacancyEmployerEvent, 'performer'> & { performer: ResolversTypes['PerformerEmployerEventUnion'] }>
  ClosedVacancyPackageService: ResolverTypeWrapper<Omit<ClosedVacancyPackageService, 'additionalServices'> & { additionalServices: ReadonlyArray<Maybe<ResolversTypes['ServiceUnited']>> }>
  ClosedVacancyPublicationService: ResolverTypeWrapper<ClosedVacancyPublicationService>
  ClubMutations: ResolverTypeWrapper<ClubMutations>
  ClubPost: ResolverTypeWrapper<ClubPost>
  ClubQueries: ResolverTypeWrapper<ClubQueries>
  ClubTag: ResolverTypeWrapper<ClubTag>
  ClubTopic: ResolverTypeWrapper<ClubTopic>
  ClubUserProfile: ResolverTypeWrapper<ClubUserProfile>
  Company: ResolverTypeWrapper<Company>
  CompanyApplyAutoReplyTemplateConfigurationUpdateInput: CompanyApplyAutoReplyTemplateConfigurationUpdateInput
  CompanyApplyAutoReplyTemplateConfigurationUpdateOutput: ResolverTypeWrapper<CompanyApplyAutoReplyTemplateConfigurationUpdateOutput>
  CompanyAssignmentOutput: ResolverTypeWrapper<CompanyAssignmentOutput>
  CompanyAssignmentRefuseInput: CompanyAssignmentRefuseInput
  CompanyAssignmentWantInput: CompanyAssignmentWantInput
  CompanyAssignmentsMutations: ResolverTypeWrapper<CompanyAssignmentsMutations>
  CompanyBenefit: ResolverTypeWrapper<CompanyBenefit>
  CompanyBlockingReasons: ResolverTypeWrapper<CompanyBlockingReasons>
  CompanyBufferEvent: ResolverTypeWrapper<CompanyBufferEvent>
  CompanyCard: ResolverTypeWrapper<CompanyCard>
  CompanyCardAction: ResolverTypeWrapper<CompanyCardAction>
  CompanyCardConnection: ResolverTypeWrapper<CompanyCardConnection>
  CompanyCardEdge: ResolverTypeWrapper<CompanyCardEdge>
  CompanyCardFilterInput: CompanyCardFilterInput
  CompanyCardPayment: ResolverTypeWrapper<CompanyCardPayment>
  CompanyCardServiceTypeEnum: CompanyCardServiceTypeEnum
  CompanyCardTicket: ResolverTypeWrapper<CompanyCardTicket>
  CompanyEditInput: CompanyEditInput
  CompanyEditOutput: ResolverTypeWrapper<CompanyEditOutput>
  CompanyEmailResendConfirmationInput: CompanyEmailResendConfirmationInput
  CompanyEmailResendConfirmationOutput: ResolverTypeWrapper<CompanyEmailResendConfirmationOutput>
  CompanyEventEnum: CompanyEventEnum
  CompanyEventInput: CompanyEventInput
  CompanyEventOutput: ResolverTypeWrapper<CompanyEventOutput>
  CompanyFreeServiceAddedEvent: ResolverTypeWrapper<Omit<CompanyFreeServiceAddedEvent, 'performer'> & { performer: ResolversTypes['PerformerEmployerEventUnion'] }>
  CompanyHonors: ResolverTypeWrapper<CompanyHonors>
  CompanyHonorsBadge: ResolverTypeWrapper<CompanyHonorsBadge>
  CompanyHonorsBadgeLocationEnum: CompanyHonorsBadgeLocationEnum
  CompanyJoinRequest: ResolverTypeWrapper<CompanyJoinRequest>
  CompanyJoinRequestStatusEnum: CompanyJoinRequestStatusEnum
  CompanyLoyaltyEnum: CompanyLoyaltyEnum
  CompanyMiniProfile: ResolverTypeWrapper<CompanyMiniProfile>
  CompanyMiniProfileInput: CompanyMiniProfileInput
  CompanyNotificationSnapshot: ResolverTypeWrapper<CompanyNotificationSnapshot>
  CompanyPublishedVacanciesFilter: ResolverTypeWrapper<CompanyPublishedVacanciesFilter>
  CompanyPublishedVacanciesFilterInput: CompanyPublishedVacanciesFilterInput
  CompanyPublishedVacanciesQueryFilter: ResolverTypeWrapper<CompanyPublishedVacanciesQueryFilter>
  CompanyReason: ResolverTypeWrapper<CompanyReason>
  CompanyRegisteredEvent: ResolverTypeWrapper<CompanyRegisteredEvent>
  CompanySavedCardAddedEvent: ResolverTypeWrapper<Omit<CompanySavedCardAddedEvent, 'performer'> & { performer: ResolversTypes['PerformerEmployerEventUnion'] }>
  CompanySavedCardRemovedEvent: ResolverTypeWrapper<Omit<CompanySavedCardRemovedEvent, 'performer'> & { performer: ResolversTypes['PerformerEmployerEventUnion'] }>
  CompanySegmentEnum: CompanySegmentEnum
  CompanySendJoinRequestErrorEnum: CompanySendJoinRequestErrorEnum
  CompanySendJoinRequestOutput: ResolverTypeWrapper<CompanySendJoinRequestOutput>
  CompanySocialMedia: ResolverTypeWrapper<CompanySocialMedia>
  CompanySocialMediaInput: CompanySocialMediaInput
  CompanyStaffSize: ResolverTypeWrapper<CompanyStaffSize>
  CompanyStateChangedDetails: ResolverTypeWrapper<CompanyStateChangedDetails>
  CompanyStateEnum: CompanyStateEnum
  CompanyStateWrapper: ResolverTypeWrapper<CompanyStateWrapper>
  CompanyTurbotaStatus: ResolverTypeWrapper<CompanyTurbotaStatus>
  CompanyVerificationInput: CompanyVerificationInput
  CompanyVerificationOutput: ResolverTypeWrapper<CompanyVerificationOutput>
  CompanyWasMergedError: ResolverTypeWrapper<CompanyWasMergedError>
  CompanyWorkRegisteredEvent: ResolverTypeWrapper<CompanyWorkRegisteredEvent>
  ConfirmEmailByCodeInput: ConfirmEmailByCodeInput
  ConfirmEmailCodeOutput: ResolverTypeWrapper<ConfirmEmailCodeOutput>
  ConfirmEmailCodeStatusEnum: ConfirmEmailCodeStatusEnum
  ConfirmProfileDeletionInput: ConfirmProfileDeletionInput
  ConfirmProfileDeletionOutput: ResolverTypeWrapper<ConfirmProfileDeletionOutput>
  ConfirmedPhoneUsersOutput: ResolverTypeWrapper<ConfirmedPhoneUsersOutput>
  ContactInfoSubTypeEnum: ContactInfoSubTypeEnum
  ContactWarmthTypeEnum: ContactWarmthTypeEnum
  ContactsAccrualPeriod: ContactsAccrualPeriod
  Contract: ResolverTypeWrapper<Contract>
  ContractCreationOutput: ResolverTypeWrapper<ContractCreationOutput>
  CountStatistics: ResolverTypeWrapper<CountStatistics>
  CreateAsCopyProfResumeInput: CreateAsCopyProfResumeInput
  CreateEmployerDuplicateProfileInput: CreateEmployerDuplicateProfileInput
  CreateEmployerProfileInput: CreateEmployerProfileInput
  CreateEmptyProfResumeError: ResolversTypes['ProfResumeLimit']
  CreateGroupNotificationInput: CreateGroupNotificationInput
  CreateGroupNotificationOutput: ResolverTypeWrapper<CreateGroupNotificationOutput>
  CreateInvoiceOutput: ResolverTypeWrapper<CreateInvoiceOutput>
  CreateOrderCvDbInput: CreateOrderCvDbInput
  CreateOrderFromCartErrorEnum: CreateOrderFromCartErrorEnum
  CreateOrderFromCartResponse: ResolverTypeWrapper<CreateOrderFromCartResponse>
  CreateOrderFromServicesInput: CreateOrderFromServicesInput
  CreateOrderFromServicesOutput: ResolverTypeWrapper<CreateOrderFromServicesOutput>
  CreateOrderServiceInput: CreateOrderServiceInput
  CreateProfResumeAsCopyError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist'] | ResolversTypes['ProfResumeLimit']
  CreateProfResumeError: ResolversTypes['ProfResumeDuplicated'] | ResolversTypes['ProfResumeLimit']
  CreateProfResumeFromAttachError: ResolversTypes['AttachNotFound'] | ResolversTypes['AttachParsingError'] | ResolversTypes['ProfResumeDuplicated'] | ResolversTypes['ProfResumeLimit']
  CreateProfResumeFromAttachInput: CreateProfResumeFromAttachInput
  CreateProfResumeFromAttachOutput: ResolverTypeWrapper<Omit<CreateProfResumeFromAttachOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['CreateProfResumeFromAttachError']>> }>
  CreateProfResumeInput: CreateProfResumeInput
  CreateRecruitmentOrderError: ResolversTypes['InvalidError'] | ResolversTypes['NotFoundError']
  CreateRecruitmentOrderInput: CreateRecruitmentOrderInput
  CreateRecruitmentOrderOutput: ResolverTypeWrapper<Omit<CreateRecruitmentOrderOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['CreateRecruitmentOrderError']>> }>
  CreateResumeDraftCreatedNotificationInput: CreateResumeDraftCreatedNotificationInput
  CreateResumeDraftCreatedNotificationOutput: ResolverTypeWrapper<CreateResumeDraftCreatedNotificationOutput>
  CreateResumePublishedNotificationInput: CreateResumePublishedNotificationInput
  CreateResumePublishedNotificationOutput: ResolverTypeWrapper<CreateResumePublishedNotificationOutput>
  CreateSeekerProfileInput: CreateSeekerProfileInput
  CreateSeekerProfileResponse: ResolverTypeWrapper<CreateSeekerProfileResponse>
  CreateSharingDocumentsDeepLinkInput: CreateSharingDocumentsDeepLinkInput
  CreateSharingDocumentsDeepLinkOutput: ResolverTypeWrapper<CreateSharingDocumentsDeepLinkOutput>
  CreateTargetedMailingInput: CreateTargetedMailingInput
  CreateTargetedMailingOutput: ResolverTypeWrapper<Omit<CreateTargetedMailingOutput, 'error'> & { error: Maybe<ResolversTypes['TargetedMailingSaveErrorUnion']> }>
  CreateUserNotificationInput: CreateUserNotificationInput
  CreateUserNotificationOutput: ResolverTypeWrapper<CreateUserNotificationOutput>
  CreateWalletError: ResolversTypes['UserIsNotAuthenticatedError'] | ResolversTypes['WalletExistsError']
  CreateWalletInput: CreateWalletInput
  CreateWalletOutput: ResolverTypeWrapper<Omit<CreateWalletOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['CreateWalletError']>> }>
  CreateWalletTransactionError: ResolversTypes['InvalidError'] | ResolversTypes['TransactionExistsError'] | ResolversTypes['UserIsNotAuthenticatedError'] | ResolversTypes['WalletDoesNotExistError']
  CreateWalletTransactionInput: CreateWalletTransactionInput
  CreateWalletTransactionOutput: ResolverTypeWrapper<Omit<CreateWalletTransactionOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['CreateWalletTransactionError']>> }>
  CreatedAsCopyProfResumeOutput: ResolverTypeWrapper<Omit<CreatedAsCopyProfResumeOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['CreateProfResumeAsCopyError']>> }>
  CreatedDraftVacancyEmployerEvent: ResolverTypeWrapper<Omit<CreatedDraftVacancyEmployerEvent, 'performer'> & { performer: ResolversTypes['PerformerEmployerEventUnion'] }>
  CreatedEmptyProfResumeOutput: ResolverTypeWrapper<Omit<CreatedEmptyProfResumeOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['CreateEmptyProfResumeError']>> }>
  CreatedOrderServiceEmployerEvent: ResolverTypeWrapper<Omit<CreatedOrderServiceEmployerEvent, 'performer'> & { performer: ResolversTypes['PerformerEmployerEventUnion'] }>
  CreatedProfResumeOutput: ResolverTypeWrapper<Omit<CreatedProfResumeOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['CreateProfResumeError']>> }>
  CrmAccount: ResolverTypeWrapper<CrmAccount>
  CrmAccountConnection: ResolverTypeWrapper<CrmAccountConnection>
  CrmAccountEdge: ResolverTypeWrapper<CrmAccountEdge>
  CrmAccountState: CrmAccountState
  CrmAction: ResolverTypeWrapper<CrmAction>
  CrmActionAddInput: CrmActionAddInput
  CrmActionAddResult: ResolverTypeWrapper<CrmActionAddResult>
  CrmActionBrief: ResolverTypeWrapper<CrmActionBrief>
  CrmActionConnection: ResolverTypeWrapper<CrmActionConnection>
  CrmActionCreatorEnum: CrmActionCreatorEnum
  CrmActionDraftRequest: CrmActionDraftRequest
  CrmActionEdge: ResolverTypeWrapper<CrmActionEdge>
  CrmActionOperation: CrmActionOperation
  CrmActionOperationInput: CrmActionOperationInput
  CrmActionType: ResolverTypeWrapper<CrmActionType>
  CrmActionTypeEnum: CrmActionTypeEnum
  CrmActionUpdateInput: CrmActionUpdateInput
  CrmCall: ResolverTypeWrapper<CrmCall>
  CrmCallConnection: ResolverTypeWrapper<CrmCallConnection>
  CrmCallDisposition: CrmCallDisposition
  CrmCallEdge: ResolverTypeWrapper<CrmCallEdge>
  CrmCallOrder: CrmCallOrder
  CrmCallType: CrmCallType
  CrmCallTypeRequest: CrmCallTypeRequest
  CrmCompany: ResolverTypeWrapper<CrmCompany>
  CrmCompanyConnection: ResolverTypeWrapper<CrmCompanyConnection>
  CrmCompanyEdge: ResolverTypeWrapper<CrmCompanyEdge>
  CrmCompletionAct: ResolverTypeWrapper<CrmCompletionAct>
  CrmContact: ResolverTypeWrapper<CrmContact>
  CrmDateInterval: CrmDateInterval
  CrmDayOfWeek: CrmDayOfWeek
  CrmEditAction: ResolverTypeWrapper<CrmEditAction>
  CrmEmployee: ResolverTypeWrapper<CrmEmployee>
  CrmFilterCallInput: CrmFilterCallInput
  CrmLinkPhoneToUserCardInput: CrmLinkPhoneToUserCardInput
  CrmManager: ResolverTypeWrapper<CrmManager>
  CrmManagerCalendarRequest: CrmManagerCalendarRequest
  CrmManagerRole: CrmManagerRole
  CrmMarketingService: ResolverTypeWrapper<CrmMarketingService>
  CrmMarketingServiceConnection: ResolverTypeWrapper<CrmMarketingServiceConnection>
  CrmMarketingServiceEdge: ResolverTypeWrapper<CrmMarketingServiceEdge>
  CrmMarketingServiceFilter: ResolverTypeWrapper<CrmMarketingServiceFilter>
  CrmMarketingServiceFilterType: CrmMarketingServiceFilterType
  CrmMeetingReason: ResolverTypeWrapper<CrmMeetingReason>
  CrmMetaCompany: ResolverTypeWrapper<CrmMetaCompany>
  CrmMultiUser: ResolverTypeWrapper<CrmMultiUser>
  CrmMultiUserRole: CrmMultiUserRole
  CrmOrderType: CrmOrderType
  CrmPayState: CrmPayState
  CrmPhone: ResolverTypeWrapper<CrmPhone>
  CrmPhoneUpdateAction: CrmPhoneUpdateAction
  CrmPinActionInput: CrmPinActionInput
  CrmPlannedActionByDay: ResolverTypeWrapper<CrmPlannedActionByDay>
  CrmPositionState: CrmPositionState
  CrmQuarter: ResolverTypeWrapper<CrmQuarter>
  CrmQuarterInput: CrmQuarterInput
  CrmUserCard: ResolverTypeWrapper<CrmUserCard>
  CrmUserCardAddInput: CrmUserCardAddInput
  CrmUserCardEdit: ResolverTypeWrapper<CrmUserCardEdit>
  CrmUserCardElectInput: CrmUserCardElectInput
  CrmUserCardRole: ResolverTypeWrapper<CrmUserCardRole>
  CrmUserCardRoleEnum: CrmUserCardRoleEnum
  CrmUserCardState: CrmUserCardState
  CrmUserCardUpdateInput: CrmUserCardUpdateInput
  CrmUserPhoneUpdateInput: CrmUserPhoneUpdateInput
  Currency: Currency
  CustomNotificationButtonTypeEnum: CustomNotificationButtonTypeEnum
  CustomNotificationDetails: ResolverTypeWrapper<CustomNotificationDetails>
  CustomNotificationElement: ResolverTypeWrapper<CustomNotificationElement>
  CustomNotificationElementButton: ResolverTypeWrapper<CustomNotificationElementButton>
  CustomNotificationElementText: ResolverTypeWrapper<CustomNotificationElementText>
  CvDbCatalogService: ResolverTypeWrapper<CvDbCatalogService>
  CvDbServiceEmployerEventSnapshot: ResolverTypeWrapper<CvDbServiceEmployerEventSnapshot>
  CvDbServiceUsagePeriodEmployerEventEnum: CvDbServiceUsagePeriodEmployerEventEnum
  CvRubricInput: CvRubricInput
  CvTagInput: CvTagInput
  CvdbPriceInput: CvdbPriceInput
  DailyResumeViewsReportCalculated: ResolverTypeWrapper<DailyResumeViewsReportCalculated>
  DashboardCompany: ResolverTypeWrapper<DashboardCompany>
  DashboardCompanyConnection: ResolverTypeWrapper<DashboardCompanyConnection>
  DashboardCompanyEdge: ResolverTypeWrapper<DashboardCompanyEdge>
  DashboardCompanyInput: DashboardCompanyInput
  DashboardMultiUserInput: DashboardMultiUserInput
  DashboardMultiUserResponse: ResolverTypeWrapper<DashboardMultiUserResponse>
  DashboardMultiUserSection: DashboardMultiUserSection
  DashboardRatingInput: DashboardRatingInput
  DashboardRatingResponse: ResolverTypeWrapper<DashboardRatingResponse>
  DashboardRatingSection: DashboardRatingSection
  DashboardResponse: ResolverTypeWrapper<DashboardResponse>
  DashboardSection: DashboardSection
  DashboardValuesEnum: DashboardValuesEnum
  Date: ResolverTypeWrapper<Scalars['Date']>
  DateCounter: ResolverTypeWrapper<DateCounter>
  DateInterval: ResolverTypeWrapper<DateInterval>
  DateIntervalInput: DateIntervalInput
  DateRange: DateRange
  DateRangeInput: DateRangeInput
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>
  DateTimeOffset: ResolverTypeWrapper<Scalars['DateTimeOffset']>
  DealCard: ResolverTypeWrapper<DealCard>
  DealCardAccount: ResolverTypeWrapper<DealCardAccount>
  DealCardPayment: ResolverTypeWrapper<DealCardPayment>
  DealDetailsOutput: ResolverTypeWrapper<DealDetailsOutput>
  DealForecast: ResolverTypeWrapper<DealForecast>
  DealOrderCard: ResolverTypeWrapper<DealOrderCard>
  DealStateEnum: DealStateEnum
  DealTableFolderEnum: DealTableFolderEnum
  DealTableOutput: ResolverTypeWrapper<DealTableOutput>
  DealTableRequestInput: DealTableRequestInput
  Decimal: ResolverTypeWrapper<Scalars['Decimal']>
  DeleteAllNotificationsError: ResolversTypes['NotificationNotFoundError'] | ResolversTypes['UnknownServerError']
  DeleteAllNotificationsOutput: ResolverTypeWrapper<Omit<DeleteAllNotificationsOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['DeleteAllNotificationsError']>> }>
  DeleteNotificationsError: ResolversTypes['NotificationNotFoundError'] | ResolversTypes['UnknownServerError']
  DeleteNotificationsInput: DeleteNotificationsInput
  DeleteNotificationsOutput: ResolverTypeWrapper<Omit<DeleteNotificationsOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['DeleteNotificationsError']>> }>
  DeletePostFromHotOutput: ResolverTypeWrapper<DeletePostFromHotOutput>
  DeletePostFromTopOutput: ResolverTypeWrapper<DeletePostFromTopOutput>
  DeleteProfResumeInput: DeleteProfResumeInput
  DeleteProfResumePortfolioOutput: ResolverTypeWrapper<Omit<DeleteProfResumePortfolioOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['DeleteSeekerProfResumePortfoliosError']>> }>
  DeleteProfResumeSocialNetworksOutput: ResolverTypeWrapper<
    Omit<DeleteProfResumeSocialNetworksOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['DeleteSeekerProfResumeSocialNetworksError']>> }
  >
  DeleteProfileOutput: ResolversTypes['DeleteProfileWithEmailOutput'] | ResolversTypes['DeleteProfileWithSmsOutput']
  DeleteProfileStatus: DeleteProfileStatus
  DeleteProfileWithEmailOutput: ResolverTypeWrapper<DeleteProfileWithEmailOutput>
  DeleteProfileWithSmsOutput: ResolverTypeWrapper<DeleteProfileWithSmsOutput>
  DeleteSeekerProfResumeAdditionalEducationsError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  DeleteSeekerProfResumeAdditionalInfoError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  DeleteSeekerProfResumeAdditionalPhonesError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  DeleteSeekerProfResumeAdditionalPhonesOutput: ResolverTypeWrapper<
    Omit<DeleteSeekerProfResumeAdditionalPhonesOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['DeleteSeekerProfResumeAdditionalPhonesError']>> }
  >
  DeleteSeekerProfResumeDiiaCertificateError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  DeleteSeekerProfResumeEducationsError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  DeleteSeekerProfResumeError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  DeleteSeekerProfResumeExperiencesError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  DeleteSeekerProfResumeLanguageSkillsError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  DeleteSeekerProfResumePortfoliosError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  DeleteSeekerProfResumeSocialNetworksError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  DeleteTargetedMailingOutput: ResolverTypeWrapper<DeleteTargetedMailingOutput>
  DeleteVacancyInput: DeleteVacancyInput
  DeleteVacancyOutput: ResolverTypeWrapper<DeleteVacancyOutput>
  DeletedProfResumeAdditionalEducationsOutput: ResolverTypeWrapper<
    Omit<DeletedProfResumeAdditionalEducationsOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['DeleteSeekerProfResumeAdditionalEducationsError']>> }
  >
  DeletedProfResumeAdditionalInfoOutput: ResolverTypeWrapper<
    Omit<DeletedProfResumeAdditionalInfoOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['DeleteSeekerProfResumeAdditionalInfoError']>> }
  >
  DeletedProfResumeEducationsOutput: ResolverTypeWrapper<Omit<DeletedProfResumeEducationsOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['DeleteSeekerProfResumeEducationsError']>> }>
  DeletedProfResumeExperiencesOutput: ResolverTypeWrapper<
    Omit<DeletedProfResumeExperiencesOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['DeleteSeekerProfResumeExperiencesError']>> }
  >
  DeletedProfResumeLanguageSkillsOutput: ResolverTypeWrapper<
    Omit<DeletedProfResumeLanguageSkillsOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['DeleteSeekerProfResumeLanguageSkillsError']>> }
  >
  DeletedProfResumeOutput: ResolverTypeWrapper<Omit<DeletedProfResumeOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['DeleteSeekerProfResumeError']>> }>
  DeletedUserManagementEmployerEvent: ResolverTypeWrapper<Omit<DeletedUserManagementEmployerEvent, 'performer'> & { performer: ResolversTypes['PerformerEmployerEventUnion'] }>
  DeletedVacancyEmployerEvent: ResolverTypeWrapper<Omit<DeletedVacancyEmployerEvent, 'performer'> & { performer: ResolversTypes['PerformerEmployerEventUnion'] }>
  DepartmentEnum: DepartmentEnum
  DirectoryItem: ResolverTypeWrapper<DirectoryItem>
  Discount: ResolversTypes['ManagerDiscount'] | ResolversTypes['PromoCodeDiscount']
  DislikeSeekerVacanciesInput: DislikeSeekerVacanciesInput
  DislikeSeekerVacanciesOutput: ResolverTypeWrapper<DislikeSeekerVacanciesOutput>
  DistrictFacet: ResolverTypeWrapper<DistrictFacet>
  DownloadActInput: DownloadActInput
  DownloadActOutput: ResolverTypeWrapper<DownloadActOutput>
  DownloadInvoiceInput: DownloadInvoiceInput
  DownloadInvoiceInputItem: DownloadInvoiceInputItem
  DownloadInvoiceOutput: ResolverTypeWrapper<DownloadInvoiceOutput>
  DropAppliedWalletBonusResponse: ResolverTypeWrapper<DropAppliedWalletBonusResponse>
  DropSelectedPromoCodeResponse: ResolverTypeWrapper<DropSelectedPromoCodeResponse>
  DuplicateCompany: ResolverTypeWrapper<DuplicateCompany>
  DuplicateReasonEnum: DuplicateReasonEnum
  EditCvTagInput: EditCvTagInput
  EditGiftLimitInput: EditGiftLimitInput
  EditLabelActionEnum: EditLabelActionEnum
  EditLabelInput: EditLabelInput
  EditedVacancyCityEmployerEvent: ResolverTypeWrapper<EditedVacancyCityEmployerEvent>
  EditedVacancyEmployerEvent: ResolverTypeWrapper<
    Omit<EditedVacancyEmployerEvent, 'editedFields' | 'performer'> & {
      editedFields: ReadonlyArray<ResolversTypes['EditedVacancyFieldEmployerEventUnion']>
      performer: ResolversTypes['PerformerEmployerEventUnion']
    }
  >
  EditedVacancyFieldEmployerEvent: ResolverTypeWrapper<EditedVacancyFieldEmployerEvent>
  EditedVacancyFieldEmployerEventUnion: ResolversTypes['EditedVacancyCityEmployerEvent'] | ResolversTypes['EditedVacancyFieldEmployerEvent'] | ResolversTypes['EditedVacancyTitleEmployerEvent']
  EditedVacancyTitleEmployerEvent: ResolverTypeWrapper<EditedVacancyTitleEmployerEvent>
  EducationLevel: ResolverTypeWrapper<EducationLevel>
  EducationLevelEnum: EducationLevelEnum
  EmailAlreadyExistsError: ResolverTypeWrapper<EmailAlreadyExistsError>
  EmailSenderResult: ResolverTypeWrapper<EmailSenderResult>
  EmailStatistics: ResolverTypeWrapper<Omit<EmailStatistics, 'links'> & { links: ReadonlyArray<ResolversTypes['TargetedMailingLinkUnion']> }>
  Employee: ResolverTypeWrapper<Employee>
  EmployeeActiveStatus: EmployeeActiveStatus
  EmployeeAtsRelation: ResolverTypeWrapper<EmployeeAtsRelation>
  EmployeeChangeEmailInput: EmployeeChangeEmailInput
  EmployeeChangeEmailOutput: ResolverTypeWrapper<Omit<EmployeeChangeEmailOutput, 'error'> & { error: Maybe<ResolversTypes['EmployeeEditError']> }>
  EmployeeChangeOutput: ResolverTypeWrapper<Omit<EmployeeChangeOutput, 'error'> & { error: Maybe<ResolversTypes['EmployeeEditError']> }>
  EmployeeChangePasswordInput: EmployeeChangePasswordInput
  EmployeeConnection: ResolverTypeWrapper<EmployeeConnection>
  EmployeeCreateError: ResolversTypes['EmailAlreadyExistsError'] | ResolversTypes['ForbiddenToMutateUsersError']
  EmployeeCreateInput: EmployeeCreateInput
  EmployeeCreateOutput: ResolverTypeWrapper<Omit<EmployeeCreateOutput, 'error'> & { error: Maybe<ResolversTypes['EmployeeCreateError']> }>
  EmployeeCvDbSavedSearchDeleteInput: EmployeeCvDbSavedSearchDeleteInput
  EmployeeCvDbSavedSearchDeleteOutput: ResolverTypeWrapper<EmployeeCvDbSavedSearchDeleteOutput>
  EmployeeEdge: ResolverTypeWrapper<EmployeeEdge>
  EmployeeEditError:
    | ResolversTypes['EmailAlreadyExistsError']
    | ResolversTypes['ForbiddenToMutateUsersError']
    | ResolversTypes['PasswordConfirmationFailedError']
    | ResolversTypes['TokenAlreadyUsedError']
  EmployeeEditInput: EmployeeEditInput
  EmployeeEditOutput: ResolverTypeWrapper<Omit<EmployeeEditOutput, 'error'> & { error: Maybe<ResolversTypes['EmployeeEditError']> }>
  EmployeeHasScopesInput: EmployeeHasScopesInput
  EmployeeHasScopesOutput: ResolverTypeWrapper<EmployeeHasScopesOutput>
  EmployeeJoiningRequestAlreadyReviewedError: ResolverTypeWrapper<EmployeeJoiningRequestAlreadyReviewedError>
  EmployeeJoiningReviewErrorUnion: ResolversTypes['EmployeeJoiningRequestAlreadyReviewedError']
  EmployeeJoiningReviewInput: EmployeeJoiningReviewInput
  EmployeeJoiningReviewOutput: ResolverTypeWrapper<Omit<EmployeeJoiningReviewOutput, 'error'> & { error: Maybe<ResolversTypes['EmployeeJoiningReviewErrorUnion']> }>
  EmployeeLimitCounter: ResolverTypeWrapper<EmployeeLimitCounter>
  EmployeeMakeMainOutput: ResolverTypeWrapper<EmployeeMakeMainOutput>
  EmployeePostEnum: EmployeePostEnum
  EmployeeResendInvitationInput: EmployeeResendInvitationInput
  EmployeeResendInvitationOutput: ResolverTypeWrapper<EmployeeResendInvitationOutput>
  EmployeeRole: EmployeeRole
  EmployeeRoleBasedScope: ResolverTypeWrapper<EmployeeRoleBasedScope>
  EmployeeRoleBasedScopeEnum: EmployeeRoleBasedScopeEnum
  EmployeeScopeChangeUserManagementEmployerEvent: ResolverTypeWrapper<EmployeeScopeChangeUserManagementEmployerEvent>
  EmployeeSendChangeEmailInput: EmployeeSendChangeEmailInput
  EmployeeSetAsAdminInput: EmployeeSetAsAdminInput
  EmployeeStatusChanged: ResolverTypeWrapper<EmployeeStatusChanged>
  EmployeeStatusEnum: EmployeeStatusEnum
  EmployeeSubscription: ResolverTypeWrapper<Omit<EmployeeSubscription, 'options'> & { options: Maybe<ReadonlyArray<ResolversTypes['EmployeeSubscriptionSubOptionUnion']>> }>
  EmployeeSubscriptionAnswerTemplate: ResolverTypeWrapper<EmployeeSubscriptionAnswerTemplate>
  EmployeeSubscriptionAnswerTemplateInput: EmployeeSubscriptionAnswerTemplateInput
  EmployeeSubscriptionAnswerTemplateOutput: ResolverTypeWrapper<EmployeeSubscriptionAnswerTemplateOutput>
  EmployeeSubscriptionIntRange: ResolverTypeWrapper<EmployeeSubscriptionIntRange>
  EmployeeSubscriptionSavedCvDbSearchContextEnum: EmployeeSubscriptionSavedCvDbSearchContextEnum
  EmployeeSubscriptionSavedCvDbSearchCvLanguageEnum: EmployeeSubscriptionSavedCvDbSearchCvLanguageEnum
  EmployeeSubscriptionSavedCvDbSearchCvSortEnum: EmployeeSubscriptionSavedCvDbSearchCvSortEnum
  EmployeeSubscriptionSavedCvDbSearchPeriodEnum: EmployeeSubscriptionSavedCvDbSearchPeriodEnum
  EmployeeSubscriptionSavedCvDbSearchRequest: ResolverTypeWrapper<EmployeeSubscriptionSavedCvDbSearchRequest>
  EmployeeSubscriptionSavedCvDbSearchSubOption: ResolverTypeWrapper<EmployeeSubscriptionSavedCvDbSearchSubOption>
  EmployeeSubscriptionSubOptionUnion: ResolversTypes['EmployeeSubscriptionSavedCvDbSearchSubOption']
  EmployeeSubscriptionType: ResolverTypeWrapper<EmployeeSubscriptionType>
  EmployeeSubscriptionTypeEnum: EmployeeSubscriptionTypeEnum
  EmployeeSubscriptionsMutations: ResolverTypeWrapper<EmployeeSubscriptionsMutations>
  EmployeeSubscriptionsQueries: ResolverTypeWrapper<EmployeeSubscriptionsQueries>
  EmployeeSubscriptionsUpdateItemInput: EmployeeSubscriptionsUpdateItemInput
  EmployeeSubscriptionsUpdateOutput: ResolverTypeWrapper<EmployeeSubscriptionsUpdateOutput>
  EmployeeSubscriptionsUpdateSubItemInput: EmployeeSubscriptionsUpdateSubItemInput
  EmployeeSummaryServiceLimit: ResolverTypeWrapper<EmployeeSummaryServiceLimit>
  EmployeesBlockInput: EmployeesBlockInput
  EmployeesBlockOutput: ResolverTypeWrapper<EmployeesBlockOutput>
  EmployeesDeleteInput: EmployeesDeleteInput
  EmployeesDeleteOutput: ResolverTypeWrapper<EmployeesDeleteOutput>
  EmployeesFilterInput: EmployeesFilterInput
  EmployeesMultiScopesUpdateOutput: ResolverTypeWrapper<EmployeesMultiScopesUpdateOutput>
  EmployeesOrderBy: EmployeesOrderBy
  EmployeesScopesMultiUpdateInput: EmployeesScopesMultiUpdateInput
  EmployeesScopesMultiUpdateItemInput: EmployeesScopesMultiUpdateItemInput
  EmployeesUnblockInput: EmployeesUnblockInput
  EmployeesUnblockOutput: ResolverTypeWrapper<EmployeesUnblockOutput>
  EmployerAchievement:
    | ResolversTypes['ApplicationsViewedEmployerAchievement']
    | ResolversTypes['OpenedContactOrVacancyOfferedEmployerAchievement']
    | ResolversTypes['PhoneNumberConfirmedEmployerAchievement']
    | ResolversTypes['VacancyPublishedEmployerAchievement']
    | ResolversTypes['VacancyPublishedWithFilledAddressAndSalaryEmployerAchievement']
  EmployerAchievementType: EmployerAchievementType
  EmployerEventLogConnection: ResolverTypeWrapper<Omit<EmployerEventLogConnection, 'items'> & { items: Maybe<ReadonlyArray<ResolversTypes['EmployerEventUnion']>> }>
  EmployerEventPerformer: ResolverTypeWrapper<EmployerEventPerformer>
  EmployerEventSnapshot: ResolverTypeWrapper<EmployerEventSnapshot>
  EmployerEventTypeEnum: EmployerEventTypeEnum
  EmployerEventUnion:
    | ResolversTypes['ActivatedOrderByTurbotaServiceEmployerEvent']
    | ResolversTypes['ActivatedServiceEmployerEvent']
    | ResolversTypes['AddedNewUserManagementEmployerEvent']
    | ResolversTypes['ApprovedProlongationRequestVacancyEmployerEvent']
    | ResolversTypes['ApprovedReviewVacancyEmployerEvent']
    | ResolversTypes['AutoProlongationDisabledVacancyEmployerEvent']
    | ResolversTypes['AutoProlongationEnabledVacancyEmployerEvent']
    | ResolversTypes['AutoProlongedVacancyEmployerEvent']
    | ResolversTypes['BlockedUserManagementEmployerEvent']
    | ResolversTypes['ChangedFullNameUserManagementEmployerEvent']
    | ResolversTypes['ChangedScopesUserManagementEmployerEvent']
    | ResolversTypes['ClosedVacancyEmployerEvent']
    | ResolversTypes['CompanyFreeServiceAddedEvent']
    | ResolversTypes['CompanySavedCardAddedEvent']
    | ResolversTypes['CompanySavedCardRemovedEvent']
    | ResolversTypes['CreatedDraftVacancyEmployerEvent']
    | ResolversTypes['CreatedOrderServiceEmployerEvent']
    | ResolversTypes['DeletedUserManagementEmployerEvent']
    | ResolversTypes['DeletedVacancyEmployerEvent']
    | ResolversTypes['EditedVacancyEmployerEvent']
    | ResolversTypes['EndedHotPeriodVacancyEmployerEvent']
    | ResolversTypes['EndedServiceEmployerEvent']
    | ResolversTypes['MadeHotVacancyEmployerEvent']
    | ResolversTypes['OfferedVacancyCvDbEmployerEvent']
    | ResolversTypes['OpenedContactsCvDbEmployerEvent']
    | ResolversTypes['OrderFreemiumServicesAccruedServiceEmployerEvent']
    | ResolversTypes['OrderServicesAccruedServiceEmployerEvent']
    | ResolversTypes['OwnerChangedVacancyEmployerEvent']
    | ResolversTypes['PaymentReceivedServiceEmployerEvent']
    | ResolversTypes['ProlongationRequestVacancyEmployerEvent']
    | ResolversTypes['PublishedVacancyEmployerEvent']
    | ResolversTypes['RePublishedVacancyEmployerEvent']
    | ResolversTypes['RejectedByModeratorVacancyEmployerEvent']
    | ResolversTypes['RejectedProlongationRequestVacancyEmployerEvent']
    | ResolversTypes['RejectedReviewVacancyEmployerEvent']
    | ResolversTypes['SentToReviewVacancyEmployerEvent']
    | ResolversTypes['UsedHotServiceEmployerEvent']
    | ResolversTypes['UsedVacancyServiceEmployerEvent']
    | ResolversTypes['WalletTransactionEvent']
  EmployerEventsLogFilterInput: EmployerEventsLogFilterInput
  EmployerOnBoardingCheckListOutput: ResolverTypeWrapper<EmployerOnBoardingCheckListOutput>
  EmployerRegistration: ResolverTypeWrapper<EmployerRegistration>
  EmployerRegistrationResponse: ResolverTypeWrapper<EmployerRegistrationResponse>
  EmployerRegistrationValidationInput: EmployerRegistrationValidationInput
  EmployerServiceLimitRequestInput: EmployerServiceLimitRequestInput
  EmployerSummaryServiceLimitInput: EmployerSummaryServiceLimitInput
  EmployerWalletUser: ResolverTypeWrapper<EmployerWalletUser>
  EmployersEventUnionEdge: ResolverTypeWrapper<Omit<EmployersEventUnionEdge, 'node'> & { node: ResolversTypes['EmployerEventUnion'] }>
  EndedHotPeriodVacancyEmployerEvent: ResolverTypeWrapper<EndedHotPeriodVacancyEmployerEvent>
  EndedServiceEmployerEvent: ResolverTypeWrapper<Omit<EndedServiceEmployerEvent, 'service'> & { service: ResolversTypes['ServiceEmployerEventSnapshotUnion'] }>
  Error:
    | ResolversTypes['AtsProjectCreationError']
    | ResolversTypes['CompanyWasMergedError']
    | ResolversTypes['HasNoAccessToRequestedFieldsError']
    | ResolversTypes['HasNoScopesForActionError']
    | ResolversTypes['InvalidError']
    | ResolversTypes['NoAvailableLimitsError']
    | ResolversTypes['NotAvailableError']
    | ResolversTypes['NotFoundError']
    | ResolversTypes['ServerError']
    | ResolversTypes['TransactionExistsError']
    | ResolversTypes['TurbotaWasExpiredError']
    | ResolversTypes['UserIsNotAuthenticatedError']
    | ResolversTypes['UserIsNotEmployerError']
    | ResolversTypes['WalletDoesNotExistError']
    | ResolversTypes['WalletExistsError']
  EventInterface:
    | ResolversTypes['CompanyBufferEvent']
    | ResolversTypes['CompanyRegisteredEvent']
    | ResolversTypes['CompanyWorkRegisteredEvent']
    | ResolversTypes['InvoiceExpiredEvent']
    | ResolversTypes['InvoicePaidEvent']
    | ResolversTypes['InvoicePaymentBankEvent']
    | ResolversTypes['InvoiceRequestedEvent']
    | ResolversTypes['MetanotebookAssignedEvent']
    | ResolversTypes['PersonalDiscountEvent']
    | ResolversTypes['ProfileRegisteredEvent']
    | ResolversTypes['ServiceIsExpiringEvent']
    | ResolversTypes['TurbotaEvent']
    | ResolversTypes['UncompletedPaymentEvent']
    | ResolversTypes['UserLeftBasketEvent']
    | ResolversTypes['VacancyBannedEvent']
    | ResolversTypes['VacancyOnWorkWillExpireInFiveDaysEvent']
  EventStateEnum: EventStateEnum
  EventTopicCompleteInput: EventTopicCompleteInput
  EventTypeEnum: EventTypeEnum
  Experience: ResolverTypeWrapper<Experience>
  ExperienceBasicQuestion: ResolverTypeWrapper<ExperienceBasicQuestion>
  ExternalCourse: ResolversTypes['PrometheusExternalCourse']
  ExternalUrlPaymentMethod: ResolverTypeWrapper<ExternalUrlPaymentMethod>
  ExternalWalletEnum: ExternalWalletEnum
  Facet: ResolverTypeWrapper<Omit<Facet, 'item'> & { item: ResolversTypes['ApplyFacetUnion'] }>
  FavoriteVacanciesRemindedDetails: ResolverTypeWrapper<FavoriteVacanciesRemindedDetails>
  FeedbackInput: FeedbackInput
  FeedbackMutations: ResolverTypeWrapper<FeedbackMutations>
  FeedbackOutput: ResolverTypeWrapper<FeedbackOutput>
  FeedbackQueries: ResolverTypeWrapper<FeedbackQueries>
  FeedbackReporterInput: FeedbackReporterInput
  FeedbackReporterTypeEnum: FeedbackReporterTypeEnum
  FeedbackTypeEnum: FeedbackTypeEnum
  FileAttach: ResolverTypeWrapper<FileAttach>
  FilterAccountsInput: FilterAccountsInput
  FilterActionsInput: FilterActionsInput
  FilterAssignmentsInput: FilterAssignmentsInput
  FilterCompaniesInput: FilterCompaniesInput
  FilterCompanyOrdersRequest: FilterCompanyOrdersRequest
  FilterGiftRequestInput: FilterGiftRequestInput
  FilterHelperCompaniesInput: FilterHelperCompaniesInput
  FilterMarketingServicesInput: FilterMarketingServicesInput
  FilterMyServicesInput: FilterMyServicesInput
  FilterNovaPoshtaRegisterListInput: FilterNovaPoshtaRegisterListInput
  FilterTeamActionsInput: FilterTeamActionsInput
  FilteredCvListInput: FilteredCvListInput
  Float: ResolverTypeWrapper<Scalars['Float']>
  ForbiddenToMutateUsersError: ResolverTypeWrapper<ForbiddenToMutateUsersError>
  GamificationDates: ResolverTypeWrapper<GamificationDates>
  GamificationWave: ResolverTypeWrapper<GamificationWave>
  GamificationWaveList: ResolverTypeWrapper<GamificationWaveList>
  Gender: Gender
  GenerateExternalPaymentUrlByInvoiceInput: GenerateExternalPaymentUrlByInvoiceInput
  GenerateExternalPaymentUrlByInvoiceOutput: ResolverTypeWrapper<GenerateExternalPaymentUrlByInvoiceOutput>
  GenerateExternalPaymentUrlInput: GenerateExternalPaymentUrlInput
  GenerateExternalPaymentUrlOutput: ResolverTypeWrapper<GenerateExternalPaymentUrlOutput>
  GetCurrentEmployerErrorUnion: ResolversTypes['InvalidError'] | ResolversTypes['NotFoundError'] | ResolversTypes['UserIsNotAuthenticatedError']
  GetCurrentEmployerOutput: ResolverTypeWrapper<Omit<GetCurrentEmployerOutput, 'errors'> & { errors: ReadonlyArray<ResolversTypes['GetCurrentEmployerErrorUnion']> }>
  GetSeekerDislikedVacanciesTotalCountOutput: ResolverTypeWrapper<GetSeekerDislikedVacanciesTotalCountOutput>
  GetSeekerFavoriteVacanciesTotalCountOutput: ResolverTypeWrapper<GetSeekerFavoriteVacanciesTotalCountOutput>
  GetVacancyByIdResponse: ResolverTypeWrapper<Omit<GetVacancyByIdResponse, 'error'> & { error: Maybe<ResolversTypes['GetVacancyError']> }>
  GetVacancyError: ResolversTypes['HasNoAccessToRequestedFieldsError'] | ResolversTypes['NotAvailableError'] | ResolversTypes['NotFoundError']
  GetVacancySearchSuggestsInput: GetVacancySearchSuggestsInput
  GetVacancySearchSuggestsOutput: ResolverTypeWrapper<GetVacancySearchSuggestsOutput>
  Gift: ResolverTypeWrapper<Gift>
  GiftCompanyWaveCounterEnum: GiftCompanyWaveCounterEnum
  GiftCompanyWaveLimit: ResolverTypeWrapper<GiftCompanyWaveLimit>
  GiftDeliveryChannelEnum: GiftDeliveryChannelEnum
  GiftDeliverySlot: ResolverTypeWrapper<GiftDeliverySlot>
  GiftDeliverySlotInput: GiftDeliverySlotInput
  GiftDeliveryTypeEnum: GiftDeliveryTypeEnum
  GiftDetailInput: GiftDetailInput
  GiftLimit: ResolverTypeWrapper<GiftLimit>
  GiftLimitsTransferHistoryByTeamLeadRequestInput: GiftLimitsTransferHistoryByTeamLeadRequestInput
  GiftOccasion: ResolverTypeWrapper<GiftOccasion>
  GiftPeriodEnum: GiftPeriodEnum
  GiftRequest: ResolverTypeWrapper<GiftRequest>
  GiftRequestConnection: ResolverTypeWrapper<GiftRequestConnection>
  GiftRequestDeliveryOptions: ResolverTypeWrapper<GiftRequestDeliveryOptions>
  GiftRequestDetail: ResolverTypeWrapper<GiftRequestDetail>
  GiftRequestEdge: ResolverTypeWrapper<GiftRequestEdge>
  GiftRequestOccasion: ResolverTypeWrapper<GiftRequestOccasion>
  GiftRequestStateEnum: GiftRequestStateEnum
  GiftSortingEnum: GiftSortingEnum
  GiftTeamLimit: ResolverTypeWrapper<GiftTeamLimit>
  GiftTeamLimitByManager: ResolverTypeWrapper<GiftTeamLimitByManager>
  GiftTeamLimitByManagerConnection: ResolverTypeWrapper<GiftTeamLimitByManagerConnection>
  GiftTeamLimitByManagerDetail: ResolverTypeWrapper<GiftTeamLimitByManagerDetail>
  GiftTeamLimitByManagerEdge: ResolverTypeWrapper<GiftTeamLimitByManagerEdge>
  GiftTeamLimitTransferHistory: ResolverTypeWrapper<GiftTeamLimitTransferHistory>
  GiftTeamLimitTransferHistoryConnection: ResolverTypeWrapper<GiftTeamLimitTransferHistoryConnection>
  GiftTeamLimitTransferHistoryEdge: ResolverTypeWrapper<GiftTeamLimitTransferHistoryEdge>
  GiftTypeEnum: GiftTypeEnum
  GiftWaveLimit: ResolverTypeWrapper<GiftWaveLimit>
  GiftsFilters: ResolverTypeWrapper<GiftsFilters>
  GiftsMutations: ResolverTypeWrapper<GiftsMutations>
  GiveAnswerInput: GiveAnswerInput
  GroupTag: ResolverTypeWrapper<GroupTag>
  Guid: ResolverTypeWrapper<Scalars['Guid']>
  HasNoAccessToRequestedFieldsError: ResolverTypeWrapper<HasNoAccessToRequestedFieldsError>
  HasNoScopesForActionError: ResolverTypeWrapper<HasNoScopesForActionError>
  HelperActivityFilterStateEnum: HelperActivityFilterStateEnum
  HelperActivityStateEnum: HelperActivityStateEnum
  HelperCompany: ResolverTypeWrapper<HelperCompany>
  HelperCompanyConnection: ResolverTypeWrapper<HelperCompanyConnection>
  HelperCompanyDetail: ResolverTypeWrapper<HelperCompanyDetail>
  HelperCompanyEdge: ResolverTypeWrapper<HelperCompanyEdge>
  HelperCompanySortEnum: HelperCompanySortEnum
  HotCatalogService: ResolverTypeWrapper<HotCatalogService>
  HotServiceEmployerEventSnapshot: ResolverTypeWrapper<HotServiceEmployerEventSnapshot>
  ID: ResolverTypeWrapper<Scalars['ID']>
  InformationBanner: ResolverTypeWrapper<InformationBanner>
  Int: ResolverTypeWrapper<Scalars['Int']>
  IntIntervalInput: IntIntervalInput
  IntRange: ResolverTypeWrapper<IntRange>
  IntRangeInput: IntRangeInput
  InvalidError: ResolverTypeWrapper<InvalidError>
  Invoice: ResolverTypeWrapper<Omit<Invoice, 'paymentMethod'> & { paymentMethod: ResolversTypes['PaymentMethods'] }>
  InvoiceAct: ResolverTypeWrapper<InvoiceAct>
  InvoiceCreateInput: InvoiceCreateInput
  InvoiceExpiredEvent: ResolverTypeWrapper<InvoiceExpiredEvent>
  InvoicePaidEvent: ResolverTypeWrapper<InvoicePaidEvent>
  InvoicePaymentBankEvent: ResolverTypeWrapper<InvoicePaymentBankEvent>
  InvoiceRequestedEvent: ResolverTypeWrapper<InvoiceRequestedEvent>
  InvoiceState: InvoiceState
  JiraServiceDeskOrganisation: ResolverTypeWrapper<JiraServiceDeskOrganisation>
  JobInstantReceivedNotificationDetails: ResolverTypeWrapper<JobInstantReceivedNotificationDetails>
  JobRecommendationReceivedDetails: ResolverTypeWrapper<JobRecommendationReceivedDetails>
  Keyword: ResolverTypeWrapper<Keyword>
  Lang: ResolverTypeWrapper<Lang>
  LangSuggest: ResolverTypeWrapper<LangSuggest>
  Language: Language
  LanguageBasicQuestion: ResolverTypeWrapper<LanguageBasicQuestion>
  LanguageLevelEnum: LanguageLevelEnum
  LanguageSkillLevel: ResolverTypeWrapper<LanguageSkillLevel>
  LegalPayerInput: LegalPayerInput
  LetterFilters: ResolverTypeWrapper<LetterFilters>
  LimitsChangeRequestedDetails: ResolverTypeWrapper<LimitsChangeRequestedDetails>
  LimitsFilters: ResolverTypeWrapper<LimitsFilters>
  ListSeekerDislikedVacanciesInput: ListSeekerDislikedVacanciesInput
  ListSeekerDislikedVacanciesOutput: ResolverTypeWrapper<ListSeekerDislikedVacanciesOutput>
  ListSeekerFavoriteVacanciesInput: ListSeekerFavoriteVacanciesInput
  ListSeekerFavoriteVacanciesOutput: ResolverTypeWrapper<ListSeekerFavoriteVacanciesOutput>
  LoginMutations: ResolverTypeWrapper<LoginMutations>
  LoginWithOtpMutations: ResolverTypeWrapper<LoginWithOtpMutations>
  LogoInPortalCatalogService: ResolverTypeWrapper<LogoInPortalCatalogService>
  LogoInPortalServiceEmployerEventSnapshot: ResolverTypeWrapper<LogoInPortalServiceEmployerEventSnapshot>
  LogoInRubricCatalogService: ResolverTypeWrapper<LogoInRubricCatalogService>
  LogoInRubricServiceEmployerEventSnapshot: ResolverTypeWrapper<LogoInRubricServiceEmployerEventSnapshot>
  LogoOnMainPageCatalogService: ResolverTypeWrapper<LogoOnMainPageCatalogService>
  LogoOnMainPageServiceEmployerEventSnapshot: ResolverTypeWrapper<LogoOnMainPageServiceEmployerEventSnapshot>
  LogoPortal: ResolverTypeWrapper<LogoPortal>
  LogoPortalBranch: ResolverTypeWrapper<LogoPortalBranch>
  Long: ResolverTypeWrapper<Scalars['Long']>
  MadeHotVacancyEmployerEvent: ResolverTypeWrapper<Omit<MadeHotVacancyEmployerEvent, 'performer'> & { performer: ResolversTypes['PerformerEmployerEventUnion'] }>
  MailingStatistics: ResolverTypeWrapper<MailingStatistics>
  Manager: ResolverTypeWrapper<Manager>
  ManagerContacts: ResolverTypeWrapper<ManagerContacts>
  ManagerDeals: ResolverTypeWrapper<ManagerDeals>
  ManagerDepartment: ResolverTypeWrapper<ManagerDepartment>
  ManagerDiscount: ResolverTypeWrapper<ManagerDiscount>
  ManagerEventPerformer: ResolverTypeWrapper<ManagerEventPerformer>
  ManagerGiftLimit: ResolverTypeWrapper<ManagerGiftLimit>
  ManagerWalletUser: ResolverTypeWrapper<ManagerWalletUser>
  MarketingCatalogService: ResolverTypeWrapper<MarketingCatalogService>
  MarketingGift: ResolverTypeWrapper<MarketingGift>
  MarketingGiftRequest: ResolverTypeWrapper<MarketingGiftRequest>
  MarketingGiftRequestConnection: ResolverTypeWrapper<MarketingGiftRequestConnection>
  MarketingGiftRequestDetail: ResolverTypeWrapper<MarketingGiftRequestDetail>
  MarketingGiftRequestEdge: ResolverTypeWrapper<MarketingGiftRequestEdge>
  MarketingLimitTransfer: ResolverTypeWrapper<MarketingLimitTransfer>
  MarketingLimitTransferConnection: ResolverTypeWrapper<MarketingLimitTransferConnection>
  MarketingLimitTransferDetail: ResolverTypeWrapper<MarketingLimitTransferDetail>
  MarketingLimitTransferEdge: ResolverTypeWrapper<MarketingLimitTransferEdge>
  MarketingServiceEmployerEventSnapshot: ResolverTypeWrapper<MarketingServiceEmployerEventSnapshot>
  MassRefuseOutput: ResolverTypeWrapper<MassRefuseOutput>
  MessagesConnection: ResolverTypeWrapper<MessagesConnection>
  MessagesEdge: ResolverTypeWrapper<MessagesEdge>
  MessengerContact: ResolverTypeWrapper<MessengerContact>
  MessengerContactInput: MessengerContactInput
  MessengerEnum: MessengerEnum
  MetanotebookAssignedEvent: ResolverTypeWrapper<MetanotebookAssignedEvent>
  Metro: ResolverTypeWrapper<Metro>
  MetroFacet: ResolverTypeWrapper<MetroFacet>
  MetroLine: ResolverTypeWrapper<MetroLine>
  MetroLineFacet: ResolverTypeWrapper<MetroLineFacet>
  MilitaryStatusEnum: MilitaryStatusEnum
  MobilePushStatistics: ResolverTypeWrapper<MobilePushStatistics>
  ModificationTypeEnum: ModificationTypeEnum
  ModifyVacancyErrorUnion:
    | ResolversTypes['CompanyWasMergedError']
    | ResolversTypes['InvalidError']
    | ResolversTypes['NoAvailableLimitsError']
    | ResolversTypes['ServerError']
    | ResolversTypes['TurbotaWasExpiredError']
    | ResolversTypes['UserIsNotAuthenticatedError']
    | ResolversTypes['UserIsNotEmployerError']
  MovingGiftLimitsDetailInput: MovingGiftLimitsDetailInput
  MovingGiftLimitsInput: MovingGiftLimitsInput
  MovingGiftLimitsOutput: ResolverTypeWrapper<MovingGiftLimitsOutput>
  MultipleVacanciesActionItem: MultipleVacanciesActionItem
  MultipleVacanciesChangeClosing: MultipleVacanciesChangeClosing
  Mutation: ResolverTypeWrapper<{}>
  MyServicesConnection: ResolverTypeWrapper<Omit<MyServicesConnection, 'items'> & { items: Maybe<ReadonlyArray<Maybe<ResolversTypes['ServiceUnited']>>> }>
  MyVacanciesByCityFilter: MyVacanciesByCityFilter
  MyVacanciesByEmployerFilter: MyVacanciesByEmployerFilter
  MyVacanciesByStatusFilter: MyVacanciesByStatusFilter
  MyVacanciesConnection: ResolverTypeWrapper<MyVacanciesConnection>
  MyVacanciesFilterInput: MyVacanciesFilterInput
  MyVacanciesSortType: MyVacanciesSortType
  NoAvailableLimitsError: ResolverTypeWrapper<NoAvailableLimitsError>
  NotAvailableError: ResolverTypeWrapper<NotAvailableError>
  NotFoundError: ResolverTypeWrapper<NotFoundError>
  NotFoundProfResume: ResolverTypeWrapper<NotFoundProfResume>
  Notification: ResolverTypeWrapper<Omit<Notification, 'details'> & { details: Maybe<ResolversTypes['NotificationDetails']> }>
  NotificationCommonVacancySnapshot: ResolverTypeWrapper<NotificationCommonVacancySnapshot>
  NotificationCommonVacancySnapshotInterface:
    | ResolversTypes['NotificationCommonVacancySnapshot']
    | ResolversTypes['NotificationEndingDaysVacancySnapshot']
    | ResolversTypes['NotificationOwnerChangedVacancySnapshot']
  NotificationDetails:
    | ResolversTypes['ApplicationInvitedNotificationDetails']
    | ResolversTypes['ApplicationRefusedNotificationDetails']
    | ResolversTypes['ApplicationSentNotificationDetails']
    | ResolversTypes['ApplicationViewedNotificationDetails']
    | ResolversTypes['CompanyStateChangedDetails']
    | ResolversTypes['CustomNotificationDetails']
    | ResolversTypes['DailyResumeViewsReportCalculated']
    | ResolversTypes['FavoriteVacanciesRemindedDetails']
    | ResolversTypes['JobInstantReceivedNotificationDetails']
    | ResolversTypes['JobRecommendationReceivedDetails']
    | ResolversTypes['LimitsChangeRequestedDetails']
    | ResolversTypes['OrderActivatedByTurbotaDetails']
    | ResolversTypes['ResumeDraftCreatedNotificationDetails']
    | ResolversTypes['ResumePublishedNotificationDetails']
    | ResolversTypes['SendNotificationSetEmailDetails']
    | ResolversTypes['UnpaidOrderDetails']
    | ResolversTypes['UserEmailHasBeenSetNotificationDetails']
    | ResolversTypes['VacancyAwaitingApprovalDetails']
    | ResolversTypes['VacancyBannedByModeratorDetails']
    | ResolversTypes['VacancyEndingCountDetails']
    | ResolversTypes['VacancyEndingLeftDaysDetails']
    | ResolversTypes['VacancyOwnerChangedDetails']
    | ResolversTypes['VacancyPublicationApprovedDetails']
    | ResolversTypes['VacancyPublicationEndedDetails']
    | ResolversTypes['VacancyPublicationRejectedDetails']
  NotificationElementButtonInput: NotificationElementButtonInput
  NotificationElementInput: NotificationElementInput
  NotificationElementTextInput: NotificationElementTextInput
  NotificationEmployeeInitiator: ResolverTypeWrapper<NotificationEmployeeInitiator>
  NotificationEmployeeSnapshot: ResolverTypeWrapper<NotificationEmployeeSnapshot>
  NotificationEndingDaysPublishPeriodSnapshot: ResolverTypeWrapper<NotificationEndingDaysPublishPeriodSnapshot>
  NotificationEndingDaysVacancySnapshot: ResolverTypeWrapper<NotificationEndingDaysVacancySnapshot>
  NotificationGroupEnum: NotificationGroupEnum
  NotificationInitiator: ResolversTypes['NotificationEmployeeInitiator'] | ResolversTypes['NotificationManagerInitiator']
  NotificationInitiatorInterface: ResolversTypes['NotificationEmployeeInitiator'] | ResolversTypes['NotificationManagerInitiator']
  NotificationManagerInitiator: ResolverTypeWrapper<NotificationManagerInitiator>
  NotificationNotFoundError: ResolverTypeWrapper<NotificationNotFoundError>
  NotificationOrderSnapshot: ResolverTypeWrapper<NotificationOrderSnapshot>
  NotificationOwnerChangedVacancySnapshot: ResolverTypeWrapper<NotificationOwnerChangedVacancySnapshot>
  NotificationVacancyEndingCountSnapshot: ResolverTypeWrapper<NotificationVacancyEndingCountSnapshot>
  NotificationsConnection: ResolverTypeWrapper<NotificationsConnection>
  NotificationsEdge: ResolverTypeWrapper<NotificationsEdge>
  NotificationsError: ResolversTypes['NotificationNotFoundError'] | ResolversTypes['UnknownServerError']
  NovaPoshtaAddCounterpartyOutput: ResolverTypeWrapper<NovaPoshtaAddCounterpartyOutput>
  NovaPoshtaAddDocumentInput: NovaPoshtaAddDocumentInput
  NovaPoshtaAddDocumentOutput: ResolverTypeWrapper<NovaPoshtaAddDocumentOutput>
  NovaPoshtaAddDocumentToRegisterDetailInput: NovaPoshtaAddDocumentToRegisterDetailInput
  NovaPoshtaAddDocumentToRegisterInput: NovaPoshtaAddDocumentToRegisterInput
  NovaPoshtaAddDocumentToRegisterOutput: ResolverTypeWrapper<NovaPoshtaAddDocumentToRegisterOutput>
  NovaPoshtaAddGiftAddressOutput: ResolverTypeWrapper<NovaPoshtaAddGiftAddressOutput>
  NovaPoshtaAddressTypeEnum: NovaPoshtaAddressTypeEnum
  NovaPoshtaCity: ResolverTypeWrapper<NovaPoshtaCity>
  NovaPoshtaCounterpartyGraphType: ResolverTypeWrapper<NovaPoshtaCounterpartyGraphType>
  NovaPoshtaDeleteDocumentOutput: ResolverTypeWrapper<NovaPoshtaDeleteDocumentOutput>
  NovaPoshtaDocument: ResolverTypeWrapper<NovaPoshtaDocument>
  NovaPoshtaDoorsAddressGraphType: ResolverTypeWrapper<NovaPoshtaDoorsAddressGraphType>
  NovaPoshtaFullAddressGraphType: ResolverTypeWrapper<NovaPoshtaFullAddressGraphType>
  NovaPoshtaNewAddedDocument: ResolverTypeWrapper<NovaPoshtaNewAddedDocument>
  NovaPoshtaOptionSeat: ResolverTypeWrapper<NovaPoshtaOptionSeat>
  NovaPoshtaRegisterDocument: ResolverTypeWrapper<NovaPoshtaRegisterDocument>
  NovaPoshtaRegisterList: ResolverTypeWrapper<NovaPoshtaRegisterList>
  NovaPoshtaRegisterListConnection: ResolverTypeWrapper<NovaPoshtaRegisterListConnection>
  NovaPoshtaRegisterListEdge: ResolverTypeWrapper<NovaPoshtaRegisterListEdge>
  NovaPoshtaSeatInput: NovaPoshtaSeatInput
  NovaPoshtaServiceTypeEnum: NovaPoshtaServiceTypeEnum
  NovaPoshtaStateEnum: NovaPoshtaStateEnum
  NovaPoshtaStreet: ResolverTypeWrapper<NovaPoshtaStreet>
  NovaPoshtaUpdateDocumentInput: NovaPoshtaUpdateDocumentInput
  NovaPoshtaWarehouse: ResolverTypeWrapper<NovaPoshtaWarehouse>
  NovaPoshtaWarehouseAddressGraphType: ResolverTypeWrapper<NovaPoshtaWarehouseAddressGraphType>
  NovaPosthaCounterpartyTypesGraphEnum: NovaPosthaCounterpartyTypesGraphEnum
  Objection: ResolverTypeWrapper<Objection>
  OfferVacancyToApplyInput: OfferVacancyToApplyInput
  OfferVacancyToApplyOutput: ResolverTypeWrapper<OfferVacancyToApplyOutput>
  OfferedVacancyCvDbEmployerEvent: ResolverTypeWrapper<Omit<OfferedVacancyCvDbEmployerEvent, 'performer'> & { performer: ResolversTypes['PerformerEmployerEventUnion'] }>
  OpenedContactOrVacancyOfferedEmployerAchievement: ResolverTypeWrapper<OpenedContactOrVacancyOfferedEmployerAchievement>
  OpenedContactsCvDbEmployerEvent: ResolverTypeWrapper<Omit<OpenedContactsCvDbEmployerEvent, 'performer'> & { performer: ResolversTypes['PerformerEmployerEventUnion'] }>
  Order: ResolverTypeWrapper<Omit<Order, 'discounts' | 'paymentMethod'> & { discounts: ReadonlyArray<ResolversTypes['Discount']>; paymentMethod: ResolversTypes['PaymentMethods'] }>
  OrderActivatedByTurbotaDetails: ResolverTypeWrapper<OrderActivatedByTurbotaDetails>
  OrderAlreadyActivatedError: ResolverTypeWrapper<OrderAlreadyActivatedError>
  OrderCanNotBeActivatedByTurbotaError: ResolverTypeWrapper<OrderCanNotBeActivatedByTurbotaError>
  OrderCartMutation: ResolverTypeWrapper<OrderCartMutation>
  OrderConnection: ResolverTypeWrapper<OrderConnection>
  OrderEdge: ResolverTypeWrapper<OrderEdge>
  OrderFreemiumServicesAccruedServiceEmployerEvent: ResolverTypeWrapper<OrderFreemiumServicesAccruedServiceEmployerEvent>
  OrderPaymentState: OrderPaymentState
  OrderPosition: ResolverTypeWrapper<Omit<OrderPosition, 'details'> & { details: ResolversTypes['OrderPositionServiceDetailsUnion'] }>
  OrderPositionAdditionalServiceDetails: ResolverTypeWrapper<OrderPositionAdditionalServiceDetails>
  OrderPositionCvDbServiceDetails: ResolverTypeWrapper<OrderPositionCvDbServiceDetails>
  OrderPositionHotServiceDetails: ResolverTypeWrapper<OrderPositionHotServiceDetails>
  OrderPositionLogoInPortalServiceDetails: ResolverTypeWrapper<OrderPositionLogoInPortalServiceDetails>
  OrderPositionLogoInRubricServiceDetails: ResolverTypeWrapper<OrderPositionLogoInRubricServiceDetails>
  OrderPositionLogoOnMainServiceDetails: ResolverTypeWrapper<OrderPositionLogoOnMainServiceDetails>
  OrderPositionMarketingServiceDetails: ResolverTypeWrapper<OrderPositionMarketingServiceDetails>
  OrderPositionRisingUpServiceDetails: ResolverTypeWrapper<OrderPositionRisingUpServiceDetails>
  OrderPositionServiceDetailsUnion:
    | ResolversTypes['OrderPositionAdditionalServiceDetails']
    | ResolversTypes['OrderPositionCvDbServiceDetails']
    | ResolversTypes['OrderPositionHotServiceDetails']
    | ResolversTypes['OrderPositionLogoInPortalServiceDetails']
    | ResolversTypes['OrderPositionLogoInRubricServiceDetails']
    | ResolversTypes['OrderPositionLogoOnMainServiceDetails']
    | ResolversTypes['OrderPositionMarketingServiceDetails']
    | ResolversTypes['OrderPositionRisingUpServiceDetails']
    | ResolversTypes['OrderPositionVacancyPackageServiceDetails']
    | ResolversTypes['OrderPositionVacancyPublicationServiceDetails']
  OrderPositionVacancyPackageServiceDetails: ResolverTypeWrapper<OrderPositionVacancyPackageServiceDetails>
  OrderPositionVacancyPublicationServiceDetails: ResolverTypeWrapper<OrderPositionVacancyPublicationServiceDetails>
  OrderServicesAccruedServiceEmployerEvent: ResolverTypeWrapper<OrderServicesAccruedServiceEmployerEvent>
  OrderState: OrderState
  OrderTurbotaActivationError: ResolversTypes['OrderAlreadyActivatedError'] | ResolversTypes['OrderCanNotBeActivatedByTurbotaError'] | ResolversTypes['OrderWasActivatedByTurbotaError']
  OrderTurbotaActivationInput: OrderTurbotaActivationInput
  OrderTurbotaActivationOutput: ResolverTypeWrapper<Omit<OrderTurbotaActivationOutput, 'error'> & { error: Maybe<ResolversTypes['OrderTurbotaActivationError']> }>
  OrderType: OrderType
  OrderWasActivatedByTurbotaError: ResolverTypeWrapper<OrderWasActivatedByTurbotaError>
  OrderedAdditionalService: ResolverTypeWrapper<OrderedAdditionalService>
  OrderedCvDbService: ResolverTypeWrapper<OrderedCvDbService>
  OrderedHotService: ResolverTypeWrapper<OrderedHotService>
  OrderedLogoInPortalService: ResolverTypeWrapper<OrderedLogoInPortalService>
  OrderedLogoInRubricService: ResolverTypeWrapper<OrderedLogoInRubricService>
  OrderedMarketingService: ResolverTypeWrapper<OrderedMarketingService>
  OrderedRisingUpService: ResolverTypeWrapper<OrderedRisingUpService>
  OrderedVacancyPackageService: ResolverTypeWrapper<Omit<OrderedVacancyPackageService, 'additionalServices'> & { additionalServices: ReadonlyArray<Maybe<ResolversTypes['ServiceUnited']>> }>
  OrderedVacancyPublicationService: ResolverTypeWrapper<OrderedVacancyPublicationService>
  OtherSiteEnum: OtherSiteEnum
  OtherSiteLink: ResolverTypeWrapper<OtherSiteLink>
  OwnerChangedVacancyEmployerEvent: ResolverTypeWrapper<Omit<OwnerChangedVacancyEmployerEvent, 'performer'> & { performer: ResolversTypes['PerformerEmployerEventUnion'] }>
  PackageServiceEmployerEventSnapshot: ResolverTypeWrapper<PackageServiceEmployerEventSnapshot>
  PageInfo: ResolverTypeWrapper<PageInfo>
  PaidOrderService: ResolverTypeWrapper<PaidOrderService>
  ParsedCv: ResolverTypeWrapper<ParsedCv>
  ParsedCvComment: ResolverTypeWrapper<ParsedCvComment>
  ParsedCvConnection: ResolverTypeWrapper<ParsedCvConnection>
  ParsedCvLabel: ResolverTypeWrapper<ParsedCvLabel>
  ParsedCvLabelInput: ParsedCvLabelInput
  ParsedCvRubric: ResolverTypeWrapper<ParsedCvRubric>
  ParsedCvStateEnum: ParsedCvStateEnum
  PasswordConfirmationFailedError: ResolverTypeWrapper<PasswordConfirmationFailedError>
  PayWithCardErrorUnion: ResolversTypes['InvalidError'] | ResolversTypes['ServerError'] | ResolversTypes['UserIsNotAuthenticatedError']
  PayWithCardResponse: ResolverTypeWrapper<Omit<PayWithCardResponse, 'errors'> & { errors: ReadonlyArray<ResolversTypes['PayWithCardErrorUnion']> }>
  PayWithExternalWalletErrorUnion: ResolversTypes['InvalidError'] | ResolversTypes['ServerError'] | ResolversTypes['UserIsNotAuthenticatedError']
  PayWithExternalWalletResponse: ResolverTypeWrapper<Omit<PayWithExternalWalletResponse, 'errors'> & { errors: ReadonlyArray<ResolversTypes['PayWithExternalWalletErrorUnion']> }>
  Payer: ResolverTypeWrapper<Payer>
  PayerEditInput: PayerEditInput
  PayerPassport: ResolverTypeWrapper<PayerPassport>
  PayerPassportInput: PayerPassportInput
  PayerResponsibleEnum: PayerResponsibleEnum
  PaymentMethods: ResolversTypes['ExternalUrlPaymentMethod'] | ResolversTypes['WidgetPaymentMethod']
  PaymentReceivedServiceEmployerEvent: ResolverTypeWrapper<Omit<PaymentReceivedServiceEmployerEvent, 'performer'> & { performer: ResolversTypes['PerformerEmployerEventUnion'] }>
  PaymentReceivedTypeEmployerEventEnum: PaymentReceivedTypeEmployerEventEnum
  PaymentSystemTypeEnum: PaymentSystemTypeEnum
  PerformerEmployerEventUnion: ResolversTypes['EmployerEventPerformer'] | ResolversTypes['ManagerEventPerformer'] | ResolversTypes['SystemEventPerformer']
  Period: ResolverTypeWrapper<Period>
  PeriodAmount: ResolverTypeWrapper<PeriodAmount>
  PeriodType: PeriodType
  PersonalDiscountEvent: ResolverTypeWrapper<PersonalDiscountEvent>
  PhoneConfirmationOutput: ResolverTypeWrapper<PhoneConfirmationOutput>
  PhoneConfirmationStatusEnum: PhoneConfirmationStatusEnum
  PhoneNumberConfirmedEmployerAchievement: ResolverTypeWrapper<PhoneNumberConfirmedEmployerAchievement>
  PriceQueryOutput: ResolverTypeWrapper<PriceQueryOutput>
  ProfResume: ResolverTypeWrapper<ProfResume>
  ProfResumeAddAdditionalEducationsInput: ProfResumeAddAdditionalEducationsInput
  ProfResumeAddAdditionalInfoInput: ProfResumeAddAdditionalInfoInput
  ProfResumeAddEducationsInput: ProfResumeAddEducationsInput
  ProfResumeAddExperiencesInput: ProfResumeAddExperiencesInput
  ProfResumeAdditionalEducationInput: ProfResumeAdditionalEducationInput
  ProfResumeAdditionalInfoInput: ProfResumeAdditionalInfoInput
  ProfResumeAdditionalInfosInput: ProfResumeAdditionalInfosInput
  ProfResumeBirthDateInput: ProfResumeBirthDateInput
  ProfResumeCannotBePublished: ResolverTypeWrapper<ProfResumeCannotBePublished>
  ProfResumeCityInput: ProfResumeCityInput
  ProfResumeContactInfoInput: ProfResumeContactInfoInput
  ProfResumeDeleteAdditionalEducationsInput: ProfResumeDeleteAdditionalEducationsInput
  ProfResumeDeleteAdditionalInfoInput: ProfResumeDeleteAdditionalInfoInput
  ProfResumeDeleteAdditionalPhonesInput: ProfResumeDeleteAdditionalPhonesInput
  ProfResumeDeleteDiiaCertificateInput: ProfResumeDeleteDiiaCertificateInput
  ProfResumeDeleteDiiaCertificateOutput: ResolverTypeWrapper<
    Omit<ProfResumeDeleteDiiaCertificateOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['DeleteSeekerProfResumeDiiaCertificateError']>> }
  >
  ProfResumeDeleteEducationsInput: ProfResumeDeleteEducationsInput
  ProfResumeDeleteLanguageSkillsInput: ProfResumeDeleteLanguageSkillsInput
  ProfResumeDeletePortfolioInput: ProfResumeDeletePortfolioInput
  ProfResumeDeleteSocialNetworksInput: ProfResumeDeleteSocialNetworksInput
  ProfResumeDiiaCertificateInput: ProfResumeDiiaCertificateInput
  ProfResumeDisabilityInput: ProfResumeDisabilityInput
  ProfResumeDoesNotBelongToSeeker: ResolverTypeWrapper<ProfResumeDoesNotBelongToSeeker>
  ProfResumeDoesNotExist: ResolverTypeWrapper<ProfResumeDoesNotExist>
  ProfResumeDuplicated: ResolverTypeWrapper<ProfResumeDuplicated>
  ProfResumeEducationInput: ProfResumeEducationInput
  ProfResumeExperienceInput: ProfResumeExperienceInput
  ProfResumeExperiencesDeleteInput: ProfResumeExperiencesDeleteInput
  ProfResumeFullNameInput: ProfResumeFullNameInput
  ProfResumeGenderInput: ProfResumeGenderInput
  ProfResumeInput: ProfResumeInput
  ProfResumeLanguageSkillInput: ProfResumeLanguageSkillInput
  ProfResumeLimit: ResolverTypeWrapper<ProfResumeLimit>
  ProfResumeMilitaryStatusInput: ProfResumeMilitaryStatusInput
  ProfResumePersonalInfoInput: ProfResumePersonalInfoInput
  ProfResumePhoneConfirmationFailed: ResolverTypeWrapper<ProfResumePhoneConfirmationFailed>
  ProfResumePhotoInput: ProfResumePhotoInput
  ProfResumePositionInput: ProfResumePositionInput
  ProfResumeResponseUnion: ResolversTypes['NotFoundProfResume'] | ResolversTypes['ProfResume']
  ProfResumeSalaryInput: ProfResumeSalaryInput
  ProfResumeScheduleInput: ProfResumeScheduleInput
  ProfResumeSkillsInput: ProfResumeSkillsInput
  ProfResumeSocialNetworkInput: ProfResumeSocialNetworkInput
  ProfResumeUpdateAdditionalEducationInput: ProfResumeUpdateAdditionalEducationInput
  ProfResumeUpdateAdditionalInfoInput: ProfResumeUpdateAdditionalInfoInput
  ProfResumeUpdateAdditionalPhonesInput: ProfResumeUpdateAdditionalPhonesInput
  ProfResumeUpdateAnonymityInput: ProfResumeUpdateAnonymityInput
  ProfResumeUpdateAvailabilityStateInput: ProfResumeUpdateAvailabilityStateInput
  ProfResumeUpdateEducationInput: ProfResumeUpdateEducationInput
  ProfResumeUpdateEmailInput: ProfResumeUpdateEmailInput
  ProfResumeUpdateExperiencesInput: ProfResumeUpdateExperiencesInput
  ProfResumeUpdateHiddenCompaniesInput: ProfResumeUpdateHiddenCompaniesInput
  ProfResumeUpdateLanguageSkillsInput: ProfResumeUpdateLanguageSkillsInput
  ProfResumeUpdatePhoneInput: ProfResumeUpdatePhoneInput
  ProfResumeUpdatePortfoliosInput: ProfResumeUpdatePortfoliosInput
  ProfResumeUpdateSalaryInput: ProfResumeUpdateSalaryInput
  ProfResumeUpdateSearchStateInput: ProfResumeUpdateSearchStateInput
  ProfResumeUpdateSocialNetworksInput: ProfResumeUpdateSocialNetworksInput
  ProfResumeUpdateStateInput: ProfResumeUpdateStateInput
  ProfResumeUpdateStateOutput: ResolverTypeWrapper<Omit<ProfResumeUpdateStateOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeStateError']>> }>
  ProfileRegisteredEvent: ResolverTypeWrapper<ProfileRegisteredEvent>
  ProlongationRequestVacancyEmployerEvent: ResolverTypeWrapper<Omit<ProlongationRequestVacancyEmployerEvent, 'performer'> & { performer: ResolversTypes['PerformerEmployerEventUnion'] }>
  PrometheusExternalCourse: ResolverTypeWrapper<PrometheusExternalCourse>
  PromoCode: ResolverTypeWrapper<Omit<PromoCode, 'target'> & { target: ResolversTypes['PromoCodeTragetUnion'] }>
  PromoCodeActivator: ResolverTypeWrapper<PromoCodeActivator>
  PromoCodeCartMutation: ResolverTypeWrapper<PromoCodeCartMutation>
  PromoCodeConnection: ResolverTypeWrapper<PromoCodeConnection>
  PromoCodeDateRangeInput: PromoCodeDateRangeInput
  PromoCodeDiscount: ResolverTypeWrapper<PromoCodeDiscount>
  PromoCodeEdge: ResolverTypeWrapper<PromoCodeEdge>
  PromoCodeFilterInput: PromoCodeFilterInput
  PromoCodeMutation: ResolverTypeWrapper<PromoCodeMutation>
  PromoCodeSortEnum: PromoCodeSortEnum
  PromoCodeStatusEnum: PromoCodeStatusEnum
  PromoCodeTargetBonusSpecialServices: ResolverTypeWrapper<PromoCodeTargetBonusSpecialServices>
  PromoCodeTargetSpecialService: ResolverTypeWrapper<PromoCodeTargetSpecialService>
  PromoCodeTargetSpecialServiceType: ResolverTypeWrapper<PromoCodeTargetSpecialServiceType>
  PromoCodeTargetWholeOrder: ResolverTypeWrapper<PromoCodeTargetWholeOrder>
  PromoCodeTragetUnion:
    | ResolversTypes['PromoCodeTargetBonusSpecialServices']
    | ResolversTypes['PromoCodeTargetSpecialService']
    | ResolversTypes['PromoCodeTargetSpecialServiceType']
    | ResolversTypes['PromoCodeTargetWholeOrder']
  PromoCodeTypeEnum: PromoCodeTypeEnum
  PromoCodeTypeWrapper: ResolverTypeWrapper<PromoCodeTypeWrapper>
  ProzoraClientDateTimeInterval: ResolverTypeWrapper<ProzoraClientDateTimeInterval>
  ProzoraClientFilters: ResolverTypeWrapper<ProzoraClientFilters>
  ProzoraClientFiltersInput: ProzoraClientFiltersInput
  PublicClubPostsCursorPaginationInput: PublicClubPostsCursorPaginationInput
  PublicClubPostsFilterInput: PublicClubPostsFilterInput
  PublicClubPostsOutput: ResolverTypeWrapper<PublicClubPostsOutput>
  PublicClubPostsSortingEnum: PublicClubPostsSortingEnum
  PublicClubTopicEnameEnum: PublicClubTopicEnameEnum
  PublicationServiceEmployerEventSnapshot: ResolverTypeWrapper<PublicationServiceEmployerEventSnapshot>
  PublishProfResumeInput: PublishProfResumeInput
  PublishSeekerProfResumeError: ResolversTypes['ProfResumeCannotBePublished'] | ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  PublishedProfResumeOutput: ResolverTypeWrapper<Omit<PublishedProfResumeOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['PublishSeekerProfResumeError']>> }>
  PublishedResumeCounterPeriodEnum: PublishedResumeCounterPeriodEnum
  PublishedResumesCounter: ResolverTypeWrapper<PublishedResumesCounter>
  PublishedVacancies: ResolverTypeWrapper<PublishedVacancies>
  PublishedVacanciesClientCluster: ResolverTypeWrapper<PublishedVacanciesClientCluster>
  PublishedVacanciesClientClusterInput: PublishedVacanciesClientClusterInput
  PublishedVacanciesClientFilter: ResolverTypeWrapper<PublishedVacanciesClientFilter>
  PublishedVacanciesClientFilterInput: PublishedVacanciesClientFilterInput
  PublishedVacanciesClientMetroBranch: ResolverTypeWrapper<PublishedVacanciesClientMetroBranch>
  PublishedVacanciesClientRubric: ResolverTypeWrapper<PublishedVacanciesClientRubric>
  PublishedVacanciesFacets: ResolverTypeWrapper<PublishedVacanciesFacets>
  PublishedVacanciesFilterInput: PublishedVacanciesFilterInput
  PublishedVacanciesFilterPeriod: PublishedVacanciesFilterPeriod
  PublishedVacanciesLocation: ResolverTypeWrapper<PublishedVacanciesLocation>
  PublishedVacanciesLocationInput: PublishedVacanciesLocationInput
  PublishedVacanciesMetroBranchInput: PublishedVacanciesMetroBranchInput
  PublishedVacanciesPaginationInput: PublishedVacanciesPaginationInput
  PublishedVacanciesQueryFilter: ResolverTypeWrapper<PublishedVacanciesQueryFilter>
  PublishedVacanciesRubricInput: PublishedVacanciesRubricInput
  PublishedVacanciesSchedule: PublishedVacanciesSchedule
  PublishedVacanciesSearchedTypeEnum: PublishedVacanciesSearchedTypeEnum
  PublishedVacanciesSortType: PublishedVacanciesSortType
  PublishedVacancyBadge: ResolverTypeWrapper<PublishedVacancyBadge>
  PublishedVacancyEmployerEvent: ResolverTypeWrapper<Omit<PublishedVacancyEmployerEvent, 'performer'> & { performer: ResolversTypes['PerformerEmployerEventUnion'] }>
  Quarter: ResolverTypeWrapper<Quarter>
  Query: ResolverTypeWrapper<{}>
  QuestionTemplatesFilterInput: QuestionTemplatesFilterInput
  Questionnaire: ResolverTypeWrapper<Omit<Questionnaire, 'questions'> & { questions: ReadonlyArray<ResolversTypes['QuestionnaireItem']> }>
  QuestionnaireAnswer: ResolverTypeWrapper<Omit<QuestionnaireAnswer, 'questionSnapshot'> & { questionSnapshot: ResolversTypes['QuestionnaireItem'] }>
  QuestionnaireAnswersSummary: ResolverTypeWrapper<QuestionnaireAnswersSummary>
  QuestionnaireConnection: ResolverTypeWrapper<QuestionnaireConnection>
  QuestionnaireCopyInput: QuestionnaireCopyInput
  QuestionnaireCopyOutput: ResolverTypeWrapper<QuestionnaireCopyOutput>
  QuestionnaireCreateInput: QuestionnaireCreateInput
  QuestionnaireCreateOutput: ResolverTypeWrapper<QuestionnaireCreateOutput>
  QuestionnaireDeleteOutput: ResolverTypeWrapper<QuestionnaireDeleteOutput>
  QuestionnaireEdge: ResolverTypeWrapper<QuestionnaireEdge>
  QuestionnaireEssayItem: ResolverTypeWrapper<QuestionnaireEssayItem>
  QuestionnaireExperienceItem: ResolverTypeWrapper<QuestionnaireExperienceItem>
  QuestionnaireExperienceItemSuggest: ResolverTypeWrapper<QuestionnaireExperienceItemSuggest>
  QuestionnaireGiveAnswersInput: QuestionnaireGiveAnswersInput
  QuestionnaireGiveAnswersOutput: ResolverTypeWrapper<QuestionnaireGiveAnswersOutput>
  QuestionnaireItem: ResolversTypes['QuestionnaireEssayItem'] | ResolversTypes['QuestionnaireExperienceItem'] | ResolversTypes['QuestionnaireLanguageItem'] | ResolversTypes['QuestionnaireSelectItem']
  QuestionnaireItemInput: QuestionnaireItemInput
  QuestionnaireItemSuggest: ResolversTypes['QuestionnaireExperienceItemSuggest'] | ResolversTypes['QuestionnaireLanguageItemSuggest'] | ResolversTypes['QuestionnaireSelectItemSuggest']
  QuestionnaireItemTypeEnum: QuestionnaireItemTypeEnum
  QuestionnaireLangEnum: QuestionnaireLangEnum
  QuestionnaireLangSuggestEnum: QuestionnaireLangSuggestEnum
  QuestionnaireLanguageItem: ResolverTypeWrapper<QuestionnaireLanguageItem>
  QuestionnaireLanguageItemSuggest: ResolverTypeWrapper<QuestionnaireLanguageItemSuggest>
  QuestionnaireSelectItem: ResolverTypeWrapper<QuestionnaireSelectItem>
  QuestionnaireSelectItemSuggest: ResolverTypeWrapper<QuestionnaireSelectItemSuggest>
  QuestionnaireSuggest: ResolverTypeWrapper<Omit<QuestionnaireSuggest, 'questions'> & { questions: ReadonlyArray<ResolversTypes['QuestionnaireItemSuggest']> }>
  QuestionnaireUpdateInput: QuestionnaireUpdateInput
  QuestionnaireUpdateOutput: ResolverTypeWrapper<QuestionnaireUpdateOutput>
  QuestionnairesFilterInput: QuestionnairesFilterInput
  RePublishedVacancyEmployerEvent: ResolverTypeWrapper<Omit<RePublishedVacancyEmployerEvent, 'performer'> & { performer: ResolversTypes['PerformerEmployerEventUnion'] }>
  RecommendationTypeEnum: RecommendationTypeEnum
  RecommendedCandidate: ResolverTypeWrapper<Omit<RecommendedCandidate, 'resume'> & { resume: ResolversTypes['ProfResumeResponseUnion'] }>
  RecommendedCandidatesConnection: ResolverTypeWrapper<RecommendedCandidatesConnection>
  RecommendedCandidatesEdge: ResolverTypeWrapper<RecommendedCandidatesEdge>
  RecommendedCandidatesFilter: RecommendedCandidatesFilter
  RecommendedProfResume: ResolverTypeWrapper<RecommendedProfResume>
  RecommendedProfResumeExperience: ResolverTypeWrapper<RecommendedProfResumeExperience>
  RecommendedProfResumeSalary: ResolverTypeWrapper<RecommendedProfResumeSalary>
  RecommendedProfResumeType: RecommendedProfResumeType
  RecommendedProfResumes: ResolverTypeWrapper<RecommendedProfResumes>
  RecommendedProfResumesInput: RecommendedProfResumesInput
  RecommendedVacanciesConnection: ResolverTypeWrapper<RecommendedVacanciesConnection>
  RecommendedVacanciesEdge: ResolverTypeWrapper<RecommendedVacanciesEdge>
  RecruitmentOrderOutput: ResolverTypeWrapper<RecruitmentOrderOutput>
  RegularGift: ResolverTypeWrapper<RegularGift>
  RejectPaymentInput: RejectPaymentInput
  RejectPaymentReasonEnum: RejectPaymentReasonEnum
  RejectedByModeratorVacancyEmployerEvent: ResolverTypeWrapper<RejectedByModeratorVacancyEmployerEvent>
  RejectedProlongationRequestVacancyEmployerEvent: ResolverTypeWrapper<
    Omit<RejectedProlongationRequestVacancyEmployerEvent, 'performer'> & { performer: ResolversTypes['PerformerEmployerEventUnion'] }
  >
  RejectedReviewVacancyEmployerEvent: ResolverTypeWrapper<Omit<RejectedReviewVacancyEmployerEvent, 'performer'> & { performer: ResolversTypes['PerformerEmployerEventUnion'] }>
  RelevanceScore: ResolverTypeWrapper<RelevanceScore>
  RemoveSeekerFavoriteVacanciesInput: RemoveSeekerFavoriteVacanciesInput
  RemoveSeekerFavoriteVacanciesOutput: ResolverTypeWrapper<RemoveSeekerFavoriteVacanciesOutput>
  ResumeActiveStateEnum: ResumeActiveStateEnum
  ResumeAdditionalEducation: ResolverTypeWrapper<ResumeAdditionalEducation>
  ResumeAdditionalInfo: ResolverTypeWrapper<ResumeAdditionalInfo>
  ResumeAvailabilityStateEnum: ResumeAvailabilityStateEnum
  ResumeContactInfo: ResolverTypeWrapper<ResumeContactInfo>
  ResumeDraftCreatedNotificationDetails: ResolverTypeWrapper<ResumeDraftCreatedNotificationDetails>
  ResumeEducation: ResolverTypeWrapper<ResumeEducation>
  ResumeError:
    | ResolversTypes['AttachNotFound']
    | ResolversTypes['AttachParsingError']
    | ResolversTypes['ProfResumeCannotBePublished']
    | ResolversTypes['ProfResumeDoesNotBelongToSeeker']
    | ResolversTypes['ProfResumeDoesNotExist']
    | ResolversTypes['ProfResumeDuplicated']
    | ResolversTypes['ProfResumeLimit']
    | ResolversTypes['ProfResumePhoneConfirmationFailed']
  ResumeExperience: ResolverTypeWrapper<ResumeExperience>
  ResumeFilling: ResolverTypeWrapper<ResumeFilling>
  ResumeFillingType: ResolverTypeWrapper<ResumeFillingType>
  ResumeLanguageSkill: ResolverTypeWrapper<ResumeLanguageSkill>
  ResumePersonalInfo: ResolverTypeWrapper<ResumePersonalInfo>
  ResumePhone: ResolverTypeWrapper<ResumePhone>
  ResumePublishedNotificationDetails: ResolverTypeWrapper<ResumePublishedNotificationDetails>
  ResumeReportInput: ResumeReportInput
  ResumeReportOutput: ResolverTypeWrapper<ResumeReportOutput>
  ResumeSalary: ResolverTypeWrapper<ResumeSalary>
  ResumeSearchStateEnum: ResumeSearchStateEnum
  ResumeSearchSuggest: ResolverTypeWrapper<ResumeSearchSuggest>
  ResumeSocialContactLink: ResolverTypeWrapper<ResumeSocialContactLink>
  ResumeState: ResolverTypeWrapper<ResumeState>
  ResumeViewInfo: ResolverTypeWrapper<ResumeViewInfo>
  ResumeViewsConnection: ResolverTypeWrapper<ResumeViewsConnection>
  ResumeViewsEdge: ResolverTypeWrapper<ResumeViewsEdge>
  RisingUpCatalogService: ResolverTypeWrapper<RisingUpCatalogService>
  RolesEnum: RolesEnum
  Rubric: ResolverTypeWrapper<Rubric>
  RubricFacet: ResolverTypeWrapper<RubricFacet>
  SalaryStatistics: ResolverTypeWrapper<SalaryStatistics>
  SaleManagerMutations: ResolverTypeWrapper<SaleManagerMutations>
  SaleManagerQueries: ResolverTypeWrapper<SaleManagerQueries>
  SaleManagerResponseOutput: ResolverTypeWrapper<SaleManagerResponseOutput>
  SaveVacancyInterviewQuestionsErrorUnion: ResolversTypes['InvalidError'] | ResolversTypes['NotFoundError']
  SaveVacancyInterviewQuestionsInput: SaveVacancyInterviewQuestionsInput
  SaveVacancyInterviewQuestionsOutput: ResolverTypeWrapper<Omit<SaveVacancyInterviewQuestionsOutput, 'errors'> & { errors: ReadonlyArray<ResolversTypes['SaveVacancyInterviewQuestionsErrorUnion']> }>
  SavedCard: ResolverTypeWrapper<SavedCard>
  SavedCardsMutations: ResolverTypeWrapper<SavedCardsMutations>
  Schedule: ResolverTypeWrapper<Schedule>
  ScheduleEnum: ScheduleEnum
  ScheduleFacet: ResolverTypeWrapper<ScheduleFacet>
  ScrollInfo: ResolverTypeWrapper<ScrollInfo>
  SeekerApplication: ResolverTypeWrapper<SeekerApplication>
  SeekerAppliesInput: SeekerAppliesInput
  SeekerAppliesPaginationInput: SeekerAppliesPaginationInput
  SeekerApplyQueries: ResolverTypeWrapper<SeekerApplyQueries>
  SeekerDislikedVacanciesPaginationInput: SeekerDislikedVacanciesPaginationInput
  SeekerDislikedVacancyDetails: ResolverTypeWrapper<SeekerDislikedVacancyDetails>
  SeekerDislikedVacancyMutations: ResolverTypeWrapper<SeekerDislikedVacancyMutations>
  SeekerDislikedVacancyQueries: ResolverTypeWrapper<SeekerDislikedVacancyQueries>
  SeekerFavoriteVacanciesPaginationInput: SeekerFavoriteVacanciesPaginationInput
  SeekerFavoriteVacancyDetails: ResolverTypeWrapper<SeekerFavoriteVacancyDetails>
  SeekerFavoriteVacancyMutations: ResolverTypeWrapper<SeekerFavoriteVacancyMutations>
  SeekerFavoriteVacancyQueries: ResolverTypeWrapper<SeekerFavoriteVacancyQueries>
  SeekerMutations: ResolverTypeWrapper<SeekerMutations>
  SeekerNotificationDetailTypeEnum: SeekerNotificationDetailTypeEnum
  SeekerRecommendationProfile: ResolverTypeWrapper<SeekerRecommendationProfile>
  SeekerRegistration: ResolverTypeWrapper<SeekerRegistration>
  SeekerSubscription: ResolverTypeWrapper<Omit<SeekerSubscription, 'profile'> & { profile: Maybe<ResolversTypes['SeekerSubscriptionProfile']> }>
  SeekerSubscriptionActivation: ResolverTypeWrapper<SeekerSubscriptionActivation>
  SeekerSubscriptionChannel: ResolverTypeWrapper<SeekerSubscriptionChannel>
  SeekerSubscriptionManualProfile: ResolverTypeWrapper<SeekerSubscriptionManualProfile>
  SeekerSubscriptionManualProfileInput: SeekerSubscriptionManualProfileInput
  SeekerSubscriptionMutations: ResolverTypeWrapper<SeekerSubscriptionMutations>
  SeekerSubscriptionProfile: ResolversTypes['SeekerRecommendationProfile'] | ResolversTypes['SeekerSubscriptionManualProfile']
  SeekerSubscriptionSummary: ResolverTypeWrapper<SeekerSubscriptionSummary>
  SeekerSubscriptionsFilterInput: SeekerSubscriptionsFilterInput
  SeekerWalletUser: ResolverTypeWrapper<SeekerWalletUser>
  SegmentationMechanicsEnum: SegmentationMechanicsEnum
  SelectPromoCodeErrorEnum: SelectPromoCodeErrorEnum
  SelectPromoCodeResponse: ResolverTypeWrapper<SelectPromoCodeResponse>
  SendChangeSeekerEmailOutput: ResolverTypeWrapper<SendChangeSeekerEmailOutput>
  SendChangeSeekerEmailStatusEnum: SendChangeSeekerEmailStatusEnum
  SendConfirmCodeEmailInput: SendConfirmCodeEmailInput
  SendEmailOutput: ResolverTypeWrapper<SendEmailOutput>
  SendEmailStatusEnum: SendEmailStatusEnum
  SendLoginEmailInput: SendLoginEmailInput
  SendNotificationSetEmailDetails: ResolverTypeWrapper<SendNotificationSetEmailDetails>
  SendOrderLetterInput: SendOrderLetterInput
  SendRegistrationConfirmEmailInput: SendRegistrationConfirmEmailInput
  SendSmsOutput: ResolverTypeWrapper<SendSmsOutput>
  SendSmsStatusEnum: SendSmsStatusEnum
  SentToReviewVacancyEmployerEvent: ResolverTypeWrapper<Omit<SentToReviewVacancyEmployerEvent, 'performer'> & { performer: ResolversTypes['PerformerEmployerEventUnion'] }>
  ServerError: ResolverTypeWrapper<ServerError>
  ServiceActivationInput: ServiceActivationInput
  ServiceActivationOutput: ResolverTypeWrapper<ServiceActivationOutput>
  ServiceActivator: ResolverTypeWrapper<ServiceActivator>
  ServiceActivatorType: ServiceActivatorType
  ServiceCompany: ResolverTypeWrapper<ServiceCompany>
  ServiceCompanyTurbotaStatus: ResolverTypeWrapper<ServiceCompanyTurbotaStatus>
  ServiceContactsAccrualPeriod: ServiceContactsAccrualPeriod
  ServiceCount: ServiceCount
  ServiceCvdbRegionsOutput: ResolverTypeWrapper<ServiceCvdbRegionsOutput>
  ServiceCvdbRubricsOutput: ResolverTypeWrapper<ServiceCvdbRubricsOutput>
  ServiceDiscountTypeWrapper: ResolverTypeWrapper<ServiceDiscountTypeWrapper>
  ServiceEmployee: ResolverTypeWrapper<ServiceEmployee>
  ServiceEmployerEventSnapshotUnion:
    | ResolversTypes['AdditionalServiceEmployerEventSnapshot']
    | ResolversTypes['CvDbServiceEmployerEventSnapshot']
    | ResolversTypes['HotServiceEmployerEventSnapshot']
    | ResolversTypes['LogoInPortalServiceEmployerEventSnapshot']
    | ResolversTypes['LogoInRubricServiceEmployerEventSnapshot']
    | ResolversTypes['LogoOnMainPageServiceEmployerEventSnapshot']
    | ResolversTypes['MarketingServiceEmployerEventSnapshot']
    | ResolversTypes['PackageServiceEmployerEventSnapshot']
    | ResolversTypes['PublicationServiceEmployerEventSnapshot']
  ServiceGroupEnum: ServiceGroupEnum
  ServiceGroupWrapper: ResolverTypeWrapper<ServiceGroupWrapper>
  ServiceIsExpiringEvent: ResolverTypeWrapper<ServiceIsExpiringEvent>
  ServiceLimit: ResolverTypeWrapper<ServiceLimit>
  ServiceLimitInput: ServiceLimitInput
  ServiceLimitRequestInput: ServiceLimitRequestInput
  ServicePrice: ResolverTypeWrapper<ServicePrice>
  ServicePurchaseHistoryOutput: ResolverTypeWrapper<ServicePurchaseHistoryOutput>
  ServicePurchasedService: ResolverTypeWrapper<ServicePurchasedService>
  ServiceState: ServiceState
  ServiceType: ServiceType
  ServiceTypeGraphType: ServiceTypeGraphType
  ServiceTypeWrapper: ResolverTypeWrapper<ServiceTypeWrapper>
  ServiceUnitEnum: ServiceUnitEnum
  ServiceUnited:
    | ResolversTypes['ActivatedAdditionalService']
    | ResolversTypes['ActivatedCvDbService']
    | ResolversTypes['ActivatedHotService']
    | ResolversTypes['ActivatedLogoInPortalService']
    | ResolversTypes['ActivatedLogoInRubricService']
    | ResolversTypes['ActivatedRisingUpService']
    | ResolversTypes['ActivatedVacancyPackageService']
    | ResolversTypes['ActivatedVacancyPublicationService']
    | ResolversTypes['ClosedAdditionalService']
    | ResolversTypes['ClosedCvDbService']
    | ResolversTypes['ClosedHotService']
    | ResolversTypes['ClosedLogoInPortalService']
    | ResolversTypes['ClosedLogoInRubricService']
    | ResolversTypes['ClosedMarketingService']
    | ResolversTypes['ClosedRisingUpService']
    | ResolversTypes['ClosedVacancyPackageService']
    | ResolversTypes['ClosedVacancyPublicationService']
    | ResolversTypes['OrderedAdditionalService']
    | ResolversTypes['OrderedCvDbService']
    | ResolversTypes['OrderedHotService']
    | ResolversTypes['OrderedLogoInPortalService']
    | ResolversTypes['OrderedLogoInRubricService']
    | ResolversTypes['OrderedMarketingService']
    | ResolversTypes['OrderedRisingUpService']
    | ResolversTypes['OrderedVacancyPackageService']
    | ResolversTypes['OrderedVacancyPublicationService']
  ServiceUnitedEdge: ResolverTypeWrapper<Omit<ServiceUnitedEdge, 'node'> & { node: Maybe<ResolversTypes['ServiceUnited']> }>
  ServiceUsagePeriod: ServiceUsagePeriod
  ServicesFilters: ResolverTypeWrapper<ServicesFilters>
  ServicesManagementClientFilters: ResolverTypeWrapper<ServicesManagementClientFilters>
  ServicesManagementClientFiltersInput: ServicesManagementClientFiltersInput
  SetActionStateOutput: ResolverTypeWrapper<SetActionStateOutput>
  SetCvRubricInput: SetCvRubricInput
  SetCvStateInput: SetCvStateInput
  SetGiftRangeInput: SetGiftRangeInput
  SetStateGiftRequestInput: SetStateGiftRequestInput
  SetVacancyCandidateKeywordsInput: SetVacancyCandidateKeywordsInput
  SimilarVacancies: ResolverTypeWrapper<SimilarVacancies>
  SimilarVacanciesPaginationInput: SimilarVacanciesPaginationInput
  SimilarVacanciesQueries: ResolverTypeWrapper<SimilarVacanciesQueries>
  SimilarVacancyKeywordsOutput: ResolverTypeWrapper<SimilarVacancyKeywordsOutput>
  SpiderVacancy: ResolverTypeWrapper<SpiderVacancy>
  StartCallInput: StartCallInput
  StartGamificationWaveInput: StartGamificationWaveInput
  StartGamificationWaveOutput: ResolverTypeWrapper<StartGamificationWaveOutput>
  Statistic: ResolverTypeWrapper<Statistic>
  StatisticBranch: ResolverTypeWrapper<StatisticBranch>
  StatisticBranchTimeseries: ResolverTypeWrapper<StatisticBranchTimeseries>
  StatisticCandidatesStructure: ResolverTypeWrapper<StatisticCandidatesStructure>
  StatisticCityInput: StatisticCityInput
  StatisticData: ResolverTypeWrapper<StatisticData>
  StatisticDataCityKeywordInput: StatisticDataCityKeywordInput
  StatisticDataCityRubricInput: StatisticDataCityRubricInput
  StatisticDataKeywordRubricInput: StatisticDataKeywordRubricInput
  StatisticInput: StatisticInput
  StatisticItem: ResolverTypeWrapper<StatisticItem>
  StatisticProfession: ResolverTypeWrapper<StatisticProfession>
  StatisticProfessionByCountTimeSeries: ResolverTypeWrapper<StatisticProfessionByCountTimeSeries>
  StatisticProfessionItem: ResolverTypeWrapper<StatisticProfessionItem>
  StatisticRubric: ResolverTypeWrapper<StatisticRubric>
  StatisticRubricTimeseries: ResolverTypeWrapper<StatisticRubricTimeseries>
  StatisticSalaryDistribution: ResolverTypeWrapper<StatisticSalaryDistribution>
  StatisticStructure: ResolverTypeWrapper<StatisticStructure>
  StatisticTimeseries: ResolverTypeWrapper<StatisticTimeseries>
  StatisticTotal: ResolverTypeWrapper<StatisticTotal>
  StatisticVacanciesStructure: ResolverTypeWrapper<StatisticVacanciesStructure>
  StatisticVacancyBadge: ResolverTypeWrapper<StatisticVacancyBadge>
  String: ResolverTypeWrapper<Scalars['String']>
  Subrubric: ResolverTypeWrapper<Omit<Subrubric, 'externalCourses'> & { externalCourses: ReadonlyArray<ResolversTypes['ExternalCourse']> }>
  SubrubricsFacet: ResolverTypeWrapper<SubrubricsFacet>
  SubscriptionChannelTypeEnum: SubscriptionChannelTypeEnum
  SubscriptionLanguageEnum: SubscriptionLanguageEnum
  SubscriptionOutputType: ResolverTypeWrapper<SubscriptionOutputType>
  SubscriptionTypeEnum: SubscriptionTypeEnum
  SuggestType: SuggestType
  SuggestedVacancySearchOutput: ResolverTypeWrapper<SuggestedVacancySearchOutput>
  SummaryService: ResolverTypeWrapper<SummaryService>
  SummaryServiceOrderBy: SummaryServiceOrderBy
  Synonym: ResolverTypeWrapper<Synonym>
  SynonymTag: ResolverTypeWrapper<SynonymTag>
  SystemEventPerformer: ResolverTypeWrapper<SystemEventPerformer>
  SystemWalletUser: ResolverTypeWrapper<SystemWalletUser>
  TagGroup: ResolverTypeWrapper<TagGroup>
  TargetMailingListFilter: ResolverTypeWrapper<TargetMailingListFilter>
  TargetMailingListQueryFilter: ResolverTypeWrapper<TargetMailingListQueryFilter>
  TargetedExternalMailingLink: ResolverTypeWrapper<TargetedExternalMailingLink>
  TargetedMailing: ResolverTypeWrapper<
    Omit<TargetedMailing, 'content' | 'history'> & {
      content: Maybe<ReadonlyArray<ResolversTypes['TargetedMailingContentUnion']>>
      history: ReadonlyArray<ResolversTypes['TargetedMailingHistoryFactUnion']>
    }
  >
  TargetedMailingCandidatesLocation: ResolverTypeWrapper<TargetedMailingCandidatesLocation>
  TargetedMailingCandidatesLocationEnum: TargetedMailingCandidatesLocationEnum
  TargetedMailingCandidatesLocationInput: TargetedMailingCandidatesLocationInput
  TargetedMailingCandidatesRubric: ResolverTypeWrapper<TargetedMailingCandidatesRubric>
  TargetedMailingCandidatesRubricInput: TargetedMailingCandidatesRubricInput
  TargetedMailingCandidatesSampling: ResolverTypeWrapper<TargetedMailingCandidatesSampling>
  TargetedMailingCandidatesSamplingByCodeInput: TargetedMailingCandidatesSamplingByCodeInput
  TargetedMailingCandidatesSamplingByCodeInputSnapshot: ResolverTypeWrapper<TargetedMailingCandidatesSamplingByCodeInputSnapshot>
  TargetedMailingCandidatesSamplingByFilterInput: TargetedMailingCandidatesSamplingByFilterInput
  TargetedMailingCandidatesSamplingByFilterInputSnapshot: ResolverTypeWrapper<TargetedMailingCandidatesSamplingByFilterInputSnapshot>
  TargetedMailingCandidatesSamplingByVacancyInput: TargetedMailingCandidatesSamplingByVacancyInput
  TargetedMailingCandidatesSamplingDetails: ResolverTypeWrapper<TargetedMailingCandidatesSamplingDetails>
  TargetedMailingCandidatesSamplingDetailsByChannel: ResolverTypeWrapper<TargetedMailingCandidatesSamplingDetailsByChannel>
  TargetedMailingCandidatesSamplingDetailsByVacancy: ResolverTypeWrapper<TargetedMailingCandidatesSamplingDetailsByVacancy>
  TargetedMailingCandidatesSamplingDetailsKindEnum: TargetedMailingCandidatesSamplingDetailsKindEnum
  TargetedMailingCandidatesSamplingErrorUnion: ResolversTypes['TargetedMailingInvalidVacanciesError']
  TargetedMailingCandidatesSamplingInput: TargetedMailingCandidatesSamplingInput
  TargetedMailingCandidatesSamplingInputSnapshot: ResolverTypeWrapper<TargetedMailingCandidatesSamplingInputSnapshot>
  TargetedMailingCandidatesSamplingOutput: ResolverTypeWrapper<Omit<TargetedMailingCandidatesSamplingOutput, 'error'> & { error: Maybe<ResolversTypes['TargetedMailingCandidatesSamplingErrorUnion']> }>
  TargetedMailingCandidatesSamplingTypeEnum: TargetedMailingCandidatesSamplingTypeEnum
  TargetedMailingChannelEnum: TargetedMailingChannelEnum
  TargetedMailingCompletedHistoryFact: ResolverTypeWrapper<TargetedMailingCompletedHistoryFact>
  TargetedMailingConnection: ResolverTypeWrapper<TargetedMailingConnection>
  TargetedMailingContentUnion: ResolversTypes['TargetedMailingMailContent'] | ResolversTypes['TargetedMailingMobilePushContent']
  TargetedMailingCreatedHistoryFact: ResolverTypeWrapper<TargetedMailingCreatedHistoryFact>
  TargetedMailingEdge: ResolverTypeWrapper<TargetedMailingEdge>
  TargetedMailingEditedHistoryFact: ResolverTypeWrapper<TargetedMailingEditedHistoryFact>
  TargetedMailingFacetsOutput: ResolverTypeWrapper<TargetedMailingFacetsOutput>
  TargetedMailingFailedHistoryFact: ResolverTypeWrapper<TargetedMailingFailedHistoryFact>
  TargetedMailingFilterInput: TargetedMailingFilterInput
  TargetedMailingHistoryFactUnion:
    | ResolversTypes['TargetedMailingCompletedHistoryFact']
    | ResolversTypes['TargetedMailingCreatedHistoryFact']
    | ResolversTypes['TargetedMailingEditedHistoryFact']
    | ResolversTypes['TargetedMailingFailedHistoryFact']
    | ResolversTypes['TargetedMailingSendingHistoryFact']
  TargetedMailingInvalidVacanciesError: ResolverTypeWrapper<TargetedMailingInvalidVacanciesError>
  TargetedMailingLanguageRequirement: ResolverTypeWrapper<TargetedMailingLanguageRequirement>
  TargetedMailingLanguageRequirementInput: TargetedMailingLanguageRequirementInput
  TargetedMailingLinkUnion: ResolversTypes['TargetedExternalMailingLink'] | ResolversTypes['TargetedVacancyMailingLink']
  TargetedMailingMailContent: ResolverTypeWrapper<TargetedMailingMailContent>
  TargetedMailingMailContentInput: TargetedMailingMailContentInput
  TargetedMailingMobilePushContent: ResolverTypeWrapper<TargetedMailingMobilePushContent>
  TargetedMailingMobilePushContentInput: TargetedMailingMobilePushContentInput
  TargetedMailingMutations: ResolverTypeWrapper<TargetedMailingMutations>
  TargetedMailingQueries: ResolverTypeWrapper<TargetedMailingQueries>
  TargetedMailingRequiredFieldsMissedError: ResolverTypeWrapper<TargetedMailingRequiredFieldsMissedError>
  TargetedMailingResponsible: ResolverTypeWrapper<TargetedMailingResponsible>
  TargetedMailingSamplingRelatedVacancy: ResolverTypeWrapper<TargetedMailingSamplingRelatedVacancy>
  TargetedMailingSaveErrorUnion: ResolversTypes['TargetedMailingInvalidVacanciesError'] | ResolversTypes['TargetedMailingRequiredFieldsMissedError']
  TargetedMailingSendingHistoryFact: ResolverTypeWrapper<TargetedMailingSendingHistoryFact>
  TargetedMailingStatusEnum: TargetedMailingStatusEnum
  TargetedMailingStatusFacet: ResolverTypeWrapper<TargetedMailingStatusFacet>
  TargetedVacancyMailingLink: ResolverTypeWrapper<TargetedVacancyMailingLink>
  TargetedVacancyMailingLinkVacancySnapshot: ResolverTypeWrapper<TargetedVacancyMailingLinkVacancySnapshot>
  TaskContactTypeEnum: TaskContactTypeEnum
  TaskPriorityEnum: TaskPriorityEnum
  TaskStatusEnum: TaskStatusEnum
  TeamActions: ResolverTypeWrapper<TeamActions>
  TestTargetedMailingInput: TestTargetedMailingInput
  TestTargetedMailingOutput: ResolverTypeWrapper<TestTargetedMailingOutput>
  TicketCardFilterInput: TicketCardFilterInput
  TokenAlreadyUsedError: ResolverTypeWrapper<TokenAlreadyUsedError>
  Topic: ResolverTypeWrapper<Topic>
  TopicTemplate: ResolverTypeWrapper<TopicTemplate>
  TrackCompanyViewOutput: ResolverTypeWrapper<TrackCompanyViewOutput>
  TransactionExistsError: ResolverTypeWrapper<TransactionExistsError>
  TransactionReasonEnum: TransactionReasonEnum
  TransactionStatusEnum: TransactionStatusEnum
  TransactionTypeEnum: TransactionTypeEnum
  TransactionsConnection: ResolverTypeWrapper<TransactionsConnection>
  TransactionsEdge: ResolverTypeWrapper<TransactionsEdge>
  TurbotaEvent: ResolverTypeWrapper<TurbotaEvent>
  TurbotaWasExpiredError: ResolverTypeWrapper<TurbotaWasExpiredError>
  UInt: ResolverTypeWrapper<Scalars['UInt']>
  UiServiceType: UiServiceType
  UncompletedPaymentEvent: ResolverTypeWrapper<UncompletedPaymentEvent>
  UndislikeSeekerVacanciesInput: UndislikeSeekerVacanciesInput
  UndislikeSeekerVacanciesOutput: ResolverTypeWrapper<UndislikeSeekerVacanciesOutput>
  UnionBannerDynamicElement: ResolversTypes['BannerClock'] | ResolversTypes['BannerProgressBar']
  University: ResolverTypeWrapper<University>
  UnknownServerError: ResolverTypeWrapper<UnknownServerError>
  UnlinkPayerOutput: ResolverTypeWrapper<UnlinkPayerOutput>
  UnpaidOrderDetails: ResolverTypeWrapper<UnpaidOrderDetails>
  UnpublishProfResumeInput: UnpublishProfResumeInput
  UnpublishSeekerProfResumeError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  UnpublishedProfResumeOutput: ResolverTypeWrapper<Omit<UnpublishedProfResumeOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['UnpublishSeekerProfResumeError']>> }>
  UpdateGiftInput: UpdateGiftInput
  UpdateGiftRequestOutput: ResolverTypeWrapper<UpdateGiftRequestOutput>
  UpdateProfResumeAdditionalPhonesOutput: ResolverTypeWrapper<
    Omit<UpdateProfResumeAdditionalPhonesOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeAdditionalPhonesError']>> }
  >
  UpdateProfResumeEmailOutput: ResolverTypeWrapper<Omit<UpdateProfResumeEmailOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeEmailError']>> }>
  UpdateProfResumePhoneOutput: ResolverTypeWrapper<Omit<UpdateProfResumePhoneOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeMainPhoneError']>> }>
  UpdateProfResumePortfoliosOutput: ResolverTypeWrapper<Omit<UpdateProfResumePortfoliosOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumePortfoliosError']>> }>
  UpdateProfResumeSocialNetworksOutput: ResolverTypeWrapper<
    Omit<UpdateProfResumeSocialNetworksOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeSocialNetworksError']>> }
  >
  UpdateSeekerProfResumeAdditionalEducationError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeAdditionalInfoError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeAdditionalInfosError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeAdditionalPhonesError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeAnonymityError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeAvailabilityStateError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeBirthDateError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeCityError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeDiiaCertificateError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeDisabilityError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeEducationError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeEmailError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeExperienceError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeFullNameError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeGenderError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeHiddenCompaniesError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeLanguageSkillsError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeMainPhoneError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist'] | ResolversTypes['ProfResumePhoneConfirmationFailed']
  UpdateSeekerProfResumeMilitaryStatusError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumePhotoError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumePortfoliosError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumePositionError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist'] | ResolversTypes['ProfResumeDuplicated']
  UpdateSeekerProfResumeSalaryError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeScheduleError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeSearchStateError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeSkillsError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeSocialNetworksError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeSortDateError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeSortDateInput: UpdateSeekerProfResumeSortDateInput
  UpdateSeekerProfResumeSortDateOutput: ResolverTypeWrapper<
    Omit<UpdateSeekerProfResumeSortDateOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeSortDateError']>> }
  >
  UpdateSeekerProfResumeStateError: ResolversTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversTypes['ProfResumeDoesNotExist']
  UpdateTargetedMailingInput: UpdateTargetedMailingInput
  UpdateTargetedMailingOutput: ResolverTypeWrapper<Omit<UpdateTargetedMailingOutput, 'error'> & { error: Maybe<ResolversTypes['TargetedMailingSaveErrorUnion']> }>
  UpdatedProfResumeAdditionalEducationsOutput: ResolverTypeWrapper<
    Omit<UpdatedProfResumeAdditionalEducationsOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeAdditionalEducationError']>> }
  >
  UpdatedProfResumeAdditionalInfoOutput: ResolverTypeWrapper<
    Omit<UpdatedProfResumeAdditionalInfoOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeAdditionalInfoError']>> }
  >
  UpdatedProfResumeAdditionalInfosOutput: ResolverTypeWrapper<
    Omit<UpdatedProfResumeAdditionalInfosOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeAdditionalInfosError']>> }
  >
  UpdatedProfResumeAnonymityOutput: ResolverTypeWrapper<Omit<UpdatedProfResumeAnonymityOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeAnonymityError']>> }>
  UpdatedProfResumeAvailabilityStateOutput: ResolverTypeWrapper<
    Omit<UpdatedProfResumeAvailabilityStateOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeAvailabilityStateError']>> }
  >
  UpdatedProfResumeBirthDateOutput: ResolverTypeWrapper<Omit<UpdatedProfResumeBirthDateOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeBirthDateError']>> }>
  UpdatedProfResumeCityOutput: ResolverTypeWrapper<Omit<UpdatedProfResumeCityOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeCityError']>> }>
  UpdatedProfResumeDiiaCertificateOutput: ResolverTypeWrapper<
    Omit<UpdatedProfResumeDiiaCertificateOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeDiiaCertificateError']>> }
  >
  UpdatedProfResumeDisabilityOutput: ResolverTypeWrapper<Omit<UpdatedProfResumeDisabilityOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeDisabilityError']>> }>
  UpdatedProfResumeEducationsOutput: ResolverTypeWrapper<Omit<UpdatedProfResumeEducationsOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeEducationError']>> }>
  UpdatedProfResumeExperiencesOutput: ResolverTypeWrapper<
    Omit<UpdatedProfResumeExperiencesOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeExperienceError']>> }
  >
  UpdatedProfResumeFullNameOutput: ResolverTypeWrapper<Omit<UpdatedProfResumeFullNameOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeFullNameError']>> }>
  UpdatedProfResumeGenderOutput: ResolverTypeWrapper<Omit<UpdatedProfResumeGenderOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeGenderError']>> }>
  UpdatedProfResumeHiddenCompaniesOutput: ResolverTypeWrapper<
    Omit<UpdatedProfResumeHiddenCompaniesOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeHiddenCompaniesError']>> }
  >
  UpdatedProfResumeLanguageSkillsOutput: ResolverTypeWrapper<
    Omit<UpdatedProfResumeLanguageSkillsOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeLanguageSkillsError']>> }
  >
  UpdatedProfResumeMilitaryStatusOutput: ResolverTypeWrapper<
    Omit<UpdatedProfResumeMilitaryStatusOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeMilitaryStatusError']>> }
  >
  UpdatedProfResumePhotoOutput: ResolverTypeWrapper<Omit<UpdatedProfResumePhotoOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumePhotoError']>> }>
  UpdatedProfResumePositionOutput: ResolverTypeWrapper<Omit<UpdatedProfResumePositionOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumePositionError']>> }>
  UpdatedProfResumeSalaryOutput: ResolverTypeWrapper<Omit<UpdatedProfResumeSalaryOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeSalaryError']>> }>
  UpdatedProfResumeScheduleOutput: ResolverTypeWrapper<Omit<UpdatedProfResumeScheduleOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeScheduleError']>> }>
  UpdatedProfResumeSearchStateOutput: ResolverTypeWrapper<
    Omit<UpdatedProfResumeSearchStateOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeSearchStateError']>> }
  >
  UpdatedProfResumeSkillsOutput: ResolverTypeWrapper<Omit<UpdatedProfResumeSkillsOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeSkillsError']>> }>
  Upload: ResolverTypeWrapper<Scalars['Upload']>
  Uri: ResolverTypeWrapper<Scalars['Uri']>
  UsagePeriodTypeWrapper: ResolverTypeWrapper<UsagePeriodTypeWrapper>
  UsePromoCodeErrorEnum: UsePromoCodeErrorEnum
  UsePromoCodeResponse: ResolverTypeWrapper<UsePromoCodeResponse>
  UsePromocodesInput: UsePromocodesInput
  UsedHotServiceEmployerEvent: ResolverTypeWrapper<
    Omit<UsedHotServiceEmployerEvent, 'performer' | 'usedService'> & { performer: ResolversTypes['PerformerEmployerEventUnion']; usedService: ResolversTypes['ServiceEmployerEventSnapshotUnion'] }
  >
  UsedVacancyServiceEmployerEvent: ResolverTypeWrapper<
    Omit<UsedVacancyServiceEmployerEvent, 'performer' | 'usedService'> & { performer: ResolversTypes['PerformerEmployerEventUnion']; usedService: ResolversTypes['ServiceEmployerEventSnapshotUnion'] }
  >
  User: ResolverTypeWrapper<User>
  UserAccountLogin: ResolverTypeWrapper<UserAccountLogin>
  UserCardDeleteOutput: ResolverTypeWrapper<UserCardDeleteOutput>
  UserEmailHasBeenSetNotificationDetails: ResolverTypeWrapper<UserEmailHasBeenSetNotificationDetails>
  UserInput: UserInput
  UserIsNotAuthenticatedError: ResolverTypeWrapper<UserIsNotAuthenticatedError>
  UserIsNotEmployerError: ResolverTypeWrapper<UserIsNotEmployerError>
  UserLeftBasketEvent: ResolverTypeWrapper<UserLeftBasketEvent>
  UserLocationRadiusFilterInput: UserLocationRadiusFilterInput
  UserRegistrationError: ResolverTypeWrapper<UserRegistrationError>
  UserRegistrationErrorEnum: UserRegistrationErrorEnum
  UserRegistrationMutations: ResolverTypeWrapper<UserRegistrationMutations>
  UserRegistrationValidationResponse: ResolverTypeWrapper<UserRegistrationValidationResponse>
  UserRoleWrapper: ResolverTypeWrapper<UserRoleWrapper>
  UserRolesEnum: UserRolesEnum
  UserState: ResolverTypeWrapper<UserState>
  Users: ResolverTypeWrapper<Users>
  UsersManagementFilters: ResolverTypeWrapper<UsersManagementFilters>
  UsersManagementFiltersInput: UsersManagementFiltersInput
  UsersManagementPageInfo: ResolverTypeWrapper<UsersManagementPageInfo>
  UsersManagementPageInfoInput: UsersManagementPageInfoInput
  UsersManagementSubFilter: ResolverTypeWrapper<UsersManagementSubFilter>
  VacanciesPageClientFilters: ResolverTypeWrapper<VacanciesPageClientFilters>
  VacanciesPageClientFiltersInput: VacanciesPageClientFiltersInput
  Vacancy: ResolverTypeWrapper<Vacancy>
  VacancyAdditionalCandidatePermissions: ResolverTypeWrapper<VacancyAdditionalCandidatePermissions>
  VacancyAdditionalCandidatePermissionsInput: VacancyAdditionalCandidatePermissionsInput
  VacancyAddress: ResolverTypeWrapper<VacancyAddress>
  VacancyAddressInput: VacancyAddressInput
  VacancyAddressesSuggestInput: VacancyAddressesSuggestInput
  VacancyAddressesSuggestOutput: ResolverTypeWrapper<VacancyAddressesSuggestOutput>
  VacancyAdminRejectDetails: ResolverTypeWrapper<VacancyAdminRejectDetails>
  VacancyAppearedInViewPortInput: VacancyAppearedInViewPortInput
  VacancyAppearedInViewPortOutput: ResolverTypeWrapper<VacancyAppearedInViewPortOutput>
  VacancyAppearedTypeEnum: VacancyAppearedTypeEnum
  VacancyApplicationsDestination: ResolverTypeWrapper<VacancyApplicationsDestination>
  VacancyApplicationsDestinationInput: VacancyApplicationsDestinationInput
  VacancyAwaitingApprovalDetails: ResolverTypeWrapper<VacancyAwaitingApprovalDetails>
  VacancyBadge: ResolverTypeWrapper<VacancyBadge>
  VacancyBannedByModeratorDetails: ResolverTypeWrapper<VacancyBannedByModeratorDetails>
  VacancyBannedEvent: ResolverTypeWrapper<VacancyBannedEvent>
  VacancyBenefit: ResolverTypeWrapper<VacancyBenefit>
  VacancyCandidatesScreeningOptions: ResolverTypeWrapper<VacancyCandidatesScreeningOptions>
  VacancyCandidatesScreeningOptionsInput: VacancyCandidatesScreeningOptionsInput
  VacancyChangeClosingBehaviorInput: VacancyChangeClosingBehaviorInput
  VacancyChangeClosingBehaviorOutput: ResolverTypeWrapper<VacancyChangeClosingBehaviorOutput>
  VacancyChangeHistory: ResolverTypeWrapper<VacancyChangeHistory>
  VacancyChangeOwnerInput: VacancyChangeOwnerInput
  VacancyChangeOwnerOutput: ResolverTypeWrapper<VacancyChangeOwnerOutput>
  VacancyChatStatusEnum: VacancyChatStatusEnum
  VacancyCityCount: ResolverTypeWrapper<VacancyCityCount>
  VacancyCloseInput: VacancyCloseInput
  VacancyCloseOutput: ResolverTypeWrapper<VacancyCloseOutput>
  VacancyClosingBehavior: VacancyClosingBehavior
  VacancyClosingType: VacancyClosingType
  VacancyCompany: ResolverTypeWrapper<VacancyCompany>
  VacancyCompetitors: ResolverTypeWrapper<VacancyCompetitors>
  VacancyCompetitorsStatistics: ResolverTypeWrapper<VacancyCompetitorsStatistics>
  VacancyContacts: ResolverTypeWrapper<VacancyContacts>
  VacancyContactsInput: VacancyContactsInput
  VacancyCounterGraphType: ResolverTypeWrapper<VacancyCounterGraphType>
  VacancyCreateError:
    | ResolversTypes['CompanyWasMergedError']
    | ResolversTypes['InvalidError']
    | ResolversTypes['NoAvailableLimitsError']
    | ResolversTypes['TurbotaWasExpiredError']
    | ResolversTypes['UserIsNotAuthenticatedError']
    | ResolversTypes['UserIsNotEmployerError']
  VacancyCreateInput: VacancyCreateInput
  VacancyCreateOutput: ResolverTypeWrapper<Omit<VacancyCreateOutput, 'error'> & { error: Maybe<ResolversTypes['VacancyCreateError']> }>
  VacancyCreateSuggest: ResolverTypeWrapper<VacancyCreateSuggest>
  VacancyCreateSuggestOutput: ResolverTypeWrapper<VacancyCreateSuggestOutput>
  VacancyDesign: ResolverTypeWrapper<VacancyDesign>
  VacancyDesignFooter: ResolverTypeWrapper<VacancyDesignFooter>
  VacancyDesignHeader: ResolverTypeWrapper<VacancyDesignHeader>
  VacancyDesignHeaderItem: ResolverTypeWrapper<VacancyDesignHeaderItem>
  VacancyDesignHeaderType: VacancyDesignHeaderType
  VacancyDictionaryBenefit: ResolverTypeWrapper<VacancyDictionaryBenefit>
  VacancyDraftInput: VacancyDraftInput
  VacancyDraftOutput: ResolverTypeWrapper<VacancyDraftOutput>
  VacancyEdge: ResolverTypeWrapper<VacancyEdge>
  VacancyEmployer: ResolverTypeWrapper<VacancyEmployer>
  VacancyEmployerCount: ResolverTypeWrapper<VacancyEmployerCount>
  VacancyEmploymentType: VacancyEmploymentType
  VacancyEndingCountDetails: ResolverTypeWrapper<VacancyEndingCountDetails>
  VacancyEndingLeftDaysDetails: ResolverTypeWrapper<VacancyEndingLeftDaysDetails>
  VacancyEventStatisticItem: ResolverTypeWrapper<VacancyEventStatisticItem>
  VacancyEventType: VacancyEventType
  VacancyExperienceQuestion: ResolverTypeWrapper<VacancyExperienceQuestion>
  VacancyExperienceQuestionInput: VacancyExperienceQuestionInput
  VacancyFromAnotherSourceGraphType: ResolverTypeWrapper<VacancyFromAnotherSourceGraphType>
  VacancyHotPeriod: ResolverTypeWrapper<VacancyHotPeriod>
  VacancyInterviewQuestionsMutations: ResolverTypeWrapper<VacancyInterviewQuestionsMutations>
  VacancyLanguageQuestion: ResolverTypeWrapper<VacancyLanguageQuestion>
  VacancyLanguageQuestionInput: VacancyLanguageQuestionInput
  VacancyMakeHotInput: VacancyMakeHotInput
  VacancyMakeHotOutput: ResolverTypeWrapper<VacancyMakeHotOutput>
  VacancyMedia: ResolverTypeWrapper<VacancyMedia>
  VacancyMediaInput: VacancyMediaInput
  VacancyMediaType: VacancyMediaType
  VacancyModeratorRejectDetails: ResolverTypeWrapper<VacancyModeratorRejectDetails>
  VacancyOnWorkWillExpireInFiveDaysEvent: ResolverTypeWrapper<VacancyOnWorkWillExpireInFiveDaysEvent>
  VacancyOwnerChangedDetails: ResolverTypeWrapper<Omit<VacancyOwnerChangedDetails, 'initiator'> & { initiator: ResolversTypes['NotificationInitiator'] }>
  VacancyPackageCatalogService: ResolverTypeWrapper<VacancyPackageCatalogService>
  VacancyPosition: ResolverTypeWrapper<VacancyPosition>
  VacancyPositionRisingInSearchList: ResolverTypeWrapper<VacancyPositionRisingInSearchList>
  VacancyProlongationRequest: ResolverTypeWrapper<VacancyProlongationRequest>
  VacancyProlongationRequestsOutput: ResolverTypeWrapper<VacancyProlongationRequestsOutput>
  VacancyProlongationReviewError: ResolversTypes['AlreadyReviewedVacancyProlongationError']
  VacancyProlongationReviewInput: VacancyProlongationReviewInput
  VacancyProlongationReviewOutput: ResolverTypeWrapper<Omit<VacancyProlongationReviewOutput, 'error'> & { error: Maybe<ResolversTypes['VacancyProlongationReviewError']> }>
  VacancyPublicationApprovedDetails: ResolverTypeWrapper<VacancyPublicationApprovedDetails>
  VacancyPublicationCatalogService: ResolverTypeWrapper<VacancyPublicationCatalogService>
  VacancyPublicationEndedDetails: ResolverTypeWrapper<VacancyPublicationEndedDetails>
  VacancyPublicationRejectedDetails: ResolverTypeWrapper<VacancyPublicationRejectedDetails>
  VacancyPublicationType: VacancyPublicationType
  VacancyPublicationTypeWrapper: ResolverTypeWrapper<VacancyPublicationTypeWrapper>
  VacancyPublishInput: VacancyPublishInput
  VacancyPublishOutput: ResolverTypeWrapper<Omit<VacancyPublishOutput, 'errors'> & { errors: ReadonlyArray<ResolversTypes['ModifyVacancyErrorUnion']> }>
  VacancyPublishPeriod: ResolverTypeWrapper<VacancyPublishPeriod>
  VacancyPublishedEmployerAchievement: ResolverTypeWrapper<VacancyPublishedEmployerAchievement>
  VacancyPublishedWithFilledAddressAndSalaryEmployerAchievement: ResolverTypeWrapper<VacancyPublishedWithFilledAddressAndSalaryEmployerAchievement>
  VacancyRejectPublicationInput: VacancyRejectPublicationInput
  VacancyRejectPublicationOutput: ResolverTypeWrapper<VacancyRejectPublicationOutput>
  VacancyReportInput: VacancyReportInput
  VacancyReportOutput: ResolverTypeWrapper<VacancyReportOutput>
  VacancyRepublishInput: VacancyRepublishInput
  VacancyRepublishOutput: ResolverTypeWrapper<Omit<VacancyRepublishOutput, 'errors'> & { errors: ReadonlyArray<ResolversTypes['ModifyVacancyErrorUnion']> }>
  VacancyResumeInput: VacancyResumeInput
  VacancyResumeOutput: ResolverTypeWrapper<VacancyResumeOutput>
  VacancyRisingUpInput: VacancyRisingUpInput
  VacancySalary: ResolverTypeWrapper<VacancySalary>
  VacancySalaryInput: VacancySalaryInput
  VacancySearchSuggest: ResolverTypeWrapper<VacancySearchSuggest>
  VacancySearchSuggestList: ResolverTypeWrapper<VacancySearchSuggestList>
  VacancySearchSuggestTypeEnum: VacancySearchSuggestTypeEnum
  VacancySearchSuggests: ResolverTypeWrapper<VacancySearchSuggests>
  VacancySearchSuggestsQueries: ResolverTypeWrapper<VacancySearchSuggestsQueries>
  VacancyServiceActivationBehavior: ResolverTypeWrapper<Omit<VacancyServiceActivationBehavior, 'catalogService'> & { catalogService: Maybe<ResolversTypes['CatalogUnitedServices']> }>
  VacancyServiceActivationBehaviorInput: VacancyServiceActivationBehaviorInput
  VacancyStatisticsItem: ResolverTypeWrapper<VacancyStatisticsItem>
  VacancyStatisticsTimeseries: ResolverTypeWrapper<VacancyStatisticsTimeseries>
  VacancyStatus: VacancyStatus
  VacancyStatusCount: ResolverTypeWrapper<VacancyStatusCount>
  VacancySuspendInput: VacancySuspendInput
  VacancySuspendOutput: ResolverTypeWrapper<VacancySuspendOutput>
  VacancyTimeseriesItem: ResolverTypeWrapper<VacancyTimeseriesItem>
  VacancyToAtsProjectExportError: ResolversTypes['AtsProjectCreationError'] | ResolversTypes['InvalidError'] | ResolversTypes['NotAvailableError'] | ResolversTypes['ServerError']
  VacancyToAtsProjectExportInput: VacancyToAtsProjectExportInput
  VacancyToAtsProjectExportOutput: ResolverTypeWrapper<Omit<VacancyToAtsProjectExportOutput, 'error'> & { error: Maybe<ResolversTypes['VacancyToAtsProjectExportError']> }>
  VacancyToAtsProjectExportResult: ResolverTypeWrapper<VacancyToAtsProjectExportResult>
  VacancyType: VacancyType
  VacancyUpdateInput: VacancyUpdateInput
  VacancyUpdateOutput: ResolverTypeWrapper<Omit<VacancyUpdateOutput, 'error'> & { error: Maybe<ResolversTypes['ModifyVacancyErrorUnion']> }>
  VacancyWorkType: VacancyWorkType
  ViewNotificationsError: ResolversTypes['NotificationNotFoundError'] | ResolversTypes['UnknownServerError']
  ViewNotificationsInput: ViewNotificationsInput
  ViewNotificationsOutput: ResolverTypeWrapper<Omit<ViewNotificationsOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversTypes['ViewNotificationsError']>> }>
  Wallet: ResolverTypeWrapper<Omit<Wallet, 'createdBy' | 'users'> & { createdBy: ResolversTypes['WalletUserUnion']; users: ReadonlyArray<ResolversTypes['WalletUserUnion']> }>
  WalletBalance: ResolverTypeWrapper<WalletBalance>
  WalletCartMutation: ResolverTypeWrapper<WalletCartMutation>
  WalletDoesNotExistError: ResolverTypeWrapper<WalletDoesNotExistError>
  WalletExistsError: ResolverTypeWrapper<WalletExistsError>
  WalletTransaction: ResolverTypeWrapper<Omit<WalletTransaction, 'initiator'> & { initiator: ResolversTypes['WalletTransactionInitiatorUnion'] }>
  WalletTransactionEvent: ResolverTypeWrapper<Omit<WalletTransactionEvent, 'performer'> & { performer: ResolversTypes['PerformerEmployerEventUnion'] }>
  WalletTransactionInitiatorUnion: ResolversTypes['EmployerWalletUser'] | ResolversTypes['ManagerWalletUser'] | ResolversTypes['SeekerWalletUser'] | ResolversTypes['SystemWalletUser']
  WalletTransactionInput: WalletTransactionInput
  WalletTransactionsFilterInput: WalletTransactionsFilterInput
  WalletTransactionsSortTypeEnum: WalletTransactionsSortTypeEnum
  WalletUserUnion: ResolversTypes['EmployerWalletUser'] | ResolversTypes['ManagerWalletUser'] | ResolversTypes['SeekerWalletUser'] | ResolversTypes['SystemWalletUser']
  WalletsConnection: ResolverTypeWrapper<WalletsConnection>
  WalletsEdge: ResolverTypeWrapper<WalletsEdge>
  WarehouseGift: ResolverTypeWrapper<WarehouseGift>
  WarehouseLimitedGift: ResolverTypeWrapper<WarehouseLimitedGift>
  WarehouseRange: ResolverTypeWrapper<WarehouseRange>
  WidgetPaymentMethod: ResolverTypeWrapper<WidgetPaymentMethod>
  WidgetPaymentMethodOutput: ResolverTypeWrapper<WidgetPaymentMethodOutput>
  appliesByUrl: ResolverTypeWrapper<AppliesByUrl>
  clickOnLinks: ResolverTypeWrapper<ClickOnLinks>
  crmActionState: CrmActionState
  novaPoshtaAddCounterpartyInput: NovaPoshtaAddCounterpartyInput
  novaPoshtaAddGiftAddressInput: NovaPoshtaAddGiftAddressInput
  serviceAvailableContacts: ResolverTypeWrapper<ServiceAvailableContacts>
  timeseries: ResolverTypeWrapper<Timeseries>
}

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AccountDateChanges: AccountDateChanges
  AcquaintWithNotificationsError: ResolversParentTypes['UnknownServerError']
  AcquaintWithNotificationsOutput: Omit<AcquaintWithNotificationsOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['AcquaintWithNotificationsError']>> }
  ActionAttach: ActionAttach
  ActionCounter: ActionCounter
  ActionFilters: ActionFilters
  ActionStateCounter: ActionStateCounter
  ActionTeamCounter: ActionTeamCounter
  ActivatedAdditionalService: ActivatedAdditionalService
  ActivatedCvDbService: ActivatedCvDbService
  ActivatedHotService: ActivatedHotService
  ActivatedLogoInPortalService: ActivatedLogoInPortalService
  ActivatedLogoInRubricService: ActivatedLogoInRubricService
  ActivatedOrderByTurbotaServiceEmployerEvent: Omit<ActivatedOrderByTurbotaServiceEmployerEvent, 'performer'> & { performer: ResolversParentTypes['PerformerEmployerEventUnion'] }
  ActivatedRisingUpService: ActivatedRisingUpService
  ActivatedServiceEmployerEvent: Omit<ActivatedServiceEmployerEvent, 'performer' | 'service'> & {
    performer: ResolversParentTypes['PerformerEmployerEventUnion']
    service: ResolversParentTypes['ServiceEmployerEventSnapshotUnion']
  }
  ActivatedVacancyPackageService: Omit<ActivatedVacancyPackageService, 'additionalServices'> & { additionalServices: ReadonlyArray<Maybe<ResolversParentTypes['ServiceUnited']>> }
  ActivatedVacancyPublicationService: ActivatedVacancyPublicationService
  AdamLabel: AdamLabel
  AddAdamLabelInput: AddAdamLabelInput
  AddClubPostInput: AddClubPostInput
  AddClubPostOutput: AddClubPostOutput
  AddContactInput: AddContactInput
  AddGamificationLimitInput: AddGamificationLimitInput
  AddGamificationLimitsOutput: AddGamificationLimitsOutput
  AddGiftDetailInput: AddGiftDetailInput
  AddGiftInput: AddGiftInput
  AddGiftOccasionInput: AddGiftOccasionInput
  AddGiftRequestDeliveryOptionsInput: AddGiftRequestDeliveryOptionsInput
  AddGiftRequestOutput: AddGiftRequestOutput
  AddGiftWaveInput: AddGiftWaveInput
  AddJobAlertSubscriptionInput: AddJobAlertSubscriptionInput
  AddManagerAlarmInput: AddManagerAlarmInput
  AddMarketingGiftRequestInput: AddMarketingGiftRequestInput
  AddOtherSiteLinkInput: AddOtherSiteLinkInput
  AddOtherSiteLinkOutput: AddOtherSiteLinkOutput
  AddPromoCodeResponse: AddPromoCodeResponse
  AddRegularGiftInput: AddRegularGiftInput
  AddSeekerFavoriteVacanciesInput: AddSeekerFavoriteVacanciesInput
  AddSeekerFavoriteVacanciesOutput: AddSeekerFavoriteVacanciesOutput
  AddSeekerProfResumeAdditionalEducationsError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  AddSeekerProfResumeAdditionalInfoError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  AddSeekerProfResumeEducationsError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  AddSeekerProfResumeExperiencesError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  AddSeekerSubscriptionMutations: AddSeekerSubscriptionMutations
  AddedNewUserManagementEmployerEvent: Omit<AddedNewUserManagementEmployerEvent, 'performer'> & { performer: ResolversParentTypes['PerformerEmployerEventUnion'] }
  AddedProfResumeAdditionalEducationsOutput: Omit<AddedProfResumeAdditionalEducationsOutput, 'errors'> & {
    errors: Maybe<ReadonlyArray<ResolversParentTypes['AddSeekerProfResumeAdditionalEducationsError']>>
  }
  AddedProfResumeAdditionalInfoOutput: Omit<AddedProfResumeAdditionalInfoOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['AddSeekerProfResumeAdditionalInfoError']>> }
  AddedProfResumeEducationsOutput: Omit<AddedProfResumeEducationsOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['AddSeekerProfResumeEducationsError']>> }
  AddedProfResumeExperiencesOutput: Omit<AddedProfResumeExperiencesOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['AddSeekerProfResumeExperiencesError']>> }
  AdditionalCatalogService: AdditionalCatalogService
  AdditionalServiceEmployerEventSnapshot: AdditionalServiceEmployerEventSnapshot
  AdvertisingBanner: Omit<AdvertisingBanner, 'dynamicElement'> & { dynamicElement: Maybe<ResolversParentTypes['UnionBannerDynamicElement']> }
  AlreadyReviewedVacancyProlongationError: AlreadyReviewedVacancyProlongationError
  AnotherSourceVacancyOutput: AnotherSourceVacancyOutput
  AnswerOption: AnswerOption
  AnswerOptionInput: AnswerOptionInput
  AnswerOptionSuggest: AnswerOptionSuggest
  ApplicationInvitedNotificationDetails: ApplicationInvitedNotificationDetails
  ApplicationRefusedNotificationDetails: ApplicationRefusedNotificationDetails
  ApplicationResumeFilling: ApplicationResumeFilling
  ApplicationSentNotificationDetails: ApplicationSentNotificationDetails
  ApplicationViewedNotificationDetails: ApplicationViewedNotificationDetails
  ApplicationsViewedEmployerAchievement: ApplicationsViewedEmployerAchievement
  Apply: Apply
  ApplyAddCompanyAddressInput: ApplyAddCompanyAddressInput
  ApplyAddCompanyAddressOutput: ApplyAddCompanyAddressOutput
  ApplyAddNoteInput: ApplyAddNoteInput
  ApplyAddNoteOutput: ApplyAddNoteOutput
  ApplyAggregation: ApplyAggregation
  ApplyAtsConnectionsFilterInput: ApplyAtsConnectionsFilterInput
  ApplyAtsProject: ApplyAtsProject
  ApplyAtsProjectEdge: ApplyAtsProjectEdge
  ApplyAttachParsedFileContent: ApplyAttachParsedFileContent
  ApplyAttachResume: ApplyAttachResume
  ApplyChangeStatusInput: ApplyChangeStatusInput
  ApplyChangeStatusOutput: ApplyChangeStatusOutput
  ApplyChangeStatusesInput: ApplyChangeStatusesInput
  ApplyChangeStatusesOutput: ApplyChangeStatusesOutput
  ApplyCompanyAddress: ApplyCompanyAddress
  ApplyConnection: ApplyConnection
  ApplyContactLink: ApplyContactLink
  ApplyCountFilterInput: ApplyCountFilterInput
  ApplyCoveringLetter: ApplyCoveringLetter
  ApplyDeleteCompanyAddressInput: ApplyDeleteCompanyAddressInput
  ApplyDeleteCompanyAddressOutput: ApplyDeleteCompanyAddressOutput
  ApplyDeleteNoteInput: ApplyDeleteNoteInput
  ApplyDeleteNoteOutput: ApplyDeleteNoteOutput
  ApplyEdge: ApplyEdge
  ApplyEditNoteInput: ApplyEditNoteInput
  ApplyEditNoteOutput: ApplyEditNoteOutput
  ApplyEmail: ApplyEmail
  ApplyEmployer: ApplyEmployer
  ApplyEmptyResume: ApplyEmptyResume
  ApplyFacetUnion: ResolversParentTypes['ApplyStatus'] | ResolversParentTypes['CandidateInteractionWrapper'] | ResolversParentTypes['CandidateSourceType'] | ResolversParentTypes['ResumeFillingType']
  ApplyFilterInput: ApplyFilterInput
  ApplyInvitationDetails: ApplyInvitationDetails
  ApplyInviteInput: ApplyInviteInput
  ApplyInviteOutput: ApplyInviteOutput
  ApplyJobSeeker: ApplyJobSeeker
  ApplyMassRefuseInput: ApplyMassRefuseInput
  ApplyNote: ApplyNote
  ApplyPhone: ApplyPhone
  ApplyProfResume: ApplyProfResume
  ApplyProfResumeFullName: ApplyProfResumeFullName
  ApplyProjectAtsConnection: ApplyProjectAtsConnection
  ApplyRefusalDetails: ApplyRefusalDetails
  ApplyRefuseInput: ApplyRefuseInput
  ApplyRefuseOutput: ApplyRefuseOutput
  ApplyResumeAdditional: ApplyResumeAdditional
  ApplyResumeAdditionalEducation: ApplyResumeAdditionalEducation
  ApplyResumeContacts: ApplyResumeContacts
  ApplyResumeDesiredPosition: ApplyResumeDesiredPosition
  ApplyResumeExperience: ApplyResumeExperience
  ApplyResumeFillingTypeCount: ApplyResumeFillingTypeCount
  ApplyResumeFillingTypeCountFilterInput: ApplyResumeFillingTypeCountFilterInput
  ApplyResumeGeneralEducation: ApplyResumeGeneralEducation
  ApplyResumeInterface: ResolversParentTypes['ApplyAttachResume'] | ResolversParentTypes['ApplyEmptyResume'] | ResolversParentTypes['ApplyProfResume']
  ApplyResumeLanguageSkill: ApplyResumeLanguageSkill
  ApplyResumeRecommendation: ApplyResumeRecommendation
  ApplySalary: ApplySalary
  ApplyStatus: ApplyStatus
  ApplyStatusCount: ApplyStatusCount
  ApplyStatusCountFilterInput: ApplyStatusCountFilterInput
  ApplySurverQuestionImportance: ApplySurverQuestionImportance
  ApplySurveyAnswer: ApplySurveyAnswer
  ApplySurveyResult: ApplySurveyResult
  ApplyToAtsExportError: ResolversParentTypes['InvalidError'] | ResolversParentTypes['NotAvailableError'] | ResolversParentTypes['ServerError']
  ApplyToAtsExportInput: ApplyToAtsExportInput
  ApplyToAtsExportOutput: Omit<ApplyToAtsExportOutput, 'error'> & { error: Maybe<ResolversParentTypes['ApplyToAtsExportError']> }
  ApplyToAtsExportResult: ApplyToAtsExportResult
  ApplyUpdateCompanyAddressInput: ApplyUpdateCompanyAddressInput
  ApplyUpdateCompanyAddressOutput: ApplyUpdateCompanyAddressOutput
  ApplyVacancyKeyword: ApplyVacancyKeyword
  ApplyWalletBonusResponse: ApplyWalletBonusResponse
  ApprovedProlongationRequestVacancyEmployerEvent: Omit<ApprovedProlongationRequestVacancyEmployerEvent, 'performer'> & { performer: ResolversParentTypes['PerformerEmployerEventUnion'] }
  ApprovedReviewVacancyEmployerEvent: Omit<ApprovedReviewVacancyEmployerEvent, 'performer'> & { performer: ResolversParentTypes['PerformerEmployerEventUnion'] }
  Assignment: Assignment
  AssignmentCompleteInput: AssignmentCompleteInput
  AssignmentConnection: AssignmentConnection
  AssignmentCreateInput: AssignmentCreateInput
  AssignmentEdge: AssignmentEdge
  AssignmentOperationOutput: AssignmentOperationOutput
  AssignmentUpdateInput: AssignmentUpdateInput
  AtsProjectCreationError: AtsProjectCreationError
  AttachNotFound: AttachNotFound
  AttachParsingError: AttachParsingError
  AutoProlongationDisabledVacancyEmployerEvent: Omit<AutoProlongationDisabledVacancyEmployerEvent, 'performer'> & { performer: ResolversParentTypes['PerformerEmployerEventUnion'] }
  AutoProlongationEnabledVacancyEmployerEvent: Omit<AutoProlongationEnabledVacancyEmployerEvent, 'performer'> & { performer: ResolversParentTypes['PerformerEmployerEventUnion'] }
  AutoProlongedVacancyEmployerEvent: AutoProlongedVacancyEmployerEvent
  BanInfo: BanInfo
  BanReasonInfo: BanReasonInfo
  Banner: ResolversParentTypes['AdvertisingBanner'] | ResolversParentTypes['InformationBanner']
  BannerButton: BannerButton
  BannerClock: BannerClock
  BannerDynamicElement: ResolversParentTypes['BannerClock'] | ResolversParentTypes['BannerProgressBar']
  BannerImageElement: BannerImageElement
  BannerImageSize: BannerImageSize
  BannerProgressBar: BannerProgressBar
  BannerQueries: BannerQueries
  BannerTextElement: BannerTextElement
  BasicQuestion: ResolversParentTypes['ExperienceBasicQuestion'] | ResolversParentTypes['LanguageBasicQuestion']
  BasicQuestionItem: BasicQuestionItem
  BillFilters: BillFilters
  BlockedUserManagementEmployerEvent: Omit<BlockedUserManagementEmployerEvent, 'performer'> & { performer: ResolversParentTypes['PerformerEmployerEventUnion'] }
  Boolean: Scalars['Boolean']
  Branch: Branch
  BriefParsedCv: BriefParsedCv
  BriefParsedCvEdge: BriefParsedCvEdge
  Bucket: Bucket
  CabinetEventsDateRange: CabinetEventsDateRange
  CabinetEventsFilter: CabinetEventsFilter
  CabinetEventsQueryFilter: CabinetEventsQueryFilter
  CalculatePriceItem: CalculatePriceItem
  CalculatePricesFilter: CalculatePricesFilter
  CalculatePricesInput: CalculatePricesInput
  CalculateServicePriceInput: CalculateServicePriceInput
  CalculateServicePriceMultipleInput: CalculateServicePriceMultipleInput
  CallFilters: CallFilters
  CandidateInteraction: CandidateInteraction
  CandidateInteractionWrapper: CandidateInteractionWrapper
  CandidateInteractionsFilterInput: CandidateInteractionsFilterInput
  CandidateMatchedKeyword: CandidateMatchedKeyword
  CandidateResumeEmployerEventSnapshot: CandidateResumeEmployerEventSnapshot
  CandidateSourceType: CandidateSourceType
  CandidatesVacancyCounters: CandidatesVacancyCounters
  CartItem: Omit<CartItem, 'service'> & { service: ResolversParentTypes['CatalogUnitedServices'] }
  CartItemInput: CartItemInput
  CartMutation: CartMutation
  CartQuery: CartQuery
  CartRemoveItemsInput: CartRemoveItemsInput
  CatalogChildService: Omit<CatalogChildService, 'service'> & { service: ResolversParentTypes['CatalogUnitedServices'] }
  CatalogService: Omit<CatalogService, 'detailsUnion'> & { detailsUnion: ResolversParentTypes['CatalogUnitedServices'] }
  CatalogServicesFilterInput: CatalogServicesFilterInput
  CatalogUnitedServices:
    | ResolversParentTypes['AdditionalCatalogService']
    | ResolversParentTypes['CvDbCatalogService']
    | ResolversParentTypes['HotCatalogService']
    | ResolversParentTypes['LogoInPortalCatalogService']
    | ResolversParentTypes['LogoInRubricCatalogService']
    | ResolversParentTypes['LogoOnMainPageCatalogService']
    | ResolversParentTypes['MarketingCatalogService']
    | ResolversParentTypes['RisingUpCatalogService']
    | ResolversParentTypes['VacancyPackageCatalogService']
    | ResolversParentTypes['VacancyPublicationCatalogService']
  CategorizationAttachesMutations: CategorizationAttachesMutations
  CategorizationAttachesQueries: CategorizationAttachesQueries
  CategorizationResultOutput: CategorizationResultOutput
  Categorizator: Categorizator
  ChangeSavedCardsErrorUnion:
    | ResolversParentTypes['HasNoScopesForActionError']
    | ResolversParentTypes['InvalidError']
    | ResolversParentTypes['ServerError']
    | ResolversParentTypes['UserIsNotAuthenticatedError']
  ChangeSavedCardsResponse: Omit<ChangeSavedCardsResponse, 'errors'> & { errors: ReadonlyArray<ResolversParentTypes['ChangeSavedCardsErrorUnion']> }
  ChangeSeekerEmailInput: ChangeSeekerEmailInput
  ChangeSeekerEmailMutation: ChangeSeekerEmailMutation
  ChangeSeekerEmailOutput: ChangeSeekerEmailOutput
  ChangeSeekerNameMutationInput: ChangeSeekerNameMutationInput
  ChangeSeekerNameMutationOutput: ChangeSeekerNameMutationOutput
  ChangeSeekerPasswordByEmailInput: ChangeSeekerPasswordByEmailInput
  ChangeSeekerPasswordByEmailMutations: ChangeSeekerPasswordByEmailMutations
  ChangeSeekerPasswordByPhoneInput: ChangeSeekerPasswordByPhoneInput
  ChangeSeekerPasswordByPhoneMutations: ChangeSeekerPasswordByPhoneMutations
  ChangeSeekerPasswordMutations: ChangeSeekerPasswordMutations
  ChangeSeekerPasswordOutput: ChangeSeekerPasswordOutput
  ChangedFullNameUserManagementEmployerEvent: Omit<ChangedFullNameUserManagementEmployerEvent, 'performer'> & { performer: ResolversParentTypes['PerformerEmployerEventUnion'] }
  ChangedScopesUserManagementEmployerEvent: Omit<ChangedScopesUserManagementEmployerEvent, 'performer'> & { performer: ResolversParentTypes['PerformerEmployerEventUnion'] }
  Chat: Chat
  ChatMessage: ChatMessage
  CheckPayer: CheckPayer
  CheckPayerResultOutput: CheckPayerResultOutput
  CheckVacancyContentCorrectnessForPublishingOutput: CheckVacancyContentCorrectnessForPublishingOutput
  CitiesConnection: CitiesConnection
  CitiesConnectionInput: CitiesConnectionInput
  City: City
  CityDistrict: CityDistrict
  CityEdge: CityEdge
  CloseBannerError: ResolversParentTypes['InvalidError'] | ResolversParentTypes['NotFoundError'] | ResolversParentTypes['ServerError'] | ResolversParentTypes['UserIsNotAuthenticatedError']
  CloseBannerResponse: Omit<CloseBannerResponse, 'error'> & { error: Maybe<ResolversParentTypes['CloseBannerError']> }
  ClosedAdditionalService: ClosedAdditionalService
  ClosedCvDbService: ClosedCvDbService
  ClosedHotService: ClosedHotService
  ClosedLogoInPortalService: ClosedLogoInPortalService
  ClosedLogoInRubricService: ClosedLogoInRubricService
  ClosedMarketingService: ClosedMarketingService
  ClosedRisingUpService: ClosedRisingUpService
  ClosedVacancyEmployerEvent: Omit<ClosedVacancyEmployerEvent, 'performer'> & { performer: ResolversParentTypes['PerformerEmployerEventUnion'] }
  ClosedVacancyPackageService: Omit<ClosedVacancyPackageService, 'additionalServices'> & { additionalServices: ReadonlyArray<Maybe<ResolversParentTypes['ServiceUnited']>> }
  ClosedVacancyPublicationService: ClosedVacancyPublicationService
  ClubMutations: ClubMutations
  ClubPost: ClubPost
  ClubQueries: ClubQueries
  ClubTag: ClubTag
  ClubTopic: ClubTopic
  ClubUserProfile: ClubUserProfile
  Company: Company
  CompanyApplyAutoReplyTemplateConfigurationUpdateInput: CompanyApplyAutoReplyTemplateConfigurationUpdateInput
  CompanyApplyAutoReplyTemplateConfigurationUpdateOutput: CompanyApplyAutoReplyTemplateConfigurationUpdateOutput
  CompanyAssignmentOutput: CompanyAssignmentOutput
  CompanyAssignmentRefuseInput: CompanyAssignmentRefuseInput
  CompanyAssignmentWantInput: CompanyAssignmentWantInput
  CompanyAssignmentsMutations: CompanyAssignmentsMutations
  CompanyBenefit: CompanyBenefit
  CompanyBlockingReasons: CompanyBlockingReasons
  CompanyBufferEvent: CompanyBufferEvent
  CompanyCard: CompanyCard
  CompanyCardAction: CompanyCardAction
  CompanyCardConnection: CompanyCardConnection
  CompanyCardEdge: CompanyCardEdge
  CompanyCardFilterInput: CompanyCardFilterInput
  CompanyCardPayment: CompanyCardPayment
  CompanyCardTicket: CompanyCardTicket
  CompanyEditInput: CompanyEditInput
  CompanyEditOutput: CompanyEditOutput
  CompanyEmailResendConfirmationInput: CompanyEmailResendConfirmationInput
  CompanyEmailResendConfirmationOutput: CompanyEmailResendConfirmationOutput
  CompanyEventInput: CompanyEventInput
  CompanyEventOutput: CompanyEventOutput
  CompanyFreeServiceAddedEvent: Omit<CompanyFreeServiceAddedEvent, 'performer'> & { performer: ResolversParentTypes['PerformerEmployerEventUnion'] }
  CompanyHonors: CompanyHonors
  CompanyHonorsBadge: CompanyHonorsBadge
  CompanyJoinRequest: CompanyJoinRequest
  CompanyMiniProfile: CompanyMiniProfile
  CompanyMiniProfileInput: CompanyMiniProfileInput
  CompanyNotificationSnapshot: CompanyNotificationSnapshot
  CompanyPublishedVacanciesFilter: CompanyPublishedVacanciesFilter
  CompanyPublishedVacanciesFilterInput: CompanyPublishedVacanciesFilterInput
  CompanyPublishedVacanciesQueryFilter: CompanyPublishedVacanciesQueryFilter
  CompanyReason: CompanyReason
  CompanyRegisteredEvent: CompanyRegisteredEvent
  CompanySavedCardAddedEvent: Omit<CompanySavedCardAddedEvent, 'performer'> & { performer: ResolversParentTypes['PerformerEmployerEventUnion'] }
  CompanySavedCardRemovedEvent: Omit<CompanySavedCardRemovedEvent, 'performer'> & { performer: ResolversParentTypes['PerformerEmployerEventUnion'] }
  CompanySendJoinRequestOutput: CompanySendJoinRequestOutput
  CompanySocialMedia: CompanySocialMedia
  CompanySocialMediaInput: CompanySocialMediaInput
  CompanyStaffSize: CompanyStaffSize
  CompanyStateChangedDetails: CompanyStateChangedDetails
  CompanyStateWrapper: CompanyStateWrapper
  CompanyTurbotaStatus: CompanyTurbotaStatus
  CompanyVerificationInput: CompanyVerificationInput
  CompanyVerificationOutput: CompanyVerificationOutput
  CompanyWasMergedError: CompanyWasMergedError
  CompanyWorkRegisteredEvent: CompanyWorkRegisteredEvent
  ConfirmEmailByCodeInput: ConfirmEmailByCodeInput
  ConfirmEmailCodeOutput: ConfirmEmailCodeOutput
  ConfirmProfileDeletionInput: ConfirmProfileDeletionInput
  ConfirmProfileDeletionOutput: ConfirmProfileDeletionOutput
  ConfirmedPhoneUsersOutput: ConfirmedPhoneUsersOutput
  Contract: Contract
  ContractCreationOutput: ContractCreationOutput
  CountStatistics: CountStatistics
  CreateAsCopyProfResumeInput: CreateAsCopyProfResumeInput
  CreateEmployerDuplicateProfileInput: CreateEmployerDuplicateProfileInput
  CreateEmployerProfileInput: CreateEmployerProfileInput
  CreateEmptyProfResumeError: ResolversParentTypes['ProfResumeLimit']
  CreateGroupNotificationInput: CreateGroupNotificationInput
  CreateGroupNotificationOutput: CreateGroupNotificationOutput
  CreateInvoiceOutput: CreateInvoiceOutput
  CreateOrderCvDbInput: CreateOrderCvDbInput
  CreateOrderFromCartResponse: CreateOrderFromCartResponse
  CreateOrderFromServicesInput: CreateOrderFromServicesInput
  CreateOrderFromServicesOutput: CreateOrderFromServicesOutput
  CreateOrderServiceInput: CreateOrderServiceInput
  CreateProfResumeAsCopyError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist'] | ResolversParentTypes['ProfResumeLimit']
  CreateProfResumeError: ResolversParentTypes['ProfResumeDuplicated'] | ResolversParentTypes['ProfResumeLimit']
  CreateProfResumeFromAttachError:
    | ResolversParentTypes['AttachNotFound']
    | ResolversParentTypes['AttachParsingError']
    | ResolversParentTypes['ProfResumeDuplicated']
    | ResolversParentTypes['ProfResumeLimit']
  CreateProfResumeFromAttachInput: CreateProfResumeFromAttachInput
  CreateProfResumeFromAttachOutput: Omit<CreateProfResumeFromAttachOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['CreateProfResumeFromAttachError']>> }
  CreateProfResumeInput: CreateProfResumeInput
  CreateRecruitmentOrderError: ResolversParentTypes['InvalidError'] | ResolversParentTypes['NotFoundError']
  CreateRecruitmentOrderInput: CreateRecruitmentOrderInput
  CreateRecruitmentOrderOutput: Omit<CreateRecruitmentOrderOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['CreateRecruitmentOrderError']>> }
  CreateResumeDraftCreatedNotificationInput: CreateResumeDraftCreatedNotificationInput
  CreateResumeDraftCreatedNotificationOutput: CreateResumeDraftCreatedNotificationOutput
  CreateResumePublishedNotificationInput: CreateResumePublishedNotificationInput
  CreateResumePublishedNotificationOutput: CreateResumePublishedNotificationOutput
  CreateSeekerProfileInput: CreateSeekerProfileInput
  CreateSeekerProfileResponse: CreateSeekerProfileResponse
  CreateSharingDocumentsDeepLinkInput: CreateSharingDocumentsDeepLinkInput
  CreateSharingDocumentsDeepLinkOutput: CreateSharingDocumentsDeepLinkOutput
  CreateTargetedMailingInput: CreateTargetedMailingInput
  CreateTargetedMailingOutput: Omit<CreateTargetedMailingOutput, 'error'> & { error: Maybe<ResolversParentTypes['TargetedMailingSaveErrorUnion']> }
  CreateUserNotificationInput: CreateUserNotificationInput
  CreateUserNotificationOutput: CreateUserNotificationOutput
  CreateWalletError: ResolversParentTypes['UserIsNotAuthenticatedError'] | ResolversParentTypes['WalletExistsError']
  CreateWalletInput: CreateWalletInput
  CreateWalletOutput: Omit<CreateWalletOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['CreateWalletError']>> }
  CreateWalletTransactionError:
    | ResolversParentTypes['InvalidError']
    | ResolversParentTypes['TransactionExistsError']
    | ResolversParentTypes['UserIsNotAuthenticatedError']
    | ResolversParentTypes['WalletDoesNotExistError']
  CreateWalletTransactionInput: CreateWalletTransactionInput
  CreateWalletTransactionOutput: Omit<CreateWalletTransactionOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['CreateWalletTransactionError']>> }
  CreatedAsCopyProfResumeOutput: Omit<CreatedAsCopyProfResumeOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['CreateProfResumeAsCopyError']>> }
  CreatedDraftVacancyEmployerEvent: Omit<CreatedDraftVacancyEmployerEvent, 'performer'> & { performer: ResolversParentTypes['PerformerEmployerEventUnion'] }
  CreatedEmptyProfResumeOutput: Omit<CreatedEmptyProfResumeOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['CreateEmptyProfResumeError']>> }
  CreatedOrderServiceEmployerEvent: Omit<CreatedOrderServiceEmployerEvent, 'performer'> & { performer: ResolversParentTypes['PerformerEmployerEventUnion'] }
  CreatedProfResumeOutput: Omit<CreatedProfResumeOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['CreateProfResumeError']>> }
  CrmAccount: CrmAccount
  CrmAccountConnection: CrmAccountConnection
  CrmAccountEdge: CrmAccountEdge
  CrmAction: CrmAction
  CrmActionAddInput: CrmActionAddInput
  CrmActionAddResult: CrmActionAddResult
  CrmActionBrief: CrmActionBrief
  CrmActionConnection: CrmActionConnection
  CrmActionDraftRequest: CrmActionDraftRequest
  CrmActionEdge: CrmActionEdge
  CrmActionOperationInput: CrmActionOperationInput
  CrmActionType: CrmActionType
  CrmActionUpdateInput: CrmActionUpdateInput
  CrmCall: CrmCall
  CrmCallConnection: CrmCallConnection
  CrmCallEdge: CrmCallEdge
  CrmCompany: CrmCompany
  CrmCompanyConnection: CrmCompanyConnection
  CrmCompanyEdge: CrmCompanyEdge
  CrmCompletionAct: CrmCompletionAct
  CrmContact: CrmContact
  CrmDateInterval: CrmDateInterval
  CrmEditAction: CrmEditAction
  CrmEmployee: CrmEmployee
  CrmFilterCallInput: CrmFilterCallInput
  CrmLinkPhoneToUserCardInput: CrmLinkPhoneToUserCardInput
  CrmManager: CrmManager
  CrmManagerCalendarRequest: CrmManagerCalendarRequest
  CrmMarketingService: CrmMarketingService
  CrmMarketingServiceConnection: CrmMarketingServiceConnection
  CrmMarketingServiceEdge: CrmMarketingServiceEdge
  CrmMarketingServiceFilter: CrmMarketingServiceFilter
  CrmMeetingReason: CrmMeetingReason
  CrmMetaCompany: CrmMetaCompany
  CrmMultiUser: CrmMultiUser
  CrmPhone: CrmPhone
  CrmPinActionInput: CrmPinActionInput
  CrmPlannedActionByDay: CrmPlannedActionByDay
  CrmQuarter: CrmQuarter
  CrmQuarterInput: CrmQuarterInput
  CrmUserCard: CrmUserCard
  CrmUserCardAddInput: CrmUserCardAddInput
  CrmUserCardEdit: CrmUserCardEdit
  CrmUserCardElectInput: CrmUserCardElectInput
  CrmUserCardRole: CrmUserCardRole
  CrmUserCardUpdateInput: CrmUserCardUpdateInput
  CrmUserPhoneUpdateInput: CrmUserPhoneUpdateInput
  CustomNotificationDetails: CustomNotificationDetails
  CustomNotificationElement: CustomNotificationElement
  CustomNotificationElementButton: CustomNotificationElementButton
  CustomNotificationElementText: CustomNotificationElementText
  CvDbCatalogService: CvDbCatalogService
  CvDbServiceEmployerEventSnapshot: CvDbServiceEmployerEventSnapshot
  CvRubricInput: CvRubricInput
  CvTagInput: CvTagInput
  CvdbPriceInput: CvdbPriceInput
  DailyResumeViewsReportCalculated: DailyResumeViewsReportCalculated
  DashboardCompany: DashboardCompany
  DashboardCompanyConnection: DashboardCompanyConnection
  DashboardCompanyEdge: DashboardCompanyEdge
  DashboardCompanyInput: DashboardCompanyInput
  DashboardMultiUserInput: DashboardMultiUserInput
  DashboardMultiUserResponse: DashboardMultiUserResponse
  DashboardRatingInput: DashboardRatingInput
  DashboardRatingResponse: DashboardRatingResponse
  DashboardResponse: DashboardResponse
  Date: Scalars['Date']
  DateCounter: DateCounter
  DateInterval: DateInterval
  DateIntervalInput: DateIntervalInput
  DateRange: DateRange
  DateRangeInput: DateRangeInput
  DateTime: Scalars['DateTime']
  DateTimeOffset: Scalars['DateTimeOffset']
  DealCard: DealCard
  DealCardAccount: DealCardAccount
  DealCardPayment: DealCardPayment
  DealDetailsOutput: DealDetailsOutput
  DealForecast: DealForecast
  DealOrderCard: DealOrderCard
  DealTableOutput: DealTableOutput
  DealTableRequestInput: DealTableRequestInput
  Decimal: Scalars['Decimal']
  DeleteAllNotificationsError: ResolversParentTypes['NotificationNotFoundError'] | ResolversParentTypes['UnknownServerError']
  DeleteAllNotificationsOutput: Omit<DeleteAllNotificationsOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['DeleteAllNotificationsError']>> }
  DeleteNotificationsError: ResolversParentTypes['NotificationNotFoundError'] | ResolversParentTypes['UnknownServerError']
  DeleteNotificationsInput: DeleteNotificationsInput
  DeleteNotificationsOutput: Omit<DeleteNotificationsOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['DeleteNotificationsError']>> }
  DeletePostFromHotOutput: DeletePostFromHotOutput
  DeletePostFromTopOutput: DeletePostFromTopOutput
  DeleteProfResumeInput: DeleteProfResumeInput
  DeleteProfResumePortfolioOutput: Omit<DeleteProfResumePortfolioOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['DeleteSeekerProfResumePortfoliosError']>> }
  DeleteProfResumeSocialNetworksOutput: Omit<DeleteProfResumeSocialNetworksOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['DeleteSeekerProfResumeSocialNetworksError']>> }
  DeleteProfileOutput: ResolversParentTypes['DeleteProfileWithEmailOutput'] | ResolversParentTypes['DeleteProfileWithSmsOutput']
  DeleteProfileWithEmailOutput: DeleteProfileWithEmailOutput
  DeleteProfileWithSmsOutput: DeleteProfileWithSmsOutput
  DeleteSeekerProfResumeAdditionalEducationsError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  DeleteSeekerProfResumeAdditionalInfoError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  DeleteSeekerProfResumeAdditionalPhonesError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  DeleteSeekerProfResumeAdditionalPhonesOutput: Omit<DeleteSeekerProfResumeAdditionalPhonesOutput, 'errors'> & {
    errors: Maybe<ReadonlyArray<ResolversParentTypes['DeleteSeekerProfResumeAdditionalPhonesError']>>
  }
  DeleteSeekerProfResumeDiiaCertificateError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  DeleteSeekerProfResumeEducationsError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  DeleteSeekerProfResumeError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  DeleteSeekerProfResumeExperiencesError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  DeleteSeekerProfResumeLanguageSkillsError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  DeleteSeekerProfResumePortfoliosError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  DeleteSeekerProfResumeSocialNetworksError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  DeleteTargetedMailingOutput: DeleteTargetedMailingOutput
  DeleteVacancyInput: DeleteVacancyInput
  DeleteVacancyOutput: DeleteVacancyOutput
  DeletedProfResumeAdditionalEducationsOutput: Omit<DeletedProfResumeAdditionalEducationsOutput, 'errors'> & {
    errors: Maybe<ReadonlyArray<ResolversParentTypes['DeleteSeekerProfResumeAdditionalEducationsError']>>
  }
  DeletedProfResumeAdditionalInfoOutput: Omit<DeletedProfResumeAdditionalInfoOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['DeleteSeekerProfResumeAdditionalInfoError']>> }
  DeletedProfResumeEducationsOutput: Omit<DeletedProfResumeEducationsOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['DeleteSeekerProfResumeEducationsError']>> }
  DeletedProfResumeExperiencesOutput: Omit<DeletedProfResumeExperiencesOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['DeleteSeekerProfResumeExperiencesError']>> }
  DeletedProfResumeLanguageSkillsOutput: Omit<DeletedProfResumeLanguageSkillsOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['DeleteSeekerProfResumeLanguageSkillsError']>> }
  DeletedProfResumeOutput: Omit<DeletedProfResumeOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['DeleteSeekerProfResumeError']>> }
  DeletedUserManagementEmployerEvent: Omit<DeletedUserManagementEmployerEvent, 'performer'> & { performer: ResolversParentTypes['PerformerEmployerEventUnion'] }
  DeletedVacancyEmployerEvent: Omit<DeletedVacancyEmployerEvent, 'performer'> & { performer: ResolversParentTypes['PerformerEmployerEventUnion'] }
  DirectoryItem: DirectoryItem
  Discount: ResolversParentTypes['ManagerDiscount'] | ResolversParentTypes['PromoCodeDiscount']
  DislikeSeekerVacanciesInput: DislikeSeekerVacanciesInput
  DislikeSeekerVacanciesOutput: DislikeSeekerVacanciesOutput
  DistrictFacet: DistrictFacet
  DownloadActInput: DownloadActInput
  DownloadActOutput: DownloadActOutput
  DownloadInvoiceInput: DownloadInvoiceInput
  DownloadInvoiceInputItem: DownloadInvoiceInputItem
  DownloadInvoiceOutput: DownloadInvoiceOutput
  DropAppliedWalletBonusResponse: DropAppliedWalletBonusResponse
  DropSelectedPromoCodeResponse: DropSelectedPromoCodeResponse
  DuplicateCompany: DuplicateCompany
  EditCvTagInput: EditCvTagInput
  EditGiftLimitInput: EditGiftLimitInput
  EditLabelInput: EditLabelInput
  EditedVacancyCityEmployerEvent: EditedVacancyCityEmployerEvent
  EditedVacancyEmployerEvent: Omit<EditedVacancyEmployerEvent, 'editedFields' | 'performer'> & {
    editedFields: ReadonlyArray<ResolversParentTypes['EditedVacancyFieldEmployerEventUnion']>
    performer: ResolversParentTypes['PerformerEmployerEventUnion']
  }
  EditedVacancyFieldEmployerEvent: EditedVacancyFieldEmployerEvent
  EditedVacancyFieldEmployerEventUnion:
    | ResolversParentTypes['EditedVacancyCityEmployerEvent']
    | ResolversParentTypes['EditedVacancyFieldEmployerEvent']
    | ResolversParentTypes['EditedVacancyTitleEmployerEvent']
  EditedVacancyTitleEmployerEvent: EditedVacancyTitleEmployerEvent
  EducationLevel: EducationLevel
  EmailAlreadyExistsError: EmailAlreadyExistsError
  EmailSenderResult: EmailSenderResult
  EmailStatistics: Omit<EmailStatistics, 'links'> & { links: ReadonlyArray<ResolversParentTypes['TargetedMailingLinkUnion']> }
  Employee: Employee
  EmployeeAtsRelation: EmployeeAtsRelation
  EmployeeChangeEmailInput: EmployeeChangeEmailInput
  EmployeeChangeEmailOutput: Omit<EmployeeChangeEmailOutput, 'error'> & { error: Maybe<ResolversParentTypes['EmployeeEditError']> }
  EmployeeChangeOutput: Omit<EmployeeChangeOutput, 'error'> & { error: Maybe<ResolversParentTypes['EmployeeEditError']> }
  EmployeeChangePasswordInput: EmployeeChangePasswordInput
  EmployeeConnection: EmployeeConnection
  EmployeeCreateError: ResolversParentTypes['EmailAlreadyExistsError'] | ResolversParentTypes['ForbiddenToMutateUsersError']
  EmployeeCreateInput: EmployeeCreateInput
  EmployeeCreateOutput: Omit<EmployeeCreateOutput, 'error'> & { error: Maybe<ResolversParentTypes['EmployeeCreateError']> }
  EmployeeCvDbSavedSearchDeleteInput: EmployeeCvDbSavedSearchDeleteInput
  EmployeeCvDbSavedSearchDeleteOutput: EmployeeCvDbSavedSearchDeleteOutput
  EmployeeEdge: EmployeeEdge
  EmployeeEditError:
    | ResolversParentTypes['EmailAlreadyExistsError']
    | ResolversParentTypes['ForbiddenToMutateUsersError']
    | ResolversParentTypes['PasswordConfirmationFailedError']
    | ResolversParentTypes['TokenAlreadyUsedError']
  EmployeeEditInput: EmployeeEditInput
  EmployeeEditOutput: Omit<EmployeeEditOutput, 'error'> & { error: Maybe<ResolversParentTypes['EmployeeEditError']> }
  EmployeeHasScopesInput: EmployeeHasScopesInput
  EmployeeHasScopesOutput: EmployeeHasScopesOutput
  EmployeeJoiningRequestAlreadyReviewedError: EmployeeJoiningRequestAlreadyReviewedError
  EmployeeJoiningReviewErrorUnion: ResolversParentTypes['EmployeeJoiningRequestAlreadyReviewedError']
  EmployeeJoiningReviewInput: EmployeeJoiningReviewInput
  EmployeeJoiningReviewOutput: Omit<EmployeeJoiningReviewOutput, 'error'> & { error: Maybe<ResolversParentTypes['EmployeeJoiningReviewErrorUnion']> }
  EmployeeLimitCounter: EmployeeLimitCounter
  EmployeeMakeMainOutput: EmployeeMakeMainOutput
  EmployeeResendInvitationInput: EmployeeResendInvitationInput
  EmployeeResendInvitationOutput: EmployeeResendInvitationOutput
  EmployeeRoleBasedScope: EmployeeRoleBasedScope
  EmployeeScopeChangeUserManagementEmployerEvent: EmployeeScopeChangeUserManagementEmployerEvent
  EmployeeSendChangeEmailInput: EmployeeSendChangeEmailInput
  EmployeeSetAsAdminInput: EmployeeSetAsAdminInput
  EmployeeStatusChanged: EmployeeStatusChanged
  EmployeeSubscription: Omit<EmployeeSubscription, 'options'> & { options: Maybe<ReadonlyArray<ResolversParentTypes['EmployeeSubscriptionSubOptionUnion']>> }
  EmployeeSubscriptionAnswerTemplate: EmployeeSubscriptionAnswerTemplate
  EmployeeSubscriptionAnswerTemplateInput: EmployeeSubscriptionAnswerTemplateInput
  EmployeeSubscriptionAnswerTemplateOutput: EmployeeSubscriptionAnswerTemplateOutput
  EmployeeSubscriptionIntRange: EmployeeSubscriptionIntRange
  EmployeeSubscriptionSavedCvDbSearchRequest: EmployeeSubscriptionSavedCvDbSearchRequest
  EmployeeSubscriptionSavedCvDbSearchSubOption: EmployeeSubscriptionSavedCvDbSearchSubOption
  EmployeeSubscriptionSubOptionUnion: ResolversParentTypes['EmployeeSubscriptionSavedCvDbSearchSubOption']
  EmployeeSubscriptionType: EmployeeSubscriptionType
  EmployeeSubscriptionsMutations: EmployeeSubscriptionsMutations
  EmployeeSubscriptionsQueries: EmployeeSubscriptionsQueries
  EmployeeSubscriptionsUpdateItemInput: EmployeeSubscriptionsUpdateItemInput
  EmployeeSubscriptionsUpdateOutput: EmployeeSubscriptionsUpdateOutput
  EmployeeSubscriptionsUpdateSubItemInput: EmployeeSubscriptionsUpdateSubItemInput
  EmployeeSummaryServiceLimit: EmployeeSummaryServiceLimit
  EmployeesBlockInput: EmployeesBlockInput
  EmployeesBlockOutput: EmployeesBlockOutput
  EmployeesDeleteInput: EmployeesDeleteInput
  EmployeesDeleteOutput: EmployeesDeleteOutput
  EmployeesFilterInput: EmployeesFilterInput
  EmployeesMultiScopesUpdateOutput: EmployeesMultiScopesUpdateOutput
  EmployeesScopesMultiUpdateInput: EmployeesScopesMultiUpdateInput
  EmployeesScopesMultiUpdateItemInput: EmployeesScopesMultiUpdateItemInput
  EmployeesUnblockInput: EmployeesUnblockInput
  EmployeesUnblockOutput: EmployeesUnblockOutput
  EmployerAchievement:
    | ResolversParentTypes['ApplicationsViewedEmployerAchievement']
    | ResolversParentTypes['OpenedContactOrVacancyOfferedEmployerAchievement']
    | ResolversParentTypes['PhoneNumberConfirmedEmployerAchievement']
    | ResolversParentTypes['VacancyPublishedEmployerAchievement']
    | ResolversParentTypes['VacancyPublishedWithFilledAddressAndSalaryEmployerAchievement']
  EmployerEventLogConnection: Omit<EmployerEventLogConnection, 'items'> & { items: Maybe<ReadonlyArray<ResolversParentTypes['EmployerEventUnion']>> }
  EmployerEventPerformer: EmployerEventPerformer
  EmployerEventSnapshot: EmployerEventSnapshot
  EmployerEventUnion:
    | ResolversParentTypes['ActivatedOrderByTurbotaServiceEmployerEvent']
    | ResolversParentTypes['ActivatedServiceEmployerEvent']
    | ResolversParentTypes['AddedNewUserManagementEmployerEvent']
    | ResolversParentTypes['ApprovedProlongationRequestVacancyEmployerEvent']
    | ResolversParentTypes['ApprovedReviewVacancyEmployerEvent']
    | ResolversParentTypes['AutoProlongationDisabledVacancyEmployerEvent']
    | ResolversParentTypes['AutoProlongationEnabledVacancyEmployerEvent']
    | ResolversParentTypes['AutoProlongedVacancyEmployerEvent']
    | ResolversParentTypes['BlockedUserManagementEmployerEvent']
    | ResolversParentTypes['ChangedFullNameUserManagementEmployerEvent']
    | ResolversParentTypes['ChangedScopesUserManagementEmployerEvent']
    | ResolversParentTypes['ClosedVacancyEmployerEvent']
    | ResolversParentTypes['CompanyFreeServiceAddedEvent']
    | ResolversParentTypes['CompanySavedCardAddedEvent']
    | ResolversParentTypes['CompanySavedCardRemovedEvent']
    | ResolversParentTypes['CreatedDraftVacancyEmployerEvent']
    | ResolversParentTypes['CreatedOrderServiceEmployerEvent']
    | ResolversParentTypes['DeletedUserManagementEmployerEvent']
    | ResolversParentTypes['DeletedVacancyEmployerEvent']
    | ResolversParentTypes['EditedVacancyEmployerEvent']
    | ResolversParentTypes['EndedHotPeriodVacancyEmployerEvent']
    | ResolversParentTypes['EndedServiceEmployerEvent']
    | ResolversParentTypes['MadeHotVacancyEmployerEvent']
    | ResolversParentTypes['OfferedVacancyCvDbEmployerEvent']
    | ResolversParentTypes['OpenedContactsCvDbEmployerEvent']
    | ResolversParentTypes['OrderFreemiumServicesAccruedServiceEmployerEvent']
    | ResolversParentTypes['OrderServicesAccruedServiceEmployerEvent']
    | ResolversParentTypes['OwnerChangedVacancyEmployerEvent']
    | ResolversParentTypes['PaymentReceivedServiceEmployerEvent']
    | ResolversParentTypes['ProlongationRequestVacancyEmployerEvent']
    | ResolversParentTypes['PublishedVacancyEmployerEvent']
    | ResolversParentTypes['RePublishedVacancyEmployerEvent']
    | ResolversParentTypes['RejectedByModeratorVacancyEmployerEvent']
    | ResolversParentTypes['RejectedProlongationRequestVacancyEmployerEvent']
    | ResolversParentTypes['RejectedReviewVacancyEmployerEvent']
    | ResolversParentTypes['SentToReviewVacancyEmployerEvent']
    | ResolversParentTypes['UsedHotServiceEmployerEvent']
    | ResolversParentTypes['UsedVacancyServiceEmployerEvent']
    | ResolversParentTypes['WalletTransactionEvent']
  EmployerEventsLogFilterInput: EmployerEventsLogFilterInput
  EmployerOnBoardingCheckListOutput: EmployerOnBoardingCheckListOutput
  EmployerRegistration: EmployerRegistration
  EmployerRegistrationResponse: EmployerRegistrationResponse
  EmployerRegistrationValidationInput: EmployerRegistrationValidationInput
  EmployerServiceLimitRequestInput: EmployerServiceLimitRequestInput
  EmployerSummaryServiceLimitInput: EmployerSummaryServiceLimitInput
  EmployerWalletUser: EmployerWalletUser
  EmployersEventUnionEdge: Omit<EmployersEventUnionEdge, 'node'> & { node: ResolversParentTypes['EmployerEventUnion'] }
  EndedHotPeriodVacancyEmployerEvent: EndedHotPeriodVacancyEmployerEvent
  EndedServiceEmployerEvent: Omit<EndedServiceEmployerEvent, 'service'> & { service: ResolversParentTypes['ServiceEmployerEventSnapshotUnion'] }
  Error:
    | ResolversParentTypes['AtsProjectCreationError']
    | ResolversParentTypes['CompanyWasMergedError']
    | ResolversParentTypes['HasNoAccessToRequestedFieldsError']
    | ResolversParentTypes['HasNoScopesForActionError']
    | ResolversParentTypes['InvalidError']
    | ResolversParentTypes['NoAvailableLimitsError']
    | ResolversParentTypes['NotAvailableError']
    | ResolversParentTypes['NotFoundError']
    | ResolversParentTypes['ServerError']
    | ResolversParentTypes['TransactionExistsError']
    | ResolversParentTypes['TurbotaWasExpiredError']
    | ResolversParentTypes['UserIsNotAuthenticatedError']
    | ResolversParentTypes['UserIsNotEmployerError']
    | ResolversParentTypes['WalletDoesNotExistError']
    | ResolversParentTypes['WalletExistsError']
  EventInterface:
    | ResolversParentTypes['CompanyBufferEvent']
    | ResolversParentTypes['CompanyRegisteredEvent']
    | ResolversParentTypes['CompanyWorkRegisteredEvent']
    | ResolversParentTypes['InvoiceExpiredEvent']
    | ResolversParentTypes['InvoicePaidEvent']
    | ResolversParentTypes['InvoicePaymentBankEvent']
    | ResolversParentTypes['InvoiceRequestedEvent']
    | ResolversParentTypes['MetanotebookAssignedEvent']
    | ResolversParentTypes['PersonalDiscountEvent']
    | ResolversParentTypes['ProfileRegisteredEvent']
    | ResolversParentTypes['ServiceIsExpiringEvent']
    | ResolversParentTypes['TurbotaEvent']
    | ResolversParentTypes['UncompletedPaymentEvent']
    | ResolversParentTypes['UserLeftBasketEvent']
    | ResolversParentTypes['VacancyBannedEvent']
    | ResolversParentTypes['VacancyOnWorkWillExpireInFiveDaysEvent']
  EventTopicCompleteInput: EventTopicCompleteInput
  Experience: Experience
  ExperienceBasicQuestion: ExperienceBasicQuestion
  ExternalCourse: ResolversParentTypes['PrometheusExternalCourse']
  ExternalUrlPaymentMethod: ExternalUrlPaymentMethod
  Facet: Omit<Facet, 'item'> & { item: ResolversParentTypes['ApplyFacetUnion'] }
  FavoriteVacanciesRemindedDetails: FavoriteVacanciesRemindedDetails
  FeedbackInput: FeedbackInput
  FeedbackMutations: FeedbackMutations
  FeedbackOutput: FeedbackOutput
  FeedbackQueries: FeedbackQueries
  FeedbackReporterInput: FeedbackReporterInput
  FileAttach: FileAttach
  FilterAccountsInput: FilterAccountsInput
  FilterActionsInput: FilterActionsInput
  FilterAssignmentsInput: FilterAssignmentsInput
  FilterCompaniesInput: FilterCompaniesInput
  FilterCompanyOrdersRequest: FilterCompanyOrdersRequest
  FilterGiftRequestInput: FilterGiftRequestInput
  FilterHelperCompaniesInput: FilterHelperCompaniesInput
  FilterMarketingServicesInput: FilterMarketingServicesInput
  FilterMyServicesInput: FilterMyServicesInput
  FilterNovaPoshtaRegisterListInput: FilterNovaPoshtaRegisterListInput
  FilterTeamActionsInput: FilterTeamActionsInput
  FilteredCvListInput: FilteredCvListInput
  Float: Scalars['Float']
  ForbiddenToMutateUsersError: ForbiddenToMutateUsersError
  GamificationDates: GamificationDates
  GamificationWave: GamificationWave
  GamificationWaveList: GamificationWaveList
  GenerateExternalPaymentUrlByInvoiceInput: GenerateExternalPaymentUrlByInvoiceInput
  GenerateExternalPaymentUrlByInvoiceOutput: GenerateExternalPaymentUrlByInvoiceOutput
  GenerateExternalPaymentUrlInput: GenerateExternalPaymentUrlInput
  GenerateExternalPaymentUrlOutput: GenerateExternalPaymentUrlOutput
  GetCurrentEmployerErrorUnion: ResolversParentTypes['InvalidError'] | ResolversParentTypes['NotFoundError'] | ResolversParentTypes['UserIsNotAuthenticatedError']
  GetCurrentEmployerOutput: Omit<GetCurrentEmployerOutput, 'errors'> & { errors: ReadonlyArray<ResolversParentTypes['GetCurrentEmployerErrorUnion']> }
  GetSeekerDislikedVacanciesTotalCountOutput: GetSeekerDislikedVacanciesTotalCountOutput
  GetSeekerFavoriteVacanciesTotalCountOutput: GetSeekerFavoriteVacanciesTotalCountOutput
  GetVacancyByIdResponse: Omit<GetVacancyByIdResponse, 'error'> & { error: Maybe<ResolversParentTypes['GetVacancyError']> }
  GetVacancyError: ResolversParentTypes['HasNoAccessToRequestedFieldsError'] | ResolversParentTypes['NotAvailableError'] | ResolversParentTypes['NotFoundError']
  GetVacancySearchSuggestsInput: GetVacancySearchSuggestsInput
  GetVacancySearchSuggestsOutput: GetVacancySearchSuggestsOutput
  Gift: Gift
  GiftCompanyWaveLimit: GiftCompanyWaveLimit
  GiftDeliverySlot: GiftDeliverySlot
  GiftDeliverySlotInput: GiftDeliverySlotInput
  GiftDetailInput: GiftDetailInput
  GiftLimit: GiftLimit
  GiftLimitsTransferHistoryByTeamLeadRequestInput: GiftLimitsTransferHistoryByTeamLeadRequestInput
  GiftOccasion: GiftOccasion
  GiftRequest: GiftRequest
  GiftRequestConnection: GiftRequestConnection
  GiftRequestDeliveryOptions: GiftRequestDeliveryOptions
  GiftRequestDetail: GiftRequestDetail
  GiftRequestEdge: GiftRequestEdge
  GiftRequestOccasion: GiftRequestOccasion
  GiftTeamLimit: GiftTeamLimit
  GiftTeamLimitByManager: GiftTeamLimitByManager
  GiftTeamLimitByManagerConnection: GiftTeamLimitByManagerConnection
  GiftTeamLimitByManagerDetail: GiftTeamLimitByManagerDetail
  GiftTeamLimitByManagerEdge: GiftTeamLimitByManagerEdge
  GiftTeamLimitTransferHistory: GiftTeamLimitTransferHistory
  GiftTeamLimitTransferHistoryConnection: GiftTeamLimitTransferHistoryConnection
  GiftTeamLimitTransferHistoryEdge: GiftTeamLimitTransferHistoryEdge
  GiftWaveLimit: GiftWaveLimit
  GiftsFilters: GiftsFilters
  GiftsMutations: GiftsMutations
  GiveAnswerInput: GiveAnswerInput
  GroupTag: GroupTag
  Guid: Scalars['Guid']
  HasNoAccessToRequestedFieldsError: HasNoAccessToRequestedFieldsError
  HasNoScopesForActionError: HasNoScopesForActionError
  HelperCompany: HelperCompany
  HelperCompanyConnection: HelperCompanyConnection
  HelperCompanyDetail: HelperCompanyDetail
  HelperCompanyEdge: HelperCompanyEdge
  HotCatalogService: HotCatalogService
  HotServiceEmployerEventSnapshot: HotServiceEmployerEventSnapshot
  ID: Scalars['ID']
  InformationBanner: InformationBanner
  Int: Scalars['Int']
  IntIntervalInput: IntIntervalInput
  IntRange: IntRange
  IntRangeInput: IntRangeInput
  InvalidError: InvalidError
  Invoice: Omit<Invoice, 'paymentMethod'> & { paymentMethod: ResolversParentTypes['PaymentMethods'] }
  InvoiceAct: InvoiceAct
  InvoiceCreateInput: InvoiceCreateInput
  InvoiceExpiredEvent: InvoiceExpiredEvent
  InvoicePaidEvent: InvoicePaidEvent
  InvoicePaymentBankEvent: InvoicePaymentBankEvent
  InvoiceRequestedEvent: InvoiceRequestedEvent
  JiraServiceDeskOrganisation: JiraServiceDeskOrganisation
  JobInstantReceivedNotificationDetails: JobInstantReceivedNotificationDetails
  JobRecommendationReceivedDetails: JobRecommendationReceivedDetails
  Keyword: Keyword
  Lang: Lang
  LangSuggest: LangSuggest
  LanguageBasicQuestion: LanguageBasicQuestion
  LanguageSkillLevel: LanguageSkillLevel
  LegalPayerInput: LegalPayerInput
  LetterFilters: LetterFilters
  LimitsChangeRequestedDetails: LimitsChangeRequestedDetails
  LimitsFilters: LimitsFilters
  ListSeekerDislikedVacanciesInput: ListSeekerDislikedVacanciesInput
  ListSeekerDislikedVacanciesOutput: ListSeekerDislikedVacanciesOutput
  ListSeekerFavoriteVacanciesInput: ListSeekerFavoriteVacanciesInput
  ListSeekerFavoriteVacanciesOutput: ListSeekerFavoriteVacanciesOutput
  LoginMutations: LoginMutations
  LoginWithOtpMutations: LoginWithOtpMutations
  LogoInPortalCatalogService: LogoInPortalCatalogService
  LogoInPortalServiceEmployerEventSnapshot: LogoInPortalServiceEmployerEventSnapshot
  LogoInRubricCatalogService: LogoInRubricCatalogService
  LogoInRubricServiceEmployerEventSnapshot: LogoInRubricServiceEmployerEventSnapshot
  LogoOnMainPageCatalogService: LogoOnMainPageCatalogService
  LogoOnMainPageServiceEmployerEventSnapshot: LogoOnMainPageServiceEmployerEventSnapshot
  LogoPortal: LogoPortal
  LogoPortalBranch: LogoPortalBranch
  Long: Scalars['Long']
  MadeHotVacancyEmployerEvent: Omit<MadeHotVacancyEmployerEvent, 'performer'> & { performer: ResolversParentTypes['PerformerEmployerEventUnion'] }
  MailingStatistics: MailingStatistics
  Manager: Manager
  ManagerContacts: ManagerContacts
  ManagerDeals: ManagerDeals
  ManagerDepartment: ManagerDepartment
  ManagerDiscount: ManagerDiscount
  ManagerEventPerformer: ManagerEventPerformer
  ManagerGiftLimit: ManagerGiftLimit
  ManagerWalletUser: ManagerWalletUser
  MarketingCatalogService: MarketingCatalogService
  MarketingGift: MarketingGift
  MarketingGiftRequest: MarketingGiftRequest
  MarketingGiftRequestConnection: MarketingGiftRequestConnection
  MarketingGiftRequestDetail: MarketingGiftRequestDetail
  MarketingGiftRequestEdge: MarketingGiftRequestEdge
  MarketingLimitTransfer: MarketingLimitTransfer
  MarketingLimitTransferConnection: MarketingLimitTransferConnection
  MarketingLimitTransferDetail: MarketingLimitTransferDetail
  MarketingLimitTransferEdge: MarketingLimitTransferEdge
  MarketingServiceEmployerEventSnapshot: MarketingServiceEmployerEventSnapshot
  MassRefuseOutput: MassRefuseOutput
  MessagesConnection: MessagesConnection
  MessagesEdge: MessagesEdge
  MessengerContact: MessengerContact
  MessengerContactInput: MessengerContactInput
  MetanotebookAssignedEvent: MetanotebookAssignedEvent
  Metro: Metro
  MetroFacet: MetroFacet
  MetroLine: MetroLine
  MetroLineFacet: MetroLineFacet
  MobilePushStatistics: MobilePushStatistics
  ModifyVacancyErrorUnion:
    | ResolversParentTypes['CompanyWasMergedError']
    | ResolversParentTypes['InvalidError']
    | ResolversParentTypes['NoAvailableLimitsError']
    | ResolversParentTypes['ServerError']
    | ResolversParentTypes['TurbotaWasExpiredError']
    | ResolversParentTypes['UserIsNotAuthenticatedError']
    | ResolversParentTypes['UserIsNotEmployerError']
  MovingGiftLimitsDetailInput: MovingGiftLimitsDetailInput
  MovingGiftLimitsInput: MovingGiftLimitsInput
  MovingGiftLimitsOutput: MovingGiftLimitsOutput
  MultipleVacanciesActionItem: MultipleVacanciesActionItem
  MultipleVacanciesChangeClosing: MultipleVacanciesChangeClosing
  Mutation: {}
  MyServicesConnection: Omit<MyServicesConnection, 'items'> & { items: Maybe<ReadonlyArray<Maybe<ResolversParentTypes['ServiceUnited']>>> }
  MyVacanciesByCityFilter: MyVacanciesByCityFilter
  MyVacanciesByEmployerFilter: MyVacanciesByEmployerFilter
  MyVacanciesByStatusFilter: MyVacanciesByStatusFilter
  MyVacanciesConnection: MyVacanciesConnection
  MyVacanciesFilterInput: MyVacanciesFilterInput
  NoAvailableLimitsError: NoAvailableLimitsError
  NotAvailableError: NotAvailableError
  NotFoundError: NotFoundError
  NotFoundProfResume: NotFoundProfResume
  Notification: Omit<Notification, 'details'> & { details: Maybe<ResolversParentTypes['NotificationDetails']> }
  NotificationCommonVacancySnapshot: NotificationCommonVacancySnapshot
  NotificationCommonVacancySnapshotInterface:
    | ResolversParentTypes['NotificationCommonVacancySnapshot']
    | ResolversParentTypes['NotificationEndingDaysVacancySnapshot']
    | ResolversParentTypes['NotificationOwnerChangedVacancySnapshot']
  NotificationDetails:
    | ResolversParentTypes['ApplicationInvitedNotificationDetails']
    | ResolversParentTypes['ApplicationRefusedNotificationDetails']
    | ResolversParentTypes['ApplicationSentNotificationDetails']
    | ResolversParentTypes['ApplicationViewedNotificationDetails']
    | ResolversParentTypes['CompanyStateChangedDetails']
    | ResolversParentTypes['CustomNotificationDetails']
    | ResolversParentTypes['DailyResumeViewsReportCalculated']
    | ResolversParentTypes['FavoriteVacanciesRemindedDetails']
    | ResolversParentTypes['JobInstantReceivedNotificationDetails']
    | ResolversParentTypes['JobRecommendationReceivedDetails']
    | ResolversParentTypes['LimitsChangeRequestedDetails']
    | ResolversParentTypes['OrderActivatedByTurbotaDetails']
    | ResolversParentTypes['ResumeDraftCreatedNotificationDetails']
    | ResolversParentTypes['ResumePublishedNotificationDetails']
    | ResolversParentTypes['SendNotificationSetEmailDetails']
    | ResolversParentTypes['UnpaidOrderDetails']
    | ResolversParentTypes['UserEmailHasBeenSetNotificationDetails']
    | ResolversParentTypes['VacancyAwaitingApprovalDetails']
    | ResolversParentTypes['VacancyBannedByModeratorDetails']
    | ResolversParentTypes['VacancyEndingCountDetails']
    | ResolversParentTypes['VacancyEndingLeftDaysDetails']
    | ResolversParentTypes['VacancyOwnerChangedDetails']
    | ResolversParentTypes['VacancyPublicationApprovedDetails']
    | ResolversParentTypes['VacancyPublicationEndedDetails']
    | ResolversParentTypes['VacancyPublicationRejectedDetails']
  NotificationElementButtonInput: NotificationElementButtonInput
  NotificationElementInput: NotificationElementInput
  NotificationElementTextInput: NotificationElementTextInput
  NotificationEmployeeInitiator: NotificationEmployeeInitiator
  NotificationEmployeeSnapshot: NotificationEmployeeSnapshot
  NotificationEndingDaysPublishPeriodSnapshot: NotificationEndingDaysPublishPeriodSnapshot
  NotificationEndingDaysVacancySnapshot: NotificationEndingDaysVacancySnapshot
  NotificationInitiator: ResolversParentTypes['NotificationEmployeeInitiator'] | ResolversParentTypes['NotificationManagerInitiator']
  NotificationInitiatorInterface: ResolversParentTypes['NotificationEmployeeInitiator'] | ResolversParentTypes['NotificationManagerInitiator']
  NotificationManagerInitiator: NotificationManagerInitiator
  NotificationNotFoundError: NotificationNotFoundError
  NotificationOrderSnapshot: NotificationOrderSnapshot
  NotificationOwnerChangedVacancySnapshot: NotificationOwnerChangedVacancySnapshot
  NotificationVacancyEndingCountSnapshot: NotificationVacancyEndingCountSnapshot
  NotificationsConnection: NotificationsConnection
  NotificationsEdge: NotificationsEdge
  NotificationsError: ResolversParentTypes['NotificationNotFoundError'] | ResolversParentTypes['UnknownServerError']
  NovaPoshtaAddCounterpartyOutput: NovaPoshtaAddCounterpartyOutput
  NovaPoshtaAddDocumentInput: NovaPoshtaAddDocumentInput
  NovaPoshtaAddDocumentOutput: NovaPoshtaAddDocumentOutput
  NovaPoshtaAddDocumentToRegisterDetailInput: NovaPoshtaAddDocumentToRegisterDetailInput
  NovaPoshtaAddDocumentToRegisterInput: NovaPoshtaAddDocumentToRegisterInput
  NovaPoshtaAddDocumentToRegisterOutput: NovaPoshtaAddDocumentToRegisterOutput
  NovaPoshtaAddGiftAddressOutput: NovaPoshtaAddGiftAddressOutput
  NovaPoshtaCity: NovaPoshtaCity
  NovaPoshtaCounterpartyGraphType: NovaPoshtaCounterpartyGraphType
  NovaPoshtaDeleteDocumentOutput: NovaPoshtaDeleteDocumentOutput
  NovaPoshtaDocument: NovaPoshtaDocument
  NovaPoshtaDoorsAddressGraphType: NovaPoshtaDoorsAddressGraphType
  NovaPoshtaFullAddressGraphType: NovaPoshtaFullAddressGraphType
  NovaPoshtaNewAddedDocument: NovaPoshtaNewAddedDocument
  NovaPoshtaOptionSeat: NovaPoshtaOptionSeat
  NovaPoshtaRegisterDocument: NovaPoshtaRegisterDocument
  NovaPoshtaRegisterList: NovaPoshtaRegisterList
  NovaPoshtaRegisterListConnection: NovaPoshtaRegisterListConnection
  NovaPoshtaRegisterListEdge: NovaPoshtaRegisterListEdge
  NovaPoshtaSeatInput: NovaPoshtaSeatInput
  NovaPoshtaStreet: NovaPoshtaStreet
  NovaPoshtaUpdateDocumentInput: NovaPoshtaUpdateDocumentInput
  NovaPoshtaWarehouse: NovaPoshtaWarehouse
  NovaPoshtaWarehouseAddressGraphType: NovaPoshtaWarehouseAddressGraphType
  Objection: Objection
  OfferVacancyToApplyInput: OfferVacancyToApplyInput
  OfferVacancyToApplyOutput: OfferVacancyToApplyOutput
  OfferedVacancyCvDbEmployerEvent: Omit<OfferedVacancyCvDbEmployerEvent, 'performer'> & { performer: ResolversParentTypes['PerformerEmployerEventUnion'] }
  OpenedContactOrVacancyOfferedEmployerAchievement: OpenedContactOrVacancyOfferedEmployerAchievement
  OpenedContactsCvDbEmployerEvent: Omit<OpenedContactsCvDbEmployerEvent, 'performer'> & { performer: ResolversParentTypes['PerformerEmployerEventUnion'] }
  Order: Omit<Order, 'discounts' | 'paymentMethod'> & { discounts: ReadonlyArray<ResolversParentTypes['Discount']>; paymentMethod: ResolversParentTypes['PaymentMethods'] }
  OrderActivatedByTurbotaDetails: OrderActivatedByTurbotaDetails
  OrderAlreadyActivatedError: OrderAlreadyActivatedError
  OrderCanNotBeActivatedByTurbotaError: OrderCanNotBeActivatedByTurbotaError
  OrderCartMutation: OrderCartMutation
  OrderConnection: OrderConnection
  OrderEdge: OrderEdge
  OrderFreemiumServicesAccruedServiceEmployerEvent: OrderFreemiumServicesAccruedServiceEmployerEvent
  OrderPosition: Omit<OrderPosition, 'details'> & { details: ResolversParentTypes['OrderPositionServiceDetailsUnion'] }
  OrderPositionAdditionalServiceDetails: OrderPositionAdditionalServiceDetails
  OrderPositionCvDbServiceDetails: OrderPositionCvDbServiceDetails
  OrderPositionHotServiceDetails: OrderPositionHotServiceDetails
  OrderPositionLogoInPortalServiceDetails: OrderPositionLogoInPortalServiceDetails
  OrderPositionLogoInRubricServiceDetails: OrderPositionLogoInRubricServiceDetails
  OrderPositionLogoOnMainServiceDetails: OrderPositionLogoOnMainServiceDetails
  OrderPositionMarketingServiceDetails: OrderPositionMarketingServiceDetails
  OrderPositionRisingUpServiceDetails: OrderPositionRisingUpServiceDetails
  OrderPositionServiceDetailsUnion:
    | ResolversParentTypes['OrderPositionAdditionalServiceDetails']
    | ResolversParentTypes['OrderPositionCvDbServiceDetails']
    | ResolversParentTypes['OrderPositionHotServiceDetails']
    | ResolversParentTypes['OrderPositionLogoInPortalServiceDetails']
    | ResolversParentTypes['OrderPositionLogoInRubricServiceDetails']
    | ResolversParentTypes['OrderPositionLogoOnMainServiceDetails']
    | ResolversParentTypes['OrderPositionMarketingServiceDetails']
    | ResolversParentTypes['OrderPositionRisingUpServiceDetails']
    | ResolversParentTypes['OrderPositionVacancyPackageServiceDetails']
    | ResolversParentTypes['OrderPositionVacancyPublicationServiceDetails']
  OrderPositionVacancyPackageServiceDetails: OrderPositionVacancyPackageServiceDetails
  OrderPositionVacancyPublicationServiceDetails: OrderPositionVacancyPublicationServiceDetails
  OrderServicesAccruedServiceEmployerEvent: OrderServicesAccruedServiceEmployerEvent
  OrderTurbotaActivationError:
    | ResolversParentTypes['OrderAlreadyActivatedError']
    | ResolversParentTypes['OrderCanNotBeActivatedByTurbotaError']
    | ResolversParentTypes['OrderWasActivatedByTurbotaError']
  OrderTurbotaActivationInput: OrderTurbotaActivationInput
  OrderTurbotaActivationOutput: Omit<OrderTurbotaActivationOutput, 'error'> & { error: Maybe<ResolversParentTypes['OrderTurbotaActivationError']> }
  OrderWasActivatedByTurbotaError: OrderWasActivatedByTurbotaError
  OrderedAdditionalService: OrderedAdditionalService
  OrderedCvDbService: OrderedCvDbService
  OrderedHotService: OrderedHotService
  OrderedLogoInPortalService: OrderedLogoInPortalService
  OrderedLogoInRubricService: OrderedLogoInRubricService
  OrderedMarketingService: OrderedMarketingService
  OrderedRisingUpService: OrderedRisingUpService
  OrderedVacancyPackageService: Omit<OrderedVacancyPackageService, 'additionalServices'> & { additionalServices: ReadonlyArray<Maybe<ResolversParentTypes['ServiceUnited']>> }
  OrderedVacancyPublicationService: OrderedVacancyPublicationService
  OtherSiteLink: OtherSiteLink
  OwnerChangedVacancyEmployerEvent: Omit<OwnerChangedVacancyEmployerEvent, 'performer'> & { performer: ResolversParentTypes['PerformerEmployerEventUnion'] }
  PackageServiceEmployerEventSnapshot: PackageServiceEmployerEventSnapshot
  PageInfo: PageInfo
  PaidOrderService: PaidOrderService
  ParsedCv: ParsedCv
  ParsedCvComment: ParsedCvComment
  ParsedCvConnection: ParsedCvConnection
  ParsedCvLabel: ParsedCvLabel
  ParsedCvLabelInput: ParsedCvLabelInput
  ParsedCvRubric: ParsedCvRubric
  PasswordConfirmationFailedError: PasswordConfirmationFailedError
  PayWithCardErrorUnion: ResolversParentTypes['InvalidError'] | ResolversParentTypes['ServerError'] | ResolversParentTypes['UserIsNotAuthenticatedError']
  PayWithCardResponse: Omit<PayWithCardResponse, 'errors'> & { errors: ReadonlyArray<ResolversParentTypes['PayWithCardErrorUnion']> }
  PayWithExternalWalletErrorUnion: ResolversParentTypes['InvalidError'] | ResolversParentTypes['ServerError'] | ResolversParentTypes['UserIsNotAuthenticatedError']
  PayWithExternalWalletResponse: Omit<PayWithExternalWalletResponse, 'errors'> & { errors: ReadonlyArray<ResolversParentTypes['PayWithExternalWalletErrorUnion']> }
  Payer: Payer
  PayerEditInput: PayerEditInput
  PayerPassport: PayerPassport
  PayerPassportInput: PayerPassportInput
  PaymentMethods: ResolversParentTypes['ExternalUrlPaymentMethod'] | ResolversParentTypes['WidgetPaymentMethod']
  PaymentReceivedServiceEmployerEvent: Omit<PaymentReceivedServiceEmployerEvent, 'performer'> & { performer: ResolversParentTypes['PerformerEmployerEventUnion'] }
  PerformerEmployerEventUnion: ResolversParentTypes['EmployerEventPerformer'] | ResolversParentTypes['ManagerEventPerformer'] | ResolversParentTypes['SystemEventPerformer']
  Period: Period
  PeriodAmount: PeriodAmount
  PersonalDiscountEvent: PersonalDiscountEvent
  PhoneConfirmationOutput: PhoneConfirmationOutput
  PhoneNumberConfirmedEmployerAchievement: PhoneNumberConfirmedEmployerAchievement
  PriceQueryOutput: PriceQueryOutput
  ProfResume: ProfResume
  ProfResumeAddAdditionalEducationsInput: ProfResumeAddAdditionalEducationsInput
  ProfResumeAddAdditionalInfoInput: ProfResumeAddAdditionalInfoInput
  ProfResumeAddEducationsInput: ProfResumeAddEducationsInput
  ProfResumeAddExperiencesInput: ProfResumeAddExperiencesInput
  ProfResumeAdditionalEducationInput: ProfResumeAdditionalEducationInput
  ProfResumeAdditionalInfoInput: ProfResumeAdditionalInfoInput
  ProfResumeAdditionalInfosInput: ProfResumeAdditionalInfosInput
  ProfResumeBirthDateInput: ProfResumeBirthDateInput
  ProfResumeCannotBePublished: ProfResumeCannotBePublished
  ProfResumeCityInput: ProfResumeCityInput
  ProfResumeContactInfoInput: ProfResumeContactInfoInput
  ProfResumeDeleteAdditionalEducationsInput: ProfResumeDeleteAdditionalEducationsInput
  ProfResumeDeleteAdditionalInfoInput: ProfResumeDeleteAdditionalInfoInput
  ProfResumeDeleteAdditionalPhonesInput: ProfResumeDeleteAdditionalPhonesInput
  ProfResumeDeleteDiiaCertificateInput: ProfResumeDeleteDiiaCertificateInput
  ProfResumeDeleteDiiaCertificateOutput: Omit<ProfResumeDeleteDiiaCertificateOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['DeleteSeekerProfResumeDiiaCertificateError']>> }
  ProfResumeDeleteEducationsInput: ProfResumeDeleteEducationsInput
  ProfResumeDeleteLanguageSkillsInput: ProfResumeDeleteLanguageSkillsInput
  ProfResumeDeletePortfolioInput: ProfResumeDeletePortfolioInput
  ProfResumeDeleteSocialNetworksInput: ProfResumeDeleteSocialNetworksInput
  ProfResumeDiiaCertificateInput: ProfResumeDiiaCertificateInput
  ProfResumeDisabilityInput: ProfResumeDisabilityInput
  ProfResumeDoesNotBelongToSeeker: ProfResumeDoesNotBelongToSeeker
  ProfResumeDoesNotExist: ProfResumeDoesNotExist
  ProfResumeDuplicated: ProfResumeDuplicated
  ProfResumeEducationInput: ProfResumeEducationInput
  ProfResumeExperienceInput: ProfResumeExperienceInput
  ProfResumeExperiencesDeleteInput: ProfResumeExperiencesDeleteInput
  ProfResumeFullNameInput: ProfResumeFullNameInput
  ProfResumeGenderInput: ProfResumeGenderInput
  ProfResumeInput: ProfResumeInput
  ProfResumeLanguageSkillInput: ProfResumeLanguageSkillInput
  ProfResumeLimit: ProfResumeLimit
  ProfResumeMilitaryStatusInput: ProfResumeMilitaryStatusInput
  ProfResumePersonalInfoInput: ProfResumePersonalInfoInput
  ProfResumePhoneConfirmationFailed: ProfResumePhoneConfirmationFailed
  ProfResumePhotoInput: ProfResumePhotoInput
  ProfResumePositionInput: ProfResumePositionInput
  ProfResumeResponseUnion: ResolversParentTypes['NotFoundProfResume'] | ResolversParentTypes['ProfResume']
  ProfResumeSalaryInput: ProfResumeSalaryInput
  ProfResumeScheduleInput: ProfResumeScheduleInput
  ProfResumeSkillsInput: ProfResumeSkillsInput
  ProfResumeSocialNetworkInput: ProfResumeSocialNetworkInput
  ProfResumeUpdateAdditionalEducationInput: ProfResumeUpdateAdditionalEducationInput
  ProfResumeUpdateAdditionalInfoInput: ProfResumeUpdateAdditionalInfoInput
  ProfResumeUpdateAdditionalPhonesInput: ProfResumeUpdateAdditionalPhonesInput
  ProfResumeUpdateAnonymityInput: ProfResumeUpdateAnonymityInput
  ProfResumeUpdateAvailabilityStateInput: ProfResumeUpdateAvailabilityStateInput
  ProfResumeUpdateEducationInput: ProfResumeUpdateEducationInput
  ProfResumeUpdateEmailInput: ProfResumeUpdateEmailInput
  ProfResumeUpdateExperiencesInput: ProfResumeUpdateExperiencesInput
  ProfResumeUpdateHiddenCompaniesInput: ProfResumeUpdateHiddenCompaniesInput
  ProfResumeUpdateLanguageSkillsInput: ProfResumeUpdateLanguageSkillsInput
  ProfResumeUpdatePhoneInput: ProfResumeUpdatePhoneInput
  ProfResumeUpdatePortfoliosInput: ProfResumeUpdatePortfoliosInput
  ProfResumeUpdateSalaryInput: ProfResumeUpdateSalaryInput
  ProfResumeUpdateSearchStateInput: ProfResumeUpdateSearchStateInput
  ProfResumeUpdateSocialNetworksInput: ProfResumeUpdateSocialNetworksInput
  ProfResumeUpdateStateInput: ProfResumeUpdateStateInput
  ProfResumeUpdateStateOutput: Omit<ProfResumeUpdateStateOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['UpdateSeekerProfResumeStateError']>> }
  ProfileRegisteredEvent: ProfileRegisteredEvent
  ProlongationRequestVacancyEmployerEvent: Omit<ProlongationRequestVacancyEmployerEvent, 'performer'> & { performer: ResolversParentTypes['PerformerEmployerEventUnion'] }
  PrometheusExternalCourse: PrometheusExternalCourse
  PromoCode: Omit<PromoCode, 'target'> & { target: ResolversParentTypes['PromoCodeTragetUnion'] }
  PromoCodeActivator: PromoCodeActivator
  PromoCodeCartMutation: PromoCodeCartMutation
  PromoCodeConnection: PromoCodeConnection
  PromoCodeDateRangeInput: PromoCodeDateRangeInput
  PromoCodeDiscount: PromoCodeDiscount
  PromoCodeEdge: PromoCodeEdge
  PromoCodeFilterInput: PromoCodeFilterInput
  PromoCodeMutation: PromoCodeMutation
  PromoCodeTargetBonusSpecialServices: PromoCodeTargetBonusSpecialServices
  PromoCodeTargetSpecialService: PromoCodeTargetSpecialService
  PromoCodeTargetSpecialServiceType: PromoCodeTargetSpecialServiceType
  PromoCodeTargetWholeOrder: PromoCodeTargetWholeOrder
  PromoCodeTragetUnion:
    | ResolversParentTypes['PromoCodeTargetBonusSpecialServices']
    | ResolversParentTypes['PromoCodeTargetSpecialService']
    | ResolversParentTypes['PromoCodeTargetSpecialServiceType']
    | ResolversParentTypes['PromoCodeTargetWholeOrder']
  PromoCodeTypeWrapper: PromoCodeTypeWrapper
  ProzoraClientDateTimeInterval: ProzoraClientDateTimeInterval
  ProzoraClientFilters: ProzoraClientFilters
  ProzoraClientFiltersInput: ProzoraClientFiltersInput
  PublicClubPostsCursorPaginationInput: PublicClubPostsCursorPaginationInput
  PublicClubPostsFilterInput: PublicClubPostsFilterInput
  PublicClubPostsOutput: PublicClubPostsOutput
  PublicationServiceEmployerEventSnapshot: PublicationServiceEmployerEventSnapshot
  PublishProfResumeInput: PublishProfResumeInput
  PublishSeekerProfResumeError: ResolversParentTypes['ProfResumeCannotBePublished'] | ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  PublishedProfResumeOutput: Omit<PublishedProfResumeOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['PublishSeekerProfResumeError']>> }
  PublishedResumesCounter: PublishedResumesCounter
  PublishedVacancies: PublishedVacancies
  PublishedVacanciesClientCluster: PublishedVacanciesClientCluster
  PublishedVacanciesClientClusterInput: PublishedVacanciesClientClusterInput
  PublishedVacanciesClientFilter: PublishedVacanciesClientFilter
  PublishedVacanciesClientFilterInput: PublishedVacanciesClientFilterInput
  PublishedVacanciesClientMetroBranch: PublishedVacanciesClientMetroBranch
  PublishedVacanciesClientRubric: PublishedVacanciesClientRubric
  PublishedVacanciesFacets: PublishedVacanciesFacets
  PublishedVacanciesFilterInput: PublishedVacanciesFilterInput
  PublishedVacanciesLocation: PublishedVacanciesLocation
  PublishedVacanciesLocationInput: PublishedVacanciesLocationInput
  PublishedVacanciesMetroBranchInput: PublishedVacanciesMetroBranchInput
  PublishedVacanciesPaginationInput: PublishedVacanciesPaginationInput
  PublishedVacanciesQueryFilter: PublishedVacanciesQueryFilter
  PublishedVacanciesRubricInput: PublishedVacanciesRubricInput
  PublishedVacancyBadge: PublishedVacancyBadge
  PublishedVacancyEmployerEvent: Omit<PublishedVacancyEmployerEvent, 'performer'> & { performer: ResolversParentTypes['PerformerEmployerEventUnion'] }
  Quarter: Quarter
  Query: {}
  QuestionTemplatesFilterInput: QuestionTemplatesFilterInput
  Questionnaire: Omit<Questionnaire, 'questions'> & { questions: ReadonlyArray<ResolversParentTypes['QuestionnaireItem']> }
  QuestionnaireAnswer: Omit<QuestionnaireAnswer, 'questionSnapshot'> & { questionSnapshot: ResolversParentTypes['QuestionnaireItem'] }
  QuestionnaireAnswersSummary: QuestionnaireAnswersSummary
  QuestionnaireConnection: QuestionnaireConnection
  QuestionnaireCopyInput: QuestionnaireCopyInput
  QuestionnaireCopyOutput: QuestionnaireCopyOutput
  QuestionnaireCreateInput: QuestionnaireCreateInput
  QuestionnaireCreateOutput: QuestionnaireCreateOutput
  QuestionnaireDeleteOutput: QuestionnaireDeleteOutput
  QuestionnaireEdge: QuestionnaireEdge
  QuestionnaireEssayItem: QuestionnaireEssayItem
  QuestionnaireExperienceItem: QuestionnaireExperienceItem
  QuestionnaireExperienceItemSuggest: QuestionnaireExperienceItemSuggest
  QuestionnaireGiveAnswersInput: QuestionnaireGiveAnswersInput
  QuestionnaireGiveAnswersOutput: QuestionnaireGiveAnswersOutput
  QuestionnaireItem:
    | ResolversParentTypes['QuestionnaireEssayItem']
    | ResolversParentTypes['QuestionnaireExperienceItem']
    | ResolversParentTypes['QuestionnaireLanguageItem']
    | ResolversParentTypes['QuestionnaireSelectItem']
  QuestionnaireItemInput: QuestionnaireItemInput
  QuestionnaireItemSuggest:
    | ResolversParentTypes['QuestionnaireExperienceItemSuggest']
    | ResolversParentTypes['QuestionnaireLanguageItemSuggest']
    | ResolversParentTypes['QuestionnaireSelectItemSuggest']
  QuestionnaireLanguageItem: QuestionnaireLanguageItem
  QuestionnaireLanguageItemSuggest: QuestionnaireLanguageItemSuggest
  QuestionnaireSelectItem: QuestionnaireSelectItem
  QuestionnaireSelectItemSuggest: QuestionnaireSelectItemSuggest
  QuestionnaireSuggest: Omit<QuestionnaireSuggest, 'questions'> & { questions: ReadonlyArray<ResolversParentTypes['QuestionnaireItemSuggest']> }
  QuestionnaireUpdateInput: QuestionnaireUpdateInput
  QuestionnaireUpdateOutput: QuestionnaireUpdateOutput
  QuestionnairesFilterInput: QuestionnairesFilterInput
  RePublishedVacancyEmployerEvent: Omit<RePublishedVacancyEmployerEvent, 'performer'> & { performer: ResolversParentTypes['PerformerEmployerEventUnion'] }
  RecommendedCandidate: Omit<RecommendedCandidate, 'resume'> & { resume: ResolversParentTypes['ProfResumeResponseUnion'] }
  RecommendedCandidatesConnection: RecommendedCandidatesConnection
  RecommendedCandidatesEdge: RecommendedCandidatesEdge
  RecommendedCandidatesFilter: RecommendedCandidatesFilter
  RecommendedProfResume: RecommendedProfResume
  RecommendedProfResumeExperience: RecommendedProfResumeExperience
  RecommendedProfResumeSalary: RecommendedProfResumeSalary
  RecommendedProfResumes: RecommendedProfResumes
  RecommendedProfResumesInput: RecommendedProfResumesInput
  RecommendedVacanciesConnection: RecommendedVacanciesConnection
  RecommendedVacanciesEdge: RecommendedVacanciesEdge
  RecruitmentOrderOutput: RecruitmentOrderOutput
  RegularGift: RegularGift
  RejectPaymentInput: RejectPaymentInput
  RejectedByModeratorVacancyEmployerEvent: RejectedByModeratorVacancyEmployerEvent
  RejectedProlongationRequestVacancyEmployerEvent: Omit<RejectedProlongationRequestVacancyEmployerEvent, 'performer'> & { performer: ResolversParentTypes['PerformerEmployerEventUnion'] }
  RejectedReviewVacancyEmployerEvent: Omit<RejectedReviewVacancyEmployerEvent, 'performer'> & { performer: ResolversParentTypes['PerformerEmployerEventUnion'] }
  RelevanceScore: RelevanceScore
  RemoveSeekerFavoriteVacanciesInput: RemoveSeekerFavoriteVacanciesInput
  RemoveSeekerFavoriteVacanciesOutput: RemoveSeekerFavoriteVacanciesOutput
  ResumeAdditionalEducation: ResumeAdditionalEducation
  ResumeAdditionalInfo: ResumeAdditionalInfo
  ResumeContactInfo: ResumeContactInfo
  ResumeDraftCreatedNotificationDetails: ResumeDraftCreatedNotificationDetails
  ResumeEducation: ResumeEducation
  ResumeError:
    | ResolversParentTypes['AttachNotFound']
    | ResolversParentTypes['AttachParsingError']
    | ResolversParentTypes['ProfResumeCannotBePublished']
    | ResolversParentTypes['ProfResumeDoesNotBelongToSeeker']
    | ResolversParentTypes['ProfResumeDoesNotExist']
    | ResolversParentTypes['ProfResumeDuplicated']
    | ResolversParentTypes['ProfResumeLimit']
    | ResolversParentTypes['ProfResumePhoneConfirmationFailed']
  ResumeExperience: ResumeExperience
  ResumeFilling: ResumeFilling
  ResumeFillingType: ResumeFillingType
  ResumeLanguageSkill: ResumeLanguageSkill
  ResumePersonalInfo: ResumePersonalInfo
  ResumePhone: ResumePhone
  ResumePublishedNotificationDetails: ResumePublishedNotificationDetails
  ResumeReportInput: ResumeReportInput
  ResumeReportOutput: ResumeReportOutput
  ResumeSalary: ResumeSalary
  ResumeSearchSuggest: ResumeSearchSuggest
  ResumeSocialContactLink: ResumeSocialContactLink
  ResumeState: ResumeState
  ResumeViewInfo: ResumeViewInfo
  ResumeViewsConnection: ResumeViewsConnection
  ResumeViewsEdge: ResumeViewsEdge
  RisingUpCatalogService: RisingUpCatalogService
  Rubric: Rubric
  RubricFacet: RubricFacet
  SalaryStatistics: SalaryStatistics
  SaleManagerMutations: SaleManagerMutations
  SaleManagerQueries: SaleManagerQueries
  SaleManagerResponseOutput: SaleManagerResponseOutput
  SaveVacancyInterviewQuestionsErrorUnion: ResolversParentTypes['InvalidError'] | ResolversParentTypes['NotFoundError']
  SaveVacancyInterviewQuestionsInput: SaveVacancyInterviewQuestionsInput
  SaveVacancyInterviewQuestionsOutput: Omit<SaveVacancyInterviewQuestionsOutput, 'errors'> & { errors: ReadonlyArray<ResolversParentTypes['SaveVacancyInterviewQuestionsErrorUnion']> }
  SavedCard: SavedCard
  SavedCardsMutations: SavedCardsMutations
  Schedule: Schedule
  ScheduleFacet: ScheduleFacet
  ScrollInfo: ScrollInfo
  SeekerApplication: SeekerApplication
  SeekerAppliesInput: SeekerAppliesInput
  SeekerAppliesPaginationInput: SeekerAppliesPaginationInput
  SeekerApplyQueries: SeekerApplyQueries
  SeekerDislikedVacanciesPaginationInput: SeekerDislikedVacanciesPaginationInput
  SeekerDislikedVacancyDetails: SeekerDislikedVacancyDetails
  SeekerDislikedVacancyMutations: SeekerDislikedVacancyMutations
  SeekerDislikedVacancyQueries: SeekerDislikedVacancyQueries
  SeekerFavoriteVacanciesPaginationInput: SeekerFavoriteVacanciesPaginationInput
  SeekerFavoriteVacancyDetails: SeekerFavoriteVacancyDetails
  SeekerFavoriteVacancyMutations: SeekerFavoriteVacancyMutations
  SeekerFavoriteVacancyQueries: SeekerFavoriteVacancyQueries
  SeekerMutations: SeekerMutations
  SeekerRecommendationProfile: SeekerRecommendationProfile
  SeekerRegistration: SeekerRegistration
  SeekerSubscription: Omit<SeekerSubscription, 'profile'> & { profile: Maybe<ResolversParentTypes['SeekerSubscriptionProfile']> }
  SeekerSubscriptionActivation: SeekerSubscriptionActivation
  SeekerSubscriptionChannel: SeekerSubscriptionChannel
  SeekerSubscriptionManualProfile: SeekerSubscriptionManualProfile
  SeekerSubscriptionManualProfileInput: SeekerSubscriptionManualProfileInput
  SeekerSubscriptionMutations: SeekerSubscriptionMutations
  SeekerSubscriptionProfile: ResolversParentTypes['SeekerRecommendationProfile'] | ResolversParentTypes['SeekerSubscriptionManualProfile']
  SeekerSubscriptionSummary: SeekerSubscriptionSummary
  SeekerSubscriptionsFilterInput: SeekerSubscriptionsFilterInput
  SeekerWalletUser: SeekerWalletUser
  SelectPromoCodeResponse: SelectPromoCodeResponse
  SendChangeSeekerEmailOutput: SendChangeSeekerEmailOutput
  SendConfirmCodeEmailInput: SendConfirmCodeEmailInput
  SendEmailOutput: SendEmailOutput
  SendLoginEmailInput: SendLoginEmailInput
  SendNotificationSetEmailDetails: SendNotificationSetEmailDetails
  SendOrderLetterInput: SendOrderLetterInput
  SendRegistrationConfirmEmailInput: SendRegistrationConfirmEmailInput
  SendSmsOutput: SendSmsOutput
  SentToReviewVacancyEmployerEvent: Omit<SentToReviewVacancyEmployerEvent, 'performer'> & { performer: ResolversParentTypes['PerformerEmployerEventUnion'] }
  ServerError: ServerError
  ServiceActivationInput: ServiceActivationInput
  ServiceActivationOutput: ServiceActivationOutput
  ServiceActivator: ServiceActivator
  ServiceCompany: ServiceCompany
  ServiceCompanyTurbotaStatus: ServiceCompanyTurbotaStatus
  ServiceCount: ServiceCount
  ServiceCvdbRegionsOutput: ServiceCvdbRegionsOutput
  ServiceCvdbRubricsOutput: ServiceCvdbRubricsOutput
  ServiceDiscountTypeWrapper: ServiceDiscountTypeWrapper
  ServiceEmployee: ServiceEmployee
  ServiceEmployerEventSnapshotUnion:
    | ResolversParentTypes['AdditionalServiceEmployerEventSnapshot']
    | ResolversParentTypes['CvDbServiceEmployerEventSnapshot']
    | ResolversParentTypes['HotServiceEmployerEventSnapshot']
    | ResolversParentTypes['LogoInPortalServiceEmployerEventSnapshot']
    | ResolversParentTypes['LogoInRubricServiceEmployerEventSnapshot']
    | ResolversParentTypes['LogoOnMainPageServiceEmployerEventSnapshot']
    | ResolversParentTypes['MarketingServiceEmployerEventSnapshot']
    | ResolversParentTypes['PackageServiceEmployerEventSnapshot']
    | ResolversParentTypes['PublicationServiceEmployerEventSnapshot']
  ServiceGroupWrapper: ServiceGroupWrapper
  ServiceIsExpiringEvent: ServiceIsExpiringEvent
  ServiceLimit: ServiceLimit
  ServiceLimitInput: ServiceLimitInput
  ServiceLimitRequestInput: ServiceLimitRequestInput
  ServicePrice: ServicePrice
  ServicePurchaseHistoryOutput: ServicePurchaseHistoryOutput
  ServicePurchasedService: ServicePurchasedService
  ServiceTypeWrapper: ServiceTypeWrapper
  ServiceUnited:
    | ResolversParentTypes['ActivatedAdditionalService']
    | ResolversParentTypes['ActivatedCvDbService']
    | ResolversParentTypes['ActivatedHotService']
    | ResolversParentTypes['ActivatedLogoInPortalService']
    | ResolversParentTypes['ActivatedLogoInRubricService']
    | ResolversParentTypes['ActivatedRisingUpService']
    | ResolversParentTypes['ActivatedVacancyPackageService']
    | ResolversParentTypes['ActivatedVacancyPublicationService']
    | ResolversParentTypes['ClosedAdditionalService']
    | ResolversParentTypes['ClosedCvDbService']
    | ResolversParentTypes['ClosedHotService']
    | ResolversParentTypes['ClosedLogoInPortalService']
    | ResolversParentTypes['ClosedLogoInRubricService']
    | ResolversParentTypes['ClosedMarketingService']
    | ResolversParentTypes['ClosedRisingUpService']
    | ResolversParentTypes['ClosedVacancyPackageService']
    | ResolversParentTypes['ClosedVacancyPublicationService']
    | ResolversParentTypes['OrderedAdditionalService']
    | ResolversParentTypes['OrderedCvDbService']
    | ResolversParentTypes['OrderedHotService']
    | ResolversParentTypes['OrderedLogoInPortalService']
    | ResolversParentTypes['OrderedLogoInRubricService']
    | ResolversParentTypes['OrderedMarketingService']
    | ResolversParentTypes['OrderedRisingUpService']
    | ResolversParentTypes['OrderedVacancyPackageService']
    | ResolversParentTypes['OrderedVacancyPublicationService']
  ServiceUnitedEdge: Omit<ServiceUnitedEdge, 'node'> & { node: Maybe<ResolversParentTypes['ServiceUnited']> }
  ServicesFilters: ServicesFilters
  ServicesManagementClientFilters: ServicesManagementClientFilters
  ServicesManagementClientFiltersInput: ServicesManagementClientFiltersInput
  SetActionStateOutput: SetActionStateOutput
  SetCvRubricInput: SetCvRubricInput
  SetCvStateInput: SetCvStateInput
  SetGiftRangeInput: SetGiftRangeInput
  SetStateGiftRequestInput: SetStateGiftRequestInput
  SetVacancyCandidateKeywordsInput: SetVacancyCandidateKeywordsInput
  SimilarVacancies: SimilarVacancies
  SimilarVacanciesPaginationInput: SimilarVacanciesPaginationInput
  SimilarVacanciesQueries: SimilarVacanciesQueries
  SimilarVacancyKeywordsOutput: SimilarVacancyKeywordsOutput
  SpiderVacancy: SpiderVacancy
  StartCallInput: StartCallInput
  StartGamificationWaveInput: StartGamificationWaveInput
  StartGamificationWaveOutput: StartGamificationWaveOutput
  Statistic: Statistic
  StatisticBranch: StatisticBranch
  StatisticBranchTimeseries: StatisticBranchTimeseries
  StatisticCandidatesStructure: StatisticCandidatesStructure
  StatisticCityInput: StatisticCityInput
  StatisticData: StatisticData
  StatisticDataCityKeywordInput: StatisticDataCityKeywordInput
  StatisticDataCityRubricInput: StatisticDataCityRubricInput
  StatisticDataKeywordRubricInput: StatisticDataKeywordRubricInput
  StatisticInput: StatisticInput
  StatisticItem: StatisticItem
  StatisticProfession: StatisticProfession
  StatisticProfessionByCountTimeSeries: StatisticProfessionByCountTimeSeries
  StatisticProfessionItem: StatisticProfessionItem
  StatisticRubric: StatisticRubric
  StatisticRubricTimeseries: StatisticRubricTimeseries
  StatisticSalaryDistribution: StatisticSalaryDistribution
  StatisticStructure: StatisticStructure
  StatisticTimeseries: StatisticTimeseries
  StatisticTotal: StatisticTotal
  StatisticVacanciesStructure: StatisticVacanciesStructure
  StatisticVacancyBadge: StatisticVacancyBadge
  String: Scalars['String']
  Subrubric: Omit<Subrubric, 'externalCourses'> & { externalCourses: ReadonlyArray<ResolversParentTypes['ExternalCourse']> }
  SubrubricsFacet: SubrubricsFacet
  SubscriptionOutputType: SubscriptionOutputType
  SuggestedVacancySearchOutput: SuggestedVacancySearchOutput
  SummaryService: SummaryService
  Synonym: Synonym
  SynonymTag: SynonymTag
  SystemEventPerformer: SystemEventPerformer
  SystemWalletUser: SystemWalletUser
  TagGroup: TagGroup
  TargetMailingListFilter: TargetMailingListFilter
  TargetMailingListQueryFilter: TargetMailingListQueryFilter
  TargetedExternalMailingLink: TargetedExternalMailingLink
  TargetedMailing: Omit<TargetedMailing, 'content' | 'history'> & {
    content: Maybe<ReadonlyArray<ResolversParentTypes['TargetedMailingContentUnion']>>
    history: ReadonlyArray<ResolversParentTypes['TargetedMailingHistoryFactUnion']>
  }
  TargetedMailingCandidatesLocation: TargetedMailingCandidatesLocation
  TargetedMailingCandidatesLocationInput: TargetedMailingCandidatesLocationInput
  TargetedMailingCandidatesRubric: TargetedMailingCandidatesRubric
  TargetedMailingCandidatesRubricInput: TargetedMailingCandidatesRubricInput
  TargetedMailingCandidatesSampling: TargetedMailingCandidatesSampling
  TargetedMailingCandidatesSamplingByCodeInput: TargetedMailingCandidatesSamplingByCodeInput
  TargetedMailingCandidatesSamplingByCodeInputSnapshot: TargetedMailingCandidatesSamplingByCodeInputSnapshot
  TargetedMailingCandidatesSamplingByFilterInput: TargetedMailingCandidatesSamplingByFilterInput
  TargetedMailingCandidatesSamplingByFilterInputSnapshot: TargetedMailingCandidatesSamplingByFilterInputSnapshot
  TargetedMailingCandidatesSamplingByVacancyInput: TargetedMailingCandidatesSamplingByVacancyInput
  TargetedMailingCandidatesSamplingDetails: TargetedMailingCandidatesSamplingDetails
  TargetedMailingCandidatesSamplingDetailsByChannel: TargetedMailingCandidatesSamplingDetailsByChannel
  TargetedMailingCandidatesSamplingDetailsByVacancy: TargetedMailingCandidatesSamplingDetailsByVacancy
  TargetedMailingCandidatesSamplingErrorUnion: ResolversParentTypes['TargetedMailingInvalidVacanciesError']
  TargetedMailingCandidatesSamplingInput: TargetedMailingCandidatesSamplingInput
  TargetedMailingCandidatesSamplingInputSnapshot: TargetedMailingCandidatesSamplingInputSnapshot
  TargetedMailingCandidatesSamplingOutput: Omit<TargetedMailingCandidatesSamplingOutput, 'error'> & { error: Maybe<ResolversParentTypes['TargetedMailingCandidatesSamplingErrorUnion']> }
  TargetedMailingCompletedHistoryFact: TargetedMailingCompletedHistoryFact
  TargetedMailingConnection: TargetedMailingConnection
  TargetedMailingContentUnion: ResolversParentTypes['TargetedMailingMailContent'] | ResolversParentTypes['TargetedMailingMobilePushContent']
  TargetedMailingCreatedHistoryFact: TargetedMailingCreatedHistoryFact
  TargetedMailingEdge: TargetedMailingEdge
  TargetedMailingEditedHistoryFact: TargetedMailingEditedHistoryFact
  TargetedMailingFacetsOutput: TargetedMailingFacetsOutput
  TargetedMailingFailedHistoryFact: TargetedMailingFailedHistoryFact
  TargetedMailingFilterInput: TargetedMailingFilterInput
  TargetedMailingHistoryFactUnion:
    | ResolversParentTypes['TargetedMailingCompletedHistoryFact']
    | ResolversParentTypes['TargetedMailingCreatedHistoryFact']
    | ResolversParentTypes['TargetedMailingEditedHistoryFact']
    | ResolversParentTypes['TargetedMailingFailedHistoryFact']
    | ResolversParentTypes['TargetedMailingSendingHistoryFact']
  TargetedMailingInvalidVacanciesError: TargetedMailingInvalidVacanciesError
  TargetedMailingLanguageRequirement: TargetedMailingLanguageRequirement
  TargetedMailingLanguageRequirementInput: TargetedMailingLanguageRequirementInput
  TargetedMailingLinkUnion: ResolversParentTypes['TargetedExternalMailingLink'] | ResolversParentTypes['TargetedVacancyMailingLink']
  TargetedMailingMailContent: TargetedMailingMailContent
  TargetedMailingMailContentInput: TargetedMailingMailContentInput
  TargetedMailingMobilePushContent: TargetedMailingMobilePushContent
  TargetedMailingMobilePushContentInput: TargetedMailingMobilePushContentInput
  TargetedMailingMutations: TargetedMailingMutations
  TargetedMailingQueries: TargetedMailingQueries
  TargetedMailingRequiredFieldsMissedError: TargetedMailingRequiredFieldsMissedError
  TargetedMailingResponsible: TargetedMailingResponsible
  TargetedMailingSamplingRelatedVacancy: TargetedMailingSamplingRelatedVacancy
  TargetedMailingSaveErrorUnion: ResolversParentTypes['TargetedMailingInvalidVacanciesError'] | ResolversParentTypes['TargetedMailingRequiredFieldsMissedError']
  TargetedMailingSendingHistoryFact: TargetedMailingSendingHistoryFact
  TargetedMailingStatusFacet: TargetedMailingStatusFacet
  TargetedVacancyMailingLink: TargetedVacancyMailingLink
  TargetedVacancyMailingLinkVacancySnapshot: TargetedVacancyMailingLinkVacancySnapshot
  TeamActions: TeamActions
  TestTargetedMailingInput: TestTargetedMailingInput
  TestTargetedMailingOutput: TestTargetedMailingOutput
  TicketCardFilterInput: TicketCardFilterInput
  TokenAlreadyUsedError: TokenAlreadyUsedError
  Topic: Topic
  TopicTemplate: TopicTemplate
  TrackCompanyViewOutput: TrackCompanyViewOutput
  TransactionExistsError: TransactionExistsError
  TransactionsConnection: TransactionsConnection
  TransactionsEdge: TransactionsEdge
  TurbotaEvent: TurbotaEvent
  TurbotaWasExpiredError: TurbotaWasExpiredError
  UInt: Scalars['UInt']
  UncompletedPaymentEvent: UncompletedPaymentEvent
  UndislikeSeekerVacanciesInput: UndislikeSeekerVacanciesInput
  UndislikeSeekerVacanciesOutput: UndislikeSeekerVacanciesOutput
  UnionBannerDynamicElement: ResolversParentTypes['BannerClock'] | ResolversParentTypes['BannerProgressBar']
  University: University
  UnknownServerError: UnknownServerError
  UnlinkPayerOutput: UnlinkPayerOutput
  UnpaidOrderDetails: UnpaidOrderDetails
  UnpublishProfResumeInput: UnpublishProfResumeInput
  UnpublishSeekerProfResumeError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  UnpublishedProfResumeOutput: Omit<UnpublishedProfResumeOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['UnpublishSeekerProfResumeError']>> }
  UpdateGiftInput: UpdateGiftInput
  UpdateGiftRequestOutput: UpdateGiftRequestOutput
  UpdateProfResumeAdditionalPhonesOutput: Omit<UpdateProfResumeAdditionalPhonesOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['UpdateSeekerProfResumeAdditionalPhonesError']>> }
  UpdateProfResumeEmailOutput: Omit<UpdateProfResumeEmailOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['UpdateSeekerProfResumeEmailError']>> }
  UpdateProfResumePhoneOutput: Omit<UpdateProfResumePhoneOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['UpdateSeekerProfResumeMainPhoneError']>> }
  UpdateProfResumePortfoliosOutput: Omit<UpdateProfResumePortfoliosOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['UpdateSeekerProfResumePortfoliosError']>> }
  UpdateProfResumeSocialNetworksOutput: Omit<UpdateProfResumeSocialNetworksOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['UpdateSeekerProfResumeSocialNetworksError']>> }
  UpdateSeekerProfResumeAdditionalEducationError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeAdditionalInfoError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeAdditionalInfosError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeAdditionalPhonesError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeAnonymityError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeAvailabilityStateError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeBirthDateError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeCityError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeDiiaCertificateError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeDisabilityError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeEducationError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeEmailError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeExperienceError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeFullNameError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeGenderError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeHiddenCompaniesError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeLanguageSkillsError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeMainPhoneError:
    | ResolversParentTypes['ProfResumeDoesNotBelongToSeeker']
    | ResolversParentTypes['ProfResumeDoesNotExist']
    | ResolversParentTypes['ProfResumePhoneConfirmationFailed']
  UpdateSeekerProfResumeMilitaryStatusError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumePhotoError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumePortfoliosError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumePositionError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist'] | ResolversParentTypes['ProfResumeDuplicated']
  UpdateSeekerProfResumeSalaryError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeScheduleError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeSearchStateError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeSkillsError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeSocialNetworksError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeSortDateError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  UpdateSeekerProfResumeSortDateInput: UpdateSeekerProfResumeSortDateInput
  UpdateSeekerProfResumeSortDateOutput: Omit<UpdateSeekerProfResumeSortDateOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['UpdateSeekerProfResumeSortDateError']>> }
  UpdateSeekerProfResumeStateError: ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] | ResolversParentTypes['ProfResumeDoesNotExist']
  UpdateTargetedMailingInput: UpdateTargetedMailingInput
  UpdateTargetedMailingOutput: Omit<UpdateTargetedMailingOutput, 'error'> & { error: Maybe<ResolversParentTypes['TargetedMailingSaveErrorUnion']> }
  UpdatedProfResumeAdditionalEducationsOutput: Omit<UpdatedProfResumeAdditionalEducationsOutput, 'errors'> & {
    errors: Maybe<ReadonlyArray<ResolversParentTypes['UpdateSeekerProfResumeAdditionalEducationError']>>
  }
  UpdatedProfResumeAdditionalInfoOutput: Omit<UpdatedProfResumeAdditionalInfoOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['UpdateSeekerProfResumeAdditionalInfoError']>> }
  UpdatedProfResumeAdditionalInfosOutput: Omit<UpdatedProfResumeAdditionalInfosOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['UpdateSeekerProfResumeAdditionalInfosError']>> }
  UpdatedProfResumeAnonymityOutput: Omit<UpdatedProfResumeAnonymityOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['UpdateSeekerProfResumeAnonymityError']>> }
  UpdatedProfResumeAvailabilityStateOutput: Omit<UpdatedProfResumeAvailabilityStateOutput, 'errors'> & {
    errors: Maybe<ReadonlyArray<ResolversParentTypes['UpdateSeekerProfResumeAvailabilityStateError']>>
  }
  UpdatedProfResumeBirthDateOutput: Omit<UpdatedProfResumeBirthDateOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['UpdateSeekerProfResumeBirthDateError']>> }
  UpdatedProfResumeCityOutput: Omit<UpdatedProfResumeCityOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['UpdateSeekerProfResumeCityError']>> }
  UpdatedProfResumeDiiaCertificateOutput: Omit<UpdatedProfResumeDiiaCertificateOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['UpdateSeekerProfResumeDiiaCertificateError']>> }
  UpdatedProfResumeDisabilityOutput: Omit<UpdatedProfResumeDisabilityOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['UpdateSeekerProfResumeDisabilityError']>> }
  UpdatedProfResumeEducationsOutput: Omit<UpdatedProfResumeEducationsOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['UpdateSeekerProfResumeEducationError']>> }
  UpdatedProfResumeExperiencesOutput: Omit<UpdatedProfResumeExperiencesOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['UpdateSeekerProfResumeExperienceError']>> }
  UpdatedProfResumeFullNameOutput: Omit<UpdatedProfResumeFullNameOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['UpdateSeekerProfResumeFullNameError']>> }
  UpdatedProfResumeGenderOutput: Omit<UpdatedProfResumeGenderOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['UpdateSeekerProfResumeGenderError']>> }
  UpdatedProfResumeHiddenCompaniesOutput: Omit<UpdatedProfResumeHiddenCompaniesOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['UpdateSeekerProfResumeHiddenCompaniesError']>> }
  UpdatedProfResumeLanguageSkillsOutput: Omit<UpdatedProfResumeLanguageSkillsOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['UpdateSeekerProfResumeLanguageSkillsError']>> }
  UpdatedProfResumeMilitaryStatusOutput: Omit<UpdatedProfResumeMilitaryStatusOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['UpdateSeekerProfResumeMilitaryStatusError']>> }
  UpdatedProfResumePhotoOutput: Omit<UpdatedProfResumePhotoOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['UpdateSeekerProfResumePhotoError']>> }
  UpdatedProfResumePositionOutput: Omit<UpdatedProfResumePositionOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['UpdateSeekerProfResumePositionError']>> }
  UpdatedProfResumeSalaryOutput: Omit<UpdatedProfResumeSalaryOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['UpdateSeekerProfResumeSalaryError']>> }
  UpdatedProfResumeScheduleOutput: Omit<UpdatedProfResumeScheduleOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['UpdateSeekerProfResumeScheduleError']>> }
  UpdatedProfResumeSearchStateOutput: Omit<UpdatedProfResumeSearchStateOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['UpdateSeekerProfResumeSearchStateError']>> }
  UpdatedProfResumeSkillsOutput: Omit<UpdatedProfResumeSkillsOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['UpdateSeekerProfResumeSkillsError']>> }
  Upload: Scalars['Upload']
  Uri: Scalars['Uri']
  UsagePeriodTypeWrapper: UsagePeriodTypeWrapper
  UsePromoCodeResponse: UsePromoCodeResponse
  UsePromocodesInput: UsePromocodesInput
  UsedHotServiceEmployerEvent: Omit<UsedHotServiceEmployerEvent, 'performer' | 'usedService'> & {
    performer: ResolversParentTypes['PerformerEmployerEventUnion']
    usedService: ResolversParentTypes['ServiceEmployerEventSnapshotUnion']
  }
  UsedVacancyServiceEmployerEvent: Omit<UsedVacancyServiceEmployerEvent, 'performer' | 'usedService'> & {
    performer: ResolversParentTypes['PerformerEmployerEventUnion']
    usedService: ResolversParentTypes['ServiceEmployerEventSnapshotUnion']
  }
  User: User
  UserAccountLogin: UserAccountLogin
  UserCardDeleteOutput: UserCardDeleteOutput
  UserEmailHasBeenSetNotificationDetails: UserEmailHasBeenSetNotificationDetails
  UserInput: UserInput
  UserIsNotAuthenticatedError: UserIsNotAuthenticatedError
  UserIsNotEmployerError: UserIsNotEmployerError
  UserLeftBasketEvent: UserLeftBasketEvent
  UserLocationRadiusFilterInput: UserLocationRadiusFilterInput
  UserRegistrationError: UserRegistrationError
  UserRegistrationMutations: UserRegistrationMutations
  UserRegistrationValidationResponse: UserRegistrationValidationResponse
  UserRoleWrapper: UserRoleWrapper
  UserState: UserState
  Users: Users
  UsersManagementFilters: UsersManagementFilters
  UsersManagementFiltersInput: UsersManagementFiltersInput
  UsersManagementPageInfo: UsersManagementPageInfo
  UsersManagementPageInfoInput: UsersManagementPageInfoInput
  UsersManagementSubFilter: UsersManagementSubFilter
  VacanciesPageClientFilters: VacanciesPageClientFilters
  VacanciesPageClientFiltersInput: VacanciesPageClientFiltersInput
  Vacancy: Vacancy
  VacancyAdditionalCandidatePermissions: VacancyAdditionalCandidatePermissions
  VacancyAdditionalCandidatePermissionsInput: VacancyAdditionalCandidatePermissionsInput
  VacancyAddress: VacancyAddress
  VacancyAddressInput: VacancyAddressInput
  VacancyAddressesSuggestInput: VacancyAddressesSuggestInput
  VacancyAddressesSuggestOutput: VacancyAddressesSuggestOutput
  VacancyAdminRejectDetails: VacancyAdminRejectDetails
  VacancyAppearedInViewPortInput: VacancyAppearedInViewPortInput
  VacancyAppearedInViewPortOutput: VacancyAppearedInViewPortOutput
  VacancyApplicationsDestination: VacancyApplicationsDestination
  VacancyApplicationsDestinationInput: VacancyApplicationsDestinationInput
  VacancyAwaitingApprovalDetails: VacancyAwaitingApprovalDetails
  VacancyBadge: VacancyBadge
  VacancyBannedByModeratorDetails: VacancyBannedByModeratorDetails
  VacancyBannedEvent: VacancyBannedEvent
  VacancyBenefit: VacancyBenefit
  VacancyCandidatesScreeningOptions: VacancyCandidatesScreeningOptions
  VacancyCandidatesScreeningOptionsInput: VacancyCandidatesScreeningOptionsInput
  VacancyChangeClosingBehaviorInput: VacancyChangeClosingBehaviorInput
  VacancyChangeClosingBehaviorOutput: VacancyChangeClosingBehaviorOutput
  VacancyChangeHistory: VacancyChangeHistory
  VacancyChangeOwnerInput: VacancyChangeOwnerInput
  VacancyChangeOwnerOutput: VacancyChangeOwnerOutput
  VacancyCityCount: VacancyCityCount
  VacancyCloseInput: VacancyCloseInput
  VacancyCloseOutput: VacancyCloseOutput
  VacancyCompany: VacancyCompany
  VacancyCompetitors: VacancyCompetitors
  VacancyCompetitorsStatistics: VacancyCompetitorsStatistics
  VacancyContacts: VacancyContacts
  VacancyContactsInput: VacancyContactsInput
  VacancyCounterGraphType: VacancyCounterGraphType
  VacancyCreateError:
    | ResolversParentTypes['CompanyWasMergedError']
    | ResolversParentTypes['InvalidError']
    | ResolversParentTypes['NoAvailableLimitsError']
    | ResolversParentTypes['TurbotaWasExpiredError']
    | ResolversParentTypes['UserIsNotAuthenticatedError']
    | ResolversParentTypes['UserIsNotEmployerError']
  VacancyCreateInput: VacancyCreateInput
  VacancyCreateOutput: Omit<VacancyCreateOutput, 'error'> & { error: Maybe<ResolversParentTypes['VacancyCreateError']> }
  VacancyCreateSuggest: VacancyCreateSuggest
  VacancyCreateSuggestOutput: VacancyCreateSuggestOutput
  VacancyDesign: VacancyDesign
  VacancyDesignFooter: VacancyDesignFooter
  VacancyDesignHeader: VacancyDesignHeader
  VacancyDesignHeaderItem: VacancyDesignHeaderItem
  VacancyDictionaryBenefit: VacancyDictionaryBenefit
  VacancyDraftInput: VacancyDraftInput
  VacancyDraftOutput: VacancyDraftOutput
  VacancyEdge: VacancyEdge
  VacancyEmployer: VacancyEmployer
  VacancyEmployerCount: VacancyEmployerCount
  VacancyEndingCountDetails: VacancyEndingCountDetails
  VacancyEndingLeftDaysDetails: VacancyEndingLeftDaysDetails
  VacancyEventStatisticItem: VacancyEventStatisticItem
  VacancyExperienceQuestion: VacancyExperienceQuestion
  VacancyExperienceQuestionInput: VacancyExperienceQuestionInput
  VacancyFromAnotherSourceGraphType: VacancyFromAnotherSourceGraphType
  VacancyHotPeriod: VacancyHotPeriod
  VacancyInterviewQuestionsMutations: VacancyInterviewQuestionsMutations
  VacancyLanguageQuestion: VacancyLanguageQuestion
  VacancyLanguageQuestionInput: VacancyLanguageQuestionInput
  VacancyMakeHotInput: VacancyMakeHotInput
  VacancyMakeHotOutput: VacancyMakeHotOutput
  VacancyMedia: VacancyMedia
  VacancyMediaInput: VacancyMediaInput
  VacancyModeratorRejectDetails: VacancyModeratorRejectDetails
  VacancyOnWorkWillExpireInFiveDaysEvent: VacancyOnWorkWillExpireInFiveDaysEvent
  VacancyOwnerChangedDetails: Omit<VacancyOwnerChangedDetails, 'initiator'> & { initiator: ResolversParentTypes['NotificationInitiator'] }
  VacancyPackageCatalogService: VacancyPackageCatalogService
  VacancyPosition: VacancyPosition
  VacancyPositionRisingInSearchList: VacancyPositionRisingInSearchList
  VacancyProlongationRequest: VacancyProlongationRequest
  VacancyProlongationRequestsOutput: VacancyProlongationRequestsOutput
  VacancyProlongationReviewError: ResolversParentTypes['AlreadyReviewedVacancyProlongationError']
  VacancyProlongationReviewInput: VacancyProlongationReviewInput
  VacancyProlongationReviewOutput: Omit<VacancyProlongationReviewOutput, 'error'> & { error: Maybe<ResolversParentTypes['VacancyProlongationReviewError']> }
  VacancyPublicationApprovedDetails: VacancyPublicationApprovedDetails
  VacancyPublicationCatalogService: VacancyPublicationCatalogService
  VacancyPublicationEndedDetails: VacancyPublicationEndedDetails
  VacancyPublicationRejectedDetails: VacancyPublicationRejectedDetails
  VacancyPublicationTypeWrapper: VacancyPublicationTypeWrapper
  VacancyPublishInput: VacancyPublishInput
  VacancyPublishOutput: Omit<VacancyPublishOutput, 'errors'> & { errors: ReadonlyArray<ResolversParentTypes['ModifyVacancyErrorUnion']> }
  VacancyPublishPeriod: VacancyPublishPeriod
  VacancyPublishedEmployerAchievement: VacancyPublishedEmployerAchievement
  VacancyPublishedWithFilledAddressAndSalaryEmployerAchievement: VacancyPublishedWithFilledAddressAndSalaryEmployerAchievement
  VacancyRejectPublicationInput: VacancyRejectPublicationInput
  VacancyRejectPublicationOutput: VacancyRejectPublicationOutput
  VacancyReportInput: VacancyReportInput
  VacancyReportOutput: VacancyReportOutput
  VacancyRepublishInput: VacancyRepublishInput
  VacancyRepublishOutput: Omit<VacancyRepublishOutput, 'errors'> & { errors: ReadonlyArray<ResolversParentTypes['ModifyVacancyErrorUnion']> }
  VacancyResumeInput: VacancyResumeInput
  VacancyResumeOutput: VacancyResumeOutput
  VacancyRisingUpInput: VacancyRisingUpInput
  VacancySalary: VacancySalary
  VacancySalaryInput: VacancySalaryInput
  VacancySearchSuggest: VacancySearchSuggest
  VacancySearchSuggestList: VacancySearchSuggestList
  VacancySearchSuggests: VacancySearchSuggests
  VacancySearchSuggestsQueries: VacancySearchSuggestsQueries
  VacancyServiceActivationBehavior: Omit<VacancyServiceActivationBehavior, 'catalogService'> & { catalogService: Maybe<ResolversParentTypes['CatalogUnitedServices']> }
  VacancyServiceActivationBehaviorInput: VacancyServiceActivationBehaviorInput
  VacancyStatisticsItem: VacancyStatisticsItem
  VacancyStatisticsTimeseries: VacancyStatisticsTimeseries
  VacancyStatusCount: VacancyStatusCount
  VacancySuspendInput: VacancySuspendInput
  VacancySuspendOutput: VacancySuspendOutput
  VacancyTimeseriesItem: VacancyTimeseriesItem
  VacancyToAtsProjectExportError:
    | ResolversParentTypes['AtsProjectCreationError']
    | ResolversParentTypes['InvalidError']
    | ResolversParentTypes['NotAvailableError']
    | ResolversParentTypes['ServerError']
  VacancyToAtsProjectExportInput: VacancyToAtsProjectExportInput
  VacancyToAtsProjectExportOutput: Omit<VacancyToAtsProjectExportOutput, 'error'> & { error: Maybe<ResolversParentTypes['VacancyToAtsProjectExportError']> }
  VacancyToAtsProjectExportResult: VacancyToAtsProjectExportResult
  VacancyUpdateInput: VacancyUpdateInput
  VacancyUpdateOutput: Omit<VacancyUpdateOutput, 'error'> & { error: Maybe<ResolversParentTypes['ModifyVacancyErrorUnion']> }
  ViewNotificationsError: ResolversParentTypes['NotificationNotFoundError'] | ResolversParentTypes['UnknownServerError']
  ViewNotificationsInput: ViewNotificationsInput
  ViewNotificationsOutput: Omit<ViewNotificationsOutput, 'errors'> & { errors: Maybe<ReadonlyArray<ResolversParentTypes['ViewNotificationsError']>> }
  Wallet: Omit<Wallet, 'createdBy' | 'users'> & { createdBy: ResolversParentTypes['WalletUserUnion']; users: ReadonlyArray<ResolversParentTypes['WalletUserUnion']> }
  WalletBalance: WalletBalance
  WalletCartMutation: WalletCartMutation
  WalletDoesNotExistError: WalletDoesNotExistError
  WalletExistsError: WalletExistsError
  WalletTransaction: Omit<WalletTransaction, 'initiator'> & { initiator: ResolversParentTypes['WalletTransactionInitiatorUnion'] }
  WalletTransactionEvent: Omit<WalletTransactionEvent, 'performer'> & { performer: ResolversParentTypes['PerformerEmployerEventUnion'] }
  WalletTransactionInitiatorUnion:
    | ResolversParentTypes['EmployerWalletUser']
    | ResolversParentTypes['ManagerWalletUser']
    | ResolversParentTypes['SeekerWalletUser']
    | ResolversParentTypes['SystemWalletUser']
  WalletTransactionInput: WalletTransactionInput
  WalletTransactionsFilterInput: WalletTransactionsFilterInput
  WalletUserUnion: ResolversParentTypes['EmployerWalletUser'] | ResolversParentTypes['ManagerWalletUser'] | ResolversParentTypes['SeekerWalletUser'] | ResolversParentTypes['SystemWalletUser']
  WalletsConnection: WalletsConnection
  WalletsEdge: WalletsEdge
  WarehouseGift: WarehouseGift
  WarehouseLimitedGift: WarehouseLimitedGift
  WarehouseRange: WarehouseRange
  WidgetPaymentMethod: WidgetPaymentMethod
  WidgetPaymentMethodOutput: WidgetPaymentMethodOutput
  appliesByUrl: AppliesByUrl
  clickOnLinks: ClickOnLinks
  novaPoshtaAddCounterpartyInput: NovaPoshtaAddCounterpartyInput
  novaPoshtaAddGiftAddressInput: NovaPoshtaAddGiftAddressInput
  serviceAvailableContacts: ServiceAvailableContacts
  timeseries: Timeseries
}

export type Apollo_Studio_MetadataDirectiveArgs = {
  buildId: Maybe<Scalars['String']>
  checkId: Maybe<Scalars['String']>
  launchId: Maybe<Scalars['String']>
}

export type Apollo_Studio_MetadataDirectiveResolver<Result, Parent, ContextType = any, Args = Apollo_Studio_MetadataDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type ClientDirectiveArgs = {}

export type ClientDirectiveResolver<Result, Parent, ContextType = any, Args = ClientDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type AccountDateChangesResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountDateChanges'] = ResolversParentTypes['AccountDateChanges']> = {
  changeDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  dateNew?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  dateOld?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AcquaintWithNotificationsErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AcquaintWithNotificationsError'] = ResolversParentTypes['AcquaintWithNotificationsError']
> = {
  __resolveType: TypeResolveFn<'UnknownServerError', ParentType, ContextType>
}

export type AcquaintWithNotificationsOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AcquaintWithNotificationsOutput'] = ResolversParentTypes['AcquaintWithNotificationsOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['AcquaintWithNotificationsError']>>, ParentType, ContextType>
  notifications?: Resolver<Maybe<ReadonlyArray<ResolversTypes['Notification']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ActionAttachResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActionAttach'] = ResolversParentTypes['ActionAttach']> = {
  addDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  awsFileName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  crmActionId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  fileName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  managerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ActionCounterResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActionCounter'] = ResolversParentTypes['ActionCounter']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  state?: Resolver<ResolversTypes['crmActionState'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ActionFiltersResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActionFilters'] = ResolversParentTypes['ActionFilters']> = {
  assigners?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CrmActionCreatorEnum']>>>, ParentType, ContextType>
  offset?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  query?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  types?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CrmActionTypeEnum']>>>, ParentType, ContextType>
  users?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ActionStateCounterResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActionStateCounter'] = ResolversParentTypes['ActionStateCounter']> = {
  actionCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  state?: Resolver<ResolversTypes['crmActionState'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ActionTeamCounterResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActionTeamCounter'] = ResolversParentTypes['ActionTeamCounter']> = {
  counters?: Resolver<ReadonlyArray<ResolversTypes['ActionStateCounter']>, ParentType, ContextType>
  isSalesSupport?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  manager?: Resolver<ResolversTypes['CrmManager'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ActivatedAdditionalServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivatedAdditionalService'] = ResolversParentTypes['ActivatedAdditionalService']> = {
  activatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  endedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  serviceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['ServiceState']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ActivatedCvDbServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivatedCvDbService'] = ResolversParentTypes['ActivatedCvDbService']> = {
  activatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  activator?: Resolver<Maybe<ResolversTypes['ServiceActivator']>, ParentType, ContextType>
  city?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  contactsAccrualPeriod?: Resolver<Maybe<ResolversTypes['ContactsAccrualPeriod']>, ParentType, ContextType>
  endedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  rubric?: Resolver<ResolversTypes['Rubric'], ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  serviceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['ServiceState']>, ParentType, ContextType>
  totalContacts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>
  usagePeriod?: Resolver<Maybe<ResolversTypes['ServiceUsagePeriod']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ActivatedHotServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivatedHotService'] = ResolversParentTypes['ActivatedHotService']> = {
  activatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  availableCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  endedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  parentServiceId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  serviceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['ServiceState']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ActivatedLogoInPortalServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivatedLogoInPortalService'] = ResolversParentTypes['ActivatedLogoInPortalService']> = {
  activatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  branch?: Resolver<ResolversTypes['LogoPortalBranch'], ParentType, ContextType>
  endedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  portal?: Resolver<ResolversTypes['LogoPortal'], ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  serviceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['ServiceState']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ActivatedLogoInRubricServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivatedLogoInRubricService'] = ResolversParentTypes['ActivatedLogoInRubricService']> = {
  activatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  endedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  rubric?: Resolver<ResolversTypes['Rubric'], ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  serviceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['ServiceState']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ActivatedOrderByTurbotaServiceEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActivatedOrderByTurbotaServiceEmployerEvent'] = ResolversParentTypes['ActivatedOrderByTurbotaServiceEmployerEvent']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  order?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ActivatedRisingUpServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivatedRisingUpService'] = ResolversParentTypes['ActivatedRisingUpService']> = {
  activatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  availableCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  endedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  serviceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['ServiceState']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ActivatedServiceEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActivatedServiceEmployerEvent'] = ResolversParentTypes['ActivatedServiceEmployerEvent']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  service?: Resolver<ResolversTypes['ServiceEmployerEventSnapshotUnion'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ActivatedVacancyPackageServiceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActivatedVacancyPackageService'] = ResolversParentTypes['ActivatedVacancyPackageService']
> = {
  activatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  activator?: Resolver<Maybe<ResolversTypes['ServiceActivator']>, ParentType, ContextType>
  additionalServices?: Resolver<ReadonlyArray<Maybe<ResolversTypes['ServiceUnited']>>, ParentType, ContextType>
  availableCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  contactsAccrualPeriod?: Resolver<Maybe<ResolversTypes['ContactsAccrualPeriod']>, ParentType, ContextType>
  contactsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  endedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isPackage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isUnlimitedContacts?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  publicationType?: Resolver<Maybe<ResolversTypes['VacancyPublicationType']>, ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  serviceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['ServiceState']>, ParentType, ContextType>
  supportedRegions?: Resolver<ReadonlyArray<ResolversTypes['City']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>
  usedContactCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  vacanciesPerPackage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  vacancyMailingCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  vacancyPublishDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  vacancyRisingCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ActivatedVacancyPublicationServiceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActivatedVacancyPublicationService'] = ResolversParentTypes['ActivatedVacancyPublicationService']
> = {
  activatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  availableCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  endedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isPackage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  parentServiceId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  publicationType?: Resolver<Maybe<ResolversTypes['VacancyPublicationType']>, ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  serviceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['ServiceState']>, ParentType, ContextType>
  supportedRegions?: Resolver<ReadonlyArray<ResolversTypes['City']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>
  vacancyMailingCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  vacancyPublishDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  vacancyRisingCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdamLabelResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdamLabel'] = ResolversParentTypes['AdamLabel']> = {
  color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  shortCut?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AddClubPostOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddClubPostOutput'] = ResolversParentTypes['AddClubPostOutput']> = {
  post?: Resolver<Maybe<ResolversTypes['ClubPost']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AddGamificationLimitsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddGamificationLimitsOutput'] = ResolversParentTypes['AddGamificationLimitsOutput']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  messageText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AddGiftRequestOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddGiftRequestOutput'] = ResolversParentTypes['AddGiftRequestOutput']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AddOtherSiteLinkOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddOtherSiteLinkOutput'] = ResolversParentTypes['AddOtherSiteLinkOutput']> = {
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  messageText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  vacancyCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AddPromoCodeResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddPromoCodeResponse'] = ResolversParentTypes['AddPromoCodeResponse']> = {
  error?: Resolver<Maybe<ResolversTypes['AddPublicPromoCodeErrorEnum']>, ParentType, ContextType>
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  promoCode?: Resolver<Maybe<ResolversTypes['PromoCode']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AddSeekerFavoriteVacanciesOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddSeekerFavoriteVacanciesOutput'] = ResolversParentTypes['AddSeekerFavoriteVacanciesOutput']
> = {
  query?: Resolver<ResolversTypes['Query'], ParentType, ContextType>
  vacancies?: Resolver<ReadonlyArray<ResolversTypes['Vacancy']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AddSeekerProfResumeAdditionalEducationsErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddSeekerProfResumeAdditionalEducationsError'] = ResolversParentTypes['AddSeekerProfResumeAdditionalEducationsError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type AddSeekerProfResumeAdditionalInfoErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddSeekerProfResumeAdditionalInfoError'] = ResolversParentTypes['AddSeekerProfResumeAdditionalInfoError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type AddSeekerProfResumeEducationsErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddSeekerProfResumeEducationsError'] = ResolversParentTypes['AddSeekerProfResumeEducationsError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type AddSeekerProfResumeExperiencesErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddSeekerProfResumeExperiencesError'] = ResolversParentTypes['AddSeekerProfResumeExperiencesError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type AddSeekerSubscriptionMutationsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddSeekerSubscriptionMutations'] = ResolversParentTypes['AddSeekerSubscriptionMutations']
> = {
  jobAlert?: Resolver<ResolversTypes['SubscriptionOutputType'], ParentType, ContextType, RequireFields<AddSeekerSubscriptionMutationsJobAlertArgs, 'input'>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AddedNewUserManagementEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddedNewUserManagementEmployerEvent'] = ResolversParentTypes['AddedNewUserManagementEmployerEvent']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  employee?: Resolver<ResolversTypes['EmployerEventSnapshot'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AddedProfResumeAdditionalEducationsOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddedProfResumeAdditionalEducationsOutput'] = ResolversParentTypes['AddedProfResumeAdditionalEducationsOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['AddSeekerProfResumeAdditionalEducationsError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AddedProfResumeAdditionalInfoOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddedProfResumeAdditionalInfoOutput'] = ResolversParentTypes['AddedProfResumeAdditionalInfoOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['AddSeekerProfResumeAdditionalInfoError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AddedProfResumeEducationsOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddedProfResumeEducationsOutput'] = ResolversParentTypes['AddedProfResumeEducationsOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['AddSeekerProfResumeEducationsError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AddedProfResumeExperiencesOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddedProfResumeExperiencesOutput'] = ResolversParentTypes['AddedProfResumeExperiencesOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['AddSeekerProfResumeExperiencesError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdditionalCatalogServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdditionalCatalogService'] = ResolversParentTypes['AdditionalCatalogService']> = {
  calculatePrice?: Resolver<ResolversTypes['ServicePrice'], ParentType, ContextType, Partial<AdditionalCatalogServiceCalculatePriceArgs>>
  groupWrapper?: Resolver<ResolversTypes['ServiceGroupWrapper'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  typeWrapper?: Resolver<ResolversTypes['ServiceTypeWrapper'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdditionalServiceEmployerEventSnapshotResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdditionalServiceEmployerEventSnapshot'] = ResolversParentTypes['AdditionalServiceEmployerEventSnapshot']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  catalogService?: Resolver<ResolversTypes['CatalogService'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AdvertisingBannerResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdvertisingBanner'] = ResolversParentTypes['AdvertisingBanner']> = {
  background?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  buttons?: Resolver<ReadonlyArray<ResolversTypes['BannerButton']>, ParentType, ContextType>
  dueTo?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  dynamicElement?: Resolver<Maybe<ResolversTypes['UnionBannerDynamicElement']>, ParentType, ContextType>
  hasCloseButton?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  image?: Resolver<Maybe<ResolversTypes['BannerImageElement']>, ParentType, ContextType>
  mainText?: Resolver<ResolversTypes['BannerTextElement'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['BannerTextElement'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['BannerTypeEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AlreadyReviewedVacancyProlongationErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AlreadyReviewedVacancyProlongationError'] = ResolversParentTypes['AlreadyReviewedVacancyProlongationError']
> = {
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AnotherSourceVacancyOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['AnotherSourceVacancyOutput'] = ResolversParentTypes['AnotherSourceVacancyOutput']> = {
  vacancy?: Resolver<ResolversTypes['VacancyFromAnotherSourceGraphType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AnswerOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AnswerOption'] = ResolversParentTypes['AnswerOption']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isCorrect?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AnswerOptionSuggestResolvers<ContextType = any, ParentType extends ResolversParentTypes['AnswerOptionSuggest'] = ResolversParentTypes['AnswerOptionSuggest']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  isCorrect?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplicationInvitedNotificationDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ApplicationInvitedNotificationDetails'] = ResolversParentTypes['ApplicationInvitedNotificationDetails']
> = {
  application?: Resolver<Maybe<ResolversTypes['Apply']>, ParentType, ContextType>
  detailType?: Resolver<ResolversTypes['SeekerNotificationDetailTypeEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplicationRefusedNotificationDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ApplicationRefusedNotificationDetails'] = ResolversParentTypes['ApplicationRefusedNotificationDetails']
> = {
  application?: Resolver<Maybe<ResolversTypes['Apply']>, ParentType, ContextType>
  detailType?: Resolver<ResolversTypes['SeekerNotificationDetailTypeEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplicationResumeFillingResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplicationResumeFilling'] = ResolversParentTypes['ApplicationResumeFilling']> = {
  percent?: Resolver<ResolversTypes['UInt'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['ResumeFillingType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplicationSentNotificationDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ApplicationSentNotificationDetails'] = ResolversParentTypes['ApplicationSentNotificationDetails']
> = {
  application?: Resolver<Maybe<ResolversTypes['Apply']>, ParentType, ContextType>
  detailType?: Resolver<ResolversTypes['SeekerNotificationDetailTypeEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplicationViewedNotificationDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ApplicationViewedNotificationDetails'] = ResolversParentTypes['ApplicationViewedNotificationDetails']
> = {
  application?: Resolver<Maybe<ResolversTypes['Apply']>, ParentType, ContextType>
  detailType?: Resolver<ResolversTypes['SeekerNotificationDetailTypeEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplicationsViewedEmployerAchievementResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ApplicationsViewedEmployerAchievement'] = ResolversParentTypes['ApplicationsViewedEmployerAchievement']
> = {
  currentCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  isDone?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  targetCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['EmployerAchievementType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Apply'] = ResolversParentTypes['Apply']> = {
  chat?: Resolver<Maybe<ResolversTypes['Chat']>, ParentType, ContextType>
  coveringLetter?: Resolver<Maybe<ResolversTypes['ApplyCoveringLetter']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  interactions?: Resolver<ReadonlyArray<ResolversTypes['CandidateInteraction']>, ParentType, ContextType, Partial<ApplyInteractionsArgs>>
  isRepeatedToVacancy?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  jobSeeker?: Resolver<Maybe<ResolversTypes['ApplyJobSeeker']>, ParentType, ContextType>
  lastTimeDeniedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  lastTimeInvitedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  madeAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  matchedKeywords?: Resolver<ReadonlyArray<Maybe<ResolversTypes['CandidateMatchedKeyword']>>, ParentType, ContextType>
  notes?: Resolver<ReadonlyArray<Maybe<ResolversTypes['ApplyNote']>>, ParentType, ContextType>
  previousApplies?: Resolver<ReadonlyArray<Maybe<ResolversTypes['Apply']>>, ParentType, ContextType>
  questionnaireAnswersRate?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  questionnaireAnswersSummary?: Resolver<Maybe<ResolversTypes['QuestionnaireAnswersSummary']>, ParentType, ContextType>
  resume?: Resolver<ResolversTypes['ApplyResumeInterface'], ParentType, ContextType>
  source?: Resolver<ResolversTypes['CandidateSource'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['ApplyStatus'], ParentType, ContextType>
  surveyResult?: Resolver<Maybe<ResolversTypes['ApplySurveyResult']>, ParentType, ContextType>
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>
  vacancy?: Resolver<Maybe<ResolversTypes['Vacancy']>, ParentType, ContextType>
  viewDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyAddCompanyAddressOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyAddCompanyAddressOutput'] = ResolversParentTypes['ApplyAddCompanyAddressOutput']> = {
  address?: Resolver<ResolversTypes['ApplyCompanyAddress'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyAddNoteOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyAddNoteOutput'] = ResolversParentTypes['ApplyAddNoteOutput']> = {
  note?: Resolver<Maybe<ResolversTypes['ApplyNote']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyAggregationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyAggregation'] = ResolversParentTypes['ApplyAggregation']> = {
  items?: Resolver<ReadonlyArray<ResolversTypes['Facet']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyAtsProjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyAtsProject'] = ResolversParentTypes['ApplyAtsProject']> = {
  candidateUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  cityName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isOwnedByCurrentUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ownerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyAtsProjectEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyAtsProjectEdge'] = ResolversParentTypes['ApplyAtsProjectEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['ApplyAtsProject'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyAttachParsedFileContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyAttachParsedFileContent'] = ResolversParentTypes['ApplyAttachParsedFileContent']> = {
  experience?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  phones?: Resolver<ReadonlyArray<Maybe<ResolversTypes['String']>>, ParentType, ContextType>
  skills?: Resolver<ReadonlyArray<Maybe<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyAttachResumeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyAttachResume'] = ResolversParentTypes['ApplyAttachResume']> = {
  contacts?: Resolver<ResolversTypes['ApplyResumeContacts'], ParentType, ContextType>
  downloadFileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  downloadFileUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  parsedFileContent?: Resolver<Maybe<ResolversTypes['ApplyAttachParsedFileContent']>, ParentType, ContextType>
  parsedResume?: Resolver<Maybe<ResolversTypes['ApplyProfResume']>, ParentType, ContextType>
  photoUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  previewFileUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyChangeStatusOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyChangeStatusOutput'] = ResolversParentTypes['ApplyChangeStatusOutput']> = {
  apply?: Resolver<Maybe<ResolversTypes['Apply']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyChangeStatusesOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyChangeStatusesOutput'] = ResolversParentTypes['ApplyChangeStatusesOutput']> = {
  applies?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Apply']>>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyCompanyAddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyCompanyAddress'] = ResolversParentTypes['ApplyCompanyAddress']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyConnection'] = ResolversParentTypes['ApplyConnection']> = {
  aggregations?: Resolver<ReadonlyArray<ResolversTypes['ApplyAggregation']>, ParentType, ContextType, Partial<ApplyConnectionAggregationsArgs>>
  edges?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['ApplyEdge']>>>, ParentType, ContextType>
  items?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Apply']>>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyContactLinkResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyContactLink'] = ResolversParentTypes['ApplyContactLink']> = {
  type?: Resolver<ResolversTypes['ApplyContactLinkType'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyCoveringLetterResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyCoveringLetter'] = ResolversParentTypes['ApplyCoveringLetter']> = {
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyDeleteCompanyAddressOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ApplyDeleteCompanyAddressOutput'] = ResolversParentTypes['ApplyDeleteCompanyAddressOutput']
> = {
  addressId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyDeleteNoteOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyDeleteNoteOutput'] = ResolversParentTypes['ApplyDeleteNoteOutput']> = {
  noteId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyEdge'] = ResolversParentTypes['ApplyEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<Maybe<ResolversTypes['Apply']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyEditNoteOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyEditNoteOutput'] = ResolversParentTypes['ApplyEditNoteOutput']> = {
  note?: Resolver<Maybe<ResolversTypes['ApplyNote']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyEmailResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyEmail'] = ResolversParentTypes['ApplyEmail']> = {
  confirmed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyEmployerResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyEmployer'] = ResolversParentTypes['ApplyEmployer']> = {
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyEmptyResumeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyEmptyResume'] = ResolversParentTypes['ApplyEmptyResume']> = {
  contacts?: Resolver<ResolversTypes['ApplyResumeContacts'], ParentType, ContextType>
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  photoUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyFacetUnionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyFacetUnion'] = ResolversParentTypes['ApplyFacetUnion']> = {
  __resolveType: TypeResolveFn<'ApplyStatus' | 'CandidateInteractionWrapper' | 'CandidateSourceType' | 'ResumeFillingType', ParentType, ContextType>
}

export type ApplyInvitationDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyInvitationDetails'] = ResolversParentTypes['ApplyInvitationDetails']> = {
  addresses?: Resolver<ReadonlyArray<Maybe<ResolversTypes['ApplyCompanyAddress']>>, ParentType, ContextType>
  customText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  defaultText?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyInviteOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyInviteOutput'] = ResolversParentTypes['ApplyInviteOutput']> = {
  apply?: Resolver<Maybe<ResolversTypes['Apply']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyJobSeekerResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyJobSeeker'] = ResolversParentTypes['ApplyJobSeeker']> = {
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isOnline?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  lastActivityAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyNoteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyNote'] = ResolversParentTypes['ApplyNote']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  madeAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  madeBy?: Resolver<ResolversTypes['ApplyEmployer'], ParentType, ContextType>
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyPhoneResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyPhone'] = ResolversParentTypes['ApplyPhone']> = {
  confirmed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyProfResumeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyProfResume'] = ResolversParentTypes['ApplyProfResume']> = {
  additionalEducations?: Resolver<ReadonlyArray<Maybe<ResolversTypes['ApplyResumeAdditionalEducation']>>, ParentType, ContextType>
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  areContactsOpened?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  birthdate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  city?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  contacts?: Resolver<ResolversTypes['ApplyResumeContacts'], ParentType, ContextType>
  desiredPosition?: Resolver<ResolversTypes['ApplyResumeDesiredPosition'], ParentType, ContextType>
  downloadFileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  downloadFileUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  experiences?: Resolver<ReadonlyArray<Maybe<ResolversTypes['ApplyResumeExperience']>>, ParentType, ContextType>
  filling?: Resolver<Maybe<ResolversTypes['ApplicationResumeFilling']>, ParentType, ContextType>
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  gender?: Resolver<ResolversTypes['ApplyResumeGender'], ParentType, ContextType>
  generalEducations?: Resolver<ReadonlyArray<Maybe<ResolversTypes['ApplyResumeGeneralEducation']>>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  infos?: Resolver<ReadonlyArray<Maybe<ResolversTypes['ApplyResumeAdditional']>>, ParentType, ContextType>
  isAnonymous?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  languageSkills?: Resolver<ReadonlyArray<Maybe<ResolversTypes['ApplyResumeLanguageSkill']>>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['ApplyProfResumeFullName'], ParentType, ContextType>
  photoUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  relevanceScore?: Resolver<Maybe<ResolversTypes['RelevanceScore']>, ParentType, ContextType>
  relocations?: Resolver<ReadonlyArray<Maybe<ResolversTypes['City']>>, ParentType, ContextType>
  skillsSummary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  subRubrics?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Subrubric']>>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyProfResumeFullNameResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyProfResumeFullName'] = ResolversParentTypes['ApplyProfResumeFullName']> = {
  fatherName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyProjectAtsConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyProjectAtsConnection'] = ResolversParentTypes['ApplyProjectAtsConnection']> = {
  candidateInDatabaseUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  edges?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['ApplyAtsProjectEdge']>>>, ParentType, ContextType>
  items?: Resolver<Maybe<ReadonlyArray<ResolversTypes['ApplyAtsProject']>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  presentInOtherUsersProjectsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyRefusalDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyRefusalDetails'] = ResolversParentTypes['ApplyRefusalDetails']> = {
  customText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  defaultText?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyRefuseOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyRefuseOutput'] = ResolversParentTypes['ApplyRefuseOutput']> = {
  apply?: Resolver<Maybe<ResolversTypes['Apply']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyResumeAdditionalResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyResumeAdditional'] = ResolversParentTypes['ApplyResumeAdditional']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyResumeAdditionalEducationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ApplyResumeAdditionalEducation'] = ResolversParentTypes['ApplyResumeAdditionalEducation']
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  institutionTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  yearOfGraduation?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyResumeContactsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyResumeContacts'] = ResolversParentTypes['ApplyResumeContacts']> = {
  emails?: Resolver<ReadonlyArray<Maybe<ResolversTypes['ApplyEmail']>>, ParentType, ContextType>
  phones?: Resolver<ReadonlyArray<Maybe<ResolversTypes['ApplyPhone']>>, ParentType, ContextType>
  socials?: Resolver<ReadonlyArray<Maybe<ResolversTypes['ApplyContactLink']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyResumeDesiredPositionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyResumeDesiredPosition'] = ResolversParentTypes['ApplyResumeDesiredPosition']> = {
  salary?: Resolver<Maybe<ResolversTypes['ApplySalary']>, ParentType, ContextType>
  schedule?: Resolver<Maybe<ResolversTypes['Schedule']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyResumeExperienceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyResumeExperience'] = ResolversParentTypes['ApplyResumeExperience']> = {
  begin?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  companyBranch?: Resolver<Maybe<ResolversTypes['Branch']>, ParentType, ContextType>
  companyTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  end?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  position?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  recommendations?: Resolver<ReadonlyArray<Maybe<ResolversTypes['ApplyResumeRecommendation']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyResumeFillingTypeCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyResumeFillingTypeCount'] = ResolversParentTypes['ApplyResumeFillingTypeCount']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['ResumeFillingType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyResumeGeneralEducationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyResumeGeneralEducation'] = ResolversParentTypes['ApplyResumeGeneralEducation']> = {
  institutionTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  level?: Resolver<ResolversTypes['EducationLevel'], ParentType, ContextType>
  location?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  speciality?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  yearOfGraduation?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyResumeInterfaceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyResumeInterface'] = ResolversParentTypes['ApplyResumeInterface']> = {
  __resolveType: TypeResolveFn<'ApplyAttachResume' | 'ApplyEmptyResume' | 'ApplyProfResume', ParentType, ContextType>
  contacts?: Resolver<ResolversTypes['ApplyResumeContacts'], ParentType, ContextType>
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  photoUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
}

export type ApplyResumeLanguageSkillResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyResumeLanguageSkill'] = ResolversParentTypes['ApplyResumeLanguageSkill']> = {
  canPassInterview?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  certificate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  language?: Resolver<ResolversTypes['Lang'], ParentType, ContextType>
  level?: Resolver<ResolversTypes['LanguageSkillLevel'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyResumeRecommendationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyResumeRecommendation'] = ResolversParentTypes['ApplyResumeRecommendation']> = {
  canShowOnDemand?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  companyTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  position?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplySalaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplySalary'] = ResolversParentTypes['ApplySalary']> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyStatus'] = ResolversParentTypes['ApplyStatus']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyStatusCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyStatusCount'] = ResolversParentTypes['ApplyStatusCount']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['ApplyStatus'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplySurverQuestionImportanceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ApplySurverQuestionImportance'] = ResolversParentTypes['ApplySurverQuestionImportance']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplySurveyAnswerResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplySurveyAnswer'] = ResolversParentTypes['ApplySurveyAnswer']> = {
  correctAnswer?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  importance?: Resolver<ResolversTypes['ApplySurverQuestionImportance'], ParentType, ContextType>
  question?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  userAnswer?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplySurveyResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplySurveyResult'] = ResolversParentTypes['ApplySurveyResult']> = {
  answers?: Resolver<ReadonlyArray<Maybe<ResolversTypes['ApplySurveyAnswer']>>, ParentType, ContextType>
  percentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyToAtsExportErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyToAtsExportError'] = ResolversParentTypes['ApplyToAtsExportError']> = {
  __resolveType: TypeResolveFn<'InvalidError' | 'NotAvailableError' | 'ServerError', ParentType, ContextType>
}

export type ApplyToAtsExportOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyToAtsExportOutput'] = ResolversParentTypes['ApplyToAtsExportOutput']> = {
  error?: Resolver<Maybe<ResolversTypes['ApplyToAtsExportError']>, ParentType, ContextType>
  result?: Resolver<Maybe<ResolversTypes['ApplyToAtsExportResult']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyToAtsExportResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyToAtsExportResult'] = ResolversParentTypes['ApplyToAtsExportResult']> = {
  candidateDatabaseUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  candidateProjectUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyUpdateCompanyAddressOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ApplyUpdateCompanyAddressOutput'] = ResolversParentTypes['ApplyUpdateCompanyAddressOutput']
> = {
  address?: Resolver<ResolversTypes['ApplyCompanyAddress'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyVacancyKeywordResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyVacancyKeyword'] = ResolversParentTypes['ApplyVacancyKeyword']> = {
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApplyWalletBonusResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplyWalletBonusResponse'] = ResolversParentTypes['ApplyWalletBonusResponse']> = {
  cart?: Resolver<ResolversTypes['CartQuery'], ParentType, ContextType>
  errors?: Resolver<ReadonlyArray<ResolversTypes['ApplyWalletBonusCodeErrorEnum']>, ParentType, ContextType>
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApprovedProlongationRequestVacancyEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ApprovedProlongationRequestVacancyEmployerEvent'] = ResolversParentTypes['ApprovedProlongationRequestVacancyEmployerEvent']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  vacancyCity?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  vacancyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  vacancyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  vacancyOwner?: Resolver<ResolversTypes['EmployerEventSnapshot'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ApprovedReviewVacancyEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ApprovedReviewVacancyEmployerEvent'] = ResolversParentTypes['ApprovedReviewVacancyEmployerEvent']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  vacancyCity?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  vacancyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  vacancyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  vacancyOwner?: Resolver<ResolversTypes['EmployerEventSnapshot'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AssignmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Assignment'] = ResolversParentTypes['Assignment']> = {
  attaches?: Resolver<ReadonlyArray<ResolversTypes['FileAttach']>, ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['CrmCompany']>, ParentType, ContextType>
  completeAt?: Resolver<Maybe<ResolversTypes['DateTimeOffset']>, ParentType, ContextType>
  contactType?: Resolver<Maybe<ResolversTypes['TaskContactTypeEnum']>, ParentType, ContextType>
  contactWarmth?: Resolver<Maybe<ResolversTypes['ContactWarmthTypeEnum']>, ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  employee?: Resolver<Maybe<ResolversTypes['CrmEmployee']>, ParentType, ContextType>
  events?: Resolver<ReadonlyArray<ResolversTypes['EventInterface']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  initiator?: Resolver<Maybe<ResolversTypes['CrmManager']>, ParentType, ContextType>
  isArchive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isInvokedByEvent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isWholeDay?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  madeAt?: Resolver<Maybe<ResolversTypes['DateTimeOffset']>, ParentType, ContextType>
  nextReschedule?: Resolver<Maybe<ResolversTypes['DateTimeOffset']>, ParentType, ContextType>
  planAt?: Resolver<Maybe<ResolversTypes['DateTimeOffset']>, ParentType, ContextType>
  plannedTask?: Resolver<Maybe<ResolversTypes['Assignment']>, ParentType, ContextType>
  points?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  priority?: Resolver<Maybe<ResolversTypes['TaskPriorityEnum']>, ParentType, ContextType>
  readAt?: Resolver<Maybe<ResolversTypes['DateTimeOffset']>, ParentType, ContextType>
  rescheduleStepHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  rescheduledCalls?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  responsible?: Resolver<Maybe<ResolversTypes['CrmManager']>, ParentType, ContextType>
  result?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  sentAt?: Resolver<Maybe<ResolversTypes['DateTimeOffset']>, ParentType, ContextType>
  sortAt?: Resolver<Maybe<ResolversTypes['DateTimeOffset']>, ParentType, ContextType>
  status?: Resolver<Maybe<ResolversTypes['TaskStatusEnum']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AssignmentConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AssignmentConnection'] = ResolversParentTypes['AssignmentConnection']> = {
  edges?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['AssignmentEdge']>>>, ParentType, ContextType>
  items?: Resolver<Maybe<ReadonlyArray<ResolversTypes['Assignment']>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AssignmentEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AssignmentEdge'] = ResolversParentTypes['AssignmentEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['Assignment'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AssignmentOperationOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['AssignmentOperationOutput'] = ResolversParentTypes['AssignmentOperationOutput']> = {
  assignment?: Resolver<Maybe<ResolversTypes['Assignment']>, ParentType, ContextType>
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AtsProjectCreationErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['AtsProjectCreationError'] = ResolversParentTypes['AtsProjectCreationError']> = {
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AttachNotFoundResolvers<ContextType = any, ParentType extends ResolversParentTypes['AttachNotFound'] = ResolversParentTypes['AttachNotFound']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AttachParsingErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['AttachParsingError'] = ResolversParentTypes['AttachParsingError']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AutoProlongationDisabledVacancyEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AutoProlongationDisabledVacancyEmployerEvent'] = ResolversParentTypes['AutoProlongationDisabledVacancyEmployerEvent']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  vacancyCity?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  vacancyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  vacancyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AutoProlongationEnabledVacancyEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AutoProlongationEnabledVacancyEmployerEvent'] = ResolversParentTypes['AutoProlongationEnabledVacancyEmployerEvent']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  autoActivateService?: Resolver<Maybe<ResolversTypes['CatalogService']>, ParentType, ContextType>
  duration?: Resolver<ResolversTypes['AutoProlongationVacancyDurationEmployerEventEnum'], ParentType, ContextType>
  hasServiceAutoActivation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  vacancyCity?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  vacancyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  vacancyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  vacancyPublicationType?: Resolver<ResolversTypes['VacancyPublicationTypeWrapper'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AutoProlongedVacancyEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AutoProlongedVacancyEmployerEvent'] = ResolversParentTypes['AutoProlongedVacancyEmployerEvent']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['SystemEventPerformer'], ParentType, ContextType>
  vacancyCity?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  vacancyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  vacancyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BanInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['BanInfo'] = ResolversParentTypes['BanInfo']> = {
  banReason?: Resolver<Maybe<ReadonlyArray<ResolversTypes['BanReasonInfo']>>, ParentType, ContextType>
  isBannedByModerator?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BanReasonInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['BanReasonInfo'] = ResolversParentTypes['BanReasonInfo']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  text2?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  text2Ukr?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  text3?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  text3Ukr?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BannerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Banner'] = ResolversParentTypes['Banner']> = {
  __resolveType: TypeResolveFn<'AdvertisingBanner' | 'InformationBanner', ParentType, ContextType>
  background?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  buttons?: Resolver<ReadonlyArray<ResolversTypes['BannerButton']>, ParentType, ContextType>
  dueTo?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  hasCloseButton?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  image?: Resolver<Maybe<ResolversTypes['BannerImageElement']>, ParentType, ContextType>
  mainText?: Resolver<ResolversTypes['BannerTextElement'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['BannerTypeEnum'], ParentType, ContextType>
}

export type BannerButtonResolvers<ContextType = any, ParentType extends ResolversParentTypes['BannerButton'] = ResolversParentTypes['BannerButton']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['BannerButtonTypeEnum'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  urlType?: Resolver<ResolversTypes['BannerButtonLinkTypeEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BannerClockResolvers<ContextType = any, ParentType extends ResolversParentTypes['BannerClock'] = ResolversParentTypes['BannerClock']> = {
  end?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['BannerDynamicElementTypeEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BannerDynamicElementResolvers<ContextType = any, ParentType extends ResolversParentTypes['BannerDynamicElement'] = ResolversParentTypes['BannerDynamicElement']> = {
  __resolveType: TypeResolveFn<'BannerClock' | 'BannerProgressBar', ParentType, ContextType>
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['BannerDynamicElementTypeEnum'], ParentType, ContextType>
}

export type BannerImageElementResolvers<ContextType = any, ParentType extends ResolversParentTypes['BannerImageElement'] = ResolversParentTypes['BannerImageElement']> = {
  sizes?: Resolver<ReadonlyArray<ResolversTypes['BannerImageSize']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BannerImageSizeResolvers<ContextType = any, ParentType extends ResolversParentTypes['BannerImageSize'] = ResolversParentTypes['BannerImageSize']> = {
  size?: Resolver<ResolversTypes['BannerImageSizeEnum'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BannerProgressBarResolvers<ContextType = any, ParentType extends ResolversParentTypes['BannerProgressBar'] = ResolversParentTypes['BannerProgressBar']> = {
  current?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  max?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['BannerDynamicElementTypeEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BannerQueriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['BannerQueries'] = ResolversParentTypes['BannerQueries']> = {
  myAdvertising?: Resolver<Maybe<ResolversTypes['AdvertisingBanner']>, ParentType, ContextType>
  myInformational?: Resolver<ReadonlyArray<ResolversTypes['InformationBanner']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BannerTextElementResolvers<ContextType = any, ParentType extends ResolversParentTypes['BannerTextElement'] = ResolversParentTypes['BannerTextElement']> = {
  color?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BasicQuestionResolvers<ContextType = any, ParentType extends ResolversParentTypes['BasicQuestion'] = ResolversParentTypes['BasicQuestion']> = {
  __resolveType: TypeResolveFn<'ExperienceBasicQuestion' | 'LanguageBasicQuestion', ParentType, ContextType>
}

export type BasicQuestionItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['BasicQuestionItem'] = ResolversParentTypes['BasicQuestionItem']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BillFiltersResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillFilters'] = ResolversParentTypes['BillFilters']> = {
  hasActs?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  offset?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  quarters?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Quarter']>>>, ParentType, ContextType>
  states?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CrmAccountState']>>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BlockedUserManagementEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockedUserManagementEmployerEvent'] = ResolversParentTypes['BlockedUserManagementEmployerEvent']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  employee?: Resolver<ResolversTypes['EmployerEventSnapshot'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BranchResolvers<ContextType = any, ParentType extends ResolversParentTypes['Branch'] = ResolversParentTypes['Branch']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  popularProfessionsTags?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['TagGroup']>>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BriefParsedCvResolvers<ContextType = any, ParentType extends ResolversParentTypes['BriefParsedCv'] = ResolversParentTypes['BriefParsedCv']> = {
  added?: Resolver<ResolversTypes['DateTimeOffset'], ParentType, ContextType>
  assinged?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  assingedName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  state?: Resolver<ResolversTypes['ParsedCvStateEnum'], ParentType, ContextType>
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BriefParsedCvEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['BriefParsedCvEdge'] = ResolversParentTypes['BriefParsedCvEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['BriefParsedCv'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BucketResolvers<ContextType = any, ParentType extends ResolversParentTypes['Bucket'] = ResolversParentTypes['Bucket']> = {
  docCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  salaryFrom?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  salaryTo?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CabinetEventsDateRangeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CabinetEventsDateRange'] = ResolversParentTypes['CabinetEventsDateRange']> = {
  begin?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  end?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CabinetEventsFilterResolvers<ContextType = any, ParentType extends ResolversParentTypes['CabinetEventsFilter'] = ResolversParentTypes['CabinetEventsFilter']> = {
  dateRange?: Resolver<ResolversTypes['CabinetEventsDateRange'], ParentType, ContextType>
  employeeIds?: Resolver<Maybe<ReadonlyArray<ResolversTypes['ID']>>, ParentType, ContextType>
  types?: Resolver<Maybe<ReadonlyArray<ResolversTypes['EmployerEventTypeEnum']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CabinetEventsQueryFilterResolvers<ContextType = any, ParentType extends ResolversParentTypes['CabinetEventsQueryFilter'] = ResolversParentTypes['CabinetEventsQueryFilter']> = {
  filter?: Resolver<ResolversTypes['CabinetEventsFilter'], ParentType, ContextType>
  page?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CalculatePriceItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['CalculatePriceItem'] = ResolversParentTypes['CalculatePriceItem']> = {
  price?: Resolver<Maybe<ResolversTypes['ServicePrice']>, ParentType, ContextType>
  requestFilter?: Resolver<ResolversTypes['CalculatePricesFilter'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CalculatePricesFilterResolvers<ContextType = any, ParentType extends ResolversParentTypes['CalculatePricesFilter'] = ResolversParentTypes['CalculatePricesFilter']> = {
  city?: Resolver<Maybe<ResolversTypes['City']>, ParentType, ContextType>
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  rubric?: Resolver<Maybe<ResolversTypes['Rubric']>, ParentType, ContextType>
  serviceType?: Resolver<ResolversTypes['ServiceTypeWrapper'], ParentType, ContextType>
  usagePeriod?: Resolver<Maybe<ResolversTypes['UsagePeriodTypeWrapper']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CallFiltersResolvers<ContextType = any, ParentType extends ResolversParentTypes['CallFilters'] = ResolversParentTypes['CallFilters']> = {
  companyId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  date?: Resolver<Maybe<ResolversTypes['DateInterval']>, ParentType, ContextType>
  query?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  types?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CrmCallTypeRequest']>>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CandidateInteractionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CandidateInteraction'] = ResolversParentTypes['CandidateInteraction']> = {
  accuredAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['CandidateInteractionWrapper'], ParentType, ContextType>
  vacancy?: Resolver<Maybe<ResolversTypes['Vacancy']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CandidateInteractionWrapperResolvers<ContextType = any, ParentType extends ResolversParentTypes['CandidateInteractionWrapper'] = ResolversParentTypes['CandidateInteractionWrapper']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['CandidateInteractionEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CandidateMatchedKeywordResolvers<ContextType = any, ParentType extends ResolversParentTypes['CandidateMatchedKeyword'] = ResolversParentTypes['CandidateMatchedKeyword']> = {
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CandidateResumeEmployerEventSnapshotResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CandidateResumeEmployerEventSnapshot'] = ResolversParentTypes['CandidateResumeEmployerEventSnapshot']
> = {
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  speciality?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CandidateSourceTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CandidateSourceType'] = ResolversParentTypes['CandidateSourceType']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['CandidateType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CandidatesVacancyCountersResolvers<ContextType = any, ParentType extends ResolversParentTypes['CandidatesVacancyCounters'] = ResolversParentTypes['CandidatesVacancyCounters']> = {
  new?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['CandidateSourceType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CartItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['CartItem'] = ResolversParentTypes['CartItem']> = {
  appliedPromoCodes?: Resolver<ReadonlyArray<ResolversTypes['PromoCode']>, ParentType, ContextType>
  appliedTotalBonusAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  city?: Resolver<Maybe<ResolversTypes['City']>, ParentType, ContextType>
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  nearPrices?: Resolver<ReadonlyArray<ResolversTypes['ServicePrice']>, ParentType, ContextType>
  price?: Resolver<ResolversTypes['ServicePrice'], ParentType, ContextType>
  rubric?: Resolver<Maybe<ResolversTypes['Rubric']>, ParentType, ContextType>
  service?: Resolver<ResolversTypes['CatalogUnitedServices'], ParentType, ContextType>
  serviceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['ServiceTypeWrapper'], ParentType, ContextType>
  usagePeriod?: Resolver<Maybe<ResolversTypes['UsagePeriodTypeWrapper']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CartMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CartMutation'] = ResolversParentTypes['CartMutation']> = {
  add?: Resolver<ResolversTypes['CartQuery'], ParentType, ContextType, RequireFields<CartMutationAddArgs, 'input'>>
  orders?: Resolver<ResolversTypes['OrderCartMutation'], ParentType, ContextType>
  promoCodes?: Resolver<ResolversTypes['PromoCodeCartMutation'], ParentType, ContextType>
  remove?: Resolver<ResolversTypes['CartQuery'], ParentType, ContextType, RequireFields<CartMutationRemoveArgs, 'input'>>
  update?: Resolver<ResolversTypes['CartQuery'], ParentType, ContextType, RequireFields<CartMutationUpdateArgs, 'input'>>
  walletBonuses?: Resolver<ResolversTypes['WalletCartMutation'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CartQueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['CartQuery'] = ResolversParentTypes['CartQuery']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<ReadonlyArray<ResolversTypes['CartItem']>, ParentType, ContextType>
  promoCodes?: Resolver<Maybe<ResolversTypes['PromoCodeConnection']>, ParentType, ContextType, Partial<CartQueryPromoCodesArgs>>
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CatalogChildServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['CatalogChildService'] = ResolversParentTypes['CatalogChildService']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  service?: Resolver<ResolversTypes['CatalogUnitedServices'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CatalogServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['CatalogService'] = ResolversParentTypes['CatalogService']> = {
  detailsUnion?: Resolver<ResolversTypes['CatalogUnitedServices'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CatalogUnitedServicesResolvers<ContextType = any, ParentType extends ResolversParentTypes['CatalogUnitedServices'] = ResolversParentTypes['CatalogUnitedServices']> = {
  __resolveType: TypeResolveFn<
    | 'AdditionalCatalogService'
    | 'CvDbCatalogService'
    | 'HotCatalogService'
    | 'LogoInPortalCatalogService'
    | 'LogoInRubricCatalogService'
    | 'LogoOnMainPageCatalogService'
    | 'MarketingCatalogService'
    | 'RisingUpCatalogService'
    | 'VacancyPackageCatalogService'
    | 'VacancyPublicationCatalogService',
    ParentType,
    ContextType
  >
}

export type CategorizationAttachesMutationsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CategorizationAttachesMutations'] = ResolversParentTypes['CategorizationAttachesMutations']
> = {
  addComment?: Resolver<ResolversTypes['CategorizationResultOutput'], ParentType, ContextType, RequireFields<CategorizationAttachesMutationsAddCommentArgs, 'cvId' | 'note'>>
  setPositionTags?: Resolver<ResolversTypes['CategorizationResultOutput'], ParentType, ContextType, RequireFields<CategorizationAttachesMutationsSetPositionTagsArgs, 'input'>>
  setRubrics?: Resolver<ResolversTypes['CategorizationResultOutput'], ParentType, ContextType, RequireFields<CategorizationAttachesMutationsSetRubricsArgs, 'input'>>
  setState?: Resolver<ResolversTypes['CategorizationResultOutput'], ParentType, ContextType, RequireFields<CategorizationAttachesMutationsSetStateArgs, 'input'>>
  setTextTags?: Resolver<ResolversTypes['CategorizationResultOutput'], ParentType, ContextType, RequireFields<CategorizationAttachesMutationsSetTextTagsArgs, 'input'>>
  updateAdamLabel?: Resolver<ResolversTypes['CategorizationResultOutput'], ParentType, ContextType, RequireFields<CategorizationAttachesMutationsUpdateAdamLabelArgs, 'input'>>
  updateCvName?: Resolver<ResolversTypes['CategorizationResultOutput'], ParentType, ContextType, RequireFields<CategorizationAttachesMutationsUpdateCvNameArgs, 'cvId' | 'name'>>
  updateLabel?: Resolver<ResolversTypes['CategorizationResultOutput'], ParentType, ContextType, RequireFields<CategorizationAttachesMutationsUpdateLabelArgs, 'input'>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CategorizationAttachesQueriesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CategorizationAttachesQueries'] = ResolversParentTypes['CategorizationAttachesQueries']
> = {
  adamLabels?: Resolver<ReadonlyArray<ResolversTypes['AdamLabel']>, ParentType, ContextType>
  categorizators?: Resolver<ReadonlyArray<ResolversTypes['Categorizator']>, ParentType, ContextType>
  parsedCv?: Resolver<Maybe<ResolversTypes['ParsedCv']>, ParentType, ContextType, RequireFields<CategorizationAttachesQueriesParsedCvArgs, 'cvId'>>
  parsedCvList?: Resolver<Maybe<ResolversTypes['ParsedCvConnection']>, ParentType, ContextType, Partial<CategorizationAttachesQueriesParsedCvListArgs>>
  positionTags?: Resolver<ReadonlyArray<ResolversTypes['SynonymTag']>, ParentType, ContextType, RequireFields<CategorizationAttachesQueriesPositionTagsArgs, 'term'>>
  textTags?: Resolver<ReadonlyArray<ResolversTypes['SynonymTag']>, ParentType, ContextType, RequireFields<CategorizationAttachesQueriesTextTagsArgs, 'term'>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CategorizationResultOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategorizationResultOutput'] = ResolversParentTypes['CategorizationResultOutput']> = {
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CategorizatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['Categorizator'] = ResolversParentTypes['Categorizator']> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChangeSavedCardsErrorUnionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChangeSavedCardsErrorUnion'] = ResolversParentTypes['ChangeSavedCardsErrorUnion']> = {
  __resolveType: TypeResolveFn<'HasNoScopesForActionError' | 'InvalidError' | 'ServerError' | 'UserIsNotAuthenticatedError', ParentType, ContextType>
}

export type ChangeSavedCardsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChangeSavedCardsResponse'] = ResolversParentTypes['ChangeSavedCardsResponse']> = {
  errors?: Resolver<ReadonlyArray<ResolversTypes['ChangeSavedCardsErrorUnion']>, ParentType, ContextType>
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  savedCards?: Resolver<ReadonlyArray<ResolversTypes['SavedCard']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChangeSeekerEmailMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChangeSeekerEmailMutation'] = ResolversParentTypes['ChangeSeekerEmailMutation']> = {
  addPasswordAndSendConfirmationEmail?: Resolver<
    ResolversTypes['SendChangeSeekerEmailOutput'],
    ParentType,
    ContextType,
    RequireFields<ChangeSeekerEmailMutationAddPasswordAndSendConfirmationEmailArgs, 'input'>
  >
  confirm?: Resolver<ResolversTypes['ChangeSeekerEmailOutput'], ParentType, ContextType, RequireFields<ChangeSeekerEmailMutationConfirmArgs, 'key'>>
  sendConfirmationEmail?: Resolver<ResolversTypes['SendChangeSeekerEmailOutput'], ParentType, ContextType, RequireFields<ChangeSeekerEmailMutationSendConfirmationEmailArgs, 'input'>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChangeSeekerEmailOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChangeSeekerEmailOutput'] = ResolversParentTypes['ChangeSeekerEmailOutput']> = {
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChangeSeekerNameMutationOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChangeSeekerNameMutationOutput'] = ResolversParentTypes['ChangeSeekerNameMutationOutput']
> = {
  seeker?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChangeSeekerPasswordByEmailMutationsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChangeSeekerPasswordByEmailMutations'] = ResolversParentTypes['ChangeSeekerPasswordByEmailMutations']
> = {
  confirmAndChange?: Resolver<ResolversTypes['ChangeSeekerPasswordOutput'], ParentType, ContextType, RequireFields<ChangeSeekerPasswordByEmailMutationsConfirmAndChangeArgs, 'input'>>
  sendConfirmation?: Resolver<ResolversTypes['SendEmailOutput'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChangeSeekerPasswordByPhoneMutationsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChangeSeekerPasswordByPhoneMutations'] = ResolversParentTypes['ChangeSeekerPasswordByPhoneMutations']
> = {
  confirmAndChange?: Resolver<ResolversTypes['ChangeSeekerPasswordOutput'], ParentType, ContextType, RequireFields<ChangeSeekerPasswordByPhoneMutationsConfirmAndChangeArgs, 'input'>>
  confirmCode?: Resolver<ResolversTypes['PhoneConfirmationOutput'], ParentType, ContextType, RequireFields<ChangeSeekerPasswordByPhoneMutationsConfirmCodeArgs, 'code'>>
  sendConfirmation?: Resolver<ResolversTypes['SendSmsOutput'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChangeSeekerPasswordMutationsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChangeSeekerPasswordMutations'] = ResolversParentTypes['ChangeSeekerPasswordMutations']
> = {
  byEmail?: Resolver<ResolversTypes['ChangeSeekerPasswordByEmailMutations'], ParentType, ContextType>
  byPhone?: Resolver<ResolversTypes['ChangeSeekerPasswordByPhoneMutations'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChangeSeekerPasswordOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChangeSeekerPasswordOutput'] = ResolversParentTypes['ChangeSeekerPasswordOutput']> = {
  status?: Resolver<ResolversTypes['ChangeSeekerPasswordStatusEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChangedFullNameUserManagementEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChangedFullNameUserManagementEmployerEvent'] = ResolversParentTypes['ChangedFullNameUserManagementEmployerEvent']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  employee?: Resolver<ResolversTypes['Employee'], ParentType, ContextType>
  fullNameAfter?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  fullNameBefore?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChangedScopesUserManagementEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChangedScopesUserManagementEmployerEvent'] = ResolversParentTypes['ChangedScopesUserManagementEmployerEvent']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  scopes?: Resolver<ReadonlyArray<ResolversTypes['EmployeeScopeChangeUserManagementEmployerEvent']>, ParentType, ContextType>
  targetEmployee?: Resolver<ResolversTypes['EmployerEventSnapshot'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChatResolvers<ContextType = any, ParentType extends ResolversParentTypes['Chat'] = ResolversParentTypes['Chat']> = {
  contextName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  messages?: Resolver<Maybe<ResolversTypes['MessagesConnection']>, ParentType, ContextType, Partial<ChatMessagesArgs>>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  unreadMessagesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ChatMessageResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChatMessage'] = ResolversParentTypes['ChatMessage']> = {
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CheckPayerResolvers<ContextType = any, ParentType extends ResolversParentTypes['CheckPayer'] = ResolversParentTypes['CheckPayer']> = {
  company?: Resolver<ResolversTypes['CrmCompany'], ParentType, ContextType>
  payer?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CheckPayerResultOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CheckPayerResultOutput'] = ResolversParentTypes['CheckPayerResultOutput']> = {
  found?: Resolver<ReadonlyArray<ResolversTypes['CheckPayer']>, ParentType, ContextType>
  isValidCode?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CheckVacancyContentCorrectnessForPublishingOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CheckVacancyContentCorrectnessForPublishingOutput'] = ResolversParentTypes['CheckVacancyContentCorrectnessForPublishingOutput']
> = {
  isCorrect?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CitiesConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CitiesConnection'] = ResolversParentTypes['CitiesConnection']> = {
  edges?: Resolver<Maybe<ReadonlyArray<ResolversTypes['CityEdge']>>, ParentType, ContextType>
  nodes?: Resolver<Maybe<ReadonlyArray<ResolversTypes['City']>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CityResolvers<ContextType = any, ParentType extends ResolversParentTypes['City'] = ResolversParentTypes['City']> = {
  en?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  regionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ru?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  statistic?: Resolver<Maybe<ResolversTypes['StatisticData']>, ParentType, ContextType, RequireFields<CityStatisticArgs, 'input'>>
  ua?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CityDistrictResolvers<ContextType = any, ParentType extends ResolversParentTypes['CityDistrict'] = ResolversParentTypes['CityDistrict']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CityEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CityEdge'] = ResolversParentTypes['CityEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CloseBannerErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['CloseBannerError'] = ResolversParentTypes['CloseBannerError']> = {
  __resolveType: TypeResolveFn<'InvalidError' | 'NotFoundError' | 'ServerError' | 'UserIsNotAuthenticatedError', ParentType, ContextType>
}

export type CloseBannerResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CloseBannerResponse'] = ResolversParentTypes['CloseBannerResponse']> = {
  error?: Resolver<Maybe<ResolversTypes['CloseBannerError']>, ParentType, ContextType>
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClosedAdditionalServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClosedAdditionalService'] = ResolversParentTypes['ClosedAdditionalService']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  serviceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  spentAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['ServiceState']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClosedCvDbServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClosedCvDbService'] = ResolversParentTypes['ClosedCvDbService']> = {
  activatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  activator?: Resolver<Maybe<ResolversTypes['ServiceActivator']>, ParentType, ContextType>
  city?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  contactsAccrualPeriod?: Resolver<Maybe<ResolversTypes['ContactsAccrualPeriod']>, ParentType, ContextType>
  endedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  rubric?: Resolver<ResolversTypes['Rubric'], ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  serviceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['ServiceState']>, ParentType, ContextType>
  totalContacts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>
  usagePeriod?: Resolver<Maybe<ResolversTypes['ServiceUsagePeriod']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClosedHotServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClosedHotService'] = ResolversParentTypes['ClosedHotService']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  parentServiceId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  serviceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  spentAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['ServiceState']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClosedLogoInPortalServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClosedLogoInPortalService'] = ResolversParentTypes['ClosedLogoInPortalService']> = {
  branch?: Resolver<ResolversTypes['LogoPortalBranch'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  portal?: Resolver<ResolversTypes['LogoPortal'], ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  serviceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  spentAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['ServiceState']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClosedLogoInRubricServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClosedLogoInRubricService'] = ResolversParentTypes['ClosedLogoInRubricService']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  rubric?: Resolver<ResolversTypes['Rubric'], ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  serviceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  spentAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['ServiceState']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClosedMarketingServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClosedMarketingService'] = ResolversParentTypes['ClosedMarketingService']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  serviceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  spentAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['ServiceState']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClosedRisingUpServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClosedRisingUpService'] = ResolversParentTypes['ClosedRisingUpService']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  serviceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  spentAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['ServiceState']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClosedVacancyEmployerEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClosedVacancyEmployerEvent'] = ResolversParentTypes['ClosedVacancyEmployerEvent']> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  vacancyCity?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  vacancyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  vacancyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClosedVacancyPackageServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClosedVacancyPackageService'] = ResolversParentTypes['ClosedVacancyPackageService']> = {
  activatedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  activator?: Resolver<Maybe<ResolversTypes['ServiceActivator']>, ParentType, ContextType>
  additionalServices?: Resolver<ReadonlyArray<Maybe<ResolversTypes['ServiceUnited']>>, ParentType, ContextType>
  contactsAccrualPeriod?: Resolver<Maybe<ResolversTypes['ContactsAccrualPeriod']>, ParentType, ContextType>
  contactsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  endedAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isPackage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isUnlimitedContacts?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  publicationType?: Resolver<Maybe<ResolversTypes['VacancyPublicationType']>, ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  serviceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  spentCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['ServiceState']>, ParentType, ContextType>
  supportedRegions?: Resolver<ReadonlyArray<ResolversTypes['City']>, ParentType, ContextType>
  totalVacancyCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>
  usedContactCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  vacanciesPerPackage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  vacancyMailingCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  vacancyPublishDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  vacancyRisingCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClosedVacancyPublicationServiceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClosedVacancyPublicationService'] = ResolversParentTypes['ClosedVacancyPublicationService']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isPackage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  parentServiceId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  publicationType?: Resolver<Maybe<ResolversTypes['VacancyPublicationType']>, ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  serviceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  spentAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['ServiceState']>, ParentType, ContextType>
  supportedRegions?: Resolver<ReadonlyArray<ResolversTypes['City']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>
  vacancyMailingCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  vacancyPublishDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  vacancyRisingCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClubMutationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClubMutations'] = ResolversParentTypes['ClubMutations']> = {
  addPost?: Resolver<ResolversTypes['AddClubPostOutput'], ParentType, ContextType, RequireFields<ClubMutationsAddPostArgs, 'input'>>
  addPostToHot?: Resolver<ResolversTypes['ClubPost'], ParentType, ContextType, RequireFields<ClubMutationsAddPostToHotArgs, 'id'>>
  addPostToTop?: Resolver<ResolversTypes['ClubPost'], ParentType, ContextType, RequireFields<ClubMutationsAddPostToTopArgs, 'id'>>
  deletePostFromHot?: Resolver<ResolversTypes['DeletePostFromHotOutput'], ParentType, ContextType, RequireFields<ClubMutationsDeletePostFromHotArgs, 'id'>>
  deletePostFromTop?: Resolver<ResolversTypes['DeletePostFromTopOutput'], ParentType, ContextType, RequireFields<ClubMutationsDeletePostFromTopArgs, 'id'>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClubPostResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClubPost'] = ResolversParentTypes['ClubPost']> = {
  body?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  relatedPosts?: Resolver<Maybe<ResolversTypes['PublicClubPostsOutput']>, ParentType, ContextType, Partial<ClubPostRelatedPostsArgs>>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tags?: Resolver<ReadonlyArray<ResolversTypes['ClubTag']>, ParentType, ContextType>
  thumbnail?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  topics?: Resolver<ReadonlyArray<ResolversTypes['ClubTopic']>, ParentType, ContextType>
  userProfile?: Resolver<Maybe<ResolversTypes['ClubUserProfile']>, ParentType, ContextType>
  viewsCount?: Resolver<ResolversTypes['UInt'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClubQueriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClubQueries'] = ResolversParentTypes['ClubQueries']> = {
  post?: Resolver<Maybe<ResolversTypes['ClubPost']>, ParentType, ContextType, Partial<ClubQueriesPostArgs>>
  publicPosts?: Resolver<ResolversTypes['PublicClubPostsOutput'], ParentType, ContextType, Partial<ClubQueriesPublicPostsArgs>>
  tag?: Resolver<ResolversTypes['ClubTag'], ParentType, ContextType, Partial<ClubQueriesTagArgs>>
  tags?: Resolver<ReadonlyArray<ResolversTypes['ClubTag']>, ParentType, ContextType>
  topPost?: Resolver<ResolversTypes['ClubPost'], ParentType, ContextType>
  topic?: Resolver<ResolversTypes['ClubTopic'], ParentType, ContextType, Partial<ClubQueriesTopicArgs>>
  topicEnums?: Resolver<Maybe<ResolversTypes['PublicClubTopicEnameEnum']>, ParentType, ContextType>
  topics?: Resolver<ReadonlyArray<ResolversTypes['ClubTopic']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClubTagResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClubTag'] = ResolversParentTypes['ClubTag']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClubTopicResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClubTopic'] = ResolversParentTypes['ClubTopic']> = {
  banner?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  color?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  engname?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  logo?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  meta?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sideBarIcon?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClubUserProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClubUserProfile'] = ResolversParentTypes['ClubUserProfile']> = {
  avatarImageName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  position?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Company'] = ResolversParentTypes['Company']> = {
  addDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  adminEmployee?: Resolver<ResolversTypes['Employee'], ParentType, ContextType>
  blockingReasons?: Resolver<Maybe<ResolversTypes['CompanyBlockingReasons']>, ParentType, ContextType>
  branch?: Resolver<ResolversTypes['Branch'], ParentType, ContextType>
  brendProfileDateTo?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  city?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  companyState?: Resolver<Maybe<ResolversTypes['CompanyStateEnum']>, ParentType, ContextType>
  companyUrl?: Resolver<Maybe<ResolversTypes['Uri']>, ParentType, ContextType>
  contactPhone?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  edrpou?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  edrpouFile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  employees?: Resolver<Maybe<ResolversTypes['EmployeeConnection']>, ParentType, ContextType, Partial<CompanyEmployeesArgs>>
  hasAtsAccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasConfirmedContactEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasNonUkrainianVacancies?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasServicesLimitAccess?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  honors?: Resolver<ReadonlyArray<ResolversTypes['CompanyHonors']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isAllowedToPersonalizeAutoReplyApplyTemplate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isBrendProfile?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isConfirmByPhoneAndSite?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isDuplicate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isEdrpouApproved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isVerified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  jiraServiceDeskOrganisation?: Resolver<ResolversTypes['JiraServiceDeskOrganisation'], ParentType, ContextType>
  logoUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  manager?: Resolver<ResolversTypes['Manager'], ParentType, ContextType>
  miniProfile?: Resolver<ResolversTypes['CompanyMiniProfile'], ParentType, ContextType>
  myOrders?: Resolver<Maybe<ResolversTypes['OrderConnection']>, ParentType, ContextType, Partial<CompanyMyOrdersArgs>>
  myServices?: Resolver<Maybe<ResolversTypes['MyServicesConnection']>, ParentType, ContextType, Partial<CompanyMyServicesArgs>>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  onlyMainUserCanActivateService?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  popularProfessions?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['TagGroup']>>>, ParentType, ContextType>
  promoCodes?: Resolver<Maybe<ResolversTypes['PromoCodeConnection']>, ParentType, ContextType, Partial<CompanyPromoCodesArgs>>
  socialMedia?: Resolver<Maybe<ResolversTypes['CompanySocialMedia']>, ParentType, ContextType>
  summaryServices?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['SummaryService']>>>, ParentType, ContextType, Partial<CompanySummaryServicesArgs>>
  turbotaStatus?: Resolver<ResolversTypes['CompanyTurbotaStatus'], ParentType, ContextType>
  wallets?: Resolver<Maybe<ResolversTypes['WalletsConnection']>, ParentType, ContextType, Partial<CompanyWalletsArgs>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyApplyAutoReplyTemplateConfigurationUpdateOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyApplyAutoReplyTemplateConfigurationUpdateOutput'] = ResolversParentTypes['CompanyApplyAutoReplyTemplateConfigurationUpdateOutput']
> = {
  isAllowedToPersonalize?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isSuccessful?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyAssignmentOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyAssignmentOutput'] = ResolversParentTypes['CompanyAssignmentOutput']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyAssignmentsMutationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyAssignmentsMutations'] = ResolversParentTypes['CompanyAssignmentsMutations']> = {
  forbidAutoUnlink?: Resolver<ResolversTypes['CompanyAssignmentOutput'], ParentType, ContextType, RequireFields<CompanyAssignmentsMutationsForbidAutoUnlinkArgs, 'companyId' | 'state'>>
  refuse?: Resolver<ResolversTypes['CompanyAssignmentOutput'], ParentType, ContextType, RequireFields<CompanyAssignmentsMutationsRefuseArgs, 'input'>>
  want?: Resolver<ResolversTypes['CompanyAssignmentOutput'], ParentType, ContextType, RequireFields<CompanyAssignmentsMutationsWantArgs, 'input'>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyBenefitResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyBenefit'] = ResolversParentTypes['CompanyBenefit']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyBlockingReasonsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyBlockingReasons'] = ResolversParentTypes['CompanyBlockingReasons']> = {
  reasons?: Resolver<ReadonlyArray<ResolversTypes['CompanyReason']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyBufferEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyBufferEvent'] = ResolversParentTypes['CompanyBufferEvent']> = {
  addDate?: Resolver<ResolversTypes['DateTimeOffset'], ParentType, ContextType>
  assignment?: Resolver<Maybe<ResolversTypes['Assignment']>, ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['CrmCompany']>, ParentType, ContextType>
  daysLeft?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  employee?: Resolver<Maybe<ResolversTypes['CrmEmployee']>, ParentType, ContextType>
  eventType?: Resolver<ResolversTypes['EventTypeEnum'], ParentType, ContextType>
  expirationDate?: Resolver<Maybe<ResolversTypes['DateTimeOffset']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isArchieved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isRead?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  lifetimeDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  state?: Resolver<ResolversTypes['EventStateEnum'], ParentType, ContextType>
  topics?: Resolver<ReadonlyArray<ResolversTypes['Topic']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyCard'] = ResolversParentTypes['CompanyCard']> = {
  action?: Resolver<Maybe<ResolversTypes['CompanyCardAction']>, ParentType, ContextType>
  anonymousRemain?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  biSegmentName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  businessRemain?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  cityName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  finishedVacancyCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  hotRemain?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  hotVacancyCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  hotWorkVacancyCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  lastPayment?: Resolver<Maybe<ResolversTypes['CompanyCardPayment']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  optimumRemain?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  payments1Year?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>
  previousLoyalDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  problemsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  professionalRemain?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  registrationDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  ticketAwayCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  totalRemain?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  uniqueVacancyCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  uniqueVacancyCountDiff?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  vacancyCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  willBurnTicketCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  willFinishVacancyCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  workVacancyCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyCardActionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyCardAction'] = ResolversParentTypes['CompanyCardAction']> = {
  actionComment?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  actionCompleteDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  actionType?: Resolver<ResolversTypes['CrmActionTypeEnum'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyCardConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyCardConnection'] = ResolversParentTypes['CompanyCardConnection']> = {
  displayedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  edges?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CompanyCardEdge']>>>, ParentType, ContextType>
  items?: Resolver<Maybe<ReadonlyArray<ResolversTypes['CompanyCard']>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyCardEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyCardEdge'] = ResolversParentTypes['CompanyCardEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['CompanyCard'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyCardPaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyCardPayment'] = ResolversParentTypes['CompanyCardPayment']> = {
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  paySum?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>
  spentDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyCardTicketResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyCardTicket'] = ResolversParentTypes['CompanyCardTicket']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['CompanyCardServiceTypeEnum'], ParentType, ContextType>
  typeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyEditOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyEditOutput'] = ResolversParentTypes['CompanyEditOutput']> = {
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyEmailResendConfirmationOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyEmailResendConfirmationOutput'] = ResolversParentTypes['CompanyEmailResendConfirmationOutput']
> = {
  isSuccessful?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyEventOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyEventOutput'] = ResolversParentTypes['CompanyEventOutput']> = {
  accountNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  accountYear?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  dealNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  eventType?: Resolver<ResolversTypes['CompanyEventEnum'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  promocode?: Resolver<Maybe<ResolversTypes['PromoCode']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyFreeServiceAddedEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyFreeServiceAddedEvent'] = ResolversParentTypes['CompanyFreeServiceAddedEvent']> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  catalogService?: Resolver<ResolversTypes['CatalogService'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyHonorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyHonors'] = ResolversParentTypes['CompanyHonors']> = {
  badge?: Resolver<Maybe<ResolversTypes['CompanyHonorsBadge']>, ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  detailsUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  logoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyHonorsBadgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyHonorsBadge'] = ResolversParentTypes['CompanyHonorsBadge']> = {
  iconUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isFavorite?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  locations?: Resolver<ReadonlyArray<ResolversTypes['CompanyHonorsBadgeLocationEnum']>, ParentType, ContextType>
  tooltipDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyJoinRequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyJoinRequest'] = ResolversParentTypes['CompanyJoinRequest']> = {
  company?: Resolver<ResolversTypes['Company'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['CompanyJoinRequestStatusEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyMiniProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyMiniProfile'] = ResolversParentTypes['CompanyMiniProfile']> = {
  benefits?: Resolver<ReadonlyArray<ResolversTypes['CompanyBenefit']>, ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  images?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>
  isEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  staffSize?: Resolver<ResolversTypes['CompanyStaffSize'], ParentType, ContextType>
  years?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyNotificationSnapshotResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyNotificationSnapshot'] = ResolversParentTypes['CompanyNotificationSnapshot']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  state?: Resolver<ResolversTypes['CompanyStateEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyPublishedVacanciesFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyPublishedVacanciesFilter'] = ResolversParentTypes['CompanyPublishedVacanciesFilter']
> = {
  cityId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  rubricId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyPublishedVacanciesQueryFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyPublishedVacanciesQueryFilter'] = ResolversParentTypes['CompanyPublishedVacanciesQueryFilter']
> = {
  filter?: Resolver<ResolversTypes['CompanyPublishedVacanciesFilter'], ParentType, ContextType>
  page?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyReasonResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyReason'] = ResolversParentTypes['CompanyReason']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyRegisteredEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyRegisteredEvent'] = ResolversParentTypes['CompanyRegisteredEvent']> = {
  addDate?: Resolver<ResolversTypes['DateTimeOffset'], ParentType, ContextType>
  assignment?: Resolver<Maybe<ResolversTypes['Assignment']>, ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['CrmCompany']>, ParentType, ContextType>
  daysLeft?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  employee?: Resolver<Maybe<ResolversTypes['CrmEmployee']>, ParentType, ContextType>
  eventType?: Resolver<ResolversTypes['EventTypeEnum'], ParentType, ContextType>
  expirationDate?: Resolver<Maybe<ResolversTypes['DateTimeOffset']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isArchieved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isRead?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  lifetimeDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  state?: Resolver<ResolversTypes['EventStateEnum'], ParentType, ContextType>
  topics?: Resolver<ReadonlyArray<ResolversTypes['Topic']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanySavedCardAddedEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanySavedCardAddedEvent'] = ResolversParentTypes['CompanySavedCardAddedEvent']> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  savedCard?: Resolver<ResolversTypes['SavedCard'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanySavedCardRemovedEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanySavedCardRemovedEvent'] = ResolversParentTypes['CompanySavedCardRemovedEvent']> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  savedCard?: Resolver<ResolversTypes['SavedCard'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanySendJoinRequestOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanySendJoinRequestOutput'] = ResolversParentTypes['CompanySendJoinRequestOutput']> = {
  errors?: Resolver<ReadonlyArray<ResolversTypes['CompanySendJoinRequestErrorEnum']>, ParentType, ContextType>
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanySocialMediaResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanySocialMedia'] = ResolversParentTypes['CompanySocialMedia']> = {
  facebookUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  instagramUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  linkedInUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyStaffSizeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyStaffSize'] = ResolversParentTypes['CompanyStaffSize']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyStateChangedDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyStateChangedDetails'] = ResolversParentTypes['CompanyStateChangedDetails']> = {
  companySnapshot?: Resolver<ResolversTypes['CompanyNotificationSnapshot'], ParentType, ContextType>
  detailType?: Resolver<ResolversTypes['SeekerNotificationDetailTypeEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyStateWrapperResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyStateWrapper'] = ResolversParentTypes['CompanyStateWrapper']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  state?: Resolver<ResolversTypes['CompanyStateEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyTurbotaStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyTurbotaStatus'] = ResolversParentTypes['CompanyTurbotaStatus']> = {
  activatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  endsAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  hasTurbotaAccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isActiveNow?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  order?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyVerificationOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyVerificationOutput'] = ResolversParentTypes['CompanyVerificationOutput']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  isSuccessful?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyWasMergedErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyWasMergedError'] = ResolversParentTypes['CompanyWasMergedError']> = {
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyWorkRegisteredEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyWorkRegisteredEvent'] = ResolversParentTypes['CompanyWorkRegisteredEvent']> = {
  addDate?: Resolver<ResolversTypes['DateTimeOffset'], ParentType, ContextType>
  assignment?: Resolver<Maybe<ResolversTypes['Assignment']>, ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['CrmCompany']>, ParentType, ContextType>
  daysLeft?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  employee?: Resolver<Maybe<ResolversTypes['CrmEmployee']>, ParentType, ContextType>
  eventType?: Resolver<ResolversTypes['EventTypeEnum'], ParentType, ContextType>
  expirationDate?: Resolver<Maybe<ResolversTypes['DateTimeOffset']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isArchieved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isRead?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  lifetimeDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  state?: Resolver<ResolversTypes['EventStateEnum'], ParentType, ContextType>
  topics?: Resolver<ReadonlyArray<ResolversTypes['Topic']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ConfirmEmailCodeOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConfirmEmailCodeOutput'] = ResolversParentTypes['ConfirmEmailCodeOutput']> = {
  status?: Resolver<ResolversTypes['ConfirmEmailCodeStatusEnum'], ParentType, ContextType>
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ConfirmProfileDeletionOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConfirmProfileDeletionOutput'] = ResolversParentTypes['ConfirmProfileDeletionOutput']> = {
  status?: Resolver<ResolversTypes['DeleteProfileStatus'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ConfirmedPhoneUsersOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConfirmedPhoneUsersOutput'] = ResolversParentTypes['ConfirmedPhoneUsersOutput']> = {
  phoneConfirmationResult?: Resolver<ResolversTypes['PhoneConfirmationOutput'], ParentType, ContextType>
  userAccounts?: Resolver<ReadonlyArray<ResolversTypes['UserAccountLogin']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ContractResolvers<ContextType = any, ParentType extends ResolversParentTypes['Contract'] = ResolversParentTypes['Contract']> = {
  additionals?: Resolver<ReadonlyArray<ResolversTypes['Contract']>, ParentType, ContextType>
  endDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isAllowDelete?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isClientApproved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isCreatedByJurist?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isSigned?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  startDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ContractCreationOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractCreationOutput'] = ResolversParentTypes['ContractCreationOutput']> = {
  errorMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CountStatisticsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CountStatistics'] = ResolversParentTypes['CountStatistics']> = {
  futurePeriodsCounts?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['PeriodAmount']>>>, ParentType, ContextType>
  lastYearCounts?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['PeriodAmount']>>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateEmptyProfResumeErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateEmptyProfResumeError'] = ResolversParentTypes['CreateEmptyProfResumeError']> = {
  __resolveType: TypeResolveFn<'ProfResumeLimit', ParentType, ContextType>
}

export type CreateGroupNotificationOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateGroupNotificationOutput'] = ResolversParentTypes['CreateGroupNotificationOutput']
> = {
  isSuccessful?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateInvoiceOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateInvoiceOutput'] = ResolversParentTypes['CreateInvoiceOutput']> = {
  invoice?: Resolver<Maybe<ResolversTypes['Invoice']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateOrderFromCartResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateOrderFromCartResponse'] = ResolversParentTypes['CreateOrderFromCartResponse']> = {
  errors?: Resolver<ReadonlyArray<ResolversTypes['CreateOrderFromCartErrorEnum']>, ParentType, ContextType>
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  order?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateOrderFromServicesOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateOrderFromServicesOutput'] = ResolversParentTypes['CreateOrderFromServicesOutput']
> = {
  order?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateProfResumeAsCopyErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateProfResumeAsCopyError'] = ResolversParentTypes['CreateProfResumeAsCopyError']> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist' | 'ProfResumeLimit', ParentType, ContextType>
}

export type CreateProfResumeErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateProfResumeError'] = ResolversParentTypes['CreateProfResumeError']> = {
  __resolveType: TypeResolveFn<'ProfResumeDuplicated' | 'ProfResumeLimit', ParentType, ContextType>
}

export type CreateProfResumeFromAttachErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateProfResumeFromAttachError'] = ResolversParentTypes['CreateProfResumeFromAttachError']
> = {
  __resolveType: TypeResolveFn<'AttachNotFound' | 'AttachParsingError' | 'ProfResumeDuplicated' | 'ProfResumeLimit', ParentType, ContextType>
}

export type CreateProfResumeFromAttachOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateProfResumeFromAttachOutput'] = ResolversParentTypes['CreateProfResumeFromAttachOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['CreateProfResumeFromAttachError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateRecruitmentOrderErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateRecruitmentOrderError'] = ResolversParentTypes['CreateRecruitmentOrderError']> = {
  __resolveType: TypeResolveFn<'InvalidError' | 'NotFoundError', ParentType, ContextType>
}

export type CreateRecruitmentOrderOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateRecruitmentOrderOutput'] = ResolversParentTypes['CreateRecruitmentOrderOutput']> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['CreateRecruitmentOrderError']>>, ParentType, ContextType>
  recruitmentOrderOutput?: Resolver<Maybe<ResolversTypes['RecruitmentOrderOutput']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateResumeDraftCreatedNotificationOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateResumeDraftCreatedNotificationOutput'] = ResolversParentTypes['CreateResumeDraftCreatedNotificationOutput']
> = {
  notification?: Resolver<Maybe<ResolversTypes['Notification']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateResumePublishedNotificationOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateResumePublishedNotificationOutput'] = ResolversParentTypes['CreateResumePublishedNotificationOutput']
> = {
  notification?: Resolver<Maybe<ResolversTypes['Notification']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateSeekerProfileResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateSeekerProfileResponse'] = ResolversParentTypes['CreateSeekerProfileResponse']> = {
  authLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  errors?: Resolver<ReadonlyArray<ResolversTypes['UserRegistrationError']>, ParentType, ContextType>
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  oneTimeLoginToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateSharingDocumentsDeepLinkOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateSharingDocumentsDeepLinkOutput'] = ResolversParentTypes['CreateSharingDocumentsDeepLinkOutput']
> = {
  deepLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateTargetedMailingOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateTargetedMailingOutput'] = ResolversParentTypes['CreateTargetedMailingOutput']> = {
  error?: Resolver<Maybe<ResolversTypes['TargetedMailingSaveErrorUnion']>, ParentType, ContextType>
  mailing?: Resolver<Maybe<ResolversTypes['TargetedMailing']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateUserNotificationOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateUserNotificationOutput'] = ResolversParentTypes['CreateUserNotificationOutput']> = {
  isSuccessful?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateWalletErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateWalletError'] = ResolversParentTypes['CreateWalletError']> = {
  __resolveType: TypeResolveFn<'UserIsNotAuthenticatedError' | 'WalletExistsError', ParentType, ContextType>
}

export type CreateWalletOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateWalletOutput'] = ResolversParentTypes['CreateWalletOutput']> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['CreateWalletError']>>, ParentType, ContextType>
  wallet?: Resolver<Maybe<ResolversTypes['Wallet']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateWalletTransactionErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateWalletTransactionError'] = ResolversParentTypes['CreateWalletTransactionError']> = {
  __resolveType: TypeResolveFn<'InvalidError' | 'TransactionExistsError' | 'UserIsNotAuthenticatedError' | 'WalletDoesNotExistError', ParentType, ContextType>
}

export type CreateWalletTransactionOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateWalletTransactionOutput'] = ResolversParentTypes['CreateWalletTransactionOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['CreateWalletTransactionError']>>, ParentType, ContextType>
  walletTransaction?: Resolver<Maybe<ResolversTypes['WalletTransaction']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreatedAsCopyProfResumeOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreatedAsCopyProfResumeOutput'] = ResolversParentTypes['CreatedAsCopyProfResumeOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['CreateProfResumeAsCopyError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreatedDraftVacancyEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreatedDraftVacancyEmployerEvent'] = ResolversParentTypes['CreatedDraftVacancyEmployerEvent']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  vacancyCity?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  vacancyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  vacancyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreatedEmptyProfResumeOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreatedEmptyProfResumeOutput'] = ResolversParentTypes['CreatedEmptyProfResumeOutput']> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['CreateEmptyProfResumeError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreatedOrderServiceEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreatedOrderServiceEmployerEvent'] = ResolversParentTypes['CreatedOrderServiceEmployerEvent']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  order?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreatedProfResumeOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreatedProfResumeOutput'] = ResolversParentTypes['CreatedProfResumeOutput']> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['CreateProfResumeError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmAccountResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmAccount'] = ResolversParentTypes['CrmAccount']> = {
  acts?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CrmCompletionAct']>>>, ParentType, ContextType>
  dealNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  orderId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  payDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  payTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['CrmAccountState']>, ParentType, ContextType>
  stateName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  total?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  year?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmAccountConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmAccountConnection'] = ResolversParentTypes['CrmAccountConnection']> = {
  edges?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CrmAccountEdge']>>>, ParentType, ContextType>
  items?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CrmAccount']>>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmAccountEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmAccountEdge'] = ResolversParentTypes['CrmAccountEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<Maybe<ResolversTypes['CrmAccount']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmActionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmAction'] = ResolversParentTypes['CrmAction']> = {
  actionComment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  assignedManagerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  assignedName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  dealId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  executionDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  filesAttachedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  isAssignedByStm?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isGamificationParticipant?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isWholeDay?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  metaNotebook?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  notebookId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  responsibleName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  stateId?: Resolver<Maybe<ResolversTypes['crmActionState']>, ParentType, ContextType>
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  typeId?: Resolver<Maybe<ResolversTypes['CrmActionTypeEnum']>, ParentType, ContextType>
  userCard?: Resolver<Maybe<ResolversTypes['CrmUserCard']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmActionAddResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmActionAddResult'] = ResolversParentTypes['CrmActionAddResult']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  ids?: Resolver<ReadonlyArray<ResolversTypes['ID']>, ParentType, ContextType>
  messageText?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  userCardId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmActionBriefResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmActionBrief'] = ResolversParentTypes['CrmActionBrief']> = {
  actionComment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  executionDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  stateId?: Resolver<Maybe<ResolversTypes['crmActionState']>, ParentType, ContextType>
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  typeId?: Resolver<Maybe<ResolversTypes['CrmActionTypeEnum']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmActionConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmActionConnection'] = ResolversParentTypes['CrmActionConnection']> = {
  edges?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CrmActionEdge']>>>, ParentType, ContextType>
  items?: Resolver<Maybe<ReadonlyArray<ResolversTypes['CrmAction']>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmActionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmActionEdge'] = ResolversParentTypes['CrmActionEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['CrmAction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmActionTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmActionType'] = ResolversParentTypes['CrmActionType']> = {
  id?: Resolver<Maybe<ResolversTypes['CrmActionTypeEnum']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  nameUkr?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmCallResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmCall'] = ResolversParentTypes['CrmCall']> = {
  actions?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CrmAction']>>>, ParentType, ContextType>
  answeredAt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  billSec?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  callOrder?: Resolver<Maybe<ResolversTypes['CrmCallOrder']>, ParentType, ContextType>
  callType?: Resolver<Maybe<ResolversTypes['CrmCallType']>, ParentType, ContextType>
  clientEmployeeId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  clientNotebookId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  clientPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  dayCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  disposition?: Resolver<Maybe<ResolversTypes['CrmCallDisposition']>, ParentType, ContextType>
  employee?: Resolver<Maybe<ResolversTypes['CrmUserCard']>, ParentType, ContextType>
  generalCallId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  history?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CrmCall']>>>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  manager?: Resolver<Maybe<ResolversTypes['CrmManager']>, ParentType, ContextType>
  managerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  managerInternalPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  managerPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  startDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  startTime?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  stopTime?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  transferManager?: Resolver<Maybe<ResolversTypes['CrmManager']>, ParentType, ContextType>
  transferManagerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  transferPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  waitSec?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmCallConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmCallConnection'] = ResolversParentTypes['CrmCallConnection']> = {
  edges?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CrmCallEdge']>>>, ParentType, ContextType>
  items?: Resolver<Maybe<ReadonlyArray<ResolversTypes['CrmCall']>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmCallEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmCallEdge'] = ResolversParentTypes['CrmCallEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['CrmCall'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmCompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmCompany'] = ResolversParentTypes['CrmCompany']> = {
  addDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  biSegmentName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  branchName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  cityId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  cityName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  comissionPercent?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>
  contactURL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  contracts?: Resolver<ReadonlyArray<ResolversTypes['Contract']>, ParentType, ContextType>
  edrpou?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  edrpouFile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  employeeCount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  employees?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CrmEmployee']>>>, ParentType, ContextType>
  exactEmployeeCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  hasEmailConfirmed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasPhoneConfirmed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  isForbiddenAutoUnlink?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isLegalPerson?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isPremium?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  logo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  manager?: Resolver<Maybe<ResolversTypes['CrmManager']>, ParentType, ContextType>
  managerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  notebookId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  payer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  payers?: Resolver<ReadonlyArray<ResolversTypes['Payer']>, ParentType, ContextType>
  publications?: Resolver<ResolversTypes['VacancyCounterGraphType'], ParentType, ContextType>
  rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  scaleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  segmentCategory?: Resolver<Maybe<ResolversTypes['CompanySegmentEnum']>, ParentType, ContextType>
  segmentationMechanics?: Resolver<Maybe<ResolversTypes['SegmentationMechanicsEnum']>, ParentType, ContextType>
  shortDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  state?: Resolver<ResolversTypes['CompanyStateEnum'], ParentType, ContextType>
  stateId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  wallets?: Resolver<Maybe<ResolversTypes['WalletsConnection']>, ParentType, ContextType, Partial<CrmCompanyWalletsArgs>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmCompanyConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmCompanyConnection'] = ResolversParentTypes['CrmCompanyConnection']> = {
  edges?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CrmCompanyEdge']>>>, ParentType, ContextType>
  items?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CrmCompany']>>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmCompanyEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmCompanyEdge'] = ResolversParentTypes['CrmCompanyEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<Maybe<ResolversTypes['CrmCompany']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmCompletionActResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmCompletionAct'] = ResolversParentTypes['CrmCompletionAct']> = {
  number1C?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  sendTypeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  sentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmContactResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmContact'] = ResolversParentTypes['CrmContact']> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmEditActionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmEditAction'] = ResolversParentTypes['CrmEditAction']> = {
  actionComment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  addDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  assigned?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  assignedName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  completeDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  dashboardEndDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  dashboardStartDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  dealId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  executionDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  executionDisplayDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  giftCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  isByPersonalCar?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isDashboardLinkActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isInaccurateDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isNotInterested?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isPinned?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isWholeDay?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  loyaltyId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  meetingReasonName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  metaNotebookId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  multiUserId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  multiUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  notebookId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  parentAlarmId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  parentId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  priority?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  responsible?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  responsibleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  stateId?: Resolver<Maybe<ResolversTypes['crmActionState']>, ParentType, ContextType>
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  typeId?: Resolver<Maybe<ResolversTypes['CrmActionTypeEnum']>, ParentType, ContextType>
  userCardId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmEmployeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmEmployee'] = ResolversParentTypes['CrmEmployee']> = {
  fullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  multiUserId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  surname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmManagerResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmManager'] = ResolversParentTypes['CrmManager']> = {
  departmentId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  fullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  internalPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  mobilePhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  nameRu?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  nameUa?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  parentManagerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  role?: Resolver<Maybe<ResolversTypes['CrmManagerRole']>, ParentType, ContextType>
  smId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  stmId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmMarketingServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmMarketingService'] = ResolversParentTypes['CrmMarketingService']> = {
  bundle?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  clientPriceSumWithVAT?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  firstPaymentDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  managerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  managerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  notebookId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  orderDetailId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  orderDetailState?: Resolver<Maybe<ResolversTypes['CrmPositionState']>, ParentType, ContextType>
  orderId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  orderPayStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  orderPayStatusId?: Resolver<Maybe<ResolversTypes['CrmPayState']>, ParentType, ContextType>
  orderType?: Resolver<Maybe<ResolversTypes['CrmOrderType']>, ParentType, ContextType>
  serviceDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  serviceId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  serviceName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmMarketingServiceConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CrmMarketingServiceConnection'] = ResolversParentTypes['CrmMarketingServiceConnection']
> = {
  edges?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CrmMarketingServiceEdge']>>>, ParentType, ContextType>
  items?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CrmMarketingService']>>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmMarketingServiceEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmMarketingServiceEdge'] = ResolversParentTypes['CrmMarketingServiceEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<Maybe<ResolversTypes['CrmMarketingService']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmMarketingServiceFilterResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmMarketingServiceFilter'] = ResolversParentTypes['CrmMarketingServiceFilter']> = {
  filter?: Resolver<Maybe<ResolversTypes['CrmMarketingServiceFilterType']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmMeetingReasonResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmMeetingReason'] = ResolversParentTypes['CrmMeetingReason']> = {
  id?: Resolver<Maybe<ResolversTypes['CrmActionTypeEnum']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  nameUkr?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmMetaCompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmMetaCompany'] = ResolversParentTypes['CrmMetaCompany']> = {
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmMultiUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmMultiUser'] = ResolversParentTypes['CrmMultiUser']> = {
  activeVacancyCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  addDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  fax?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  internalPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  isApproved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  lastVisit?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  loginLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  mobilePhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  publishedVacancyCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  region?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  rights?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  roleId?: Resolver<Maybe<ResolversTypes['CrmMultiUserRole']>, ParentType, ContextType>
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>
  userCard?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  viewedResumeCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmPhoneResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmPhone'] = ResolversParentTypes['CrmPhone']> = {
  isUserPhone?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  lastCall?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  phoneId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmPlannedActionByDayResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmPlannedActionByDay'] = ResolversParentTypes['CrmPlannedActionByDay']> = {
  actionsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  dayOfMonth?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  dayOfWeek?: Resolver<Maybe<ResolversTypes['CrmDayOfWeek']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmQuarterResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmQuarter'] = ResolversParentTypes['CrmQuarter']> = {
  number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  year?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmUserCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmUserCard'] = ResolversParentTypes['CrmUserCard']> = {
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['CrmCompany']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  isAdmin?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isElected?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isOwn?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isUserCard?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  messengers?: Resolver<ReadonlyArray<ResolversTypes['MessengerContact']>, ParentType, ContextType>
  multiUserId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  phones?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CrmPhone']>>>, ParentType, ContextType>
  postName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  stateId?: Resolver<Maybe<ResolversTypes['CrmUserCardState']>, ParentType, ContextType>
  userEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  userEmails?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmUserCardEditResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmUserCardEdit'] = ResolversParentTypes['CrmUserCardEdit']> = {
  addDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  addressCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  addressHouseNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  addressStreet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  birthday?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  emails?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  isElected?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  messengers?: Resolver<ReadonlyArray<ResolversTypes['MessengerContact']>, ParentType, ContextType>
  multiUserId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  phones?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CrmPhone']>>>, ParentType, ContextType>
  postName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  roleId?: Resolver<Maybe<ResolversTypes['CrmUserCardRoleEnum']>, ParentType, ContextType>
  stateId?: Resolver<Maybe<ResolversTypes['CrmUserCardState']>, ParentType, ContextType>
  surname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CrmUserCardRoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmUserCardRole'] = ResolversParentTypes['CrmUserCardRole']> = {
  id?: Resolver<Maybe<ResolversTypes['CrmUserCardRoleEnum']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  nameUkr?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomNotificationDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomNotificationDetails'] = ResolversParentTypes['CustomNotificationDetails']> = {
  detailType?: Resolver<ResolversTypes['SeekerNotificationDetailTypeEnum'], ParentType, ContextType>
  elements?: Resolver<ReadonlyArray<ResolversTypes['CustomNotificationElement']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomNotificationElementResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomNotificationElement'] = ResolversParentTypes['CustomNotificationElement']> = {
  buttons?: Resolver<ReadonlyArray<ResolversTypes['CustomNotificationElementButton']>, ParentType, ContextType>
  iconUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  text?: Resolver<ReadonlyArray<ResolversTypes['CustomNotificationElementText']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomNotificationElementButtonResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomNotificationElementButton'] = ResolversParentTypes['CustomNotificationElementButton']
> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['CustomNotificationButtonTypeEnum'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomNotificationElementTextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomNotificationElementText'] = ResolversParentTypes['CustomNotificationElementText']
> = {
  isBold?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CvDbCatalogServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['CvDbCatalogService'] = ResolversParentTypes['CvDbCatalogService']> = {
  calculatePrice?: Resolver<ResolversTypes['ServicePrice'], ParentType, ContextType, Partial<CvDbCatalogServiceCalculatePriceArgs>>
  city?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  contactsAccrualPeriod?: Resolver<Maybe<ResolversTypes['ContactsAccrualPeriod']>, ParentType, ContextType>
  groupWrapper?: Resolver<ResolversTypes['ServiceGroupWrapper'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  rubric?: Resolver<ResolversTypes['Rubric'], ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  totalContacts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  typeWrapper?: Resolver<ResolversTypes['ServiceTypeWrapper'], ParentType, ContextType>
  usagePeriod?: Resolver<ResolversTypes['UsagePeriodTypeWrapper'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CvDbServiceEmployerEventSnapshotResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CvDbServiceEmployerEventSnapshot'] = ResolversParentTypes['CvDbServiceEmployerEventSnapshot']
> = {
  activeTo?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  catalogService?: Resolver<ResolversTypes['CatalogService'], ParentType, ContextType>
  city?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  period?: Resolver<Maybe<ResolversTypes['CvDbServiceUsagePeriodEmployerEventEnum']>, ParentType, ContextType>
  rubric?: Resolver<ResolversTypes['Rubric'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DailyResumeViewsReportCalculatedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DailyResumeViewsReportCalculated'] = ResolversParentTypes['DailyResumeViewsReportCalculated']
> = {
  companiesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  detailType?: Resolver<ResolversTypes['SeekerNotificationDetailTypeEnum'], ParentType, ContextType>
  resume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  viewsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DashboardCompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['DashboardCompany'] = ResolversParentTypes['DashboardCompany']> = {
  cityId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  logo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  managerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  notebookId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  stateId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DashboardCompanyConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DashboardCompanyConnection'] = ResolversParentTypes['DashboardCompanyConnection']> = {
  edges?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['DashboardCompanyEdge']>>>, ParentType, ContextType>
  items?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['DashboardCompany']>>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DashboardCompanyEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DashboardCompanyEdge'] = ResolversParentTypes['DashboardCompanyEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<Maybe<ResolversTypes['DashboardCompany']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DashboardMultiUserResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DashboardMultiUserResponse'] = ResolversParentTypes['DashboardMultiUserResponse']> = {
  chartData?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Int']>>>, ParentType, ContextType>
  currentValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  diffAbs?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  diffPerc?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  isStared?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  multiUserId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  multiUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  region?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  section?: Resolver<Maybe<ResolversTypes['DashboardMultiUserSection']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DashboardRatingResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DashboardRatingResponse'] = ResolversParentTypes['DashboardRatingResponse']> = {
  currentRank?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  currentValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  diffAbs?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  diffPerc?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  multiUserId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  multiUserName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  rating?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  region?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  section?: Resolver<Maybe<ResolversTypes['DashboardRatingSection']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DashboardResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DashboardResponse'] = ResolversParentTypes['DashboardResponse']> = {
  chartData?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Int']>>>, ParentType, ContextType>
  percents?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  points?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  section?: Resolver<Maybe<ResolversTypes['DashboardSection']>, ParentType, ContextType>
  value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date'
}

export type DateCounterResolvers<ContextType = any, ParentType extends ResolversParentTypes['DateCounter'] = ResolversParentTypes['DateCounter']> = {
  count?: Resolver<ResolversTypes['Long'], ParentType, ContextType>
  date?: Resolver<ResolversTypes['DateTimeOffset'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DateIntervalResolvers<ContextType = any, ParentType extends ResolversParentTypes['DateInterval'] = ResolversParentTypes['DateInterval']> = {
  begin?: Resolver<Maybe<ResolversTypes['DateTimeOffset']>, ParentType, ContextType>
  end?: Resolver<Maybe<ResolversTypes['DateTimeOffset']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime'
}

export interface DateTimeOffsetScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTimeOffset'], any> {
  name: 'DateTimeOffset'
}

export type DealCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['DealCard'] = ResolversParentTypes['DealCard']> = {
  accounts?: Resolver<ReadonlyArray<ResolversTypes['DealCardAccount']>, ParentType, ContextType>
  bonusDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  bonusSum?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>
  closePossibility?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  column?: Resolver<ResolversTypes['DealTableFolderEnum'], ParentType, ContextType>
  company?: Resolver<ResolversTypes['CrmCompany'], ParentType, ContextType>
  dealState?: Resolver<ResolversTypes['DealStateEnum'], ParentType, ContextType>
  dealStateName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isArchive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isEcommerce?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isTurbota?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  kanbanCardDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  orderClientPriceSumVat?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>
  orderDebt?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>
  orderNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  orderPaymentSum?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>
  orderRecognizedRevenue?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DealCardAccountResolvers<ContextType = any, ParentType extends ResolversParentTypes['DealCardAccount'] = ResolversParentTypes['DealCardAccount']> = {
  accYear?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  accountDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  addDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  dateChanges?: Resolver<ReadonlyArray<ResolversTypes['AccountDateChanges']>, ParentType, ContextType>
  dateFirst?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  payment?: Resolver<Maybe<ResolversTypes['DealCardPayment']>, ParentType, ContextType>
  possibility?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  sumVat?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DealCardPaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['DealCardPayment'] = ResolversParentTypes['DealCardPayment']> = {
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  sumVat?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DealDetailsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DealDetailsOutput'] = ResolversParentTypes['DealDetailsOutput']> = {
  accounts?: Resolver<ReadonlyArray<ResolversTypes['DealCardAccount']>, ParentType, ContextType>
  company?: Resolver<ResolversTypes['CrmCompany'], ParentType, ContextType>
  dealAddDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isEcommerce?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isTurbota?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  order?: Resolver<ResolversTypes['DealOrderCard'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DealForecastResolvers<ContextType = any, ParentType extends ResolversParentTypes['DealForecast'] = ResolversParentTypes['DealForecast']> = {
  column?: Resolver<ResolversTypes['DealTableFolderEnum'], ParentType, ContextType>
  expectedIncome?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>
  maxPossibility?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  minPossibility?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DealOrderCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['DealOrderCard'] = ResolversParentTypes['DealOrderCard']> = {
  clientPriceSumVat?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>
  debt?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  paymentSum?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>
  recognizedRevenue?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DealTableOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DealTableOutput'] = ResolversParentTypes['DealTableOutput']> = {
  deals?: Resolver<ReadonlyArray<ResolversTypes['DealCard']>, ParentType, ContextType>
  forecasts?: Resolver<ReadonlyArray<ResolversTypes['DealForecast']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface DecimalScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Decimal'], any> {
  name: 'Decimal'
}

export type DeleteAllNotificationsErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteAllNotificationsError'] = ResolversParentTypes['DeleteAllNotificationsError']> = {
  __resolveType: TypeResolveFn<'NotificationNotFoundError' | 'UnknownServerError', ParentType, ContextType>
}

export type DeleteAllNotificationsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteAllNotificationsOutput'] = ResolversParentTypes['DeleteAllNotificationsOutput']> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['DeleteAllNotificationsError']>>, ParentType, ContextType>
  notifications?: Resolver<Maybe<ReadonlyArray<ResolversTypes['Notification']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeleteNotificationsErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteNotificationsError'] = ResolversParentTypes['DeleteNotificationsError']> = {
  __resolveType: TypeResolveFn<'NotificationNotFoundError' | 'UnknownServerError', ParentType, ContextType>
}

export type DeleteNotificationsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteNotificationsOutput'] = ResolversParentTypes['DeleteNotificationsOutput']> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['DeleteNotificationsError']>>, ParentType, ContextType>
  notifications?: Resolver<Maybe<ReadonlyArray<ResolversTypes['Notification']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeletePostFromHotOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeletePostFromHotOutput'] = ResolversParentTypes['DeletePostFromHotOutput']> = {
  post?: Resolver<Maybe<ResolversTypes['ClubPost']>, ParentType, ContextType>
  postId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeletePostFromTopOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeletePostFromTopOutput'] = ResolversParentTypes['DeletePostFromTopOutput']> = {
  post?: Resolver<Maybe<ResolversTypes['ClubPost']>, ParentType, ContextType>
  postId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeleteProfResumePortfolioOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteProfResumePortfolioOutput'] = ResolversParentTypes['DeleteProfResumePortfolioOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['DeleteSeekerProfResumePortfoliosError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeleteProfResumeSocialNetworksOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteProfResumeSocialNetworksOutput'] = ResolversParentTypes['DeleteProfResumeSocialNetworksOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['DeleteSeekerProfResumeSocialNetworksError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeleteProfileOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteProfileOutput'] = ResolversParentTypes['DeleteProfileOutput']> = {
  __resolveType: TypeResolveFn<'DeleteProfileWithEmailOutput' | 'DeleteProfileWithSmsOutput', ParentType, ContextType>
  status?: Resolver<ResolversTypes['DeleteProfileStatus'], ParentType, ContextType>
}

export type DeleteProfileWithEmailOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteProfileWithEmailOutput'] = ResolversParentTypes['DeleteProfileWithEmailOutput']> = {
  sendEmailStatus?: Resolver<ResolversTypes['SendEmailOutput'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['DeleteProfileStatus'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeleteProfileWithSmsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteProfileWithSmsOutput'] = ResolversParentTypes['DeleteProfileWithSmsOutput']> = {
  sendSmsStatus?: Resolver<ResolversTypes['SendSmsOutput'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['DeleteProfileStatus'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeleteSeekerProfResumeAdditionalEducationsErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteSeekerProfResumeAdditionalEducationsError'] = ResolversParentTypes['DeleteSeekerProfResumeAdditionalEducationsError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type DeleteSeekerProfResumeAdditionalInfoErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteSeekerProfResumeAdditionalInfoError'] = ResolversParentTypes['DeleteSeekerProfResumeAdditionalInfoError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type DeleteSeekerProfResumeAdditionalPhonesErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteSeekerProfResumeAdditionalPhonesError'] = ResolversParentTypes['DeleteSeekerProfResumeAdditionalPhonesError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type DeleteSeekerProfResumeAdditionalPhonesOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteSeekerProfResumeAdditionalPhonesOutput'] = ResolversParentTypes['DeleteSeekerProfResumeAdditionalPhonesOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['DeleteSeekerProfResumeAdditionalPhonesError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeleteSeekerProfResumeDiiaCertificateErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteSeekerProfResumeDiiaCertificateError'] = ResolversParentTypes['DeleteSeekerProfResumeDiiaCertificateError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type DeleteSeekerProfResumeEducationsErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteSeekerProfResumeEducationsError'] = ResolversParentTypes['DeleteSeekerProfResumeEducationsError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type DeleteSeekerProfResumeErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteSeekerProfResumeError'] = ResolversParentTypes['DeleteSeekerProfResumeError']> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type DeleteSeekerProfResumeExperiencesErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteSeekerProfResumeExperiencesError'] = ResolversParentTypes['DeleteSeekerProfResumeExperiencesError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type DeleteSeekerProfResumeLanguageSkillsErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteSeekerProfResumeLanguageSkillsError'] = ResolversParentTypes['DeleteSeekerProfResumeLanguageSkillsError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type DeleteSeekerProfResumePortfoliosErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteSeekerProfResumePortfoliosError'] = ResolversParentTypes['DeleteSeekerProfResumePortfoliosError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type DeleteSeekerProfResumeSocialNetworksErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteSeekerProfResumeSocialNetworksError'] = ResolversParentTypes['DeleteSeekerProfResumeSocialNetworksError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type DeleteTargetedMailingOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteTargetedMailingOutput'] = ResolversParentTypes['DeleteTargetedMailingOutput']> = {
  isSuccessful?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeleteVacancyOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteVacancyOutput'] = ResolversParentTypes['DeleteVacancyOutput']> = {
  vacanciesIds?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>
  vacancyId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeletedProfResumeAdditionalEducationsOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeletedProfResumeAdditionalEducationsOutput'] = ResolversParentTypes['DeletedProfResumeAdditionalEducationsOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['DeleteSeekerProfResumeAdditionalEducationsError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeletedProfResumeAdditionalInfoOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeletedProfResumeAdditionalInfoOutput'] = ResolversParentTypes['DeletedProfResumeAdditionalInfoOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['DeleteSeekerProfResumeAdditionalInfoError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeletedProfResumeEducationsOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeletedProfResumeEducationsOutput'] = ResolversParentTypes['DeletedProfResumeEducationsOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['DeleteSeekerProfResumeEducationsError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeletedProfResumeExperiencesOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeletedProfResumeExperiencesOutput'] = ResolversParentTypes['DeletedProfResumeExperiencesOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['DeleteSeekerProfResumeExperiencesError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeletedProfResumeLanguageSkillsOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeletedProfResumeLanguageSkillsOutput'] = ResolversParentTypes['DeletedProfResumeLanguageSkillsOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['DeleteSeekerProfResumeLanguageSkillsError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeletedProfResumeOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeletedProfResumeOutput'] = ResolversParentTypes['DeletedProfResumeOutput']> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['DeleteSeekerProfResumeError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ReadonlyArray<ResolversTypes['ProfResume']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeletedUserManagementEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeletedUserManagementEmployerEvent'] = ResolversParentTypes['DeletedUserManagementEmployerEvent']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  deleted?: Resolver<ResolversTypes['EmployerEventSnapshot'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  responsible?: Resolver<ResolversTypes['EmployerEventSnapshot'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeletedVacancyEmployerEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeletedVacancyEmployerEvent'] = ResolversParentTypes['DeletedVacancyEmployerEvent']> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  vacancyCity?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  vacancyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  vacancyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DirectoryItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['DirectoryItem'] = ResolversParentTypes['DirectoryItem']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DiscountResolvers<ContextType = any, ParentType extends ResolversParentTypes['Discount'] = ResolversParentTypes['Discount']> = {
  __resolveType: TypeResolveFn<'ManagerDiscount' | 'PromoCodeDiscount', ParentType, ContextType>
}

export type DislikeSeekerVacanciesOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DislikeSeekerVacanciesOutput'] = ResolversParentTypes['DislikeSeekerVacanciesOutput']> = {
  query?: Resolver<ResolversTypes['Query'], ParentType, ContextType>
  vacancies?: Resolver<ReadonlyArray<ResolversTypes['Vacancy']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DistrictFacetResolvers<ContextType = any, ParentType extends ResolversParentTypes['DistrictFacet'] = ResolversParentTypes['DistrictFacet']> = {
  cityDistrict?: Resolver<ResolversTypes['CityDistrict'], ParentType, ContextType>
  count?: Resolver<ResolversTypes['Long'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DownloadActOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DownloadActOutput'] = ResolversParentTypes['DownloadActOutput']> = {
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DownloadInvoiceOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DownloadInvoiceOutput'] = ResolversParentTypes['DownloadInvoiceOutput']> = {
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DropAppliedWalletBonusResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DropAppliedWalletBonusResponse'] = ResolversParentTypes['DropAppliedWalletBonusResponse']
> = {
  cart?: Resolver<ResolversTypes['CartQuery'], ParentType, ContextType>
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DropSelectedPromoCodeResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DropSelectedPromoCodeResponse'] = ResolversParentTypes['DropSelectedPromoCodeResponse']
> = {
  cart?: Resolver<ResolversTypes['CartQuery'], ParentType, ContextType>
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DuplicateCompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['DuplicateCompany'] = ResolversParentTypes['DuplicateCompany']> = {
  duplicateType?: Resolver<ResolversTypes['DuplicateReasonEnum'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  managerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EditedVacancyCityEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EditedVacancyCityEmployerEvent'] = ResolversParentTypes['EditedVacancyCityEmployerEvent']
> = {
  from?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  to?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EditedVacancyEmployerEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['EditedVacancyEmployerEvent'] = ResolversParentTypes['EditedVacancyEmployerEvent']> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  editedFields?: Resolver<ReadonlyArray<ResolversTypes['EditedVacancyFieldEmployerEventUnion']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  vacancyCity?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  vacancyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  vacancyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EditedVacancyFieldEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EditedVacancyFieldEmployerEvent'] = ResolversParentTypes['EditedVacancyFieldEmployerEvent']
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EditedVacancyFieldEmployerEventUnionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EditedVacancyFieldEmployerEventUnion'] = ResolversParentTypes['EditedVacancyFieldEmployerEventUnion']
> = {
  __resolveType: TypeResolveFn<'EditedVacancyCityEmployerEvent' | 'EditedVacancyFieldEmployerEvent' | 'EditedVacancyTitleEmployerEvent', ParentType, ContextType>
}

export type EditedVacancyTitleEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EditedVacancyTitleEmployerEvent'] = ResolversParentTypes['EditedVacancyTitleEmployerEvent']
> = {
  from?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  to?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EducationLevelResolvers<ContextType = any, ParentType extends ResolversParentTypes['EducationLevel'] = ResolversParentTypes['EducationLevel']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmailAlreadyExistsErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmailAlreadyExistsError'] = ResolversParentTypes['EmailAlreadyExistsError']> = {
  isEmployer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isSeeker?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmailSenderResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmailSenderResult'] = ResolversParentTypes['EmailSenderResult']> = {
  isSuccess?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmailStatisticsResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmailStatistics'] = ResolversParentTypes['EmailStatistics']> = {
  appliesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  clicks?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  deliveries?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  links?: Resolver<ReadonlyArray<ResolversTypes['TargetedMailingLinkUnion']>, ParentType, ContextType>
  openings?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Employee'] = ResolversParentTypes['Employee']> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  applyAutoReplyTemplate?: Resolver<ResolversTypes['EmployeeSubscriptionAnswerTemplate'], ParentType, ContextType>
  ats?: Resolver<ResolversTypes['EmployeeAtsRelation'], ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>
  contactPhoto?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  department?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  fullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  hasEverPostedVacancies?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isActiveUser?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isCurrentUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isOnline?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  lastPasswordChangedDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  lastSeenAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  lastStatusChanged?: Resolver<Maybe<ResolversTypes['EmployeeStatusChanged']>, ParentType, ContextType>
  mainPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  phones?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  post?: Resolver<Maybe<ResolversTypes['EmployeePostEnum']>, ParentType, ContextType>
  role?: Resolver<Maybe<ResolversTypes['EmployeeRole']>, ParentType, ContextType>
  scopes?: Resolver<ReadonlyArray<ResolversTypes['EmployeeRoleBasedScope']>, ParentType, ContextType>
  serviceLimitCounters?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['EmployeeLimitCounter']>>>, ParentType, ContextType, Partial<EmployeeServiceLimitCountersArgs>>
  serviceLimits?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['EmployeeSummaryServiceLimit']>>>, ParentType, ContextType, Partial<EmployeeServiceLimitsArgs>>
  status?: Resolver<ResolversTypes['EmployeeStatusEnum'], ParentType, ContextType>
  subscriptions?: Resolver<ReadonlyArray<ResolversTypes['EmployeeSubscription']>, ParentType, ContextType>
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeAtsRelationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployeeAtsRelation'] = ResolversParentTypes['EmployeeAtsRelation']> = {
  hasCandidatesDatabaseAccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isBlocked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isRegistered?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeChangeEmailOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployeeChangeEmailOutput'] = ResolversParentTypes['EmployeeChangeEmailOutput']> = {
  error?: Resolver<Maybe<ResolversTypes['EmployeeEditError']>, ParentType, ContextType>
  isSuccessful?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeChangeOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployeeChangeOutput'] = ResolversParentTypes['EmployeeChangeOutput']> = {
  error?: Resolver<Maybe<ResolversTypes['EmployeeEditError']>, ParentType, ContextType>
  isSuccessful?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployeeConnection'] = ResolversParentTypes['EmployeeConnection']> = {
  edges?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['EmployeeEdge']>>>, ParentType, ContextType>
  items?: Resolver<Maybe<ReadonlyArray<ResolversTypes['Employee']>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeCreateErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployeeCreateError'] = ResolversParentTypes['EmployeeCreateError']> = {
  __resolveType: TypeResolveFn<'EmailAlreadyExistsError' | 'ForbiddenToMutateUsersError', ParentType, ContextType>
}

export type EmployeeCreateOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployeeCreateOutput'] = ResolversParentTypes['EmployeeCreateOutput']> = {
  employee?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType>
  error?: Resolver<Maybe<ResolversTypes['EmployeeCreateError']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeCvDbSavedSearchDeleteOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeCvDbSavedSearchDeleteOutput'] = ResolversParentTypes['EmployeeCvDbSavedSearchDeleteOutput']
> = {
  isSuccessful?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployeeEdge'] = ResolversParentTypes['EmployeeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['Employee'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeEditErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployeeEditError'] = ResolversParentTypes['EmployeeEditError']> = {
  __resolveType: TypeResolveFn<'EmailAlreadyExistsError' | 'ForbiddenToMutateUsersError' | 'PasswordConfirmationFailedError' | 'TokenAlreadyUsedError', ParentType, ContextType>
}

export type EmployeeEditOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployeeEditOutput'] = ResolversParentTypes['EmployeeEditOutput']> = {
  employee?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType>
  error?: Resolver<Maybe<ResolversTypes['EmployeeEditError']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeHasScopesOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployeeHasScopesOutput'] = ResolversParentTypes['EmployeeHasScopesOutput']> = {
  existing?: Resolver<ReadonlyArray<ResolversTypes['EmployeeRoleBasedScope']>, ParentType, ContextType>
  hasRequestedScopes?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  requested?: Resolver<ReadonlyArray<ResolversTypes['EmployeeRoleBasedScope']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeJoiningRequestAlreadyReviewedErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeJoiningRequestAlreadyReviewedError'] = ResolversParentTypes['EmployeeJoiningRequestAlreadyReviewedError']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeJoiningReviewErrorUnionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeJoiningReviewErrorUnion'] = ResolversParentTypes['EmployeeJoiningReviewErrorUnion']
> = {
  __resolveType: TypeResolveFn<'EmployeeJoiningRequestAlreadyReviewedError', ParentType, ContextType>
}

export type EmployeeJoiningReviewOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployeeJoiningReviewOutput'] = ResolversParentTypes['EmployeeJoiningReviewOutput']> = {
  employee?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType>
  error?: Resolver<Maybe<ResolversTypes['EmployeeJoiningReviewErrorUnion']>, ParentType, ContextType>
  isSuccessful?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeLimitCounterResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployeeLimitCounter'] = ResolversParentTypes['EmployeeLimitCounter']> = {
  has?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  remaining?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  serviceType?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  usesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeMakeMainOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployeeMakeMainOutput'] = ResolversParentTypes['EmployeeMakeMainOutput']> = {
  isSuccessful?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeResendInvitationOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeResendInvitationOutput'] = ResolversParentTypes['EmployeeResendInvitationOutput']
> = {
  isSuccessful?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeRoleBasedScopeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployeeRoleBasedScope'] = ResolversParentTypes['EmployeeRoleBasedScope']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  role?: Resolver<ResolversTypes['EmployeeRoleBasedScopeEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeScopeChangeUserManagementEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeScopeChangeUserManagementEmployerEvent'] = ResolversParentTypes['EmployeeScopeChangeUserManagementEmployerEvent']
> = {
  isEnabledAfter?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isEnabledBefore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  scope?: Resolver<ResolversTypes['EmployeeRoleBasedScope'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeStatusChangedResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployeeStatusChanged'] = ResolversParentTypes['EmployeeStatusChanged']> = {
  at?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  by?: Resolver<ResolversTypes['Employee'], ParentType, ContextType>
  from?: Resolver<Maybe<ResolversTypes['EmployeeStatusEnum']>, ParentType, ContextType>
  to?: Resolver<ResolversTypes['EmployeeStatusEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeSubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployeeSubscription'] = ResolversParentTypes['EmployeeSubscription']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  options?: Resolver<Maybe<ReadonlyArray<ResolversTypes['EmployeeSubscriptionSubOptionUnion']>>, ParentType, ContextType>
  type?: Resolver<ResolversTypes['EmployeeSubscriptionType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeSubscriptionAnswerTemplateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeSubscriptionAnswerTemplate'] = ResolversParentTypes['EmployeeSubscriptionAnswerTemplate']
> = {
  isSendForAllApplies?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeSubscriptionAnswerTemplateOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeSubscriptionAnswerTemplateOutput'] = ResolversParentTypes['EmployeeSubscriptionAnswerTemplateOutput']
> = {
  applyAutoReplyTemplate?: Resolver<ResolversTypes['EmployeeSubscriptionAnswerTemplate'], ParentType, ContextType>
  isSuccessful?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeSubscriptionIntRangeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployeeSubscriptionIntRange'] = ResolversParentTypes['EmployeeSubscriptionIntRange']> = {
  from?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  to?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeSubscriptionSavedCvDbSearchRequestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeSubscriptionSavedCvDbSearchRequest'] = ResolversParentTypes['EmployeeSubscriptionSavedCvDbSearchRequest']
> = {
  age?: Resolver<Maybe<ResolversTypes['EmployeeSubscriptionIntRange']>, ParentType, ContextType>
  branchIds?: Resolver<Maybe<ReadonlyArray<ResolversTypes['ID']>>, ParentType, ContextType>
  city?: Resolver<Maybe<ResolversTypes['City']>, ParentType, ContextType>
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  cvLanguage?: Resolver<Maybe<ResolversTypes['EmployeeSubscriptionSavedCvDbSearchCvLanguageEnum']>, ParentType, ContextType>
  districtIds?: Resolver<Maybe<ReadonlyArray<ResolversTypes['ID']>>, ParentType, ContextType>
  educationIds?: Resolver<Maybe<ReadonlyArray<ResolversTypes['ID']>>, ParentType, ContextType>
  experienceIds?: Resolver<Maybe<ReadonlyArray<ResolversTypes['ID']>>, ParentType, ContextType>
  hasMoveability?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  hasOnlyMoveability?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  hasPhoto?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isIncludeDisliked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isInside?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isOnlyDisabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isOnlyFavorite?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isOnlyNew?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isOnlyStudents?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isOnlyViewed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isOnlyWithCurrentNotebookNotes?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isOnlyWithOpenedContacts?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isOnlyWithOwnCar?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isReverse?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isShowCvWithoutSalary?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isSynonym?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isUkrainian?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  keyWords?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  languages?: Resolver<Maybe<ReadonlyArray<ResolversTypes['ID']>>, ParentType, ContextType>
  lastSort?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  page?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  period?: Resolver<Maybe<ResolversTypes['EmployeeSubscriptionSavedCvDbSearchPeriodEnum']>, ParentType, ContextType>
  profLevelIds?: Resolver<Maybe<ReadonlyArray<ResolversTypes['ID']>>, ParentType, ContextType>
  resumeFillingTypeIds?: Resolver<Maybe<ReadonlyArray<ResolversTypes['ID']>>, ParentType, ContextType>
  rubrics?: Resolver<Maybe<ReadonlyArray<ResolversTypes['ID']>>, ParentType, ContextType>
  salary?: Resolver<Maybe<ResolversTypes['EmployeeSubscriptionIntRange']>, ParentType, ContextType>
  scheduleIds?: Resolver<Maybe<ReadonlyArray<ResolversTypes['ID']>>, ParentType, ContextType>
  searchContext?: Resolver<Maybe<ResolversTypes['EmployeeSubscriptionSavedCvDbSearchContextEnum']>, ParentType, ContextType>
  searchType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  sex?: Resolver<Maybe<ResolversTypes['Gender']>, ParentType, ContextType>
  sort?: Resolver<Maybe<ResolversTypes['EmployeeSubscriptionSavedCvDbSearchCvSortEnum']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeSubscriptionSavedCvDbSearchSubOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeSubscriptionSavedCvDbSearchSubOption'] = ResolversParentTypes['EmployeeSubscriptionSavedCvDbSearchSubOption']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  searchRequest?: Resolver<ResolversTypes['EmployeeSubscriptionSavedCvDbSearchRequest'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeSubscriptionSubOptionUnionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeSubscriptionSubOptionUnion'] = ResolversParentTypes['EmployeeSubscriptionSubOptionUnion']
> = {
  __resolveType: TypeResolveFn<'EmployeeSubscriptionSavedCvDbSearchSubOption', ParentType, ContextType>
}

export type EmployeeSubscriptionTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployeeSubscriptionType'] = ResolversParentTypes['EmployeeSubscriptionType']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['EmployeeSubscriptionTypeEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeSubscriptionsMutationsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeSubscriptionsMutations'] = ResolversParentTypes['EmployeeSubscriptionsMutations']
> = {
  update?: Resolver<ResolversTypes['EmployeeSubscriptionsUpdateOutput'], ParentType, ContextType, RequireFields<EmployeeSubscriptionsMutationsUpdateArgs, 'items'>>
  updateAnswerTemplate?: Resolver<ResolversTypes['EmployeeSubscriptionAnswerTemplateOutput'], ParentType, ContextType, RequireFields<EmployeeSubscriptionsMutationsUpdateAnswerTemplateArgs, 'input'>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeSubscriptionsQueriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployeeSubscriptionsQueries'] = ResolversParentTypes['EmployeeSubscriptionsQueries']> = {
  applyAutoReplyTemplate?: Resolver<ResolversTypes['EmployeeSubscriptionAnswerTemplate'], ParentType, ContextType, RequireFields<EmployeeSubscriptionsQueriesApplyAutoReplyTemplateArgs, 'employeeId'>>
  glossary?: Resolver<ReadonlyArray<ResolversTypes['EmployeeSubscriptionType']>, ParentType, ContextType>
  list?: Resolver<ReadonlyArray<ResolversTypes['EmployeeSubscription']>, ParentType, ContextType>
  single?: Resolver<Maybe<ResolversTypes['EmployeeSubscription']>, ParentType, ContextType, RequireFields<EmployeeSubscriptionsQueriesSingleArgs, 'id'>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeSubscriptionsUpdateOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeeSubscriptionsUpdateOutput'] = ResolversParentTypes['EmployeeSubscriptionsUpdateOutput']
> = {
  isSuccessful?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeeSummaryServiceLimitResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployeeSummaryServiceLimit'] = ResolversParentTypes['EmployeeSummaryServiceLimit']> = {
  employee?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType>
  has?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  month?: Resolver<Maybe<ResolversTypes['ServiceLimit']>, ParentType, ContextType>
  permanent?: Resolver<Maybe<ResolversTypes['ServiceLimit']>, ParentType, ContextType>
  remaining?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  service?: Resolver<Maybe<ResolversTypes['SummaryService']>, ParentType, ContextType>
  serviceType?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  usedInLastMonth?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  usesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeesBlockOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployeesBlockOutput'] = ResolversParentTypes['EmployeesBlockOutput']> = {
  isSuccessful?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeesDeleteOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployeesDeleteOutput'] = ResolversParentTypes['EmployeesDeleteOutput']> = {
  isSuccessful?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeesMultiScopesUpdateOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployeesMultiScopesUpdateOutput'] = ResolversParentTypes['EmployeesMultiScopesUpdateOutput']
> = {
  isSuccessful?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployeesUnblockOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployeesUnblockOutput'] = ResolversParentTypes['EmployeesUnblockOutput']> = {
  isSuccessful?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployerAchievementResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployerAchievement'] = ResolversParentTypes['EmployerAchievement']> = {
  __resolveType: TypeResolveFn<
    | 'ApplicationsViewedEmployerAchievement'
    | 'OpenedContactOrVacancyOfferedEmployerAchievement'
    | 'PhoneNumberConfirmedEmployerAchievement'
    | 'VacancyPublishedEmployerAchievement'
    | 'VacancyPublishedWithFilledAddressAndSalaryEmployerAchievement',
    ParentType,
    ContextType
  >
  isDone?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['EmployerAchievementType'], ParentType, ContextType>
}

export type EmployerEventLogConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployerEventLogConnection'] = ResolversParentTypes['EmployerEventLogConnection']> = {
  edges?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['EmployersEventUnionEdge']>>>, ParentType, ContextType>
  items?: Resolver<Maybe<ReadonlyArray<ResolversTypes['EmployerEventUnion']>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployerEventPerformerResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployerEventPerformer'] = ResolversParentTypes['EmployerEventPerformer']> = {
  employeeSnapshot?: Resolver<ResolversTypes['EmployerEventSnapshot'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployerEventSnapshotResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployerEventSnapshot'] = ResolversParentTypes['EmployerEventSnapshot']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployerEventUnionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployerEventUnion'] = ResolversParentTypes['EmployerEventUnion']> = {
  __resolveType: TypeResolveFn<
    | 'ActivatedOrderByTurbotaServiceEmployerEvent'
    | 'ActivatedServiceEmployerEvent'
    | 'AddedNewUserManagementEmployerEvent'
    | 'ApprovedProlongationRequestVacancyEmployerEvent'
    | 'ApprovedReviewVacancyEmployerEvent'
    | 'AutoProlongationDisabledVacancyEmployerEvent'
    | 'AutoProlongationEnabledVacancyEmployerEvent'
    | 'AutoProlongedVacancyEmployerEvent'
    | 'BlockedUserManagementEmployerEvent'
    | 'ChangedFullNameUserManagementEmployerEvent'
    | 'ChangedScopesUserManagementEmployerEvent'
    | 'ClosedVacancyEmployerEvent'
    | 'CompanyFreeServiceAddedEvent'
    | 'CompanySavedCardAddedEvent'
    | 'CompanySavedCardRemovedEvent'
    | 'CreatedDraftVacancyEmployerEvent'
    | 'CreatedOrderServiceEmployerEvent'
    | 'DeletedUserManagementEmployerEvent'
    | 'DeletedVacancyEmployerEvent'
    | 'EditedVacancyEmployerEvent'
    | 'EndedHotPeriodVacancyEmployerEvent'
    | 'EndedServiceEmployerEvent'
    | 'MadeHotVacancyEmployerEvent'
    | 'OfferedVacancyCvDbEmployerEvent'
    | 'OpenedContactsCvDbEmployerEvent'
    | 'OrderFreemiumServicesAccruedServiceEmployerEvent'
    | 'OrderServicesAccruedServiceEmployerEvent'
    | 'OwnerChangedVacancyEmployerEvent'
    | 'PaymentReceivedServiceEmployerEvent'
    | 'ProlongationRequestVacancyEmployerEvent'
    | 'PublishedVacancyEmployerEvent'
    | 'RePublishedVacancyEmployerEvent'
    | 'RejectedByModeratorVacancyEmployerEvent'
    | 'RejectedProlongationRequestVacancyEmployerEvent'
    | 'RejectedReviewVacancyEmployerEvent'
    | 'SentToReviewVacancyEmployerEvent'
    | 'UsedHotServiceEmployerEvent'
    | 'UsedVacancyServiceEmployerEvent'
    | 'WalletTransactionEvent',
    ParentType,
    ContextType
  >
}

export type EmployerOnBoardingCheckListOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmployerOnBoardingCheckListOutput'] = ResolversParentTypes['EmployerOnBoardingCheckListOutput']
> = {
  applicationsViewed?: Resolver<ResolversTypes['ApplicationsViewedEmployerAchievement'], ParentType, ContextType>
  companyPhoneNumberConfirmed?: Resolver<Maybe<ResolversTypes['PhoneNumberConfirmedEmployerAchievement']>, ParentType, ContextType>
  firstVacancyPublished?: Resolver<ResolversTypes['VacancyPublishedEmployerAchievement'], ParentType, ContextType>
  isOnBoardingCheckListRead?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  openedContactOrVacancyOffered?: Resolver<ResolversTypes['OpenedContactOrVacancyOfferedEmployerAchievement'], ParentType, ContextType>
  phoneNumberConfirmed?: Resolver<ResolversTypes['PhoneNumberConfirmedEmployerAchievement'], ParentType, ContextType>
  vacancyPublishedWithFilledAddressAndSalary?: Resolver<ResolversTypes['VacancyPublishedWithFilledAddressAndSalaryEmployerAchievement'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployerRegistrationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployerRegistration'] = ResolversParentTypes['EmployerRegistration']> = {
  createDuplicateProfile?: Resolver<ResolversTypes['EmployerRegistrationResponse'], ParentType, ContextType, RequireFields<EmployerRegistrationCreateDuplicateProfileArgs, 'input'>>
  createProfile?: Resolver<ResolversTypes['EmployerRegistrationResponse'], ParentType, ContextType, RequireFields<EmployerRegistrationCreateProfileArgs, 'input'>>
  validate?: Resolver<ResolversTypes['UserRegistrationValidationResponse'], ParentType, ContextType, RequireFields<EmployerRegistrationValidateArgs, 'input'>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployerRegistrationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployerRegistrationResponse'] = ResolversParentTypes['EmployerRegistrationResponse']> = {
  authLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  employer?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType>
  errors?: Resolver<ReadonlyArray<ResolversTypes['UserRegistrationError']>, ParentType, ContextType>
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployerWalletUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployerWalletUser'] = ResolversParentTypes['EmployerWalletUser']> = {
  employer?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmployersEventUnionEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployersEventUnionEdge'] = ResolversParentTypes['EmployersEventUnionEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['EmployerEventUnion'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EndedHotPeriodVacancyEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EndedHotPeriodVacancyEmployerEvent'] = ResolversParentTypes['EndedHotPeriodVacancyEmployerEvent']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['SystemEventPerformer'], ParentType, ContextType>
  vacancyCity?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  vacancyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  vacancyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EndedServiceEmployerEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['EndedServiceEmployerEvent'] = ResolversParentTypes['EndedServiceEmployerEvent']> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['SystemEventPerformer'], ParentType, ContextType>
  service?: Resolver<ResolversTypes['ServiceEmployerEventSnapshotUnion'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['Error'] = ResolversParentTypes['Error']> = {
  __resolveType: TypeResolveFn<
    | 'AtsProjectCreationError'
    | 'CompanyWasMergedError'
    | 'HasNoAccessToRequestedFieldsError'
    | 'HasNoScopesForActionError'
    | 'InvalidError'
    | 'NoAvailableLimitsError'
    | 'NotAvailableError'
    | 'NotFoundError'
    | 'ServerError'
    | 'TransactionExistsError'
    | 'TurbotaWasExpiredError'
    | 'UserIsNotAuthenticatedError'
    | 'UserIsNotEmployerError'
    | 'WalletDoesNotExistError'
    | 'WalletExistsError',
    ParentType,
    ContextType
  >
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>
}

export type EventInterfaceResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventInterface'] = ResolversParentTypes['EventInterface']> = {
  __resolveType: TypeResolveFn<
    | 'CompanyBufferEvent'
    | 'CompanyRegisteredEvent'
    | 'CompanyWorkRegisteredEvent'
    | 'InvoiceExpiredEvent'
    | 'InvoicePaidEvent'
    | 'InvoicePaymentBankEvent'
    | 'InvoiceRequestedEvent'
    | 'MetanotebookAssignedEvent'
    | 'PersonalDiscountEvent'
    | 'ProfileRegisteredEvent'
    | 'ServiceIsExpiringEvent'
    | 'TurbotaEvent'
    | 'UncompletedPaymentEvent'
    | 'UserLeftBasketEvent'
    | 'VacancyBannedEvent'
    | 'VacancyOnWorkWillExpireInFiveDaysEvent',
    ParentType,
    ContextType
  >
  addDate?: Resolver<ResolversTypes['DateTimeOffset'], ParentType, ContextType>
  assignment?: Resolver<Maybe<ResolversTypes['Assignment']>, ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['CrmCompany']>, ParentType, ContextType>
  daysLeft?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  employee?: Resolver<Maybe<ResolversTypes['CrmEmployee']>, ParentType, ContextType>
  eventType?: Resolver<ResolversTypes['EventTypeEnum'], ParentType, ContextType>
  expirationDate?: Resolver<Maybe<ResolversTypes['DateTimeOffset']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isArchieved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isRead?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  lifetimeDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  state?: Resolver<ResolversTypes['EventStateEnum'], ParentType, ContextType>
  topics?: Resolver<ReadonlyArray<ResolversTypes['Topic']>, ParentType, ContextType>
}

export type ExperienceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Experience'] = ResolversParentTypes['Experience']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ExperienceBasicQuestionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExperienceBasicQuestion'] = ResolversParentTypes['ExperienceBasicQuestion']> = {
  defaultText?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  experiences?: Resolver<ReadonlyArray<ResolversTypes['BasicQuestionItem']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ExternalCourseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExternalCourse'] = ResolversParentTypes['ExternalCourse']> = {
  __resolveType: TypeResolveFn<'PrometheusExternalCourse', ParentType, ContextType>
}

export type ExternalUrlPaymentMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExternalUrlPaymentMethod'] = ResolversParentTypes['ExternalUrlPaymentMethod']> = {
  generate?: Resolver<ResolversTypes['GenerateExternalPaymentUrlOutput'], ParentType, ContextType, Partial<ExternalUrlPaymentMethodGenerateArgs>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FacetResolvers<ContextType = any, ParentType extends ResolversParentTypes['Facet'] = ResolversParentTypes['Facet']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  item?: Resolver<ResolversTypes['ApplyFacetUnion'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FavoriteVacanciesRemindedDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FavoriteVacanciesRemindedDetails'] = ResolversParentTypes['FavoriteVacanciesRemindedDetails']
> = {
  detailType?: Resolver<ResolversTypes['SeekerNotificationDetailTypeEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FeedbackMutationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FeedbackMutations'] = ResolversParentTypes['FeedbackMutations']> = {
  give?: Resolver<ResolversTypes['FeedbackOutput'], ParentType, ContextType, RequireFields<FeedbackMutationsGiveArgs, 'input'>>
  reportResume?: Resolver<ResolversTypes['ResumeReportOutput'], ParentType, ContextType, RequireFields<FeedbackMutationsReportResumeArgs, 'input'>>
  reportVacancy?: Resolver<ResolversTypes['VacancyReportOutput'], ParentType, ContextType, RequireFields<FeedbackMutationsReportVacancyArgs, 'input'>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FeedbackOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['FeedbackOutput'] = ResolversParentTypes['FeedbackOutput']> = {
  isSuccessful?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FeedbackQueriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['FeedbackQueries'] = ResolversParentTypes['FeedbackQueries']> = {
  ping?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FileAttachResolvers<ContextType = any, ParentType extends ResolversParentTypes['FileAttach'] = ResolversParentTypes['FileAttach']> = {
  addDate?: Resolver<ResolversTypes['DateTimeOffset'], ParentType, ContextType>
  assignmentId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  fileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  managerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ForbiddenToMutateUsersErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ForbiddenToMutateUsersError'] = ResolversParentTypes['ForbiddenToMutateUsersError']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GamificationDatesResolvers<ContextType = any, ParentType extends ResolversParentTypes['GamificationDates'] = ResolversParentTypes['GamificationDates']> = {
  end?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  start?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GamificationWaveResolvers<ContextType = any, ParentType extends ResolversParentTypes['GamificationWave'] = ResolversParentTypes['GamificationWave']> = {
  endDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  giftCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isGiftSent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  multiUsers?: Resolver<ReadonlyArray<ResolversTypes['ID']>, ParentType, ContextType>
  occasionWaveName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  startDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  wave?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GamificationWaveListResolvers<ContextType = any, ParentType extends ResolversParentTypes['GamificationWaveList'] = ResolversParentTypes['GamificationWaveList']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  occasion?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GenerateExternalPaymentUrlByInvoiceOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GenerateExternalPaymentUrlByInvoiceOutput'] = ResolversParentTypes['GenerateExternalPaymentUrlByInvoiceOutput']
> = {
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GenerateExternalPaymentUrlOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GenerateExternalPaymentUrlOutput'] = ResolversParentTypes['GenerateExternalPaymentUrlOutput']
> = {
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GetCurrentEmployerErrorUnionResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetCurrentEmployerErrorUnion'] = ResolversParentTypes['GetCurrentEmployerErrorUnion']> = {
  __resolveType: TypeResolveFn<'InvalidError' | 'NotFoundError' | 'UserIsNotAuthenticatedError', ParentType, ContextType>
}

export type GetCurrentEmployerOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetCurrentEmployerOutput'] = ResolversParentTypes['GetCurrentEmployerOutput']> = {
  employer?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType>
  errors?: Resolver<ReadonlyArray<ResolversTypes['GetCurrentEmployerErrorUnion']>, ParentType, ContextType>
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GetSeekerDislikedVacanciesTotalCountOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetSeekerDislikedVacanciesTotalCountOutput'] = ResolversParentTypes['GetSeekerDislikedVacanciesTotalCountOutput']
> = {
  value?: Resolver<ResolversTypes['UInt'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GetSeekerFavoriteVacanciesTotalCountOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetSeekerFavoriteVacanciesTotalCountOutput'] = ResolversParentTypes['GetSeekerFavoriteVacanciesTotalCountOutput']
> = {
  value?: Resolver<ResolversTypes['UInt'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GetVacancyByIdResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetVacancyByIdResponse'] = ResolversParentTypes['GetVacancyByIdResponse']> = {
  error?: Resolver<Maybe<ResolversTypes['GetVacancyError']>, ParentType, ContextType>
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  vacancy?: Resolver<Maybe<ResolversTypes['Vacancy']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GetVacancyErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetVacancyError'] = ResolversParentTypes['GetVacancyError']> = {
  __resolveType: TypeResolveFn<'HasNoAccessToRequestedFieldsError' | 'NotAvailableError' | 'NotFoundError', ParentType, ContextType>
}

export type GetVacancySearchSuggestsOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetVacancySearchSuggestsOutput'] = ResolversParentTypes['GetVacancySearchSuggestsOutput']
> = {
  list?: Resolver<ReadonlyArray<ResolversTypes['VacancySearchSuggestList']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GiftResolvers<ContextType = any, ParentType extends ResolversParentTypes['Gift'] = ResolversParentTypes['Gift']> = {
  giftType?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isDeleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  typeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GiftCompanyWaveLimitResolvers<ContextType = any, ParentType extends ResolversParentTypes['GiftCompanyWaveLimit'] = ResolversParentTypes['GiftCompanyWaveLimit']> = {
  counter?: Resolver<ResolversTypes['GiftCompanyWaveCounterEnum'], ParentType, ContextType>
  counterValue?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  gift?: Resolver<ResolversTypes['Gift'], ParentType, ContextType>
  occasionWave?: Resolver<ResolversTypes['GiftOccasion'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GiftDeliverySlotResolvers<ContextType = any, ParentType extends ResolversParentTypes['GiftDeliverySlot'] = ResolversParentTypes['GiftDeliverySlot']> = {
  height?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  length?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  weight?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>
  width?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GiftLimitResolvers<ContextType = any, ParentType extends ResolversParentTypes['GiftLimit'] = ResolversParentTypes['GiftLimit']> = {
  allowedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  giftType?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  giftTypeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isAllowedPaymentRange?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  occasion?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  occasionName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  occasionWave?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  occasionWaveName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GiftOccasionResolvers<ContextType = any, ParentType extends ResolversParentTypes['GiftOccasion'] = ResolversParentTypes['GiftOccasion']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GiftRequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['GiftRequest'] = ResolversParentTypes['GiftRequest']> = {
  addDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  company?: Resolver<ResolversTypes['CrmCompany'], ParentType, ContextType>
  deliveryChannel?: Resolver<ResolversTypes['GiftDeliveryChannelEnum'], ParentType, ContextType>
  deliveryOption?: Resolver<Maybe<ResolversTypes['GiftRequestDeliveryOptions']>, ParentType, ContextType>
  deliverySlots?: Resolver<ReadonlyArray<ResolversTypes['GiftDeliverySlot']>, ParentType, ContextType>
  details?: Resolver<ReadonlyArray<ResolversTypes['GiftRequestDetail']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  manager?: Resolver<ResolversTypes['CrmManager'], ParentType, ContextType>
  occasion?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  occasionName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  state?: Resolver<ResolversTypes['GiftRequestStateEnum'], ParentType, ContextType>
  userCard?: Resolver<Maybe<ResolversTypes['CrmUserCard']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GiftRequestConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['GiftRequestConnection'] = ResolversParentTypes['GiftRequestConnection']> = {
  edges?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['GiftRequestEdge']>>>, ParentType, ContextType>
  items?: Resolver<Maybe<ReadonlyArray<ResolversTypes['GiftRequest']>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GiftRequestDeliveryOptionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['GiftRequestDeliveryOptions'] = ResolversParentTypes['GiftRequestDeliveryOptions']> = {
  addressRef?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  contactPersonRef?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  cost?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  counterpartyRef?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  deliveryCost?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  deliveryDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  deliveryType?: Resolver<ResolversTypes['GiftDeliveryTypeEnum'], ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  documentNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  documentRef?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  edrpou?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  isExactDeliveryDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isNeedToAttachDocuments?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  novaPoshtaComment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  novaPoshtaState?: Resolver<ResolversTypes['NovaPoshtaStateEnum'], ParentType, ContextType>
  recipientCityName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  recipientFirstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  recipientFlat?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  recipientHouse?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  recipientLastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  recipientPhone?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  recipientStreetName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  recipientType?: Resolver<ResolversTypes['NovaPosthaCounterpartyTypesGraphEnum'], ParentType, ContextType>
  senderFullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  senderPhone?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  senderRef?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  settlementRef?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GiftRequestDetailResolvers<ContextType = any, ParentType extends ResolversParentTypes['GiftRequestDetail'] = ResolversParentTypes['GiftRequestDetail']> = {
  giftName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  giftType?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GiftRequestEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['GiftRequestEdge'] = ResolversParentTypes['GiftRequestEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['GiftRequest'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GiftRequestOccasionResolvers<ContextType = any, ParentType extends ResolversParentTypes['GiftRequestOccasion'] = ResolversParentTypes['GiftRequestOccasion']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GiftTeamLimitResolvers<ContextType = any, ParentType extends ResolversParentTypes['GiftTeamLimit'] = ResolversParentTypes['GiftTeamLimit']> = {
  gift?: Resolver<ResolversTypes['Gift'], ParentType, ContextType>
  limitCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  limitTransferedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  occasion?: Resolver<ResolversTypes['GiftOccasion'], ParentType, ContextType>
  occasionWave?: Resolver<Maybe<ResolversTypes['GiftOccasion']>, ParentType, ContextType>
  startDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GiftTeamLimitByManagerResolvers<ContextType = any, ParentType extends ResolversParentTypes['GiftTeamLimitByManager'] = ResolversParentTypes['GiftTeamLimitByManager']> = {
  gift?: Resolver<ResolversTypes['Gift'], ParentType, ContextType>
  gifts?: Resolver<ReadonlyArray<ResolversTypes['GiftTeamLimitByManagerDetail']>, ParentType, ContextType>
  limitCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  limitTransferedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  manager?: Resolver<ResolversTypes['CrmManager'], ParentType, ContextType>
  occasion?: Resolver<ResolversTypes['GiftOccasion'], ParentType, ContextType>
  occasionWave?: Resolver<Maybe<ResolversTypes['GiftOccasion']>, ParentType, ContextType>
  startDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GiftTeamLimitByManagerConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GiftTeamLimitByManagerConnection'] = ResolversParentTypes['GiftTeamLimitByManagerConnection']
> = {
  edges?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['GiftTeamLimitByManagerEdge']>>>, ParentType, ContextType>
  items?: Resolver<Maybe<ReadonlyArray<ResolversTypes['GiftTeamLimitByManager']>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GiftTeamLimitByManagerDetailResolvers<ContextType = any, ParentType extends ResolversParentTypes['GiftTeamLimitByManagerDetail'] = ResolversParentTypes['GiftTeamLimitByManagerDetail']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  limitCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  limitTransferedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GiftTeamLimitByManagerEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['GiftTeamLimitByManagerEdge'] = ResolversParentTypes['GiftTeamLimitByManagerEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['GiftTeamLimitByManager'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GiftTeamLimitTransferHistoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['GiftTeamLimitTransferHistory'] = ResolversParentTypes['GiftTeamLimitTransferHistory']> = {
  addDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  gift?: Resolver<ResolversTypes['Gift'], ParentType, ContextType>
  limitCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  limitTransferedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  manager?: Resolver<ResolversTypes['CrmManager'], ParentType, ContextType>
  occasion?: Resolver<ResolversTypes['GiftOccasion'], ParentType, ContextType>
  occasionWave?: Resolver<Maybe<ResolversTypes['GiftOccasion']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GiftTeamLimitTransferHistoryConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GiftTeamLimitTransferHistoryConnection'] = ResolversParentTypes['GiftTeamLimitTransferHistoryConnection']
> = {
  edges?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['GiftTeamLimitTransferHistoryEdge']>>>, ParentType, ContextType>
  items?: Resolver<Maybe<ReadonlyArray<ResolversTypes['GiftTeamLimitTransferHistory']>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GiftTeamLimitTransferHistoryEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GiftTeamLimitTransferHistoryEdge'] = ResolversParentTypes['GiftTeamLimitTransferHistoryEdge']
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['GiftTeamLimitTransferHistory'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GiftWaveLimitResolvers<ContextType = any, ParentType extends ResolversParentTypes['GiftWaveLimit'] = ResolversParentTypes['GiftWaveLimit']> = {
  gift?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  giftName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  limitCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  occasion?: Resolver<ResolversTypes['GiftOccasion'], ParentType, ContextType>
  occasionWave?: Resolver<ResolversTypes['GiftOccasion'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GiftsFiltersResolvers<ContextType = any, ParentType extends ResolversParentTypes['GiftsFilters'] = ResolversParentTypes['GiftsFilters']> = {
  deliveryChannel?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['GiftDeliveryChannelEnum']>>>, ParentType, ContextType>
  giftIds?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>
  managerIds?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>
  notebookId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  novaPoshtaState?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['NovaPoshtaStateEnum']>>>, ParentType, ContextType>
  occasions?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>
  period?: Resolver<Maybe<ResolversTypes['GiftPeriodEnum']>, ParentType, ContextType>
  stateIds?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['GiftRequestStateEnum']>>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GiftsMutationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['GiftsMutations'] = ResolversParentTypes['GiftsMutations']> = {
  addGift?: Resolver<ResolversTypes['MovingGiftLimitsOutput'], ParentType, ContextType, RequireFields<GiftsMutationsAddGiftArgs, 'input'>>
  addMarketingGiftRequest?: Resolver<ResolversTypes['MovingGiftLimitsOutput'], ParentType, ContextType, RequireFields<GiftsMutationsAddMarketingGiftRequestArgs, 'input'>>
  addOccasion?: Resolver<ResolversTypes['MovingGiftLimitsOutput'], ParentType, ContextType, RequireFields<GiftsMutationsAddOccasionArgs, 'input'>>
  addWave?: Resolver<ResolversTypes['MovingGiftLimitsOutput'], ParentType, ContextType, RequireFields<GiftsMutationsAddWaveArgs, 'input'>>
  finishOccasion?: Resolver<ResolversTypes['MovingGiftLimitsOutput'], ParentType, ContextType, RequireFields<GiftsMutationsFinishOccasionArgs, 'id'>>
  finishWave?: Resolver<ResolversTypes['MovingGiftLimitsOutput'], ParentType, ContextType, RequireFields<GiftsMutationsFinishWaveArgs, 'waveId'>>
  setDeleted?: Resolver<ResolversTypes['MovingGiftLimitsOutput'], ParentType, ContextType, RequireFields<GiftsMutationsSetDeletedArgs, 'giftId'>>
  setRange?: Resolver<ResolversTypes['MovingGiftLimitsOutput'], ParentType, ContextType, RequireFields<GiftsMutationsSetRangeArgs, 'input'>>
  updateGiftLimitByStm?: Resolver<ResolversTypes['MovingGiftLimitsOutput'], ParentType, ContextType, RequireFields<GiftsMutationsUpdateGiftLimitByStmArgs, 'input'>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GroupTagResolvers<ContextType = any, ParentType extends ResolversParentTypes['GroupTag'] = ResolversParentTypes['GroupTag']> = {
  childTags?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['GroupTag']>>>, ParentType, ContextType>
  childTagsProf?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Int']>>>, ParentType, ContextType>
  clusterId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  isProf?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  parentTags?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['GroupTag']>>>, ParentType, ContextType>
  parentTagsProf?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Int']>>>, ParentType, ContextType>
  synonymIds?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Int']>>>, ParentType, ContextType>
  synonymNames?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface GuidScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Guid'], any> {
  name: 'Guid'
}

export type HasNoAccessToRequestedFieldsErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HasNoAccessToRequestedFieldsError'] = ResolversParentTypes['HasNoAccessToRequestedFieldsError']
> = {
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HasNoScopesForActionErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['HasNoScopesForActionError'] = ResolversParentTypes['HasNoScopesForActionError']> = {
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HelperCompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['HelperCompany'] = ResolversParentTypes['HelperCompany']> = {
  activityState?: Resolver<ResolversTypes['HelperActivityStateEnum'], ParentType, ContextType>
  details?: Resolver<ReadonlyArray<ResolversTypes['HelperCompanyDetail']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isRegistered?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  managerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HelperCompanyConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['HelperCompanyConnection'] = ResolversParentTypes['HelperCompanyConnection']> = {
  activeCompaniesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  attractedCompaniesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  edges?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['HelperCompanyEdge']>>>, ParentType, ContextType>
  items?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['HelperCompany']>>>, ParentType, ContextType>
  notAttractedCompaniesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HelperCompanyDetailResolvers<ContextType = any, ParentType extends ResolversParentTypes['HelperCompanyDetail'] = ResolversParentTypes['HelperCompanyDetail']> = {
  calendarWeek?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  endDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  eventCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  startDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HelperCompanyEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['HelperCompanyEdge'] = ResolversParentTypes['HelperCompanyEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<Maybe<ResolversTypes['HelperCompany']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HotCatalogServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['HotCatalogService'] = ResolversParentTypes['HotCatalogService']> = {
  calculatePrice?: Resolver<ResolversTypes['ServicePrice'], ParentType, ContextType, Partial<HotCatalogServiceCalculatePriceArgs>>
  calculatePrices?: Resolver<ReadonlyArray<ResolversTypes['ServicePrice']>, ParentType, ContextType, Partial<HotCatalogServiceCalculatePricesArgs>>
  groupWrapper?: Resolver<ResolversTypes['ServiceGroupWrapper'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  packagesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  typeWrapper?: Resolver<ResolversTypes['ServiceTypeWrapper'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HotServiceEmployerEventSnapshotResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HotServiceEmployerEventSnapshot'] = ResolversParentTypes['HotServiceEmployerEventSnapshot']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  catalogService?: Resolver<ResolversTypes['CatalogService'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InformationBannerResolvers<ContextType = any, ParentType extends ResolversParentTypes['InformationBanner'] = ResolversParentTypes['InformationBanner']> = {
  background?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  buttons?: Resolver<ReadonlyArray<ResolversTypes['BannerButton']>, ParentType, ContextType>
  displayPages?: Resolver<ReadonlyArray<ResolversTypes['BannerDisplayPagesEnum']>, ParentType, ContextType>
  dueTo?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  hasCloseButton?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  image?: Resolver<Maybe<ResolversTypes['BannerImageElement']>, ParentType, ContextType>
  mainText?: Resolver<ResolversTypes['BannerTextElement'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['BannerTypeEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type IntRangeResolvers<ContextType = any, ParentType extends ResolversParentTypes['IntRange'] = ResolversParentTypes['IntRange']> = {
  from?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  to?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InvalidErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvalidError'] = ResolversParentTypes['InvalidError']> = {
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Invoice'] = ResolversParentTypes['Invoice']> = {
  acts?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['InvoiceAct']>>>, ParentType, ContextType>
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  generateExternalPaymentUrl?: Resolver<Maybe<ResolversTypes['GenerateExternalPaymentUrlOutput']>, ParentType, ContextType, Partial<InvoiceGenerateExternalPaymentUrlArgs>>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isPaid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isValid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  lastPaidAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  orderId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  payWithCard?: Resolver<ResolversTypes['PayWithCardResponse'], ParentType, ContextType, RequireFields<InvoicePayWithCardArgs, 'cardId'>>
  payWithExternalWallet?: Resolver<ResolversTypes['PayWithExternalWalletResponse'], ParentType, ContextType, RequireFields<InvoicePayWithExternalWalletArgs, 'paymentData' | 'walletType'>>
  payer?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  paymentMethod?: Resolver<ResolversTypes['PaymentMethods'], ParentType, ContextType>
  state?: Resolver<ResolversTypes['InvoiceState'], ParentType, ContextType>
  totalPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  validTo?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  year?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InvoiceActResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceAct'] = ResolversParentTypes['InvoiceAct']> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  year?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InvoiceExpiredEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceExpiredEvent'] = ResolversParentTypes['InvoiceExpiredEvent']> = {
  accountNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  accountYear?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  addDate?: Resolver<ResolversTypes['DateTimeOffset'], ParentType, ContextType>
  assignment?: Resolver<Maybe<ResolversTypes['Assignment']>, ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['CrmCompany']>, ParentType, ContextType>
  daysLeft?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  dealNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  employee?: Resolver<Maybe<ResolversTypes['CrmEmployee']>, ParentType, ContextType>
  eventType?: Resolver<ResolversTypes['EventTypeEnum'], ParentType, ContextType>
  expirationDate?: Resolver<Maybe<ResolversTypes['DateTimeOffset']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isArchieved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isRead?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  lifetimeDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  orderNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  state?: Resolver<ResolversTypes['EventStateEnum'], ParentType, ContextType>
  topics?: Resolver<ReadonlyArray<ResolversTypes['Topic']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InvoicePaidEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoicePaidEvent'] = ResolversParentTypes['InvoicePaidEvent']> = {
  accountNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  accountYear?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  addDate?: Resolver<ResolversTypes['DateTimeOffset'], ParentType, ContextType>
  assignment?: Resolver<Maybe<ResolversTypes['Assignment']>, ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['CrmCompany']>, ParentType, ContextType>
  daysLeft?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  dealNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  employee?: Resolver<Maybe<ResolversTypes['CrmEmployee']>, ParentType, ContextType>
  eventType?: Resolver<ResolversTypes['EventTypeEnum'], ParentType, ContextType>
  expirationDate?: Resolver<Maybe<ResolversTypes['DateTimeOffset']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isArchieved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isRead?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  lifetimeDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  orderNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  state?: Resolver<ResolversTypes['EventStateEnum'], ParentType, ContextType>
  topics?: Resolver<ReadonlyArray<ResolversTypes['Topic']>, ParentType, ContextType>
  total?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InvoicePaymentBankEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoicePaymentBankEvent'] = ResolversParentTypes['InvoicePaymentBankEvent']> = {
  accountNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  accountYear?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  addDate?: Resolver<ResolversTypes['DateTimeOffset'], ParentType, ContextType>
  assignment?: Resolver<Maybe<ResolversTypes['Assignment']>, ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['CrmCompany']>, ParentType, ContextType>
  daysLeft?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  dealNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  employee?: Resolver<Maybe<ResolversTypes['CrmEmployee']>, ParentType, ContextType>
  eventType?: Resolver<ResolversTypes['EventTypeEnum'], ParentType, ContextType>
  expirationDate?: Resolver<Maybe<ResolversTypes['DateTimeOffset']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isArchieved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isRead?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  lifetimeDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  orderNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  state?: Resolver<ResolversTypes['EventStateEnum'], ParentType, ContextType>
  topics?: Resolver<ReadonlyArray<ResolversTypes['Topic']>, ParentType, ContextType>
  total?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type InvoiceRequestedEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvoiceRequestedEvent'] = ResolversParentTypes['InvoiceRequestedEvent']> = {
  accountNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  accountYear?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  addDate?: Resolver<ResolversTypes['DateTimeOffset'], ParentType, ContextType>
  assignment?: Resolver<Maybe<ResolversTypes['Assignment']>, ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['CrmCompany']>, ParentType, ContextType>
  daysLeft?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  dealNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  employee?: Resolver<Maybe<ResolversTypes['CrmEmployee']>, ParentType, ContextType>
  eventType?: Resolver<ResolversTypes['EventTypeEnum'], ParentType, ContextType>
  expirationDate?: Resolver<Maybe<ResolversTypes['DateTimeOffset']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isArchieved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isRead?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  lifetimeDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  orderNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  state?: Resolver<ResolversTypes['EventStateEnum'], ParentType, ContextType>
  topics?: Resolver<ReadonlyArray<ResolversTypes['Topic']>, ParentType, ContextType>
  total?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type JiraServiceDeskOrganisationResolvers<ContextType = any, ParentType extends ResolversParentTypes['JiraServiceDeskOrganisation'] = ResolversParentTypes['JiraServiceDeskOrganisation']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type JobInstantReceivedNotificationDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobInstantReceivedNotificationDetails'] = ResolversParentTypes['JobInstantReceivedNotificationDetails']
> = {
  detailType?: Resolver<ResolversTypes['SeekerNotificationDetailTypeEnum'], ParentType, ContextType>
  vacancy?: Resolver<Maybe<ResolversTypes['Vacancy']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type JobRecommendationReceivedDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobRecommendationReceivedDetails'] = ResolversParentTypes['JobRecommendationReceivedDetails']
> = {
  detailType?: Resolver<ResolversTypes['SeekerNotificationDetailTypeEnum'], ParentType, ContextType>
  vacancyCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type KeywordResolvers<ContextType = any, ParentType extends ResolversParentTypes['Keyword'] = ResolversParentTypes['Keyword']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isProfession?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  statistic?: Resolver<Maybe<ResolversTypes['StatisticData']>, ParentType, ContextType, RequireFields<KeywordStatisticArgs, 'input' | 'withoutChildKeywords'>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LangResolvers<ContextType = any, ParentType extends ResolversParentTypes['Lang'] = ResolversParentTypes['Lang']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LangSuggestResolvers<ContextType = any, ParentType extends ResolversParentTypes['LangSuggest'] = ResolversParentTypes['LangSuggest']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LanguageBasicQuestionResolvers<ContextType = any, ParentType extends ResolversParentTypes['LanguageBasicQuestion'] = ResolversParentTypes['LanguageBasicQuestion']> = {
  defaultText?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  languages?: Resolver<ReadonlyArray<ResolversTypes['BasicQuestionItem']>, ParentType, ContextType>
  skills?: Resolver<ReadonlyArray<ResolversTypes['BasicQuestionItem']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LanguageSkillLevelResolvers<ContextType = any, ParentType extends ResolversParentTypes['LanguageSkillLevel'] = ResolversParentTypes['LanguageSkillLevel']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LetterFiltersResolvers<ContextType = any, ParentType extends ResolversParentTypes['LetterFilters'] = ResolversParentTypes['LetterFilters']> = {
  showButtons?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  showDates?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  showPerson?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LimitsChangeRequestedDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['LimitsChangeRequestedDetails'] = ResolversParentTypes['LimitsChangeRequestedDetails']> = {
  detailType?: Resolver<ResolversTypes['SeekerNotificationDetailTypeEnum'], ParentType, ContextType>
  employeeSnapshot?: Resolver<ResolversTypes['NotificationEmployeeSnapshot'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LimitsFiltersResolvers<ContextType = any, ParentType extends ResolversParentTypes['LimitsFilters'] = ResolversParentTypes['LimitsFilters']> = {
  giftIds?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>
  managerIds?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ListSeekerDislikedVacanciesOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ListSeekerDislikedVacanciesOutput'] = ResolversParentTypes['ListSeekerDislikedVacanciesOutput']
> = {
  vacancies?: Resolver<ReadonlyArray<ResolversTypes['Vacancy']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ListSeekerFavoriteVacanciesOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ListSeekerFavoriteVacanciesOutput'] = ResolversParentTypes['ListSeekerFavoriteVacanciesOutput']
> = {
  vacancies?: Resolver<ReadonlyArray<ResolversTypes['Vacancy']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LoginMutationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['LoginMutations'] = ResolversParentTypes['LoginMutations']> = {
  otpLogin?: Resolver<ResolversTypes['LoginWithOtpMutations'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LoginWithOtpMutationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['LoginWithOtpMutations'] = ResolversParentTypes['LoginWithOtpMutations']> = {
  confirmCode?: Resolver<ResolversTypes['ConfirmedPhoneUsersOutput'], ParentType, ContextType, RequireFields<LoginWithOtpMutationsConfirmCodeArgs, 'code' | 'phone'>>
  sendConfirmation?: Resolver<ResolversTypes['SendSmsOutput'], ParentType, ContextType, RequireFields<LoginWithOtpMutationsSendConfirmationArgs, 'phone'>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LogoInPortalCatalogServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['LogoInPortalCatalogService'] = ResolversParentTypes['LogoInPortalCatalogService']> = {
  calculatePrice?: Resolver<ResolversTypes['ServicePrice'], ParentType, ContextType, Partial<LogoInPortalCatalogServiceCalculatePriceArgs>>
  groupWrapper?: Resolver<ResolversTypes['ServiceGroupWrapper'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  typeWrapper?: Resolver<ResolversTypes['ServiceTypeWrapper'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LogoInPortalServiceEmployerEventSnapshotResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LogoInPortalServiceEmployerEventSnapshot'] = ResolversParentTypes['LogoInPortalServiceEmployerEventSnapshot']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  catalogService?: Resolver<ResolversTypes['CatalogService'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LogoInRubricCatalogServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['LogoInRubricCatalogService'] = ResolversParentTypes['LogoInRubricCatalogService']> = {
  calculatePrice?: Resolver<ResolversTypes['ServicePrice'], ParentType, ContextType, Partial<LogoInRubricCatalogServiceCalculatePriceArgs>>
  groupWrapper?: Resolver<ResolversTypes['ServiceGroupWrapper'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  typeWrapper?: Resolver<ResolversTypes['ServiceTypeWrapper'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LogoInRubricServiceEmployerEventSnapshotResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LogoInRubricServiceEmployerEventSnapshot'] = ResolversParentTypes['LogoInRubricServiceEmployerEventSnapshot']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  catalogService?: Resolver<ResolversTypes['CatalogService'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  rubric?: Resolver<ResolversTypes['Rubric'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LogoOnMainPageCatalogServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['LogoOnMainPageCatalogService'] = ResolversParentTypes['LogoOnMainPageCatalogService']> = {
  calculatePrice?: Resolver<ResolversTypes['ServicePrice'], ParentType, ContextType, Partial<LogoOnMainPageCatalogServiceCalculatePriceArgs>>
  groupWrapper?: Resolver<ResolversTypes['ServiceGroupWrapper'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  typeWrapper?: Resolver<ResolversTypes['ServiceTypeWrapper'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LogoOnMainPageServiceEmployerEventSnapshotResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LogoOnMainPageServiceEmployerEventSnapshot'] = ResolversParentTypes['LogoOnMainPageServiceEmployerEventSnapshot']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  catalogService?: Resolver<ResolversTypes['CatalogService'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LogoPortalResolvers<ContextType = any, ParentType extends ResolversParentTypes['LogoPortal'] = ResolversParentTypes['LogoPortal']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LogoPortalBranchResolvers<ContextType = any, ParentType extends ResolversParentTypes['LogoPortalBranch'] = ResolversParentTypes['LogoPortalBranch']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface LongScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Long'], any> {
  name: 'Long'
}

export type MadeHotVacancyEmployerEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['MadeHotVacancyEmployerEvent'] = ResolversParentTypes['MadeHotVacancyEmployerEvent']> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  durationInDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  endsAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  vacancyCity?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  vacancyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  vacancyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MailingStatisticsResolvers<ContextType = any, ParentType extends ResolversParentTypes['MailingStatistics'] = ResolversParentTypes['MailingStatistics']> = {
  email?: Resolver<Maybe<ResolversTypes['EmailStatistics']>, ParentType, ContextType>
  mobilePush?: Resolver<Maybe<ResolversTypes['MobilePushStatistics']>, ParentType, ContextType>
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ManagerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Manager'] = ResolversParentTypes['Manager']> = {
  contacts?: Resolver<Maybe<ResolversTypes['ManagerContacts']>, ParentType, ContextType>
  department?: Resolver<ResolversTypes['ManagerDepartment'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  imageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ManagerContactsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagerContacts'] = ResolversParentTypes['ManagerContacts']> = {
  additionalPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  internalPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  mobilePhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  publicPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  skype?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ManagerDealsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagerDeals'] = ResolversParentTypes['ManagerDeals']> = {
  accountFind?: Resolver<ReadonlyArray<ResolversTypes['CrmAccount']>, ParentType, ContextType, RequireFields<ManagerDealsAccountFindArgs, 'query'>>
  deal?: Resolver<ResolversTypes['DealDetailsOutput'], ParentType, ContextType, RequireFields<ManagerDealsDealArgs, 'dealId'>>
  dealTable?: Resolver<ResolversTypes['DealTableOutput'], ParentType, ContextType, RequireFields<ManagerDealsDealTableArgs, 'input'>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ManagerDepartmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagerDepartment'] = ResolversParentTypes['ManagerDepartment']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ManagerDiscountResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagerDiscount'] = ResolversParentTypes['ManagerDiscount']> = {
  manager?: Resolver<ResolversTypes['Manager'], ParentType, ContextType>
  sum?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ManagerEventPerformerResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagerEventPerformer'] = ResolversParentTypes['ManagerEventPerformer']> = {
  manager?: Resolver<Maybe<ResolversTypes['Manager']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ManagerGiftLimitResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagerGiftLimit'] = ResolversParentTypes['ManagerGiftLimit']> = {
  giftName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  limitCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  occasion?: Resolver<ResolversTypes['GiftOccasion'], ParentType, ContextType>
  wave?: Resolver<Maybe<ResolversTypes['GiftOccasion']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ManagerWalletUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManagerWalletUser'] = ResolversParentTypes['ManagerWalletUser']> = {
  manager?: Resolver<ResolversTypes['Manager'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MarketingCatalogServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['MarketingCatalogService'] = ResolversParentTypes['MarketingCatalogService']> = {
  calculatePrice?: Resolver<ResolversTypes['ServicePrice'], ParentType, ContextType, Partial<MarketingCatalogServiceCalculatePriceArgs>>
  groupWrapper?: Resolver<ResolversTypes['ServiceGroupWrapper'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  typeWrapper?: Resolver<ResolversTypes['ServiceTypeWrapper'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MarketingGiftResolvers<ContextType = any, ParentType extends ResolversParentTypes['MarketingGift'] = ResolversParentTypes['MarketingGift']> = {
  allowedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  giftType?: Resolver<ResolversTypes['GiftTypeEnum'], ParentType, ContextType>
  giftTypeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MarketingGiftRequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['MarketingGiftRequest'] = ResolversParentTypes['MarketingGiftRequest']> = {
  addDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  details?: Resolver<ReadonlyArray<ResolversTypes['MarketingGiftRequestDetail']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  marketingEventName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MarketingGiftRequestConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MarketingGiftRequestConnection'] = ResolversParentTypes['MarketingGiftRequestConnection']
> = {
  edges?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['MarketingGiftRequestEdge']>>>, ParentType, ContextType>
  items?: Resolver<Maybe<ReadonlyArray<ResolversTypes['MarketingGiftRequest']>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MarketingGiftRequestDetailResolvers<ContextType = any, ParentType extends ResolversParentTypes['MarketingGiftRequestDetail'] = ResolversParentTypes['MarketingGiftRequestDetail']> = {
  gift?: Resolver<ResolversTypes['Gift'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MarketingGiftRequestEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['MarketingGiftRequestEdge'] = ResolversParentTypes['MarketingGiftRequestEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['MarketingGiftRequest'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MarketingLimitTransferResolvers<ContextType = any, ParentType extends ResolversParentTypes['MarketingLimitTransfer'] = ResolversParentTypes['MarketingLimitTransfer']> = {
  gifts?: Resolver<ReadonlyArray<ResolversTypes['MarketingLimitTransferDetail']>, ParentType, ContextType>
  manager?: Resolver<ResolversTypes['CrmManager'], ParentType, ContextType>
  occasion?: Resolver<ResolversTypes['GiftOccasion'], ParentType, ContextType>
  transferDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  wave?: Resolver<Maybe<ResolversTypes['GiftOccasion']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MarketingLimitTransferConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MarketingLimitTransferConnection'] = ResolversParentTypes['MarketingLimitTransferConnection']
> = {
  edges?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['MarketingLimitTransferEdge']>>>, ParentType, ContextType>
  items?: Resolver<Maybe<ReadonlyArray<ResolversTypes['MarketingLimitTransfer']>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MarketingLimitTransferDetailResolvers<ContextType = any, ParentType extends ResolversParentTypes['MarketingLimitTransferDetail'] = ResolversParentTypes['MarketingLimitTransferDetail']> = {
  currentCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  transferedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MarketingLimitTransferEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['MarketingLimitTransferEdge'] = ResolversParentTypes['MarketingLimitTransferEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['MarketingLimitTransfer'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MarketingServiceEmployerEventSnapshotResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MarketingServiceEmployerEventSnapshot'] = ResolversParentTypes['MarketingServiceEmployerEventSnapshot']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  catalogService?: Resolver<ResolversTypes['CatalogService'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MassRefuseOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['MassRefuseOutput'] = ResolversParentTypes['MassRefuseOutput']> = {
  applies?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Apply']>>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MessagesConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['MessagesConnection'] = ResolversParentTypes['MessagesConnection']> = {
  edges?: Resolver<Maybe<ReadonlyArray<ResolversTypes['MessagesEdge']>>, ParentType, ContextType>
  nodes?: Resolver<Maybe<ReadonlyArray<ResolversTypes['ChatMessage']>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MessagesEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['MessagesEdge'] = ResolversParentTypes['MessagesEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['ChatMessage'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MessengerContactResolvers<ContextType = any, ParentType extends ResolversParentTypes['MessengerContact'] = ResolversParentTypes['MessengerContact']> = {
  messenger?: Resolver<ResolversTypes['MessengerEnum'], ParentType, ContextType>
  userIdentifier?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MetanotebookAssignedEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['MetanotebookAssignedEvent'] = ResolversParentTypes['MetanotebookAssignedEvent']> = {
  addDate?: Resolver<ResolversTypes['DateTimeOffset'], ParentType, ContextType>
  assignment?: Resolver<Maybe<ResolversTypes['Assignment']>, ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['CrmCompany']>, ParentType, ContextType>
  daysLeft?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  employee?: Resolver<Maybe<ResolversTypes['CrmEmployee']>, ParentType, ContextType>
  eventType?: Resolver<ResolversTypes['EventTypeEnum'], ParentType, ContextType>
  expirationDate?: Resolver<Maybe<ResolversTypes['DateTimeOffset']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isArchieved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isRead?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  lifetimeDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  state?: Resolver<ResolversTypes['EventStateEnum'], ParentType, ContextType>
  topics?: Resolver<ReadonlyArray<ResolversTypes['Topic']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MetroResolvers<ContextType = any, ParentType extends ResolversParentTypes['Metro'] = ResolversParentTypes['Metro']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  line?: Resolver<ResolversTypes['MetroLine'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MetroFacetResolvers<ContextType = any, ParentType extends ResolversParentTypes['MetroFacet'] = ResolversParentTypes['MetroFacet']> = {
  count?: Resolver<ResolversTypes['Long'], ParentType, ContextType>
  metro?: Resolver<ResolversTypes['Metro'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MetroLineResolvers<ContextType = any, ParentType extends ResolversParentTypes['MetroLine'] = ResolversParentTypes['MetroLine']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MetroLineFacetResolvers<ContextType = any, ParentType extends ResolversParentTypes['MetroLineFacet'] = ResolversParentTypes['MetroLineFacet']> = {
  count?: Resolver<ResolversTypes['Long'], ParentType, ContextType>
  metroLine?: Resolver<ResolversTypes['MetroLine'], ParentType, ContextType>
  metroStationsFacets?: Resolver<ReadonlyArray<ResolversTypes['MetroFacet']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MobilePushStatisticsResolvers<ContextType = any, ParentType extends ResolversParentTypes['MobilePushStatistics'] = ResolversParentTypes['MobilePushStatistics']> = {
  appliesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  clicks?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  deliveries?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ModifyVacancyErrorUnionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ModifyVacancyErrorUnion'] = ResolversParentTypes['ModifyVacancyErrorUnion']> = {
  __resolveType: TypeResolveFn<
    'CompanyWasMergedError' | 'InvalidError' | 'NoAvailableLimitsError' | 'ServerError' | 'TurbotaWasExpiredError' | 'UserIsNotAuthenticatedError' | 'UserIsNotEmployerError',
    ParentType,
    ContextType
  >
}

export type MovingGiftLimitsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['MovingGiftLimitsOutput'] = ResolversParentTypes['MovingGiftLimitsOutput']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  messageText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  acquaintWithNotifications?: Resolver<ResolversTypes['AcquaintWithNotificationsOutput'], ParentType, ContextType>
  activateOrderByTurbota?: Resolver<ResolversTypes['OrderTurbotaActivationOutput'], ParentType, ContextType, RequireFields<MutationActivateOrderByTurbotaArgs, 'input'>>
  addGamificationGiftLimits?: Resolver<ResolversTypes['AddGamificationLimitsOutput'], ParentType, ContextType, RequireFields<MutationAddGamificationGiftLimitsArgs, 'input'>>
  addGiftRequest?: Resolver<Maybe<ResolversTypes['AddGiftRequestOutput']>, ParentType, ContextType, RequireFields<MutationAddGiftRequestArgs, 'input'>>
  addSeekerProfResumeAdditionalEducations?: Resolver<
    ResolversTypes['AddedProfResumeAdditionalEducationsOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationAddSeekerProfResumeAdditionalEducationsArgs, 'input'>
  >
  addSeekerProfResumeAdditionalInfo?: Resolver<ResolversTypes['AddedProfResumeAdditionalInfoOutput'], ParentType, ContextType, RequireFields<MutationAddSeekerProfResumeAdditionalInfoArgs, 'input'>>
  addSeekerProfResumeEducations?: Resolver<ResolversTypes['AddedProfResumeEducationsOutput'], ParentType, ContextType, RequireFields<MutationAddSeekerProfResumeEducationsArgs, 'input'>>
  addSeekerProfResumeExperiences?: Resolver<ResolversTypes['AddedProfResumeExperiencesOutput'], ParentType, ContextType, RequireFields<MutationAddSeekerProfResumeExperiencesArgs, 'input'>>
  applyAddCompanyAddress?: Resolver<Maybe<ResolversTypes['ApplyAddCompanyAddressOutput']>, ParentType, ContextType, RequireFields<MutationApplyAddCompanyAddressArgs, 'input'>>
  applyAddNote?: Resolver<Maybe<ResolversTypes['ApplyAddNoteOutput']>, ParentType, ContextType, RequireFields<MutationApplyAddNoteArgs, 'input'>>
  applyChangeStatus?: Resolver<Maybe<ResolversTypes['ApplyChangeStatusOutput']>, ParentType, ContextType, RequireFields<MutationApplyChangeStatusArgs, 'input'>>
  applyChangeStatuses?: Resolver<Maybe<ResolversTypes['ApplyChangeStatusesOutput']>, ParentType, ContextType, RequireFields<MutationApplyChangeStatusesArgs, 'input'>>
  applyDeleteCompanyAddress?: Resolver<Maybe<ResolversTypes['ApplyDeleteCompanyAddressOutput']>, ParentType, ContextType, RequireFields<MutationApplyDeleteCompanyAddressArgs, 'input'>>
  applyDeleteNote?: Resolver<Maybe<ResolversTypes['ApplyDeleteNoteOutput']>, ParentType, ContextType, RequireFields<MutationApplyDeleteNoteArgs, 'input'>>
  applyEditNote?: Resolver<Maybe<ResolversTypes['ApplyEditNoteOutput']>, ParentType, ContextType, RequireFields<MutationApplyEditNoteArgs, 'input'>>
  applyInvite?: Resolver<Maybe<ResolversTypes['ApplyInviteOutput']>, ParentType, ContextType, RequireFields<MutationApplyInviteArgs, 'input'>>
  applyMassRefuse?: Resolver<Maybe<ResolversTypes['MassRefuseOutput']>, ParentType, ContextType, RequireFields<MutationApplyMassRefuseArgs, 'input'>>
  applyRefuse?: Resolver<Maybe<ResolversTypes['ApplyRefuseOutput']>, ParentType, ContextType, RequireFields<MutationApplyRefuseArgs, 'input'>>
  applyToAtsExport?: Resolver<ResolversTypes['ApplyToAtsExportOutput'], ParentType, ContextType, RequireFields<MutationApplyToAtsExportArgs, 'input'>>
  applyUpdateCompanyAddress?: Resolver<Maybe<ResolversTypes['ApplyUpdateCompanyAddressOutput']>, ParentType, ContextType, RequireFields<MutationApplyUpdateCompanyAddressArgs, 'input'>>
  cart?: Resolver<ResolversTypes['CartMutation'], ParentType, ContextType>
  categorizationAttaches?: Resolver<ResolversTypes['CategorizationAttachesMutations'], ParentType, ContextType>
  changeUserServiceLimit?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType, RequireFields<MutationChangeUserServiceLimitArgs, 'input'>>
  clearAllProzoraClientFilters?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  clearCabinetEventsClientFilters?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  clearCompanyPublishedVacanciesClientFilters?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  clearPublishedVacanciesClientFilters?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  clearServicesManagementFilters?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  clearTargetMailingListClientFilters?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  clearUsersManagementFilters?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  clearVacanciesPageFilters?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  closeBanner?: Resolver<ResolversTypes['CloseBannerResponse'], ParentType, ContextType, RequireFields<MutationCloseBannerArgs, 'id'>>
  clubs?: Resolver<ResolversTypes['ClubMutations'], ParentType, ContextType>
  companyApplyAutoReplyTemplateConfigurationUpdate?: Resolver<
    ResolversTypes['CompanyApplyAutoReplyTemplateConfigurationUpdateOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationCompanyApplyAutoReplyTemplateConfigurationUpdateArgs, 'input'>
  >
  companyAssignments?: Resolver<ResolversTypes['CompanyAssignmentsMutations'], ParentType, ContextType>
  companyChangeInvoicesPayer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCompanyChangeInvoicesPayerArgs, 'newPayerName'>>
  companyEditInfo?: Resolver<ResolversTypes['CompanyEditOutput'], ParentType, ContextType, RequireFields<MutationCompanyEditInfoArgs, 'input'>>
  companyEmailResendConfirmation?: Resolver<ResolversTypes['CompanyEmailResendConfirmationOutput'], ParentType, ContextType, RequireFields<MutationCompanyEmailResendConfirmationArgs, 'input'>>
  companySendJoinRequest?: Resolver<ResolversTypes['CompanySendJoinRequestOutput'], ParentType, ContextType, RequireFields<MutationCompanySendJoinRequestArgs, 'companyId'>>
  companyTrackView?: Resolver<Maybe<ResolversTypes['TrackCompanyViewOutput']>, ParentType, ContextType, RequireFields<MutationCompanyTrackViewArgs, 'companyId'>>
  companyVerification?: Resolver<ResolversTypes['CompanyVerificationOutput'], ParentType, ContextType, RequireFields<MutationCompanyVerificationArgs, 'input'>>
  completeAssignment?: Resolver<ResolversTypes['AssignmentOperationOutput'], ParentType, ContextType, RequireFields<MutationCompleteAssignmentArgs, 'input'>>
  createAssignment?: Resolver<ResolversTypes['AssignmentOperationOutput'], ParentType, ContextType, RequireFields<MutationCreateAssignmentArgs, 'input'>>
  createEmptyProfResume?: Resolver<ResolversTypes['CreatedEmptyProfResumeOutput'], ParentType, ContextType>
  createGroupNotification?: Resolver<ResolversTypes['CreateGroupNotificationOutput'], ParentType, ContextType, RequireFields<MutationCreateGroupNotificationArgs, 'input'>>
  createInvoice?: Resolver<ResolversTypes['CreateInvoiceOutput'], ParentType, ContextType, RequireFields<MutationCreateInvoiceArgs, 'input'>>
  createOrderFromServices?: Resolver<ResolversTypes['CreateOrderFromServicesOutput'], ParentType, ContextType, RequireFields<MutationCreateOrderFromServicesArgs, 'input'>>
  createProfResume?: Resolver<ResolversTypes['CreatedProfResumeOutput'], ParentType, ContextType, RequireFields<MutationCreateProfResumeArgs, 'input'>>
  createProfResumeAsCopy?: Resolver<ResolversTypes['CreatedAsCopyProfResumeOutput'], ParentType, ContextType, RequireFields<MutationCreateProfResumeAsCopyArgs, 'input'>>
  createProfResumeFromAttach?: Resolver<ResolversTypes['CreateProfResumeFromAttachOutput'], ParentType, ContextType, RequireFields<MutationCreateProfResumeFromAttachArgs, 'input'>>
  createRecruitmentOrder?: Resolver<ResolversTypes['CreateRecruitmentOrderOutput'], ParentType, ContextType, RequireFields<MutationCreateRecruitmentOrderArgs, 'input'>>
  createSharingDocumentsDeepLink?: Resolver<ResolversTypes['CreateSharingDocumentsDeepLinkOutput'], ParentType, ContextType, RequireFields<MutationCreateSharingDocumentsDeepLinkArgs, 'input'>>
  createUserNotification?: Resolver<ResolversTypes['CreateUserNotificationOutput'], ParentType, ContextType, RequireFields<MutationCreateUserNotificationArgs, 'input'>>
  createWallet?: Resolver<ResolversTypes['CreateWalletOutput'], ParentType, ContextType, RequireFields<MutationCreateWalletArgs, 'input'>>
  createWalletTransaction?: Resolver<ResolversTypes['CreateWalletTransactionOutput'], ParentType, ContextType, RequireFields<MutationCreateWalletTransactionArgs, 'input'>>
  crmActionAdd?: Resolver<Maybe<ResolversTypes['CrmActionAddResult']>, ParentType, ContextType, RequireFields<MutationCrmActionAddArgs, 'input'>>
  crmActionOperation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationCrmActionOperationArgs, 'input'>>
  crmActionUpdate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationCrmActionUpdateArgs, 'input'>>
  crmAddUserCard?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationCrmAddUserCardArgs, 'input'>>
  crmGifts?: Resolver<ResolversTypes['GiftsMutations'], ParentType, ContextType>
  crmLinkActionToCall?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationCrmLinkActionToCallArgs, 'actionId' | 'callId'>>
  crmLinkPhoneToUserCard?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationCrmLinkPhoneToUserCardArgs, 'input'>>
  crmLoginToNotebook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationCrmLoginToNotebookArgs, 'userCardId'>>
  crmPinAction?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationCrmPinActionArgs, 'input'>>
  crmRemoveAttach?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationCrmRemoveAttachArgs, 'input'>>
  crmSendOrderEmail?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationCrmSendOrderEmailArgs, 'input'>>
  crmSendOrderEmailExt?: Resolver<Maybe<ResolversTypes['EmailSenderResult']>, ParentType, ContextType, RequireFields<MutationCrmSendOrderEmailExtArgs, 'input'>>
  crmSetActivationDate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationCrmSetActivationDateArgs, 'date' | 'orderDetailId'>>
  crmUpdateUserCard?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationCrmUpdateUserCardArgs, 'input'>>
  deleteAllNotifications?: Resolver<ResolversTypes['DeleteAllNotificationsOutput'], ParentType, ContextType>
  deleteAssignment?: Resolver<ResolversTypes['AssignmentOperationOutput'], ParentType, ContextType, RequireFields<MutationDeleteAssignmentArgs, 'id'>>
  deleteNotifications?: Resolver<ResolversTypes['DeleteNotificationsOutput'], ParentType, ContextType, RequireFields<MutationDeleteNotificationsArgs, 'input'>>
  deleteSeekerProfResume?: Resolver<ResolversTypes['DeletedProfResumeOutput'], ParentType, ContextType, RequireFields<MutationDeleteSeekerProfResumeArgs, 'input'>>
  deleteSeekerProfResumeAdditionalEducations?: Resolver<
    ResolversTypes['DeletedProfResumeAdditionalEducationsOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteSeekerProfResumeAdditionalEducationsArgs, 'input'>
  >
  deleteSeekerProfResumeAdditionalInfo?: Resolver<
    ResolversTypes['DeletedProfResumeAdditionalInfoOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteSeekerProfResumeAdditionalInfoArgs, 'input'>
  >
  deleteSeekerProfResumeAdditionalPhones?: Resolver<
    ResolversTypes['DeleteSeekerProfResumeAdditionalPhonesOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteSeekerProfResumeAdditionalPhonesArgs, 'input'>
  >
  deleteSeekerProfResumeDiiaCertificate?: Resolver<
    ResolversTypes['ProfResumeDeleteDiiaCertificateOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteSeekerProfResumeDiiaCertificateArgs, 'input'>
  >
  deleteSeekerProfResumeEducations?: Resolver<ResolversTypes['DeletedProfResumeEducationsOutput'], ParentType, ContextType, RequireFields<MutationDeleteSeekerProfResumeEducationsArgs, 'input'>>
  deleteSeekerProfResumeExperiences?: Resolver<ResolversTypes['DeletedProfResumeExperiencesOutput'], ParentType, ContextType, RequireFields<MutationDeleteSeekerProfResumeExperiencesArgs, 'input'>>
  deleteSeekerProfResumeLanguageSkills?: Resolver<
    ResolversTypes['DeletedProfResumeLanguageSkillsOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteSeekerProfResumeLanguageSkillsArgs, 'input'>
  >
  deleteSeekerProfResumePortfolios?: Resolver<ResolversTypes['DeleteProfResumePortfolioOutput'], ParentType, ContextType, RequireFields<MutationDeleteSeekerProfResumePortfoliosArgs, 'input'>>
  deleteSeekerProfResumeSocialNetworks?: Resolver<
    ResolversTypes['DeleteProfResumeSocialNetworksOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteSeekerProfResumeSocialNetworksArgs, 'input'>
  >
  deleteUserCard?: Resolver<ResolversTypes['UserCardDeleteOutput'], ParentType, ContextType, RequireFields<MutationDeleteUserCardArgs, 'id'>>
  employeeChangeEmailConfirm?: Resolver<ResolversTypes['EmployeeChangeEmailOutput'], ParentType, ContextType, RequireFields<MutationEmployeeChangeEmailConfirmArgs, 'input'>>
  employeeChangePasswordConfirm?: Resolver<ResolversTypes['EmployeeChangeOutput'], ParentType, ContextType, RequireFields<MutationEmployeeChangePasswordConfirmArgs, 'input'>>
  employeeCreate?: Resolver<ResolversTypes['EmployeeCreateOutput'], ParentType, ContextType, RequireFields<MutationEmployeeCreateArgs, 'input'>>
  employeeCvDbSavedSearchDelete?: Resolver<ResolversTypes['EmployeeCvDbSavedSearchDeleteOutput'], ParentType, ContextType, RequireFields<MutationEmployeeCvDbSavedSearchDeleteArgs, 'input'>>
  employeeEdit?: Resolver<ResolversTypes['EmployeeEditOutput'], ParentType, ContextType, RequireFields<MutationEmployeeEditArgs, 'input'>>
  employeeEditPersonalInfo?: Resolver<ResolversTypes['EmployeeEditOutput'], ParentType, ContextType, RequireFields<MutationEmployeeEditPersonalInfoArgs, 'input'>>
  employeeJoiningReview?: Resolver<ResolversTypes['EmployeeJoiningReviewOutput'], ParentType, ContextType, Partial<MutationEmployeeJoiningReviewArgs>>
  employeeResendInvitation?: Resolver<ResolversTypes['EmployeeResendInvitationOutput'], ParentType, ContextType, Partial<MutationEmployeeResendInvitationArgs>>
  employeeSendChangeEmail?: Resolver<ResolversTypes['EmployeeChangeOutput'], ParentType, ContextType, RequireFields<MutationEmployeeSendChangeEmailArgs, 'input'>>
  employeeSendChangePassword?: Resolver<ResolversTypes['EmployeeChangeOutput'], ParentType, ContextType>
  employeeSetAsAdmin?: Resolver<ResolversTypes['EmployeeMakeMainOutput'], ParentType, ContextType, RequireFields<MutationEmployeeSetAsAdminArgs, 'input'>>
  employeesBlock?: Resolver<ResolversTypes['EmployeesBlockOutput'], ParentType, ContextType, RequireFields<MutationEmployeesBlockArgs, 'input'>>
  employeesDelete?: Resolver<ResolversTypes['EmployeesDeleteOutput'], ParentType, ContextType, RequireFields<MutationEmployeesDeleteArgs, 'input'>>
  employeesScopesMultiUpdate?: Resolver<ResolversTypes['EmployeesMultiScopesUpdateOutput'], ParentType, ContextType, RequireFields<MutationEmployeesScopesMultiUpdateArgs, 'input'>>
  employeesUnblock?: Resolver<ResolversTypes['EmployeesUnblockOutput'], ParentType, ContextType, RequireFields<MutationEmployeesUnblockArgs, 'input'>>
  employerSubscriptions?: Resolver<ResolversTypes['EmployeeSubscriptionsMutations'], ParentType, ContextType>
  feedback?: Resolver<ResolversTypes['FeedbackMutations'], ParentType, ContextType>
  movingGiftLimits?: Resolver<ResolversTypes['MovingGiftLimitsOutput'], ParentType, ContextType, RequireFields<MutationMovingGiftLimitsArgs, 'input'>>
  novaPoshtaAddAddress?: Resolver<Maybe<ResolversTypes['NovaPoshtaAddGiftAddressOutput']>, ParentType, ContextType, RequireFields<MutationNovaPoshtaAddAddressArgs, 'input'>>
  novaPoshtaAddCounterparty?: Resolver<Maybe<ResolversTypes['NovaPoshtaAddCounterpartyOutput']>, ParentType, ContextType, RequireFields<MutationNovaPoshtaAddCounterpartyArgs, 'input'>>
  novaPoshtaAddDocument?: Resolver<ResolversTypes['NovaPoshtaAddDocumentOutput'], ParentType, ContextType, RequireFields<MutationNovaPoshtaAddDocumentArgs, 'input'>>
  novaPoshtaAddDocumentsToRegister?: Resolver<ResolversTypes['NovaPoshtaAddDocumentToRegisterOutput'], ParentType, ContextType, RequireFields<MutationNovaPoshtaAddDocumentsToRegisterArgs, 'input'>>
  novaPoshtaDeleteDocument?: Resolver<ResolversTypes['NovaPoshtaDeleteDocumentOutput'], ParentType, ContextType, RequireFields<MutationNovaPoshtaDeleteDocumentArgs, 'id'>>
  novaPoshtaUpdateDocument?: Resolver<ResolversTypes['NovaPoshtaAddDocumentOutput'], ParentType, ContextType, RequireFields<MutationNovaPoshtaUpdateDocumentArgs, 'input'>>
  offerVacancyToApply?: Resolver<ResolversTypes['OfferVacancyToApplyOutput'], ParentType, ContextType, RequireFields<MutationOfferVacancyToApplyArgs, 'input'>>
  promoCodes?: Resolver<ResolversTypes['PromoCodeMutation'], ParentType, ContextType>
  publishSeekerProfResume?: Resolver<ResolversTypes['PublishedProfResumeOutput'], ParentType, ContextType, RequireFields<MutationPublishSeekerProfResumeArgs, 'input'>>
  questionnaireCopy?: Resolver<ResolversTypes['QuestionnaireCopyOutput'], ParentType, ContextType, RequireFields<MutationQuestionnaireCopyArgs, 'input'>>
  questionnaireCreate?: Resolver<ResolversTypes['QuestionnaireCreateOutput'], ParentType, ContextType, RequireFields<MutationQuestionnaireCreateArgs, 'input'>>
  questionnaireDelete?: Resolver<ResolversTypes['QuestionnaireDeleteOutput'], ParentType, ContextType, RequireFields<MutationQuestionnaireDeleteArgs, 'id'>>
  questionnaireGiveAnswers?: Resolver<ResolversTypes['QuestionnaireGiveAnswersOutput'], ParentType, ContextType, RequireFields<MutationQuestionnaireGiveAnswersArgs, 'input'>>
  questionnaireUpdate?: Resolver<ResolversTypes['QuestionnaireUpdateOutput'], ParentType, ContextType, RequireFields<MutationQuestionnaireUpdateArgs, 'input'>>
  removeAssignmentAttach?: Resolver<ResolversTypes['AssignmentOperationOutput'], ParentType, ContextType, RequireFields<MutationRemoveAssignmentAttachArgs, 'attachId'>>
  requestLimits?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationRequestLimitsArgs, 'input'>>
  saleManager?: Resolver<ResolversTypes['SaleManagerMutations'], ParentType, ContextType>
  savedCards?: Resolver<ResolversTypes['SavedCardsMutations'], ParentType, ContextType>
  seekerDislikedVacancies?: Resolver<ResolversTypes['SeekerDislikedVacancyMutations'], ParentType, ContextType>
  seekerFavoriteVacancies?: Resolver<ResolversTypes['SeekerFavoriteVacancyMutations'], ParentType, ContextType>
  seekerSubscriptions?: Resolver<ResolversTypes['SeekerSubscriptionMutations'], ParentType, ContextType>
  serviceActivate?: Resolver<Maybe<ResolversTypes['ServiceActivationOutput']>, ParentType, ContextType, RequireFields<MutationServiceActivateArgs, 'input'>>
  setActionFilters?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationSetActionFiltersArgs>>
  setActionState?: Resolver<ResolversTypes['SetActionStateOutput'], ParentType, ContextType, RequireFields<MutationSetActionStateArgs, 'ids' | 'state'>>
  setBillFilters?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationSetBillFiltersArgs>>
  setCabinetEventsClientFilters?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSetCabinetEventsClientFiltersArgs, 'input'>>
  setCabinetEventsClientPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSetCabinetEventsClientPageArgs, 'page'>>
  setCallFilters?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationSetCallFiltersArgs>>
  setCompanyPublishedVacanciesClientFilters?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSetCompanyPublishedVacanciesClientFiltersArgs, 'input'>>
  setCompanyPublishedVacanciesClientPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSetCompanyPublishedVacanciesClientPageArgs, 'page'>>
  setElectedUserCard?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationSetElectedUserCardArgs, 'input'>>
  setGiftRegistersScrollInfo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationSetGiftRegistersScrollInfoArgs, 'hasNextOffset'>>
  setGiftsFilters?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationSetGiftsFiltersArgs>>
  setGiftsManagerScrollInfo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationSetGiftsManagerScrollInfoArgs, 'hasNextOffset'>>
  setGiftsMarketingBySelfScrollInfo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationSetGiftsMarketingBySelfScrollInfoArgs, 'hasNextOffset'>>
  setGiftsMarketingNovaPoshtaScrollInfo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationSetGiftsMarketingNovaPoshtaScrollInfoArgs, 'hasNextOffset'>>
  setLetterFilters?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationSetLetterFiltersArgs, 'showButtons' | 'showDates' | 'showPerson'>>
  setLimitsFilters?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationSetLimitsFiltersArgs>>
  setProzoraClientFilters?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationSetProzoraClientFiltersArgs, 'input'>>
  setPublishedVacanciesClientFilters?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSetPublishedVacanciesClientFiltersArgs, 'input'>>
  setPublishedVacanciesClientPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSetPublishedVacanciesClientPageArgs, 'page'>>
  setPublishedVacanciesClientSort?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSetPublishedVacanciesClientSortArgs, 'sort'>>
  setServicesFilters?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationSetServicesFiltersArgs>>
  setServicesManagementFilters?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationSetServicesManagementFiltersArgs, 'input'>>
  setStateGiftRequest?: Resolver<Maybe<ResolversTypes['GiftRequest']>, ParentType, ContextType, RequireFields<MutationSetStateGiftRequestArgs, 'input'>>
  setTargetMailingListClientFilters?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSetTargetMailingListClientFiltersArgs, 'input'>>
  setTargetMailingListClientPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSetTargetMailingListClientPageArgs, 'page'>>
  setUserState?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationSetUserStateArgs>>
  setUsersManagementFilters?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationSetUsersManagementFiltersArgs, 'input'>>
  setUsersManagementPageInfo?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationSetUsersManagementPageInfoArgs, 'input'>>
  setVacanciesPageFilters?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationSetVacanciesPageFiltersArgs, 'input'>>
  setVacancyCandidateKeywords?: Resolver<ResolversTypes['Vacancy'], ParentType, ContextType, RequireFields<MutationSetVacancyCandidateKeywordsArgs, 'input'>>
  startCall?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationStartCallArgs, 'input'>>
  startGamificationWave?: Resolver<ResolversTypes['StartGamificationWaveOutput'], ParentType, ContextType, RequireFields<MutationStartGamificationWaveArgs, 'input'>>
  targetedMailing?: Resolver<ResolversTypes['TargetedMailingMutations'], ParentType, ContextType>
  unpublishSeekerProfResume?: Resolver<ResolversTypes['UnpublishedProfResumeOutput'], ParentType, ContextType, RequireFields<MutationUnpublishSeekerProfResumeArgs, 'input'>>
  updateAssignment?: Resolver<ResolversTypes['AssignmentOperationOutput'], ParentType, ContextType, RequireFields<MutationUpdateAssignmentArgs, 'input'>>
  updateGiftRequest?: Resolver<Maybe<ResolversTypes['UpdateGiftRequestOutput']>, ParentType, ContextType, RequireFields<MutationUpdateGiftRequestArgs, 'input'>>
  updateSeekerProfResumeAdditionalEducation?: Resolver<
    ResolversTypes['UpdatedProfResumeAdditionalEducationsOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateSeekerProfResumeAdditionalEducationArgs, 'input'>
  >
  updateSeekerProfResumeAdditionalInfo?: Resolver<
    ResolversTypes['UpdatedProfResumeAdditionalInfoOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateSeekerProfResumeAdditionalInfoArgs, 'input'>
  >
  updateSeekerProfResumeAdditionalInfos?: Resolver<
    ResolversTypes['UpdatedProfResumeAdditionalInfosOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateSeekerProfResumeAdditionalInfosArgs, 'input'>
  >
  updateSeekerProfResumeAdditionalPhones?: Resolver<
    ResolversTypes['UpdateProfResumeAdditionalPhonesOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateSeekerProfResumeAdditionalPhonesArgs, 'input'>
  >
  updateSeekerProfResumeAnonymity?: Resolver<ResolversTypes['UpdatedProfResumeAnonymityOutput'], ParentType, ContextType, RequireFields<MutationUpdateSeekerProfResumeAnonymityArgs, 'input'>>
  updateSeekerProfResumeAvailabilityState?: Resolver<
    ResolversTypes['UpdatedProfResumeAvailabilityStateOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateSeekerProfResumeAvailabilityStateArgs, 'input'>
  >
  updateSeekerProfResumeBirthDate?: Resolver<ResolversTypes['UpdatedProfResumeBirthDateOutput'], ParentType, ContextType, RequireFields<MutationUpdateSeekerProfResumeBirthDateArgs, 'input'>>
  updateSeekerProfResumeCity?: Resolver<ResolversTypes['UpdatedProfResumeCityOutput'], ParentType, ContextType, RequireFields<MutationUpdateSeekerProfResumeCityArgs, 'input'>>
  updateSeekerProfResumeDiiaCertificate?: Resolver<
    ResolversTypes['UpdatedProfResumeDiiaCertificateOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateSeekerProfResumeDiiaCertificateArgs, 'input'>
  >
  updateSeekerProfResumeDisability?: Resolver<ResolversTypes['UpdatedProfResumeDisabilityOutput'], ParentType, ContextType, RequireFields<MutationUpdateSeekerProfResumeDisabilityArgs, 'input'>>
  updateSeekerProfResumeEducation?: Resolver<ResolversTypes['UpdatedProfResumeEducationsOutput'], ParentType, ContextType, RequireFields<MutationUpdateSeekerProfResumeEducationArgs, 'input'>>
  updateSeekerProfResumeEmail?: Resolver<ResolversTypes['UpdateProfResumeEmailOutput'], ParentType, ContextType, RequireFields<MutationUpdateSeekerProfResumeEmailArgs, 'input'>>
  updateSeekerProfResumeExperience?: Resolver<ResolversTypes['UpdatedProfResumeExperiencesOutput'], ParentType, ContextType, RequireFields<MutationUpdateSeekerProfResumeExperienceArgs, 'input'>>
  updateSeekerProfResumeFullName?: Resolver<ResolversTypes['UpdatedProfResumeFullNameOutput'], ParentType, ContextType, RequireFields<MutationUpdateSeekerProfResumeFullNameArgs, 'input'>>
  updateSeekerProfResumeGender?: Resolver<ResolversTypes['UpdatedProfResumeGenderOutput'], ParentType, ContextType, RequireFields<MutationUpdateSeekerProfResumeGenderArgs, 'input'>>
  updateSeekerProfResumeHiddenCompanies?: Resolver<
    ResolversTypes['UpdatedProfResumeHiddenCompaniesOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateSeekerProfResumeHiddenCompaniesArgs, 'input'>
  >
  updateSeekerProfResumeLanguageSkills?: Resolver<
    ResolversTypes['UpdatedProfResumeLanguageSkillsOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateSeekerProfResumeLanguageSkillsArgs, 'input'>
  >
  updateSeekerProfResumeMainPhone?: Resolver<ResolversTypes['UpdateProfResumePhoneOutput'], ParentType, ContextType, RequireFields<MutationUpdateSeekerProfResumeMainPhoneArgs, 'input'>>
  updateSeekerProfResumeMilitaryStatus?: Resolver<
    ResolversTypes['UpdatedProfResumeMilitaryStatusOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateSeekerProfResumeMilitaryStatusArgs, 'input'>
  >
  updateSeekerProfResumePhoto?: Resolver<ResolversTypes['UpdatedProfResumePhotoOutput'], ParentType, ContextType, RequireFields<MutationUpdateSeekerProfResumePhotoArgs, 'input'>>
  updateSeekerProfResumePortfolios?: Resolver<ResolversTypes['UpdateProfResumePortfoliosOutput'], ParentType, ContextType, RequireFields<MutationUpdateSeekerProfResumePortfoliosArgs, 'input'>>
  updateSeekerProfResumePosition?: Resolver<ResolversTypes['UpdatedProfResumePositionOutput'], ParentType, ContextType, RequireFields<MutationUpdateSeekerProfResumePositionArgs, 'input'>>
  updateSeekerProfResumeSalary?: Resolver<ResolversTypes['UpdatedProfResumeSalaryOutput'], ParentType, ContextType, RequireFields<MutationUpdateSeekerProfResumeSalaryArgs, 'input'>>
  updateSeekerProfResumeSchedule?: Resolver<ResolversTypes['UpdatedProfResumeScheduleOutput'], ParentType, ContextType, RequireFields<MutationUpdateSeekerProfResumeScheduleArgs, 'input'>>
  updateSeekerProfResumeSearchState?: Resolver<ResolversTypes['UpdatedProfResumeSearchStateOutput'], ParentType, ContextType, RequireFields<MutationUpdateSeekerProfResumeSearchStateArgs, 'input'>>
  updateSeekerProfResumeSkills?: Resolver<ResolversTypes['UpdatedProfResumeSkillsOutput'], ParentType, ContextType, RequireFields<MutationUpdateSeekerProfResumeSkillsArgs, 'input'>>
  updateSeekerProfResumeSocialNetworks?: Resolver<
    ResolversTypes['UpdateProfResumeSocialNetworksOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateSeekerProfResumeSocialNetworksArgs, 'input'>
  >
  updateSeekerProfResumeSortDate?: Resolver<ResolversTypes['UpdateSeekerProfResumeSortDateOutput'], ParentType, ContextType, RequireFields<MutationUpdateSeekerProfResumeSortDateArgs, 'input'>>
  updateSeekerProfResumeState?: Resolver<ResolversTypes['ProfResumeUpdateStateOutput'], ParentType, ContextType, RequireFields<MutationUpdateSeekerProfResumeStateArgs, 'input'>>
  users?: Resolver<ResolversTypes['Users'], ParentType, ContextType>
  vacancyAppearedInViewPort?: Resolver<ResolversTypes['VacancyAppearedInViewPortOutput'], ParentType, ContextType, RequireFields<MutationVacancyAppearedInViewPortArgs, 'input'>>
  vacancyChangeClosingBehavior?: Resolver<Maybe<ResolversTypes['VacancyChangeClosingBehaviorOutput']>, ParentType, ContextType, RequireFields<MutationVacancyChangeClosingBehaviorArgs, 'input'>>
  vacancyChangeOwner?: Resolver<Maybe<ResolversTypes['VacancyChangeOwnerOutput']>, ParentType, ContextType, RequireFields<MutationVacancyChangeOwnerArgs, 'input'>>
  vacancyClose?: Resolver<Maybe<ResolversTypes['VacancyCloseOutput']>, ParentType, ContextType, RequireFields<MutationVacancyCloseArgs, 'input'>>
  vacancyCreate?: Resolver<Maybe<ResolversTypes['VacancyCreateOutput']>, ParentType, ContextType, RequireFields<MutationVacancyCreateArgs, 'input'>>
  vacancyDelete?: Resolver<Maybe<ResolversTypes['DeleteVacancyOutput']>, ParentType, ContextType, RequireFields<MutationVacancyDeleteArgs, 'input'>>
  vacancyInterviewQuestions?: Resolver<ResolversTypes['VacancyInterviewQuestionsMutations'], ParentType, ContextType>
  vacancyMakeHot?: Resolver<Maybe<ResolversTypes['VacancyMakeHotOutput']>, ParentType, ContextType, RequireFields<MutationVacancyMakeHotArgs, 'input'>>
  vacancyProlongationReview?: Resolver<ResolversTypes['VacancyProlongationReviewOutput'], ParentType, ContextType, RequireFields<MutationVacancyProlongationReviewArgs, 'input'>>
  vacancyPublish?: Resolver<Maybe<ResolversTypes['VacancyPublishOutput']>, ParentType, ContextType, RequireFields<MutationVacancyPublishArgs, 'input'>>
  vacancyRejectPublication?: Resolver<Maybe<ResolversTypes['VacancyRejectPublicationOutput']>, ParentType, ContextType, RequireFields<MutationVacancyRejectPublicationArgs, 'input'>>
  vacancyRepublish?: Resolver<Maybe<ResolversTypes['VacancyRepublishOutput']>, ParentType, ContextType, RequireFields<MutationVacancyRepublishArgs, 'input'>>
  vacancyResume?: Resolver<Maybe<ResolversTypes['VacancyResumeOutput']>, ParentType, ContextType, RequireFields<MutationVacancyResumeArgs, 'input'>>
  vacancyRisingUp?: Resolver<Maybe<ResolversTypes['VacancyMakeHotOutput']>, ParentType, ContextType, RequireFields<MutationVacancyRisingUpArgs, 'input'>>
  vacancySuspend?: Resolver<Maybe<ResolversTypes['VacancySuspendOutput']>, ParentType, ContextType, RequireFields<MutationVacancySuspendArgs, 'input'>>
  vacancyToAtsProjectExport?: Resolver<ResolversTypes['VacancyToAtsProjectExportOutput'], ParentType, ContextType, RequireFields<MutationVacancyToAtsProjectExportArgs, 'input'>>
  vacancyToDraft?: Resolver<Maybe<ResolversTypes['VacancyDraftOutput']>, ParentType, ContextType, RequireFields<MutationVacancyToDraftArgs, 'input'>>
  vacancyUpdate?: Resolver<Maybe<ResolversTypes['VacancyUpdateOutput']>, ParentType, ContextType, RequireFields<MutationVacancyUpdateArgs, 'input'>>
  viewNotifications?: Resolver<ResolversTypes['ViewNotificationsOutput'], ParentType, ContextType, RequireFields<MutationViewNotificationsArgs, 'input'>>
}

export type MyServicesConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['MyServicesConnection'] = ResolversParentTypes['MyServicesConnection']> = {
  edges?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['ServiceUnitedEdge']>>>, ParentType, ContextType>
  items?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['ServiceUnited']>>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MyVacanciesConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['MyVacanciesConnection'] = ResolversParentTypes['MyVacanciesConnection']> = {
  edges?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['VacancyEdge']>>>, ParentType, ContextType>
  items?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Vacancy']>>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NoAvailableLimitsErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['NoAvailableLimitsError'] = ResolversParentTypes['NoAvailableLimitsError']> = {
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NotAvailableErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotAvailableError'] = ResolversParentTypes['NotAvailableError']> = {
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NotFoundErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotFoundError'] = ResolversParentTypes['NotFoundError']> = {
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NotFoundProfResumeResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotFoundProfResume'] = ResolversParentTypes['NotFoundProfResume']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NotificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Notification'] = ResolversParentTypes['Notification']> = {
  arrivedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  details?: Resolver<Maybe<ResolversTypes['NotificationDetails']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isAcquainted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isViewed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NotificationCommonVacancySnapshotResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationCommonVacancySnapshot'] = ResolversParentTypes['NotificationCommonVacancySnapshot']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NotificationCommonVacancySnapshotInterfaceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationCommonVacancySnapshotInterface'] = ResolversParentTypes['NotificationCommonVacancySnapshotInterface']
> = {
  __resolveType: TypeResolveFn<'NotificationCommonVacancySnapshot' | 'NotificationEndingDaysVacancySnapshot' | 'NotificationOwnerChangedVacancySnapshot', ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
}

export type NotificationDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationDetails'] = ResolversParentTypes['NotificationDetails']> = {
  __resolveType: TypeResolveFn<
    | 'ApplicationInvitedNotificationDetails'
    | 'ApplicationRefusedNotificationDetails'
    | 'ApplicationSentNotificationDetails'
    | 'ApplicationViewedNotificationDetails'
    | 'CompanyStateChangedDetails'
    | 'CustomNotificationDetails'
    | 'DailyResumeViewsReportCalculated'
    | 'FavoriteVacanciesRemindedDetails'
    | 'JobInstantReceivedNotificationDetails'
    | 'JobRecommendationReceivedDetails'
    | 'LimitsChangeRequestedDetails'
    | 'OrderActivatedByTurbotaDetails'
    | 'ResumeDraftCreatedNotificationDetails'
    | 'ResumePublishedNotificationDetails'
    | 'SendNotificationSetEmailDetails'
    | 'UnpaidOrderDetails'
    | 'UserEmailHasBeenSetNotificationDetails'
    | 'VacancyAwaitingApprovalDetails'
    | 'VacancyBannedByModeratorDetails'
    | 'VacancyEndingCountDetails'
    | 'VacancyEndingLeftDaysDetails'
    | 'VacancyOwnerChangedDetails'
    | 'VacancyPublicationApprovedDetails'
    | 'VacancyPublicationEndedDetails'
    | 'VacancyPublicationRejectedDetails',
    ParentType,
    ContextType
  >
}

export type NotificationEmployeeInitiatorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationEmployeeInitiator'] = ResolversParentTypes['NotificationEmployeeInitiator']
> = {
  employee?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType>
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NotificationEmployeeSnapshotResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationEmployeeSnapshot'] = ResolversParentTypes['NotificationEmployeeSnapshot']> = {
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NotificationEndingDaysPublishPeriodSnapshotResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationEndingDaysPublishPeriodSnapshot'] = ResolversParentTypes['NotificationEndingDaysPublishPeriodSnapshot']
> = {
  daysUntilEnd?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  end?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NotificationEndingDaysVacancySnapshotResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationEndingDaysVacancySnapshot'] = ResolversParentTypes['NotificationEndingDaysVacancySnapshot']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  publishPeriod?: Resolver<ResolversTypes['NotificationEndingDaysPublishPeriodSnapshot'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NotificationInitiatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationInitiator'] = ResolversParentTypes['NotificationInitiator']> = {
  __resolveType: TypeResolveFn<'NotificationEmployeeInitiator' | 'NotificationManagerInitiator', ParentType, ContextType>
}

export type NotificationInitiatorInterfaceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationInitiatorInterface'] = ResolversParentTypes['NotificationInitiatorInterface']
> = {
  __resolveType: TypeResolveFn<'NotificationEmployeeInitiator' | 'NotificationManagerInitiator', ParentType, ContextType>
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
}

export type NotificationManagerInitiatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationManagerInitiator'] = ResolversParentTypes['NotificationManagerInitiator']> = {
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  manager?: Resolver<Maybe<ResolversTypes['Manager']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NotificationNotFoundErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationNotFoundError'] = ResolversParentTypes['NotificationNotFoundError']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NotificationOrderSnapshotResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationOrderSnapshot'] = ResolversParentTypes['NotificationOrderSnapshot']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  orderedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  totalPrice?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>
  turbotaEndsAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NotificationOwnerChangedVacancySnapshotResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationOwnerChangedVacancySnapshot'] = ResolversParentTypes['NotificationOwnerChangedVacancySnapshot']
> = {
  city?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NotificationVacancyEndingCountSnapshotResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationVacancyEndingCountSnapshot'] = ResolversParentTypes['NotificationVacancyEndingCountSnapshot']
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  daysUntilEnd?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NotificationsConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationsConnection'] = ResolversParentTypes['NotificationsConnection']> = {
  edges?: Resolver<Maybe<ReadonlyArray<ResolversTypes['NotificationsEdge']>>, ParentType, ContextType>
  nodes?: Resolver<Maybe<ReadonlyArray<ResolversTypes['Notification']>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NotificationsEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationsEdge'] = ResolversParentTypes['NotificationsEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['Notification'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NotificationsErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationsError'] = ResolversParentTypes['NotificationsError']> = {
  __resolveType: TypeResolveFn<'NotificationNotFoundError' | 'UnknownServerError', ParentType, ContextType>
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
}

export type NovaPoshtaAddCounterpartyOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NovaPoshtaAddCounterpartyOutput'] = ResolversParentTypes['NovaPoshtaAddCounterpartyOutput']
> = {
  errorText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  result?: Resolver<Maybe<ResolversTypes['NovaPoshtaCounterpartyGraphType']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NovaPoshtaAddDocumentOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['NovaPoshtaAddDocumentOutput'] = ResolversParentTypes['NovaPoshtaAddDocumentOutput']> = {
  deliveryCost?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  documentNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  documentRef?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  errorText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NovaPoshtaAddDocumentToRegisterOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NovaPoshtaAddDocumentToRegisterOutput'] = ResolversParentTypes['NovaPoshtaAddDocumentToRegisterOutput']
> = {
  errorText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  registerDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  registerNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  registerRef?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NovaPoshtaAddGiftAddressOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NovaPoshtaAddGiftAddressOutput'] = ResolversParentTypes['NovaPoshtaAddGiftAddressOutput']
> = {
  addressRef?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  contactRef?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NovaPoshtaCityResolvers<ContextType = any, ParentType extends ResolversParentTypes['NovaPoshtaCity'] = ResolversParentTypes['NovaPoshtaCity']> = {
  area?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  deliveryCity?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  hasAddressDeliveryAllowed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasStreetsAvailability?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  mainDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  present?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ref?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  region?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  settlementTypeCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NovaPoshtaCounterpartyGraphTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NovaPoshtaCounterpartyGraphType'] = ResolversParentTypes['NovaPoshtaCounterpartyGraphType']
> = {
  additionalPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  addresses?: Resolver<Maybe<ResolversTypes['NovaPoshtaFullAddressGraphType']>, ParentType, ContextType>
  contactPersonNote?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  contactPersonRef?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  counterpartyRef?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  counterpartyType?: Resolver<Maybe<ResolversTypes['NovaPosthaCounterpartyTypesGraphEnum']>, ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  edrpou?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  middleName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ownershipForm?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  phone?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ref?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NovaPoshtaDeleteDocumentOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NovaPoshtaDeleteDocumentOutput'] = ResolversParentTypes['NovaPoshtaDeleteDocumentOutput']
> = {
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NovaPoshtaDocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['NovaPoshtaDocument'] = ResolversParentTypes['NovaPoshtaDocument']> = {
  additionalInformation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  cityRecipient?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  cityRecipientRef?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  contactRecipient?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  contactRecipientRef?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  cost?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  costOnSite?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  customerNote?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  dateTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  giftRequest?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  isPrinted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  preferredDeliveryDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  recipientAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  recipientAddressDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  recipientAddressRef?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  recipientContactPerson?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  recipientContactPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  recipientEDRPOU?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  recipientRef?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  recipientWarehouseNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  recipientsPhone?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ref?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  seats?: Resolver<ReadonlyArray<ResolversTypes['NovaPoshtaOptionSeat']>, ParentType, ContextType>
  seatsAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  serviceType?: Resolver<ResolversTypes['NovaPoshtaServiceTypeEnum'], ParentType, ContextType>
  state?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  volumeWeight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  weight?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NovaPoshtaDoorsAddressGraphTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NovaPoshtaDoorsAddressGraphType'] = ResolversParentTypes['NovaPoshtaDoorsAddressGraphType']
> = {
  addressName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  areaDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  buildingNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  cityRef?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  flat?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  floor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ref?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  regionDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  settlementDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  settlementRef?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  streetDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  streetRef?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NovaPoshtaFullAddressGraphTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NovaPoshtaFullAddressGraphType'] = ResolversParentTypes['NovaPoshtaFullAddressGraphType']
> = {
  doorsAddresses?: Resolver<ReadonlyArray<ResolversTypes['NovaPoshtaDoorsAddressGraphType']>, ParentType, ContextType>
  warehouseAddresses?: Resolver<ReadonlyArray<ResolversTypes['NovaPoshtaWarehouseAddressGraphType']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NovaPoshtaNewAddedDocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['NovaPoshtaNewAddedDocument'] = ResolversParentTypes['NovaPoshtaNewAddedDocument']> = {
  cityRecipientDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  contactRecipient?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  costOnSite?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  dateTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  giftRequest?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  intDocNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  recipientAddressDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  recipientContactPerson?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  recipientContactPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  recipientsPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ref?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  seatsAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  state?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NovaPoshtaOptionSeatResolvers<ContextType = any, ParentType extends ResolversParentTypes['NovaPoshtaOptionSeat'] = ResolversParentTypes['NovaPoshtaOptionSeat']> = {
  volumetricHeight?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  volumetricLength?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  volumetricVolume?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  volumetricWeight?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  volumetricWidth?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  weight?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NovaPoshtaRegisterDocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['NovaPoshtaRegisterDocument'] = ResolversParentTypes['NovaPoshtaRegisterDocument']> = {
  cityRecipientDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  recipientAddressDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  recipientContactPersonFullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  recipientFullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  recipientPhone?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ref?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  stateName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NovaPoshtaRegisterListResolvers<ContextType = any, ParentType extends ResolversParentTypes['NovaPoshtaRegisterList'] = ResolversParentTypes['NovaPoshtaRegisterList']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  isPrinted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ref?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NovaPoshtaRegisterListConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NovaPoshtaRegisterListConnection'] = ResolversParentTypes['NovaPoshtaRegisterListConnection']
> = {
  edges?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['NovaPoshtaRegisterListEdge']>>>, ParentType, ContextType>
  items?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['NovaPoshtaRegisterList']>>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NovaPoshtaRegisterListEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['NovaPoshtaRegisterListEdge'] = ResolversParentTypes['NovaPoshtaRegisterListEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<Maybe<ResolversTypes['NovaPoshtaRegisterList']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NovaPoshtaStreetResolvers<ContextType = any, ParentType extends ResolversParentTypes['NovaPoshtaStreet'] = ResolversParentTypes['NovaPoshtaStreet']> = {
  present?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  settlementStreetDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  settlementStreetRef?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  streetsTypeDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NovaPoshtaWarehouseResolvers<ContextType = any, ParentType extends ResolversParentTypes['NovaPoshtaWarehouse'] = ResolversParentTypes['NovaPoshtaWarehouse']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ref?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  typeOfWarehouse?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NovaPoshtaWarehouseAddressGraphTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NovaPoshtaWarehouseAddressGraphType'] = ResolversParentTypes['NovaPoshtaWarehouseAddressGraphType']
> = {
  addressDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  cityDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  cityRef?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ref?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  typeOfWarehouse?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  warehouseNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ObjectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Objection'] = ResolversParentTypes['Objection']> = {
  addDate?: Resolver<ResolversTypes['DateTimeOffset'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  labels?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OfferVacancyToApplyOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['OfferVacancyToApplyOutput'] = ResolversParentTypes['OfferVacancyToApplyOutput']> = {
  apply?: Resolver<Maybe<ResolversTypes['Apply']>, ParentType, ContextType>
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OfferedVacancyCvDbEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OfferedVacancyCvDbEmployerEvent'] = ResolversParentTypes['OfferedVacancyCvDbEmployerEvent']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  cv?: Resolver<ResolversTypes['CandidateResumeEmployerEventSnapshot'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  vacancyCity?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  vacancyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  vacancyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OpenedContactOrVacancyOfferedEmployerAchievementResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OpenedContactOrVacancyOfferedEmployerAchievement'] = ResolversParentTypes['OpenedContactOrVacancyOfferedEmployerAchievement']
> = {
  currentCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  isDone?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  targetCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['EmployerAchievementType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OpenedContactsCvDbEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OpenedContactsCvDbEmployerEvent'] = ResolversParentTypes['OpenedContactsCvDbEmployerEvent']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  cv?: Resolver<ResolversTypes['CandidateResumeEmployerEventSnapshot'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderResolvers<ContextType = any, ParentType extends ResolversParentTypes['Order'] = ResolversParentTypes['Order']> = {
  activatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  canBeActivatedByTurbota?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  catalogPriceSum?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  clientPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  companyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  createInvoice?: Resolver<ResolversTypes['CreateInvoiceOutput'], ParentType, ContextType>
  discountActionSum?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  discountOptSum?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  discountPointsSum?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  discounts?: Resolver<ReadonlyArray<ResolversTypes['Discount']>, ParentType, ContextType>
  employee?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType>
  generateExternalPaymentUrl?: Resolver<Maybe<ResolversTypes['GenerateExternalPaymentUrlOutput']>, ParentType, ContextType, Partial<OrderGenerateExternalPaymentUrlArgs>>
  hasIrrelevantPromotionalOffer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  invoices?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Invoice']>>>, ParentType, ContextType>
  isCreatedByManager?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isFullyConfigured?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isPaidByCard?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isValid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  lastPaidAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  orderedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  paidSum?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  payWithCard?: Resolver<ResolversTypes['PayWithCardResponse'], ParentType, ContextType, RequireFields<OrderPayWithCardArgs, 'cardId'>>
  payWithExternalWallet?: Resolver<ResolversTypes['PayWithExternalWalletResponse'], ParentType, ContextType, RequireFields<OrderPayWithExternalWalletArgs, 'paymentData' | 'walletType'>>
  paymentMethod?: Resolver<ResolversTypes['PaymentMethods'], ParentType, ContextType>
  paymentState?: Resolver<ResolversTypes['OrderPaymentState'], ParentType, ContextType>
  positions?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['OrderPosition']>>>, ParentType, ContextType>
  state?: Resolver<ResolversTypes['OrderState'], ParentType, ContextType>
  totalPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  turbotaEndsAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  type?: Resolver<ResolversTypes['OrderType'], ParentType, ContextType>
  wasActivatedByTurbota?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderActivatedByTurbotaDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderActivatedByTurbotaDetails'] = ResolversParentTypes['OrderActivatedByTurbotaDetails']
> = {
  detailType?: Resolver<ResolversTypes['SeekerNotificationDetailTypeEnum'], ParentType, ContextType>
  orderSnapshot?: Resolver<ResolversTypes['NotificationOrderSnapshot'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderAlreadyActivatedErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderAlreadyActivatedError'] = ResolversParentTypes['OrderAlreadyActivatedError']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderCanNotBeActivatedByTurbotaErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderCanNotBeActivatedByTurbotaError'] = ResolversParentTypes['OrderCanNotBeActivatedByTurbotaError']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderCartMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderCartMutation'] = ResolversParentTypes['OrderCartMutation']> = {
  create?: Resolver<ResolversTypes['CreateOrderFromCartResponse'], ParentType, ContextType, Partial<OrderCartMutationCreateArgs>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderConnection'] = ResolversParentTypes['OrderConnection']> = {
  edges?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['OrderEdge']>>>, ParentType, ContextType>
  items?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Order']>>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderEdge'] = ResolversParentTypes['OrderEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderFreemiumServicesAccruedServiceEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderFreemiumServicesAccruedServiceEmployerEvent'] = ResolversParentTypes['OrderFreemiumServicesAccruedServiceEmployerEvent']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  order?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType>
  performer?: Resolver<ResolversTypes['SystemEventPerformer'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderPositionResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPosition'] = ResolversParentTypes['OrderPosition']> = {
  catalogPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  catalogPricePerItem?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  clientPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  clientPricePerItem?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  details?: Resolver<ResolversTypes['OrderPositionServiceDetailsUnion'], ParentType, ContextType>
  hasDiscount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderPositionAdditionalServiceDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderPositionAdditionalServiceDetails'] = ResolversParentTypes['OrderPositionAdditionalServiceDetails']
> = {
  service?: Resolver<ResolversTypes['CatalogService'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderPositionCvDbServiceDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderPositionCvDbServiceDetails'] = ResolversParentTypes['OrderPositionCvDbServiceDetails']
> = {
  city?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  period?: Resolver<Maybe<ResolversTypes['ServiceUsagePeriod']>, ParentType, ContextType>
  rubric?: Resolver<ResolversTypes['Rubric'], ParentType, ContextType>
  service?: Resolver<ResolversTypes['CatalogService'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderPositionHotServiceDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderPositionHotServiceDetails'] = ResolversParentTypes['OrderPositionHotServiceDetails']
> = {
  service?: Resolver<ResolversTypes['CatalogService'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderPositionLogoInPortalServiceDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderPositionLogoInPortalServiceDetails'] = ResolversParentTypes['OrderPositionLogoInPortalServiceDetails']
> = {
  service?: Resolver<ResolversTypes['CatalogService'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderPositionLogoInRubricServiceDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderPositionLogoInRubricServiceDetails'] = ResolversParentTypes['OrderPositionLogoInRubricServiceDetails']
> = {
  service?: Resolver<ResolversTypes['CatalogService'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderPositionLogoOnMainServiceDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderPositionLogoOnMainServiceDetails'] = ResolversParentTypes['OrderPositionLogoOnMainServiceDetails']
> = {
  service?: Resolver<ResolversTypes['CatalogService'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderPositionMarketingServiceDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderPositionMarketingServiceDetails'] = ResolversParentTypes['OrderPositionMarketingServiceDetails']
> = {
  service?: Resolver<ResolversTypes['CatalogService'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderPositionRisingUpServiceDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderPositionRisingUpServiceDetails'] = ResolversParentTypes['OrderPositionRisingUpServiceDetails']
> = {
  service?: Resolver<ResolversTypes['CatalogService'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderPositionServiceDetailsUnionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderPositionServiceDetailsUnion'] = ResolversParentTypes['OrderPositionServiceDetailsUnion']
> = {
  __resolveType: TypeResolveFn<
    | 'OrderPositionAdditionalServiceDetails'
    | 'OrderPositionCvDbServiceDetails'
    | 'OrderPositionHotServiceDetails'
    | 'OrderPositionLogoInPortalServiceDetails'
    | 'OrderPositionLogoInRubricServiceDetails'
    | 'OrderPositionLogoOnMainServiceDetails'
    | 'OrderPositionMarketingServiceDetails'
    | 'OrderPositionRisingUpServiceDetails'
    | 'OrderPositionVacancyPackageServiceDetails'
    | 'OrderPositionVacancyPublicationServiceDetails',
    ParentType,
    ContextType
  >
}

export type OrderPositionVacancyPackageServiceDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderPositionVacancyPackageServiceDetails'] = ResolversParentTypes['OrderPositionVacancyPackageServiceDetails']
> = {
  service?: Resolver<ResolversTypes['CatalogService'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderPositionVacancyPublicationServiceDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderPositionVacancyPublicationServiceDetails'] = ResolversParentTypes['OrderPositionVacancyPublicationServiceDetails']
> = {
  service?: Resolver<ResolversTypes['CatalogService'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderServicesAccruedServiceEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderServicesAccruedServiceEmployerEvent'] = ResolversParentTypes['OrderServicesAccruedServiceEmployerEvent']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  order?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType>
  performer?: Resolver<ResolversTypes['SystemEventPerformer'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderTurbotaActivationErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderTurbotaActivationError'] = ResolversParentTypes['OrderTurbotaActivationError']> = {
  __resolveType: TypeResolveFn<'OrderAlreadyActivatedError' | 'OrderCanNotBeActivatedByTurbotaError' | 'OrderWasActivatedByTurbotaError', ParentType, ContextType>
}

export type OrderTurbotaActivationOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderTurbotaActivationOutput'] = ResolversParentTypes['OrderTurbotaActivationOutput']> = {
  error?: Resolver<Maybe<ResolversTypes['OrderTurbotaActivationError']>, ParentType, ContextType>
  isSuccessful?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  order?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderWasActivatedByTurbotaErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderWasActivatedByTurbotaError'] = ResolversParentTypes['OrderWasActivatedByTurbotaError']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderedAdditionalServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderedAdditionalService'] = ResolversParentTypes['OrderedAdditionalService']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  paidAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  serviceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['ServiceState']>, ParentType, ContextType>
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderedCvDbServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderedCvDbService'] = ResolversParentTypes['OrderedCvDbService']> = {
  activateTo?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  city?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  contactsAccrualPeriod?: Resolver<Maybe<ResolversTypes['ContactsAccrualPeriod']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  packagesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  paidAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  rubric?: Resolver<ResolversTypes['Rubric'], ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  serviceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['ServiceState']>, ParentType, ContextType>
  totalContacts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>
  usagePeriod?: Resolver<Maybe<ResolversTypes['ServiceUsagePeriod']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderedHotServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderedHotService'] = ResolversParentTypes['OrderedHotService']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  packagesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  paidAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  parentServiceId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  serviceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  servicesPerPackage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['ServiceState']>, ParentType, ContextType>
  totalTicketsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderedLogoInPortalServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderedLogoInPortalService'] = ResolversParentTypes['OrderedLogoInPortalService']> = {
  branch?: Resolver<ResolversTypes['LogoPortalBranch'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  paidAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  portal?: Resolver<ResolversTypes['LogoPortal'], ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  serviceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['ServiceState']>, ParentType, ContextType>
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderedLogoInRubricServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderedLogoInRubricService'] = ResolversParentTypes['OrderedLogoInRubricService']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  paidAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  rubric?: Resolver<ResolversTypes['Rubric'], ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  serviceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['ServiceState']>, ParentType, ContextType>
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderedMarketingServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderedMarketingService'] = ResolversParentTypes['OrderedMarketingService']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  paidAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  serviceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['ServiceState']>, ParentType, ContextType>
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderedRisingUpServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderedRisingUpService'] = ResolversParentTypes['OrderedRisingUpService']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  paidAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  serviceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['ServiceState']>, ParentType, ContextType>
  totalTicketsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderedVacancyPackageServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderedVacancyPackageService'] = ResolversParentTypes['OrderedVacancyPackageService']> = {
  activateTo?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  additionalServices?: Resolver<ReadonlyArray<Maybe<ResolversTypes['ServiceUnited']>>, ParentType, ContextType>
  contactsAccrualPeriod?: Resolver<Maybe<ResolversTypes['ContactsAccrualPeriod']>, ParentType, ContextType>
  contactsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isPackage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isUnlimitedContacts?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  packagesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  paidAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  publicationType?: Resolver<Maybe<ResolversTypes['VacancyPublicationType']>, ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  serviceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['ServiceState']>, ParentType, ContextType>
  supportedRegions?: Resolver<ReadonlyArray<ResolversTypes['City']>, ParentType, ContextType>
  totalVacancyCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>
  vacanciesPerPackage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  vacancyMailingCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  vacancyPublishDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  vacancyRisingCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderedVacancyPublicationServiceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderedVacancyPublicationService'] = ResolversParentTypes['OrderedVacancyPublicationService']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isPackage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  paidAt?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  parentServiceId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  publicationType?: Resolver<Maybe<ResolversTypes['VacancyPublicationType']>, ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  serviceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['ServiceState']>, ParentType, ContextType>
  supportedRegions?: Resolver<ReadonlyArray<ResolversTypes['City']>, ParentType, ContextType>
  totalVacancyCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>
  vacancyMailingCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  vacancyPublishDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  vacancyRisingCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OtherSiteLinkResolvers<ContextType = any, ParentType extends ResolversParentTypes['OtherSiteLink'] = ResolversParentTypes['OtherSiteLink']> = {
  source?: Resolver<ResolversTypes['OtherSiteEnum'], ParentType, ContextType>
  vacancyCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  vacancyLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OwnerChangedVacancyEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OwnerChangedVacancyEmployerEvent'] = ResolversParentTypes['OwnerChangedVacancyEmployerEvent']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  currentOwner?: Resolver<ResolversTypes['EmployerEventSnapshot'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  previousOwner?: Resolver<ResolversTypes['EmployerEventSnapshot'], ParentType, ContextType>
  vacancyCity?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  vacancyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  vacancyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PackageServiceEmployerEventSnapshotResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PackageServiceEmployerEventSnapshot'] = ResolversParentTypes['PackageServiceEmployerEventSnapshot']
> = {
  activeTo?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  catalogService?: Resolver<ResolversTypes['CatalogService'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  publicationType?: Resolver<ResolversTypes['VacancyPublicationTypeWrapper'], ParentType, ContextType>
  restServices?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  totalServices?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PageInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo']> = {
  endCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  startCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PaidOrderServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaidOrderService'] = ResolversParentTypes['PaidOrderService']> = {
  fixedQuantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isAction?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isShow?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  minimumQuantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  nameUkr?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  openContactCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  packageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  serviceGroup?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  serviceType?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  unitIds?: Resolver<ReadonlyArray<ResolversTypes['ServiceUnitEnum']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ParsedCvResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParsedCv'] = ResolversParentTypes['ParsedCv']> = {
  assinged?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  assingedName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  comments?: Resolver<ReadonlyArray<ResolversTypes['ParsedCvComment']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  labels?: Resolver<ReadonlyArray<ResolversTypes['ParsedCvLabel']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  nameTags?: Resolver<ReadonlyArray<ResolversTypes['SynonymTag']>, ParentType, ContextType>
  rubrics?: Resolver<ReadonlyArray<ResolversTypes['ParsedCvRubric']>, ParentType, ContextType>
  state?: Resolver<ResolversTypes['ParsedCvStateEnum'], ParentType, ContextType>
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  textTags?: Resolver<ReadonlyArray<ResolversTypes['SynonymTag']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ParsedCvCommentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParsedCvComment'] = ResolversParentTypes['ParsedCvComment']> = {
  author?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  authorEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ParsedCvConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParsedCvConnection'] = ResolversParentTypes['ParsedCvConnection']> = {
  edges?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['BriefParsedCvEdge']>>>, ParentType, ContextType>
  items?: Resolver<Maybe<ReadonlyArray<ResolversTypes['BriefParsedCv']>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ParsedCvLabelResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParsedCvLabel'] = ResolversParentTypes['ParsedCvLabel']> = {
  end?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  labelType?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  start?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ParsedCvRubricResolvers<ContextType = any, ParentType extends ResolversParentTypes['ParsedCvRubric'] = ResolversParentTypes['ParsedCvRubric']> = {
  experience?: Resolver<ResolversTypes['Experience'], ParentType, ContextType>
  rubric?: Resolver<ResolversTypes['Rubric'], ParentType, ContextType>
  subrubric?: Resolver<ResolversTypes['Subrubric'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PasswordConfirmationFailedErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PasswordConfirmationFailedError'] = ResolversParentTypes['PasswordConfirmationFailedError']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PayWithCardErrorUnionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayWithCardErrorUnion'] = ResolversParentTypes['PayWithCardErrorUnion']> = {
  __resolveType: TypeResolveFn<'InvalidError' | 'ServerError' | 'UserIsNotAuthenticatedError', ParentType, ContextType>
}

export type PayWithCardResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayWithCardResponse'] = ResolversParentTypes['PayWithCardResponse']> = {
  errors?: Resolver<ReadonlyArray<ResolversTypes['PayWithCardErrorUnion']>, ParentType, ContextType>
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  validationUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PayWithExternalWalletErrorUnionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayWithExternalWalletErrorUnion'] = ResolversParentTypes['PayWithExternalWalletErrorUnion']
> = {
  __resolveType: TypeResolveFn<'InvalidError' | 'ServerError' | 'UserIsNotAuthenticatedError', ParentType, ContextType>
}

export type PayWithExternalWalletResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayWithExternalWalletResponse'] = ResolversParentTypes['PayWithExternalWalletResponse']
> = {
  errors?: Resolver<ReadonlyArray<ResolversTypes['PayWithExternalWalletErrorUnion']>, ParentType, ContextType>
  validationUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PayerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Payer'] = ResolversParentTypes['Payer']> = {
  accountNum?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  certificate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  contractDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  edrpou?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  gender?: Resolver<Maybe<ResolversTypes['Gender']>, ParentType, ContextType>
  iban?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  ipn?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  isLegal?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  legalAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  mfo?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  payerResponsible?: Resolver<ResolversTypes['PayerResponsibleEnum'], ParentType, ContextType>
  payerResponsiblePost?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  physicalPassport?: Resolver<Maybe<ResolversTypes['PayerPassport']>, ParentType, ContextType>
  proxyDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  proxyNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  responsibleBasis?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  responsibleFullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  responsibleName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  responsiblePatronymic?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  responsibleSurname?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PayerPassportResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayerPassport'] = ResolversParentTypes['PayerPassport']> = {
  isNewFormat?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  passportIssuingAuthority?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  passportIssuingDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  passportNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PaymentMethodsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentMethods'] = ResolversParentTypes['PaymentMethods']> = {
  __resolveType: TypeResolveFn<'ExternalUrlPaymentMethod' | 'WidgetPaymentMethod', ParentType, ContextType>
}

export type PaymentReceivedServiceEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentReceivedServiceEmployerEvent'] = ResolversParentTypes['PaymentReceivedServiceEmployerEvent']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  hasFullyPaid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  order?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType>
  paidInvoiceIdentifier?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  paidSum?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  paymentType?: Resolver<ResolversTypes['PaymentReceivedTypeEmployerEventEnum'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PerformerEmployerEventUnionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PerformerEmployerEventUnion'] = ResolversParentTypes['PerformerEmployerEventUnion']> = {
  __resolveType: TypeResolveFn<'EmployerEventPerformer' | 'ManagerEventPerformer' | 'SystemEventPerformer', ParentType, ContextType>
}

export type PeriodResolvers<ContextType = any, ParentType extends ResolversParentTypes['Period'] = ResolversParentTypes['Period']> = {
  month?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  year?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PeriodAmountResolvers<ContextType = any, ParentType extends ResolversParentTypes['PeriodAmount'] = ResolversParentTypes['PeriodAmount']> = {
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  period?: Resolver<Maybe<ResolversTypes['Period']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PersonalDiscountEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['PersonalDiscountEvent'] = ResolversParentTypes['PersonalDiscountEvent']> = {
  addDate?: Resolver<ResolversTypes['DateTimeOffset'], ParentType, ContextType>
  assignment?: Resolver<Maybe<ResolversTypes['Assignment']>, ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['CrmCompany']>, ParentType, ContextType>
  daysLeft?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  employee?: Resolver<Maybe<ResolversTypes['CrmEmployee']>, ParentType, ContextType>
  eventType?: Resolver<ResolversTypes['EventTypeEnum'], ParentType, ContextType>
  expirationDate?: Resolver<Maybe<ResolversTypes['DateTimeOffset']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isArchieved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isRead?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  lifetimeDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  state?: Resolver<ResolversTypes['EventStateEnum'], ParentType, ContextType>
  topics?: Resolver<ReadonlyArray<ResolversTypes['Topic']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PhoneConfirmationOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['PhoneConfirmationOutput'] = ResolversParentTypes['PhoneConfirmationOutput']> = {
  remainingAttempts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['PhoneConfirmationStatusEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PhoneNumberConfirmedEmployerAchievementResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PhoneNumberConfirmedEmployerAchievement'] = ResolversParentTypes['PhoneNumberConfirmedEmployerAchievement']
> = {
  isDone?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['EmployerAchievementType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PriceQueryOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['PriceQueryOutput'] = ResolversParentTypes['PriceQueryOutput']> = {
  items?: Resolver<ReadonlyArray<ResolversTypes['CalculatePriceItem']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProfResumeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProfResume'] = ResolversParentTypes['ProfResume']> = {
  additionalEducations?: Resolver<ReadonlyArray<ResolversTypes['ResumeAdditionalEducation']>, ParentType, ContextType>
  additionals?: Resolver<ReadonlyArray<ResolversTypes['ResumeAdditionalInfo']>, ParentType, ContextType>
  city?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  cityDistricts?: Resolver<ReadonlyArray<ResolversTypes['CityDistrict']>, ParentType, ContextType>
  contacts?: Resolver<ResolversTypes['ResumeContactInfo'], ParentType, ContextType>
  diiaCertificate?: Resolver<Maybe<ResolversTypes['Uri']>, ParentType, ContextType>
  educations?: Resolver<ReadonlyArray<ResolversTypes['ResumeEducation']>, ParentType, ContextType>
  experiences?: Resolver<ReadonlyArray<ResolversTypes['ResumeExperience']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isDisabledPerson?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  languageSkills?: Resolver<ReadonlyArray<ResolversTypes['ResumeLanguageSkill']>, ParentType, ContextType>
  militaryStatus?: Resolver<Maybe<ResolversTypes['MilitaryStatusEnum']>, ParentType, ContextType>
  personal?: Resolver<ResolversTypes['ResumePersonalInfo'], ParentType, ContextType>
  relocationCities?: Resolver<ReadonlyArray<ResolversTypes['City']>, ParentType, ContextType>
  resumeFilling?: Resolver<Maybe<ResolversTypes['ResumeFilling']>, ParentType, ContextType>
  rubrics?: Resolver<ReadonlyArray<ResolversTypes['Subrubric']>, ParentType, ContextType>
  salary?: Resolver<Maybe<ResolversTypes['ResumeSalary']>, ParentType, ContextType>
  schedules?: Resolver<ReadonlyArray<ResolversTypes['Schedule']>, ParentType, ContextType>
  similarVacanciesCount?: Resolver<Maybe<ResolversTypes['Long']>, ParentType, ContextType>
  skills?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  state?: Resolver<ResolversTypes['ResumeState'], ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  updateDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  views?: Resolver<Maybe<ResolversTypes['ResumeViewsConnection']>, ParentType, ContextType, Partial<ProfResumeViewsArgs>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProfResumeCannotBePublishedResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProfResumeCannotBePublished'] = ResolversParentTypes['ProfResumeCannotBePublished']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProfResumeDeleteDiiaCertificateOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProfResumeDeleteDiiaCertificateOutput'] = ResolversParentTypes['ProfResumeDeleteDiiaCertificateOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['DeleteSeekerProfResumeDiiaCertificateError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProfResumeDoesNotBelongToSeekerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProfResumeDoesNotBelongToSeeker'] = ResolversParentTypes['ProfResumeDoesNotBelongToSeeker']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProfResumeDoesNotExistResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProfResumeDoesNotExist'] = ResolversParentTypes['ProfResumeDoesNotExist']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProfResumeDuplicatedResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProfResumeDuplicated'] = ResolversParentTypes['ProfResumeDuplicated']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProfResumeLimitResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProfResumeLimit'] = ResolversParentTypes['ProfResumeLimit']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProfResumePhoneConfirmationFailedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProfResumePhoneConfirmationFailed'] = ResolversParentTypes['ProfResumePhoneConfirmationFailed']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProfResumeResponseUnionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProfResumeResponseUnion'] = ResolversParentTypes['ProfResumeResponseUnion']> = {
  __resolveType: TypeResolveFn<'NotFoundProfResume' | 'ProfResume', ParentType, ContextType>
}

export type ProfResumeUpdateStateOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProfResumeUpdateStateOutput'] = ResolversParentTypes['ProfResumeUpdateStateOutput']> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeStateError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProfileRegisteredEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProfileRegisteredEvent'] = ResolversParentTypes['ProfileRegisteredEvent']> = {
  addDate?: Resolver<ResolversTypes['DateTimeOffset'], ParentType, ContextType>
  assignment?: Resolver<Maybe<ResolversTypes['Assignment']>, ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['CrmCompany']>, ParentType, ContextType>
  daysLeft?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  employee?: Resolver<Maybe<ResolversTypes['CrmEmployee']>, ParentType, ContextType>
  eventType?: Resolver<ResolversTypes['EventTypeEnum'], ParentType, ContextType>
  expirationDate?: Resolver<Maybe<ResolversTypes['DateTimeOffset']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isArchieved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isRead?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  lifetimeDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  state?: Resolver<ResolversTypes['EventStateEnum'], ParentType, ContextType>
  topics?: Resolver<ReadonlyArray<ResolversTypes['Topic']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProlongationRequestVacancyEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProlongationRequestVacancyEmployerEvent'] = ResolversParentTypes['ProlongationRequestVacancyEmployerEvent']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  vacancyCity?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  vacancyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  vacancyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PrometheusExternalCourseResolvers<ContextType = any, ParentType extends ResolversParentTypes['PrometheusExternalCourse'] = ResolversParentTypes['PrometheusExternalCourse']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PromoCodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PromoCode'] = ResolversParentTypes['PromoCode']> = {
  activatorsInfo?: Resolver<ReadonlyArray<ResolversTypes['PromoCodeActivator']>, ParentType, ContextType>
  addDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  chargesLeft?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  chargesTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  discount?: Resolver<ResolversTypes['ServiceDiscountTypeWrapper'], ParentType, ContextType>
  discountSizePercent?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  expirationDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isAvailableInCart?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['PromoCodeStatusEnum'], ParentType, ContextType>
  target?: Resolver<ResolversTypes['PromoCodeTragetUnion'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['PromoCodeTypeWrapper'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PromoCodeActivatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['PromoCodeActivator'] = ResolversParentTypes['PromoCodeActivator']> = {
  activationDates?: Resolver<ReadonlyArray<ResolversTypes['DateTime']>, ParentType, ContextType>
  chargesUsed?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  employee?: Resolver<ResolversTypes['Employee'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PromoCodeCartMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['PromoCodeCartMutation'] = ResolversParentTypes['PromoCodeCartMutation']> = {
  apply?: Resolver<ResolversTypes['SelectPromoCodeResponse'], ParentType, ContextType, RequireFields<PromoCodeCartMutationApplyArgs, 'id'>>
  drop?: Resolver<ResolversTypes['DropSelectedPromoCodeResponse'], ParentType, ContextType, Partial<PromoCodeCartMutationDropArgs>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PromoCodeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PromoCodeConnection'] = ResolversParentTypes['PromoCodeConnection']> = {
  edges?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['PromoCodeEdge']>>>, ParentType, ContextType>
  items?: Resolver<Maybe<ReadonlyArray<ResolversTypes['PromoCode']>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PromoCodeDiscountResolvers<ContextType = any, ParentType extends ResolversParentTypes['PromoCodeDiscount'] = ResolversParentTypes['PromoCodeDiscount']> = {
  promoCode?: Resolver<ResolversTypes['PromoCode'], ParentType, ContextType>
  sum?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PromoCodeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PromoCodeEdge'] = ResolversParentTypes['PromoCodeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['PromoCode'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PromoCodeMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['PromoCodeMutation'] = ResolversParentTypes['PromoCodeMutation']> = {
  add?: Resolver<ResolversTypes['AddPromoCodeResponse'], ParentType, ContextType, RequireFields<PromoCodeMutationAddArgs, 'code'>>
  use?: Resolver<ResolversTypes['UsePromoCodeResponse'], ParentType, ContextType, RequireFields<PromoCodeMutationUseArgs, 'input'>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PromoCodeTargetBonusSpecialServicesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PromoCodeTargetBonusSpecialServices'] = ResolversParentTypes['PromoCodeTargetBonusSpecialServices']
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  services?: Resolver<ReadonlyArray<ResolversTypes['CatalogService']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PromoCodeTargetSpecialServiceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PromoCodeTargetSpecialService'] = ResolversParentTypes['PromoCodeTargetSpecialService']
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  service?: Resolver<ResolversTypes['CatalogService'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PromoCodeTargetSpecialServiceTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PromoCodeTargetSpecialServiceType'] = ResolversParentTypes['PromoCodeTargetSpecialServiceType']
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  serviceType?: Resolver<ResolversTypes['ServiceTypeWrapper'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PromoCodeTargetWholeOrderResolvers<ContextType = any, ParentType extends ResolversParentTypes['PromoCodeTargetWholeOrder'] = ResolversParentTypes['PromoCodeTargetWholeOrder']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PromoCodeTragetUnionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PromoCodeTragetUnion'] = ResolversParentTypes['PromoCodeTragetUnion']> = {
  __resolveType: TypeResolveFn<'PromoCodeTargetBonusSpecialServices' | 'PromoCodeTargetSpecialService' | 'PromoCodeTargetSpecialServiceType' | 'PromoCodeTargetWholeOrder', ParentType, ContextType>
}

export type PromoCodeTypeWrapperResolvers<ContextType = any, ParentType extends ResolversParentTypes['PromoCodeTypeWrapper'] = ResolversParentTypes['PromoCodeTypeWrapper']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['PromoCodeTypeEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProzoraClientDateTimeIntervalResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProzoraClientDateTimeInterval'] = ResolversParentTypes['ProzoraClientDateTimeInterval']
> = {
  begin?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  end?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProzoraClientFiltersResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProzoraClientFilters'] = ResolversParentTypes['ProzoraClientFilters']> = {
  cityId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  keyword?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  range?: Resolver<ResolversTypes['ProzoraClientDateTimeInterval'], ParentType, ContextType>
  rubricId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublicClubPostsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicClubPostsOutput'] = ResolversParentTypes['PublicClubPostsOutput']> = {
  count?: Resolver<Maybe<ResolversTypes['Long']>, ParentType, ContextType>
  items?: Resolver<ReadonlyArray<ResolversTypes['ClubPost']>, ParentType, ContextType>
  nextCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  previousCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublicationServiceEmployerEventSnapshotResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublicationServiceEmployerEventSnapshot'] = ResolversParentTypes['PublicationServiceEmployerEventSnapshot']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  catalogService?: Resolver<ResolversTypes['CatalogService'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  publicationType?: Resolver<ResolversTypes['VacancyPublicationTypeWrapper'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishSeekerProfResumeErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublishSeekerProfResumeError'] = ResolversParentTypes['PublishSeekerProfResumeError']> = {
  __resolveType: TypeResolveFn<'ProfResumeCannotBePublished' | 'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type PublishedProfResumeOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublishedProfResumeOutput'] = ResolversParentTypes['PublishedProfResumeOutput']> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['PublishSeekerProfResumeError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedResumesCounterResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublishedResumesCounter'] = ResolversParentTypes['PublishedResumesCounter']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  period?: Resolver<ResolversTypes['PublishedResumeCounterPeriodEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedVacanciesResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublishedVacancies'] = ResolversParentTypes['PublishedVacancies']> = {
  items?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Vacancy']>>>, ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Long']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedVacanciesClientClusterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishedVacanciesClientCluster'] = ResolversParentTypes['PublishedVacanciesClientCluster']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  tagIds?: Resolver<ReadonlyArray<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedVacanciesClientFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishedVacanciesClientFilter'] = ResolversParentTypes['PublishedVacanciesClientFilter']
> = {
  cityId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  clusters?: Resolver<ReadonlyArray<ResolversTypes['PublishedVacanciesClientCluster']>, ParentType, ContextType>
  districtIds?: Resolver<ReadonlyArray<ResolversTypes['ID']>, ParentType, ContextType>
  isForVeterans?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isOfficeWithGenerator?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isOfficeWithShelter?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isReservation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  keywords?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  location?: Resolver<ResolversTypes['PublishedVacanciesLocation'], ParentType, ContextType>
  metroBranches?: Resolver<ReadonlyArray<ResolversTypes['PublishedVacanciesClientMetroBranch']>, ParentType, ContextType>
  regionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  rubrics?: Resolver<ReadonlyArray<ResolversTypes['PublishedVacanciesClientRubric']>, ParentType, ContextType>
  salary?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  scheduleIds?: Resolver<ReadonlyArray<ResolversTypes['ID']>, ParentType, ContextType>
  showAgencies?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  showMilitary?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  showOnlyNoCvApplyVacancies?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  showOnlyNotViewed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  showOnlySpecialNeeds?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  showOnlyWithoutExperience?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  showWithoutSalary?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedVacanciesClientMetroBranchResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishedVacanciesClientMetroBranch'] = ResolversParentTypes['PublishedVacanciesClientMetroBranch']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  stationIds?: Resolver<ReadonlyArray<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedVacanciesClientRubricResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishedVacanciesClientRubric'] = ResolversParentTypes['PublishedVacanciesClientRubric']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  subrubricIds?: Resolver<ReadonlyArray<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedVacanciesFacetsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublishedVacanciesFacets'] = ResolversParentTypes['PublishedVacanciesFacets']> = {
  districtsFacets?: Resolver<ReadonlyArray<ResolversTypes['DistrictFacet']>, ParentType, ContextType>
  metrosFacets?: Resolver<ReadonlyArray<ResolversTypes['MetroLineFacet']>, ParentType, ContextType>
  rubricsFacets?: Resolver<ReadonlyArray<ResolversTypes['RubricFacet']>, ParentType, ContextType>
  schedulesFacets?: Resolver<ReadonlyArray<ResolversTypes['ScheduleFacet']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedVacanciesLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublishedVacanciesLocation'] = ResolversParentTypes['PublishedVacanciesLocation']> = {
  latitude?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  longitude?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedVacanciesQueryFilterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishedVacanciesQueryFilter'] = ResolversParentTypes['PublishedVacanciesQueryFilter']
> = {
  filter?: Resolver<ResolversTypes['PublishedVacanciesClientFilter'], ParentType, ContextType>
  page?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  sort?: Resolver<ResolversTypes['PublishedVacanciesSortType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedVacancyBadgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublishedVacancyBadge'] = ResolversParentTypes['PublishedVacancyBadge']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PublishedVacancyEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishedVacancyEmployerEvent'] = ResolversParentTypes['PublishedVacancyEmployerEvent']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  vacancyCity?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  vacancyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  vacancyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QuarterResolvers<ContextType = any, ParentType extends ResolversParentTypes['Quarter'] = ResolversParentTypes['Quarter']> = {
  number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  year?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  actDownloadUrl?: Resolver<ResolversTypes['DownloadActOutput'], ParentType, ContextType, RequireFields<QueryActDownloadUrlArgs, 'input'>>
  actionFilters?: Resolver<Maybe<ResolversTypes['ActionFilters']>, ParentType, ContextType>
  anotherSourceVacancy?: Resolver<Maybe<ResolversTypes['AnotherSourceVacancyOutput']>, ParentType, ContextType, RequireFields<QueryAnotherSourceVacancyArgs, 'url'>>
  applies?: Resolver<Maybe<ResolversTypes['ApplyConnection']>, ParentType, ContextType, Partial<QueryAppliesArgs>>
  apply?: Resolver<Maybe<ResolversTypes['Apply']>, ParentType, ContextType, RequireFields<QueryApplyArgs, 'id'>>
  applyAtsConnection?: Resolver<Maybe<ResolversTypes['ApplyProjectAtsConnection']>, ParentType, ContextType, RequireFields<QueryApplyAtsConnectionArgs, 'applyId'>>
  applyInvitationDetails?: Resolver<Maybe<ResolversTypes['ApplyInvitationDetails']>, ParentType, ContextType, RequireFields<QueryApplyInvitationDetailsArgs, 'applyId'>>
  applyRefusalDetails?: Resolver<Maybe<ResolversTypes['ApplyRefusalDetails']>, ParentType, ContextType, RequireFields<QueryApplyRefusalDetailsArgs, 'applyId'>>
  applyResumeFillingTypeCounters?: Resolver<ReadonlyArray<ResolversTypes['ApplyResumeFillingTypeCount']>, ParentType, ContextType, Partial<QueryApplyResumeFillingTypeCountersArgs>>
  applyStatuses?: Resolver<ReadonlyArray<Maybe<ResolversTypes['ApplyStatusCount']>>, ParentType, ContextType, Partial<QueryApplyStatusesArgs>>
  assignment?: Resolver<ResolversTypes['Assignment'], ParentType, ContextType, Partial<QueryAssignmentArgs>>
  assignmentCounters?: Resolver<ReadonlyArray<ResolversTypes['DateCounter']>, ParentType, ContextType, Partial<QueryAssignmentCountersArgs>>
  assignments?: Resolver<Maybe<ResolversTypes['AssignmentConnection']>, ParentType, ContextType, RequireFields<QueryAssignmentsArgs, 'filter'>>
  banners?: Resolver<ResolversTypes['BannerQueries'], ParentType, ContextType>
  billFilters?: Resolver<Maybe<ResolversTypes['BillFilters']>, ParentType, ContextType>
  branch?: Resolver<Maybe<ResolversTypes['Branch']>, ParentType, ContextType, RequireFields<QueryBranchArgs, 'id'>>
  branches?: Resolver<ReadonlyArray<Maybe<ResolversTypes['Branch']>>, ParentType, ContextType>
  cabinetEventsClientFilters?: Resolver<ResolversTypes['CabinetEventsQueryFilter'], ParentType, ContextType>
  calculatePrices?: Resolver<ResolversTypes['PriceQueryOutput'], ParentType, ContextType, RequireFields<QueryCalculatePricesArgs, 'input'>>
  callFilters?: Resolver<Maybe<ResolversTypes['CallFilters']>, ParentType, ContextType>
  cart?: Resolver<ResolversTypes['CartQuery'], ParentType, ContextType>
  cartById?: Resolver<ResolversTypes['CartQuery'], ParentType, ContextType, RequireFields<QueryCartByIdArgs, 'id'>>
  catalogService?: Resolver<Maybe<ResolversTypes['CatalogUnitedServices']>, ParentType, ContextType, Partial<QueryCatalogServiceArgs>>
  catalogServiceRegions?: Resolver<ReadonlyArray<ResolversTypes['City']>, ParentType, ContextType, RequireFields<QueryCatalogServiceRegionsArgs, 'serviceType'>>
  catalogServices?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CatalogUnitedServices']>>>, ParentType, ContextType, Partial<QueryCatalogServicesArgs>>
  categorizationAttaches?: Resolver<ResolversTypes['CategorizationAttachesQueries'], ParentType, ContextType>
  checkVacancyContentCorrectnessForPublishing?: Resolver<
    ResolversTypes['CheckVacancyContentCorrectnessForPublishingOutput'],
    ParentType,
    ContextType,
    RequireFields<QueryCheckVacancyContentCorrectnessForPublishingArgs, 'vacancyId'>
  >
  cities?: Resolver<ReadonlyArray<Maybe<ResolversTypes['City']>>, ParentType, ContextType>
  citiesConnection?: Resolver<Maybe<ResolversTypes['CitiesConnection']>, ParentType, ContextType, Partial<QueryCitiesConnectionArgs>>
  city?: Resolver<Maybe<ResolversTypes['City']>, ParentType, ContextType, RequireFields<QueryCityArgs, 'id'>>
  cityDistrict?: Resolver<ResolversTypes['CityDistrict'], ParentType, ContextType, RequireFields<QueryCityDistrictArgs, 'id'>>
  cityDistricts?: Resolver<ReadonlyArray<Maybe<ResolversTypes['CityDistrict']>>, ParentType, ContextType, RequireFields<QueryCityDistrictsArgs, 'cityId'>>
  cityMetro?: Resolver<ReadonlyArray<Maybe<ResolversTypes['Metro']>>, ParentType, ContextType, RequireFields<QueryCityMetroArgs, 'cityId'>>
  clubs?: Resolver<ResolversTypes['ClubQueries'], ParentType, ContextType>
  companiesSimilarToMine?: Resolver<ReadonlyArray<ResolversTypes['Company']>, ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType, RequireFields<QueryCompanyArgs, 'id'>>
  companyDuplicates?: Resolver<ReadonlyArray<ResolversTypes['DuplicateCompany']>, ParentType, ContextType, RequireFields<QueryCompanyDuplicatesArgs, 'companyId'>>
  companyEvents?: Resolver<ReadonlyArray<ResolversTypes['CompanyEventOutput']>, ParentType, ContextType, RequireFields<QueryCompanyEventsArgs, 'filter'>>
  companyPublishedVacanciesClientFilters?: Resolver<ResolversTypes['CompanyPublishedVacanciesQueryFilter'], ParentType, ContextType>
  companyStaffSize?: Resolver<ResolversTypes['CompanyStaffSize'], ParentType, ContextType, RequireFields<QueryCompanyStaffSizeArgs, 'id'>>
  companyStaffSizes?: Resolver<ReadonlyArray<ResolversTypes['CompanyStaffSize']>, ParentType, ContextType>
  companyStates?: Resolver<ReadonlyArray<ResolversTypes['CompanyStateWrapper']>, ParentType, ContextType>
  confirmedCompanyPhones?: Resolver<ReadonlyArray<ResolversTypes['CrmPhone']>, ParentType, ContextType, RequireFields<QueryConfirmedCompanyPhonesArgs, 'companyId'>>
  crmAccounts?: Resolver<Maybe<ResolversTypes['CrmAccountConnection']>, ParentType, ContextType, Partial<QueryCrmAccountsArgs>>
  crmAction?: Resolver<Maybe<ResolversTypes['CrmEditAction']>, ParentType, ContextType, RequireFields<QueryCrmActionArgs, 'id'>>
  crmActionAttaches?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['ActionAttach']>>>, ParentType, ContextType, RequireFields<QueryCrmActionAttachesArgs, 'input'>>
  crmActionDraft?: Resolver<Maybe<ResolversTypes['CrmEditAction']>, ParentType, ContextType, RequireFields<QueryCrmActionDraftArgs, 'input'>>
  crmActionMeetingReasons?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CrmMeetingReason']>>>, ParentType, ContextType>
  crmActionPreviousList?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CrmActionBrief']>>>, ParentType, ContextType, RequireFields<QueryCrmActionPreviousListArgs, 'actionId'>>
  crmActionTypes?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CrmActionType']>>>, ParentType, ContextType>
  crmActions?: Resolver<Maybe<ResolversTypes['CrmActionConnection']>, ParentType, ContextType, Partial<QueryCrmActionsArgs>>
  crmCalls?: Resolver<Maybe<ResolversTypes['CrmCallConnection']>, ParentType, ContextType, Partial<QueryCrmCallsArgs>>
  crmCompanies?: Resolver<Maybe<ResolversTypes['CrmCompanyConnection']>, ParentType, ContextType, Partial<QueryCrmCompaniesArgs>>
  crmCompany?: Resolver<Maybe<ResolversTypes['CrmCompany']>, ParentType, ContextType, RequireFields<QueryCrmCompanyArgs, 'id'>>
  crmEmailContacts?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CrmContact']>>>, ParentType, ContextType, RequireFields<QueryCrmEmailContactsArgs, 'companyId'>>
  crmManagerCalendar?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CrmPlannedActionByDay']>>>, ParentType, ContextType, RequireFields<QueryCrmManagerCalendarArgs, 'input'>>
  crmMarketingServiceFilters?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CrmMarketingServiceFilter']>>>, ParentType, ContextType, RequireFields<QueryCrmMarketingServiceFiltersArgs, 'filter'>>
  crmMarketingServices?: Resolver<Maybe<ResolversTypes['CrmMarketingServiceConnection']>, ParentType, ContextType, Partial<QueryCrmMarketingServicesArgs>>
  crmMetaCompany?: Resolver<Maybe<ResolversTypes['CrmMetaCompany']>, ParentType, ContextType, RequireFields<QueryCrmMetaCompanyArgs, 'id'>>
  crmMultiUser?: Resolver<Maybe<ResolversTypes['CrmMultiUser']>, ParentType, ContextType, RequireFields<QueryCrmMultiUserArgs, 'userId'>>
  crmOrderServices?: Resolver<ReadonlyArray<ResolversTypes['PaidOrderService']>, ParentType, ContextType>
  crmPinnedActions?: Resolver<Maybe<ReadonlyArray<ResolversTypes['CrmAction']>>, ParentType, ContextType, RequireFields<QueryCrmPinnedActionsArgs, 'companyId'>>
  crmQuarterList?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CrmQuarter']>>>, ParentType, ContextType>
  crmSaleManagers?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CrmManager']>>>, ParentType, ContextType>
  crmUserCard?: Resolver<Maybe<ResolversTypes['CrmUserCardEdit']>, ParentType, ContextType, RequireFields<QueryCrmUserCardArgs, 'cardId'>>
  crmUserCardRoles?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CrmUserCardRole']>>>, ParentType, ContextType>
  crmUserCards?: Resolver<Maybe<ReadonlyArray<ResolversTypes['CrmUserCard']>>, ParentType, ContextType, RequireFields<QueryCrmUserCardsArgs, 'companyId'>>
  currentEmployer?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType>
  cvCounts?: Resolver<Maybe<ResolversTypes['CountStatistics']>, ParentType, ContextType, RequireFields<QueryCvCountsArgs, 'input'>>
  cvdbRegions?: Resolver<ResolversTypes['ServiceCvdbRegionsOutput'], ParentType, ContextType>
  cvdbRubrics?: Resolver<ResolversTypes['ServiceCvdbRubricsOutput'], ParentType, ContextType>
  dashboardCompanies?: Resolver<Maybe<ResolversTypes['DashboardCompanyConnection']>, ParentType, ContextType, Partial<QueryDashboardCompaniesArgs>>
  dashboardCompanyStatistics?: Resolver<Maybe<ResolversTypes['DashboardResponse']>, ParentType, ContextType, Partial<QueryDashboardCompanyStatisticsArgs>>
  dashboardMultiUserRatings?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['DashboardRatingResponse']>>>, ParentType, ContextType, Partial<QueryDashboardMultiUserRatingsArgs>>
  dashboardMultiUserStatistics?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['DashboardMultiUserResponse']>>>, ParentType, ContextType, Partial<QueryDashboardMultiUserStatisticsArgs>>
  employee?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType, RequireFields<QueryEmployeeArgs, 'id'>>
  employeeHasScopes?: Resolver<ResolversTypes['EmployeeHasScopesOutput'], ParentType, ContextType, RequireFields<QueryEmployeeHasScopesArgs, 'input'>>
  employeeRoleBasedScopeEnumToIds?: Resolver<ReadonlyArray<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<QueryEmployeeRoleBasedScopeEnumToIdsArgs, 'items'>>
  employeeRoleBasedScopes?: Resolver<ReadonlyArray<ResolversTypes['EmployeeRoleBasedScope']>, ParentType, ContextType>
  employeeScopes?: Resolver<ReadonlyArray<ResolversTypes['EmployeeRoleBasedScope']>, ParentType, ContextType, RequireFields<QueryEmployeeScopesArgs, 'employeeId'>>
  employeeSubscriptions?: Resolver<ResolversTypes['EmployeeSubscriptionsQueries'], ParentType, ContextType>
  employerOnBoardingCheckList?: Resolver<Maybe<ResolversTypes['EmployerOnBoardingCheckListOutput']>, ParentType, ContextType>
  experience?: Resolver<Maybe<ResolversTypes['Experience']>, ParentType, ContextType, RequireFields<QueryExperienceArgs, 'id'>>
  experiences?: Resolver<ReadonlyArray<Maybe<ResolversTypes['Experience']>>, ParentType, ContextType>
  feedback?: Resolver<ResolversTypes['FeedbackQueries'], ParentType, ContextType>
  gamificationDashboardDates?: Resolver<Maybe<ResolversTypes['GamificationDates']>, ParentType, ContextType, RequireFields<QueryGamificationDashboardDatesArgs, 'notebookId'>>
  gamificationRecommendedWinnerCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryGamificationRecommendedWinnerCountArgs, 'notebookId'>>
  gamificationWaveList?: Resolver<ReadonlyArray<ResolversTypes['GamificationWaveList']>, ParentType, ContextType>
  gamificationWaves?: Resolver<ReadonlyArray<ResolversTypes['GamificationWave']>, ParentType, ContextType, RequireFields<QueryGamificationWavesArgs, 'companyId'>>
  generateExternalPaymentUrl?: Resolver<Maybe<ResolversTypes['GenerateExternalPaymentUrlOutput']>, ParentType, ContextType, RequireFields<QueryGenerateExternalPaymentUrlArgs, 'input'>>
  generateExternalPaymentUrlByInvoice?: Resolver<
    Maybe<ResolversTypes['GenerateExternalPaymentUrlByInvoiceOutput']>,
    ParentType,
    ContextType,
    RequireFields<QueryGenerateExternalPaymentUrlByInvoiceArgs, 'input'>
  >
  getCounterparty?: Resolver<Maybe<ResolversTypes['NovaPoshtaCounterpartyGraphType']>, ParentType, ContextType, RequireFields<QueryGetCounterpartyArgs, 'input'>>
  getCurrentEmployer?: Resolver<ResolversTypes['GetCurrentEmployerOutput'], ParentType, ContextType>
  getWorkVacancy?: Resolver<Maybe<ResolversTypes['SpiderVacancy']>, ParentType, ContextType, RequireFields<QueryGetWorkVacancyArgs, 'vacancyId'>>
  gift?: Resolver<ResolversTypes['RegularGift'], ParentType, ContextType, RequireFields<QueryGiftArgs, 'giftId'>>
  giftCompanyLimitsByWave?: Resolver<ReadonlyArray<ResolversTypes['GiftCompanyWaveLimit']>, ParentType, ContextType, RequireFields<QueryGiftCompanyLimitsByWaveArgs, 'notebookId' | 'waveId'>>
  giftLimits?: Resolver<ReadonlyArray<ResolversTypes['GiftLimit']>, ParentType, ContextType, RequireFields<QueryGiftLimitsArgs, 'companyId'>>
  giftLimitsByManager?: Resolver<ReadonlyArray<ResolversTypes['ManagerGiftLimit']>, ParentType, ContextType>
  giftLimitsByTeamLead?: Resolver<ReadonlyArray<ResolversTypes['GiftTeamLimit']>, ParentType, ContextType>
  giftLimitsByTeamLeadByManagers?: Resolver<ReadonlyArray<ResolversTypes['GiftTeamLimitByManager']>, ParentType, ContextType, Partial<QueryGiftLimitsByTeamLeadByManagersArgs>>
  giftLimitsByTeamLeadByManagersPaging?: Resolver<Maybe<ResolversTypes['GiftTeamLimitByManagerConnection']>, ParentType, ContextType, Partial<QueryGiftLimitsByTeamLeadByManagersPagingArgs>>
  giftLimitsByWave?: Resolver<ReadonlyArray<ResolversTypes['GiftWaveLimit']>, ParentType, ContextType, RequireFields<QueryGiftLimitsByWaveArgs, 'waveId'>>
  giftLimitsTransferHistoryByTeamLead?: Resolver<ReadonlyArray<ResolversTypes['GiftTeamLimitTransferHistory']>, ParentType, ContextType, Partial<QueryGiftLimitsTransferHistoryByTeamLeadArgs>>
  giftLimitsTransferHistoryByTeamLeadPaging?: Resolver<
    Maybe<ResolversTypes['GiftTeamLimitTransferHistoryConnection']>,
    ParentType,
    ContextType,
    Partial<QueryGiftLimitsTransferHistoryByTeamLeadPagingArgs>
  >
  giftList?: Resolver<ReadonlyArray<ResolversTypes['Gift']>, ParentType, ContextType>
  giftRegistersScrollInfo?: Resolver<Maybe<ResolversTypes['ScrollInfo']>, ParentType, ContextType>
  giftRequest?: Resolver<ResolversTypes['GiftRequest'], ParentType, ContextType, RequireFields<QueryGiftRequestArgs, 'giftId'>>
  giftRequestActiveOccasions?: Resolver<ReadonlyArray<ResolversTypes['GiftRequestOccasion']>, ParentType, ContextType>
  giftRequestByManager?: Resolver<Maybe<ResolversTypes['GiftRequestConnection']>, ParentType, ContextType, Partial<QueryGiftRequestByManagerArgs>>
  giftRequestMarketing?: Resolver<Maybe<ResolversTypes['GiftRequestConnection']>, ParentType, ContextType, Partial<QueryGiftRequestMarketingArgs>>
  giftRequestOccasions?: Resolver<ReadonlyArray<ResolversTypes['GiftRequestOccasion']>, ParentType, ContextType>
  giftsFilters?: Resolver<Maybe<ResolversTypes['GiftsFilters']>, ParentType, ContextType>
  giftsInWarehouse?: Resolver<ReadonlyArray<ResolversTypes['WarehouseGift']>, ParentType, ContextType>
  giftsManagerScrollInfo?: Resolver<Maybe<ResolversTypes['ScrollInfo']>, ParentType, ContextType>
  giftsMarketingBySelfScrollInfo?: Resolver<Maybe<ResolversTypes['ScrollInfo']>, ParentType, ContextType>
  giftsMarketingNovaPoshtaScrollInfo?: Resolver<Maybe<ResolversTypes['ScrollInfo']>, ParentType, ContextType>
  groupTag?: Resolver<Maybe<ResolversTypes['GroupTag']>, ParentType, ContextType, RequireFields<QueryGroupTagArgs, 'id'>>
  groupTagsInsideCluster?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['GroupTag']>>>,
    ParentType,
    ContextType,
    RequireFields<QueryGroupTagsInsideClusterArgs, 'after' | 'clusterId' | 'first' | 'prefix'>
  >
  hasCompanyNewUsersManagingFeature?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryHasCompanyNewUsersManagingFeatureArgs, 'id'>>
  hasLimitsFunctionality?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  invoice?: Resolver<Maybe<ResolversTypes['Invoice']>, ParentType, ContextType, RequireFields<QueryInvoiceArgs, 'id' | 'year'>>
  invoiceDownloadUrl?: Resolver<ResolversTypes['DownloadInvoiceOutput'], ParentType, ContextType, RequireFields<QueryInvoiceDownloadUrlArgs, 'input'>>
  isbluecollar?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<QueryIsbluecollarArgs, 'name'>>
  keyword?: Resolver<Maybe<ResolversTypes['Keyword']>, ParentType, ContextType, RequireFields<QueryKeywordArgs, 'name'>>
  keywords?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Keyword']>>>, ParentType, ContextType, RequireFields<QueryKeywordsArgs, 'first' | 'isProfession' | 'offset'>>
  language?: Resolver<Maybe<ResolversTypes['Lang']>, ParentType, ContextType, RequireFields<QueryLanguageArgs, 'id'>>
  languageSkillLevel?: Resolver<Maybe<ResolversTypes['LanguageSkillLevel']>, ParentType, ContextType, RequireFields<QueryLanguageSkillLevelArgs, 'id'>>
  languageSkillLevels?: Resolver<ReadonlyArray<Maybe<ResolversTypes['LanguageSkillLevel']>>, ParentType, ContextType>
  languages?: Resolver<ReadonlyArray<Maybe<ResolversTypes['Lang']>>, ParentType, ContextType>
  letterFilters?: Resolver<Maybe<ResolversTypes['LetterFilters']>, ParentType, ContextType>
  limitedGiftsInWarehouse?: Resolver<ReadonlyArray<ResolversTypes['WarehouseLimitedGift']>, ParentType, ContextType>
  limitsFilters?: Resolver<Maybe<ResolversTypes['LimitsFilters']>, ParentType, ContextType>
  logoRubrics?: Resolver<ReadonlyArray<Maybe<ResolversTypes['LogoPortal']>>, ParentType, ContextType>
  logoSubPortals?: Resolver<ReadonlyArray<Maybe<ResolversTypes['LogoPortal']>>, ParentType, ContextType>
  manager?: Resolver<Maybe<ResolversTypes['Manager']>, ParentType, ContextType, RequireFields<QueryManagerArgs, 'id'>>
  managerActionCounter?: Resolver<ReadonlyArray<ResolversTypes['ActionCounter']>, ParentType, ContextType, RequireFields<QueryManagerActionCounterArgs, 'filter'>>
  managerDeals?: Resolver<ResolversTypes['ManagerDeals'], ParentType, ContextType>
  managerForAnonymousUser?: Resolver<Maybe<ResolversTypes['Manager']>, ParentType, ContextType>
  marketingGiftLimitHistory?: Resolver<Maybe<ResolversTypes['MarketingLimitTransferConnection']>, ParentType, ContextType, Partial<QueryMarketingGiftLimitHistoryArgs>>
  marketingGiftRequests?: Resolver<Maybe<ResolversTypes['MarketingGiftRequestConnection']>, ParentType, ContextType, Partial<QueryMarketingGiftRequestsArgs>>
  marketingGifts?: Resolver<ReadonlyArray<ResolversTypes['MarketingGift']>, ParentType, ContextType>
  me?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>
  metro?: Resolver<ResolversTypes['Metro'], ParentType, ContextType, RequireFields<QueryMetroArgs, 'id'>>
  multiUsers?: Resolver<ReadonlyArray<ResolversTypes['CrmMultiUser']>, ParentType, ContextType, RequireFields<QueryMultiUsersArgs, 'companyId'>>
  myCompaniesToJoin?: Resolver<ReadonlyArray<ResolversTypes['CompanyJoinRequest']>, ParentType, ContextType>
  myEventsLog?: Resolver<Maybe<ResolversTypes['EmployerEventLogConnection']>, ParentType, ContextType, Partial<QueryMyEventsLogArgs>>
  myJoiningEmployees?: Resolver<ReadonlyArray<ResolversTypes['Employee']>, ParentType, ContextType>
  myOrder?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType, RequireFields<QueryMyOrderArgs, 'id'>>
  myPromoCodes?: Resolver<Maybe<ResolversTypes['PromoCodeConnection']>, ParentType, ContextType, Partial<QueryMyPromoCodesArgs>>
  myVacancies?: Resolver<Maybe<ResolversTypes['MyVacanciesConnection']>, ParentType, ContextType, Partial<QueryMyVacanciesArgs>>
  myVacanciesByCity?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['VacancyCityCount']>>>, ParentType, ContextType, Partial<QueryMyVacanciesByCityArgs>>
  myVacanciesByEmployer?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['VacancyEmployerCount']>>>, ParentType, ContextType, Partial<QueryMyVacanciesByEmployerArgs>>
  myVacanciesByStatus?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['VacancyStatusCount']>>>, ParentType, ContextType, Partial<QueryMyVacanciesByStatusArgs>>
  notifications?: Resolver<Maybe<ResolversTypes['NotificationsConnection']>, ParentType, ContextType, Partial<QueryNotificationsArgs>>
  novaPoshtaDocument?: Resolver<Maybe<ResolversTypes['NovaPoshtaDocument']>, ParentType, ContextType, RequireFields<QueryNovaPoshtaDocumentArgs, 'docRef'>>
  novaPoshtaFindCity?: Resolver<ReadonlyArray<ResolversTypes['NovaPoshtaCity']>, ParentType, ContextType, RequireFields<QueryNovaPoshtaFindCityArgs, 'name'>>
  novaPoshtaFindCounterpartyPerson?: Resolver<
    ReadonlyArray<ResolversTypes['NovaPoshtaCounterpartyGraphType']>,
    ParentType,
    ContextType,
    RequireFields<QueryNovaPoshtaFindCounterpartyPersonArgs, 'findByString'>
  >
  novaPoshtaFindStreet?: Resolver<ReadonlyArray<ResolversTypes['NovaPoshtaStreet']>, ParentType, ContextType, RequireFields<QueryNovaPoshtaFindStreetArgs, 'cityRef' | 'name'>>
  novaPoshtaFindWarehouse?: Resolver<ReadonlyArray<ResolversTypes['NovaPoshtaWarehouse']>, ParentType, ContextType, RequireFields<QueryNovaPoshtaFindWarehouseArgs, 'cityRef'>>
  novaPoshtaNewDocuments?: Resolver<ReadonlyArray<ResolversTypes['NovaPoshtaNewAddedDocument']>, ParentType, ContextType>
  novaPoshtaRegisterDocuments?: Resolver<ReadonlyArray<ResolversTypes['NovaPoshtaRegisterDocument']>, ParentType, ContextType, RequireFields<QueryNovaPoshtaRegisterDocumentsArgs, 'registerRef'>>
  novaPoshtaRegisterList?: Resolver<Maybe<ResolversTypes['NovaPoshtaRegisterListConnection']>, ParentType, ContextType, Partial<QueryNovaPoshtaRegisterListArgs>>
  novaPoshtaSenders?: Resolver<ReadonlyArray<ResolversTypes['NovaPoshtaCounterpartyGraphType']>, ParentType, ContextType>
  objection?: Resolver<ResolversTypes['Objection'], ParentType, ContextType, RequireFields<QueryObjectionArgs, 'id'>>
  popularSkills?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['TagGroup']>>>, ParentType, ContextType, RequireFields<QueryPopularSkillsArgs, 'keyword' | 'top'>>
  positions?: Resolver<ReadonlyArray<Maybe<ResolversTypes['String']>>, ParentType, ContextType, RequireFields<QueryPositionsArgs, 'term'>>
  professionAutocomplete?: Resolver<ReadonlyArray<Maybe<ResolversTypes['String']>>, ParentType, ContextType, RequireFields<QueryProfessionAutocompleteArgs, 'keyword' | 'size'>>
  professionSuggestions?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['String']>>>, ParentType, ContextType, RequireFields<QueryProfessionSuggestionsArgs, 'cityId' | 'keyword'>>
  promoCodeById?: Resolver<Maybe<ResolversTypes['PromoCode']>, ParentType, ContextType, RequireFields<QueryPromoCodeByIdArgs, 'id'>>
  promoCodesByIds?: Resolver<ReadonlyArray<ResolversTypes['PromoCode']>, ParentType, ContextType, RequireFields<QueryPromoCodesByIdsArgs, 'ids'>>
  prozoraClientFilters?: Resolver<ResolversTypes['ProzoraClientFilters'], ParentType, ContextType>
  publishedResumesCounter?: Resolver<ReadonlyArray<ResolversTypes['PublishedResumesCounter']>, ParentType, ContextType, RequireFields<QueryPublishedResumesCounterArgs, 'input'>>
  publishedVacancies?: Resolver<ResolversTypes['PublishedVacancies'], ParentType, ContextType, RequireFields<QueryPublishedVacanciesArgs, 'filter'>>
  publishedVacanciesClientFilters?: Resolver<ResolversTypes['PublishedVacanciesQueryFilter'], ParentType, ContextType>
  publishedVacanciesFacets?: Resolver<ResolversTypes['PublishedVacanciesFacets'], ParentType, ContextType, RequireFields<QueryPublishedVacanciesFacetsArgs, 'filter'>>
  publishedVacancy?: Resolver<Maybe<ResolversTypes['Vacancy']>, ParentType, ContextType, RequireFields<QueryPublishedVacancyArgs, 'id'>>
  questionTemplates?: Resolver<ReadonlyArray<ResolversTypes['BasicQuestion']>, ParentType, ContextType, Partial<QueryQuestionTemplatesArgs>>
  questionnaire?: Resolver<Maybe<ResolversTypes['Questionnaire']>, ParentType, ContextType, RequireFields<QueryQuestionnaireArgs, 'id'>>
  questionnaireAnswersSummaryByApply?: Resolver<Maybe<ResolversTypes['QuestionnaireAnswersSummary']>, ParentType, ContextType, RequireFields<QueryQuestionnaireAnswersSummaryByApplyArgs, 'applyId'>>
  questionnaireSuggest?: Resolver<Maybe<ResolversTypes['QuestionnaireSuggest']>, ParentType, ContextType, RequireFields<QueryQuestionnaireSuggestArgs, 'vacancyDescription' | 'vacancyName'>>
  questionnaires?: Resolver<Maybe<ResolversTypes['QuestionnaireConnection']>, ParentType, ContextType, Partial<QueryQuestionnairesArgs>>
  recommendationProfile?: Resolver<Maybe<ResolversTypes['SeekerRecommendationProfile']>, ParentType, ContextType, RequireFields<QueryRecommendationProfileArgs, 'id'>>
  recommendedProfResumes?: Resolver<Maybe<ResolversTypes['RecommendedProfResumes']>, ParentType, ContextType, RequireFields<QueryRecommendedProfResumesArgs, 'after' | 'first' | 'input'>>
  recommendedVacancies?: Resolver<Maybe<ResolversTypes['RecommendedVacanciesConnection']>, ParentType, ContextType, RequireFields<QueryRecommendedVacanciesArgs, 'after' | 'first'>>
  resumeFillingType?: Resolver<ResolversTypes['ResumeFillingType'], ParentType, ContextType, RequireFields<QueryResumeFillingTypeArgs, 'id'>>
  resumeFillingTypes?: Resolver<ReadonlyArray<ResolversTypes['ResumeFillingType']>, ParentType, ContextType>
  resumeSearchSuggest?: Resolver<ReadonlyArray<ResolversTypes['ResumeSearchSuggest']>, ParentType, ContextType, RequireFields<QueryResumeSearchSuggestArgs, 'query'>>
  rubric?: Resolver<Maybe<ResolversTypes['Rubric']>, ParentType, ContextType, RequireFields<QueryRubricArgs, 'id'>>
  rubrics?: Resolver<ReadonlyArray<Maybe<ResolversTypes['Rubric']>>, ParentType, ContextType>
  saleManagerQueries?: Resolver<ResolversTypes['SaleManagerQueries'], ParentType, ContextType>
  savedCards?: Resolver<ReadonlyArray<ResolversTypes['SavedCard']>, ParentType, ContextType>
  schedule?: Resolver<Maybe<ResolversTypes['Schedule']>, ParentType, ContextType, RequireFields<QueryScheduleArgs, 'id'>>
  schedules?: Resolver<ReadonlyArray<ResolversTypes['Schedule']>, ParentType, ContextType>
  seekerApplies?: Resolver<ResolversTypes['SeekerApplyQueries'], ParentType, ContextType>
  seekerDislikedVacancies?: Resolver<ResolversTypes['SeekerDislikedVacancyQueries'], ParentType, ContextType>
  seekerFavoriteVacancies?: Resolver<ResolversTypes['SeekerFavoriteVacancyQueries'], ParentType, ContextType>
  seekerResume?: Resolver<ResolversTypes['ProfResume'], ParentType, ContextType, RequireFields<QuerySeekerResumeArgs, 'id'>>
  seekerResumeViews?: Resolver<Maybe<ResolversTypes['ResumeViewsConnection']>, ParentType, ContextType, Partial<QuerySeekerResumeViewsArgs>>
  seekerResumes?: Resolver<ReadonlyArray<ResolversTypes['ProfResume']>, ParentType, ContextType>
  seekerSalaryStatistics?: Resolver<Maybe<ResolversTypes['SalaryStatistics']>, ParentType, ContextType, RequireFields<QuerySeekerSalaryStatisticsArgs, 'input'>>
  seekerSubscriptionChannels?: Resolver<ReadonlyArray<ResolversTypes['SeekerSubscriptionChannel']>, ParentType, ContextType>
  seekerSubscriptions?: Resolver<ReadonlyArray<ResolversTypes['SeekerSubscription']>, ParentType, ContextType, Partial<QuerySeekerSubscriptionsArgs>>
  seekerSubscriptionsSummaries?: Resolver<ReadonlyArray<ResolversTypes['SeekerSubscriptionSummary']>, ParentType, ContextType>
  serviceAvailableContacts?: Resolver<ResolversTypes['serviceAvailableContacts'], ParentType, ContextType>
  serviceCompany?: Resolver<Maybe<ResolversTypes['ServiceCompany']>, ParentType, ContextType, RequireFields<QueryServiceCompanyArgs, 'id'>>
  serviceCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<QueryServiceCountArgs>>
  serviceEmployee?: Resolver<Maybe<ResolversTypes['ServiceEmployee']>, ParentType, ContextType, RequireFields<QueryServiceEmployeeArgs, 'id'>>
  serviceEmployerPurchaseHistory?: Resolver<ResolversTypes['ServicePurchaseHistoryOutput'], ParentType, ContextType>
  servicesFilters?: Resolver<Maybe<ResolversTypes['ServicesFilters']>, ParentType, ContextType>
  servicesManagementFilters?: Resolver<Maybe<ResolversTypes['ServicesManagementClientFilters']>, ParentType, ContextType>
  similarSuccessfulVacancies?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['Vacancy']>>>,
    ParentType,
    ContextType,
    RequireFields<QuerySimilarSuccessfulVacanciesArgs, 'after' | 'cityId' | 'first' | 'vacancyTitle'>
  >
  similarVacancies?: Resolver<ResolversTypes['SimilarVacanciesQueries'], ParentType, ContextType>
  similarVacancyKeywords?: Resolver<ResolversTypes['SimilarVacancyKeywordsOutput'], ParentType, ContextType, RequireFields<QuerySimilarVacancyKeywordsArgs, 'request' | 'size'>>
  statistics?: Resolver<Maybe<ResolversTypes['Statistic']>, ParentType, ContextType, RequireFields<QueryStatisticsArgs, 'input'>>
  stopWords?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>
  subrubric?: Resolver<Maybe<ResolversTypes['Subrubric']>, ParentType, ContextType, RequireFields<QuerySubrubricArgs, 'id'>>
  subrubrics?: Resolver<ReadonlyArray<ResolversTypes['Subrubric']>, ParentType, ContextType>
  suggestedVacancySearch?: Resolver<ResolversTypes['SuggestedVacancySearchOutput'], ParentType, ContextType, RequireFields<QuerySuggestedVacancySearchArgs, 'request' | 'size'>>
  tagGroup?: Resolver<Maybe<ResolversTypes['TagGroup']>, ParentType, ContextType, RequireFields<QueryTagGroupArgs, 'id'>>
  tagGroupByName?: Resolver<Maybe<ResolversTypes['TagGroup']>, ParentType, ContextType, RequireFields<QueryTagGroupByNameArgs, 'keyword'>>
  targetMailingListClientFilters?: Resolver<ResolversTypes['TargetMailingListQueryFilter'], ParentType, ContextType>
  targetedMailing?: Resolver<ResolversTypes['TargetedMailingQueries'], ParentType, ContextType>
  teamActionCounter?: Resolver<ResolversTypes['TeamActions'], ParentType, ContextType, Partial<QueryTeamActionCounterArgs>>
  teamManagers?: Resolver<ReadonlyArray<ResolversTypes['CrmManager']>, ParentType, ContextType>
  topCitiesByVacancyCount?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['City']>>>, ParentType, ContextType, RequireFields<QueryTopCitiesByVacancyCountArgs, 'first' | 'offset'>>
  topRubricsByVacancyCount?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Rubric']>>>, ParentType, ContextType, RequireFields<QueryTopRubricsByVacancyCountArgs, 'first' | 'offset'>>
  topicTemplates?: Resolver<ReadonlyArray<ResolversTypes['TopicTemplate']>, ParentType, ContextType>
  universities?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['University']>>>, ParentType, ContextType, RequireFields<QueryUniversitiesArgs, 'first' | 'offset' | 'prefix'>>
  university?: Resolver<Maybe<ResolversTypes['University']>, ParentType, ContextType, RequireFields<QueryUniversityArgs, 'id'>>
  userState?: Resolver<Maybe<ResolversTypes['UserState']>, ParentType, ContextType>
  usersManagementFilters?: Resolver<ResolversTypes['UsersManagementFilters'], ParentType, ContextType>
  usersManagementPageInfo?: Resolver<ResolversTypes['UsersManagementPageInfo'], ParentType, ContextType>
  vacanciesPageFilters?: Resolver<ResolversTypes['VacanciesPageClientFilters'], ParentType, ContextType>
  vacancy?: Resolver<Maybe<ResolversTypes['Vacancy']>, ParentType, ContextType, RequireFields<QueryVacancyArgs, 'id'>>
  vacancyAddressesSuggest?: Resolver<ResolversTypes['VacancyAddressesSuggestOutput'], ParentType, ContextType, RequireFields<QueryVacancyAddressesSuggestArgs, 'input'>>
  vacancyBadges?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['VacancyBadge']>>>, ParentType, ContextType, RequireFields<QueryVacancyBadgesArgs, 'first' | 'input'>>
  vacancyBenefit?: Resolver<ResolversTypes['VacancyDictionaryBenefit'], ParentType, ContextType, RequireFields<QueryVacancyBenefitArgs, 'id'>>
  vacancyBenefits?: Resolver<ReadonlyArray<Maybe<ResolversTypes['VacancyDictionaryBenefit']>>, ParentType, ContextType>
  vacancyById?: Resolver<ResolversTypes['GetVacancyByIdResponse'], ParentType, ContextType, RequireFields<QueryVacancyByIdArgs, 'id'>>
  vacancyChangeHistory?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['VacancyChangeHistory']>>>, ParentType, ContextType, RequireFields<QueryVacancyChangeHistoryArgs, 'id'>>
  vacancyCounts?: Resolver<Maybe<ResolversTypes['CountStatistics']>, ParentType, ContextType, RequireFields<QueryVacancyCountsArgs, 'input'>>
  vacancyCreateSuggest?: Resolver<Maybe<ResolversTypes['VacancyCreateSuggestOutput']>, ParentType, ContextType>
  vacancyDesign?: Resolver<Maybe<ResolversTypes['VacancyDesign']>, ParentType, ContextType, RequireFields<QueryVacancyDesignArgs, 'id'>>
  vacancyProlongationRequests?: Resolver<ResolversTypes['VacancyProlongationRequestsOutput'], ParentType, ContextType>
  vacancyPublicationTypeValues?: Resolver<ReadonlyArray<ResolversTypes['VacancyPublicationTypeWrapper']>, ParentType, ContextType>
  vacancySalaryStatistics?: Resolver<Maybe<ResolversTypes['SalaryStatistics']>, ParentType, ContextType, RequireFields<QueryVacancySalaryStatisticsArgs, 'input'>>
  vacancySearchSuggests?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['VacancySearchSuggests']>>>, ParentType, ContextType, RequireFields<QueryVacancySearchSuggestsArgs, 'query'>>
  vacancySearchSuggestsV2?: Resolver<ResolversTypes['VacancySearchSuggestsQueries'], ParentType, ContextType>
}

export type QuestionnaireResolvers<ContextType = any, ParentType extends ResolversParentTypes['Questionnaire'] = ResolversParentTypes['Questionnaire']> = {
  company?: Resolver<ResolversTypes['Company'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isTemplate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  language?: Resolver<ResolversTypes['QuestionnaireLangEnum'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  questions?: Resolver<ReadonlyArray<ResolversTypes['QuestionnaireItem']>, ParentType, ContextType>
  user?: Resolver<ResolversTypes['Employee'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QuestionnaireAnswerResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionnaireAnswer'] = ResolversParentTypes['QuestionnaireAnswer']> = {
  givenAnswers?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>
  isCorrect?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  questionSnapshot?: Resolver<ResolversTypes['QuestionnaireItem'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QuestionnaireAnswersSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionnaireAnswersSummary'] = ResolversParentTypes['QuestionnaireAnswersSummary']> = {
  answers?: Resolver<ReadonlyArray<ResolversTypes['QuestionnaireAnswer']>, ParentType, ContextType>
  apply?: Resolver<ResolversTypes['Apply'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  questionnaire?: Resolver<ResolversTypes['Questionnaire'], ParentType, ContextType>
  rate?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  sentAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QuestionnaireConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionnaireConnection'] = ResolversParentTypes['QuestionnaireConnection']> = {
  edges?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['QuestionnaireEdge']>>>, ParentType, ContextType>
  items?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Questionnaire']>>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QuestionnaireCopyOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionnaireCopyOutput'] = ResolversParentTypes['QuestionnaireCopyOutput']> = {
  questionnaire?: Resolver<Maybe<ResolversTypes['Questionnaire']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QuestionnaireCreateOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionnaireCreateOutput'] = ResolversParentTypes['QuestionnaireCreateOutput']> = {
  questionnaire?: Resolver<Maybe<ResolversTypes['Questionnaire']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QuestionnaireDeleteOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionnaireDeleteOutput'] = ResolversParentTypes['QuestionnaireDeleteOutput']> = {
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QuestionnaireEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionnaireEdge'] = ResolversParentTypes['QuestionnaireEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<Maybe<ResolversTypes['Questionnaire']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QuestionnaireEssayItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionnaireEssayItem'] = ResolversParentTypes['QuestionnaireEssayItem']> = {
  answerType?: Resolver<ResolversTypes['ApplicableAnswerTypeEnum'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  question?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QuestionnaireExperienceItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionnaireExperienceItem'] = ResolversParentTypes['QuestionnaireExperienceItem']> = {
  answerOptions?: Resolver<ReadonlyArray<ResolversTypes['AnswerOption']>, ParentType, ContextType>
  answerType?: Resolver<ResolversTypes['ApplicableAnswerTypeEnum'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  question?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QuestionnaireExperienceItemSuggestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QuestionnaireExperienceItemSuggest'] = ResolversParentTypes['QuestionnaireExperienceItemSuggest']
> = {
  answerOptions?: Resolver<ReadonlyArray<ResolversTypes['AnswerOptionSuggest']>, ParentType, ContextType>
  answerType?: Resolver<ResolversTypes['ApplicableAnswerTypeSuggestEnum'], ParentType, ContextType>
  question?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QuestionnaireGiveAnswersOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QuestionnaireGiveAnswersOutput'] = ResolversParentTypes['QuestionnaireGiveAnswersOutput']
> = {
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QuestionnaireItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionnaireItem'] = ResolversParentTypes['QuestionnaireItem']> = {
  __resolveType: TypeResolveFn<'QuestionnaireEssayItem' | 'QuestionnaireExperienceItem' | 'QuestionnaireLanguageItem' | 'QuestionnaireSelectItem', ParentType, ContextType>
}

export type QuestionnaireItemSuggestResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionnaireItemSuggest'] = ResolversParentTypes['QuestionnaireItemSuggest']> = {
  __resolveType: TypeResolveFn<'QuestionnaireExperienceItemSuggest' | 'QuestionnaireLanguageItemSuggest' | 'QuestionnaireSelectItemSuggest', ParentType, ContextType>
}

export type QuestionnaireLanguageItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionnaireLanguageItem'] = ResolversParentTypes['QuestionnaireLanguageItem']> = {
  answerOptions?: Resolver<ReadonlyArray<ResolversTypes['AnswerOption']>, ParentType, ContextType>
  answerType?: Resolver<ResolversTypes['ApplicableAnswerTypeEnum'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  language?: Resolver<ResolversTypes['Lang'], ParentType, ContextType>
  question?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QuestionnaireLanguageItemSuggestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QuestionnaireLanguageItemSuggest'] = ResolversParentTypes['QuestionnaireLanguageItemSuggest']
> = {
  answerOptions?: Resolver<ReadonlyArray<ResolversTypes['AnswerOptionSuggest']>, ParentType, ContextType>
  answerType?: Resolver<ResolversTypes['ApplicableAnswerTypeSuggestEnum'], ParentType, ContextType>
  language?: Resolver<ResolversTypes['LangSuggest'], ParentType, ContextType>
  question?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QuestionnaireSelectItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionnaireSelectItem'] = ResolversParentTypes['QuestionnaireSelectItem']> = {
  answerOptions?: Resolver<ReadonlyArray<ResolversTypes['AnswerOption']>, ParentType, ContextType>
  answerType?: Resolver<ResolversTypes['ApplicableAnswerTypeEnum'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  question?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QuestionnaireSelectItemSuggestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QuestionnaireSelectItemSuggest'] = ResolversParentTypes['QuestionnaireSelectItemSuggest']
> = {
  answerOptions?: Resolver<ReadonlyArray<ResolversTypes['AnswerOptionSuggest']>, ParentType, ContextType>
  answerType?: Resolver<ResolversTypes['ApplicableAnswerTypeSuggestEnum'], ParentType, ContextType>
  question?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QuestionnaireSuggestResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionnaireSuggest'] = ResolversParentTypes['QuestionnaireSuggest']> = {
  language?: Resolver<ResolversTypes['QuestionnaireLangSuggestEnum'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  questions?: Resolver<ReadonlyArray<ResolversTypes['QuestionnaireItemSuggest']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QuestionnaireUpdateOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionnaireUpdateOutput'] = ResolversParentTypes['QuestionnaireUpdateOutput']> = {
  questionnaire?: Resolver<Maybe<ResolversTypes['Questionnaire']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RePublishedVacancyEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RePublishedVacancyEmployerEvent'] = ResolversParentTypes['RePublishedVacancyEmployerEvent']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  vacancyCity?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  vacancyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  vacancyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RecommendedCandidateResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecommendedCandidate'] = ResolversParentTypes['RecommendedCandidate']> = {
  lastActivityDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  recommendationType?: Resolver<ResolversTypes['RecommendationTypeEnum'], ParentType, ContextType>
  resume?: Resolver<ResolversTypes['ProfResumeResponseUnion'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RecommendedCandidatesConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecommendedCandidatesConnection'] = ResolversParentTypes['RecommendedCandidatesConnection']
> = {
  edges?: Resolver<Maybe<ReadonlyArray<ResolversTypes['RecommendedCandidatesEdge']>>, ParentType, ContextType>
  nodes?: Resolver<Maybe<ReadonlyArray<ResolversTypes['RecommendedCandidate']>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RecommendedCandidatesEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecommendedCandidatesEdge'] = ResolversParentTypes['RecommendedCandidatesEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['RecommendedCandidate'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RecommendedProfResumeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecommendedProfResume'] = ResolversParentTypes['RecommendedProfResume']> = {
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  city?: Resolver<Maybe<ResolversTypes['City']>, ParentType, ContextType>
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  experience?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['RecommendedProfResumeExperience']>>>, ParentType, ContextType>
  gender?: Resolver<Maybe<ResolversTypes['Gender']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  lastActivityDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  photo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  recommendationType?: Resolver<Maybe<ResolversTypes['RecommendedProfResumeType']>, ParentType, ContextType>
  resumeSalary?: Resolver<Maybe<ResolversTypes['RecommendedProfResumeSalary']>, ParentType, ContextType>
  speciality?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  updateDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RecommendedProfResumeExperienceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecommendedProfResumeExperience'] = ResolversParentTypes['RecommendedProfResumeExperience']
> = {
  branchId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  endWork?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  period?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  startWork?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RecommendedProfResumeSalaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecommendedProfResumeSalary'] = ResolversParentTypes['RecommendedProfResumeSalary']> = {
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  currency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RecommendedProfResumesResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecommendedProfResumes'] = ResolversParentTypes['RecommendedProfResumes']> = {
  recommendedProfResumeList?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['RecommendedProfResume']>>>, ParentType, ContextType>
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RecommendedVacanciesConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecommendedVacanciesConnection'] = ResolversParentTypes['RecommendedVacanciesConnection']
> = {
  edges?: Resolver<Maybe<ReadonlyArray<ResolversTypes['RecommendedVacanciesEdge']>>, ParentType, ContextType>
  nodes?: Resolver<Maybe<ReadonlyArray<ResolversTypes['Vacancy']>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RecommendedVacanciesEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecommendedVacanciesEdge'] = ResolversParentTypes['RecommendedVacanciesEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['Vacancy'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RecruitmentOrderOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecruitmentOrderOutput'] = ResolversParentTypes['RecruitmentOrderOutput']> = {
  isSuccessful?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RegularGiftResolvers<ContextType = any, ParentType extends ResolversParentTypes['RegularGift'] = ResolversParentTypes['RegularGift']> = {
  cost?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>
  giftType?: Resolver<ResolversTypes['GiftTypeEnum'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isDeleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isSouvenir?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  standCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  storageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  weight?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RejectedByModeratorVacancyEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RejectedByModeratorVacancyEmployerEvent'] = ResolversParentTypes['RejectedByModeratorVacancyEmployerEvent']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  vacancyCity?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  vacancyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  vacancyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  vacancyOwner?: Resolver<ResolversTypes['EmployerEventSnapshot'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RejectedProlongationRequestVacancyEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RejectedProlongationRequestVacancyEmployerEvent'] = ResolversParentTypes['RejectedProlongationRequestVacancyEmployerEvent']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  vacancyCity?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  vacancyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  vacancyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RejectedReviewVacancyEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RejectedReviewVacancyEmployerEvent'] = ResolversParentTypes['RejectedReviewVacancyEmployerEvent']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  vacancyCity?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  vacancyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  vacancyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  vacancyOwner?: Resolver<ResolversTypes['EmployerEventSnapshot'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RelevanceScoreResolvers<ContextType = any, ParentType extends ResolversParentTypes['RelevanceScore'] = ResolversParentTypes['RelevanceScore']> = {
  isCalculated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RemoveSeekerFavoriteVacanciesOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RemoveSeekerFavoriteVacanciesOutput'] = ResolversParentTypes['RemoveSeekerFavoriteVacanciesOutput']
> = {
  query?: Resolver<ResolversTypes['Query'], ParentType, ContextType>
  vacancies?: Resolver<ReadonlyArray<ResolversTypes['Vacancy']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ResumeAdditionalEducationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResumeAdditionalEducation'] = ResolversParentTypes['ResumeAdditionalEducation']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  location?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  yearOfGraduation?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ResumeAdditionalInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResumeAdditionalInfo'] = ResolversParentTypes['ResumeAdditionalInfo']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ResumeContactInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResumeContactInfo'] = ResolversParentTypes['ResumeContactInfo']> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  emails?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>
  phone?: Resolver<Maybe<ResolversTypes['ResumePhone']>, ParentType, ContextType>
  phones?: Resolver<ReadonlyArray<ResolversTypes['ResumePhone']>, ParentType, ContextType>
  portfolios?: Resolver<ReadonlyArray<ResolversTypes['Uri']>, ParentType, ContextType>
  socials?: Resolver<ReadonlyArray<ResolversTypes['ResumeSocialContactLink']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ResumeDraftCreatedNotificationDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResumeDraftCreatedNotificationDetails'] = ResolversParentTypes['ResumeDraftCreatedNotificationDetails']
> = {
  detailType?: Resolver<ResolversTypes['SeekerNotificationDetailTypeEnum'], ParentType, ContextType>
  resume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ResumeEducationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResumeEducation'] = ResolversParentTypes['ResumeEducation']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  institutionTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  level?: Resolver<Maybe<ResolversTypes['EducationLevelEnum']>, ParentType, ContextType>
  location?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  speciality?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  yearOfGraduation?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ResumeErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResumeError'] = ResolversParentTypes['ResumeError']> = {
  __resolveType: TypeResolveFn<
    | 'AttachNotFound'
    | 'AttachParsingError'
    | 'ProfResumeCannotBePublished'
    | 'ProfResumeDoesNotBelongToSeeker'
    | 'ProfResumeDoesNotExist'
    | 'ProfResumeDuplicated'
    | 'ProfResumeLimit'
    | 'ProfResumePhoneConfirmationFailed',
    ParentType,
    ContextType
  >
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
}

export type ResumeExperienceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResumeExperience'] = ResolversParentTypes['ResumeExperience']> = {
  branch?: Resolver<Maybe<ResolversTypes['Branch']>, ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  endWork?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  startWork?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ResumeFillingResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResumeFilling'] = ResolversParentTypes['ResumeFilling']> = {
  percentage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ResumeFillingTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResumeFillingType'] = ResolversParentTypes['ResumeFillingType']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ResumeLanguageSkillResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResumeLanguageSkill'] = ResolversParentTypes['ResumeLanguageSkill']> = {
  certificate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  isCanPassInterview?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  language?: Resolver<ResolversTypes['Lang'], ParentType, ContextType>
  level?: Resolver<ResolversTypes['LanguageSkillLevel'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ResumePersonalInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResumePersonalInfo'] = ResolversParentTypes['ResumePersonalInfo']> = {
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  birthDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  fatherName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  gender?: Resolver<ResolversTypes['Gender'], ParentType, ContextType>
  photoUrl?: Resolver<ResolversTypes['Uri'], ParentType, ContextType>
  surName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ResumePhoneResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResumePhone'] = ResolversParentTypes['ResumePhone']> = {
  isConfirmed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ResumePublishedNotificationDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResumePublishedNotificationDetails'] = ResolversParentTypes['ResumePublishedNotificationDetails']
> = {
  detailType?: Resolver<ResolversTypes['SeekerNotificationDetailTypeEnum'], ParentType, ContextType>
  resume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ResumeReportOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResumeReportOutput'] = ResolversParentTypes['ResumeReportOutput']> = {
  isSuccessful?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ResumeSalaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResumeSalary'] = ResolversParentTypes['ResumeSalary']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ResumeSearchSuggestResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResumeSearchSuggest'] = ResolversParentTypes['ResumeSearchSuggest']> = {
  fullOption?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  score?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  suggest?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ResumeSocialContactLinkResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResumeSocialContactLink'] = ResolversParentTypes['ResumeSocialContactLink']> = {
  type?: Resolver<ResolversTypes['ContactInfoSubTypeEnum'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ResumeStateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResumeState'] = ResolversParentTypes['ResumeState']> = {
  availabilityState?: Resolver<ResolversTypes['ResumeAvailabilityStateEnum'], ParentType, ContextType>
  banInfo?: Resolver<ResolversTypes['BanInfo'], ParentType, ContextType>
  hiddenCompanies?: Resolver<ReadonlyArray<ResolversTypes['Company']>, ParentType, ContextType>
  isAllowedToShareWithPartners?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isAnonymous?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isBannedByModerator?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  searchState?: Resolver<ResolversTypes['ResumeSearchStateEnum'], ParentType, ContextType>
  state?: Resolver<ResolversTypes['ResumeActiveStateEnum'], ParentType, ContextType>
  viewsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ResumeViewInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResumeViewInfo'] = ResolversParentTypes['ResumeViewInfo']> = {
  company?: Resolver<ResolversTypes['Company'], ParentType, ContextType>
  resume?: Resolver<ResolversTypes['ProfResume'], ParentType, ContextType>
  viewDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ResumeViewsConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResumeViewsConnection'] = ResolversParentTypes['ResumeViewsConnection']> = {
  edges?: Resolver<Maybe<ReadonlyArray<ResolversTypes['ResumeViewsEdge']>>, ParentType, ContextType>
  items?: Resolver<Maybe<ReadonlyArray<ResolversTypes['ResumeViewInfo']>>, ParentType, ContextType>
  newCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  nodes?: Resolver<Maybe<ReadonlyArray<ResolversTypes['ResumeViewInfo']>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ResumeViewsEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResumeViewsEdge'] = ResolversParentTypes['ResumeViewsEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['ResumeViewInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RisingUpCatalogServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['RisingUpCatalogService'] = ResolversParentTypes['RisingUpCatalogService']> = {
  calculatePrice?: Resolver<ResolversTypes['ServicePrice'], ParentType, ContextType, Partial<RisingUpCatalogServiceCalculatePriceArgs>>
  groupWrapper?: Resolver<ResolversTypes['ServiceGroupWrapper'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  packagesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  typeWrapper?: Resolver<ResolversTypes['ServiceTypeWrapper'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RubricResolvers<ContextType = any, ParentType extends ResolversParentTypes['Rubric'] = ResolversParentTypes['Rubric']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  statistic?: Resolver<Maybe<ResolversTypes['StatisticData']>, ParentType, ContextType, RequireFields<RubricStatisticArgs, 'input'>>
  subrubrics?: Resolver<ReadonlyArray<ResolversTypes['Subrubric']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RubricFacetResolvers<ContextType = any, ParentType extends ResolversParentTypes['RubricFacet'] = ResolversParentTypes['RubricFacet']> = {
  count?: Resolver<ResolversTypes['Long'], ParentType, ContextType>
  rubric?: Resolver<ResolversTypes['Rubric'], ParentType, ContextType>
  subrubricsFacets?: Resolver<ReadonlyArray<ResolversTypes['SubrubricsFacet']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SalaryStatisticsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalaryStatistics'] = ResolversParentTypes['SalaryStatistics']> = {
  futurePeriodsMedians?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['PeriodAmount']>>>, ParentType, ContextType>
  previousMonthCount?: Resolver<Maybe<ResolversTypes['PeriodAmount']>, ParentType, ContextType>
  previousMonthHistogram?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Bucket']>>>, ParentType, ContextType>
  previousMonthMax?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  previousMonthMedian?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  previousMonthMin?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  previousPeriodsMedians?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['PeriodAmount']>>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SaleManagerMutationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SaleManagerMutations'] = ResolversParentTypes['SaleManagerMutations']> = {
  addManagerAlarm?: Resolver<ResolversTypes['SaleManagerResponseOutput'], ParentType, ContextType, RequireFields<SaleManagerMutationsAddManagerAlarmArgs, 'input'>>
  addOtherSiteLink?: Resolver<ResolversTypes['AddOtherSiteLinkOutput'], ParentType, ContextType, RequireFields<SaleManagerMutationsAddOtherSiteLinkArgs, 'input'>>
  addTypicalContact?: Resolver<ResolversTypes['ContractCreationOutput'], ParentType, ContextType, RequireFields<SaleManagerMutationsAddTypicalContactArgs, 'input'>>
  deleteContract?: Resolver<ResolversTypes['SaleManagerResponseOutput'], ParentType, ContextType, RequireFields<SaleManagerMutationsDeleteContractArgs, 'contractId' | 'typical'>>
  rejectPayment?: Resolver<ResolversTypes['CompanyAssignmentOutput'], ParentType, ContextType, RequireFields<SaleManagerMutationsRejectPaymentArgs, 'input'>>
  unbindPayer?: Resolver<ResolversTypes['UnlinkPayerOutput'], ParentType, ContextType, RequireFields<SaleManagerMutationsUnbindPayerArgs, 'companyId' | 'payerId'>>
  updatePayer?: Resolver<ResolversTypes['SaleManagerResponseOutput'], ParentType, ContextType, RequireFields<SaleManagerMutationsUpdatePayerArgs, 'input'>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SaleManagerQueriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['SaleManagerQueries'] = ResolversParentTypes['SaleManagerQueries']> = {
  checkPayer?: Resolver<ResolversTypes['CheckPayerResultOutput'], ParentType, ContextType, RequireFields<SaleManagerQueriesCheckPayerArgs, 'code' | 'companyId'>>
  companyCards?: Resolver<Maybe<ResolversTypes['CompanyCardConnection']>, ParentType, ContextType, Partial<SaleManagerQueriesCompanyCardsArgs>>
  helperCompanies?: Resolver<Maybe<ResolversTypes['HelperCompanyConnection']>, ParentType, ContextType, Partial<SaleManagerQueriesHelperCompaniesArgs>>
  payer?: Resolver<ResolversTypes['Payer'], ParentType, ContextType, RequireFields<SaleManagerQueriesPayerArgs, 'payerId'>>
  payerResponsibleBasis?: Resolver<ReadonlyArray<ResolversTypes['DirectoryItem']>, ParentType, ContextType>
  ticketsAway?: Resolver<ReadonlyArray<ResolversTypes['CompanyCardTicket']>, ParentType, ContextType, RequireFields<SaleManagerQueriesTicketsAwayArgs, 'input'>>
  ticketsWillBurn?: Resolver<ReadonlyArray<ResolversTypes['CompanyCardTicket']>, ParentType, ContextType, RequireFields<SaleManagerQueriesTicketsWillBurnArgs, 'input'>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SaleManagerResponseOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SaleManagerResponseOutput'] = ResolversParentTypes['SaleManagerResponseOutput']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  messageText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SaveVacancyInterviewQuestionsErrorUnionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SaveVacancyInterviewQuestionsErrorUnion'] = ResolversParentTypes['SaveVacancyInterviewQuestionsErrorUnion']
> = {
  __resolveType: TypeResolveFn<'InvalidError' | 'NotFoundError', ParentType, ContextType>
}

export type SaveVacancyInterviewQuestionsOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SaveVacancyInterviewQuestionsOutput'] = ResolversParentTypes['SaveVacancyInterviewQuestionsOutput']
> = {
  errors?: Resolver<ReadonlyArray<ResolversTypes['SaveVacancyInterviewQuestionsErrorUnion']>, ParentType, ContextType>
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  questions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SavedCardResolvers<ContextType = any, ParentType extends ResolversParentTypes['SavedCard'] = ResolversParentTypes['SavedCard']> = {
  addDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isMain?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  mask?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  paymentSystem?: Resolver<ResolversTypes['PaymentSystemTypeEnum'], ParentType, ContextType>
  provider?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SavedCardsMutationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SavedCardsMutations'] = ResolversParentTypes['SavedCardsMutations']> = {
  add?: Resolver<ResolversTypes['ChangeSavedCardsResponse'], ParentType, ContextType, RequireFields<SavedCardsMutationsAddArgs, 'isForOnePayment' | 'mask' | 'token'>>
  makeMain?: Resolver<ResolversTypes['ChangeSavedCardsResponse'], ParentType, ContextType, RequireFields<SavedCardsMutationsMakeMainArgs, 'id'>>
  remove?: Resolver<ResolversTypes['ChangeSavedCardsResponse'], ParentType, ContextType, RequireFields<SavedCardsMutationsRemoveArgs, 'id'>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ScheduleResolvers<ContextType = any, ParentType extends ResolversParentTypes['Schedule'] = ResolversParentTypes['Schedule']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ScheduleFacetResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScheduleFacet'] = ResolversParentTypes['ScheduleFacet']> = {
  count?: Resolver<ResolversTypes['Long'], ParentType, ContextType>
  schedule?: Resolver<ResolversTypes['Schedule'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ScrollInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['ScrollInfo'] = ResolversParentTypes['ScrollInfo']> = {
  currentOffset?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  hasNextOffset?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  nextOffset?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SeekerApplicationResolvers<ContextType = any, ParentType extends ResolversParentTypes['SeekerApplication'] = ResolversParentTypes['SeekerApplication']> = {
  isApplied?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  lastTimeAppliedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SeekerApplyQueriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['SeekerApplyQueries'] = ResolversParentTypes['SeekerApplyQueries']> = {
  list?: Resolver<ReadonlyArray<ResolversTypes['Apply']>, ParentType, ContextType, RequireFields<SeekerApplyQueriesListArgs, 'input'>>
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SeekerDislikedVacancyDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SeekerDislikedVacancyDetails'] = ResolversParentTypes['SeekerDislikedVacancyDetails']> = {
  dislikedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  isDisliked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SeekerDislikedVacancyMutationsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeekerDislikedVacancyMutations'] = ResolversParentTypes['SeekerDislikedVacancyMutations']
> = {
  dislike?: Resolver<ResolversTypes['DislikeSeekerVacanciesOutput'], ParentType, ContextType, RequireFields<SeekerDislikedVacancyMutationsDislikeArgs, 'input'>>
  undislike?: Resolver<ResolversTypes['UndislikeSeekerVacanciesOutput'], ParentType, ContextType, RequireFields<SeekerDislikedVacancyMutationsUndislikeArgs, 'input'>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SeekerDislikedVacancyQueriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['SeekerDislikedVacancyQueries'] = ResolversParentTypes['SeekerDislikedVacancyQueries']> = {
  list?: Resolver<ResolversTypes['ListSeekerDislikedVacanciesOutput'], ParentType, ContextType, RequireFields<SeekerDislikedVacancyQueriesListArgs, 'input'>>
  totalCount?: Resolver<ResolversTypes['GetSeekerDislikedVacanciesTotalCountOutput'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SeekerFavoriteVacancyDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SeekerFavoriteVacancyDetails'] = ResolversParentTypes['SeekerFavoriteVacancyDetails']> = {
  addedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  isFavorite?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SeekerFavoriteVacancyMutationsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeekerFavoriteVacancyMutations'] = ResolversParentTypes['SeekerFavoriteVacancyMutations']
> = {
  add?: Resolver<ResolversTypes['AddSeekerFavoriteVacanciesOutput'], ParentType, ContextType, RequireFields<SeekerFavoriteVacancyMutationsAddArgs, 'input'>>
  remove?: Resolver<ResolversTypes['RemoveSeekerFavoriteVacanciesOutput'], ParentType, ContextType, RequireFields<SeekerFavoriteVacancyMutationsRemoveArgs, 'input'>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SeekerFavoriteVacancyQueriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['SeekerFavoriteVacancyQueries'] = ResolversParentTypes['SeekerFavoriteVacancyQueries']> = {
  list?: Resolver<ResolversTypes['ListSeekerFavoriteVacanciesOutput'], ParentType, ContextType, Partial<SeekerFavoriteVacancyQueriesListArgs>>
  totalCount?: Resolver<ResolversTypes['GetSeekerFavoriteVacanciesTotalCountOutput'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SeekerMutationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SeekerMutations'] = ResolversParentTypes['SeekerMutations']> = {
  changeEmail?: Resolver<ResolversTypes['ChangeSeekerEmailMutation'], ParentType, ContextType>
  changeName?: Resolver<ResolversTypes['ChangeSeekerNameMutationOutput'], ParentType, ContextType, RequireFields<SeekerMutationsChangeNameArgs, 'input'>>
  changePassword?: Resolver<ResolversTypes['ChangeSeekerPasswordMutations'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SeekerRecommendationProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['SeekerRecommendationProfile'] = ResolversParentTypes['SeekerRecommendationProfile']> = {
  cities?: Resolver<ReadonlyArray<ResolversTypes['City']>, ParentType, ContextType, Partial<SeekerRecommendationProfileCitiesArgs>>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isTop?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  keywords?: Resolver<ReadonlyArray<ResolversTypes['Keyword']>, ParentType, ContextType, Partial<SeekerRecommendationProfileKeywordsArgs>>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  weight?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SeekerRegistrationResolvers<ContextType = any, ParentType extends ResolversParentTypes['SeekerRegistration'] = ResolversParentTypes['SeekerRegistration']> = {
  createProfile?: Resolver<ResolversTypes['CreateSeekerProfileResponse'], ParentType, ContextType, RequireFields<SeekerRegistrationCreateProfileArgs, 'input'>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SeekerSubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SeekerSubscription'] = ResolversParentTypes['SeekerSubscription']> = {
  addDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  channels?: Resolver<ReadonlyArray<ResolversTypes['SeekerSubscriptionChannel']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isActiveProfile?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  language?: Resolver<ResolversTypes['SubscriptionLanguageEnum'], ParentType, ContextType>
  profile?: Resolver<Maybe<ResolversTypes['SeekerSubscriptionProfile']>, ParentType, ContextType>
  type?: Resolver<ResolversTypes['SubscriptionTypeEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SeekerSubscriptionActivationResolvers<ContextType = any, ParentType extends ResolversParentTypes['SeekerSubscriptionActivation'] = ResolversParentTypes['SeekerSubscriptionActivation']> = {
  all?: Resolver<ResolversTypes['SubscriptionOutputType'], ParentType, ContextType>
  byId?: Resolver<ResolversTypes['SubscriptionOutputType'], ParentType, ContextType, RequireFields<SeekerSubscriptionActivationByIdArgs, 'id'>>
  byProfile?: Resolver<ResolversTypes['SubscriptionOutputType'], ParentType, ContextType, RequireFields<SeekerSubscriptionActivationByProfileArgs, 'profileId'>>
  byType?: Resolver<ResolversTypes['SubscriptionOutputType'], ParentType, ContextType, RequireFields<SeekerSubscriptionActivationByTypeArgs, 'type'>>
  byTypes?: Resolver<ResolversTypes['SubscriptionOutputType'], ParentType, ContextType, RequireFields<SeekerSubscriptionActivationByTypesArgs, 'types'>>
  manual?: Resolver<ResolversTypes['SubscriptionOutputType'], ParentType, ContextType>
  recommended?: Resolver<ResolversTypes['SubscriptionOutputType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SeekerSubscriptionChannelResolvers<ContextType = any, ParentType extends ResolversParentTypes['SeekerSubscriptionChannel'] = ResolversParentTypes['SeekerSubscriptionChannel']> = {
  addDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  lastUpdateDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['SubscriptionChannelTypeEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SeekerSubscriptionManualProfileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeekerSubscriptionManualProfile'] = ResolversParentTypes['SeekerSubscriptionManualProfile']
> = {
  city?: Resolver<Maybe<ResolversTypes['City']>, ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  keyword?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  keywordSynonyms?: Resolver<ReadonlyArray<ResolversTypes['Synonym']>, ParentType, ContextType>
  keywords?: Resolver<ReadonlyArray<ResolversTypes['Keyword']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  rubric?: Resolver<Maybe<ResolversTypes['Rubric']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SeekerSubscriptionMutationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SeekerSubscriptionMutations'] = ResolversParentTypes['SeekerSubscriptionMutations']> = {
  addSeekerSubscription?: Resolver<ResolversTypes['AddSeekerSubscriptionMutations'], ParentType, ContextType>
  changeLanguage?: Resolver<ResolversTypes['SubscriptionOutputType'], ParentType, ContextType, RequireFields<SeekerSubscriptionMutationsChangeLanguageArgs, 'lang'>>
  deleteSubscription?: Resolver<ResolversTypes['SubscriptionOutputType'], ParentType, ContextType, RequireFields<SeekerSubscriptionMutationsDeleteSubscriptionArgs, 'id'>>
  setActive?: Resolver<ResolversTypes['SeekerSubscriptionActivation'], ParentType, ContextType, RequireFields<SeekerSubscriptionMutationsSetActiveArgs, 'isActive'>>
  updateManualProfile?: Resolver<ResolversTypes['SubscriptionOutputType'], ParentType, ContextType, RequireFields<SeekerSubscriptionMutationsUpdateManualProfileArgs, 'input'>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SeekerSubscriptionProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['SeekerSubscriptionProfile'] = ResolversParentTypes['SeekerSubscriptionProfile']> = {
  __resolveType: TypeResolveFn<'SeekerRecommendationProfile' | 'SeekerSubscriptionManualProfile', ParentType, ContextType>
}

export type SeekerSubscriptionSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['SeekerSubscriptionSummary'] = ResolversParentTypes['SeekerSubscriptionSummary']> = {
  channels?: Resolver<ReadonlyArray<ResolversTypes['SeekerSubscriptionChannel']>, ParentType, ContextType>
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  isContainsActiveSubscription?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isContainsProfile?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['SubscriptionTypeEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SeekerWalletUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['SeekerWalletUser'] = ResolversParentTypes['SeekerWalletUser']> = {
  seeker?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SelectPromoCodeResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SelectPromoCodeResponse'] = ResolversParentTypes['SelectPromoCodeResponse']> = {
  cart?: Resolver<ResolversTypes['CartQuery'], ParentType, ContextType>
  errors?: Resolver<ReadonlyArray<ResolversTypes['SelectPromoCodeErrorEnum']>, ParentType, ContextType>
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SendChangeSeekerEmailOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SendChangeSeekerEmailOutput'] = ResolversParentTypes['SendChangeSeekerEmailOutput']> = {
  sendEmailOutput?: Resolver<Maybe<ResolversTypes['SendEmailOutput']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['SendChangeSeekerEmailStatusEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SendEmailOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SendEmailOutput'] = ResolversParentTypes['SendEmailOutput']> = {
  remainingAttempts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['SendEmailStatusEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SendNotificationSetEmailDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SendNotificationSetEmailDetails'] = ResolversParentTypes['SendNotificationSetEmailDetails']
> = {
  detailType?: Resolver<ResolversTypes['SeekerNotificationDetailTypeEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SendSmsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SendSmsOutput'] = ResolversParentTypes['SendSmsOutput']> = {
  remainingAttempts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['SendSmsStatusEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SentToReviewVacancyEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SentToReviewVacancyEmployerEvent'] = ResolversParentTypes['SentToReviewVacancyEmployerEvent']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  vacancyCity?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  vacancyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  vacancyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ServerErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServerError'] = ResolversParentTypes['ServerError']> = {
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ServiceActivationOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceActivationOutput'] = ResolversParentTypes['ServiceActivationOutput']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  state?: Resolver<Maybe<ResolversTypes['ServiceState']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ServiceActivatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceActivator'] = ResolversParentTypes['ServiceActivator']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['ServiceActivatorType']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ServiceCompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceCompany'] = ResolversParentTypes['ServiceCompany']> = {
  hasServicesLimitAccess?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  myOrders?: Resolver<Maybe<ResolversTypes['OrderConnection']>, ParentType, ContextType, Partial<ServiceCompanyMyOrdersArgs>>
  myServices?: Resolver<Maybe<ResolversTypes['MyServicesConnection']>, ParentType, ContextType, Partial<ServiceCompanyMyServicesArgs>>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  onlyMainUserCanActivateService?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  summaryServices?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['SummaryService']>>>, ParentType, ContextType, Partial<ServiceCompanySummaryServicesArgs>>
  turbotaStatus?: Resolver<ResolversTypes['ServiceCompanyTurbotaStatus'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ServiceCompanyTurbotaStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceCompanyTurbotaStatus'] = ResolversParentTypes['ServiceCompanyTurbotaStatus']> = {
  activatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  endsAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  hasTurbotaAccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isActiveNow?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  order?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ServiceCvdbRegionsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceCvdbRegionsOutput'] = ResolversParentTypes['ServiceCvdbRegionsOutput']> = {
  cities?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['City']>>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ServiceCvdbRubricsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceCvdbRubricsOutput'] = ResolversParentTypes['ServiceCvdbRubricsOutput']> = {
  rubrics?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Rubric']>>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ServiceDiscountTypeWrapperResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceDiscountTypeWrapper'] = ResolversParentTypes['ServiceDiscountTypeWrapper']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['CatalogServiceDiscountEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ServiceEmployeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceEmployee'] = ResolversParentTypes['ServiceEmployee']> = {
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  serviceLimitCounters?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['EmployeeLimitCounter']>>>, ParentType, ContextType, Partial<ServiceEmployeeServiceLimitCountersArgs>>
  serviceLimits?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['EmployeeSummaryServiceLimit']>>>, ParentType, ContextType, Partial<ServiceEmployeeServiceLimitsArgs>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ServiceEmployerEventSnapshotUnionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ServiceEmployerEventSnapshotUnion'] = ResolversParentTypes['ServiceEmployerEventSnapshotUnion']
> = {
  __resolveType: TypeResolveFn<
    | 'AdditionalServiceEmployerEventSnapshot'
    | 'CvDbServiceEmployerEventSnapshot'
    | 'HotServiceEmployerEventSnapshot'
    | 'LogoInPortalServiceEmployerEventSnapshot'
    | 'LogoInRubricServiceEmployerEventSnapshot'
    | 'LogoOnMainPageServiceEmployerEventSnapshot'
    | 'MarketingServiceEmployerEventSnapshot'
    | 'PackageServiceEmployerEventSnapshot'
    | 'PublicationServiceEmployerEventSnapshot',
    ParentType,
    ContextType
  >
}

export type ServiceGroupWrapperResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceGroupWrapper'] = ResolversParentTypes['ServiceGroupWrapper']> = {
  group?: Resolver<ResolversTypes['ServiceGroupEnum'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ServiceIsExpiringEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceIsExpiringEvent'] = ResolversParentTypes['ServiceIsExpiringEvent']> = {
  addDate?: Resolver<ResolversTypes['DateTimeOffset'], ParentType, ContextType>
  assignment?: Resolver<Maybe<ResolversTypes['Assignment']>, ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['CrmCompany']>, ParentType, ContextType>
  daysLeft?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  dealNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  employee?: Resolver<Maybe<ResolversTypes['CrmEmployee']>, ParentType, ContextType>
  eventType?: Resolver<ResolversTypes['EventTypeEnum'], ParentType, ContextType>
  expirationDate?: Resolver<Maybe<ResolversTypes['DateTimeOffset']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isArchieved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isRead?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  lifetimeDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  orderNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  serviceLeftCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  serviceName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  state?: Resolver<ResolversTypes['EventStateEnum'], ParentType, ContextType>
  topics?: Resolver<ReadonlyArray<ResolversTypes['Topic']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ServiceLimitResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceLimit'] = ResolversParentTypes['ServiceLimit']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ServicePriceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServicePrice'] = ResolversParentTypes['ServicePrice']> = {
  actionDiscountAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  appliedBonusAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  currentTotalPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  currentUnitPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  defaultTotalPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  defaultUnitPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  discountValueFixed?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  discountValuePercent?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  discounts?: Resolver<ReadonlyArray<ResolversTypes['ServiceDiscountTypeWrapper']>, ParentType, ContextType>
  optDiscountsAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  promoCodeDiscountsAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  requestedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ServicePurchaseHistoryOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServicePurchaseHistoryOutput'] = ResolversParentTypes['ServicePurchaseHistoryOutput']> = {
  everBoughtCVDB?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  everBoughtPackages?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  everBoughtPublications?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  lastPurchasedServices?: Resolver<ReadonlyArray<Maybe<ResolversTypes['ServicePurchasedService']>>, ParentType, ContextType, Partial<ServicePurchaseHistoryOutputLastPurchasedServicesArgs>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ServicePurchasedServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServicePurchasedService'] = ResolversParentTypes['ServicePurchasedService']> = {
  city?: Resolver<Maybe<ResolversTypes['City']>, ParentType, ContextType>
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  date?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  price?: Resolver<ResolversTypes['ServicePrice'], ParentType, ContextType>
  rubric?: Resolver<Maybe<ResolversTypes['Rubric']>, ParentType, ContextType>
  type?: Resolver<ResolversTypes['ServiceTypeWrapper'], ParentType, ContextType>
  usagePeriod?: Resolver<Maybe<ResolversTypes['UsagePeriodTypeWrapper']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ServiceTypeWrapperResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceTypeWrapper'] = ResolversParentTypes['ServiceTypeWrapper']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['ServiceType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ServiceUnitedResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceUnited'] = ResolversParentTypes['ServiceUnited']> = {
  __resolveType: TypeResolveFn<
    | 'ActivatedAdditionalService'
    | 'ActivatedCvDbService'
    | 'ActivatedHotService'
    | 'ActivatedLogoInPortalService'
    | 'ActivatedLogoInRubricService'
    | 'ActivatedRisingUpService'
    | 'ActivatedVacancyPackageService'
    | 'ActivatedVacancyPublicationService'
    | 'ClosedAdditionalService'
    | 'ClosedCvDbService'
    | 'ClosedHotService'
    | 'ClosedLogoInPortalService'
    | 'ClosedLogoInRubricService'
    | 'ClosedMarketingService'
    | 'ClosedRisingUpService'
    | 'ClosedVacancyPackageService'
    | 'ClosedVacancyPublicationService'
    | 'OrderedAdditionalService'
    | 'OrderedCvDbService'
    | 'OrderedHotService'
    | 'OrderedLogoInPortalService'
    | 'OrderedLogoInRubricService'
    | 'OrderedMarketingService'
    | 'OrderedRisingUpService'
    | 'OrderedVacancyPackageService'
    | 'OrderedVacancyPublicationService',
    ParentType,
    ContextType
  >
}

export type ServiceUnitedEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServiceUnitedEdge'] = ResolversParentTypes['ServiceUnitedEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<Maybe<ResolversTypes['ServiceUnited']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ServicesFiltersResolvers<ContextType = any, ParentType extends ResolversParentTypes['ServicesFilters'] = ResolversParentTypes['ServicesFilters']> = {
  dateRange?: Resolver<Maybe<ResolversTypes['DateInterval']>, ParentType, ContextType>
  managerId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  offset?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  payStatusIds?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CrmPayState']>>>, ParentType, ContextType>
  paymentDateRange?: Resolver<Maybe<ResolversTypes['DateInterval']>, ParentType, ContextType>
  positionStates?: Resolver<Maybe<ReadonlyArray<ResolversTypes['CrmPositionState']>>, ParentType, ContextType>
  query?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  serviceId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ServicesManagementClientFiltersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ServicesManagementClientFilters'] = ResolversParentTypes['ServicesManagementClientFilters']
> = {
  states?: Resolver<Maybe<ReadonlyArray<ResolversTypes['ServiceState']>>, ParentType, ContextType>
  types?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UiServiceType']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SetActionStateOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SetActionStateOutput'] = ResolversParentTypes['SetActionStateOutput']> = {
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SimilarVacanciesResolvers<ContextType = any, ParentType extends ResolversParentTypes['SimilarVacancies'] = ResolversParentTypes['SimilarVacancies']> = {
  items?: Resolver<ReadonlyArray<ResolversTypes['Vacancy']>, ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Long']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SimilarVacanciesQueriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['SimilarVacanciesQueries'] = ResolversParentTypes['SimilarVacanciesQueries']> = {
  byKeyword?: Resolver<ResolversTypes['SimilarVacancies'], ParentType, ContextType, RequireFields<SimilarVacanciesQueriesByKeywordArgs, 'cityId' | 'keyword'>>
  byResume?: Resolver<ResolversTypes['SimilarVacancies'], ParentType, ContextType, RequireFields<SimilarVacanciesQueriesByResumeArgs, 'resumeId'>>
  byVacancy?: Resolver<ResolversTypes['SimilarVacancies'], ParentType, ContextType, RequireFields<SimilarVacanciesQueriesByVacancyArgs, 'vacancyId'>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SimilarVacancyKeywordsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SimilarVacancyKeywordsOutput'] = ResolversParentTypes['SimilarVacancyKeywordsOutput']> = {
  keywords?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SpiderVacancyResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpiderVacancy'] = ResolversParentTypes['SpiderVacancy']> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  business?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  cityAdditional?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  cityId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  companyConfirmed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  companyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  companyLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  companyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  contactPerson?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  contactPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  hot?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  noCvApply?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  salaryComment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  salaryFrom?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  salaryTo?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  schedules?: Resolver<ReadonlyArray<ResolversTypes['ScheduleEnum']>, ParentType, ContextType>
  source?: Resolver<ResolversTypes['OtherSiteEnum'], ParentType, ContextType>
  specialNeeds?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  student?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  vacancyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  vacancyLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  vipCompany?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StartGamificationWaveOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['StartGamificationWaveOutput'] = ResolversParentTypes['StartGamificationWaveOutput']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StatisticResolvers<ContextType = any, ParentType extends ResolversParentTypes['Statistic'] = ResolversParentTypes['Statistic']> = {
  data?: Resolver<Maybe<ResolversTypes['StatisticData']>, ParentType, ContextType, RequireFields<StatisticDataArgs, 'search'>>
  structure?: Resolver<Maybe<ResolversTypes['StatisticStructure']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StatisticBranchResolvers<ContextType = any, ParentType extends ResolversParentTypes['StatisticBranch'] = ResolversParentTypes['StatisticBranch']> = {
  branch?: Resolver<Maybe<ResolversTypes['Branch']>, ParentType, ContextType>
  percent?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StatisticBranchTimeseriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['StatisticBranchTimeseries'] = ResolversParentTypes['StatisticBranchTimeseries']> = {
  begin?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  branches?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['StatisticBranch']>>>, ParentType, ContextType>
  end?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  others?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StatisticCandidatesStructureResolvers<ContextType = any, ParentType extends ResolversParentTypes['StatisticCandidatesStructure'] = ResolversParentTypes['StatisticCandidatesStructure']> = {
  domain?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['StatisticRubricTimeseries']>>>, ParentType, ContextType, RequireFields<StatisticCandidatesStructureDomainArgs, 'first' | 'staticTop'>>
  professions?: Resolver<Maybe<ResolversTypes['StatisticProfessionItem']>, ParentType, ContextType, Partial<StatisticCandidatesStructureProfessionsArgs>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StatisticDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['StatisticData'] = ResolversParentTypes['StatisticData']> = {
  candidate?: Resolver<Maybe<ResolversTypes['StatisticItem']>, ParentType, ContextType>
  period?: Resolver<Maybe<ResolversTypes['PeriodType']>, ParentType, ContextType>
  vacancy?: Resolver<Maybe<ResolversTypes['StatisticItem']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StatisticItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['StatisticItem'] = ResolversParentTypes['StatisticItem']> = {
  count?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['StatisticTimeseries']>>>, ParentType, ContextType>
  max?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['StatisticTimeseries']>>>, ParentType, ContextType>
  median?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['StatisticTimeseries']>>>, ParentType, ContextType>
  min?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['StatisticTimeseries']>>>, ParentType, ContextType>
  total?: Resolver<Maybe<ResolversTypes['StatisticTotal']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StatisticProfessionResolvers<ContextType = any, ParentType extends ResolversParentTypes['StatisticProfession'] = ResolversParentTypes['StatisticProfession']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  profession?: Resolver<Maybe<ResolversTypes['Keyword']>, ParentType, ContextType>
  salary?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StatisticProfessionByCountTimeSeriesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatisticProfessionByCountTimeSeries'] = ResolversParentTypes['StatisticProfessionByCountTimeSeries']
> = {
  begin?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  end?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  professions?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['StatisticProfession']>>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StatisticProfessionItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['StatisticProfessionItem'] = ResolversParentTypes['StatisticProfessionItem']> = {
  timeseriesByCount?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['StatisticProfessionByCountTimeSeries']>>>,
    ParentType,
    ContextType,
    RequireFields<StatisticProfessionItemTimeseriesByCountArgs, 'first' | 'period'>
  >
  topByCount?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['StatisticProfession']>>>, ParentType, ContextType, RequireFields<StatisticProfessionItemTopByCountArgs, 'first'>>
  topBySalary?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['StatisticProfession']>>>,
    ParentType,
    ContextType,
    RequireFields<StatisticProfessionItemTopBySalaryArgs, 'first' | 'minDocsWithSalary'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StatisticRubricResolvers<ContextType = any, ParentType extends ResolversParentTypes['StatisticRubric'] = ResolversParentTypes['StatisticRubric']> = {
  percent?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>
  rubric?: Resolver<Maybe<ResolversTypes['Rubric']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StatisticRubricTimeseriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['StatisticRubricTimeseries'] = ResolversParentTypes['StatisticRubricTimeseries']> = {
  begin?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  end?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  others?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>
  rubrics?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['StatisticRubric']>>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StatisticSalaryDistributionResolvers<ContextType = any, ParentType extends ResolversParentTypes['StatisticSalaryDistribution'] = ResolversParentTypes['StatisticSalaryDistribution']> = {
  begin?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  end?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StatisticStructureResolvers<ContextType = any, ParentType extends ResolversParentTypes['StatisticStructure'] = ResolversParentTypes['StatisticStructure']> = {
  candidate?: Resolver<Maybe<ResolversTypes['StatisticCandidatesStructure']>, ParentType, ContextType>
  vacancy?: Resolver<Maybe<ResolversTypes['StatisticVacanciesStructure']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StatisticTimeseriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['StatisticTimeseries'] = ResolversParentTypes['StatisticTimeseries']> = {
  begin?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  end?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  prediction?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StatisticTotalResolvers<ContextType = any, ParentType extends ResolversParentTypes['StatisticTotal'] = ResolversParentTypes['StatisticTotal']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  salary?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  salaryDistribution?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['StatisticSalaryDistribution']>>>, ParentType, ContextType, RequireFields<StatisticTotalSalaryDistributionArgs, 'bins'>>
  salaryMax?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  salaryMin?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StatisticVacanciesStructureResolvers<ContextType = any, ParentType extends ResolversParentTypes['StatisticVacanciesStructure'] = ResolversParentTypes['StatisticVacanciesStructure']> = {
  domain?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['StatisticBranchTimeseries']>>>, ParentType, ContextType, RequireFields<StatisticVacanciesStructureDomainArgs, 'first' | 'staticTop'>>
  professions?: Resolver<Maybe<ResolversTypes['StatisticProfessionItem']>, ParentType, ContextType, RequireFields<StatisticVacanciesStructureProfessionsArgs, 'keyword'>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StatisticVacancyBadgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['StatisticVacancyBadge'] = ResolversParentTypes['StatisticVacancyBadge']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  percent?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SubrubricResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subrubric'] = ResolversParentTypes['Subrubric']> = {
  externalCourses?: Resolver<ReadonlyArray<ResolversTypes['ExternalCourse']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  rubric?: Resolver<ResolversTypes['Rubric'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SubrubricsFacetResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubrubricsFacet'] = ResolversParentTypes['SubrubricsFacet']> = {
  count?: Resolver<ResolversTypes['Long'], ParentType, ContextType>
  subrubric?: Resolver<ResolversTypes['Subrubric'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SubscriptionOutputTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubscriptionOutputType'] = ResolversParentTypes['SubscriptionOutputType']> = {
  subscriptionIds?: Resolver<ReadonlyArray<ResolversTypes['ID']>, ParentType, ContextType>
  subscriptions?: Resolver<ReadonlyArray<ResolversTypes['SeekerSubscription']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SuggestedVacancySearchOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['SuggestedVacancySearchOutput'] = ResolversParentTypes['SuggestedVacancySearchOutput']> = {
  keywords?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SummaryServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['SummaryService'] = ResolversParentTypes['SummaryService']> = {
  activated?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  activatedAccrualPeriod?: Resolver<Maybe<ResolversTypes['ContactsAccrualPeriod']>, ParentType, ContextType>
  activatedContactsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  inactiveAccrualPeriod?: Resolver<Maybe<ResolversTypes['ContactsAccrualPeriod']>, ParentType, ContextType>
  inactiveContactsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  limitedUserTotal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  remainingLimits?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  serviceType?: Resolver<Maybe<ResolversTypes['ServiceType']>, ParentType, ContextType>
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  usedContactsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SynonymResolvers<ContextType = any, ParentType extends ResolversParentTypes['Synonym'] = ResolversParentTypes['Synonym']> = {
  group?: Resolver<ResolversTypes['Keyword'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SynonymTagResolvers<ContextType = any, ParentType extends ResolversParentTypes['SynonymTag'] = ResolversParentTypes['SynonymTag']> = {
  group?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SystemEventPerformerResolvers<ContextType = any, ParentType extends ResolversParentTypes['SystemEventPerformer'] = ResolversParentTypes['SystemEventPerformer']> = {
  isSystem?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SystemWalletUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['SystemWalletUser'] = ResolversParentTypes['SystemWalletUser']> = {
  isSystem?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TagGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['TagGroup'] = ResolversParentTypes['TagGroup']> = {
  childTags?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['TagGroup']>>>, ParentType, ContextType>
  childTagsProf?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Int']>>>, ParentType, ContextType>
  clusterId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isProf?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  parentTags?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['TagGroup']>>>, ParentType, ContextType>
  parentTagsProf?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Int']>>>, ParentType, ContextType>
  popularBranches?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Branch']>>>, ParentType, ContextType>
  popularCompanies?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Company']>>>, ParentType, ContextType>
  synonymIds?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Int']>>>, ParentType, ContextType>
  synonymNames?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetMailingListFilterResolvers<ContextType = any, ParentType extends ResolversParentTypes['TargetMailingListFilter'] = ResolversParentTypes['TargetMailingListFilter']> = {
  statuses?: Resolver<Maybe<ReadonlyArray<ResolversTypes['TargetedMailingStatusEnum']>>, ParentType, ContextType>
  term?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetMailingListQueryFilterResolvers<ContextType = any, ParentType extends ResolversParentTypes['TargetMailingListQueryFilter'] = ResolversParentTypes['TargetMailingListQueryFilter']> = {
  filter?: Resolver<ResolversTypes['TargetMailingListFilter'], ParentType, ContextType>
  page?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedExternalMailingLinkResolvers<ContextType = any, ParentType extends ResolversParentTypes['TargetedExternalMailingLink'] = ResolversParentTypes['TargetedExternalMailingLink']> = {
  clicks?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedMailingResolvers<ContextType = any, ParentType extends ResolversParentTypes['TargetedMailing'] = ResolversParentTypes['TargetedMailing']> = {
  candidatesSamplingType?: Resolver<Maybe<ResolversTypes['TargetedMailingCandidatesSamplingTypeEnum']>, ParentType, ContextType>
  company?: Resolver<ResolversTypes['Company'], ParentType, ContextType>
  content?: Resolver<Maybe<ReadonlyArray<ResolversTypes['TargetedMailingContentUnion']>>, ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  history?: Resolver<ReadonlyArray<ResolversTypes['TargetedMailingHistoryFactUnion']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  plannedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  readyToSpendServicesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  responsible?: Resolver<ResolversTypes['TargetedMailingResponsible'], ParentType, ContextType>
  samplingSnapshot?: Resolver<Maybe<ResolversTypes['TargetedMailingCandidatesSampling']>, ParentType, ContextType>
  sentAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  statistics?: Resolver<Maybe<ResolversTypes['MailingStatistics']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['TargetedMailingStatusEnum'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedMailingCandidatesLocationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TargetedMailingCandidatesLocation'] = ResolversParentTypes['TargetedMailingCandidatesLocation']
> = {
  city?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['TargetedMailingCandidatesLocationEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedMailingCandidatesRubricResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TargetedMailingCandidatesRubric'] = ResolversParentTypes['TargetedMailingCandidatesRubric']
> = {
  rubric?: Resolver<ResolversTypes['Rubric'], ParentType, ContextType>
  subrubrics?: Resolver<Maybe<ReadonlyArray<ResolversTypes['Subrubric']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedMailingCandidatesSamplingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TargetedMailingCandidatesSampling'] = ResolversParentTypes['TargetedMailingCandidatesSampling']
> = {
  candidatesLimitPerService?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  details?: Resolver<Maybe<ReadonlyArray<ResolversTypes['TargetedMailingCandidatesSamplingDetails']>>, ParentType, ContextType>
  input?: Resolver<ResolversTypes['TargetedMailingCandidatesSamplingInputSnapshot'], ParentType, ContextType>
  requiredServices?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedMailingCandidatesSamplingByCodeInputSnapshotResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TargetedMailingCandidatesSamplingByCodeInputSnapshot'] = ResolversParentTypes['TargetedMailingCandidatesSamplingByCodeInputSnapshot']
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedMailingCandidatesSamplingByFilterInputSnapshotResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TargetedMailingCandidatesSamplingByFilterInputSnapshot'] = ResolversParentTypes['TargetedMailingCandidatesSamplingByFilterInputSnapshot']
> = {
  age?: Resolver<Maybe<ResolversTypes['IntRange']>, ParentType, ContextType>
  experiences?: Resolver<Maybe<ReadonlyArray<ResolversTypes['Experience']>>, ParentType, ContextType>
  genders?: Resolver<Maybe<ReadonlyArray<ResolversTypes['Gender']>>, ParentType, ContextType>
  languages?: Resolver<Maybe<ReadonlyArray<ResolversTypes['TargetedMailingLanguageRequirement']>>, ParentType, ContextType>
  locations?: Resolver<Maybe<ReadonlyArray<ResolversTypes['TargetedMailingCandidatesLocation']>>, ParentType, ContextType>
  rubrics?: Resolver<Maybe<ReadonlyArray<ResolversTypes['TargetedMailingCandidatesRubric']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedMailingCandidatesSamplingDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TargetedMailingCandidatesSamplingDetails'] = ResolversParentTypes['TargetedMailingCandidatesSamplingDetails']
> = {
  byChannels?: Resolver<ReadonlyArray<ResolversTypes['TargetedMailingCandidatesSamplingDetailsByChannel']>, ParentType, ContextType>
  byVacancies?: Resolver<Maybe<ReadonlyArray<ResolversTypes['TargetedMailingCandidatesSamplingDetailsByVacancy']>>, ParentType, ContextType>
  kind?: Resolver<ResolversTypes['TargetedMailingCandidatesSamplingDetailsKindEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedMailingCandidatesSamplingDetailsByChannelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TargetedMailingCandidatesSamplingDetailsByChannel'] = ResolversParentTypes['TargetedMailingCandidatesSamplingDetailsByChannel']
> = {
  channels?: Resolver<ReadonlyArray<ResolversTypes['TargetedMailingChannelEnum']>, ParentType, ContextType>
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedMailingCandidatesSamplingDetailsByVacancyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TargetedMailingCandidatesSamplingDetailsByVacancy'] = ResolversParentTypes['TargetedMailingCandidatesSamplingDetailsByVacancy']
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  vacancySnapshot?: Resolver<ResolversTypes['TargetedMailingSamplingRelatedVacancy'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedMailingCandidatesSamplingErrorUnionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TargetedMailingCandidatesSamplingErrorUnion'] = ResolversParentTypes['TargetedMailingCandidatesSamplingErrorUnion']
> = {
  __resolveType: TypeResolveFn<'TargetedMailingInvalidVacanciesError', ParentType, ContextType>
}

export type TargetedMailingCandidatesSamplingInputSnapshotResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TargetedMailingCandidatesSamplingInputSnapshot'] = ResolversParentTypes['TargetedMailingCandidatesSamplingInputSnapshot']
> = {
  channels?: Resolver<ReadonlyArray<ResolversTypes['TargetedMailingChannelEnum']>, ParentType, ContextType>
  code?: Resolver<Maybe<ResolversTypes['TargetedMailingCandidatesSamplingByCodeInputSnapshot']>, ParentType, ContextType>
  filter?: Resolver<Maybe<ResolversTypes['TargetedMailingCandidatesSamplingByFilterInputSnapshot']>, ParentType, ContextType>
  vacancies?: Resolver<Maybe<ReadonlyArray<ResolversTypes['TargetedMailingSamplingRelatedVacancy']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedMailingCandidatesSamplingOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TargetedMailingCandidatesSamplingOutput'] = ResolversParentTypes['TargetedMailingCandidatesSamplingOutput']
> = {
  candidatesSampling?: Resolver<Maybe<ResolversTypes['TargetedMailingCandidatesSampling']>, ParentType, ContextType>
  error?: Resolver<Maybe<ResolversTypes['TargetedMailingCandidatesSamplingErrorUnion']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedMailingCompletedHistoryFactResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TargetedMailingCompletedHistoryFact'] = ResolversParentTypes['TargetedMailingCompletedHistoryFact']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedMailingConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TargetedMailingConnection'] = ResolversParentTypes['TargetedMailingConnection']> = {
  edges?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['TargetedMailingEdge']>>>, ParentType, ContextType>
  items?: Resolver<Maybe<ReadonlyArray<ResolversTypes['TargetedMailing']>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedMailingContentUnionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TargetedMailingContentUnion'] = ResolversParentTypes['TargetedMailingContentUnion']> = {
  __resolveType: TypeResolveFn<'TargetedMailingMailContent' | 'TargetedMailingMobilePushContent', ParentType, ContextType>
}

export type TargetedMailingCreatedHistoryFactResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TargetedMailingCreatedHistoryFact'] = ResolversParentTypes['TargetedMailingCreatedHistoryFact']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  responsible?: Resolver<ResolversTypes['TargetedMailingResponsible'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedMailingEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TargetedMailingEdge'] = ResolversParentTypes['TargetedMailingEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['TargetedMailing'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedMailingEditedHistoryFactResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TargetedMailingEditedHistoryFact'] = ResolversParentTypes['TargetedMailingEditedHistoryFact']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  responsible?: Resolver<ResolversTypes['TargetedMailingResponsible'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedMailingFacetsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['TargetedMailingFacetsOutput'] = ResolversParentTypes['TargetedMailingFacetsOutput']> = {
  statuses?: Resolver<ReadonlyArray<ResolversTypes['TargetedMailingStatusFacet']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedMailingFailedHistoryFactResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TargetedMailingFailedHistoryFact'] = ResolversParentTypes['TargetedMailingFailedHistoryFact']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedMailingHistoryFactUnionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TargetedMailingHistoryFactUnion'] = ResolversParentTypes['TargetedMailingHistoryFactUnion']
> = {
  __resolveType: TypeResolveFn<
    'TargetedMailingCompletedHistoryFact' | 'TargetedMailingCreatedHistoryFact' | 'TargetedMailingEditedHistoryFact' | 'TargetedMailingFailedHistoryFact' | 'TargetedMailingSendingHistoryFact',
    ParentType,
    ContextType
  >
}

export type TargetedMailingInvalidVacanciesErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TargetedMailingInvalidVacanciesError'] = ResolversParentTypes['TargetedMailingInvalidVacanciesError']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedMailingLanguageRequirementResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TargetedMailingLanguageRequirement'] = ResolversParentTypes['TargetedMailingLanguageRequirement']
> = {
  language?: Resolver<ResolversTypes['Lang'], ParentType, ContextType>
  languageSkill?: Resolver<ResolversTypes['LanguageSkillLevel'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedMailingLinkUnionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TargetedMailingLinkUnion'] = ResolversParentTypes['TargetedMailingLinkUnion']> = {
  __resolveType: TypeResolveFn<'TargetedExternalMailingLink' | 'TargetedVacancyMailingLink', ParentType, ContextType>
}

export type TargetedMailingMailContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['TargetedMailingMailContent'] = ResolversParentTypes['TargetedMailingMailContent']> = {
  htmlTemplateUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedMailingMobilePushContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TargetedMailingMobilePushContent'] = ResolversParentTypes['TargetedMailingMobilePushContent']
> = {
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  vacancyId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedMailingMutationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TargetedMailingMutations'] = ResolversParentTypes['TargetedMailingMutations']> = {
  create?: Resolver<ResolversTypes['CreateTargetedMailingOutput'], ParentType, ContextType, RequireFields<TargetedMailingMutationsCreateArgs, 'input'>>
  delete?: Resolver<ResolversTypes['DeleteTargetedMailingOutput'], ParentType, ContextType, RequireFields<TargetedMailingMutationsDeleteArgs, 'id'>>
  testEmailTemplate?: Resolver<ResolversTypes['TestTargetedMailingOutput'], ParentType, ContextType, RequireFields<TargetedMailingMutationsTestEmailTemplateArgs, 'input'>>
  update?: Resolver<ResolversTypes['UpdateTargetedMailingOutput'], ParentType, ContextType, RequireFields<TargetedMailingMutationsUpdateArgs, 'input'>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedMailingQueriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['TargetedMailingQueries'] = ResolversParentTypes['TargetedMailingQueries']> = {
  candidatesSuggest?: Resolver<ResolversTypes['TargetedMailingCandidatesSamplingOutput'], ParentType, ContextType, RequireFields<TargetedMailingQueriesCandidatesSuggestArgs, 'input' | 'type'>>
  facets?: Resolver<ResolversTypes['TargetedMailingFacetsOutput'], ParentType, ContextType, Partial<TargetedMailingQueriesFacetsArgs>>
  list?: Resolver<ResolversTypes['TargetedMailingConnection'], ParentType, ContextType, Partial<TargetedMailingQueriesListArgs>>
  single?: Resolver<Maybe<ResolversTypes['TargetedMailing']>, ParentType, ContextType, RequireFields<TargetedMailingQueriesSingleArgs, 'id'>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedMailingRequiredFieldsMissedErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TargetedMailingRequiredFieldsMissedError'] = ResolversParentTypes['TargetedMailingRequiredFieldsMissedError']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedMailingResponsibleResolvers<ContextType = any, ParentType extends ResolversParentTypes['TargetedMailingResponsible'] = ResolversParentTypes['TargetedMailingResponsible']> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedMailingSamplingRelatedVacancyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TargetedMailingSamplingRelatedVacancy'] = ResolversParentTypes['TargetedMailingSamplingRelatedVacancy']
> = {
  city?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  hasSelectedForMobileApplication?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedMailingSaveErrorUnionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TargetedMailingSaveErrorUnion'] = ResolversParentTypes['TargetedMailingSaveErrorUnion']
> = {
  __resolveType: TypeResolveFn<'TargetedMailingInvalidVacanciesError' | 'TargetedMailingRequiredFieldsMissedError', ParentType, ContextType>
}

export type TargetedMailingSendingHistoryFactResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TargetedMailingSendingHistoryFact'] = ResolversParentTypes['TargetedMailingSendingHistoryFact']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  candidatesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedMailingStatusFacetResolvers<ContextType = any, ParentType extends ResolversParentTypes['TargetedMailingStatusFacet'] = ResolversParentTypes['TargetedMailingStatusFacet']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['TargetedMailingStatusEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedVacancyMailingLinkResolvers<ContextType = any, ParentType extends ResolversParentTypes['TargetedVacancyMailingLink'] = ResolversParentTypes['TargetedVacancyMailingLink']> = {
  appliesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  clicks?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  vacancy?: Resolver<ResolversTypes['TargetedVacancyMailingLinkVacancySnapshot'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TargetedVacancyMailingLinkVacancySnapshotResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TargetedVacancyMailingLinkVacancySnapshot'] = ResolversParentTypes['TargetedVacancyMailingLinkVacancySnapshot']
> = {
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TeamActionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TeamActions'] = ResolversParentTypes['TeamActions']> = {
  counters?: Resolver<ReadonlyArray<ResolversTypes['ActionStateCounter']>, ParentType, ContextType>
  managers?: Resolver<ReadonlyArray<ResolversTypes['ActionTeamCounter']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TestTargetedMailingOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['TestTargetedMailingOutput'] = ResolversParentTypes['TestTargetedMailingOutput']> = {
  isSuccessful?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TokenAlreadyUsedErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['TokenAlreadyUsedError'] = ResolversParentTypes['TokenAlreadyUsedError']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TopicResolvers<ContextType = any, ParentType extends ResolversParentTypes['Topic'] = ResolversParentTypes['Topic']> = {
  isDone?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  subTopics?: Resolver<ReadonlyArray<ResolversTypes['Topic']>, ParentType, ContextType>
  template?: Resolver<Maybe<ResolversTypes['TopicTemplate']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TopicTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TopicTemplate'] = ResolversParentTypes['TopicTemplate']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  objections?: Resolver<ReadonlyArray<ResolversTypes['Objection']>, ParentType, ContextType>
  subTopicTemplates?: Resolver<ReadonlyArray<ResolversTypes['TopicTemplate']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TrackCompanyViewOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrackCompanyViewOutput'] = ResolversParentTypes['TrackCompanyViewOutput']> = {
  company?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TransactionExistsErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransactionExistsError'] = ResolversParentTypes['TransactionExistsError']> = {
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TransactionsConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransactionsConnection'] = ResolversParentTypes['TransactionsConnection']> = {
  edges?: Resolver<Maybe<ReadonlyArray<ResolversTypes['TransactionsEdge']>>, ParentType, ContextType>
  nodes?: Resolver<Maybe<ReadonlyArray<ResolversTypes['WalletTransaction']>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TransactionsEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransactionsEdge'] = ResolversParentTypes['TransactionsEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['WalletTransaction'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TurbotaEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['TurbotaEvent'] = ResolversParentTypes['TurbotaEvent']> = {
  addDate?: Resolver<ResolversTypes['DateTimeOffset'], ParentType, ContextType>
  assignment?: Resolver<Maybe<ResolversTypes['Assignment']>, ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['CrmCompany']>, ParentType, ContextType>
  daysLeft?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  employee?: Resolver<Maybe<ResolversTypes['CrmEmployee']>, ParentType, ContextType>
  eventType?: Resolver<ResolversTypes['EventTypeEnum'], ParentType, ContextType>
  expirationDate?: Resolver<Maybe<ResolversTypes['DateTimeOffset']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isArchieved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isRead?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  lifetimeDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  state?: Resolver<ResolversTypes['EventStateEnum'], ParentType, ContextType>
  topics?: Resolver<ReadonlyArray<ResolversTypes['Topic']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TurbotaWasExpiredErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['TurbotaWasExpiredError'] = ResolversParentTypes['TurbotaWasExpiredError']> = {
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface UIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['UInt'], any> {
  name: 'UInt'
}

export type UncompletedPaymentEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['UncompletedPaymentEvent'] = ResolversParentTypes['UncompletedPaymentEvent']> = {
  accountNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  accountYear?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  addDate?: Resolver<ResolversTypes['DateTimeOffset'], ParentType, ContextType>
  assignment?: Resolver<Maybe<ResolversTypes['Assignment']>, ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['CrmCompany']>, ParentType, ContextType>
  daysLeft?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  dealNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  employee?: Resolver<Maybe<ResolversTypes['CrmEmployee']>, ParentType, ContextType>
  eventType?: Resolver<ResolversTypes['EventTypeEnum'], ParentType, ContextType>
  expirationDate?: Resolver<Maybe<ResolversTypes['DateTimeOffset']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isArchieved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isRead?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  lifetimeDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  orderNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  state?: Resolver<ResolversTypes['EventStateEnum'], ParentType, ContextType>
  topics?: Resolver<ReadonlyArray<ResolversTypes['Topic']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UndislikeSeekerVacanciesOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UndislikeSeekerVacanciesOutput'] = ResolversParentTypes['UndislikeSeekerVacanciesOutput']
> = {
  query?: Resolver<ResolversTypes['Query'], ParentType, ContextType>
  vacancies?: Resolver<ReadonlyArray<ResolversTypes['Vacancy']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UnionBannerDynamicElementResolvers<ContextType = any, ParentType extends ResolversParentTypes['UnionBannerDynamicElement'] = ResolversParentTypes['UnionBannerDynamicElement']> = {
  __resolveType: TypeResolveFn<'BannerClock' | 'BannerProgressBar', ParentType, ContextType>
}

export type UniversityResolvers<ContextType = any, ParentType extends ResolversParentTypes['University'] = ResolversParentTypes['University']> = {
  EDRPOU?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  shortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UnknownServerErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UnknownServerError'] = ResolversParentTypes['UnknownServerError']> = {
  exeptionTypeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UnlinkPayerOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UnlinkPayerOutput'] = ResolversParentTypes['UnlinkPayerOutput']> = {
  errorMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UnpaidOrderDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UnpaidOrderDetails'] = ResolversParentTypes['UnpaidOrderDetails']> = {
  detailType?: Resolver<ResolversTypes['SeekerNotificationDetailTypeEnum'], ParentType, ContextType>
  orderSnapshot?: Resolver<ResolversTypes['NotificationOrderSnapshot'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UnpublishSeekerProfResumeErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnpublishSeekerProfResumeError'] = ResolversParentTypes['UnpublishSeekerProfResumeError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type UnpublishedProfResumeOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UnpublishedProfResumeOutput'] = ResolversParentTypes['UnpublishedProfResumeOutput']> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UnpublishSeekerProfResumeError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateGiftRequestOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateGiftRequestOutput'] = ResolversParentTypes['UpdateGiftRequestOutput']> = {
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateProfResumeAdditionalPhonesOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateProfResumeAdditionalPhonesOutput'] = ResolversParentTypes['UpdateProfResumeAdditionalPhonesOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeAdditionalPhonesError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateProfResumeEmailOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateProfResumeEmailOutput'] = ResolversParentTypes['UpdateProfResumeEmailOutput']> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeEmailError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateProfResumePhoneOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateProfResumePhoneOutput'] = ResolversParentTypes['UpdateProfResumePhoneOutput']> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeMainPhoneError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateProfResumePortfoliosOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateProfResumePortfoliosOutput'] = ResolversParentTypes['UpdateProfResumePortfoliosOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumePortfoliosError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateProfResumeSocialNetworksOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateProfResumeSocialNetworksOutput'] = ResolversParentTypes['UpdateProfResumeSocialNetworksOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeSocialNetworksError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateSeekerProfResumeAdditionalEducationErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSeekerProfResumeAdditionalEducationError'] = ResolversParentTypes['UpdateSeekerProfResumeAdditionalEducationError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type UpdateSeekerProfResumeAdditionalInfoErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSeekerProfResumeAdditionalInfoError'] = ResolversParentTypes['UpdateSeekerProfResumeAdditionalInfoError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type UpdateSeekerProfResumeAdditionalInfosErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSeekerProfResumeAdditionalInfosError'] = ResolversParentTypes['UpdateSeekerProfResumeAdditionalInfosError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type UpdateSeekerProfResumeAdditionalPhonesErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSeekerProfResumeAdditionalPhonesError'] = ResolversParentTypes['UpdateSeekerProfResumeAdditionalPhonesError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type UpdateSeekerProfResumeAnonymityErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSeekerProfResumeAnonymityError'] = ResolversParentTypes['UpdateSeekerProfResumeAnonymityError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type UpdateSeekerProfResumeAvailabilityStateErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSeekerProfResumeAvailabilityStateError'] = ResolversParentTypes['UpdateSeekerProfResumeAvailabilityStateError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type UpdateSeekerProfResumeBirthDateErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSeekerProfResumeBirthDateError'] = ResolversParentTypes['UpdateSeekerProfResumeBirthDateError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type UpdateSeekerProfResumeCityErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSeekerProfResumeCityError'] = ResolversParentTypes['UpdateSeekerProfResumeCityError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type UpdateSeekerProfResumeDiiaCertificateErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSeekerProfResumeDiiaCertificateError'] = ResolversParentTypes['UpdateSeekerProfResumeDiiaCertificateError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type UpdateSeekerProfResumeDisabilityErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSeekerProfResumeDisabilityError'] = ResolversParentTypes['UpdateSeekerProfResumeDisabilityError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type UpdateSeekerProfResumeEducationErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSeekerProfResumeEducationError'] = ResolversParentTypes['UpdateSeekerProfResumeEducationError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type UpdateSeekerProfResumeEmailErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSeekerProfResumeEmailError'] = ResolversParentTypes['UpdateSeekerProfResumeEmailError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type UpdateSeekerProfResumeExperienceErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSeekerProfResumeExperienceError'] = ResolversParentTypes['UpdateSeekerProfResumeExperienceError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type UpdateSeekerProfResumeFullNameErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSeekerProfResumeFullNameError'] = ResolversParentTypes['UpdateSeekerProfResumeFullNameError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type UpdateSeekerProfResumeGenderErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSeekerProfResumeGenderError'] = ResolversParentTypes['UpdateSeekerProfResumeGenderError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type UpdateSeekerProfResumeHiddenCompaniesErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSeekerProfResumeHiddenCompaniesError'] = ResolversParentTypes['UpdateSeekerProfResumeHiddenCompaniesError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type UpdateSeekerProfResumeLanguageSkillsErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSeekerProfResumeLanguageSkillsError'] = ResolversParentTypes['UpdateSeekerProfResumeLanguageSkillsError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type UpdateSeekerProfResumeMainPhoneErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSeekerProfResumeMainPhoneError'] = ResolversParentTypes['UpdateSeekerProfResumeMainPhoneError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist' | 'ProfResumePhoneConfirmationFailed', ParentType, ContextType>
}

export type UpdateSeekerProfResumeMilitaryStatusErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSeekerProfResumeMilitaryStatusError'] = ResolversParentTypes['UpdateSeekerProfResumeMilitaryStatusError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type UpdateSeekerProfResumePhotoErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSeekerProfResumePhotoError'] = ResolversParentTypes['UpdateSeekerProfResumePhotoError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type UpdateSeekerProfResumePortfoliosErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSeekerProfResumePortfoliosError'] = ResolversParentTypes['UpdateSeekerProfResumePortfoliosError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type UpdateSeekerProfResumePositionErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSeekerProfResumePositionError'] = ResolversParentTypes['UpdateSeekerProfResumePositionError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist' | 'ProfResumeDuplicated', ParentType, ContextType>
}

export type UpdateSeekerProfResumeSalaryErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSeekerProfResumeSalaryError'] = ResolversParentTypes['UpdateSeekerProfResumeSalaryError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type UpdateSeekerProfResumeScheduleErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSeekerProfResumeScheduleError'] = ResolversParentTypes['UpdateSeekerProfResumeScheduleError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type UpdateSeekerProfResumeSearchStateErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSeekerProfResumeSearchStateError'] = ResolversParentTypes['UpdateSeekerProfResumeSearchStateError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type UpdateSeekerProfResumeSkillsErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSeekerProfResumeSkillsError'] = ResolversParentTypes['UpdateSeekerProfResumeSkillsError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type UpdateSeekerProfResumeSocialNetworksErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSeekerProfResumeSocialNetworksError'] = ResolversParentTypes['UpdateSeekerProfResumeSocialNetworksError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type UpdateSeekerProfResumeSortDateErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSeekerProfResumeSortDateError'] = ResolversParentTypes['UpdateSeekerProfResumeSortDateError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type UpdateSeekerProfResumeSortDateOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSeekerProfResumeSortDateOutput'] = ResolversParentTypes['UpdateSeekerProfResumeSortDateOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeSortDateError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateSeekerProfResumeStateErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateSeekerProfResumeStateError'] = ResolversParentTypes['UpdateSeekerProfResumeStateError']
> = {
  __resolveType: TypeResolveFn<'ProfResumeDoesNotBelongToSeeker' | 'ProfResumeDoesNotExist', ParentType, ContextType>
}

export type UpdateTargetedMailingOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateTargetedMailingOutput'] = ResolversParentTypes['UpdateTargetedMailingOutput']> = {
  error?: Resolver<Maybe<ResolversTypes['TargetedMailingSaveErrorUnion']>, ParentType, ContextType>
  mailing?: Resolver<Maybe<ResolversTypes['TargetedMailing']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdatedProfResumeAdditionalEducationsOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdatedProfResumeAdditionalEducationsOutput'] = ResolversParentTypes['UpdatedProfResumeAdditionalEducationsOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeAdditionalEducationError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdatedProfResumeAdditionalInfoOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdatedProfResumeAdditionalInfoOutput'] = ResolversParentTypes['UpdatedProfResumeAdditionalInfoOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeAdditionalInfoError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdatedProfResumeAdditionalInfosOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdatedProfResumeAdditionalInfosOutput'] = ResolversParentTypes['UpdatedProfResumeAdditionalInfosOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeAdditionalInfosError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdatedProfResumeAnonymityOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdatedProfResumeAnonymityOutput'] = ResolversParentTypes['UpdatedProfResumeAnonymityOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeAnonymityError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdatedProfResumeAvailabilityStateOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdatedProfResumeAvailabilityStateOutput'] = ResolversParentTypes['UpdatedProfResumeAvailabilityStateOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeAvailabilityStateError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdatedProfResumeBirthDateOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdatedProfResumeBirthDateOutput'] = ResolversParentTypes['UpdatedProfResumeBirthDateOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeBirthDateError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdatedProfResumeCityOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdatedProfResumeCityOutput'] = ResolversParentTypes['UpdatedProfResumeCityOutput']> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeCityError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdatedProfResumeDiiaCertificateOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdatedProfResumeDiiaCertificateOutput'] = ResolversParentTypes['UpdatedProfResumeDiiaCertificateOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeDiiaCertificateError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdatedProfResumeDisabilityOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdatedProfResumeDisabilityOutput'] = ResolversParentTypes['UpdatedProfResumeDisabilityOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeDisabilityError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdatedProfResumeEducationsOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdatedProfResumeEducationsOutput'] = ResolversParentTypes['UpdatedProfResumeEducationsOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeEducationError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdatedProfResumeExperiencesOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdatedProfResumeExperiencesOutput'] = ResolversParentTypes['UpdatedProfResumeExperiencesOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeExperienceError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdatedProfResumeFullNameOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdatedProfResumeFullNameOutput'] = ResolversParentTypes['UpdatedProfResumeFullNameOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeFullNameError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdatedProfResumeGenderOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdatedProfResumeGenderOutput'] = ResolversParentTypes['UpdatedProfResumeGenderOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeGenderError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdatedProfResumeHiddenCompaniesOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdatedProfResumeHiddenCompaniesOutput'] = ResolversParentTypes['UpdatedProfResumeHiddenCompaniesOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeHiddenCompaniesError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdatedProfResumeLanguageSkillsOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdatedProfResumeLanguageSkillsOutput'] = ResolversParentTypes['UpdatedProfResumeLanguageSkillsOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeLanguageSkillsError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdatedProfResumeMilitaryStatusOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdatedProfResumeMilitaryStatusOutput'] = ResolversParentTypes['UpdatedProfResumeMilitaryStatusOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeMilitaryStatusError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdatedProfResumePhotoOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdatedProfResumePhotoOutput'] = ResolversParentTypes['UpdatedProfResumePhotoOutput']> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumePhotoError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdatedProfResumePositionOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdatedProfResumePositionOutput'] = ResolversParentTypes['UpdatedProfResumePositionOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumePositionError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdatedProfResumeSalaryOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdatedProfResumeSalaryOutput'] = ResolversParentTypes['UpdatedProfResumeSalaryOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeSalaryError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdatedProfResumeScheduleOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdatedProfResumeScheduleOutput'] = ResolversParentTypes['UpdatedProfResumeScheduleOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeScheduleError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdatedProfResumeSearchStateOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdatedProfResumeSearchStateOutput'] = ResolversParentTypes['UpdatedProfResumeSearchStateOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeSearchStateError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdatedProfResumeSkillsOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdatedProfResumeSkillsOutput'] = ResolversParentTypes['UpdatedProfResumeSkillsOutput']
> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UpdateSeekerProfResumeSkillsError']>>, ParentType, ContextType>
  profResume?: Resolver<Maybe<ResolversTypes['ProfResume']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface UploadScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Upload'], any> {
  name: 'Upload'
}

export interface UriScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Uri'], any> {
  name: 'Uri'
}

export type UsagePeriodTypeWrapperResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsagePeriodTypeWrapper'] = ResolversParentTypes['UsagePeriodTypeWrapper']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['ServiceUsagePeriod'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UsePromoCodeResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsePromoCodeResponse'] = ResolversParentTypes['UsePromoCodeResponse']> = {
  error?: Resolver<Maybe<ResolversTypes['UsePromoCodeErrorEnum']>, ParentType, ContextType>
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  promoCodes?: Resolver<ReadonlyArray<ResolversTypes['PromoCode']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UsedHotServiceEmployerEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsedHotServiceEmployerEvent'] = ResolversParentTypes['UsedHotServiceEmployerEvent']> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  restSameServices?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  usedService?: Resolver<ResolversTypes['ServiceEmployerEventSnapshotUnion'], ParentType, ContextType>
  vacancyCity?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  vacancyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  vacancyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UsedVacancyServiceEmployerEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsedVacancyServiceEmployerEvent'] = ResolversParentTypes['UsedVacancyServiceEmployerEvent']
> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  restSameServices?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  usedPublicationType?: Resolver<ResolversTypes['VacancyPublicationTypeWrapper'], ParentType, ContextType>
  usedService?: Resolver<ResolversTypes['ServiceEmployerEventSnapshotUnion'], ParentType, ContextType>
  vacancyCity?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  vacancyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  vacancyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  employerDetails?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType>
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isOnline?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  lastActivityTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  notebookId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  phone?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  role?: Resolver<ResolversTypes['UserRoleWrapper'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserAccountLoginResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserAccountLogin'] = ResolversParentTypes['UserAccountLogin']> = {
  oneTimeLoginToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  userInfo?: Resolver<ResolversTypes['User'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserCardDeleteOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserCardDeleteOutput'] = ResolversParentTypes['UserCardDeleteOutput']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  messageText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserEmailHasBeenSetNotificationDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserEmailHasBeenSetNotificationDetails'] = ResolversParentTypes['UserEmailHasBeenSetNotificationDetails']
> = {
  detailType?: Resolver<ResolversTypes['SeekerNotificationDetailTypeEnum'], ParentType, ContextType>
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserIsNotAuthenticatedErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserIsNotAuthenticatedError'] = ResolversParentTypes['UserIsNotAuthenticatedError']> = {
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserIsNotEmployerErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserIsNotEmployerError'] = ResolversParentTypes['UserIsNotEmployerError']> = {
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserLeftBasketEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserLeftBasketEvent'] = ResolversParentTypes['UserLeftBasketEvent']> = {
  addDate?: Resolver<ResolversTypes['DateTimeOffset'], ParentType, ContextType>
  assignment?: Resolver<Maybe<ResolversTypes['Assignment']>, ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['CrmCompany']>, ParentType, ContextType>
  daysLeft?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  employee?: Resolver<Maybe<ResolversTypes['CrmEmployee']>, ParentType, ContextType>
  eventType?: Resolver<ResolversTypes['EventTypeEnum'], ParentType, ContextType>
  expirationDate?: Resolver<Maybe<ResolversTypes['DateTimeOffset']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isArchieved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isRead?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  lifetimeDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  state?: Resolver<ResolversTypes['EventStateEnum'], ParentType, ContextType>
  topics?: Resolver<ReadonlyArray<ResolversTypes['Topic']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserRegistrationErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRegistrationError'] = ResolversParentTypes['UserRegistrationError']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['UserRegistrationErrorEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserRegistrationMutationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRegistrationMutations'] = ResolversParentTypes['UserRegistrationMutations']> = {
  employer?: Resolver<ResolversTypes['EmployerRegistration'], ParentType, ContextType>
  seeker?: Resolver<ResolversTypes['SeekerRegistration'], ParentType, ContextType>
  sendRegistrationConfirmationEmail?: Resolver<ResolversTypes['SendEmailOutput'], ParentType, ContextType, RequireFields<UserRegistrationMutationsSendRegistrationConfirmationEmailArgs, 'input'>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserRegistrationValidationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserRegistrationValidationResponse'] = ResolversParentTypes['UserRegistrationValidationResponse']
> = {
  errors?: Resolver<ReadonlyArray<ResolversTypes['UserRegistrationError']>, ParentType, ContextType>
  isSuccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  login?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  similarCompanies?: Resolver<ReadonlyArray<ResolversTypes['Company']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserRoleWrapperResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRoleWrapper'] = ResolversParentTypes['UserRoleWrapper']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['UserRolesEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserStateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserState'] = ResolversParentTypes['UserState']> = {
  department?: Resolver<Maybe<ResolversTypes['DepartmentEnum']>, ParentType, ContextType>
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  isSM?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isSTM?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  roles?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UsersResolvers<ContextType = any, ParentType extends ResolversParentTypes['Users'] = ResolversParentTypes['Users']> = {
  confirmEmailByCode?: Resolver<ResolversTypes['ConfirmEmailCodeOutput'], ParentType, ContextType, RequireFields<UsersConfirmEmailByCodeArgs, 'input'>>
  confirmProfileDeletion?: Resolver<ResolversTypes['ConfirmProfileDeletionOutput'], ParentType, ContextType, Partial<UsersConfirmProfileDeletionArgs>>
  deleteProfile?: Resolver<ResolversTypes['DeleteProfileOutput'], ParentType, ContextType>
  login?: Resolver<ResolversTypes['LoginMutations'], ParentType, ContextType>
  registration?: Resolver<ResolversTypes['UserRegistrationMutations'], ParentType, ContextType>
  seeker?: Resolver<ResolversTypes['SeekerMutations'], ParentType, ContextType>
  sendConfirmCodeEmail?: Resolver<ResolversTypes['SendEmailOutput'], ParentType, ContextType, RequireFields<UsersSendConfirmCodeEmailArgs, 'input'>>
  sendLoginMagicLinkEmail?: Resolver<ResolversTypes['SendEmailOutput'], ParentType, ContextType, RequireFields<UsersSendLoginMagicLinkEmailArgs, 'input'>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UsersManagementFiltersResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersManagementFilters'] = ResolversParentTypes['UsersManagementFilters']> = {
  activeStatus?: Resolver<ResolversTypes['EmployeeActiveStatus'], ParentType, ContextType>
  orderBy?: Resolver<ResolversTypes['EmployeesOrderBy'], ParentType, ContextType>
  term?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UsersManagementPageInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersManagementPageInfo'] = ResolversParentTypes['UsersManagementPageInfo']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UsersManagementSubFilterResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersManagementSubFilter'] = ResolversParentTypes['UsersManagementSubFilter']> = {
  activeStatus?: Resolver<ResolversTypes['EmployeeActiveStatus'], ParentType, ContextType>
  term?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacanciesPageClientFiltersResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacanciesPageClientFilters'] = ResolversParentTypes['VacanciesPageClientFilters']> = {
  cityIds?: Resolver<ReadonlyArray<ResolversTypes['ID']>, ParentType, ContextType>
  closingBehaviors?: Resolver<ReadonlyArray<ResolversTypes['VacancyClosingBehavior']>, ParentType, ContextType>
  employerIds?: Resolver<ReadonlyArray<ResolversTypes['ID']>, ParentType, ContextType>
  keyword?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sortType?: Resolver<ResolversTypes['MyVacanciesSortType'], ParentType, ContextType>
  statuses?: Resolver<ReadonlyArray<ResolversTypes['VacancyStatus']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Vacancy'] = ResolversParentTypes['Vacancy']> = {
  activateCvMailing?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  additionalCandidatePermissions?: Resolver<ResolversTypes['VacancyAdditionalCandidatePermissions'], ParentType, ContextType>
  address?: Resolver<Maybe<ResolversTypes['VacancyAddress']>, ParentType, ContextType>
  adminRejectDetails?: Resolver<Maybe<ResolversTypes['VacancyAdminRejectDetails']>, ParentType, ContextType>
  allowedStatuses?: Resolver<ReadonlyArray<Maybe<ResolversTypes['VacancyStatus']>>, ParentType, ContextType>
  allowedVacancyActions?: Resolver<ReadonlyArray<Maybe<ResolversTypes['AllowedVacancyActions']>>, ParentType, ContextType>
  anonymous?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  applicationsDestination?: Resolver<ResolversTypes['VacancyApplicationsDestination'], ParentType, ContextType>
  appliesByLink?: Resolver<Maybe<ResolversTypes['appliesByUrl']>, ParentType, ContextType, RequireFields<VacancyAppliesByLinkArgs, 'range'>>
  appliesByLinkCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  badges?: Resolver<ReadonlyArray<Maybe<ResolversTypes['PublishedVacancyBadge']>>, ParentType, ContextType>
  benefits?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['VacancyBenefit']>>>, ParentType, ContextType>
  branch?: Resolver<Maybe<ResolversTypes['Branch']>, ParentType, ContextType>
  candidatesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType, Partial<VacancyCandidatesCountArgs>>
  candidatesCounters?: Resolver<ReadonlyArray<ResolversTypes['CandidatesVacancyCounters']>, ParentType, ContextType>
  candidatesScreening?: Resolver<ResolversTypes['VacancyCandidatesScreeningOptions'], ParentType, ContextType>
  chatStatus?: Resolver<ResolversTypes['VacancyChatStatusEnum'], ParentType, ContextType>
  city?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  closingBehavior?: Resolver<ResolversTypes['VacancyClosingBehavior'], ParentType, ContextType>
  closingType?: Resolver<ResolversTypes['VacancyClosingType'], ParentType, ContextType>
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>
  contacts?: Resolver<ResolversTypes['VacancyContacts'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  currentPublicationService?: Resolver<Maybe<ResolversTypes['CatalogService']>, ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  descriptionUpper?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  design?: Resolver<Maybe<ResolversTypes['VacancyDesign']>, ParentType, ContextType>
  designBannerUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  designId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  designType?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  distanceText?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  employmentTypes?: Resolver<ReadonlyArray<Maybe<ResolversTypes['VacancyEmploymentType']>>, ParentType, ContextType>
  experienceQuestions?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['VacancyExperienceQuestion']>>>, ParentType, ContextType>
  firstPublishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  formApplyCustomUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  fullDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  groupKeyTags?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['GroupTag']>>>, ParentType, ContextType>
  hasDesign?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasMyUnreviewedProlongationRequest?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hideFromCompanyProfile?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hot?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hotPeriod?: Resolver<Maybe<ResolversTypes['VacancyHotPeriod']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  interviewQuestions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isAgency?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isPublicationInAllCities?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  keyTagGroups?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['TagGroup']>>>, ParentType, ContextType>
  languageQuestions?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['VacancyLanguageQuestion']>>>, ParentType, ContextType>
  languageSkillsRequired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  linkClicks?: Resolver<Maybe<ResolversTypes['clickOnLinks']>, ParentType, ContextType, RequireFields<VacancyLinkClicksArgs, 'range'>>
  location?: Resolver<Maybe<ResolversTypes['PublishedVacanciesLocation']>, ParentType, ContextType>
  madeAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  media?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['VacancyMedia']>>>, ParentType, ContextType>
  moderatorRejectDetails?: Resolver<Maybe<ResolversTypes['VacancyModeratorRejectDetails']>, ParentType, ContextType>
  modifyDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  offerMessageFromEmployerToSeeker?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  owner?: Resolver<ResolversTypes['VacancyEmployer'], ParentType, ContextType>
  positionRising?: Resolver<Maybe<ResolversTypes['VacancyPositionRisingInSearchList']>, ParentType, ContextType>
  profLevelId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  publicationType?: Resolver<ResolversTypes['VacancyPublicationType'], ParentType, ContextType>
  publicationTypeEnumWrapper?: Resolver<ResolversTypes['VacancyPublicationTypeWrapper'], ParentType, ContextType>
  publishPeriod?: Resolver<Maybe<ResolversTypes['VacancyPublishPeriod']>, ParentType, ContextType>
  recommendedCandidates?: Resolver<Maybe<ResolversTypes['RecommendedCandidatesConnection']>, ParentType, ContextType, Partial<VacancyRecommendedCandidatesArgs>>
  recommendedProfResumes?: Resolver<Maybe<ResolversTypes['RecommendedProfResumes']>, ParentType, ContextType, RequireFields<VacancyRecommendedProfResumesArgs, 'after' | 'first' | 'resumeType'>>
  regionalPackage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  salary?: Resolver<Maybe<ResolversTypes['VacancySalary']>, ParentType, ContextType>
  schedules?: Resolver<ReadonlyArray<Maybe<ResolversTypes['Schedule']>>, ParentType, ContextType>
  seekerApplication?: Resolver<Maybe<ResolversTypes['SeekerApplication']>, ParentType, ContextType>
  seekerDisliked?: Resolver<Maybe<ResolversTypes['SeekerDislikedVacancyDetails']>, ParentType, ContextType>
  seekerFavorite?: Resolver<Maybe<ResolversTypes['SeekerFavoriteVacancyDetails']>, ParentType, ContextType>
  serviceActivationBehavior?: Resolver<ResolversTypes['VacancyServiceActivationBehavior'], ParentType, ContextType>
  showLogo?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  showProfile?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  sortDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  sortDateText?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  statisticsTimeseries?: Resolver<Maybe<ResolversTypes['VacancyStatisticsTimeseries']>, ParentType, ContextType, RequireFields<VacancyStatisticsTimeseriesArgs, 'range'>>
  status?: Resolver<ResolversTypes['VacancyStatus'], ParentType, ContextType>
  statusChangedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  subrubrics?: Resolver<ReadonlyArray<ResolversTypes['Subrubric']>, ParentType, ContextType>
  supportApplicationWithoutResume?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['VacancyType'], ParentType, ContextType>
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  vacancyCompany?: Resolver<ResolversTypes['VacancyCompany'], ParentType, ContextType>
  vacancyCompanyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  vacancyCompanyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  vacancyCompetitors?: Resolver<Maybe<ResolversTypes['VacancyCompetitors']>, ParentType, ContextType, Partial<VacancyVacancyCompetitorsArgs>>
  vacancyKeywords?: Resolver<ReadonlyArray<Maybe<ResolversTypes['ApplyVacancyKeyword']>>, ParentType, ContextType>
  vacancyPositions?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['VacancyPosition']>>>, ParentType, ContextType>
  workTypes?: Resolver<ReadonlyArray<Maybe<ResolversTypes['VacancyWorkType']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyAdditionalCandidatePermissionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VacancyAdditionalCandidatePermissions'] = ResolversParentTypes['VacancyAdditionalCandidatePermissions']
> = {
  specialNeeds?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  student?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyAddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyAddress'] = ResolversParentTypes['VacancyAddress']> = {
  district?: Resolver<Maybe<ResolversTypes['CityDistrict']>, ParentType, ContextType>
  latitude?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>
  longitude?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>
  metro?: Resolver<Maybe<ResolversTypes['Metro']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyAddressesSuggestOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VacancyAddressesSuggestOutput'] = ResolversParentTypes['VacancyAddressesSuggestOutput']
> = {
  addresses?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyAdminRejectDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyAdminRejectDetails'] = ResolversParentTypes['VacancyAdminRejectDetails']> = {
  comment?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  commentMadeAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  madeAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyAppearedInViewPortOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VacancyAppearedInViewPortOutput'] = ResolversParentTypes['VacancyAppearedInViewPortOutput']
> = {
  isSuccessful?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyApplicationsDestinationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VacancyApplicationsDestination'] = ResolversParentTypes['VacancyApplicationsDestination']
> = {
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  type?: Resolver<ResolversTypes['ApplicationsDestinationType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyAwaitingApprovalDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VacancyAwaitingApprovalDetails'] = ResolversParentTypes['VacancyAwaitingApprovalDetails']
> = {
  detailType?: Resolver<ResolversTypes['SeekerNotificationDetailTypeEnum'], ParentType, ContextType>
  vacancySnapshot?: Resolver<ResolversTypes['NotificationCommonVacancySnapshot'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyBadgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyBadge'] = ResolversParentTypes['VacancyBadge']> = {
  URL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  statistic?: Resolver<Maybe<ResolversTypes['StatisticVacancyBadge']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyBannedByModeratorDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VacancyBannedByModeratorDetails'] = ResolversParentTypes['VacancyBannedByModeratorDetails']
> = {
  detailType?: Resolver<ResolversTypes['SeekerNotificationDetailTypeEnum'], ParentType, ContextType>
  vacancy?: Resolver<Maybe<ResolversTypes['Vacancy']>, ParentType, ContextType>
  vacancySnapshot?: Resolver<ResolversTypes['NotificationCommonVacancySnapshot'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyBannedEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyBannedEvent'] = ResolversParentTypes['VacancyBannedEvent']> = {
  addDate?: Resolver<ResolversTypes['DateTimeOffset'], ParentType, ContextType>
  assignment?: Resolver<Maybe<ResolversTypes['Assignment']>, ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['CrmCompany']>, ParentType, ContextType>
  daysLeft?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  employee?: Resolver<Maybe<ResolversTypes['CrmEmployee']>, ParentType, ContextType>
  eventType?: Resolver<ResolversTypes['EventTypeEnum'], ParentType, ContextType>
  expirationDate?: Resolver<Maybe<ResolversTypes['DateTimeOffset']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isArchieved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isRead?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  lifetimeDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  moderatorLogin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  state?: Resolver<ResolversTypes['EventStateEnum'], ParentType, ContextType>
  topics?: Resolver<ReadonlyArray<ResolversTypes['Topic']>, ParentType, ContextType>
  vacancy?: Resolver<Maybe<ResolversTypes['Vacancy']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyBenefitResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyBenefit'] = ResolversParentTypes['VacancyBenefit']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyCandidatesScreeningOptionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VacancyCandidatesScreeningOptions'] = ResolversParentTypes['VacancyCandidatesScreeningOptions']
> = {
  isEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  questionnaire?: Resolver<Maybe<ResolversTypes['Questionnaire']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyChangeClosingBehaviorOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VacancyChangeClosingBehaviorOutput'] = ResolversParentTypes['VacancyChangeClosingBehaviorOutput']
> = {
  vacancies?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Vacancy']>>>, ParentType, ContextType>
  vacancy?: Resolver<Maybe<ResolversTypes['Vacancy']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyChangeHistoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyChangeHistory'] = ResolversParentTypes['VacancyChangeHistory']> = {
  employerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  madeAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  userName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyChangeOwnerOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyChangeOwnerOutput'] = ResolversParentTypes['VacancyChangeOwnerOutput']> = {
  vacancies?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Vacancy']>>>, ParentType, ContextType>
  vacancy?: Resolver<Maybe<ResolversTypes['Vacancy']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyCityCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyCityCount'] = ResolversParentTypes['VacancyCityCount']> = {
  city?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyCloseOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyCloseOutput'] = ResolversParentTypes['VacancyCloseOutput']> = {
  vacancies?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Vacancy']>>>, ParentType, ContextType>
  vacancy?: Resolver<Maybe<ResolversTypes['Vacancy']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyCompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyCompany'] = ResolversParentTypes['VacancyCompany']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyCompetitorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyCompetitors'] = ResolversParentTypes['VacancyCompetitors']> = {
  vacancies?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Vacancy']>>>, ParentType, ContextType>
  vacancyCompetitorsStatistics?: Resolver<Maybe<ResolversTypes['VacancyCompetitorsStatistics']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyCompetitorsStatisticsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyCompetitorsStatistics'] = ResolversParentTypes['VacancyCompetitorsStatistics']> = {
  allCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  anonymousCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  businessCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  businessPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  hotCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  hotPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  optimumCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  optimumPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  professionalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  professionalPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  successfulVacanciesSalary?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  testCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyContactsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyContacts'] = ResolversParentTypes['VacancyContacts']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  phones?: Resolver<ReadonlyArray<Maybe<ResolversTypes['String']>>, ParentType, ContextType>
  photo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  socials?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyCounterGraphTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyCounterGraphType'] = ResolversParentTypes['VacancyCounterGraphType']> = {
  activeVacancyCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  lastVisit?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  otherSites?: Resolver<ReadonlyArray<ResolversTypes['OtherSiteLink']>, ParentType, ContextType>
  ourPublishedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  resumeViewCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  totalVacancyCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  uniquePotential?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  uniquePublishedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  workPublishedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  workVacancyCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyCreateErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyCreateError'] = ResolversParentTypes['VacancyCreateError']> = {
  __resolveType: TypeResolveFn<
    'CompanyWasMergedError' | 'InvalidError' | 'NoAvailableLimitsError' | 'TurbotaWasExpiredError' | 'UserIsNotAuthenticatedError' | 'UserIsNotEmployerError',
    ParentType,
    ContextType
  >
}

export type VacancyCreateOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyCreateOutput'] = ResolversParentTypes['VacancyCreateOutput']> = {
  error?: Resolver<Maybe<ResolversTypes['VacancyCreateError']>, ParentType, ContextType>
  vacancy?: Resolver<Maybe<ResolversTypes['Vacancy']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyCreateSuggestResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyCreateSuggest'] = ResolversParentTypes['VacancyCreateSuggest']> = {
  list?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>
  previous?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyCreateSuggestOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyCreateSuggestOutput'] = ResolversParentTypes['VacancyCreateSuggestOutput']> = {
  activateCvMailing?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  address?: Resolver<Maybe<ResolversTypes['VacancyCreateSuggest']>, ParentType, ContextType>
  applicationDestinationLocation?: Resolver<Maybe<ResolversTypes['VacancyCreateSuggest']>, ParentType, ContextType>
  benefits?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>
  cityId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  contactName?: Resolver<Maybe<ResolversTypes['VacancyCreateSuggest']>, ParentType, ContextType>
  contactPhones?: Resolver<Maybe<ResolversTypes['VacancyCreateSuggest']>, ParentType, ContextType>
  contactPhoto?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  contactSocials?: Resolver<Maybe<ResolversTypes['VacancyCreateSuggest']>, ParentType, ContextType>
  title?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyDesignResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyDesign'] = ResolversParentTypes['VacancyDesign']> = {
  backgroundHtml?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  footerInfo?: Resolver<Maybe<ResolversTypes['VacancyDesignFooter']>, ParentType, ContextType>
  headerInfo?: Resolver<Maybe<ResolversTypes['VacancyDesignHeader']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyDesignFooterResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyDesignFooter'] = ResolversParentTypes['VacancyDesignFooter']> = {
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyDesignHeaderResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyDesignHeader'] = ResolversParentTypes['VacancyDesignHeader']> = {
  mediaItems?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['VacancyDesignHeaderItem']>>>, ParentType, ContextType>
  videoPlayButtonImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyDesignHeaderItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyDesignHeaderItem'] = ResolversParentTypes['VacancyDesignHeaderItem']> = {
  type?: Resolver<Maybe<ResolversTypes['VacancyDesignHeaderType']>, ParentType, ContextType>
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  videoCoverImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyDictionaryBenefitResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyDictionaryBenefit'] = ResolversParentTypes['VacancyDictionaryBenefit']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyDraftOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyDraftOutput'] = ResolversParentTypes['VacancyDraftOutput']> = {
  vacancy?: Resolver<Maybe<ResolversTypes['Vacancy']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyEdge'] = ResolversParentTypes['VacancyEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<Maybe<ResolversTypes['Vacancy']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyEmployerResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyEmployer'] = ResolversParentTypes['VacancyEmployer']> = {
  applyLetterTemplate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  contactEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  contactPhone?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyEmployerCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyEmployerCount'] = ResolversParentTypes['VacancyEmployerCount']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  employer?: Resolver<ResolversTypes['VacancyEmployer'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyEndingCountDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyEndingCountDetails'] = ResolversParentTypes['VacancyEndingCountDetails']> = {
  detailType?: Resolver<ResolversTypes['SeekerNotificationDetailTypeEnum'], ParentType, ContextType>
  vacancyEndingCountSnapshot?: Resolver<ResolversTypes['NotificationVacancyEndingCountSnapshot'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyEndingLeftDaysDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyEndingLeftDaysDetails'] = ResolversParentTypes['VacancyEndingLeftDaysDetails']> = {
  detailType?: Resolver<ResolversTypes['SeekerNotificationDetailTypeEnum'], ParentType, ContextType>
  vacancy?: Resolver<Maybe<ResolversTypes['Vacancy']>, ParentType, ContextType>
  vacancySnapshot?: Resolver<ResolversTypes['NotificationEndingDaysVacancySnapshot'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyEventStatisticItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyEventStatisticItem'] = ResolversParentTypes['VacancyEventStatisticItem']> = {
  date?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  datetime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['VacancyEventType']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyExperienceQuestionResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyExperienceQuestion'] = ResolversParentTypes['VacancyExperienceQuestion']> = {
  experience?: Resolver<ResolversTypes['Experience'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyFromAnotherSourceGraphTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VacancyFromAnotherSourceGraphType'] = ResolversParentTypes['VacancyFromAnotherSourceGraphType']
> = {
  additionalCandidatePermissions?: Resolver<ResolversTypes['VacancyAdditionalCandidatePermissions'], ParentType, ContextType>
  address?: Resolver<Maybe<ResolversTypes['VacancyAddress']>, ParentType, ContextType>
  city?: Resolver<ResolversTypes['City'], ParentType, ContextType>
  contacts?: Resolver<ResolversTypes['VacancyContacts'], ParentType, ContextType>
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  employmentTypes?: Resolver<ReadonlyArray<ResolversTypes['VacancyEmploymentType']>, ParentType, ContextType>
  hasSupportApplicationWithoutResume?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  salary?: Resolver<Maybe<ResolversTypes['VacancySalary']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  workTypes?: Resolver<ReadonlyArray<ResolversTypes['VacancyWorkType']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyHotPeriodResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyHotPeriod'] = ResolversParentTypes['VacancyHotPeriod']> = {
  begin?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  daysUntilEnd?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  end?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyInterviewQuestionsMutationsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VacancyInterviewQuestionsMutations'] = ResolversParentTypes['VacancyInterviewQuestionsMutations']
> = {
  update?: Resolver<ResolversTypes['SaveVacancyInterviewQuestionsOutput'], ParentType, ContextType, RequireFields<VacancyInterviewQuestionsMutationsUpdateArgs, 'input'>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyLanguageQuestionResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyLanguageQuestion'] = ResolversParentTypes['VacancyLanguageQuestion']> = {
  language?: Resolver<ResolversTypes['Lang'], ParentType, ContextType>
  languageSkillLevel?: Resolver<ResolversTypes['LanguageSkillLevel'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyMakeHotOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyMakeHotOutput'] = ResolversParentTypes['VacancyMakeHotOutput']> = {
  vacancy?: Resolver<Maybe<ResolversTypes['Vacancy']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyMediaResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyMedia'] = ResolversParentTypes['VacancyMedia']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  type?: Resolver<ResolversTypes['VacancyMediaType'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyModeratorRejectDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VacancyModeratorRejectDetails'] = ResolversParentTypes['VacancyModeratorRejectDetails']
> = {
  commentMadeAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  comments?: Resolver<ReadonlyArray<Maybe<ResolversTypes['String']>>, ParentType, ContextType>
  madeAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyOnWorkWillExpireInFiveDaysEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VacancyOnWorkWillExpireInFiveDaysEvent'] = ResolversParentTypes['VacancyOnWorkWillExpireInFiveDaysEvent']
> = {
  addDate?: Resolver<ResolversTypes['DateTimeOffset'], ParentType, ContextType>
  assignment?: Resolver<Maybe<ResolversTypes['Assignment']>, ParentType, ContextType>
  company?: Resolver<Maybe<ResolversTypes['CrmCompany']>, ParentType, ContextType>
  daysLeft?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  employee?: Resolver<Maybe<ResolversTypes['CrmEmployee']>, ParentType, ContextType>
  eventType?: Resolver<ResolversTypes['EventTypeEnum'], ParentType, ContextType>
  expirationDate?: Resolver<Maybe<ResolversTypes['DateTimeOffset']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isArchieved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isRead?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  lifetimeDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  spiderVacancyLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  spiderVacancyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  state?: Resolver<ResolversTypes['EventStateEnum'], ParentType, ContextType>
  topics?: Resolver<ReadonlyArray<ResolversTypes['Topic']>, ParentType, ContextType>
  workVacancy?: Resolver<Maybe<ResolversTypes['SpiderVacancy']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyOwnerChangedDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyOwnerChangedDetails'] = ResolversParentTypes['VacancyOwnerChangedDetails']> = {
  detailType?: Resolver<ResolversTypes['SeekerNotificationDetailTypeEnum'], ParentType, ContextType>
  initiator?: Resolver<ResolversTypes['NotificationInitiator'], ParentType, ContextType>
  newOwner?: Resolver<ResolversTypes['NotificationEmployeeSnapshot'], ParentType, ContextType>
  oldOwner?: Resolver<ResolversTypes['NotificationEmployeeSnapshot'], ParentType, ContextType>
  vacancySnapshot?: Resolver<ResolversTypes['NotificationOwnerChangedVacancySnapshot'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyPackageCatalogServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyPackageCatalogService'] = ResolversParentTypes['VacancyPackageCatalogService']> = {
  additionalServices?: Resolver<ReadonlyArray<ResolversTypes['CatalogChildService']>, ParentType, ContextType>
  calculatePrice?: Resolver<ResolversTypes['ServicePrice'], ParentType, ContextType, Partial<VacancyPackageCatalogServiceCalculatePriceArgs>>
  calculatePrices?: Resolver<ReadonlyArray<ResolversTypes['ServicePrice']>, ParentType, ContextType, Partial<VacancyPackageCatalogServiceCalculatePricesArgs>>
  contactsAccrualPeriod?: Resolver<Maybe<ResolversTypes['ContactsAccrualPeriod']>, ParentType, ContextType>
  contactsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  groupWrapper?: Resolver<ResolversTypes['ServiceGroupWrapper'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isPackage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isUnlimitedContacts?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  publicationType?: Resolver<Maybe<ResolversTypes['VacancyPublicationType']>, ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  supportedRegions?: Resolver<ReadonlyArray<ResolversTypes['City']>, ParentType, ContextType>
  totalVacancyCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  typeWrapper?: Resolver<ResolversTypes['ServiceTypeWrapper'], ParentType, ContextType>
  vacancyMailingCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  vacancyPublishDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  vacancyRisingCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyPositionResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyPosition'] = ResolversParentTypes['VacancyPosition']> = {
  groupTag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  position?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyPositionRisingInSearchListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VacancyPositionRisingInSearchList'] = ResolversParentTypes['VacancyPositionRisingInSearchList']
> = {
  last?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  leftDates?: Resolver<ReadonlyArray<Maybe<ResolversTypes['DateTime']>>, ParentType, ContextType>
  leftTimes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  next?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyProlongationRequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyProlongationRequest'] = ResolversParentTypes['VacancyProlongationRequest']> = {
  desiredClosingBehavior?: Resolver<ResolversTypes['VacancyClosingBehavior'], ParentType, ContextType>
  desiredServiceActivationBehavior?: Resolver<ResolversTypes['VacancyServiceActivationBehavior'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  vacancy?: Resolver<ResolversTypes['Vacancy'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyProlongationRequestsOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VacancyProlongationRequestsOutput'] = ResolversParentTypes['VacancyProlongationRequestsOutput']
> = {
  requests?: Resolver<ReadonlyArray<ResolversTypes['VacancyProlongationRequest']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyProlongationReviewErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VacancyProlongationReviewError'] = ResolversParentTypes['VacancyProlongationReviewError']
> = {
  __resolveType: TypeResolveFn<'AlreadyReviewedVacancyProlongationError', ParentType, ContextType>
}

export type VacancyProlongationReviewOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VacancyProlongationReviewOutput'] = ResolversParentTypes['VacancyProlongationReviewOutput']
> = {
  error?: Resolver<Maybe<ResolversTypes['VacancyProlongationReviewError']>, ParentType, ContextType>
  vacancies?: Resolver<Maybe<ReadonlyArray<ResolversTypes['Vacancy']>>, ParentType, ContextType>
  vacancy?: Resolver<Maybe<ResolversTypes['Vacancy']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyPublicationApprovedDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VacancyPublicationApprovedDetails'] = ResolversParentTypes['VacancyPublicationApprovedDetails']
> = {
  detailType?: Resolver<ResolversTypes['SeekerNotificationDetailTypeEnum'], ParentType, ContextType>
  vacancySnapshot?: Resolver<ResolversTypes['NotificationCommonVacancySnapshot'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyPublicationCatalogServiceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VacancyPublicationCatalogService'] = ResolversParentTypes['VacancyPublicationCatalogService']
> = {
  calculatePrice?: Resolver<ResolversTypes['ServicePrice'], ParentType, ContextType, Partial<VacancyPublicationCatalogServiceCalculatePriceArgs>>
  calculatePrices?: Resolver<ReadonlyArray<ResolversTypes['ServicePrice']>, ParentType, ContextType, Partial<VacancyPublicationCatalogServiceCalculatePricesArgs>>
  groupWrapper?: Resolver<ResolversTypes['ServiceGroupWrapper'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isPackage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  publicationType?: Resolver<Maybe<ResolversTypes['VacancyPublicationType']>, ParentType, ContextType>
  serviceActiveDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  supportedRegions?: Resolver<ReadonlyArray<ResolversTypes['City']>, ParentType, ContextType>
  typeWrapper?: Resolver<ResolversTypes['ServiceTypeWrapper'], ParentType, ContextType>
  vacancyMailingCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  vacancyPublishDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  vacancyRisingCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyPublicationEndedDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VacancyPublicationEndedDetails'] = ResolversParentTypes['VacancyPublicationEndedDetails']
> = {
  detailType?: Resolver<ResolversTypes['SeekerNotificationDetailTypeEnum'], ParentType, ContextType>
  vacancy?: Resolver<Maybe<ResolversTypes['Vacancy']>, ParentType, ContextType>
  vacancySnapshot?: Resolver<ResolversTypes['NotificationCommonVacancySnapshot'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyPublicationRejectedDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VacancyPublicationRejectedDetails'] = ResolversParentTypes['VacancyPublicationRejectedDetails']
> = {
  detailType?: Resolver<ResolversTypes['SeekerNotificationDetailTypeEnum'], ParentType, ContextType>
  vacancySnapshot?: Resolver<ResolversTypes['NotificationCommonVacancySnapshot'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyPublicationTypeWrapperResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VacancyPublicationTypeWrapper'] = ResolversParentTypes['VacancyPublicationTypeWrapper']
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['VacancyPublicationType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyPublishOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyPublishOutput'] = ResolversParentTypes['VacancyPublishOutput']> = {
  errors?: Resolver<ReadonlyArray<ResolversTypes['ModifyVacancyErrorUnion']>, ParentType, ContextType>
  vacancies?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Vacancy']>>>, ParentType, ContextType>
  vacancy?: Resolver<Maybe<ResolversTypes['Vacancy']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyPublishPeriodResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyPublishPeriod'] = ResolversParentTypes['VacancyPublishPeriod']> = {
  autoProlongEnd?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  begin?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  daysUntilEnd?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  end?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  nextAutoProlongDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyPublishedEmployerAchievementResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VacancyPublishedEmployerAchievement'] = ResolversParentTypes['VacancyPublishedEmployerAchievement']
> = {
  currentCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  isDone?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  targetCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['EmployerAchievementType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyPublishedWithFilledAddressAndSalaryEmployerAchievementResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VacancyPublishedWithFilledAddressAndSalaryEmployerAchievement'] = ResolversParentTypes['VacancyPublishedWithFilledAddressAndSalaryEmployerAchievement']
> = {
  isDone?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['EmployerAchievementType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyRejectPublicationOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VacancyRejectPublicationOutput'] = ResolversParentTypes['VacancyRejectPublicationOutput']
> = {
  vacancy?: Resolver<Maybe<ResolversTypes['Vacancy']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyReportOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyReportOutput'] = ResolversParentTypes['VacancyReportOutput']> = {
  isSuccessful?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  key?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyRepublishOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyRepublishOutput'] = ResolversParentTypes['VacancyRepublishOutput']> = {
  errors?: Resolver<ReadonlyArray<ResolversTypes['ModifyVacancyErrorUnion']>, ParentType, ContextType>
  vacancies?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Vacancy']>>>, ParentType, ContextType>
  vacancy?: Resolver<Maybe<ResolversTypes['Vacancy']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyResumeOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyResumeOutput'] = ResolversParentTypes['VacancyResumeOutput']> = {
  vacancies?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Vacancy']>>>, ParentType, ContextType>
  vacancy?: Resolver<Maybe<ResolversTypes['Vacancy']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancySalaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancySalary'] = ResolversParentTypes['VacancySalary']> = {
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  amountFrom?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  amountTo?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  currency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancySearchSuggestResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancySearchSuggest'] = ResolversParentTypes['VacancySearchSuggest']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  logo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  searchCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  synonymousId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  vacancyCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancySearchSuggestListResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancySearchSuggestList'] = ResolversParentTypes['VacancySearchSuggestList']> = {
  suggests?: Resolver<ReadonlyArray<ResolversTypes['VacancySearchSuggest']>, ParentType, ContextType>
  type?: Resolver<ResolversTypes['VacancySearchSuggestTypeEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancySearchSuggestsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancySearchSuggests'] = ResolversParentTypes['VacancySearchSuggests']> = {
  type?: Resolver<Maybe<ResolversTypes['SuggestType']>, ParentType, ContextType>
  typedSuggests?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['VacancySearchSuggest']>>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancySearchSuggestsQueriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancySearchSuggestsQueries'] = ResolversParentTypes['VacancySearchSuggestsQueries']> = {
  getVacancySearchSuggests?: Resolver<ResolversTypes['GetVacancySearchSuggestsOutput'], ParentType, ContextType, Partial<VacancySearchSuggestsQueriesGetVacancySearchSuggestsArgs>>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyServiceActivationBehaviorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VacancyServiceActivationBehavior'] = ResolversParentTypes['VacancyServiceActivationBehavior']
> = {
  catalogService?: Resolver<Maybe<ResolversTypes['CatalogUnitedServices']>, ParentType, ContextType>
  isActivated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  serviceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyStatisticsItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyStatisticsItem'] = ResolversParentTypes['VacancyStatisticsItem']> = {
  timeseries?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['VacancyTimeseriesItem']>>>, ParentType, ContextType>
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyStatisticsTimeseriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyStatisticsTimeseries'] = ResolversParentTypes['VacancyStatisticsTimeseries']> = {
  applies?: Resolver<Maybe<ResolversTypes['VacancyStatisticsItem']>, ParentType, ContextType>
  keyEvents?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['VacancyEventStatisticItem']>>>, ParentType, ContextType>
  openedContacts?: Resolver<Maybe<ResolversTypes['VacancyStatisticsItem']>, ParentType, ContextType>
  shows?: Resolver<Maybe<ResolversTypes['VacancyStatisticsItem']>, ParentType, ContextType>
  views?: Resolver<Maybe<ResolversTypes['VacancyStatisticsItem']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyStatusCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyStatusCount'] = ResolversParentTypes['VacancyStatusCount']> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['VacancyStatus'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancySuspendOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancySuspendOutput'] = ResolversParentTypes['VacancySuspendOutput']> = {
  vacancies?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Vacancy']>>>, ParentType, ContextType>
  vacancy?: Resolver<Maybe<ResolversTypes['Vacancy']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyTimeseriesItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyTimeseriesItem'] = ResolversParentTypes['VacancyTimeseriesItem']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  date?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyToAtsProjectExportErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VacancyToAtsProjectExportError'] = ResolversParentTypes['VacancyToAtsProjectExportError']
> = {
  __resolveType: TypeResolveFn<'AtsProjectCreationError' | 'InvalidError' | 'NotAvailableError' | 'ServerError', ParentType, ContextType>
}

export type VacancyToAtsProjectExportOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VacancyToAtsProjectExportOutput'] = ResolversParentTypes['VacancyToAtsProjectExportOutput']
> = {
  error?: Resolver<Maybe<ResolversTypes['VacancyToAtsProjectExportError']>, ParentType, ContextType>
  result?: Resolver<Maybe<ResolversTypes['VacancyToAtsProjectExportResult']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyToAtsProjectExportResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VacancyToAtsProjectExportResult'] = ResolversParentTypes['VacancyToAtsProjectExportResult']
> = {
  candidatesUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VacancyUpdateOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['VacancyUpdateOutput'] = ResolversParentTypes['VacancyUpdateOutput']> = {
  error?: Resolver<Maybe<ResolversTypes['ModifyVacancyErrorUnion']>, ParentType, ContextType>
  vacancy?: Resolver<Maybe<ResolversTypes['Vacancy']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ViewNotificationsErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ViewNotificationsError'] = ResolversParentTypes['ViewNotificationsError']> = {
  __resolveType: TypeResolveFn<'NotificationNotFoundError' | 'UnknownServerError', ParentType, ContextType>
}

export type ViewNotificationsOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ViewNotificationsOutput'] = ResolversParentTypes['ViewNotificationsOutput']> = {
  errors?: Resolver<Maybe<ReadonlyArray<ResolversTypes['ViewNotificationsError']>>, ParentType, ContextType>
  notifications?: Resolver<Maybe<ReadonlyArray<ResolversTypes['Notification']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WalletResolvers<ContextType = any, ParentType extends ResolversParentTypes['Wallet'] = ResolversParentTypes['Wallet']> = {
  balance?: Resolver<ResolversTypes['WalletBalance'], ParentType, ContextType>
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  createdBy?: Resolver<ResolversTypes['WalletUserUnion'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  transactions?: Resolver<Maybe<ResolversTypes['TransactionsConnection']>, ParentType, ContextType, Partial<WalletTransactionsArgs>>
  users?: Resolver<ReadonlyArray<ResolversTypes['WalletUserUnion']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WalletBalanceResolvers<ContextType = any, ParentType extends ResolversParentTypes['WalletBalance'] = ResolversParentTypes['WalletBalance']> = {
  burnAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  holdAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  totalAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WalletCartMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['WalletCartMutation'] = ResolversParentTypes['WalletCartMutation']> = {
  apply?: Resolver<ResolversTypes['ApplyWalletBonusResponse'], ParentType, ContextType, RequireFields<WalletCartMutationApplyArgs, 'amount'>>
  drop?: Resolver<ResolversTypes['DropAppliedWalletBonusResponse'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WalletDoesNotExistErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['WalletDoesNotExistError'] = ResolversParentTypes['WalletDoesNotExistError']> = {
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WalletExistsErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['WalletExistsError'] = ResolversParentTypes['WalletExistsError']> = {
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WalletTransactionResolvers<ContextType = any, ParentType extends ResolversParentTypes['WalletTransaction'] = ResolversParentTypes['WalletTransaction']> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  expirationDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  initiator?: Resolver<ResolversTypes['WalletTransactionInitiatorUnion'], ParentType, ContextType>
  orderId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  reason?: Resolver<Maybe<ResolversTypes['TransactionReasonEnum']>, ParentType, ContextType>
  reasonDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  status?: Resolver<ResolversTypes['TransactionStatusEnum'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['TransactionTypeEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WalletTransactionEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['WalletTransactionEvent'] = ResolversParentTypes['WalletTransactionEvent']> = {
  addedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  expirationDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  initiator?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  order?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType>
  performer?: Resolver<ResolversTypes['PerformerEmployerEventUnion'], ParentType, ContextType>
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  reasonDescriptionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  transactionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WalletTransactionInitiatorUnionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WalletTransactionInitiatorUnion'] = ResolversParentTypes['WalletTransactionInitiatorUnion']
> = {
  __resolveType: TypeResolveFn<'EmployerWalletUser' | 'ManagerWalletUser' | 'SeekerWalletUser' | 'SystemWalletUser', ParentType, ContextType>
}

export type WalletUserUnionResolvers<ContextType = any, ParentType extends ResolversParentTypes['WalletUserUnion'] = ResolversParentTypes['WalletUserUnion']> = {
  __resolveType: TypeResolveFn<'EmployerWalletUser' | 'ManagerWalletUser' | 'SeekerWalletUser' | 'SystemWalletUser', ParentType, ContextType>
}

export type WalletsConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['WalletsConnection'] = ResolversParentTypes['WalletsConnection']> = {
  edges?: Resolver<Maybe<ReadonlyArray<ResolversTypes['WalletsEdge']>>, ParentType, ContextType>
  nodes?: Resolver<Maybe<ReadonlyArray<ResolversTypes['Wallet']>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WalletsEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['WalletsEdge'] = ResolversParentTypes['WalletsEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  node?: Resolver<ResolversTypes['Wallet'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WarehouseGiftResolvers<ContextType = any, ParentType extends ResolversParentTypes['WarehouseGift'] = ResolversParentTypes['WarehouseGift']> = {
  addDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  cost?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>
  giftType?: Resolver<ResolversTypes['GiftTypeEnum'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ranges?: Resolver<ReadonlyArray<ResolversTypes['WarehouseRange']>, ParentType, ContextType>
  standCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  storageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  weight?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WarehouseLimitedGiftResolvers<ContextType = any, ParentType extends ResolversParentTypes['WarehouseLimitedGift'] = ResolversParentTypes['WarehouseLimitedGift']> = {
  addDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>
  cost?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  limitCountTransfered?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  occasion?: Resolver<ResolversTypes['GiftOccasion'], ParentType, ContextType>
  occasionStartDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>
  standCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  wave?: Resolver<Maybe<ResolversTypes['GiftOccasion']>, ParentType, ContextType>
  weight?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WarehouseRangeResolvers<ContextType = any, ParentType extends ResolversParentTypes['WarehouseRange'] = ResolversParentTypes['WarehouseRange']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isInRange?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WidgetPaymentMethodResolvers<ContextType = any, ParentType extends ResolversParentTypes['WidgetPaymentMethod'] = ResolversParentTypes['WidgetPaymentMethod']> = {
  generate?: Resolver<ResolversTypes['WidgetPaymentMethodOutput'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WidgetPaymentMethodOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['WidgetPaymentMethodOutput'] = ResolversParentTypes['WidgetPaymentMethodOutput']> = {
  data?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  paymentSystem?: Resolver<ResolversTypes['PaymentSystemTypeEnum'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AppliesByUrlResolvers<ContextType = any, ParentType extends ResolversParentTypes['appliesByUrl'] = ResolversParentTypes['appliesByUrl']> = {
  timeseries?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['timeseries']>>>, ParentType, ContextType>
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClickOnLinksResolvers<ContextType = any, ParentType extends ResolversParentTypes['clickOnLinks'] = ResolversParentTypes['clickOnLinks']> = {
  timeseries?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['timeseries']>>>, ParentType, ContextType>
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ServiceAvailableContactsResolvers<ContextType = any, ParentType extends ResolversParentTypes['serviceAvailableContacts'] = ResolversParentTypes['serviceAvailableContacts']> = {
  contactsAccrualPeriod?: Resolver<Maybe<ResolversTypes['ServiceContactsAccrualPeriod']>, ParentType, ContextType>
  hasServicesWithLimitedDailyContacts?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasServicesWithLimitedMonthlyContacts?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasServicesWithUnlimitedContacts?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  leftLimitedContactsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  limitedDailyContactsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TimeseriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['timeseries'] = ResolversParentTypes['timeseries']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  date?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Resolvers<ContextType = any> = {
  AccountDateChanges?: AccountDateChangesResolvers<ContextType>
  AcquaintWithNotificationsError?: AcquaintWithNotificationsErrorResolvers<ContextType>
  AcquaintWithNotificationsOutput?: AcquaintWithNotificationsOutputResolvers<ContextType>
  ActionAttach?: ActionAttachResolvers<ContextType>
  ActionCounter?: ActionCounterResolvers<ContextType>
  ActionFilters?: ActionFiltersResolvers<ContextType>
  ActionStateCounter?: ActionStateCounterResolvers<ContextType>
  ActionTeamCounter?: ActionTeamCounterResolvers<ContextType>
  ActivatedAdditionalService?: ActivatedAdditionalServiceResolvers<ContextType>
  ActivatedCvDbService?: ActivatedCvDbServiceResolvers<ContextType>
  ActivatedHotService?: ActivatedHotServiceResolvers<ContextType>
  ActivatedLogoInPortalService?: ActivatedLogoInPortalServiceResolvers<ContextType>
  ActivatedLogoInRubricService?: ActivatedLogoInRubricServiceResolvers<ContextType>
  ActivatedOrderByTurbotaServiceEmployerEvent?: ActivatedOrderByTurbotaServiceEmployerEventResolvers<ContextType>
  ActivatedRisingUpService?: ActivatedRisingUpServiceResolvers<ContextType>
  ActivatedServiceEmployerEvent?: ActivatedServiceEmployerEventResolvers<ContextType>
  ActivatedVacancyPackageService?: ActivatedVacancyPackageServiceResolvers<ContextType>
  ActivatedVacancyPublicationService?: ActivatedVacancyPublicationServiceResolvers<ContextType>
  AdamLabel?: AdamLabelResolvers<ContextType>
  AddClubPostOutput?: AddClubPostOutputResolvers<ContextType>
  AddGamificationLimitsOutput?: AddGamificationLimitsOutputResolvers<ContextType>
  AddGiftRequestOutput?: AddGiftRequestOutputResolvers<ContextType>
  AddOtherSiteLinkOutput?: AddOtherSiteLinkOutputResolvers<ContextType>
  AddPromoCodeResponse?: AddPromoCodeResponseResolvers<ContextType>
  AddSeekerFavoriteVacanciesOutput?: AddSeekerFavoriteVacanciesOutputResolvers<ContextType>
  AddSeekerProfResumeAdditionalEducationsError?: AddSeekerProfResumeAdditionalEducationsErrorResolvers<ContextType>
  AddSeekerProfResumeAdditionalInfoError?: AddSeekerProfResumeAdditionalInfoErrorResolvers<ContextType>
  AddSeekerProfResumeEducationsError?: AddSeekerProfResumeEducationsErrorResolvers<ContextType>
  AddSeekerProfResumeExperiencesError?: AddSeekerProfResumeExperiencesErrorResolvers<ContextType>
  AddSeekerSubscriptionMutations?: AddSeekerSubscriptionMutationsResolvers<ContextType>
  AddedNewUserManagementEmployerEvent?: AddedNewUserManagementEmployerEventResolvers<ContextType>
  AddedProfResumeAdditionalEducationsOutput?: AddedProfResumeAdditionalEducationsOutputResolvers<ContextType>
  AddedProfResumeAdditionalInfoOutput?: AddedProfResumeAdditionalInfoOutputResolvers<ContextType>
  AddedProfResumeEducationsOutput?: AddedProfResumeEducationsOutputResolvers<ContextType>
  AddedProfResumeExperiencesOutput?: AddedProfResumeExperiencesOutputResolvers<ContextType>
  AdditionalCatalogService?: AdditionalCatalogServiceResolvers<ContextType>
  AdditionalServiceEmployerEventSnapshot?: AdditionalServiceEmployerEventSnapshotResolvers<ContextType>
  AdvertisingBanner?: AdvertisingBannerResolvers<ContextType>
  AlreadyReviewedVacancyProlongationError?: AlreadyReviewedVacancyProlongationErrorResolvers<ContextType>
  AnotherSourceVacancyOutput?: AnotherSourceVacancyOutputResolvers<ContextType>
  AnswerOption?: AnswerOptionResolvers<ContextType>
  AnswerOptionSuggest?: AnswerOptionSuggestResolvers<ContextType>
  ApplicationInvitedNotificationDetails?: ApplicationInvitedNotificationDetailsResolvers<ContextType>
  ApplicationRefusedNotificationDetails?: ApplicationRefusedNotificationDetailsResolvers<ContextType>
  ApplicationResumeFilling?: ApplicationResumeFillingResolvers<ContextType>
  ApplicationSentNotificationDetails?: ApplicationSentNotificationDetailsResolvers<ContextType>
  ApplicationViewedNotificationDetails?: ApplicationViewedNotificationDetailsResolvers<ContextType>
  ApplicationsViewedEmployerAchievement?: ApplicationsViewedEmployerAchievementResolvers<ContextType>
  Apply?: ApplyResolvers<ContextType>
  ApplyAddCompanyAddressOutput?: ApplyAddCompanyAddressOutputResolvers<ContextType>
  ApplyAddNoteOutput?: ApplyAddNoteOutputResolvers<ContextType>
  ApplyAggregation?: ApplyAggregationResolvers<ContextType>
  ApplyAtsProject?: ApplyAtsProjectResolvers<ContextType>
  ApplyAtsProjectEdge?: ApplyAtsProjectEdgeResolvers<ContextType>
  ApplyAttachParsedFileContent?: ApplyAttachParsedFileContentResolvers<ContextType>
  ApplyAttachResume?: ApplyAttachResumeResolvers<ContextType>
  ApplyChangeStatusOutput?: ApplyChangeStatusOutputResolvers<ContextType>
  ApplyChangeStatusesOutput?: ApplyChangeStatusesOutputResolvers<ContextType>
  ApplyCompanyAddress?: ApplyCompanyAddressResolvers<ContextType>
  ApplyConnection?: ApplyConnectionResolvers<ContextType>
  ApplyContactLink?: ApplyContactLinkResolvers<ContextType>
  ApplyCoveringLetter?: ApplyCoveringLetterResolvers<ContextType>
  ApplyDeleteCompanyAddressOutput?: ApplyDeleteCompanyAddressOutputResolvers<ContextType>
  ApplyDeleteNoteOutput?: ApplyDeleteNoteOutputResolvers<ContextType>
  ApplyEdge?: ApplyEdgeResolvers<ContextType>
  ApplyEditNoteOutput?: ApplyEditNoteOutputResolvers<ContextType>
  ApplyEmail?: ApplyEmailResolvers<ContextType>
  ApplyEmployer?: ApplyEmployerResolvers<ContextType>
  ApplyEmptyResume?: ApplyEmptyResumeResolvers<ContextType>
  ApplyFacetUnion?: ApplyFacetUnionResolvers<ContextType>
  ApplyInvitationDetails?: ApplyInvitationDetailsResolvers<ContextType>
  ApplyInviteOutput?: ApplyInviteOutputResolvers<ContextType>
  ApplyJobSeeker?: ApplyJobSeekerResolvers<ContextType>
  ApplyNote?: ApplyNoteResolvers<ContextType>
  ApplyPhone?: ApplyPhoneResolvers<ContextType>
  ApplyProfResume?: ApplyProfResumeResolvers<ContextType>
  ApplyProfResumeFullName?: ApplyProfResumeFullNameResolvers<ContextType>
  ApplyProjectAtsConnection?: ApplyProjectAtsConnectionResolvers<ContextType>
  ApplyRefusalDetails?: ApplyRefusalDetailsResolvers<ContextType>
  ApplyRefuseOutput?: ApplyRefuseOutputResolvers<ContextType>
  ApplyResumeAdditional?: ApplyResumeAdditionalResolvers<ContextType>
  ApplyResumeAdditionalEducation?: ApplyResumeAdditionalEducationResolvers<ContextType>
  ApplyResumeContacts?: ApplyResumeContactsResolvers<ContextType>
  ApplyResumeDesiredPosition?: ApplyResumeDesiredPositionResolvers<ContextType>
  ApplyResumeExperience?: ApplyResumeExperienceResolvers<ContextType>
  ApplyResumeFillingTypeCount?: ApplyResumeFillingTypeCountResolvers<ContextType>
  ApplyResumeGeneralEducation?: ApplyResumeGeneralEducationResolvers<ContextType>
  ApplyResumeInterface?: ApplyResumeInterfaceResolvers<ContextType>
  ApplyResumeLanguageSkill?: ApplyResumeLanguageSkillResolvers<ContextType>
  ApplyResumeRecommendation?: ApplyResumeRecommendationResolvers<ContextType>
  ApplySalary?: ApplySalaryResolvers<ContextType>
  ApplyStatus?: ApplyStatusResolvers<ContextType>
  ApplyStatusCount?: ApplyStatusCountResolvers<ContextType>
  ApplySurverQuestionImportance?: ApplySurverQuestionImportanceResolvers<ContextType>
  ApplySurveyAnswer?: ApplySurveyAnswerResolvers<ContextType>
  ApplySurveyResult?: ApplySurveyResultResolvers<ContextType>
  ApplyToAtsExportError?: ApplyToAtsExportErrorResolvers<ContextType>
  ApplyToAtsExportOutput?: ApplyToAtsExportOutputResolvers<ContextType>
  ApplyToAtsExportResult?: ApplyToAtsExportResultResolvers<ContextType>
  ApplyUpdateCompanyAddressOutput?: ApplyUpdateCompanyAddressOutputResolvers<ContextType>
  ApplyVacancyKeyword?: ApplyVacancyKeywordResolvers<ContextType>
  ApplyWalletBonusResponse?: ApplyWalletBonusResponseResolvers<ContextType>
  ApprovedProlongationRequestVacancyEmployerEvent?: ApprovedProlongationRequestVacancyEmployerEventResolvers<ContextType>
  ApprovedReviewVacancyEmployerEvent?: ApprovedReviewVacancyEmployerEventResolvers<ContextType>
  Assignment?: AssignmentResolvers<ContextType>
  AssignmentConnection?: AssignmentConnectionResolvers<ContextType>
  AssignmentEdge?: AssignmentEdgeResolvers<ContextType>
  AssignmentOperationOutput?: AssignmentOperationOutputResolvers<ContextType>
  AtsProjectCreationError?: AtsProjectCreationErrorResolvers<ContextType>
  AttachNotFound?: AttachNotFoundResolvers<ContextType>
  AttachParsingError?: AttachParsingErrorResolvers<ContextType>
  AutoProlongationDisabledVacancyEmployerEvent?: AutoProlongationDisabledVacancyEmployerEventResolvers<ContextType>
  AutoProlongationEnabledVacancyEmployerEvent?: AutoProlongationEnabledVacancyEmployerEventResolvers<ContextType>
  AutoProlongedVacancyEmployerEvent?: AutoProlongedVacancyEmployerEventResolvers<ContextType>
  BanInfo?: BanInfoResolvers<ContextType>
  BanReasonInfo?: BanReasonInfoResolvers<ContextType>
  Banner?: BannerResolvers<ContextType>
  BannerButton?: BannerButtonResolvers<ContextType>
  BannerClock?: BannerClockResolvers<ContextType>
  BannerDynamicElement?: BannerDynamicElementResolvers<ContextType>
  BannerImageElement?: BannerImageElementResolvers<ContextType>
  BannerImageSize?: BannerImageSizeResolvers<ContextType>
  BannerProgressBar?: BannerProgressBarResolvers<ContextType>
  BannerQueries?: BannerQueriesResolvers<ContextType>
  BannerTextElement?: BannerTextElementResolvers<ContextType>
  BasicQuestion?: BasicQuestionResolvers<ContextType>
  BasicQuestionItem?: BasicQuestionItemResolvers<ContextType>
  BillFilters?: BillFiltersResolvers<ContextType>
  BlockedUserManagementEmployerEvent?: BlockedUserManagementEmployerEventResolvers<ContextType>
  Branch?: BranchResolvers<ContextType>
  BriefParsedCv?: BriefParsedCvResolvers<ContextType>
  BriefParsedCvEdge?: BriefParsedCvEdgeResolvers<ContextType>
  Bucket?: BucketResolvers<ContextType>
  CabinetEventsDateRange?: CabinetEventsDateRangeResolvers<ContextType>
  CabinetEventsFilter?: CabinetEventsFilterResolvers<ContextType>
  CabinetEventsQueryFilter?: CabinetEventsQueryFilterResolvers<ContextType>
  CalculatePriceItem?: CalculatePriceItemResolvers<ContextType>
  CalculatePricesFilter?: CalculatePricesFilterResolvers<ContextType>
  CallFilters?: CallFiltersResolvers<ContextType>
  CandidateInteraction?: CandidateInteractionResolvers<ContextType>
  CandidateInteractionWrapper?: CandidateInteractionWrapperResolvers<ContextType>
  CandidateMatchedKeyword?: CandidateMatchedKeywordResolvers<ContextType>
  CandidateResumeEmployerEventSnapshot?: CandidateResumeEmployerEventSnapshotResolvers<ContextType>
  CandidateSourceType?: CandidateSourceTypeResolvers<ContextType>
  CandidatesVacancyCounters?: CandidatesVacancyCountersResolvers<ContextType>
  CartItem?: CartItemResolvers<ContextType>
  CartMutation?: CartMutationResolvers<ContextType>
  CartQuery?: CartQueryResolvers<ContextType>
  CatalogChildService?: CatalogChildServiceResolvers<ContextType>
  CatalogService?: CatalogServiceResolvers<ContextType>
  CatalogUnitedServices?: CatalogUnitedServicesResolvers<ContextType>
  CategorizationAttachesMutations?: CategorizationAttachesMutationsResolvers<ContextType>
  CategorizationAttachesQueries?: CategorizationAttachesQueriesResolvers<ContextType>
  CategorizationResultOutput?: CategorizationResultOutputResolvers<ContextType>
  Categorizator?: CategorizatorResolvers<ContextType>
  ChangeSavedCardsErrorUnion?: ChangeSavedCardsErrorUnionResolvers<ContextType>
  ChangeSavedCardsResponse?: ChangeSavedCardsResponseResolvers<ContextType>
  ChangeSeekerEmailMutation?: ChangeSeekerEmailMutationResolvers<ContextType>
  ChangeSeekerEmailOutput?: ChangeSeekerEmailOutputResolvers<ContextType>
  ChangeSeekerNameMutationOutput?: ChangeSeekerNameMutationOutputResolvers<ContextType>
  ChangeSeekerPasswordByEmailMutations?: ChangeSeekerPasswordByEmailMutationsResolvers<ContextType>
  ChangeSeekerPasswordByPhoneMutations?: ChangeSeekerPasswordByPhoneMutationsResolvers<ContextType>
  ChangeSeekerPasswordMutations?: ChangeSeekerPasswordMutationsResolvers<ContextType>
  ChangeSeekerPasswordOutput?: ChangeSeekerPasswordOutputResolvers<ContextType>
  ChangedFullNameUserManagementEmployerEvent?: ChangedFullNameUserManagementEmployerEventResolvers<ContextType>
  ChangedScopesUserManagementEmployerEvent?: ChangedScopesUserManagementEmployerEventResolvers<ContextType>
  Chat?: ChatResolvers<ContextType>
  ChatMessage?: ChatMessageResolvers<ContextType>
  CheckPayer?: CheckPayerResolvers<ContextType>
  CheckPayerResultOutput?: CheckPayerResultOutputResolvers<ContextType>
  CheckVacancyContentCorrectnessForPublishingOutput?: CheckVacancyContentCorrectnessForPublishingOutputResolvers<ContextType>
  CitiesConnection?: CitiesConnectionResolvers<ContextType>
  City?: CityResolvers<ContextType>
  CityDistrict?: CityDistrictResolvers<ContextType>
  CityEdge?: CityEdgeResolvers<ContextType>
  CloseBannerError?: CloseBannerErrorResolvers<ContextType>
  CloseBannerResponse?: CloseBannerResponseResolvers<ContextType>
  ClosedAdditionalService?: ClosedAdditionalServiceResolvers<ContextType>
  ClosedCvDbService?: ClosedCvDbServiceResolvers<ContextType>
  ClosedHotService?: ClosedHotServiceResolvers<ContextType>
  ClosedLogoInPortalService?: ClosedLogoInPortalServiceResolvers<ContextType>
  ClosedLogoInRubricService?: ClosedLogoInRubricServiceResolvers<ContextType>
  ClosedMarketingService?: ClosedMarketingServiceResolvers<ContextType>
  ClosedRisingUpService?: ClosedRisingUpServiceResolvers<ContextType>
  ClosedVacancyEmployerEvent?: ClosedVacancyEmployerEventResolvers<ContextType>
  ClosedVacancyPackageService?: ClosedVacancyPackageServiceResolvers<ContextType>
  ClosedVacancyPublicationService?: ClosedVacancyPublicationServiceResolvers<ContextType>
  ClubMutations?: ClubMutationsResolvers<ContextType>
  ClubPost?: ClubPostResolvers<ContextType>
  ClubQueries?: ClubQueriesResolvers<ContextType>
  ClubTag?: ClubTagResolvers<ContextType>
  ClubTopic?: ClubTopicResolvers<ContextType>
  ClubUserProfile?: ClubUserProfileResolvers<ContextType>
  Company?: CompanyResolvers<ContextType>
  CompanyApplyAutoReplyTemplateConfigurationUpdateOutput?: CompanyApplyAutoReplyTemplateConfigurationUpdateOutputResolvers<ContextType>
  CompanyAssignmentOutput?: CompanyAssignmentOutputResolvers<ContextType>
  CompanyAssignmentsMutations?: CompanyAssignmentsMutationsResolvers<ContextType>
  CompanyBenefit?: CompanyBenefitResolvers<ContextType>
  CompanyBlockingReasons?: CompanyBlockingReasonsResolvers<ContextType>
  CompanyBufferEvent?: CompanyBufferEventResolvers<ContextType>
  CompanyCard?: CompanyCardResolvers<ContextType>
  CompanyCardAction?: CompanyCardActionResolvers<ContextType>
  CompanyCardConnection?: CompanyCardConnectionResolvers<ContextType>
  CompanyCardEdge?: CompanyCardEdgeResolvers<ContextType>
  CompanyCardPayment?: CompanyCardPaymentResolvers<ContextType>
  CompanyCardTicket?: CompanyCardTicketResolvers<ContextType>
  CompanyEditOutput?: CompanyEditOutputResolvers<ContextType>
  CompanyEmailResendConfirmationOutput?: CompanyEmailResendConfirmationOutputResolvers<ContextType>
  CompanyEventOutput?: CompanyEventOutputResolvers<ContextType>
  CompanyFreeServiceAddedEvent?: CompanyFreeServiceAddedEventResolvers<ContextType>
  CompanyHonors?: CompanyHonorsResolvers<ContextType>
  CompanyHonorsBadge?: CompanyHonorsBadgeResolvers<ContextType>
  CompanyJoinRequest?: CompanyJoinRequestResolvers<ContextType>
  CompanyMiniProfile?: CompanyMiniProfileResolvers<ContextType>
  CompanyNotificationSnapshot?: CompanyNotificationSnapshotResolvers<ContextType>
  CompanyPublishedVacanciesFilter?: CompanyPublishedVacanciesFilterResolvers<ContextType>
  CompanyPublishedVacanciesQueryFilter?: CompanyPublishedVacanciesQueryFilterResolvers<ContextType>
  CompanyReason?: CompanyReasonResolvers<ContextType>
  CompanyRegisteredEvent?: CompanyRegisteredEventResolvers<ContextType>
  CompanySavedCardAddedEvent?: CompanySavedCardAddedEventResolvers<ContextType>
  CompanySavedCardRemovedEvent?: CompanySavedCardRemovedEventResolvers<ContextType>
  CompanySendJoinRequestOutput?: CompanySendJoinRequestOutputResolvers<ContextType>
  CompanySocialMedia?: CompanySocialMediaResolvers<ContextType>
  CompanyStaffSize?: CompanyStaffSizeResolvers<ContextType>
  CompanyStateChangedDetails?: CompanyStateChangedDetailsResolvers<ContextType>
  CompanyStateWrapper?: CompanyStateWrapperResolvers<ContextType>
  CompanyTurbotaStatus?: CompanyTurbotaStatusResolvers<ContextType>
  CompanyVerificationOutput?: CompanyVerificationOutputResolvers<ContextType>
  CompanyWasMergedError?: CompanyWasMergedErrorResolvers<ContextType>
  CompanyWorkRegisteredEvent?: CompanyWorkRegisteredEventResolvers<ContextType>
  ConfirmEmailCodeOutput?: ConfirmEmailCodeOutputResolvers<ContextType>
  ConfirmProfileDeletionOutput?: ConfirmProfileDeletionOutputResolvers<ContextType>
  ConfirmedPhoneUsersOutput?: ConfirmedPhoneUsersOutputResolvers<ContextType>
  Contract?: ContractResolvers<ContextType>
  ContractCreationOutput?: ContractCreationOutputResolvers<ContextType>
  CountStatistics?: CountStatisticsResolvers<ContextType>
  CreateEmptyProfResumeError?: CreateEmptyProfResumeErrorResolvers<ContextType>
  CreateGroupNotificationOutput?: CreateGroupNotificationOutputResolvers<ContextType>
  CreateInvoiceOutput?: CreateInvoiceOutputResolvers<ContextType>
  CreateOrderFromCartResponse?: CreateOrderFromCartResponseResolvers<ContextType>
  CreateOrderFromServicesOutput?: CreateOrderFromServicesOutputResolvers<ContextType>
  CreateProfResumeAsCopyError?: CreateProfResumeAsCopyErrorResolvers<ContextType>
  CreateProfResumeError?: CreateProfResumeErrorResolvers<ContextType>
  CreateProfResumeFromAttachError?: CreateProfResumeFromAttachErrorResolvers<ContextType>
  CreateProfResumeFromAttachOutput?: CreateProfResumeFromAttachOutputResolvers<ContextType>
  CreateRecruitmentOrderError?: CreateRecruitmentOrderErrorResolvers<ContextType>
  CreateRecruitmentOrderOutput?: CreateRecruitmentOrderOutputResolvers<ContextType>
  CreateResumeDraftCreatedNotificationOutput?: CreateResumeDraftCreatedNotificationOutputResolvers<ContextType>
  CreateResumePublishedNotificationOutput?: CreateResumePublishedNotificationOutputResolvers<ContextType>
  CreateSeekerProfileResponse?: CreateSeekerProfileResponseResolvers<ContextType>
  CreateSharingDocumentsDeepLinkOutput?: CreateSharingDocumentsDeepLinkOutputResolvers<ContextType>
  CreateTargetedMailingOutput?: CreateTargetedMailingOutputResolvers<ContextType>
  CreateUserNotificationOutput?: CreateUserNotificationOutputResolvers<ContextType>
  CreateWalletError?: CreateWalletErrorResolvers<ContextType>
  CreateWalletOutput?: CreateWalletOutputResolvers<ContextType>
  CreateWalletTransactionError?: CreateWalletTransactionErrorResolvers<ContextType>
  CreateWalletTransactionOutput?: CreateWalletTransactionOutputResolvers<ContextType>
  CreatedAsCopyProfResumeOutput?: CreatedAsCopyProfResumeOutputResolvers<ContextType>
  CreatedDraftVacancyEmployerEvent?: CreatedDraftVacancyEmployerEventResolvers<ContextType>
  CreatedEmptyProfResumeOutput?: CreatedEmptyProfResumeOutputResolvers<ContextType>
  CreatedOrderServiceEmployerEvent?: CreatedOrderServiceEmployerEventResolvers<ContextType>
  CreatedProfResumeOutput?: CreatedProfResumeOutputResolvers<ContextType>
  CrmAccount?: CrmAccountResolvers<ContextType>
  CrmAccountConnection?: CrmAccountConnectionResolvers<ContextType>
  CrmAccountEdge?: CrmAccountEdgeResolvers<ContextType>
  CrmAction?: CrmActionResolvers<ContextType>
  CrmActionAddResult?: CrmActionAddResultResolvers<ContextType>
  CrmActionBrief?: CrmActionBriefResolvers<ContextType>
  CrmActionConnection?: CrmActionConnectionResolvers<ContextType>
  CrmActionEdge?: CrmActionEdgeResolvers<ContextType>
  CrmActionType?: CrmActionTypeResolvers<ContextType>
  CrmCall?: CrmCallResolvers<ContextType>
  CrmCallConnection?: CrmCallConnectionResolvers<ContextType>
  CrmCallEdge?: CrmCallEdgeResolvers<ContextType>
  CrmCompany?: CrmCompanyResolvers<ContextType>
  CrmCompanyConnection?: CrmCompanyConnectionResolvers<ContextType>
  CrmCompanyEdge?: CrmCompanyEdgeResolvers<ContextType>
  CrmCompletionAct?: CrmCompletionActResolvers<ContextType>
  CrmContact?: CrmContactResolvers<ContextType>
  CrmEditAction?: CrmEditActionResolvers<ContextType>
  CrmEmployee?: CrmEmployeeResolvers<ContextType>
  CrmManager?: CrmManagerResolvers<ContextType>
  CrmMarketingService?: CrmMarketingServiceResolvers<ContextType>
  CrmMarketingServiceConnection?: CrmMarketingServiceConnectionResolvers<ContextType>
  CrmMarketingServiceEdge?: CrmMarketingServiceEdgeResolvers<ContextType>
  CrmMarketingServiceFilter?: CrmMarketingServiceFilterResolvers<ContextType>
  CrmMeetingReason?: CrmMeetingReasonResolvers<ContextType>
  CrmMetaCompany?: CrmMetaCompanyResolvers<ContextType>
  CrmMultiUser?: CrmMultiUserResolvers<ContextType>
  CrmPhone?: CrmPhoneResolvers<ContextType>
  CrmPlannedActionByDay?: CrmPlannedActionByDayResolvers<ContextType>
  CrmQuarter?: CrmQuarterResolvers<ContextType>
  CrmUserCard?: CrmUserCardResolvers<ContextType>
  CrmUserCardEdit?: CrmUserCardEditResolvers<ContextType>
  CrmUserCardRole?: CrmUserCardRoleResolvers<ContextType>
  CustomNotificationDetails?: CustomNotificationDetailsResolvers<ContextType>
  CustomNotificationElement?: CustomNotificationElementResolvers<ContextType>
  CustomNotificationElementButton?: CustomNotificationElementButtonResolvers<ContextType>
  CustomNotificationElementText?: CustomNotificationElementTextResolvers<ContextType>
  CvDbCatalogService?: CvDbCatalogServiceResolvers<ContextType>
  CvDbServiceEmployerEventSnapshot?: CvDbServiceEmployerEventSnapshotResolvers<ContextType>
  DailyResumeViewsReportCalculated?: DailyResumeViewsReportCalculatedResolvers<ContextType>
  DashboardCompany?: DashboardCompanyResolvers<ContextType>
  DashboardCompanyConnection?: DashboardCompanyConnectionResolvers<ContextType>
  DashboardCompanyEdge?: DashboardCompanyEdgeResolvers<ContextType>
  DashboardMultiUserResponse?: DashboardMultiUserResponseResolvers<ContextType>
  DashboardRatingResponse?: DashboardRatingResponseResolvers<ContextType>
  DashboardResponse?: DashboardResponseResolvers<ContextType>
  Date?: GraphQLScalarType
  DateCounter?: DateCounterResolvers<ContextType>
  DateInterval?: DateIntervalResolvers<ContextType>
  DateTime?: GraphQLScalarType
  DateTimeOffset?: GraphQLScalarType
  DealCard?: DealCardResolvers<ContextType>
  DealCardAccount?: DealCardAccountResolvers<ContextType>
  DealCardPayment?: DealCardPaymentResolvers<ContextType>
  DealDetailsOutput?: DealDetailsOutputResolvers<ContextType>
  DealForecast?: DealForecastResolvers<ContextType>
  DealOrderCard?: DealOrderCardResolvers<ContextType>
  DealTableOutput?: DealTableOutputResolvers<ContextType>
  Decimal?: GraphQLScalarType
  DeleteAllNotificationsError?: DeleteAllNotificationsErrorResolvers<ContextType>
  DeleteAllNotificationsOutput?: DeleteAllNotificationsOutputResolvers<ContextType>
  DeleteNotificationsError?: DeleteNotificationsErrorResolvers<ContextType>
  DeleteNotificationsOutput?: DeleteNotificationsOutputResolvers<ContextType>
  DeletePostFromHotOutput?: DeletePostFromHotOutputResolvers<ContextType>
  DeletePostFromTopOutput?: DeletePostFromTopOutputResolvers<ContextType>
  DeleteProfResumePortfolioOutput?: DeleteProfResumePortfolioOutputResolvers<ContextType>
  DeleteProfResumeSocialNetworksOutput?: DeleteProfResumeSocialNetworksOutputResolvers<ContextType>
  DeleteProfileOutput?: DeleteProfileOutputResolvers<ContextType>
  DeleteProfileWithEmailOutput?: DeleteProfileWithEmailOutputResolvers<ContextType>
  DeleteProfileWithSmsOutput?: DeleteProfileWithSmsOutputResolvers<ContextType>
  DeleteSeekerProfResumeAdditionalEducationsError?: DeleteSeekerProfResumeAdditionalEducationsErrorResolvers<ContextType>
  DeleteSeekerProfResumeAdditionalInfoError?: DeleteSeekerProfResumeAdditionalInfoErrorResolvers<ContextType>
  DeleteSeekerProfResumeAdditionalPhonesError?: DeleteSeekerProfResumeAdditionalPhonesErrorResolvers<ContextType>
  DeleteSeekerProfResumeAdditionalPhonesOutput?: DeleteSeekerProfResumeAdditionalPhonesOutputResolvers<ContextType>
  DeleteSeekerProfResumeDiiaCertificateError?: DeleteSeekerProfResumeDiiaCertificateErrorResolvers<ContextType>
  DeleteSeekerProfResumeEducationsError?: DeleteSeekerProfResumeEducationsErrorResolvers<ContextType>
  DeleteSeekerProfResumeError?: DeleteSeekerProfResumeErrorResolvers<ContextType>
  DeleteSeekerProfResumeExperiencesError?: DeleteSeekerProfResumeExperiencesErrorResolvers<ContextType>
  DeleteSeekerProfResumeLanguageSkillsError?: DeleteSeekerProfResumeLanguageSkillsErrorResolvers<ContextType>
  DeleteSeekerProfResumePortfoliosError?: DeleteSeekerProfResumePortfoliosErrorResolvers<ContextType>
  DeleteSeekerProfResumeSocialNetworksError?: DeleteSeekerProfResumeSocialNetworksErrorResolvers<ContextType>
  DeleteTargetedMailingOutput?: DeleteTargetedMailingOutputResolvers<ContextType>
  DeleteVacancyOutput?: DeleteVacancyOutputResolvers<ContextType>
  DeletedProfResumeAdditionalEducationsOutput?: DeletedProfResumeAdditionalEducationsOutputResolvers<ContextType>
  DeletedProfResumeAdditionalInfoOutput?: DeletedProfResumeAdditionalInfoOutputResolvers<ContextType>
  DeletedProfResumeEducationsOutput?: DeletedProfResumeEducationsOutputResolvers<ContextType>
  DeletedProfResumeExperiencesOutput?: DeletedProfResumeExperiencesOutputResolvers<ContextType>
  DeletedProfResumeLanguageSkillsOutput?: DeletedProfResumeLanguageSkillsOutputResolvers<ContextType>
  DeletedProfResumeOutput?: DeletedProfResumeOutputResolvers<ContextType>
  DeletedUserManagementEmployerEvent?: DeletedUserManagementEmployerEventResolvers<ContextType>
  DeletedVacancyEmployerEvent?: DeletedVacancyEmployerEventResolvers<ContextType>
  DirectoryItem?: DirectoryItemResolvers<ContextType>
  Discount?: DiscountResolvers<ContextType>
  DislikeSeekerVacanciesOutput?: DislikeSeekerVacanciesOutputResolvers<ContextType>
  DistrictFacet?: DistrictFacetResolvers<ContextType>
  DownloadActOutput?: DownloadActOutputResolvers<ContextType>
  DownloadInvoiceOutput?: DownloadInvoiceOutputResolvers<ContextType>
  DropAppliedWalletBonusResponse?: DropAppliedWalletBonusResponseResolvers<ContextType>
  DropSelectedPromoCodeResponse?: DropSelectedPromoCodeResponseResolvers<ContextType>
  DuplicateCompany?: DuplicateCompanyResolvers<ContextType>
  EditedVacancyCityEmployerEvent?: EditedVacancyCityEmployerEventResolvers<ContextType>
  EditedVacancyEmployerEvent?: EditedVacancyEmployerEventResolvers<ContextType>
  EditedVacancyFieldEmployerEvent?: EditedVacancyFieldEmployerEventResolvers<ContextType>
  EditedVacancyFieldEmployerEventUnion?: EditedVacancyFieldEmployerEventUnionResolvers<ContextType>
  EditedVacancyTitleEmployerEvent?: EditedVacancyTitleEmployerEventResolvers<ContextType>
  EducationLevel?: EducationLevelResolvers<ContextType>
  EmailAlreadyExistsError?: EmailAlreadyExistsErrorResolvers<ContextType>
  EmailSenderResult?: EmailSenderResultResolvers<ContextType>
  EmailStatistics?: EmailStatisticsResolvers<ContextType>
  Employee?: EmployeeResolvers<ContextType>
  EmployeeAtsRelation?: EmployeeAtsRelationResolvers<ContextType>
  EmployeeChangeEmailOutput?: EmployeeChangeEmailOutputResolvers<ContextType>
  EmployeeChangeOutput?: EmployeeChangeOutputResolvers<ContextType>
  EmployeeConnection?: EmployeeConnectionResolvers<ContextType>
  EmployeeCreateError?: EmployeeCreateErrorResolvers<ContextType>
  EmployeeCreateOutput?: EmployeeCreateOutputResolvers<ContextType>
  EmployeeCvDbSavedSearchDeleteOutput?: EmployeeCvDbSavedSearchDeleteOutputResolvers<ContextType>
  EmployeeEdge?: EmployeeEdgeResolvers<ContextType>
  EmployeeEditError?: EmployeeEditErrorResolvers<ContextType>
  EmployeeEditOutput?: EmployeeEditOutputResolvers<ContextType>
  EmployeeHasScopesOutput?: EmployeeHasScopesOutputResolvers<ContextType>
  EmployeeJoiningRequestAlreadyReviewedError?: EmployeeJoiningRequestAlreadyReviewedErrorResolvers<ContextType>
  EmployeeJoiningReviewErrorUnion?: EmployeeJoiningReviewErrorUnionResolvers<ContextType>
  EmployeeJoiningReviewOutput?: EmployeeJoiningReviewOutputResolvers<ContextType>
  EmployeeLimitCounter?: EmployeeLimitCounterResolvers<ContextType>
  EmployeeMakeMainOutput?: EmployeeMakeMainOutputResolvers<ContextType>
  EmployeeResendInvitationOutput?: EmployeeResendInvitationOutputResolvers<ContextType>
  EmployeeRoleBasedScope?: EmployeeRoleBasedScopeResolvers<ContextType>
  EmployeeScopeChangeUserManagementEmployerEvent?: EmployeeScopeChangeUserManagementEmployerEventResolvers<ContextType>
  EmployeeStatusChanged?: EmployeeStatusChangedResolvers<ContextType>
  EmployeeSubscription?: EmployeeSubscriptionResolvers<ContextType>
  EmployeeSubscriptionAnswerTemplate?: EmployeeSubscriptionAnswerTemplateResolvers<ContextType>
  EmployeeSubscriptionAnswerTemplateOutput?: EmployeeSubscriptionAnswerTemplateOutputResolvers<ContextType>
  EmployeeSubscriptionIntRange?: EmployeeSubscriptionIntRangeResolvers<ContextType>
  EmployeeSubscriptionSavedCvDbSearchRequest?: EmployeeSubscriptionSavedCvDbSearchRequestResolvers<ContextType>
  EmployeeSubscriptionSavedCvDbSearchSubOption?: EmployeeSubscriptionSavedCvDbSearchSubOptionResolvers<ContextType>
  EmployeeSubscriptionSubOptionUnion?: EmployeeSubscriptionSubOptionUnionResolvers<ContextType>
  EmployeeSubscriptionType?: EmployeeSubscriptionTypeResolvers<ContextType>
  EmployeeSubscriptionsMutations?: EmployeeSubscriptionsMutationsResolvers<ContextType>
  EmployeeSubscriptionsQueries?: EmployeeSubscriptionsQueriesResolvers<ContextType>
  EmployeeSubscriptionsUpdateOutput?: EmployeeSubscriptionsUpdateOutputResolvers<ContextType>
  EmployeeSummaryServiceLimit?: EmployeeSummaryServiceLimitResolvers<ContextType>
  EmployeesBlockOutput?: EmployeesBlockOutputResolvers<ContextType>
  EmployeesDeleteOutput?: EmployeesDeleteOutputResolvers<ContextType>
  EmployeesMultiScopesUpdateOutput?: EmployeesMultiScopesUpdateOutputResolvers<ContextType>
  EmployeesUnblockOutput?: EmployeesUnblockOutputResolvers<ContextType>
  EmployerAchievement?: EmployerAchievementResolvers<ContextType>
  EmployerEventLogConnection?: EmployerEventLogConnectionResolvers<ContextType>
  EmployerEventPerformer?: EmployerEventPerformerResolvers<ContextType>
  EmployerEventSnapshot?: EmployerEventSnapshotResolvers<ContextType>
  EmployerEventUnion?: EmployerEventUnionResolvers<ContextType>
  EmployerOnBoardingCheckListOutput?: EmployerOnBoardingCheckListOutputResolvers<ContextType>
  EmployerRegistration?: EmployerRegistrationResolvers<ContextType>
  EmployerRegistrationResponse?: EmployerRegistrationResponseResolvers<ContextType>
  EmployerWalletUser?: EmployerWalletUserResolvers<ContextType>
  EmployersEventUnionEdge?: EmployersEventUnionEdgeResolvers<ContextType>
  EndedHotPeriodVacancyEmployerEvent?: EndedHotPeriodVacancyEmployerEventResolvers<ContextType>
  EndedServiceEmployerEvent?: EndedServiceEmployerEventResolvers<ContextType>
  Error?: ErrorResolvers<ContextType>
  EventInterface?: EventInterfaceResolvers<ContextType>
  Experience?: ExperienceResolvers<ContextType>
  ExperienceBasicQuestion?: ExperienceBasicQuestionResolvers<ContextType>
  ExternalCourse?: ExternalCourseResolvers<ContextType>
  ExternalUrlPaymentMethod?: ExternalUrlPaymentMethodResolvers<ContextType>
  Facet?: FacetResolvers<ContextType>
  FavoriteVacanciesRemindedDetails?: FavoriteVacanciesRemindedDetailsResolvers<ContextType>
  FeedbackMutations?: FeedbackMutationsResolvers<ContextType>
  FeedbackOutput?: FeedbackOutputResolvers<ContextType>
  FeedbackQueries?: FeedbackQueriesResolvers<ContextType>
  FileAttach?: FileAttachResolvers<ContextType>
  ForbiddenToMutateUsersError?: ForbiddenToMutateUsersErrorResolvers<ContextType>
  GamificationDates?: GamificationDatesResolvers<ContextType>
  GamificationWave?: GamificationWaveResolvers<ContextType>
  GamificationWaveList?: GamificationWaveListResolvers<ContextType>
  GenerateExternalPaymentUrlByInvoiceOutput?: GenerateExternalPaymentUrlByInvoiceOutputResolvers<ContextType>
  GenerateExternalPaymentUrlOutput?: GenerateExternalPaymentUrlOutputResolvers<ContextType>
  GetCurrentEmployerErrorUnion?: GetCurrentEmployerErrorUnionResolvers<ContextType>
  GetCurrentEmployerOutput?: GetCurrentEmployerOutputResolvers<ContextType>
  GetSeekerDislikedVacanciesTotalCountOutput?: GetSeekerDislikedVacanciesTotalCountOutputResolvers<ContextType>
  GetSeekerFavoriteVacanciesTotalCountOutput?: GetSeekerFavoriteVacanciesTotalCountOutputResolvers<ContextType>
  GetVacancyByIdResponse?: GetVacancyByIdResponseResolvers<ContextType>
  GetVacancyError?: GetVacancyErrorResolvers<ContextType>
  GetVacancySearchSuggestsOutput?: GetVacancySearchSuggestsOutputResolvers<ContextType>
  Gift?: GiftResolvers<ContextType>
  GiftCompanyWaveLimit?: GiftCompanyWaveLimitResolvers<ContextType>
  GiftDeliverySlot?: GiftDeliverySlotResolvers<ContextType>
  GiftLimit?: GiftLimitResolvers<ContextType>
  GiftOccasion?: GiftOccasionResolvers<ContextType>
  GiftRequest?: GiftRequestResolvers<ContextType>
  GiftRequestConnection?: GiftRequestConnectionResolvers<ContextType>
  GiftRequestDeliveryOptions?: GiftRequestDeliveryOptionsResolvers<ContextType>
  GiftRequestDetail?: GiftRequestDetailResolvers<ContextType>
  GiftRequestEdge?: GiftRequestEdgeResolvers<ContextType>
  GiftRequestOccasion?: GiftRequestOccasionResolvers<ContextType>
  GiftTeamLimit?: GiftTeamLimitResolvers<ContextType>
  GiftTeamLimitByManager?: GiftTeamLimitByManagerResolvers<ContextType>
  GiftTeamLimitByManagerConnection?: GiftTeamLimitByManagerConnectionResolvers<ContextType>
  GiftTeamLimitByManagerDetail?: GiftTeamLimitByManagerDetailResolvers<ContextType>
  GiftTeamLimitByManagerEdge?: GiftTeamLimitByManagerEdgeResolvers<ContextType>
  GiftTeamLimitTransferHistory?: GiftTeamLimitTransferHistoryResolvers<ContextType>
  GiftTeamLimitTransferHistoryConnection?: GiftTeamLimitTransferHistoryConnectionResolvers<ContextType>
  GiftTeamLimitTransferHistoryEdge?: GiftTeamLimitTransferHistoryEdgeResolvers<ContextType>
  GiftWaveLimit?: GiftWaveLimitResolvers<ContextType>
  GiftsFilters?: GiftsFiltersResolvers<ContextType>
  GiftsMutations?: GiftsMutationsResolvers<ContextType>
  GroupTag?: GroupTagResolvers<ContextType>
  Guid?: GraphQLScalarType
  HasNoAccessToRequestedFieldsError?: HasNoAccessToRequestedFieldsErrorResolvers<ContextType>
  HasNoScopesForActionError?: HasNoScopesForActionErrorResolvers<ContextType>
  HelperCompany?: HelperCompanyResolvers<ContextType>
  HelperCompanyConnection?: HelperCompanyConnectionResolvers<ContextType>
  HelperCompanyDetail?: HelperCompanyDetailResolvers<ContextType>
  HelperCompanyEdge?: HelperCompanyEdgeResolvers<ContextType>
  HotCatalogService?: HotCatalogServiceResolvers<ContextType>
  HotServiceEmployerEventSnapshot?: HotServiceEmployerEventSnapshotResolvers<ContextType>
  InformationBanner?: InformationBannerResolvers<ContextType>
  IntRange?: IntRangeResolvers<ContextType>
  InvalidError?: InvalidErrorResolvers<ContextType>
  Invoice?: InvoiceResolvers<ContextType>
  InvoiceAct?: InvoiceActResolvers<ContextType>
  InvoiceExpiredEvent?: InvoiceExpiredEventResolvers<ContextType>
  InvoicePaidEvent?: InvoicePaidEventResolvers<ContextType>
  InvoicePaymentBankEvent?: InvoicePaymentBankEventResolvers<ContextType>
  InvoiceRequestedEvent?: InvoiceRequestedEventResolvers<ContextType>
  JiraServiceDeskOrganisation?: JiraServiceDeskOrganisationResolvers<ContextType>
  JobInstantReceivedNotificationDetails?: JobInstantReceivedNotificationDetailsResolvers<ContextType>
  JobRecommendationReceivedDetails?: JobRecommendationReceivedDetailsResolvers<ContextType>
  Keyword?: KeywordResolvers<ContextType>
  Lang?: LangResolvers<ContextType>
  LangSuggest?: LangSuggestResolvers<ContextType>
  LanguageBasicQuestion?: LanguageBasicQuestionResolvers<ContextType>
  LanguageSkillLevel?: LanguageSkillLevelResolvers<ContextType>
  LetterFilters?: LetterFiltersResolvers<ContextType>
  LimitsChangeRequestedDetails?: LimitsChangeRequestedDetailsResolvers<ContextType>
  LimitsFilters?: LimitsFiltersResolvers<ContextType>
  ListSeekerDislikedVacanciesOutput?: ListSeekerDislikedVacanciesOutputResolvers<ContextType>
  ListSeekerFavoriteVacanciesOutput?: ListSeekerFavoriteVacanciesOutputResolvers<ContextType>
  LoginMutations?: LoginMutationsResolvers<ContextType>
  LoginWithOtpMutations?: LoginWithOtpMutationsResolvers<ContextType>
  LogoInPortalCatalogService?: LogoInPortalCatalogServiceResolvers<ContextType>
  LogoInPortalServiceEmployerEventSnapshot?: LogoInPortalServiceEmployerEventSnapshotResolvers<ContextType>
  LogoInRubricCatalogService?: LogoInRubricCatalogServiceResolvers<ContextType>
  LogoInRubricServiceEmployerEventSnapshot?: LogoInRubricServiceEmployerEventSnapshotResolvers<ContextType>
  LogoOnMainPageCatalogService?: LogoOnMainPageCatalogServiceResolvers<ContextType>
  LogoOnMainPageServiceEmployerEventSnapshot?: LogoOnMainPageServiceEmployerEventSnapshotResolvers<ContextType>
  LogoPortal?: LogoPortalResolvers<ContextType>
  LogoPortalBranch?: LogoPortalBranchResolvers<ContextType>
  Long?: GraphQLScalarType
  MadeHotVacancyEmployerEvent?: MadeHotVacancyEmployerEventResolvers<ContextType>
  MailingStatistics?: MailingStatisticsResolvers<ContextType>
  Manager?: ManagerResolvers<ContextType>
  ManagerContacts?: ManagerContactsResolvers<ContextType>
  ManagerDeals?: ManagerDealsResolvers<ContextType>
  ManagerDepartment?: ManagerDepartmentResolvers<ContextType>
  ManagerDiscount?: ManagerDiscountResolvers<ContextType>
  ManagerEventPerformer?: ManagerEventPerformerResolvers<ContextType>
  ManagerGiftLimit?: ManagerGiftLimitResolvers<ContextType>
  ManagerWalletUser?: ManagerWalletUserResolvers<ContextType>
  MarketingCatalogService?: MarketingCatalogServiceResolvers<ContextType>
  MarketingGift?: MarketingGiftResolvers<ContextType>
  MarketingGiftRequest?: MarketingGiftRequestResolvers<ContextType>
  MarketingGiftRequestConnection?: MarketingGiftRequestConnectionResolvers<ContextType>
  MarketingGiftRequestDetail?: MarketingGiftRequestDetailResolvers<ContextType>
  MarketingGiftRequestEdge?: MarketingGiftRequestEdgeResolvers<ContextType>
  MarketingLimitTransfer?: MarketingLimitTransferResolvers<ContextType>
  MarketingLimitTransferConnection?: MarketingLimitTransferConnectionResolvers<ContextType>
  MarketingLimitTransferDetail?: MarketingLimitTransferDetailResolvers<ContextType>
  MarketingLimitTransferEdge?: MarketingLimitTransferEdgeResolvers<ContextType>
  MarketingServiceEmployerEventSnapshot?: MarketingServiceEmployerEventSnapshotResolvers<ContextType>
  MassRefuseOutput?: MassRefuseOutputResolvers<ContextType>
  MessagesConnection?: MessagesConnectionResolvers<ContextType>
  MessagesEdge?: MessagesEdgeResolvers<ContextType>
  MessengerContact?: MessengerContactResolvers<ContextType>
  MetanotebookAssignedEvent?: MetanotebookAssignedEventResolvers<ContextType>
  Metro?: MetroResolvers<ContextType>
  MetroFacet?: MetroFacetResolvers<ContextType>
  MetroLine?: MetroLineResolvers<ContextType>
  MetroLineFacet?: MetroLineFacetResolvers<ContextType>
  MobilePushStatistics?: MobilePushStatisticsResolvers<ContextType>
  ModifyVacancyErrorUnion?: ModifyVacancyErrorUnionResolvers<ContextType>
  MovingGiftLimitsOutput?: MovingGiftLimitsOutputResolvers<ContextType>
  Mutation?: MutationResolvers<ContextType>
  MyServicesConnection?: MyServicesConnectionResolvers<ContextType>
  MyVacanciesConnection?: MyVacanciesConnectionResolvers<ContextType>
  NoAvailableLimitsError?: NoAvailableLimitsErrorResolvers<ContextType>
  NotAvailableError?: NotAvailableErrorResolvers<ContextType>
  NotFoundError?: NotFoundErrorResolvers<ContextType>
  NotFoundProfResume?: NotFoundProfResumeResolvers<ContextType>
  Notification?: NotificationResolvers<ContextType>
  NotificationCommonVacancySnapshot?: NotificationCommonVacancySnapshotResolvers<ContextType>
  NotificationCommonVacancySnapshotInterface?: NotificationCommonVacancySnapshotInterfaceResolvers<ContextType>
  NotificationDetails?: NotificationDetailsResolvers<ContextType>
  NotificationEmployeeInitiator?: NotificationEmployeeInitiatorResolvers<ContextType>
  NotificationEmployeeSnapshot?: NotificationEmployeeSnapshotResolvers<ContextType>
  NotificationEndingDaysPublishPeriodSnapshot?: NotificationEndingDaysPublishPeriodSnapshotResolvers<ContextType>
  NotificationEndingDaysVacancySnapshot?: NotificationEndingDaysVacancySnapshotResolvers<ContextType>
  NotificationInitiator?: NotificationInitiatorResolvers<ContextType>
  NotificationInitiatorInterface?: NotificationInitiatorInterfaceResolvers<ContextType>
  NotificationManagerInitiator?: NotificationManagerInitiatorResolvers<ContextType>
  NotificationNotFoundError?: NotificationNotFoundErrorResolvers<ContextType>
  NotificationOrderSnapshot?: NotificationOrderSnapshotResolvers<ContextType>
  NotificationOwnerChangedVacancySnapshot?: NotificationOwnerChangedVacancySnapshotResolvers<ContextType>
  NotificationVacancyEndingCountSnapshot?: NotificationVacancyEndingCountSnapshotResolvers<ContextType>
  NotificationsConnection?: NotificationsConnectionResolvers<ContextType>
  NotificationsEdge?: NotificationsEdgeResolvers<ContextType>
  NotificationsError?: NotificationsErrorResolvers<ContextType>
  NovaPoshtaAddCounterpartyOutput?: NovaPoshtaAddCounterpartyOutputResolvers<ContextType>
  NovaPoshtaAddDocumentOutput?: NovaPoshtaAddDocumentOutputResolvers<ContextType>
  NovaPoshtaAddDocumentToRegisterOutput?: NovaPoshtaAddDocumentToRegisterOutputResolvers<ContextType>
  NovaPoshtaAddGiftAddressOutput?: NovaPoshtaAddGiftAddressOutputResolvers<ContextType>
  NovaPoshtaCity?: NovaPoshtaCityResolvers<ContextType>
  NovaPoshtaCounterpartyGraphType?: NovaPoshtaCounterpartyGraphTypeResolvers<ContextType>
  NovaPoshtaDeleteDocumentOutput?: NovaPoshtaDeleteDocumentOutputResolvers<ContextType>
  NovaPoshtaDocument?: NovaPoshtaDocumentResolvers<ContextType>
  NovaPoshtaDoorsAddressGraphType?: NovaPoshtaDoorsAddressGraphTypeResolvers<ContextType>
  NovaPoshtaFullAddressGraphType?: NovaPoshtaFullAddressGraphTypeResolvers<ContextType>
  NovaPoshtaNewAddedDocument?: NovaPoshtaNewAddedDocumentResolvers<ContextType>
  NovaPoshtaOptionSeat?: NovaPoshtaOptionSeatResolvers<ContextType>
  NovaPoshtaRegisterDocument?: NovaPoshtaRegisterDocumentResolvers<ContextType>
  NovaPoshtaRegisterList?: NovaPoshtaRegisterListResolvers<ContextType>
  NovaPoshtaRegisterListConnection?: NovaPoshtaRegisterListConnectionResolvers<ContextType>
  NovaPoshtaRegisterListEdge?: NovaPoshtaRegisterListEdgeResolvers<ContextType>
  NovaPoshtaStreet?: NovaPoshtaStreetResolvers<ContextType>
  NovaPoshtaWarehouse?: NovaPoshtaWarehouseResolvers<ContextType>
  NovaPoshtaWarehouseAddressGraphType?: NovaPoshtaWarehouseAddressGraphTypeResolvers<ContextType>
  Objection?: ObjectionResolvers<ContextType>
  OfferVacancyToApplyOutput?: OfferVacancyToApplyOutputResolvers<ContextType>
  OfferedVacancyCvDbEmployerEvent?: OfferedVacancyCvDbEmployerEventResolvers<ContextType>
  OpenedContactOrVacancyOfferedEmployerAchievement?: OpenedContactOrVacancyOfferedEmployerAchievementResolvers<ContextType>
  OpenedContactsCvDbEmployerEvent?: OpenedContactsCvDbEmployerEventResolvers<ContextType>
  Order?: OrderResolvers<ContextType>
  OrderActivatedByTurbotaDetails?: OrderActivatedByTurbotaDetailsResolvers<ContextType>
  OrderAlreadyActivatedError?: OrderAlreadyActivatedErrorResolvers<ContextType>
  OrderCanNotBeActivatedByTurbotaError?: OrderCanNotBeActivatedByTurbotaErrorResolvers<ContextType>
  OrderCartMutation?: OrderCartMutationResolvers<ContextType>
  OrderConnection?: OrderConnectionResolvers<ContextType>
  OrderEdge?: OrderEdgeResolvers<ContextType>
  OrderFreemiumServicesAccruedServiceEmployerEvent?: OrderFreemiumServicesAccruedServiceEmployerEventResolvers<ContextType>
  OrderPosition?: OrderPositionResolvers<ContextType>
  OrderPositionAdditionalServiceDetails?: OrderPositionAdditionalServiceDetailsResolvers<ContextType>
  OrderPositionCvDbServiceDetails?: OrderPositionCvDbServiceDetailsResolvers<ContextType>
  OrderPositionHotServiceDetails?: OrderPositionHotServiceDetailsResolvers<ContextType>
  OrderPositionLogoInPortalServiceDetails?: OrderPositionLogoInPortalServiceDetailsResolvers<ContextType>
  OrderPositionLogoInRubricServiceDetails?: OrderPositionLogoInRubricServiceDetailsResolvers<ContextType>
  OrderPositionLogoOnMainServiceDetails?: OrderPositionLogoOnMainServiceDetailsResolvers<ContextType>
  OrderPositionMarketingServiceDetails?: OrderPositionMarketingServiceDetailsResolvers<ContextType>
  OrderPositionRisingUpServiceDetails?: OrderPositionRisingUpServiceDetailsResolvers<ContextType>
  OrderPositionServiceDetailsUnion?: OrderPositionServiceDetailsUnionResolvers<ContextType>
  OrderPositionVacancyPackageServiceDetails?: OrderPositionVacancyPackageServiceDetailsResolvers<ContextType>
  OrderPositionVacancyPublicationServiceDetails?: OrderPositionVacancyPublicationServiceDetailsResolvers<ContextType>
  OrderServicesAccruedServiceEmployerEvent?: OrderServicesAccruedServiceEmployerEventResolvers<ContextType>
  OrderTurbotaActivationError?: OrderTurbotaActivationErrorResolvers<ContextType>
  OrderTurbotaActivationOutput?: OrderTurbotaActivationOutputResolvers<ContextType>
  OrderWasActivatedByTurbotaError?: OrderWasActivatedByTurbotaErrorResolvers<ContextType>
  OrderedAdditionalService?: OrderedAdditionalServiceResolvers<ContextType>
  OrderedCvDbService?: OrderedCvDbServiceResolvers<ContextType>
  OrderedHotService?: OrderedHotServiceResolvers<ContextType>
  OrderedLogoInPortalService?: OrderedLogoInPortalServiceResolvers<ContextType>
  OrderedLogoInRubricService?: OrderedLogoInRubricServiceResolvers<ContextType>
  OrderedMarketingService?: OrderedMarketingServiceResolvers<ContextType>
  OrderedRisingUpService?: OrderedRisingUpServiceResolvers<ContextType>
  OrderedVacancyPackageService?: OrderedVacancyPackageServiceResolvers<ContextType>
  OrderedVacancyPublicationService?: OrderedVacancyPublicationServiceResolvers<ContextType>
  OtherSiteLink?: OtherSiteLinkResolvers<ContextType>
  OwnerChangedVacancyEmployerEvent?: OwnerChangedVacancyEmployerEventResolvers<ContextType>
  PackageServiceEmployerEventSnapshot?: PackageServiceEmployerEventSnapshotResolvers<ContextType>
  PageInfo?: PageInfoResolvers<ContextType>
  PaidOrderService?: PaidOrderServiceResolvers<ContextType>
  ParsedCv?: ParsedCvResolvers<ContextType>
  ParsedCvComment?: ParsedCvCommentResolvers<ContextType>
  ParsedCvConnection?: ParsedCvConnectionResolvers<ContextType>
  ParsedCvLabel?: ParsedCvLabelResolvers<ContextType>
  ParsedCvRubric?: ParsedCvRubricResolvers<ContextType>
  PasswordConfirmationFailedError?: PasswordConfirmationFailedErrorResolvers<ContextType>
  PayWithCardErrorUnion?: PayWithCardErrorUnionResolvers<ContextType>
  PayWithCardResponse?: PayWithCardResponseResolvers<ContextType>
  PayWithExternalWalletErrorUnion?: PayWithExternalWalletErrorUnionResolvers<ContextType>
  PayWithExternalWalletResponse?: PayWithExternalWalletResponseResolvers<ContextType>
  Payer?: PayerResolvers<ContextType>
  PayerPassport?: PayerPassportResolvers<ContextType>
  PaymentMethods?: PaymentMethodsResolvers<ContextType>
  PaymentReceivedServiceEmployerEvent?: PaymentReceivedServiceEmployerEventResolvers<ContextType>
  PerformerEmployerEventUnion?: PerformerEmployerEventUnionResolvers<ContextType>
  Period?: PeriodResolvers<ContextType>
  PeriodAmount?: PeriodAmountResolvers<ContextType>
  PersonalDiscountEvent?: PersonalDiscountEventResolvers<ContextType>
  PhoneConfirmationOutput?: PhoneConfirmationOutputResolvers<ContextType>
  PhoneNumberConfirmedEmployerAchievement?: PhoneNumberConfirmedEmployerAchievementResolvers<ContextType>
  PriceQueryOutput?: PriceQueryOutputResolvers<ContextType>
  ProfResume?: ProfResumeResolvers<ContextType>
  ProfResumeCannotBePublished?: ProfResumeCannotBePublishedResolvers<ContextType>
  ProfResumeDeleteDiiaCertificateOutput?: ProfResumeDeleteDiiaCertificateOutputResolvers<ContextType>
  ProfResumeDoesNotBelongToSeeker?: ProfResumeDoesNotBelongToSeekerResolvers<ContextType>
  ProfResumeDoesNotExist?: ProfResumeDoesNotExistResolvers<ContextType>
  ProfResumeDuplicated?: ProfResumeDuplicatedResolvers<ContextType>
  ProfResumeLimit?: ProfResumeLimitResolvers<ContextType>
  ProfResumePhoneConfirmationFailed?: ProfResumePhoneConfirmationFailedResolvers<ContextType>
  ProfResumeResponseUnion?: ProfResumeResponseUnionResolvers<ContextType>
  ProfResumeUpdateStateOutput?: ProfResumeUpdateStateOutputResolvers<ContextType>
  ProfileRegisteredEvent?: ProfileRegisteredEventResolvers<ContextType>
  ProlongationRequestVacancyEmployerEvent?: ProlongationRequestVacancyEmployerEventResolvers<ContextType>
  PrometheusExternalCourse?: PrometheusExternalCourseResolvers<ContextType>
  PromoCode?: PromoCodeResolvers<ContextType>
  PromoCodeActivator?: PromoCodeActivatorResolvers<ContextType>
  PromoCodeCartMutation?: PromoCodeCartMutationResolvers<ContextType>
  PromoCodeConnection?: PromoCodeConnectionResolvers<ContextType>
  PromoCodeDiscount?: PromoCodeDiscountResolvers<ContextType>
  PromoCodeEdge?: PromoCodeEdgeResolvers<ContextType>
  PromoCodeMutation?: PromoCodeMutationResolvers<ContextType>
  PromoCodeTargetBonusSpecialServices?: PromoCodeTargetBonusSpecialServicesResolvers<ContextType>
  PromoCodeTargetSpecialService?: PromoCodeTargetSpecialServiceResolvers<ContextType>
  PromoCodeTargetSpecialServiceType?: PromoCodeTargetSpecialServiceTypeResolvers<ContextType>
  PromoCodeTargetWholeOrder?: PromoCodeTargetWholeOrderResolvers<ContextType>
  PromoCodeTragetUnion?: PromoCodeTragetUnionResolvers<ContextType>
  PromoCodeTypeWrapper?: PromoCodeTypeWrapperResolvers<ContextType>
  ProzoraClientDateTimeInterval?: ProzoraClientDateTimeIntervalResolvers<ContextType>
  ProzoraClientFilters?: ProzoraClientFiltersResolvers<ContextType>
  PublicClubPostsOutput?: PublicClubPostsOutputResolvers<ContextType>
  PublicationServiceEmployerEventSnapshot?: PublicationServiceEmployerEventSnapshotResolvers<ContextType>
  PublishSeekerProfResumeError?: PublishSeekerProfResumeErrorResolvers<ContextType>
  PublishedProfResumeOutput?: PublishedProfResumeOutputResolvers<ContextType>
  PublishedResumesCounter?: PublishedResumesCounterResolvers<ContextType>
  PublishedVacancies?: PublishedVacanciesResolvers<ContextType>
  PublishedVacanciesClientCluster?: PublishedVacanciesClientClusterResolvers<ContextType>
  PublishedVacanciesClientFilter?: PublishedVacanciesClientFilterResolvers<ContextType>
  PublishedVacanciesClientMetroBranch?: PublishedVacanciesClientMetroBranchResolvers<ContextType>
  PublishedVacanciesClientRubric?: PublishedVacanciesClientRubricResolvers<ContextType>
  PublishedVacanciesFacets?: PublishedVacanciesFacetsResolvers<ContextType>
  PublishedVacanciesLocation?: PublishedVacanciesLocationResolvers<ContextType>
  PublishedVacanciesQueryFilter?: PublishedVacanciesQueryFilterResolvers<ContextType>
  PublishedVacancyBadge?: PublishedVacancyBadgeResolvers<ContextType>
  PublishedVacancyEmployerEvent?: PublishedVacancyEmployerEventResolvers<ContextType>
  Quarter?: QuarterResolvers<ContextType>
  Query?: QueryResolvers<ContextType>
  Questionnaire?: QuestionnaireResolvers<ContextType>
  QuestionnaireAnswer?: QuestionnaireAnswerResolvers<ContextType>
  QuestionnaireAnswersSummary?: QuestionnaireAnswersSummaryResolvers<ContextType>
  QuestionnaireConnection?: QuestionnaireConnectionResolvers<ContextType>
  QuestionnaireCopyOutput?: QuestionnaireCopyOutputResolvers<ContextType>
  QuestionnaireCreateOutput?: QuestionnaireCreateOutputResolvers<ContextType>
  QuestionnaireDeleteOutput?: QuestionnaireDeleteOutputResolvers<ContextType>
  QuestionnaireEdge?: QuestionnaireEdgeResolvers<ContextType>
  QuestionnaireEssayItem?: QuestionnaireEssayItemResolvers<ContextType>
  QuestionnaireExperienceItem?: QuestionnaireExperienceItemResolvers<ContextType>
  QuestionnaireExperienceItemSuggest?: QuestionnaireExperienceItemSuggestResolvers<ContextType>
  QuestionnaireGiveAnswersOutput?: QuestionnaireGiveAnswersOutputResolvers<ContextType>
  QuestionnaireItem?: QuestionnaireItemResolvers<ContextType>
  QuestionnaireItemSuggest?: QuestionnaireItemSuggestResolvers<ContextType>
  QuestionnaireLanguageItem?: QuestionnaireLanguageItemResolvers<ContextType>
  QuestionnaireLanguageItemSuggest?: QuestionnaireLanguageItemSuggestResolvers<ContextType>
  QuestionnaireSelectItem?: QuestionnaireSelectItemResolvers<ContextType>
  QuestionnaireSelectItemSuggest?: QuestionnaireSelectItemSuggestResolvers<ContextType>
  QuestionnaireSuggest?: QuestionnaireSuggestResolvers<ContextType>
  QuestionnaireUpdateOutput?: QuestionnaireUpdateOutputResolvers<ContextType>
  RePublishedVacancyEmployerEvent?: RePublishedVacancyEmployerEventResolvers<ContextType>
  RecommendedCandidate?: RecommendedCandidateResolvers<ContextType>
  RecommendedCandidatesConnection?: RecommendedCandidatesConnectionResolvers<ContextType>
  RecommendedCandidatesEdge?: RecommendedCandidatesEdgeResolvers<ContextType>
  RecommendedProfResume?: RecommendedProfResumeResolvers<ContextType>
  RecommendedProfResumeExperience?: RecommendedProfResumeExperienceResolvers<ContextType>
  RecommendedProfResumeSalary?: RecommendedProfResumeSalaryResolvers<ContextType>
  RecommendedProfResumes?: RecommendedProfResumesResolvers<ContextType>
  RecommendedVacanciesConnection?: RecommendedVacanciesConnectionResolvers<ContextType>
  RecommendedVacanciesEdge?: RecommendedVacanciesEdgeResolvers<ContextType>
  RecruitmentOrderOutput?: RecruitmentOrderOutputResolvers<ContextType>
  RegularGift?: RegularGiftResolvers<ContextType>
  RejectedByModeratorVacancyEmployerEvent?: RejectedByModeratorVacancyEmployerEventResolvers<ContextType>
  RejectedProlongationRequestVacancyEmployerEvent?: RejectedProlongationRequestVacancyEmployerEventResolvers<ContextType>
  RejectedReviewVacancyEmployerEvent?: RejectedReviewVacancyEmployerEventResolvers<ContextType>
  RelevanceScore?: RelevanceScoreResolvers<ContextType>
  RemoveSeekerFavoriteVacanciesOutput?: RemoveSeekerFavoriteVacanciesOutputResolvers<ContextType>
  ResumeAdditionalEducation?: ResumeAdditionalEducationResolvers<ContextType>
  ResumeAdditionalInfo?: ResumeAdditionalInfoResolvers<ContextType>
  ResumeContactInfo?: ResumeContactInfoResolvers<ContextType>
  ResumeDraftCreatedNotificationDetails?: ResumeDraftCreatedNotificationDetailsResolvers<ContextType>
  ResumeEducation?: ResumeEducationResolvers<ContextType>
  ResumeError?: ResumeErrorResolvers<ContextType>
  ResumeExperience?: ResumeExperienceResolvers<ContextType>
  ResumeFilling?: ResumeFillingResolvers<ContextType>
  ResumeFillingType?: ResumeFillingTypeResolvers<ContextType>
  ResumeLanguageSkill?: ResumeLanguageSkillResolvers<ContextType>
  ResumePersonalInfo?: ResumePersonalInfoResolvers<ContextType>
  ResumePhone?: ResumePhoneResolvers<ContextType>
  ResumePublishedNotificationDetails?: ResumePublishedNotificationDetailsResolvers<ContextType>
  ResumeReportOutput?: ResumeReportOutputResolvers<ContextType>
  ResumeSalary?: ResumeSalaryResolvers<ContextType>
  ResumeSearchSuggest?: ResumeSearchSuggestResolvers<ContextType>
  ResumeSocialContactLink?: ResumeSocialContactLinkResolvers<ContextType>
  ResumeState?: ResumeStateResolvers<ContextType>
  ResumeViewInfo?: ResumeViewInfoResolvers<ContextType>
  ResumeViewsConnection?: ResumeViewsConnectionResolvers<ContextType>
  ResumeViewsEdge?: ResumeViewsEdgeResolvers<ContextType>
  RisingUpCatalogService?: RisingUpCatalogServiceResolvers<ContextType>
  Rubric?: RubricResolvers<ContextType>
  RubricFacet?: RubricFacetResolvers<ContextType>
  SalaryStatistics?: SalaryStatisticsResolvers<ContextType>
  SaleManagerMutations?: SaleManagerMutationsResolvers<ContextType>
  SaleManagerQueries?: SaleManagerQueriesResolvers<ContextType>
  SaleManagerResponseOutput?: SaleManagerResponseOutputResolvers<ContextType>
  SaveVacancyInterviewQuestionsErrorUnion?: SaveVacancyInterviewQuestionsErrorUnionResolvers<ContextType>
  SaveVacancyInterviewQuestionsOutput?: SaveVacancyInterviewQuestionsOutputResolvers<ContextType>
  SavedCard?: SavedCardResolvers<ContextType>
  SavedCardsMutations?: SavedCardsMutationsResolvers<ContextType>
  Schedule?: ScheduleResolvers<ContextType>
  ScheduleFacet?: ScheduleFacetResolvers<ContextType>
  ScrollInfo?: ScrollInfoResolvers<ContextType>
  SeekerApplication?: SeekerApplicationResolvers<ContextType>
  SeekerApplyQueries?: SeekerApplyQueriesResolvers<ContextType>
  SeekerDislikedVacancyDetails?: SeekerDislikedVacancyDetailsResolvers<ContextType>
  SeekerDislikedVacancyMutations?: SeekerDislikedVacancyMutationsResolvers<ContextType>
  SeekerDislikedVacancyQueries?: SeekerDislikedVacancyQueriesResolvers<ContextType>
  SeekerFavoriteVacancyDetails?: SeekerFavoriteVacancyDetailsResolvers<ContextType>
  SeekerFavoriteVacancyMutations?: SeekerFavoriteVacancyMutationsResolvers<ContextType>
  SeekerFavoriteVacancyQueries?: SeekerFavoriteVacancyQueriesResolvers<ContextType>
  SeekerMutations?: SeekerMutationsResolvers<ContextType>
  SeekerRecommendationProfile?: SeekerRecommendationProfileResolvers<ContextType>
  SeekerRegistration?: SeekerRegistrationResolvers<ContextType>
  SeekerSubscription?: SeekerSubscriptionResolvers<ContextType>
  SeekerSubscriptionActivation?: SeekerSubscriptionActivationResolvers<ContextType>
  SeekerSubscriptionChannel?: SeekerSubscriptionChannelResolvers<ContextType>
  SeekerSubscriptionManualProfile?: SeekerSubscriptionManualProfileResolvers<ContextType>
  SeekerSubscriptionMutations?: SeekerSubscriptionMutationsResolvers<ContextType>
  SeekerSubscriptionProfile?: SeekerSubscriptionProfileResolvers<ContextType>
  SeekerSubscriptionSummary?: SeekerSubscriptionSummaryResolvers<ContextType>
  SeekerWalletUser?: SeekerWalletUserResolvers<ContextType>
  SelectPromoCodeResponse?: SelectPromoCodeResponseResolvers<ContextType>
  SendChangeSeekerEmailOutput?: SendChangeSeekerEmailOutputResolvers<ContextType>
  SendEmailOutput?: SendEmailOutputResolvers<ContextType>
  SendNotificationSetEmailDetails?: SendNotificationSetEmailDetailsResolvers<ContextType>
  SendSmsOutput?: SendSmsOutputResolvers<ContextType>
  SentToReviewVacancyEmployerEvent?: SentToReviewVacancyEmployerEventResolvers<ContextType>
  ServerError?: ServerErrorResolvers<ContextType>
  ServiceActivationOutput?: ServiceActivationOutputResolvers<ContextType>
  ServiceActivator?: ServiceActivatorResolvers<ContextType>
  ServiceCompany?: ServiceCompanyResolvers<ContextType>
  ServiceCompanyTurbotaStatus?: ServiceCompanyTurbotaStatusResolvers<ContextType>
  ServiceCvdbRegionsOutput?: ServiceCvdbRegionsOutputResolvers<ContextType>
  ServiceCvdbRubricsOutput?: ServiceCvdbRubricsOutputResolvers<ContextType>
  ServiceDiscountTypeWrapper?: ServiceDiscountTypeWrapperResolvers<ContextType>
  ServiceEmployee?: ServiceEmployeeResolvers<ContextType>
  ServiceEmployerEventSnapshotUnion?: ServiceEmployerEventSnapshotUnionResolvers<ContextType>
  ServiceGroupWrapper?: ServiceGroupWrapperResolvers<ContextType>
  ServiceIsExpiringEvent?: ServiceIsExpiringEventResolvers<ContextType>
  ServiceLimit?: ServiceLimitResolvers<ContextType>
  ServicePrice?: ServicePriceResolvers<ContextType>
  ServicePurchaseHistoryOutput?: ServicePurchaseHistoryOutputResolvers<ContextType>
  ServicePurchasedService?: ServicePurchasedServiceResolvers<ContextType>
  ServiceTypeWrapper?: ServiceTypeWrapperResolvers<ContextType>
  ServiceUnited?: ServiceUnitedResolvers<ContextType>
  ServiceUnitedEdge?: ServiceUnitedEdgeResolvers<ContextType>
  ServicesFilters?: ServicesFiltersResolvers<ContextType>
  ServicesManagementClientFilters?: ServicesManagementClientFiltersResolvers<ContextType>
  SetActionStateOutput?: SetActionStateOutputResolvers<ContextType>
  SimilarVacancies?: SimilarVacanciesResolvers<ContextType>
  SimilarVacanciesQueries?: SimilarVacanciesQueriesResolvers<ContextType>
  SimilarVacancyKeywordsOutput?: SimilarVacancyKeywordsOutputResolvers<ContextType>
  SpiderVacancy?: SpiderVacancyResolvers<ContextType>
  StartGamificationWaveOutput?: StartGamificationWaveOutputResolvers<ContextType>
  Statistic?: StatisticResolvers<ContextType>
  StatisticBranch?: StatisticBranchResolvers<ContextType>
  StatisticBranchTimeseries?: StatisticBranchTimeseriesResolvers<ContextType>
  StatisticCandidatesStructure?: StatisticCandidatesStructureResolvers<ContextType>
  StatisticData?: StatisticDataResolvers<ContextType>
  StatisticItem?: StatisticItemResolvers<ContextType>
  StatisticProfession?: StatisticProfessionResolvers<ContextType>
  StatisticProfessionByCountTimeSeries?: StatisticProfessionByCountTimeSeriesResolvers<ContextType>
  StatisticProfessionItem?: StatisticProfessionItemResolvers<ContextType>
  StatisticRubric?: StatisticRubricResolvers<ContextType>
  StatisticRubricTimeseries?: StatisticRubricTimeseriesResolvers<ContextType>
  StatisticSalaryDistribution?: StatisticSalaryDistributionResolvers<ContextType>
  StatisticStructure?: StatisticStructureResolvers<ContextType>
  StatisticTimeseries?: StatisticTimeseriesResolvers<ContextType>
  StatisticTotal?: StatisticTotalResolvers<ContextType>
  StatisticVacanciesStructure?: StatisticVacanciesStructureResolvers<ContextType>
  StatisticVacancyBadge?: StatisticVacancyBadgeResolvers<ContextType>
  Subrubric?: SubrubricResolvers<ContextType>
  SubrubricsFacet?: SubrubricsFacetResolvers<ContextType>
  SubscriptionOutputType?: SubscriptionOutputTypeResolvers<ContextType>
  SuggestedVacancySearchOutput?: SuggestedVacancySearchOutputResolvers<ContextType>
  SummaryService?: SummaryServiceResolvers<ContextType>
  Synonym?: SynonymResolvers<ContextType>
  SynonymTag?: SynonymTagResolvers<ContextType>
  SystemEventPerformer?: SystemEventPerformerResolvers<ContextType>
  SystemWalletUser?: SystemWalletUserResolvers<ContextType>
  TagGroup?: TagGroupResolvers<ContextType>
  TargetMailingListFilter?: TargetMailingListFilterResolvers<ContextType>
  TargetMailingListQueryFilter?: TargetMailingListQueryFilterResolvers<ContextType>
  TargetedExternalMailingLink?: TargetedExternalMailingLinkResolvers<ContextType>
  TargetedMailing?: TargetedMailingResolvers<ContextType>
  TargetedMailingCandidatesLocation?: TargetedMailingCandidatesLocationResolvers<ContextType>
  TargetedMailingCandidatesRubric?: TargetedMailingCandidatesRubricResolvers<ContextType>
  TargetedMailingCandidatesSampling?: TargetedMailingCandidatesSamplingResolvers<ContextType>
  TargetedMailingCandidatesSamplingByCodeInputSnapshot?: TargetedMailingCandidatesSamplingByCodeInputSnapshotResolvers<ContextType>
  TargetedMailingCandidatesSamplingByFilterInputSnapshot?: TargetedMailingCandidatesSamplingByFilterInputSnapshotResolvers<ContextType>
  TargetedMailingCandidatesSamplingDetails?: TargetedMailingCandidatesSamplingDetailsResolvers<ContextType>
  TargetedMailingCandidatesSamplingDetailsByChannel?: TargetedMailingCandidatesSamplingDetailsByChannelResolvers<ContextType>
  TargetedMailingCandidatesSamplingDetailsByVacancy?: TargetedMailingCandidatesSamplingDetailsByVacancyResolvers<ContextType>
  TargetedMailingCandidatesSamplingErrorUnion?: TargetedMailingCandidatesSamplingErrorUnionResolvers<ContextType>
  TargetedMailingCandidatesSamplingInputSnapshot?: TargetedMailingCandidatesSamplingInputSnapshotResolvers<ContextType>
  TargetedMailingCandidatesSamplingOutput?: TargetedMailingCandidatesSamplingOutputResolvers<ContextType>
  TargetedMailingCompletedHistoryFact?: TargetedMailingCompletedHistoryFactResolvers<ContextType>
  TargetedMailingConnection?: TargetedMailingConnectionResolvers<ContextType>
  TargetedMailingContentUnion?: TargetedMailingContentUnionResolvers<ContextType>
  TargetedMailingCreatedHistoryFact?: TargetedMailingCreatedHistoryFactResolvers<ContextType>
  TargetedMailingEdge?: TargetedMailingEdgeResolvers<ContextType>
  TargetedMailingEditedHistoryFact?: TargetedMailingEditedHistoryFactResolvers<ContextType>
  TargetedMailingFacetsOutput?: TargetedMailingFacetsOutputResolvers<ContextType>
  TargetedMailingFailedHistoryFact?: TargetedMailingFailedHistoryFactResolvers<ContextType>
  TargetedMailingHistoryFactUnion?: TargetedMailingHistoryFactUnionResolvers<ContextType>
  TargetedMailingInvalidVacanciesError?: TargetedMailingInvalidVacanciesErrorResolvers<ContextType>
  TargetedMailingLanguageRequirement?: TargetedMailingLanguageRequirementResolvers<ContextType>
  TargetedMailingLinkUnion?: TargetedMailingLinkUnionResolvers<ContextType>
  TargetedMailingMailContent?: TargetedMailingMailContentResolvers<ContextType>
  TargetedMailingMobilePushContent?: TargetedMailingMobilePushContentResolvers<ContextType>
  TargetedMailingMutations?: TargetedMailingMutationsResolvers<ContextType>
  TargetedMailingQueries?: TargetedMailingQueriesResolvers<ContextType>
  TargetedMailingRequiredFieldsMissedError?: TargetedMailingRequiredFieldsMissedErrorResolvers<ContextType>
  TargetedMailingResponsible?: TargetedMailingResponsibleResolvers<ContextType>
  TargetedMailingSamplingRelatedVacancy?: TargetedMailingSamplingRelatedVacancyResolvers<ContextType>
  TargetedMailingSaveErrorUnion?: TargetedMailingSaveErrorUnionResolvers<ContextType>
  TargetedMailingSendingHistoryFact?: TargetedMailingSendingHistoryFactResolvers<ContextType>
  TargetedMailingStatusFacet?: TargetedMailingStatusFacetResolvers<ContextType>
  TargetedVacancyMailingLink?: TargetedVacancyMailingLinkResolvers<ContextType>
  TargetedVacancyMailingLinkVacancySnapshot?: TargetedVacancyMailingLinkVacancySnapshotResolvers<ContextType>
  TeamActions?: TeamActionsResolvers<ContextType>
  TestTargetedMailingOutput?: TestTargetedMailingOutputResolvers<ContextType>
  TokenAlreadyUsedError?: TokenAlreadyUsedErrorResolvers<ContextType>
  Topic?: TopicResolvers<ContextType>
  TopicTemplate?: TopicTemplateResolvers<ContextType>
  TrackCompanyViewOutput?: TrackCompanyViewOutputResolvers<ContextType>
  TransactionExistsError?: TransactionExistsErrorResolvers<ContextType>
  TransactionsConnection?: TransactionsConnectionResolvers<ContextType>
  TransactionsEdge?: TransactionsEdgeResolvers<ContextType>
  TurbotaEvent?: TurbotaEventResolvers<ContextType>
  TurbotaWasExpiredError?: TurbotaWasExpiredErrorResolvers<ContextType>
  UInt?: GraphQLScalarType
  UncompletedPaymentEvent?: UncompletedPaymentEventResolvers<ContextType>
  UndislikeSeekerVacanciesOutput?: UndislikeSeekerVacanciesOutputResolvers<ContextType>
  UnionBannerDynamicElement?: UnionBannerDynamicElementResolvers<ContextType>
  University?: UniversityResolvers<ContextType>
  UnknownServerError?: UnknownServerErrorResolvers<ContextType>
  UnlinkPayerOutput?: UnlinkPayerOutputResolvers<ContextType>
  UnpaidOrderDetails?: UnpaidOrderDetailsResolvers<ContextType>
  UnpublishSeekerProfResumeError?: UnpublishSeekerProfResumeErrorResolvers<ContextType>
  UnpublishedProfResumeOutput?: UnpublishedProfResumeOutputResolvers<ContextType>
  UpdateGiftRequestOutput?: UpdateGiftRequestOutputResolvers<ContextType>
  UpdateProfResumeAdditionalPhonesOutput?: UpdateProfResumeAdditionalPhonesOutputResolvers<ContextType>
  UpdateProfResumeEmailOutput?: UpdateProfResumeEmailOutputResolvers<ContextType>
  UpdateProfResumePhoneOutput?: UpdateProfResumePhoneOutputResolvers<ContextType>
  UpdateProfResumePortfoliosOutput?: UpdateProfResumePortfoliosOutputResolvers<ContextType>
  UpdateProfResumeSocialNetworksOutput?: UpdateProfResumeSocialNetworksOutputResolvers<ContextType>
  UpdateSeekerProfResumeAdditionalEducationError?: UpdateSeekerProfResumeAdditionalEducationErrorResolvers<ContextType>
  UpdateSeekerProfResumeAdditionalInfoError?: UpdateSeekerProfResumeAdditionalInfoErrorResolvers<ContextType>
  UpdateSeekerProfResumeAdditionalInfosError?: UpdateSeekerProfResumeAdditionalInfosErrorResolvers<ContextType>
  UpdateSeekerProfResumeAdditionalPhonesError?: UpdateSeekerProfResumeAdditionalPhonesErrorResolvers<ContextType>
  UpdateSeekerProfResumeAnonymityError?: UpdateSeekerProfResumeAnonymityErrorResolvers<ContextType>
  UpdateSeekerProfResumeAvailabilityStateError?: UpdateSeekerProfResumeAvailabilityStateErrorResolvers<ContextType>
  UpdateSeekerProfResumeBirthDateError?: UpdateSeekerProfResumeBirthDateErrorResolvers<ContextType>
  UpdateSeekerProfResumeCityError?: UpdateSeekerProfResumeCityErrorResolvers<ContextType>
  UpdateSeekerProfResumeDiiaCertificateError?: UpdateSeekerProfResumeDiiaCertificateErrorResolvers<ContextType>
  UpdateSeekerProfResumeDisabilityError?: UpdateSeekerProfResumeDisabilityErrorResolvers<ContextType>
  UpdateSeekerProfResumeEducationError?: UpdateSeekerProfResumeEducationErrorResolvers<ContextType>
  UpdateSeekerProfResumeEmailError?: UpdateSeekerProfResumeEmailErrorResolvers<ContextType>
  UpdateSeekerProfResumeExperienceError?: UpdateSeekerProfResumeExperienceErrorResolvers<ContextType>
  UpdateSeekerProfResumeFullNameError?: UpdateSeekerProfResumeFullNameErrorResolvers<ContextType>
  UpdateSeekerProfResumeGenderError?: UpdateSeekerProfResumeGenderErrorResolvers<ContextType>
  UpdateSeekerProfResumeHiddenCompaniesError?: UpdateSeekerProfResumeHiddenCompaniesErrorResolvers<ContextType>
  UpdateSeekerProfResumeLanguageSkillsError?: UpdateSeekerProfResumeLanguageSkillsErrorResolvers<ContextType>
  UpdateSeekerProfResumeMainPhoneError?: UpdateSeekerProfResumeMainPhoneErrorResolvers<ContextType>
  UpdateSeekerProfResumeMilitaryStatusError?: UpdateSeekerProfResumeMilitaryStatusErrorResolvers<ContextType>
  UpdateSeekerProfResumePhotoError?: UpdateSeekerProfResumePhotoErrorResolvers<ContextType>
  UpdateSeekerProfResumePortfoliosError?: UpdateSeekerProfResumePortfoliosErrorResolvers<ContextType>
  UpdateSeekerProfResumePositionError?: UpdateSeekerProfResumePositionErrorResolvers<ContextType>
  UpdateSeekerProfResumeSalaryError?: UpdateSeekerProfResumeSalaryErrorResolvers<ContextType>
  UpdateSeekerProfResumeScheduleError?: UpdateSeekerProfResumeScheduleErrorResolvers<ContextType>
  UpdateSeekerProfResumeSearchStateError?: UpdateSeekerProfResumeSearchStateErrorResolvers<ContextType>
  UpdateSeekerProfResumeSkillsError?: UpdateSeekerProfResumeSkillsErrorResolvers<ContextType>
  UpdateSeekerProfResumeSocialNetworksError?: UpdateSeekerProfResumeSocialNetworksErrorResolvers<ContextType>
  UpdateSeekerProfResumeSortDateError?: UpdateSeekerProfResumeSortDateErrorResolvers<ContextType>
  UpdateSeekerProfResumeSortDateOutput?: UpdateSeekerProfResumeSortDateOutputResolvers<ContextType>
  UpdateSeekerProfResumeStateError?: UpdateSeekerProfResumeStateErrorResolvers<ContextType>
  UpdateTargetedMailingOutput?: UpdateTargetedMailingOutputResolvers<ContextType>
  UpdatedProfResumeAdditionalEducationsOutput?: UpdatedProfResumeAdditionalEducationsOutputResolvers<ContextType>
  UpdatedProfResumeAdditionalInfoOutput?: UpdatedProfResumeAdditionalInfoOutputResolvers<ContextType>
  UpdatedProfResumeAdditionalInfosOutput?: UpdatedProfResumeAdditionalInfosOutputResolvers<ContextType>
  UpdatedProfResumeAnonymityOutput?: UpdatedProfResumeAnonymityOutputResolvers<ContextType>
  UpdatedProfResumeAvailabilityStateOutput?: UpdatedProfResumeAvailabilityStateOutputResolvers<ContextType>
  UpdatedProfResumeBirthDateOutput?: UpdatedProfResumeBirthDateOutputResolvers<ContextType>
  UpdatedProfResumeCityOutput?: UpdatedProfResumeCityOutputResolvers<ContextType>
  UpdatedProfResumeDiiaCertificateOutput?: UpdatedProfResumeDiiaCertificateOutputResolvers<ContextType>
  UpdatedProfResumeDisabilityOutput?: UpdatedProfResumeDisabilityOutputResolvers<ContextType>
  UpdatedProfResumeEducationsOutput?: UpdatedProfResumeEducationsOutputResolvers<ContextType>
  UpdatedProfResumeExperiencesOutput?: UpdatedProfResumeExperiencesOutputResolvers<ContextType>
  UpdatedProfResumeFullNameOutput?: UpdatedProfResumeFullNameOutputResolvers<ContextType>
  UpdatedProfResumeGenderOutput?: UpdatedProfResumeGenderOutputResolvers<ContextType>
  UpdatedProfResumeHiddenCompaniesOutput?: UpdatedProfResumeHiddenCompaniesOutputResolvers<ContextType>
  UpdatedProfResumeLanguageSkillsOutput?: UpdatedProfResumeLanguageSkillsOutputResolvers<ContextType>
  UpdatedProfResumeMilitaryStatusOutput?: UpdatedProfResumeMilitaryStatusOutputResolvers<ContextType>
  UpdatedProfResumePhotoOutput?: UpdatedProfResumePhotoOutputResolvers<ContextType>
  UpdatedProfResumePositionOutput?: UpdatedProfResumePositionOutputResolvers<ContextType>
  UpdatedProfResumeSalaryOutput?: UpdatedProfResumeSalaryOutputResolvers<ContextType>
  UpdatedProfResumeScheduleOutput?: UpdatedProfResumeScheduleOutputResolvers<ContextType>
  UpdatedProfResumeSearchStateOutput?: UpdatedProfResumeSearchStateOutputResolvers<ContextType>
  UpdatedProfResumeSkillsOutput?: UpdatedProfResumeSkillsOutputResolvers<ContextType>
  Upload?: GraphQLScalarType
  Uri?: GraphQLScalarType
  UsagePeriodTypeWrapper?: UsagePeriodTypeWrapperResolvers<ContextType>
  UsePromoCodeResponse?: UsePromoCodeResponseResolvers<ContextType>
  UsedHotServiceEmployerEvent?: UsedHotServiceEmployerEventResolvers<ContextType>
  UsedVacancyServiceEmployerEvent?: UsedVacancyServiceEmployerEventResolvers<ContextType>
  User?: UserResolvers<ContextType>
  UserAccountLogin?: UserAccountLoginResolvers<ContextType>
  UserCardDeleteOutput?: UserCardDeleteOutputResolvers<ContextType>
  UserEmailHasBeenSetNotificationDetails?: UserEmailHasBeenSetNotificationDetailsResolvers<ContextType>
  UserIsNotAuthenticatedError?: UserIsNotAuthenticatedErrorResolvers<ContextType>
  UserIsNotEmployerError?: UserIsNotEmployerErrorResolvers<ContextType>
  UserLeftBasketEvent?: UserLeftBasketEventResolvers<ContextType>
  UserRegistrationError?: UserRegistrationErrorResolvers<ContextType>
  UserRegistrationMutations?: UserRegistrationMutationsResolvers<ContextType>
  UserRegistrationValidationResponse?: UserRegistrationValidationResponseResolvers<ContextType>
  UserRoleWrapper?: UserRoleWrapperResolvers<ContextType>
  UserState?: UserStateResolvers<ContextType>
  Users?: UsersResolvers<ContextType>
  UsersManagementFilters?: UsersManagementFiltersResolvers<ContextType>
  UsersManagementPageInfo?: UsersManagementPageInfoResolvers<ContextType>
  UsersManagementSubFilter?: UsersManagementSubFilterResolvers<ContextType>
  VacanciesPageClientFilters?: VacanciesPageClientFiltersResolvers<ContextType>
  Vacancy?: VacancyResolvers<ContextType>
  VacancyAdditionalCandidatePermissions?: VacancyAdditionalCandidatePermissionsResolvers<ContextType>
  VacancyAddress?: VacancyAddressResolvers<ContextType>
  VacancyAddressesSuggestOutput?: VacancyAddressesSuggestOutputResolvers<ContextType>
  VacancyAdminRejectDetails?: VacancyAdminRejectDetailsResolvers<ContextType>
  VacancyAppearedInViewPortOutput?: VacancyAppearedInViewPortOutputResolvers<ContextType>
  VacancyApplicationsDestination?: VacancyApplicationsDestinationResolvers<ContextType>
  VacancyAwaitingApprovalDetails?: VacancyAwaitingApprovalDetailsResolvers<ContextType>
  VacancyBadge?: VacancyBadgeResolvers<ContextType>
  VacancyBannedByModeratorDetails?: VacancyBannedByModeratorDetailsResolvers<ContextType>
  VacancyBannedEvent?: VacancyBannedEventResolvers<ContextType>
  VacancyBenefit?: VacancyBenefitResolvers<ContextType>
  VacancyCandidatesScreeningOptions?: VacancyCandidatesScreeningOptionsResolvers<ContextType>
  VacancyChangeClosingBehaviorOutput?: VacancyChangeClosingBehaviorOutputResolvers<ContextType>
  VacancyChangeHistory?: VacancyChangeHistoryResolvers<ContextType>
  VacancyChangeOwnerOutput?: VacancyChangeOwnerOutputResolvers<ContextType>
  VacancyCityCount?: VacancyCityCountResolvers<ContextType>
  VacancyCloseOutput?: VacancyCloseOutputResolvers<ContextType>
  VacancyCompany?: VacancyCompanyResolvers<ContextType>
  VacancyCompetitors?: VacancyCompetitorsResolvers<ContextType>
  VacancyCompetitorsStatistics?: VacancyCompetitorsStatisticsResolvers<ContextType>
  VacancyContacts?: VacancyContactsResolvers<ContextType>
  VacancyCounterGraphType?: VacancyCounterGraphTypeResolvers<ContextType>
  VacancyCreateError?: VacancyCreateErrorResolvers<ContextType>
  VacancyCreateOutput?: VacancyCreateOutputResolvers<ContextType>
  VacancyCreateSuggest?: VacancyCreateSuggestResolvers<ContextType>
  VacancyCreateSuggestOutput?: VacancyCreateSuggestOutputResolvers<ContextType>
  VacancyDesign?: VacancyDesignResolvers<ContextType>
  VacancyDesignFooter?: VacancyDesignFooterResolvers<ContextType>
  VacancyDesignHeader?: VacancyDesignHeaderResolvers<ContextType>
  VacancyDesignHeaderItem?: VacancyDesignHeaderItemResolvers<ContextType>
  VacancyDictionaryBenefit?: VacancyDictionaryBenefitResolvers<ContextType>
  VacancyDraftOutput?: VacancyDraftOutputResolvers<ContextType>
  VacancyEdge?: VacancyEdgeResolvers<ContextType>
  VacancyEmployer?: VacancyEmployerResolvers<ContextType>
  VacancyEmployerCount?: VacancyEmployerCountResolvers<ContextType>
  VacancyEndingCountDetails?: VacancyEndingCountDetailsResolvers<ContextType>
  VacancyEndingLeftDaysDetails?: VacancyEndingLeftDaysDetailsResolvers<ContextType>
  VacancyEventStatisticItem?: VacancyEventStatisticItemResolvers<ContextType>
  VacancyExperienceQuestion?: VacancyExperienceQuestionResolvers<ContextType>
  VacancyFromAnotherSourceGraphType?: VacancyFromAnotherSourceGraphTypeResolvers<ContextType>
  VacancyHotPeriod?: VacancyHotPeriodResolvers<ContextType>
  VacancyInterviewQuestionsMutations?: VacancyInterviewQuestionsMutationsResolvers<ContextType>
  VacancyLanguageQuestion?: VacancyLanguageQuestionResolvers<ContextType>
  VacancyMakeHotOutput?: VacancyMakeHotOutputResolvers<ContextType>
  VacancyMedia?: VacancyMediaResolvers<ContextType>
  VacancyModeratorRejectDetails?: VacancyModeratorRejectDetailsResolvers<ContextType>
  VacancyOnWorkWillExpireInFiveDaysEvent?: VacancyOnWorkWillExpireInFiveDaysEventResolvers<ContextType>
  VacancyOwnerChangedDetails?: VacancyOwnerChangedDetailsResolvers<ContextType>
  VacancyPackageCatalogService?: VacancyPackageCatalogServiceResolvers<ContextType>
  VacancyPosition?: VacancyPositionResolvers<ContextType>
  VacancyPositionRisingInSearchList?: VacancyPositionRisingInSearchListResolvers<ContextType>
  VacancyProlongationRequest?: VacancyProlongationRequestResolvers<ContextType>
  VacancyProlongationRequestsOutput?: VacancyProlongationRequestsOutputResolvers<ContextType>
  VacancyProlongationReviewError?: VacancyProlongationReviewErrorResolvers<ContextType>
  VacancyProlongationReviewOutput?: VacancyProlongationReviewOutputResolvers<ContextType>
  VacancyPublicationApprovedDetails?: VacancyPublicationApprovedDetailsResolvers<ContextType>
  VacancyPublicationCatalogService?: VacancyPublicationCatalogServiceResolvers<ContextType>
  VacancyPublicationEndedDetails?: VacancyPublicationEndedDetailsResolvers<ContextType>
  VacancyPublicationRejectedDetails?: VacancyPublicationRejectedDetailsResolvers<ContextType>
  VacancyPublicationTypeWrapper?: VacancyPublicationTypeWrapperResolvers<ContextType>
  VacancyPublishOutput?: VacancyPublishOutputResolvers<ContextType>
  VacancyPublishPeriod?: VacancyPublishPeriodResolvers<ContextType>
  VacancyPublishedEmployerAchievement?: VacancyPublishedEmployerAchievementResolvers<ContextType>
  VacancyPublishedWithFilledAddressAndSalaryEmployerAchievement?: VacancyPublishedWithFilledAddressAndSalaryEmployerAchievementResolvers<ContextType>
  VacancyRejectPublicationOutput?: VacancyRejectPublicationOutputResolvers<ContextType>
  VacancyReportOutput?: VacancyReportOutputResolvers<ContextType>
  VacancyRepublishOutput?: VacancyRepublishOutputResolvers<ContextType>
  VacancyResumeOutput?: VacancyResumeOutputResolvers<ContextType>
  VacancySalary?: VacancySalaryResolvers<ContextType>
  VacancySearchSuggest?: VacancySearchSuggestResolvers<ContextType>
  VacancySearchSuggestList?: VacancySearchSuggestListResolvers<ContextType>
  VacancySearchSuggests?: VacancySearchSuggestsResolvers<ContextType>
  VacancySearchSuggestsQueries?: VacancySearchSuggestsQueriesResolvers<ContextType>
  VacancyServiceActivationBehavior?: VacancyServiceActivationBehaviorResolvers<ContextType>
  VacancyStatisticsItem?: VacancyStatisticsItemResolvers<ContextType>
  VacancyStatisticsTimeseries?: VacancyStatisticsTimeseriesResolvers<ContextType>
  VacancyStatusCount?: VacancyStatusCountResolvers<ContextType>
  VacancySuspendOutput?: VacancySuspendOutputResolvers<ContextType>
  VacancyTimeseriesItem?: VacancyTimeseriesItemResolvers<ContextType>
  VacancyToAtsProjectExportError?: VacancyToAtsProjectExportErrorResolvers<ContextType>
  VacancyToAtsProjectExportOutput?: VacancyToAtsProjectExportOutputResolvers<ContextType>
  VacancyToAtsProjectExportResult?: VacancyToAtsProjectExportResultResolvers<ContextType>
  VacancyUpdateOutput?: VacancyUpdateOutputResolvers<ContextType>
  ViewNotificationsError?: ViewNotificationsErrorResolvers<ContextType>
  ViewNotificationsOutput?: ViewNotificationsOutputResolvers<ContextType>
  Wallet?: WalletResolvers<ContextType>
  WalletBalance?: WalletBalanceResolvers<ContextType>
  WalletCartMutation?: WalletCartMutationResolvers<ContextType>
  WalletDoesNotExistError?: WalletDoesNotExistErrorResolvers<ContextType>
  WalletExistsError?: WalletExistsErrorResolvers<ContextType>
  WalletTransaction?: WalletTransactionResolvers<ContextType>
  WalletTransactionEvent?: WalletTransactionEventResolvers<ContextType>
  WalletTransactionInitiatorUnion?: WalletTransactionInitiatorUnionResolvers<ContextType>
  WalletUserUnion?: WalletUserUnionResolvers<ContextType>
  WalletsConnection?: WalletsConnectionResolvers<ContextType>
  WalletsEdge?: WalletsEdgeResolvers<ContextType>
  WarehouseGift?: WarehouseGiftResolvers<ContextType>
  WarehouseLimitedGift?: WarehouseLimitedGiftResolvers<ContextType>
  WarehouseRange?: WarehouseRangeResolvers<ContextType>
  WidgetPaymentMethod?: WidgetPaymentMethodResolvers<ContextType>
  WidgetPaymentMethodOutput?: WidgetPaymentMethodOutputResolvers<ContextType>
  appliesByUrl?: AppliesByUrlResolvers<ContextType>
  clickOnLinks?: ClickOnLinksResolvers<ContextType>
  serviceAvailableContacts?: ServiceAvailableContactsResolvers<ContextType>
  timeseries?: TimeseriesResolvers<ContextType>
}

export type DirectiveResolvers<ContextType = any> = {
  apollo_studio_metadata?: Apollo_Studio_MetadataDirectiveResolver<any, any, ContextType>
  client?: ClientDirectiveResolver<any, any, ContextType>
}
