import { AuthService } from '@alliance/shared/auth/api'
import { AccountLinksEnum, ATS_EXTENSION_URL } from '@alliance/shared/constants'
import { Environment } from '@alliance/shared/environment'
import { CountryCodesEnum, TranslationService } from '@alliance/shared/translation'
import { DetectPlatformService, RouterConfigStateService } from '@alliance/shared/utils'
import { Inject, Injectable } from '@angular/core'
import { Params, Route, Router } from '@angular/router'
import { WINDOW } from '@ng-web-apis/common'
import { take } from 'rxjs/operators'
import { AccountQueryParamsModel } from './models/account-query-params-model'
import { AccountLinkModel, TargetTypeEnum } from './models/AccountLinkModel'

@Injectable({
  providedIn: 'root'
})
export class AccountLinkMapService {
  // todo approach: via native routerLink with external urls resolver
  // https://medium.com/@adrianfaciu/using-the-angular-router-to-navigate-to-external-links-15cc585b7b88

  private langPath = this.translationService.currentLangIsRussian() ? `/${CountryCodesEnum.RU}` : ''

  private readonly seekerLinks = {
    seekerSubscriptions: this.removeTrailingSlash(this.environment.mainSiteUrl ?? '') + 'my/subscriptions',
    myResumes: this.removeTrailingSlash(this.environment.mainSiteUrl ?? '') + '/my/resumes',
    seekerProfile: this.removeTrailingSlash(this.environment.mainSiteUrl ?? '') + '/my/profile-settings',
    seekerMailingLists: (this.environment.mainSiteUrl ?? '') + 'my/subscriptions',
    applicationHistory: (this.environment.mainSiteUrl ?? '') + 'my/applies',
    favoriteVacancies: (this.environment.mainSiteUrl ?? '') + 'my/favorites/vacancies',
    profile: (this.environment.mainSiteUrl ?? '') + 'my/profile',
    dislikedVacancies: (this.environment.mainSiteUrl ?? '') + 'my/disliked/vacancies',
    jobseekerHome: this.environment.mainSiteUrl ?? '',
    jobseekerNewRecomUrl: (this.environment.mainSiteUrl ?? '') + 'my/recommendations',
    seekerCvCreate: this.removeTrailingSlash(this.environment.mainSiteUrl ?? '') + '/wizard',
    seeker: this.environment.mainSiteUrl ?? '',
    newRegistrationJobseeker: this.removeTrailingSlash(this.environment.mainSiteUrl ?? '') + this.langPath + '/auth/registration/jobseeker',
    cvWelcome: this.removeTrailingSlash(this.environment.mainSiteUrl ?? '') + '/cv-welcome',
    vacanciesByRubrics: (this.environment.mainSiteUrl ?? '') + '/jobsearch/rubrics'
  }

  private readonly employerLinks = {
    home: (this.environment.mainSiteUrl ?? '') + 'employer',
    candidates: this.removeTrailingSlash(this.environment.mainSiteUrl ?? '') + this.langPath + '/candidates',
    vacancies: (this.environment.mainSiteUrl ?? '') + 'my/vacancies',
    chat: (this.environment.mainSiteUrl ?? '') + 'my/chat',
    login: this.removeTrailingSlash(this.environment.mainSiteUrl ?? '') + this.langPath + '/auth/login',
    register: this.removeTrailingSlash(this.environment.mainSiteUrl ?? '') + this.langPath + '/auth/registration/employer',
    addVacancy: (this.environment.mainSiteUrl ?? '') + 'my/vacancies/create',
    resumeViewers: (this.environment.mainSiteUrl ?? '') + 'my/resumes/views',
    myServices: (this.environment.mainSiteUrl ?? '') + 'my/services',
    newRegistrationEmployer: this.removeTrailingSlash(this.environment.mainSiteUrl ?? '') + this.langPath + '/auth/registration/employer',
    wallets: (this.environment.mainSiteUrl ?? '') + 'my/wallets'
  }

  private readonly blogsLinks = {
    blog: 'https://budni.robota.ua/ua',
    aboutBlog: this.removeTrailingSlash(this.environment.budni ?? '') + '/about-budni',
    howCreateResume: (this.environment.budni ?? '') + '/resume-job-search/yak-skladaty-rezyume-schob-pryvernuty-uvahu-robotodavtsya-pid-chas-viyny',
    budni: this.environment.budni ?? '',
    help: 'http://help.robota.ua',
    about: `https://about.${this.environment.domain ?? ''}`,
    thepoint: 'https://thepoint.rabota.ua',
    media: `https://mediakit.${this.environment.domain ?? ''}`
  }

  private readonly linksAssets = {
    termsOfUseEmployer: 'https://images.cf-rabota.com.ua/alliance/terms_of_use_employer_v4.pdf',
    termsOfUseSeeker: 'https://images.cf-rabota.com.ua/alliance/terms_of_use_seeker.pdf',
    publicOffer: 'https://images.cf-rabota.com.ua/alliance/public_offer_2022.pdf',
    privacyOffer: 'https://images.cf-rabota.com.ua/alliance/privacy_offer.pdf'
  }

  private readonly LinkMap: Record<AccountLinksEnum, string> = {
    ...this.seekerLinks,
    ...this.employerLinks,
    ...this.blogsLinks,
    ...this.linksAssets,
    newLogin: this.removeTrailingSlash(this.environment.mainSiteUrl ?? '') + this.langPath + '/auth/login',
    logout: (this.environment.mainSiteUrl ?? '') + 'auth/login',
    ats: `https://helper.${this.environment.domain ?? 'rabota.ua'}`,
    viewVacancy: this.removeTrailingSlash(this.environment.mainSiteUrl ?? '') + this.langPath + '/company0/vacancy',
    prozora: (this.environment.mainSiteUrl ?? '') + 'prozora',
    allApplies: (this.environment.mainSiteUrl ?? '') + 'my/vacancies/all/applies',
    services: (this.environment.mainSiteUrl ?? '') + 'services',
    turbota: (this.environment.mainSiteUrl ?? '') + 'my/turbota',
    support: (this.environment.mainSiteUrl ?? '') + 'feedback',
    employerDashboard: (this.environment.mainSiteUrl ?? '') + 'my',
    resume: (this.environment.accountHost ?? '') + this.langPath + '/jobsearch/notepad/cvs',
    users: this.removeTrailingSlash(this.environment.mainSiteUrl ?? '') + '/my/users',
    eventsHistory: this.removeTrailingSlash(this.environment.mainSiteUrl ?? '') + '/my/log/events',
    servicesManagement: this.removeTrailingSlash(this.environment.mainSiteUrl) + this.langPath + '/my/services/management',
    centerTurboty: (this.environment.mainSiteUrl ?? '') + 'center-turboty',
    employerSettings: this.removeTrailingSlash(this.environment.mainSiteUrl ?? '') + '/my/settings',
    atsExtension: ATS_EXTENSION_URL
  }

  private availablePaths: string[] = []

  public constructor(
    private authService: AuthService,
    private environment: Environment,
    private router: Router,
    private routerConfigStateService: RouterConfigStateService,
    private platform: DetectPlatformService,
    private translationService: TranslationService,
    @Inject(WINDOW) private readonly windowRef: Window
  ) {
    this.availablePaths = AccountLinkMapService.getAvailablePaths(this.router.config)

    if (this.platform.isBrowser) {
      this.routerConfigStateService.routerConfigState$.subscribe(() => (this.availablePaths = AccountLinkMapService.getAvailablePaths(this.router.config)))
    }
  }

  private static getAvailablePaths(routes: Route[], collectedRoutes: string[] = [], parent = ''): string[] {
    if (!routes.length) {
      return collectedRoutes
    }

    return routes.reduce<string[]>((acc, route) => {
      let currentRoute = parent
      let res = acc
      if (route?.path !== '**') {
        currentRoute = (parent ? `${parent}/${route.path ?? ''}` : route.path) ?? ''
        res = [...acc, currentRoute]
      }
      return route.children ? AccountLinkMapService.getAvailablePaths(route.children, res, currentRoute) : res
    }, collectedRoutes)
  }

  public proceedAction(path: AccountLinkModel, options: { target: TargetTypeEnum; queryParams?: Params } = { target: '_self' }): void {
    const route = path[0]
    if (route === AccountLinksEnum.logout) {
      this.logout(route)
    } else {
      this.goToPage(path, options)
    }
  }

  public checkIfInternal(key: AccountLinksEnum): boolean {
    const currentPath = (this.LinkMap[key] ?? '')
      .replace(this.environment.mainSiteUrl ?? '', '')
      .replace(this.environment.accountHost ?? '', '')
      .replace(/^\//, '')
      .replace(`${CountryCodesEnum.RU}/`, '')

    return this.availablePaths.some(url => url === currentPath)
  }

  public composeLink(path: AccountLinkModel, queryParams?: AccountQueryParamsModel): string {
    const type = path[0]
    const mainPathElement = path[1]
    const parsedFirstParam = mainPathElement && !(this.LinkMap[type] ?? '').includes(mainPathElement.toString()) ? mainPathElement : ''
    const otherParams = path.slice(2).length ? '/' + path.slice(2).join('/') : ''
    const queryParamsString = Object.entries(queryParams || {})
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    const composedPath = `${this.LinkMap[type] ?? ''}${parsedFirstParam}${otherParams}`

    return queryParamsString ? `${composedPath}?${queryParamsString}` : `${composedPath}`
  }

  private goToPage(path: AccountLinkModel, options: { target: TargetTypeEnum; queryParams?: Params }): void {
    if (this.platform.isBrowser) {
      this.windowRef.open(this.composeLink(path, options.queryParams ?? {}), options.target)
    }
  }

  private removeTrailingSlash(url: string = ''): string {
    return url.replace(/\/$/, '')
  }

  private logout(route: AccountLinksEnum): void {
    this.authService
      .logout()
      .pipe(take(1))
      .subscribe(() => this.goToPage([route], { target: '_self' }))
  }
}
