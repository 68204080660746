import { DEPLOY_FOLDER } from '@alliance/shared/models'
import { NgZoneHelperService } from '@alliance/shared/utils'
import { HttpClient } from '@angular/common/http'
import { Inject, Injectable, Optional } from '@angular/core'
import { makeStateKey, StateKey, TransferState } from '@angular/platform-browser'
import { LOCATION } from '@ng-web-apis/common'
import { catchError, Observable, of, shareReplay, take } from 'rxjs'
import { IconName } from '../autogenerated'
import { getIconFullUrl } from './icons-url'

@Injectable({
  providedIn: 'root'
})
export class IconsService {
  private registry = new Map<IconName, Observable<string>>()

  public constructor(
    @Inject(LOCATION) private location: Location,
    @Optional() @Inject(DEPLOY_FOLDER) private deployFolder: string | null,
    @Optional() private transferState: TransferState,
    private http: HttpClient,
    private ngZoneHelperService: NgZoneHelperService
  ) {}

  public getIconData$(icon: IconName): Observable<string> {
    // use rehydration data from server
    const cacheKey: StateKey<string> = makeStateKey<string>(icon)
    const storedIcon = this.transferState?.get(cacheKey, null)

    if (storedIcon) {
      this.registry.set(icon, of(storedIcon))
    }

    if (this.registry.has(icon)) {
      return this.registry.get(icon) || of('')
    }

    const url = getIconFullUrl(icon, {
      origin: this.location.origin,
      folder: this.deployFolder
    })
    const icon$ = url ? this.getIconByUrl$(url) : of('')

    this.registry.set(icon, icon$)
    return icon$
  }

  private getIconByUrl$(url: string): Observable<string> {
    return this.http.get<string>(url, { responseType: 'text' as 'json' }).pipe(
      this.ngZoneHelperService.outsideNgZone(),
      take(1),
      shareReplay(1),
      catchError(() => of(''))
    )
  }
}
