import { PATTERNS } from '@alliance/shared/constants'
import { log } from '@alliance/shared/logger'

// https://github.com/angular/angular-cli/issues/2276#issuecomment-264502520
// global variable for webpack, has url for assets
declare let __webpack_public_path__: string

export const getWebpackPublicPath = (): string | null => {
  try {
    return __webpack_public_path__
  } catch {
    return null
  }
}

export const getFileUrl = (pathName: string, params: { origin: string; folder?: string | null }): string => {
  // TODO: delete folder logic when admin8 & admin-crm ready
  if (params.folder) {
    const isExternal = (path: string): boolean => !!path.match(new RegExp(PATTERNS.URL_PATTERN))

    if (isExternal(pathName)) {
      return pathName
    }

    const pathFile = `${params.folder.replace(/^\/+|\/+$/g, '')}/${pathName}`

    try {
      return new URL(`${pathFile}`, params.origin).toString()
    } catch (e) {
      log.log({ where: 'shared-utils: DynamicFilesLoaderService', category: 'try_catch', message: 'createElement with pathNameWithQuery failed', error: e, pathName })
      return `/${pathFile}`
    }
  }

  try {
    return new URL(pathName, getWebpackPublicPath() || params.origin).toString()
  } catch (e) {
    log.log({ where: 'shared-utils: DynamicFilesLoaderService', category: 'try_catch', message: 'createElement with pathNameWithQuery failed', error: e, pathName })
    return `/${pathName}`
  }
}
