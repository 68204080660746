import { InjectionToken } from '@angular/core'

// temp workaround for admin-crm elements
// TODO: delete after deployFolder & deployUrl for admin-crm removed
// steps:
// 1. get rid from static.robota.ua in deployUrl, left /admin8elements/ folder for ingress rewrites <-- we are here
// 2. get rid from deployUrl entirely:
//      - move all admin8 scripts & styles from base path to dedicated folders OR move them to alliance ??
//        -  founded broken scripts for firebase & reminders feature --> move to admin-crm as new elements & fix
//      - cleanup projects.json & these workarounds
//      - ingress rewrites to "redirect" all js / css / svg (exclude specific admin* folders) files to admin-crm

export const DEPLOY_URL = new InjectionToken<string | null>('DEPLOY_URL')
export const DEPLOY_FOLDER = new InjectionToken<string | null>('DEPLOY_FOLDER')
