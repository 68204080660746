export const PATTERNS = {
  EMAIL_PATTERN: /^[\w-]+[_.\-\w]*@\w+([.-]?\w+)*(\.\w{2,15})+$/,
  MULTIPLE_EMAIL_PATTERN: /^(([\w-]+[_.\-\w]*@\w+([.-]?\w+)*(\.\w{2,15})+)( )*(;|,| |$)( )*)+$/, // separator `( )*(;|,| |$)( )*)` - per DB
  NAME_PATTERN: /^\s*[a-zA-ZА-Яа-яіїІЇёєЄҐґ'`‘’-]+\s*$/,
  NAME_AND_LAST_NAME_PATTERN: /^\s*[a-zA-ZА-Яа-яіїІЇёєЄҐґ '`‘’-]+\s*$/,
  PHONE_PATTERN: /^\+38 \(0\d{2}\) \d{3}-\d{2}-\d{2}$/,
  YEAR_PATTERN: /^(19|20)\d\d$/,
  ONLY_NUMBERS_PATTERN: '^[0-9]*$',
  URL_PATTERN: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,8}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/,
  YOUTUBE_URL_PATTERN: /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(&(amp;)?[\w?=]*)?/,
  FACEBOOK_URL_PATTERN: /http(?:s?):\/\/(?:www\.)?(facebook|fb\.com)\b([-a-zA-Z0-9@:%_+.~#?&/=]*)$/,
  INSTAGRAM_URL_PATTERN: /http(?:s?):\/\/(?:www\.)?(instagram\.com)\b([-a-zA-Z0-9@:%_+.~#?&/=]*)$/,
  LINKEDIN_URL_PATTERN: /http(?:s?):\/\/(?:www\.)?([a-z]{2,3}\.)?(linkedin\.com)\b([-a-zA-Z0-9@:%_+.~#?&/=]*)$/,
  LINKEDIN_USER_PROFILE_URL_PATTERN: /^https:\/\/www\.linkedin\.com\/in\/[^/]{1,}\/?$/,
  WORK_VACANCY_URL_PATTERN: /(?:http(?:s?):\/\/)?(?:www\.)?work.ua(?:\/en|\/ru)?\/jobs\/(\d{6,8})(?:\/)?(?:.)*/,
  WORK_URL_PATTERN: /(?:http(?:s?):\/\/)?(?:www\.)?work\.ua(?:\/en|\/ru)?(?:\/)?(?:.)*/,
  OLX_URL_PATTERN: /^(https?:\/\/)(www\.)?(olx\.(ua|pl))\b([-a-zA-Z0-9@:%_+.~#?&/=]*)$/
}
