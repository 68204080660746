import { ICONS_ASSETS_PATH } from '@alliance/shared/constants'
import { log } from '@alliance/shared/logger'
import { getWebpackPublicPath } from '@alliance/shared/utils'

import { IconName } from '../autogenerated/models/icon-model'
import { IconNameSeparator } from '../autogenerated/constants/icon-name-separator'

type IconNameOnly = [string]
type IconNameWirthVersion = [string, string]

const isValidIconAttributes = (value: string[]): value is IconNameOnly | IconNameWirthVersion => value.length >= 1 && !!value[0].length

export const getIconNameWithVersion = (icon: IconName): string | null => {
  const iconAttributes = icon.split(IconNameSeparator)
  if (isValidIconAttributes(iconAttributes)) {
    const name = iconAttributes[0]
    const hash = iconAttributes[1] ?? ''
    return `${name}.svg?v=${hash}`
  }

  return null
}

export const getIconFullUrl = (icon: IconName, params: { origin: string; folder: string | null }): string | null => {
  const iconNameWithVersion = getIconNameWithVersion(icon)
  const defaultIconPath = [ICONS_ASSETS_PATH, iconNameWithVersion].join('/')

  if (!iconNameWithVersion) {
    log.warn({ where: 'shared-icons: getIconUrl', category: 'unexpected_value', message: 'receive invalid icon attributes during name splitting', icon })
    return null
  }

  // TODO: delete folder logic when admin8 & admin-crm ready
  if (params.folder) {
    const pathWithFolder = [params.folder.replace(/^\/+|\/+$/g, ''), defaultIconPath].join('/')
    try {
      return new URL(pathWithFolder, params.origin).toString()
    } catch (e) {
      log.warn({ where: 'shared-icons: getIconUrl', category: 'try_catch', message: 'new URL failed, fallback returned', error: e, icon, path: pathWithFolder })
      return `/${pathWithFolder}`
    }
  }

  try {
    return new URL(defaultIconPath, getWebpackPublicPath() || params.origin).toString()
  } catch (e) {
    log.warn({ where: 'shared-icons: getIconUrl', category: 'try_catch', message: 'new URL failed, fallback returned', error: e, icon, path: defaultIconPath })
    return `/${defaultIconPath}`
  }
}
