/**
 * Converts given hex color to rgb format
 * Original code from https://stackoverflow.com/a/5624139
 * @param hex e.g. "#0033FF"
 * @returns rgb format e.g. "0, 51, 255"
 */
const hexToRgb = (hex: string): string | null => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, (_m: string, r: string, g: string, b: string) => r + r + g + g + b + b)

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : null
}

const config = {
  purge: {
    enabled: false,
    mode: 'layers',
    layers: ['components', 'utilities']
  },
  prefix: 'santa-',
  important: true,
  theme: {
    extend: {
      opacity: {
        '15': '0.15'
      }
    },
    screens: {
      321: '321px',
      480: '480px',
      600: '600px',
      760: '760px',
      700: '700px',
      1000: '1000px',
      1024: '1024px',
      1280: '1280px',
      1200: '1200px',
      1300: '1300px',
      1400: '1400px',
      1800: '1800px'
    },
    colorsRgb: {},
    colors: {
      transparent: 'transparent',
      white: '#fff',
      facebook: '#4267B2',
      google: '#4285f4',
      twitter: '#1DA1F2',
      youtube: '#FF0000',
      linkedin: '#0077B5',
      telegram: '#039BE5',
      viber: '#8E24AA',
      red: {
        100: '#FFF4F4',
        200: '#FFE9E9',
        300: '#FFD8D8',
        400: '#FF9D9D',
        500: '#FF5252',
        550: '#EC4142',
        600: '#D93A3B',
        700: '#BC0002',
        800: '#870000'
      },
      black: {
        100: '#F2F5F8',
        200: '#E0E7EB',
        300: '#C8D1D6',
        400: '#A3B2BA',
        500: '#687E88',
        600: '#303A3E',
        700: '#212527',
        800: '#050506'
      },
      green: {
        100: '#ECFFF4',
        200: '#D5FFE6',
        300: '#B3F6CE',
        400: '#88E5AE',
        500: '#3BC372',
        600: '#00963D',
        700: '#00702D',
        800: '#00491E'
      },
      yellow: {
        100: '#FFFDE7',
        200: '#FFF59D',
        300: '#FFE886',
        400: '#FDD835',
        500: '#F9A825',
        600: '#F57F17',
        700: '#DD3500',
        800: '#AA3300'
      },
      blue: {
        100: '#E9F1FF',
        200: '#DCE8FF',
        300: '#B7CFFF',
        400: '#7AA2F2',
        500: '#3366CC',
        600: '#154CBC',
        700: '#073797',
        800: '#001E5A'
      },
      violet: {
        100: '#F8EFFF',
        200: '#F2E3FF',
        300: '#E3C4FF',
        400: '#CCA4EF',
        500: '#9B6EC3',
        600: '#63338D',
        700: '#4A1678',
        800: '#330061'
      },
      pink: {
        500: '#E040FB'
      }
    },
    spacing: {
      0: '0',
      10: '10px',
      20: '20px',
      40: '40px',
      80: '80px',
      160: '160px'
    },
    fontSize: {
      10: '10px',
      12: '12px',
      14: '14px',
      16: '16px',
      18: '18px',
      20: '20px',
      24: '24px',
      32: '32px',
      40: '40px',
      60: '60px'
    },
    fontFamily: {
      sans: ['Roboto', '"San Francisco"', 'Helvetica', 'sans-serif'],
      glight: ['"Graphik Light"', 'sans-serif'],
      gregular: ['"Graphik Regular"', 'sans-serif'],
      gmedium: ['"Graphik Medium"', 'sans-serif'],
      gsemibold: ['"Graphik Semibold"', 'sans-serif'],
      gbold: ['"Graphik Bold"', 'sans-serif']
    },
    borderRadius: {
      none: '0',
      full: '9999px',
      1: '1px',
      5: '5px',
      10: '10px',
      20: '20px'
    },
    lineHeight: {
      zero: '0',
      none: '1',
      10: '10px',
      20: '20px',
      30: '30px',
      40: '40px',
      50: '50px'
    },
    maxWidth: {
      32: '320px',
      700: '700px',
      1000: '1000px',
      1200: '1200px',
      1400: '1400px',
      1800: '1800px',
      half: '50%',
      full: '100%',
      '100vw': '100vw'
    },
    gap: {
      5: '5px',
      10: '10px',
      15: '15px',
      20: '20px',
      40: '40px',
      80: '80px'
    }
  },
  variants: {
    display: ['responsive', 'empty', 'group-hover'],
    margin: ['responsive', 'first', 'last', 'empty'],
    padding: ['responsive', 'first', 'last'],
    textAlign: ['responsive', 'first', 'last'],
    outline: ['focus'],
    backgroundColor: ['hover', 'focus', 'active', 'group-hover'],
    textColor: ['responsive', 'hover', 'focus', 'active', 'group-hover'],
    opacity: ['hover', 'group-hover'],
    gridTemplateColumns: ['responsive'],
    gridTemplateRows: ['responsive'],
    gap: ['responsive'],
    borderRadius: ['responsive', 'first', 'last'],
    borderWidth: ['responsive', 'first', 'last']
  },
  plugins: [],
  corePlugins: {
    preflight: false
  }
}

config.theme.colorsRgb = {
  black: {
    '200': hexToRgb(config.theme.colors.black[200]),
    '300': hexToRgb(config.theme.colors.black[300]),
    '400': hexToRgb(config.theme.colors.black[400]),
    '500': hexToRgb(config.theme.colors.black[500]),
    '600': hexToRgb(config.theme.colors.black[600]),
    '700': hexToRgb(config.theme.colors.black[700]),
    '800': hexToRgb(config.theme.colors.black[800])
  },
  blue: {
    '700': hexToRgb(config.theme.colors.blue[700])
  },
  green: {
    '700': hexToRgb(config.theme.colors.green[700])
  },
  red: {
    '500': hexToRgb(config.theme.colors.red[500]),
    '700': hexToRgb(config.theme.colors.red[700])
  },
  white: hexToRgb(config.theme.colors.white),
  yellow: {
    '300': hexToRgb(config.theme.colors.yellow[300])
  }
}

export default config
