<div
  class="mobile-modal-container"
  *ngIf="{
    blockIsResized: blockIsResized$ | async,
    height: height$ | async,
    openedState: openedState$ | async,
    disabled: disabled$ | async
  } as state"
>
  <div
    allianceOuterClick
    [emit]="!state.disabled"
    (clickedOutside)="animateCloseModal()"
    #mobileModalWrapper
    class="mobile-modal-wrapper"
    (click)="onInnerClick($event)"
    [style.height]="state.height ? state.height + 'px' : 'auto'"
    [@openStateBottom]="state.openedState"
    (@openStateBottom.done)="onAnimationEnd($event)"
  >
    <div class="pan-block" *ngIf="state.blockIsResized" (panup)="onPanUp($event)" (pandown)="onPanDown($event)" (panend)="onPanEnd($event)" (panstart)="onPanStart()">
      <div class="pan-btn"></div>
    </div>
    <div #mobileModalContent class="santa-overflow-y-auto santa-bg-white santa-z-40" [class.santa-h-full]="!resetHeightOnContentMutation" subtree childList (waMutationObserver)="onContentMutation()">
      <ng-template *cdkPortalOutlet="content"></ng-template>
    </div>
  </div>
</div>
